var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GSProvider;

var _gameSparks = _interopRequireDefault(require("./gameSparks"));

var instance = null;

function GSProvider() {
  if (!instance) {
    instance = new _gameSparks.default();
  }

  return instance;
}