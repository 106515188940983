var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LeaveButton;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _AudioVideoProvider = require("../../common/audio-video/AudioVideoProvider");

var _DesignSystem = require("../../../theme/DesignSystem");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _backstageComponentsStyle = _interopRequireDefault(require("./backstageComponents-style"));

var _actions = require("../../../core/user-rooms/actions");

function LeaveButton(_ref) {
  var roomId = _ref.roomId;
  var Styles = (0, _backstageComponentsStyle.default)();
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      leave = _useAudioVideoContext.leave,
      hasLocalScreenShare = _useAudioVideoContext.hasScreenShare;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.mlg;
  });

  var onClick = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!hasLocalScreenShare) {
                _context.next = 3;
                break;
              }

              _context.next = 3;
              return dispatch((0, _actions.stopScreenShare)(roomId));

            case 3:
              _context.next = 5;
              return leave();

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onClick() {
      return _ref3.apply(this, arguments);
    };
  }();

  if (match) return _react.default.createElement(_ColoredIconButton.default, {
    customColor: colors.errorColor,
    onClick: onClick
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.iconButtonSize
  }, _react.default.createElement(_reactFeather.LogOut, null)));
  return _react.default.createElement(_ContainedButton.default, {
    onPress: onClick,
    style: {
      backgroundColor: colors.errorColor
    }
  }, _i18n.default.translate('backstage.leave'));
}