Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectTestimonialLength = exports.selectTestimonialById = exports.selectTestimonialsIsLoaded = exports.selectTestimonialsIsLoading = exports.selectTestimonialsList = void 0;

var _reducer = require("./reducer");

var _constants = require("./constants");

var _toolkit = require("@reduxjs/toolkit");

var selectTestimonialsState = function selectTestimonialsState(state) {
  return state[_constants.NAME];
};

var selectTestimonialsList = _reducer.testimonialsEntityAdapter.getSelectors(selectTestimonialsState).selectAll;

exports.selectTestimonialsList = selectTestimonialsList;
var selectTestimonialsIsLoading = (0, _toolkit.createSelector)(selectTestimonialsState, function (state) {
  return state.loading;
});
exports.selectTestimonialsIsLoading = selectTestimonialsIsLoading;
var selectTestimonialsIsLoaded = (0, _toolkit.createSelector)(selectTestimonialsState, function (state) {
  return state.loaded;
});
exports.selectTestimonialsIsLoaded = selectTestimonialsIsLoaded;

var selectTestimonialById = _reducer.testimonialsEntityAdapter.getSelectors(selectTestimonialsState).selectById;

exports.selectTestimonialById = selectTestimonialById;
var selectTestimonialLength = (0, _toolkit.createSelector)(selectTestimonialsList, function (state) {
  return state.length;
});
exports.selectTestimonialLength = selectTestimonialLength;