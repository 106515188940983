Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TICKET_ERROR = exports.TICKET_BOUGHT = exports.ASSIGNED_USER_ROLES = exports.PAYMENT_TICKET_COMPLETED = exports.LOGIN_PROVIDERS = exports.FIREBASE_PHONE_AUTH_FAILED = exports.FIREBASE_PHONE_AUTH_COMPLETED = exports.FIREBASE_PHONE_AUTH_PROGRESS = exports.FIREBASE_USER_DOES_NOT_EXIST_ERROR = exports.FIREBASE_USER_PROFILE_UPDATE = exports.STOP_AUTH_STATE_LOADING = exports.FIREBASE_AUTH_STATE_CHANGED = exports.FIREBASE_AUTH_STATE_LISTENING = exports.FIREBASE_LOGIN_FAILED = exports.FIREBASE_LOGIN_MAIL_SENT = exports.FIREBASE_LOGIN_COMPLETED = exports.FIREBASE_LOGIN_IN_PROGRESS = exports.USER_INCOMPLETE_PROFILE = exports.USER_UNAUTHENTICATED = exports.START_USER_LOGOUT = exports.USER_LOGOUT = exports.LOGIN_COMPLETED = exports.WEBSOCKET_AUTHENTICATED_EVENT = exports.WEBSOCKET_AUTHENTICATION_EVENT = exports.WEBSOCKET_UNAUTHENTICATED_EVENT = exports.NAME = void 0;
var NAME = 'user';
exports.NAME = NAME;
var WEBSOCKET_UNAUTHENTICATED_EVENT = 'error:unauthenticated';
exports.WEBSOCKET_UNAUTHENTICATED_EVENT = WEBSOCKET_UNAUTHENTICATED_EVENT;
var WEBSOCKET_AUTHENTICATION_EVENT = 'authentication';
exports.WEBSOCKET_AUTHENTICATION_EVENT = WEBSOCKET_AUTHENTICATION_EVENT;
var WEBSOCKET_AUTHENTICATED_EVENT = 'authenticated';
exports.WEBSOCKET_AUTHENTICATED_EVENT = WEBSOCKET_AUTHENTICATED_EVENT;
var LOGIN_COMPLETED = 'user/LOGIN_COMPLETED';
exports.LOGIN_COMPLETED = LOGIN_COMPLETED;
var USER_LOGOUT = 'profile/USER_LOGOUT';
exports.USER_LOGOUT = USER_LOGOUT;
var START_USER_LOGOUT = 'profile/START_USER_LOGOUT';
exports.START_USER_LOGOUT = START_USER_LOGOUT;
var USER_UNAUTHENTICATED = 'user/USER_UNAUTHENTICATED';
exports.USER_UNAUTHENTICATED = USER_UNAUTHENTICATED;
var USER_INCOMPLETE_PROFILE = 'user/USER_INCOMPLETE_PROFILE';
exports.USER_INCOMPLETE_PROFILE = USER_INCOMPLETE_PROFILE;
var FIREBASE_LOGIN_IN_PROGRESS = 'user/FIREBASE_LOGIN_IN_PROGRESS';
exports.FIREBASE_LOGIN_IN_PROGRESS = FIREBASE_LOGIN_IN_PROGRESS;
var FIREBASE_LOGIN_COMPLETED = 'user/FIREBASE_LOGIN_COMPLETED';
exports.FIREBASE_LOGIN_COMPLETED = FIREBASE_LOGIN_COMPLETED;
var FIREBASE_LOGIN_MAIL_SENT = 'user/FIREBASE_LOGIN_MAIL_SENT';
exports.FIREBASE_LOGIN_MAIL_SENT = FIREBASE_LOGIN_MAIL_SENT;
var FIREBASE_LOGIN_FAILED = 'user/FIREBASE_LOGIN_FAILED';
exports.FIREBASE_LOGIN_FAILED = FIREBASE_LOGIN_FAILED;
var FIREBASE_AUTH_STATE_LISTENING = 'user/FIREBASE_AUTH_STATE_LISTENING';
exports.FIREBASE_AUTH_STATE_LISTENING = FIREBASE_AUTH_STATE_LISTENING;
var FIREBASE_AUTH_STATE_CHANGED = 'user/FIREBASE_AUTH_STATE_CHANGED';
exports.FIREBASE_AUTH_STATE_CHANGED = FIREBASE_AUTH_STATE_CHANGED;
var STOP_AUTH_STATE_LOADING = 'user/STOP_AUTH_STATE_LOADING';
exports.STOP_AUTH_STATE_LOADING = STOP_AUTH_STATE_LOADING;
var FIREBASE_USER_PROFILE_UPDATE = 'user/FIREBASE_USER_PROFILE_UPDATE';
exports.FIREBASE_USER_PROFILE_UPDATE = FIREBASE_USER_PROFILE_UPDATE;
var FIREBASE_USER_DOES_NOT_EXIST_ERROR = 'user/FIREBASE_USER_DOES_NOT_EXIST';
exports.FIREBASE_USER_DOES_NOT_EXIST_ERROR = FIREBASE_USER_DOES_NOT_EXIST_ERROR;
var FIREBASE_PHONE_AUTH_PROGRESS = 'user/FIREBASE_PHONE_AUTH_PROGRESS';
exports.FIREBASE_PHONE_AUTH_PROGRESS = FIREBASE_PHONE_AUTH_PROGRESS;
var FIREBASE_PHONE_AUTH_COMPLETED = 'user/FIREBASE_PHONE_AUTH_COMPLETED';
exports.FIREBASE_PHONE_AUTH_COMPLETED = FIREBASE_PHONE_AUTH_COMPLETED;
var FIREBASE_PHONE_AUTH_FAILED = 'user/FIREBASE_PHONE_AUTH_FAILED';
exports.FIREBASE_PHONE_AUTH_FAILED = FIREBASE_PHONE_AUTH_FAILED;
var LOGIN_PROVIDERS = {
  FACEBOOK: 'facebook',
  APPLE: 'apple',
  GOOGLE: 'google',
  FIREBASE: 'firebase'
};
exports.LOGIN_PROVIDERS = LOGIN_PROVIDERS;
var PAYMENT_TICKET_COMPLETED = 'user/PAYMENT_TICKET_COMPLETED';
exports.PAYMENT_TICKET_COMPLETED = PAYMENT_TICKET_COMPLETED;
var ASSIGNED_USER_ROLES = 'user/ASSIGNED_USER_ROLES';
exports.ASSIGNED_USER_ROLES = ASSIGNED_USER_ROLES;
var TICKET_BOUGHT = 'TICKET_BOUGHT';
exports.TICKET_BOUGHT = TICKET_BOUGHT;
var TICKET_ERROR = 'TICKET_ERROR';
exports.TICKET_ERROR = TICKET_ERROR;