var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LeaderboardCountdown;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _SmartCountDown = _interopRequireDefault(require("../../../common/SmartCountDown"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

var _leaderboardCountdownStyles = _interopRequireWildcard(require("./leaderboard-countdown-styles"));

var _headerStyles = _interopRequireDefault(require("../utils/header-styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function LeaderboardCountdown(props) {
  var onFinish = props.onFinish,
      untilDate = props.untilDate;
  return _react.default.createElement(_reactNative.View, {
    style: _headerStyles.default.announcingWinnersContainer
  }, _react.default.createElement(_Title.default, {
    style: _leaderboardCountdownStyles.default.announcingWinnersTitle
  }, "Announcing winners "), _react.default.createElement(_SmartCountDown.default, {
    untilDate: untilDate,
    onFinish: onFinish,
    color: _leaderboardCountdownStyles.countdownColor,
    size: _leaderboardCountdownStyles.countdownSize,
    style: _leaderboardCountdownStyles.default.leaderboardCountdown,
    timeElapsedMessage: "in a few moments"
  }));
}