var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _eventCardView = _interopRequireDefault(require("./event-card/event-card-view"));

var _eventListStyles = _interopRequireDefault(require("./event-list-styles"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

function EventList(_ref) {
  var events = _ref.events;
  var Styles = (0, _eventListStyles.default)();
  var classes = (0, _styles.default)();

  var groupBy = function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  var groupedEvents = groupBy(events, 'status');

  var live = _i18n.default.translate('event.live');

  var upcoming = _i18n.default.translate('event.upcoming');

  var past = _i18n.default.translate('event.past');

  var eventsArray = {};

  if (groupedEvents[live]) {
    eventsArray[live] = groupedEvents[live];
  }

  if (groupedEvents[upcoming]) {
    eventsArray[upcoming] = groupedEvents[upcoming];
  }

  if (groupedEvents[past]) {
    eventsArray[past] = groupedEvents[past];
  }

  return _react.default.createElement(_react.default.Fragment, null, Object.entries(eventsArray).map(function (_ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];

    return _react.default.createElement(_reactNative.View, {
      key: key,
      style: Styles.eventsContainer
    }, _react.default.createElement(_Spacing.default, {
      style: Styles.header
    }, _react.default.createElement(_core.Typography, {
      className: classes.textUppercase,
      variant: "h2"
    }, "".concat(key, " (").concat(value.length, ")"))), _react.default.createElement(_Spacing.default, {
      wrap: "wrap",
      alignItems: "center",
      style: Styles.container
    }, value.map(function (event) {
      return _react.default.createElement(_eventCardView.default, {
        key: event.id,
        event: event
      });
    })));
  }));
}

var _default = EventList;
exports.default = _default;