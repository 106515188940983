var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/chat/actions");

var _actions2 = require("../../../core/event-stages/actions");

var _selectors = require("../../../core/event-stages/selectors");

var _selectors2 = require("../../../core/session/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useLiveSessionState = require("../../../utils/hooks/useLiveSessionState");

var _footer = _interopRequireDefault(require("../../common/footer"));

var _liveStreamVideoContainer = _interopRequireDefault(require("../../common/live-stream-video/live-stream-video-container"));

var _eventFinishedView = _interopRequireDefault(require("../../live/components/event-finished-view"));

var _EventStateForLive = _interopRequireDefault(require("../../live/components/EventStateForLive"));

var _loadingView = _interopRequireDefault(require("../../live/components/loading-view"));

var _nextSessionView = _interopRequireDefault(require("../../live/components/next-session-view"));

var _ResponsiveLiveContainer = _interopRequireDefault(require("../../live/components/ResponsiveLiveContainer"));

var _ResponsiveWebVideoContainer = _interopRequireDefault(require("../../live/components/ResponsiveWebVideoContainer"));

var _sessionFinishedView = _interopRequireDefault(require("../../live/components/session-finished-view"));

var _sessionTitleContainer = _interopRequireDefault(require("../../live/components/session-title/session-title-container"));

var _partners = _interopRequireDefault(require("../../partners"));

var _livePageMenu = _interopRequireDefault(require("./live-page-menu"));

var _livePageStyles = _interopRequireDefault(require("./live-page-styles"));

var _livePageTypes = _interopRequireDefault(require("./live-page-types"));

var _invalidSessionView = _interopRequireDefault(require("../../live/components/invalid-session-view/invalid-session-view"));

var _noAccessView = _interopRequireDefault(require("../../live/components/no-access-view"));

var _prerecordedVideoFinishedView = _interopRequireDefault(require("../../live/components/prerecorded-video-finished-view"));

var _BaseVideo = _interopRequireDefault(require("../../common/audio-video/BaseVideo"));

var _BackstageLive = _interopRequireDefault(require("./components/BackstageLive"));

var _selectors3 = require("../../../core/event-info/selectors");

var _sessionTypeConsts = require("../../../../functions/constants/session-type-consts");

var _sessions = _interopRequireDefault(require("../../sessions"));

var _waitingForStreamView = _interopRequireDefault(require("../../live/components/waiting-for-stream-view"));

var _selectors4 = require("../../../core/user/selectors");

var _actions3 = require("../../../core/user/actions");

var _userActivityConstants = require("../../../constants/user-activity-constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var LivePageContainer = function LivePageContainer() {
  var liveId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.LIVE_ID);
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });

  var sub = _react.default.useRef(function () {});

  var dispatch = (0, _reactRedux.useDispatch)();
  var isFocused = (0, _reactNavigationHooks.useIsFocused)();
  var Styles = (0, _livePageStyles.default)();
  var stage = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectEventStageWithSessionsById)(state, liveId);
  });
  var firstSession = (0, _reactRedux.useSelector)(_selectors2.selectFirstSessionWithSpeaker);
  var live = (0, _useLiveSessionState.useLiveSessionState)(stage);
  var isStageLoading = (0, _reactRedux.useSelector)(_selectors.selectEventStagesLoading);
  var isStageLoaded = (0, _reactRedux.useSelector)(_selectors.selectEventStagesLoaded);
  var isSessionLoading = (0, _reactRedux.useSelector)(_selectors2.selectSessionsIsLoading);
  var isSessionLoaded = (0, _reactRedux.useSelector)(_selectors2.selectSessionsIsLoaded);
  var isLoading = isStageLoading || isSessionLoading;
  var isLoaded = isStageLoaded && isSessionLoaded;
  var eventId = (0, _reactRedux.useSelector)(_selectors3.selectEventInfoId);
  var event = (0, _reactRedux.useSelector)(_selectors3.selectEventInfo);
  var firebaseUser = (0, _reactRedux.useSelector)(_selectors4.getFirebaseUser);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      liveDefined = _useState2[0],
      setLiveDefined = _useState2[1];

  var muxMetaDataInfo = {};
  (0, _react.useEffect)(function () {
    dispatch((0, _actions.sendRoomChange)(liveId));
  }, [dispatch, liveId]);
  (0, _react.useEffect)(function () {
    if (liveId && isFocused) {
      dispatch((0, _actions2.listenStageChangesById)(liveId)).then(function (_ref) {
        var payload = _ref.payload;

        if (payload) {
          if (sub.current) {
            sub.current();
          }

          sub.current = payload;
        }
      });
    } else {
      if (sub.current) {
        sub.current();
      }
    }
  }, [liveId, dispatch, isFocused]);
  (0, _react.useEffect)(function () {
    if (live && (live.currentSession || live.nextSession)) {
      setLiveDefined(true);
    }
  }, [live]);
  (0, _react.useEffect)(function () {
    var sessionData = live && live.currentSession ? live.currentSession : live && live.nextSession ? live.nextSession : null;

    if (sessionData && liveDefined) {
      (0, _actions3.trackUserActivity)({
        event: event,
        eventType: _userActivityConstants.TRACKABLE_EVENTS.SESSION_PAGE_ACCESSED,
        session: {
          name: sessionData.title,
          id: sessionData.id
        }
      });
    }
  }, [liveDefined]);

  if (live && live.currentSession && firebaseUser) {
    muxMetaDataInfo = {
      page_type: 'watchpage',
      viewer_user_id: firebaseUser.uid,
      video_series: eventId,
      video_id: live.currentSession.id,
      video_title: live.currentSession.title,
      video_stream_type: live.currentSession.sessionType === 'PRE_RECORDED_SESSION_TYPE' ? 'on-demand' : 'live'
    };
  }

  var liveSession = (0, _react.useMemo)(function () {
    var _live$feeds$;

    return live && live.currentSession && {
      streamingPageUrl: live.currentSession.videoUrl || ((_live$feeds$ = live.feeds[0]) === null || _live$feeds$ === void 0 ? void 0 : _live$feeds$.streamingPageUrl),
      id: live.currentSession.id,
      startTime: live.currentSession.start,
      endTime: live.currentSession.end,
      type: live.currentSession.sessionType
    };
  }, [live]);

  var screensForTheRunningEvents = function screensForTheRunningEvents() {
    if (isLoading) {
      return _react.default.createElement(_loadingView.default, {
        key: "loading"
      });
    }

    if (!isLoading && !live && isLoaded) {
      return _react.default.createElement(_invalidSessionView.default, {
        key: "invalid"
      });
    }

    if (live && live.currentSession) {
      if (live.currentSession.isForbidden) {
        return _react.default.createElement(_noAccessView.default, {
          key: "forbidden"
        });
      }

      switch (live.currentSession.sessionType) {
        case _sessionTypeConsts.TIMER_UNTIL_NEXT_SESSION:
          return live.nextSession ? _react.default.createElement(_nextSessionView.default, {
            session: live.nextSession,
            key: "next"
          }) : _react.default.createElement(_sessionFinishedView.default, {
            key: "finish"
          });

        case _sessionTypeConsts.LIVE_BACKSTAGE_SESSION_TYPE:
          return _react.default.createElement(_BackstageLive.default, {
            room: stage.id,
            eventId: eventId,
            key: "backstageStream"
          }, function (stream, streamStarted) {
            return streamStarted ? _react.default.createElement(_Spacing.default, {
              mSpacing: "s"
            }, _react.default.createElement(_BaseVideo.default, {
              src: stream
            })) : _react.default.createElement(_waitingForStreamView.default, {
              role: "backstage"
            });
          });

        case _sessionTypeConsts.LIVE_RTMPS_SESSION_TYPE:
        case _sessionTypeConsts.PRE_RECORDED_SESSION_TYPE:
          return _react.default.createElement(_liveStreamVideoContainer.default, {
            videoEndedContent: _react.default.createElement(_prerecordedVideoFinishedView.default, null),
            live: liveSession,
            muxMetaDataInfo: muxMetaDataInfo,
            key: "currentSession"
          });

        default:
          return null;
      }
    } else {
      if (live && live.nextSession) {
        return _react.default.createElement(_nextSessionView.default, {
          session: live.nextSession,
          key: "next"
        });
      } else {
        return _react.default.createElement(_sessionFinishedView.default, {
          key: "finish"
        });
      }
    }
  };

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    style: Styles.container
  }, _react.default.createElement(_ResponsiveLiveContainer.default, null, _react.default.createElement(_ResponsiveWebVideoContainer.default, null, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.liveVideoContainer
  }, _react.default.createElement(_EventStateForLive.default, {
    beforeEvent: _react.default.createElement(_nextSessionView.default, {
      session: firstSession,
      key: "next"
    }),
    runningEvent: screensForTheRunningEvents(),
    afterEvent: _react.default.createElement(_eventFinishedView.default, {
      key: "finished"
    }),
    loading: _react.default.createElement(_loadingView.default, {
      key: "loading"
    })
  })), _react.default.createElement(_sessionTitleContainer.default, {
    stage: stage,
    dateFormat: event.dateFormat
  })), _react.default.createElement(_livePageMenu.default, {
    live: live
  })), _react.default.createElement(_Spacing.default, {
    pSpacing: ['xl', 'm']
  }, _react.default.createElement(_sessions.default, {
    improvedWithPreview: false,
    showFullSchedule: false,
    showJoinLiveButton: true
  })), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.partnersContainer
  }, _react.default.createElement(_partners.default, null))), _react.default.createElement(_footer.default, null)));
};

var _default = LivePageContainer;
exports.default = _default;