var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.NetworkingEvents = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var NetworkingEvents = function () {
  function NetworkingEvents() {
    (0, _classCallCheck2.default)(this, NetworkingEvents);
  }

  (0, _createClass2.default)(NetworkingEvents, null, [{
    key: "watch",
    value: function watch() {
      return 'WATCH';
    }
  }, {
    key: "unwatch",
    value: function unwatch(key) {
      return "UNWATCH/".concat(key);
    }
  }, {
    key: "join",
    value: function join() {
      return 'JOIN';
    }
  }, {
    key: "leave",
    value: function leave(key) {
      return "LEAVE/".concat(key);
    }
  }, {
    key: "participantsChanged",
    value: function participantsChanged(key) {
      return "PARTICIPANTS_CHANGED/".concat(key);
    }
  }, {
    key: "roomChanged",
    value: function roomChanged(key) {
      return "ROOM_CHANGED/".concat(key);
    }
  }, {
    key: "joinLiveRequest",
    value: function joinLiveRequest(key) {
      return "JOIN_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "cancelLiveRequest",
    value: function cancelLiveRequest(key) {
      return "CANCEL_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "leaveLive",
    value: function leaveLive(key) {
      return "LEAVE_LIVE/".concat(key);
    }
  }, {
    key: "startLive",
    value: function startLive(key) {
      return "START_LIVE/".concat(key);
    }
  }, {
    key: "stopLive",
    value: function stopLive(key) {
      return "STOP_LIVE/".concat(key);
    }
  }, {
    key: "allowLiveRequest",
    value: function allowLiveRequest(key) {
      return "ALLOW_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "declineLiveRequest",
    value: function declineLiveRequest(key) {
      return "DECLINE_LIVE_REQUEST/".concat(key);
    }
  }, {
    key: "kickSpeakerFromLive",
    value: function kickSpeakerFromLive(key) {
      return "KICK_SPEAKER_FROM_LIVE/".concat(key);
    }
  }, {
    key: "startScreenShare",
    value: function startScreenShare(key) {
      return "START_SCREEN_SHARE/".concat(key);
    }
  }, {
    key: "stopScreenShare",
    value: function stopScreenShare(key) {
      return "STOP_SCREEN_SHARE/".concat(key);
    }
  }, {
    key: "muteUnmuteSpeaker",
    value: function muteUnmuteSpeaker(key) {
      return "MUTE_UNMUTE_SPEAKER/".concat(key);
    }
  }, {
    key: "disconnect",
    value: function disconnect() {
      return 'disconnect';
    }
  }, {
    key: "reconnect",
    value: function reconnect() {
      return 'reconnect';
    }
  }, {
    key: "connect",
    value: function connect() {
      return 'connect';
    }
  }]);
  return NetworkingEvents;
}();

exports.NetworkingEvents = NetworkingEvents;

var NetworkingService = function () {
  function NetworkingService(socket) {
    (0, _classCallCheck2.default)(this, NetworkingService);
    this.socket = null;
    this.socket = socket;
  }

  (0, _createClass2.default)(NetworkingService, [{
    key: "watch",
    value: function () {
      var _watch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(data) {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.watch(), data);

              case 2:
                return _context.abrupt("return", _context.sent);

              case 3:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function watch(_x) {
        return _watch.apply(this, arguments);
      }

      return watch;
    }()
  }, {
    key: "unwatch",
    value: function () {
      var _unwatch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(id) {
        return _regenerator.default.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.unwatch(id), null);

              case 2:
                return _context2.abrupt("return", _context2.sent);

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function unwatch(_x2) {
        return _unwatch.apply(this, arguments);
      }

      return unwatch;
    }()
  }, {
    key: "joinRoom",
    value: function () {
      var _joinRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(data) {
        return _regenerator.default.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.join(), data);

              case 2:
                return _context3.abrupt("return", _context3.sent);

              case 3:
              case "end":
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function joinRoom(_x3) {
        return _joinRoom.apply(this, arguments);
      }

      return joinRoom;
    }()
  }, {
    key: "leaveRoom",
    value: function () {
      var _leaveRoom = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(id) {
        return _regenerator.default.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.leave(id), null);

              case 2:
                return _context4.abrupt("return", _context4.sent);

              case 3:
              case "end":
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function leaveRoom(_x4) {
        return _leaveRoom.apply(this, arguments);
      }

      return leaveRoom;
    }()
  }, {
    key: "joinLiveRequest",
    value: function () {
      var _joinLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(id) {
        return _regenerator.default.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _context5.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.joinLiveRequest(id), null);

              case 2:
                return _context5.abrupt("return", _context5.sent);

              case 3:
              case "end":
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function joinLiveRequest(_x5) {
        return _joinLiveRequest.apply(this, arguments);
      }

      return joinLiveRequest;
    }()
  }, {
    key: "cancelLiveRequest",
    value: function () {
      var _cancelLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(id) {
        return _regenerator.default.wrap(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                _context6.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.cancelLiveRequest(id), null);

              case 2:
                return _context6.abrupt("return", _context6.sent);

              case 3:
              case "end":
                return _context6.stop();
            }
          }
        }, _callee6, this);
      }));

      function cancelLiveRequest(_x6) {
        return _cancelLiveRequest.apply(this, arguments);
      }

      return cancelLiveRequest;
    }()
  }, {
    key: "leaveLive",
    value: function () {
      var _leaveLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(id) {
        return _regenerator.default.wrap(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                _context7.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.leaveLive(id), null);

              case 2:
                return _context7.abrupt("return", _context7.sent);

              case 3:
              case "end":
                return _context7.stop();
            }
          }
        }, _callee7, this);
      }));

      function leaveLive(_x7) {
        return _leaveLive.apply(this, arguments);
      }

      return leaveLive;
    }()
  }, {
    key: "startLive",
    value: function () {
      var _startLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(id) {
        return _regenerator.default.wrap(function _callee8$(_context8) {
          while (1) {
            switch (_context8.prev = _context8.next) {
              case 0:
                _context8.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.startLive(id), null);

              case 2:
                return _context8.abrupt("return", _context8.sent);

              case 3:
              case "end":
                return _context8.stop();
            }
          }
        }, _callee8, this);
      }));

      function startLive(_x8) {
        return _startLive.apply(this, arguments);
      }

      return startLive;
    }()
  }, {
    key: "stopLive",
    value: function () {
      var _stopLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(id) {
        return _regenerator.default.wrap(function _callee9$(_context9) {
          while (1) {
            switch (_context9.prev = _context9.next) {
              case 0:
                _context9.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.stopLive(id), null);

              case 2:
                return _context9.abrupt("return", _context9.sent);

              case 3:
              case "end":
                return _context9.stop();
            }
          }
        }, _callee9, this);
      }));

      function stopLive(_x9) {
        return _stopLive.apply(this, arguments);
      }

      return stopLive;
    }()
  }, {
    key: "allowLiveRequest",
    value: function () {
      var _allowLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee10(id, speakerId) {
        return _regenerator.default.wrap(function _callee10$(_context10) {
          while (1) {
            switch (_context10.prev = _context10.next) {
              case 0:
                _context10.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.allowLiveRequest(id), speakerId);

              case 2:
                return _context10.abrupt("return", _context10.sent);

              case 3:
              case "end":
                return _context10.stop();
            }
          }
        }, _callee10, this);
      }));

      function allowLiveRequest(_x10, _x11) {
        return _allowLiveRequest.apply(this, arguments);
      }

      return allowLiveRequest;
    }()
  }, {
    key: "declineLiveRequest",
    value: function () {
      var _declineLiveRequest = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee11(id, speakerId) {
        return _regenerator.default.wrap(function _callee11$(_context11) {
          while (1) {
            switch (_context11.prev = _context11.next) {
              case 0:
                _context11.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.declineLiveRequest(id), speakerId);

              case 2:
                return _context11.abrupt("return", _context11.sent);

              case 3:
              case "end":
                return _context11.stop();
            }
          }
        }, _callee11, this);
      }));

      function declineLiveRequest(_x12, _x13) {
        return _declineLiveRequest.apply(this, arguments);
      }

      return declineLiveRequest;
    }()
  }, {
    key: "kickSpeakerFromLive",
    value: function () {
      var _kickSpeakerFromLive = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee12(id, speakerId) {
        return _regenerator.default.wrap(function _callee12$(_context12) {
          while (1) {
            switch (_context12.prev = _context12.next) {
              case 0:
                _context12.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.kickSpeakerFromLive(id), speakerId);

              case 2:
                return _context12.abrupt("return", _context12.sent);

              case 3:
              case "end":
                return _context12.stop();
            }
          }
        }, _callee12, this);
      }));

      function kickSpeakerFromLive(_x14, _x15) {
        return _kickSpeakerFromLive.apply(this, arguments);
      }

      return kickSpeakerFromLive;
    }()
  }, {
    key: "startScreenShare",
    value: function () {
      var _startScreenShare = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee13(id) {
        return _regenerator.default.wrap(function _callee13$(_context13) {
          while (1) {
            switch (_context13.prev = _context13.next) {
              case 0:
                _context13.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.startScreenShare(id), null);

              case 2:
                return _context13.abrupt("return", _context13.sent);

              case 3:
              case "end":
                return _context13.stop();
            }
          }
        }, _callee13, this);
      }));

      function startScreenShare(_x16) {
        return _startScreenShare.apply(this, arguments);
      }

      return startScreenShare;
    }()
  }, {
    key: "stopScreenShare",
    value: function () {
      var _stopScreenShare = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee14(id) {
        return _regenerator.default.wrap(function _callee14$(_context14) {
          while (1) {
            switch (_context14.prev = _context14.next) {
              case 0:
                _context14.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.stopScreenShare(id), null);

              case 2:
                return _context14.abrupt("return", _context14.sent);

              case 3:
              case "end":
                return _context14.stop();
            }
          }
        }, _callee14, this);
      }));

      function stopScreenShare(_x17) {
        return _stopScreenShare.apply(this, arguments);
      }

      return stopScreenShare;
    }()
  }, {
    key: "muteUnmuteSpeaker",
    value: function () {
      var _muteUnmuteSpeaker = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee15(key, data) {
        return _regenerator.default.wrap(function _callee15$(_context15) {
          while (1) {
            switch (_context15.prev = _context15.next) {
              case 0:
                _context15.next = 2;
                return this.socket.asyncEmit(NetworkingEvents.muteUnmuteSpeaker(key), data);

              case 2:
                return _context15.abrupt("return", _context15.sent);

              case 3:
              case "end":
                return _context15.stop();
            }
          }
        }, _callee15, this);
      }));

      function muteUnmuteSpeaker(_x18, _x19) {
        return _muteUnmuteSpeaker.apply(this, arguments);
      }

      return muteUnmuteSpeaker;
    }()
  }, {
    key: "connect",
    value: function () {
      var _connect = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee16(params) {
        return _regenerator.default.wrap(function _callee16$(_context16) {
          while (1) {
            switch (_context16.prev = _context16.next) {
              case 0:
                _context16.next = 2;
                return this.socket.asyncConnect(params);

              case 2:
                return _context16.abrupt("return", _context16.sent);

              case 3:
              case "end":
                return _context16.stop();
            }
          }
        }, _callee16, this);
      }));

      function connect(_x20) {
        return _connect.apply(this, arguments);
      }

      return connect;
    }()
  }, {
    key: "disconnect",
    value: function () {
      var _disconnect = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee17() {
        return _regenerator.default.wrap(function _callee17$(_context17) {
          while (1) {
            switch (_context17.prev = _context17.next) {
              case 0:
                _context17.next = 2;
                return this.socket.asyncDisconnect();

              case 2:
                return _context17.abrupt("return", _context17.sent);

              case 3:
              case "end":
                return _context17.stop();
            }
          }
        }, _callee17, this);
      }));

      function disconnect() {
        return _disconnect.apply(this, arguments);
      }

      return disconnect;
    }()
  }, {
    key: "onRoomChange",
    value: function onRoomChange(key, listener) {
      var _this = this;

      this.socket.on(NetworkingEvents.roomChanged(key), listener);
      return function () {
        return _this.socket.off(NetworkingEvents.roomChanged(key));
      };
    }
  }, {
    key: "onParticipantsChange",
    value: function onParticipantsChange(key, listener) {
      var _this2 = this;

      this.socket.on(NetworkingEvents.participantsChanged(key), listener);
      return function () {
        return _this2.socket.off(NetworkingEvents.participantsChanged(key));
      };
    }
  }, {
    key: "onDisconnect",
    value: function onDisconnect(l) {
      var _this3 = this;

      this.socket.on(NetworkingEvents.disconnect(), l);
      return function () {
        return _this3.socket.off(NetworkingEvents.disconnect());
      };
    }
  }, {
    key: "onReconnect",
    value: function onReconnect(l) {
      var _this4 = this;

      this.socket.on(NetworkingEvents.reconnect(), l);
      return function () {
        return _this4.socket.off(NetworkingEvents.reconnect());
      };
    }
  }, {
    key: "onConnect",
    value: function onConnect(l) {
      var _this5 = this;

      this.socket.on(NetworkingEvents.connect(), l);
      return function () {
        return _this5.socket.off(NetworkingEvents.connect());
      };
    }
  }]);
  return NetworkingService;
}();

exports.default = NetworkingService;