Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectChatUserProfileById = exports.selectChatUserProfileIsLoaded = exports.selectChatUserProfileIsLoading = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectChatUsersProfileState = function selectChatUsersProfileState(state) {
  return state[_constants.NAME];
};

var selectChatUserProfileIsLoading = (0, _toolkit.createSelector)(selectChatUsersProfileState, function (state) {
  return state.loading;
});
exports.selectChatUserProfileIsLoading = selectChatUserProfileIsLoading;
var selectChatUserProfileIsLoaded = (0, _toolkit.createSelector)(selectChatUsersProfileState, function (state) {
  return state.loaded;
});
exports.selectChatUserProfileIsLoaded = selectChatUserProfileIsLoaded;

var selectChatUserProfileById = _reducer.chatUserProfilesEntityAdapter.getSelectors(selectChatUsersProfileState).selectById;

exports.selectChatUserProfileById = selectChatUserProfileById;