var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReportView;

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _headingView = _interopRequireDefault(require("design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("design-system/typography/subheading-view"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _react = _interopRequireDefault(require("react"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _AvatarImage = _interopRequireDefault(require("../../common/avatar/AvatarImage"));

var _reportOrBlockStyles = _interopRequireDefault(require("./report-or-block-styles"));

function ReportView(props) {
  var onPressReport = props.onPressReport,
      onPressBlock = props.onPressBlock,
      _props$fullName = props.fullName,
      fullName = _props$fullName === void 0 ? '' : _props$fullName,
      message = props.message,
      avatar = props.avatar,
      loggedUser = props.loggedUser,
      userId = props.userId;
  var navigation = (0, _useNavigationExtended.default)();
  var nameInitials = fullName.split(' ', 2).map(function (i) {
    return i.charAt(0);
  });
  var shouldBlockMe = loggedUser && userId && loggedUser.toString() === userId.toString();
  var shouldBlockMeTitle = 'You cannot block yourself.';
  var reportContentTitle = 'Report content from this \n person as inappropriate';
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_section.default, {
    spacingHorizontal: "loose",
    spacingVertical: "tight"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, message ? _react.default.createElement(_headingView.default, {
    inverted: true,
    textStyle: "center"
  }, 'Report comment as \n inappropriate') : _react.default.createElement(_subheadingView.default, {
    inverted: true,
    textStyle: "center"
  }, shouldBlockMe ? shouldBlockMeTitle : reportContentTitle))), _react.default.createElement(_section.default, {
    spacingVertical: "loose",
    spacingHorizontal: "base"
  }, _react.default.createElement(_Stack.default, {
    vertical: true,
    alignment: "center",
    spacing: "tight"
  }, _react.default.createElement(_AvatarImage.default, {
    size: 48,
    source: {
      uri: avatar
    },
    defaultLabel: nameInitials
  }), _react.default.createElement(_section.default, null, _react.default.createElement(_Stack.default, {
    vertical: true,
    distribution: "center",
    alignment: "center",
    spacing: "tight"
  }, _react.default.createElement(_subheadingView.default, {
    inverted: true,
    textStyle: "strong"
  }, fullName.toString()), message ? _react.default.createElement(_textView.default, {
    inverted: true,
    textStyle: "center"
  }, message.toString()) : _react.default.createElement(_react.default.Fragment, null))))), _react.default.createElement(_iconButton.default, {
    inverted: true,
    icon: "close",
    name: 'goBackTouch',
    onPress: function onPress() {
      return navigation.goBack();
    },
    style: _reportOrBlockStyles.default.closeButton
  }), !shouldBlockMe ? _react.default.createElement(_section.default, {
    spacing: "base",
    spacingBottom: "loose"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_button.default, {
    inverted: true,
    fixedWidth: 120,
    size: "large",
    name: "reportButton",
    onPress: onPressReport
  }, "Report"), _react.default.createElement(_button.default, {
    inverted: true,
    fixedWidth: 120,
    size: "large",
    name: "reportButton",
    onPress: onPressBlock
  }, "Block"))) : _react.default.createElement(_react.default.Fragment, null));
}