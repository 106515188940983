var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _textView = _interopRequireDefault(require("./text-view"));

var _typographyStyles = _interopRequireDefault(require("./typography-styles"));

var _CustomIcon = _interopRequireDefault(require("../../src/assets/icons/CustomIcon"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function TextWithIcon(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors;

  var containerStyle = [_typographyStyles.default.textWithIcon, props.style];
  var textStyles = [{
    color: colors.neutralLightest
  }, _typographyStyles.default.text, props.innerTextStyle];

  if (props.inverted) {
    textStyles.push({
      color: colors.neutralDarkest
    });
  }

  if (props.textStyle === 'strong') {
    textStyles.push({
      fontWeight: 'bold'
    });
  }

  var iconStyle = [_typographyStyles.default.icon];
  var iconProps = {
    name: props.icon,
    size: 20,
    color: _reactNative.StyleSheet.flatten(textStyles).color
  };

  if (props.iconSize != null) {
    iconProps = _objectSpread(_objectSpread({}, iconProps), {}, {
      size: props.iconSize
    });
  }

  if (props.iconColor) {
    iconProps = _objectSpread(_objectSpread({}, iconProps), {}, {
      color: props.iconColor
    });
  }

  return _react.default.createElement(_reactNative.View, {
    style: containerStyle
  }, props.icon && _react.default.createElement(_CustomIcon.default, (0, _extends2.default)({}, iconProps, {
    style: iconStyle
  })), _react.default.createElement(_textView.default, {
    style: textStyles
  }, props.children));
}

var _default = TextWithIcon;
exports.default = _default;