var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _speakerRequest = _interopRequireDefault(require("./speaker-request"));

var _core = require("@material-ui/core");

var _useParticipants = require("../../../../utils/hooks/useParticipants");

var _selectors = require("../../../../core/user-rooms/selectors");

var _actions = require("../../../../core/user-rooms/actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var SpeakerRequestsContainer = function SpeakerRequestsContainer() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var backstageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BACKSTAGE_ID);
  var participantsWithRequest = (0, _reactRedux.useSelector)((0, _selectors.participantsWithRequestSelector)(backstageId));
  var participantsWithRequestArray = Object.entries(participantsWithRequest).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        id = _ref2[0],
        p = _ref2[1];

    return _objectSpread({
      id: Number(id)
    }, p);
  });
  var participants = (0, _useParticipants.useContextParticipants)();

  var admitSpeaker = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(userId) {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              return _context.abrupt("return", dispatch((0, _actions.allowLiveRequest)({
                roomId: backstageId,
                speakerId: userId
              })));

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function admitSpeaker(_x) {
      return _ref3.apply(this, arguments);
    };
  }();

  var denySpeaker = function () {
    var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(userId) {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              return _context2.abrupt("return", dispatch((0, _actions.declineLiveRequest)({
                roomId: backstageId,
                speakerId: userId
              })));

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function denySpeaker(_x2) {
      return _ref4.apply(this, arguments);
    };
  }();

  if (!participantsWithRequestArray.length) return _react.default.createElement(_reactNative.ScrollView, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    showsHorizontalScrollIndicator: false
  }, _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    pSpacing: "l",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('backstage.noRequests'))));
  return _react.default.createElement(_reactNative.ScrollView, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    showsHorizontalScrollIndicator: false
  }, participantsWithRequestArray.map(function (_ref5) {
    var _participants$get;

    var id = _ref5.id,
        name = _ref5.name,
        picture = _ref5.picture;
    return _react.default.createElement(_speakerRequest.default, {
      key: id,
      id: id,
      name: name,
      picture: picture,
      videoTrack: (_participants$get = participants.get(id)) === null || _participants$get === void 0 ? void 0 : _participants$get.videoTrack,
      onAdmit: admitSpeaker,
      onDeny: denySpeaker
    });
  }));
};

var _default = SpeakerRequestsContainer;
exports.default = _default;