var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _appraisal = _interopRequireDefault(require("../../../core/appraisal"));

var _chat = _interopRequireDefault(require("../../../core/chat"));

var _FollowBaseButton = _interopRequireDefault(require("./FollowBaseButton"));

function mapStateToProps(state, ownProps) {
  var id = ownProps.id;
  return {
    inProgress: _appraisal.default.selectors.isFollowFestivalInProgress(state, id),
    isFollowing: _appraisal.default.selectors.isFollowingFestival(state, id),
    festivalId: id.toString(),
    isFollowingFestival: _appraisal.default.selectors.isFollowingFestival(state, id),
    isFromFestival: true
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  follow: _appraisal.default.actions.followFestival,
  unfollow: _appraisal.default.actions.unfollowFestival,
  followFestival: _appraisal.default.actions.followFestival,
  reportMessage: _chat.default.actions.reportMessage
})(_FollowBaseButton.default);

exports.default = _default;