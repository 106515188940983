var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OutlineButton;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _DesignSystem = require("../../DesignSystem");

var useStyles = (0, _core.makeStyles)({
  root: {
    color: function color(_ref) {
      var _color = _ref.color;
      return _color;
    },
    borderColor: function borderColor(_ref2) {
      var color = _ref2.color;
      return color;
    }
  }
});

function OutlineButton(_ref3) {
  var _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? 'button' : _ref3$color,
      children = _ref3.children,
      _ref3$buttonProps = _ref3.buttonProps,
      buttonProps = _ref3$buttonProps === void 0 ? {} : _ref3$buttonProps;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var styles = useStyles({
    color: colors[color]
  });
  return _react.default.createElement(_Button.default, (0, _extends2.default)({
    variant: "outlined",
    className: styles.root
  }, buttonProps), children);
}