var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.tableEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var tableEntityAdapter = (0, _toolkit.createEntityAdapter)({
  sortComparer: function sortComparer(a, b) {
    return a.sort !== undefined && b.sort !== undefined ? a.sort - b.sort : new Date(a.createdDate) > new Date(b.createdDate);
  }
});
exports.tableEntityAdapter = tableEntityAdapter;
var InitialState = {
  tables: tableEntityAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null,
    selectedTable: null
  })
};
var networkingReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getTables.pending, function (state, _) {
  state.tables.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTables.rejected, function (state, _ref) {
  var error = _ref.error;
  state.tables.loading = false;
  state.tables.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTables.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.tables.loaded = true;
  state.tables.loading = false;
  tableEntityAdapter.addMany(state.tables, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.onTablesChanged.type, function (state, _ref3) {
  var payload = _ref3.payload;
  tableEntityAdapter.upsertMany(state.tables, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.onTableChangedById.type, function (state, _ref4) {
  var payload = _ref4.payload;
  tableEntityAdapter.upsertOne(state.tables, payload);
}), _createReducer));
var _default = networkingReducer;
exports.default = _default;