var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ParticipantsCanvasStream;
exports.ENCODER_CONFIG = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _LayoutStrategies = require("../LayoutStrategies");

var _AnimatedLayer = _interopRequireDefault(require("./AnimatedLayer"));

var _CanvasGrid = _interopRequireDefault(require("./CanvasGrid"));

var _CanvasLayout = _interopRequireDefault(require("./CanvasLayout"));

var _CanvasNameStrip = _interopRequireDefault(require("./CanvasNameStrip"));

var _CanvasParticipant = _interopRequireDefault(require("./CanvasParticipant"));

var _CanvasRemainedParticipants = _interopRequireDefault(require("./CanvasRemainedParticipants"));

var _CanvasVideo = _interopRequireDefault(require("./CanvasVideo"));

var _isMobile = _interopRequireDefault(require("is-mobile"));

var ENCODER_CONFIG = {
  '240p': {
    WIDTH: 352,
    HEIGHT: 198,
    FPS: 15
  },
  '360p': {
    WIDTH: 480,
    HEIGHT: 270,
    FPS: 15
  },
  '420p': {
    WIDTH: 640,
    HEIGHT: 360,
    FPS: 15
  },
  '720p': {
    WIDTH: 1280,
    HEIGHT: 720,
    FPS: 30
  },
  '1080p': {
    WIDTH: 1920,
    HEIGHT: 1080,
    FPS: 30
  },
  '1440p': {
    WIDTH: 2560,
    HEIGHT: 1440
  }
};
exports.ENCODER_CONFIG = ENCODER_CONFIG;

function ParticipantsCanvasStream(_ref) {
  var webRtcParticipants = _ref.webRtcParticipants,
      participantsInfo = _ref.participantsInfo,
      stream = _ref.stream,
      screenSharer = _ref.screenSharer,
      _ref$customMessage = _ref.customMessage,
      customMessage = _ref$customMessage === void 0 ? 'Please wait. The host is about to start the stream...' : _ref$customMessage,
      _ref$encoderConfig = _ref.encoderConfig,
      encoderConfig = _ref$encoderConfig === void 0 ? (0, _isMobile.default)() ? ENCODER_CONFIG['240p'] : ENCODER_CONFIG['720p'] : _ref$encoderConfig,
      _ref$extraGraphics = _ref.extraGraphics,
      extraGraphics = _ref$extraGraphics === void 0 ? null : _ref$extraGraphics;
  var WIDTH = encoderConfig.WIDTH,
      HEIGHT = encoderConfig.HEIGHT,
      FPS = encoderConfig.FPS;
  var participants = Object.entries(participantsInfo).sort(function (_ref2, _ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
        aData = _ref4[1];

    var _ref5 = (0, _slicedToArray2.default)(_ref3, 2),
        bData = _ref5[1];

    return new Date(aData.joinedAt) - new Date(bData.joinedAt);
  }).map(function (_ref6) {
    var _ref7 = (0, _slicedToArray2.default)(_ref6, 1),
        id = _ref7[0];

    return {
      id: Number(id),
      screenShareId: Number(screenSharer.id) === Number(id) ? screenSharer.screenShareId : null
    };
  });
  return _react.default.createElement(_reactKonva.Stage, {
    style: {
      position: 'fixed',
      left: -10000,
      top: -10000,
      zIndex: -1,
      opacity: 0
    },
    width: WIDTH,
    height: HEIGHT
  }, _react.default.createElement(_AnimatedLayer.default, {
    fps: FPS,
    stream: stream
  }, _react.default.createElement(_reactKonva.Rect, {
    width: WIDTH,
    height: HEIGHT,
    fill: '#313131'
  }), !participants.length && _react.default.createElement(_reactKonva.Text, {
    fontSize: 36,
    width: 300,
    x: WIDTH / 2 - 150,
    y: HEIGHT / 2 - 36,
    align: "center",
    fill: 'white',
    text: customMessage
  }), _react.default.createElement(_CanvasLayout.default, {
    hasScreenShare: screenSharer.screenShareId,
    containerWidth: WIDTH,
    items: participants
  }, function (grids) {
    return grids.map(function (grid) {
      var webRtcParticipant = webRtcParticipants.get(grid.id);
      var info = participantsInfo[grid.id];

      switch (grid.type) {
        case _LayoutStrategies.GRID_TYPES.PARTICIPANT:
          {
            return _react.default.createElement(_CanvasGrid.default, (0, _extends2.default)({
              key: grid.id
            }, grid), _react.default.createElement(_CanvasParticipant.default, {
              grid: grid,
              hasAudio: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.hasAudio,
              videoTrack: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.videoTrack,
              name: info === null || info === void 0 ? void 0 : info.name,
              hasParticipant: Boolean(webRtcParticipant),
              reconnecting: info === null || info === void 0 ? void 0 : info.isReconnecting,
              picture: info === null || info === void 0 ? void 0 : info.picture
            }));
          }

        case _LayoutStrategies.GRID_TYPES.SCREEN_SHARE:
          {
            var screenInfo = participantsInfo[screenSharer.id];
            return _react.default.createElement(_CanvasGrid.default, (0, _extends2.default)({
              key: grid.id
            }, grid), _react.default.createElement(_CanvasVideo.default, {
              width: grid.width,
              height: grid.height,
              track: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.videoTrack
            }), _react.default.createElement(_CanvasNameStrip.default, {
              name: screenInfo ? "".concat(screenInfo.name, " (screen)") : '(screen)',
              verticalAlign: "bottom",
              horizontalAlign: "left",
              containerHeight: grid.height,
              containerWidth: grid.width
            }));
          }

        case _LayoutStrategies.GRID_TYPES.REMAINED_PARTICIPANTS:
          {
            return _react.default.createElement(_CanvasGrid.default, (0, _extends2.default)({
              key: grid.id
            }, grid), _react.default.createElement(_CanvasRemainedParticipants.default, (0, _extends2.default)({}, grid, {
              count: grid.remainedParticipants.length
            })));
          }

        default:
          {
            return _react.default.createElement(_CanvasGrid.default, (0, _extends2.default)({
              key: grid.id
            }, grid), _react.default.createElement(_CanvasParticipant.default, {
              grid: grid,
              hasAudio: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.hasAudio,
              videoTrack: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.videoTrack,
              name: info === null || info === void 0 ? void 0 : info.name,
              hasParticipant: Boolean(webRtcParticipant),
              reconnecting: info === null || info === void 0 ? void 0 : info.isReconnecting,
              picture: info === null || info === void 0 ? void 0 : info.picture
            }));
          }
      }
    });
  }), extraGraphics));
}