var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalEventItemInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../core/events"));

var _reactNative = require("react-native");

var _FollowUserButton = _interopRequireDefault(require("../../common/follow/FollowUserButton"));

var _SmartDate = _interopRequireDefault(require("../../common/SmartDate"));

var _reactNavigation = require("react-navigation");

var _CustomCachedImage = _interopRequireDefault(require("../../common/CustomCachedImage"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var FestivalEventItemInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalEventItemInternal, _React$PureComponent);

  var _super = _createSuper(FestivalEventItemInternal);

  function FestivalEventItemInternal() {
    (0, _classCallCheck2.default)(this, FestivalEventItemInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(FestivalEventItemInternal, [{
    key: "render",
    value: function render() {
      var _this = this;

      var date = this.props.startDate;
      var imageUrl = this.props.djPicture;
      var djName = this.props.djName;
      var djId = this.props.djId;
      var festivalStageName = this.props.festivalStage;
      var eventId = this.props.eventId;
      return React.createElement(React.Fragment, null, React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TepEventItem' + eventId,
        onPress: function onPress() {
          return _this.props.navigation.push('EventDetail', {
            eventId: eventId
          });
        }
      }, React.createElement(_reactNative.View, {
        style: {
          flex: 1,
          flexDirection: 'row',
          marginVertical: 8,
          justifyContent: 'space-between'
        }
      }, React.createElement(_CustomCachedImage.default, {
        style: {
          width: 80,
          height: 80,
          borderRadius: 4
        },
        source: {
          uri: imageUrl
        }
      }), React.createElement(_reactNative.View, {
        style: {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          paddingHorizontal: 16
        }
      }, React.createElement(_reactNative.Text, {
        style: {
          color: 'white',
          lineHeight: 18,
          fontSize: 15,
          paddingBottom: 8
        }
      }, djName), React.createElement(_SmartDate.default, {
        date: date,
        displayDirection: "row",
        fontSize: 13,
        color: "rgba(255,255,255,0.5)",
        style: {
          lineHeight: 13,
          paddingBottom: 4
        }
      }), React.createElement(_reactNative.Text, {
        style: {
          color: 'rgba(255,255,255,0.5)',
          lineHeight: 13,
          fontSize: 13
        }
      }, festivalStageName)), React.createElement(_reactNative.View, {
        style: {
          justifyContent: 'center'
        }
      }, React.createElement(_FollowUserButton.default, {
        id: djId,
        eventId: eventId,
        buttonSize: 'small',
        style: {
          width: 74,
          paddingHorizontal: 0,
          fontSize: 13,
          lineHeight: 13
        }
      })))));
    }
  }]);
  return FestivalEventItemInternal;
}(React.PureComponent);

exports.FestivalEventItemInternal = FestivalEventItemInternal;

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;
  return {
    startDate: _events.default.selectors.getStartDate(state, eventId),
    djId: _events.default.selectors.getDjId(state, eventId),
    djName: _events.default.selectors.getDjName(state, eventId),
    djPicture: _events.default.selectors.getDjPicture(state, eventId),
    festivalStage: _events.default.selectors.getFestivalStageName(state, eventId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)(FestivalEventItemInternal));

exports.default = _default;