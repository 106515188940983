var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _constants = require("./constants");

var _createReducer;

var initialState = _constants.pollsEntityAdapter.getInitialState({
  loaded: false,
  loading: false,
  error: null
});

var pollsReducer = (0, _toolkit.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getPolls.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPolls.rejected, function (state, _ref) {
  var error = _ref.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPolls.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.addMany(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.savePoll.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.savePoll.rejected, function (state, _ref3) {
  var error = _ref3.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.savePoll.fulfilled, function (state, _ref4) {
  var payload = _ref4.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.addOne(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.deletePoll.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.deletePoll.rejected, function (state, _ref5) {
  var error = _ref5.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.deletePoll.fulfilled, function (state, _ref6) {
  var payload = _ref6.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.removeOne(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.updatePoll.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.updatePoll.rejected, function (state, _ref7) {
  var error = _ref7.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.updatePoll.fulfilled, function (state, _ref8) {
  var payload = _ref8.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.updateOne(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.deleteOption.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.deleteOption.rejected, function (state, _ref9) {
  var error = _ref9.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.deleteOption.fulfilled, function (state, _ref10) {
  var payload = _ref10.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.removeOne(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.castVote.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.castVote.rejected, function (state, _ref11) {
  var error = _ref11.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.castVote.fulfilled, function (state, _ref12) {
  var payload = _ref12.payload;
  state.loaded = true;
  state.loading = false;

  _constants.pollsEntityAdapter.updateOne(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.onPollsChanged.type, function (state, _ref13) {
  var payload = _ref13.payload;
  payload.map(function (poll) {
    if (poll.isArchived) {
      _constants.pollsEntityAdapter.removeOne(state, poll.id);
    } else {
      _constants.pollsEntityAdapter.upsertOne(state, poll);
    }
  });
}), (0, _defineProperty2.default)(_createReducer, _actions.resetPolls.type, function (_, __) {
  return initialState;
}), _createReducer));
var _default = pollsReducer;
exports.default = _default;