var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var yup = _interopRequireWildcard(require("yup"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _Input = _interopRequireDefault(require("../../../theme/components/inputs/Input"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _reactNative = require("react-native");

var _getInitials = _interopRequireDefault(require("../../common/avatar/getInitials"));

var _profileModalStyles = _interopRequireDefault(require("./profile-modal-styles"));

var _reactFeather = require("react-feather");

var _OutlinedButton = _interopRequireDefault(require("../../../theme/components/buttons/OutlinedButton"));

var _TextButton = _interopRequireDefault(require("../../../theme/components/buttons/TextButton"));

var _file = _interopRequireDefault(require("../../../theme/components/inputs/file.web"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-profile/selectors");

var _selectors2 = require("../../../core/user/selectors");

var _actions = require("../../../core/user-profile/actions");

var _reactNativePaperV = require("react-native-paper-v3");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var ProfileModalContent = function ProfileModalContent() {
  var Styles = (0, _profileModalStyles.default)();
  var firebaseUser = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUser);
  var userProfile = (0, _reactRedux.useSelector)(_selectors.selectUserProfile);
  var dispatch = (0, _reactRedux.useDispatch)();

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      visible = _React$useState2[0],
      setVisible = _React$useState2[1];

  var _React$useState3 = _react.default.useState(null),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      previewContent = _React$useState4[0],
      setPreviewContent = _React$useState4[1];

  var phoneRegExp = /^(\+){0,1}((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  function readImageURL(file) {
    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setPreviewContent(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }

  return _react.default.createElement(_Spacing.default, {
    mSpacing: ['l', 'sm'],
    flex: 1,
    alignItems: "flex-start",
    style: Styles.modalContent
  }, _react.default.createElement(_reactNativePaperV.Snackbar, {
    duration: 120,
    visible: visible,
    style: Styles.snackbar
  }, _react.default.createElement(_Typography.default, {
    style: Styles.snackbarText
  }, _i18n.default.t('profile.success'))), _react.default.createElement(_formik.Formik, {
    enableReinitialize: true,
    initialValues: {
      photo: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.photo) || '',
      displayName: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.displayName) || (userProfile === null || userProfile === void 0 ? void 0 : userProfile.fullName) || '',
      headline: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.headline) || '',
      bio: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.bio) || '',
      email: firebaseUser.email || '',
      phone: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.phone) || '',
      linkedin: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.linkedin) || '',
      facebook: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.facebook) || '',
      twitter: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.twitter) || '',
      instagram: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.instagram) || '',
      imageUpload: null
    },
    validationSchema: yup.object().shape({
      displayName: yup.string().trim().required(_i18n.default.t('register.displayNameRequired')).max(50, _i18n.default.t('profile.max50')),
      headline: yup.string().trim().max(100, _i18n.default.t('profile.max100')),
      bio: yup.string().trim().max(800, _i18n.default.t('profile.max800')),
      email: yup.string().trim().email(_i18n.default.t('login.emailValid')).required(_i18n.default.t('login.emailRequired')),
      phone: yup.string().trim().matches(phoneRegExp, _i18n.default.t('profile.phoneInvalid')).max(50, _i18n.default.t('profile.max50')),
      linkedin: yup.string().trim(),
      facebook: yup.string().trim(),
      twitter: yup.string().trim(),
      instagram: yup.string().trim()
    }),
    onSubmit: function onSubmit(values) {
      dispatch((0, _actions.updateUserProfile)(values)).then(function () {
        setVisible(true);
        setTimeout(function () {
          setVisible(false);
        }, 2000);
      });
    }
  }, function (_ref) {
    var values = _ref.values,
        handleChange = _ref.handleChange,
        errors = _ref.errors,
        setFieldTouched = _ref.setFieldTouched,
        setFieldValue = _ref.setFieldValue,
        touched = _ref.touched,
        isValid = _ref.isValid,
        submitForm = _ref.submitForm;
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 'm', 'xl', 'm'],
      direction: "column",
      justifyContent: "center",
      style: Styles.column
    }, _react.default.createElement(_reactNative.Image, {
      style: Styles.avatar,
      source: {
        uri: previewContent || values.photo || "https://via.placeholder.com/128/000000/FFFFFF/?text=".concat((0, _getInitials.default)("".concat(values.displayName)))
      }
    }), _react.default.createElement(_file.default, {
      accept: "image/*",
      parser: function parser(files) {
        var _files$ = files[0],
            name = _files$.name,
            type = _files$.type,
            size = _files$.size;
        setFieldValue('imageUpload', {
          name: name,
          type: type,
          size: size,
          file: files[0]
        });

        if (files && files[0]) {
          readImageURL(files[0]);
        }
      }
    }, function (_, __, inputRef) {
      return values.imageUpload ? _react.default.createElement(_Spacing.default, {
        alignItems: "center",
        justifyContent: "space-between"
      }, _react.default.createElement(_Typography.default, {
        numberOfLines: 1,
        ellipsizeMode: "head",
        style: Styles.newAvatarImageName,
        color: "alternativeText"
      }, values.imageUpload.name || values.imageUpload.type || values.imageUpload.size), _react.default.createElement(_TextButton.default, {
        onPress: function onPress() {
          setFieldValue('imageUpload', null);
          setPreviewContent(null);
        },
        color: "alternativeText"
      }, _react.default.createElement(_reactFeather.Trash, null))) : _react.default.createElement(_OutlinedButton.default, {
        color: errors.imageUpload && touched ? 'errorColor' : 'alternativeText',
        onPress: function onPress() {
          inputRef.current.focus();
          inputRef.current.click();
        },
        spacing: "base"
      }, _react.default.createElement(_Typography.default, {
        variant: "description",
        style: Styles.changeAvatar
      }, _i18n.default.t('profile.changeAvatar')));
    })), _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 'm'],
      direction: "column",
      alignItems: "stretch",
      style: Styles.column
    }, _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: Styles.fieldsSet
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.displayName')), _react.default.createElement(_Input.default, {
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.displayName,
      onChange: handleChange,
      name: "displayName",
      error: !!errors.displayName && touched.displayName,
      onBlur: function onBlur() {
        return setFieldTouched('displayName');
      }
    }), !!errors.displayName && touched.displayName && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.displayName)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: Styles.fieldsSet
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.headline')), _react.default.createElement(_Input.default, {
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.headline,
      onChange: handleChange,
      name: "headline",
      error: !!errors.headline && touched.headline,
      onBlur: function onBlur() {
        return setFieldTouched('headline');
      }
    }), !!errors.headline && touched.headline && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.headline)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: Styles.fieldsSet
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.bio')), _react.default.createElement(_Input.default, {
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.bio,
      onChange: handleChange,
      name: "bio",
      lines: 6,
      error: !!errors.bio && touched.bio,
      onBlur: function onBlur() {
        return setFieldTouched('bio');
      }
    }), !!errors.bio && touched.bio && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.bio)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: Styles.fieldsSet
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.email')), _react.default.createElement(_Input.default, {
      shaped: true,
      disabled: true,
      type: "text",
      color: "alternativeText",
      value: values.email,
      onChange: handleChange,
      name: "email",
      error: !!errors.email && touched.email,
      onBlur: function onBlur() {
        return setFieldTouched('email');
      }
    }), !!errors.email && touched.email && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.email)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: Styles.fieldsSet
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.phone')), _react.default.createElement(_Input.default, {
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.phone,
      onChange: handleChange,
      name: "phone",
      error: !!errors.phone && touched.phone,
      onBlur: function onBlur() {
        return setFieldTouched('phone');
      }
    }), !!errors.phone && touched.phone && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.phone)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: [Styles.doubleField, Styles.fieldsSet]
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.linkedin')), _react.default.createElement(_Input.default, {
      placeholder: _i18n.default.t('profile.linkedinPlaceholder'),
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.linkedin,
      onChange: handleChange,
      name: "linkedin",
      error: !!errors.linkedin && touched.linkedin,
      onBlur: function onBlur() {
        return setFieldTouched('linkedin');
      }
    }), !!errors.linkedin && touched.linkedin && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.linkedin))), _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.facebook')), _react.default.createElement(_Input.default, {
      placeholder: _i18n.default.t('profile.facebookPlaceholder'),
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.facebook,
      onChange: handleChange,
      name: "facebook",
      error: !!errors.facebook && touched.facebook,
      onBlur: function onBlur() {
        return setFieldTouched('facebook');
      }
    }), !!errors.facebook && touched.facebook && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.facebook)))), _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      style: [Styles.doubleField, Styles.fieldsSet]
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.twitter')), _react.default.createElement(_Input.default, {
      placeholder: _i18n.default.t('profile.twitterPlaceholder'),
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.twitter,
      onChange: handleChange,
      name: "twitter",
      error: !!errors.twitter && touched.twitter,
      onBlur: function onBlur() {
        return setFieldTouched('twitter');
      }
    }), !!errors.twitter && touched.twitter && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.twitter))), _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch",
      mSpacing: "base",
      flex: 1
    }, _react.default.createElement(_Typography.default, {
      style: {
        marginBottom: 10,
        fontWeight: 'bold'
      },
      variant: "description"
    }, _i18n.default.t('profile.instagram')), _react.default.createElement(_Input.default, {
      placeholder: _i18n.default.t('profile.instagramPlaceholder'),
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.instagram,
      onChange: handleChange,
      name: "instagram",
      error: !!errors.instagram && touched.instagram,
      onBlur: function onBlur() {
        return setFieldTouched('instagram');
      }
    }), !!errors.instagram && touched.instagram && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: "errorColor"
    }, errors.instagram)))), _react.default.createElement(_Spacing.default, {
      mSpacing: "base",
      style: Styles.saveButton
    }, _react.default.createElement(_ContainedButton.default, {
      onPress: function onPress() {
        if (isValid) {
          submitForm();
        }
      }
    }, _i18n.default.t('register.saveDisplayName')))));
  }));
};

var _default = ProfileModalContent;
exports.default = _default;