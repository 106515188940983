var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleNoInfo;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

function HandleNoInfo(_ref) {
  var hasInfo = _ref.hasInfo,
      onNoInfo = _ref.onNoInfo,
      roomId = _ref.roomId;
  var loaded = (0, _reactRedux.useSelector)((0, _selectors.roomLoadedSelector)(roomId));

  _react.default.useEffect(function () {
    if (!hasInfo && loaded) {
      onNoInfo();
    }
  }, [hasInfo, onNoInfo, loaded]);

  return null;
}