var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readNewMessages = readNewMessages;
exports.readNewMessagesCompleted = readNewMessagesCompleted;
exports.setAutoReadMode = setAutoReadMode;
exports.hasSeenPushNotificationSuggestion = hasSeenPushNotificationSuggestion;
exports.timeSpentInLive = timeSpentInLive;

var _actionTypes = require("./actionTypes");

var _live = _interopRequireDefault(require("../live"));

function readNewMessages(message) {
  return function (dispatch, getState) {
    var state = getState();

    var liveId = _live.default.selectors.getLiveId(state);

    return dispatch(readNewMessagesCompleted(liveId, message.get('key')));
  };
}

function readNewMessagesCompleted(liveId, lastMessageKey) {
  return {
    type: _actionTypes.READ_NEW_MESSAGES,
    liveId: liveId,
    lastMessageKey: lastMessageKey
  };
}

function setAutoReadMode(enabled) {
  return {
    type: _actionTypes.SET_AUTO_READ_MODE,
    enabled: enabled
  };
}

function hasSeenPushNotificationSuggestion() {
  return {
    type: _actionTypes.HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION,
    enabled: true
  };
}

function timeSpentInLive(liveId, timeSpent) {
  return {
    eventId: liveId,
    timeSpent: timeSpent,
    type: _actionTypes.TIME_SPENT_IN_LIVE
  };
}