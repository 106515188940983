var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackstageView;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _backstageViewStyles = _interopRequireDefault(require("./backstage-view-styles"));

var _DynamicSection = _interopRequireDefault(require("../../theme/components/section/DynamicSection"));

var _BackstageWelcomeMessage = _interopRequireDefault(require("../common/audio-video/welcome-messages/BackstageWelcomeMessage"));

var _GreenRoomWelcomeMessage = _interopRequireDefault(require("../common/audio-video/welcome-messages/GreenRoomWelcomeMessage"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../theme/components/buttons/ContainedButton"));

var _ClientState = _interopRequireDefault(require("../common/audio-video/ClientState"));

var _ToggleAudio = _interopRequireDefault(require("../common/audio-video/ToggleAudio"));

var _ToggleVideo = _interopRequireDefault(require("../common/audio-video/ToggleVideo"));

var _SettingsButton = _interopRequireDefault(require("../common/audio-video/SettingsButton"));

var _HandleJoin = _interopRequireDefault(require("../common/audio-video/HandleJoin"));

var _ColoredCircularProgress = _interopRequireDefault(require("../../theme/components/loaders/ColoredCircularProgress"));

var _ToggleScreenShare = _interopRequireDefault(require("../common/audio-video/ToggleScreenShare"));

var _Participants = _interopRequireDefault(require("../common/audio-video/Participants"));

var _LeaveButton = _interopRequireDefault(require("./components/LeaveButton"));

var _ReadyButton = _interopRequireDefault(require("./components/ReadyButton"));

var _DesignSystem = require("../../theme/DesignSystem");

var _LiveStateMarker = _interopRequireDefault(require("./components/LiveStateMarker"));

var _WaitForAllowButton = _interopRequireDefault(require("./components/WaitForAllowButton"));

var _backstageMenu = _interopRequireDefault(require("./menu/backstage-menu"));

var _HandleLeave = _interopRequireDefault(require("../common/audio-video/HandleLeave"));

var _ResponsiveLiveContainer = _interopRequireDefault(require("../live/components/ResponsiveLiveContainer"));

var _ResponsiveWebVideoContainer = _interopRequireDefault(require("../live/components/ResponsiveWebVideoContainer"));

var _BackstageState = _interopRequireDefault(require("./components/BackstageState"));

var _CancelRequestButton = _interopRequireDefault(require("./components/CancelRequestButton"));

var _LeaveLiveButton = _interopRequireDefault(require("./components/LeaveLiveButton"));

var _reactFeather = require("react-feather");

var _featureNamesConstants = require("../../constants/feature-names-constants");

var _ShowComponentByBackstageFeatureAccess = _interopRequireDefault(require("./components/ShowComponentByBackstageFeatureAccess"));

var _StartSessionButton = _interopRequireDefault(require("./components/StartSessionButton"));

var _StopSessionButton = _interopRequireDefault(require("./components/StopSessionButton"));

var _LiveCountDown = _interopRequireDefault(require("./components/LiveCountDown"));

var _AudioVideoDeviceSelectorStep = _interopRequireDefault(require("../common/audio-video/AudioVideoDeviceSelectorStep"));

var _selectors = require("../../core/user-rooms/selectors");

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var _HandleNoInfo = _interopRequireDefault(require("../common/audio-video/HandleNoInfo"));

var _routesConsts = require("../../navigation/client/routes-consts");

var _WelcomeNotification = _interopRequireDefault(require("./components/WelcomeNotification"));

var _OptimizeLocalVideo = _interopRequireDefault(require("../common/audio-video/OptimizeLocalVideo"));

var _OptimizeRemoteVideo = _interopRequireDefault(require("../common/audio-video/OptimizeRemoteVideo"));

var _HandleRequestDecline = _interopRequireDefault(require("./components/HandleRequestDecline"));

var _HandleError = _interopRequireDefault(require("./components/HandleError"));

var _sessionTitleContainer = _interopRequireDefault(require("../live/components/session-title/session-title-container"));

var _sessionsCalendarContainer = _interopRequireDefault(require("../sessions/sessions-calendar-container"));

var _selectors2 = require("../../core/event-stages/selectors");

var _selectors3 = require("../../core/user-roles/selectors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_agoraRtcSdkNg.default.setLogLevel(3);

var ALLOWED_FEATURES = [_featureNamesConstants.START_STOP_LIVE_SESSION, _featureNamesConstants.START_STOP_LIVE_SESSION_BY_STAGE, _featureNamesConstants.START_STOP_LIVE_SESSION_BY_SESSION];

function BackstageView(_ref) {
  var backstageId = _ref.backstageId,
      dateFormat = _ref.dateFormat;
  var Styles = (0, _backstageViewStyles.default)();
  var stage = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors2.selectEventStageWithSessionsById)(state, backstageId);
  });

  var _useSelector = (0, _reactRedux.useSelector)((0, _selectors.accessTokensSelector)(backstageId)),
      token = _useSelector.token,
      uid = _useSelector.id;

  var participantsInfo = (0, _reactRedux.useSelector)((0, _selectors.userRoomParticipants)(backstageId));
  var userIsInGreenRoom = (0, _reactRedux.useSelector)((0, _selectors.userIsInParticipants)(backstageId));
  var canUserMuteSpeaker = (0, _reactRedux.useSelector)((0, _selectors3.hasUserFeatureAccessSelector)(_featureNamesConstants.MUTE_UNMUTE_SPEAKER, backstageId));

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing,
      cards = _useTheme.shape.cards;

  var navigation = (0, _useNavigationExtended.default)();
  var isLive = (0, _reactRedux.useSelector)((0, _selectors.isRoomLiveStartedSelector)(backstageId));

  var _useState = (0, _react.useState)(true),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showImReadyTooltip = _useState2[0],
      setShowImReadyTooltip = _useState2[1];

  var roomParticipantIds = (0, _react.useMemo)(function () {
    return Object.keys(participantsInfo).map(Number).filter(function (id) {
      return id !== Number(uid);
    });
  }, [participantsInfo, uid]);

  var loadingPlaceholder = function loadingPlaceholder(key) {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: "s",
      flex: 1,
      direction: "column",
      alignItems: "stretch",
      key: key
    }, _react.default.createElement(_DynamicSection.default, {
      style: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: spacing.xxl
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_ColoredCircularProgress.default, {
        customColor: color,
        size: 120
      });
    }));
  };

  var titleAndSchedule = function titleAndSchedule() {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: ['m', 'none'],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_sessionTitleContainer.default, {
      stage: stage,
      dateFormat: dateFormat
    }), _react.default.createElement(_Spacing.default, {
      pSpacing: ['xl', 'm']
    }, _react.default.createElement(_sessionsCalendarContainer.default, {
      improvedWithPreview: false,
      showFullSchedule: false
    })));
  };

  return _react.default.createElement(_Spacing.default, {
    style: Styles.networkingContainer,
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_HandleLeave.default, null), _react.default.createElement(_OptimizeLocalVideo.default, null), _react.default.createElement(_HandleError.default, {
    roomId: backstageId
  }), _react.default.createElement(_HandleRequestDecline.default, {
    roomId: backstageId
  }), _react.default.createElement(_ClientState.default, {
    disconnectedState: _react.default.createElement(_Spacing.default, {
      mSpacing: "s",
      flex: 1,
      direction: "column",
      alignItems: "stretch",
      key: "disconnected"
    }, _react.default.createElement(_DynamicSection.default, {
      style: {
        flex: 1,
        padding: spacing.xxl,
        justifyContent: 'center'
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_WelcomeNotification.default, {
        id: "BackstageWelcome|".concat(uid, "|").concat(backstageId),
        notification: function notification(close) {
          return _react.default.createElement(_BackstageWelcomeMessage.default, {
            color: color,
            action: _react.default.createElement(_ContainedButton.default, {
              onPress: close,
              iconRight: _react.default.createElement(_reactFeather.ArrowRight, {
                style: {
                  paddingLeft: 10
                }
              }),
              size: "large",
              style: {
                minWidth: 200
              }
            }, _i18n.default.translate('backstage.goToBackstage'))
          });
        }
      }, _react.default.createElement(_AudioVideoDeviceSelectorStep.default, {
        withSidebar: false
      }, _react.default.createElement(_HandleJoin.default, {
        room: backstageId,
        uid: uid,
        token: token
      }, function (_ref2) {
        var isQuerying = _ref2.isQuerying,
            onJoin = _ref2.onJoin;
        return _react.default.createElement(_ContainedButton.default, {
          onPress: onJoin,
          disabled: isQuerying || !token,
          iconRight: _react.default.createElement(_reactFeather.ArrowRight, {
            style: {
              paddingLeft: 10
            }
          }),
          size: "large",
          style: {
            minWidth: 200
          }
        }, _i18n.default.translate('backstage.goToBackstage'));
      })));
    }), titleAndSchedule()),
    connectedState: _react.default.createElement(_react.default.Fragment, {
      key: "connected"
    }, _react.default.createElement(_ResponsiveLiveContainer.default, null, _react.default.createElement(_ResponsiveWebVideoContainer.default, null, _react.default.createElement(_OptimizeRemoteVideo.default, {
      participants: roomParticipantIds
    }), _react.default.createElement(_HandleNoInfo.default, {
      roomId: backstageId,
      hasInfo: participantsInfo[uid],
      onNoInfo: function onNoInfo() {
        navigation.navigate(_routesConsts.Routes.LOBBY);
      }
    }), _react.default.createElement(_Spacing.default, {
      mSpacing: "s",
      flex: 1,
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_WelcomeNotification.default, {
      id: "GreenRoomWelcome|".concat(uid, "|").concat(backstageId),
      showInitially: userIsInGreenRoom,
      notification: function notification(close) {
        return _react.default.createElement(_GreenRoomWelcomeMessage.default, null, _react.default.createElement(_ContainedButton.default, {
          onPress: close,
          iconRight: _react.default.createElement(_reactFeather.ArrowRight, {
            style: {
              paddingLeft: 10
            }
          }),
          size: "large",
          style: {
            minWidth: 200
          }
        }, _i18n.default.translate('backstage.continueToGreenRoom')));
      }
    }, _react.default.createElement(_DynamicSection.default, {
      shaping: "cards",
      darkColor: userIsInGreenRoom ? '#008000' : '#313131',
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Grid, {
        container: true,
        style: _objectSpread(_objectSpread({}, cards), {}, {
          backgroundColor: '#101010'
        })
      }, _react.default.createElement(_Spacing.default, {
        style: [Styles.colBar, Styles.stateBar],
        justifyContent: "flex-start"
      }, _react.default.createElement(_core.Box, {
        ml: 4
      }, _react.default.createElement(_Spacing.default, null, _react.default.createElement(_LiveStateMarker.default, {
        live: isLive && !userIsInGreenRoom
      })))), _react.default.createElement(_Spacing.default, {
        style: [Styles.colBar, Styles.colActions],
        justifyContent: "center"
      }, _react.default.createElement(_core.Box, {
        m: 1,
        display: "flex",
        flexDirection: "row"
      }, _react.default.createElement(_ToggleAudio.default, {
        color: color,
        roomId: backstageId,
        speakerId: uid
      }), _react.default.createElement(_Spacing.default, {
        style: Styles.spacer
      }), _react.default.createElement(_ToggleVideo.default, {
        color: color
      }), _react.default.createElement(_Spacing.default, {
        style: Styles.spacer
      }), _react.default.createElement(_SettingsButton.default, {
        iconButton: true,
        color: color
      }), _react.default.createElement(_Spacing.default, {
        style: Styles.spacer
      }), _react.default.createElement(_ToggleScreenShare.default, {
        room: backstageId,
        color: color
      }))), _react.default.createElement(_Spacing.default, {
        style: [Styles.colBar, Styles.colOptions],
        justifyContent: "flex-end"
      }, _react.default.createElement(_Spacing.default, {
        style: Styles.spacerEnd,
        display: "flex",
        flexDirection: "row"
      }, _react.default.createElement(_BackstageState.default, {
        backstageId: backstageId,
        greenRoomState: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ReadyButton.default, {
          tooltip: showImReadyTooltip,
          hideTooltip: function hideTooltip() {
            return setShowImReadyTooltip(false);
          }
        }), _react.default.createElement(_Spacing.default, {
          style: Styles.spacer
        }), _react.default.createElement(_LeaveButton.default, null)),
        requestState: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_WaitForAllowButton.default, null), _react.default.createElement(_Spacing.default, {
          style: Styles.spacer
        }), _react.default.createElement(_CancelRequestButton.default, null)),
        liveRoomState: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ShowComponentByBackstageFeatureAccess.default, {
          backstageId: backstageId,
          allowedFeatures: ALLOWED_FEATURES
        }, _react.default.createElement(_StartSessionButton.default, null)), _react.default.createElement(_Spacing.default, {
          style: Styles.spacer
        }), _react.default.createElement(_LeaveLiveButton.default, null)),
        liveLoadingState: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ShowComponentByBackstageFeatureAccess.default, {
          backstageId: backstageId,
          allowedFeatures: ALLOWED_FEATURES
        }, _react.default.createElement(_StartSessionButton.default, {
          disabled: true
        })), _react.default.createElement(_Spacing.default, {
          style: Styles.spacer
        }), _react.default.createElement(_LeaveLiveButton.default, null), _react.default.createElement(_LiveCountDown.default, {
          backstageId: backstageId
        })),
        liveState: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ShowComponentByBackstageFeatureAccess.default, {
          backstageId: backstageId,
          allowedFeatures: ALLOWED_FEATURES
        }, _react.default.createElement(_StopSessionButton.default, null)), _react.default.createElement(_Spacing.default, {
          style: Styles.spacer
        }), _react.default.createElement(_LeaveLiveButton.default, null))
      })))), _react.default.createElement(_Participants.default, {
        roomId: backstageId,
        participantsInfo: participantsInfo,
        uid: uid,
        canUserMuteSpeaker: canUserMuteSpeaker
      }));
    })), titleAndSchedule())), _react.default.createElement(_backstageMenu.default, {
      key: backstageId,
      backstageId: backstageId
    }))),
    connectingState: loadingPlaceholder('connecting'),
    disconnectingState: loadingPlaceholder('disconnecting'),
    reconnectingOverlay: loadingPlaceholder('reconnecting')
  }));
}