var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _storyCardStyles = _interopRequireDefault(require("./story-card-styles"));

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _CustomCachedImage = _interopRequireDefault(require("../../../src/components/common/CustomCachedImage"));

var _badge = _interopRequireDefault(require("./badge"));

var _footer = _interopRequireDefault(require("./footer"));

var _section = _interopRequireDefault(require("./section"));

var _actions = _interopRequireDefault(require("./actions"));

function StoryCardView(props) {
  return _react.default.createElement(_reactNative.TouchableOpacity, {
    onPress: props.onPress,
    activeOpacity: 0.9,
    style: [_storyCardStyles.default.container, props.style]
  }, _react.default.createElement(_reactNative.View, {
    testID: props.testID,
    style: _storyCardStyles.default.imageContainer
  }, _react.default.createElement(_reactNative.View, {
    style: _storyCardStyles.default.imageContainer
  }, _react.default.createElement(_CustomCachedImage.default, {
    style: _storyCardStyles.default.itemImage,
    source: {
      uri: props.image
    },
    resizeMode: "cover"
  }), _react.default.createElement(_reactNativeLinearGradient.default, {
    locations: [0, 1],
    colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.3)'],
    style: _storyCardStyles.default.linearGradient
  }))), props.children);
}

StoryCardView.Badge = _badge.default;
StoryCardView.Footer = _footer.default;
StoryCardView.Section = _section.default;
StoryCardView.Actions = _actions.default;
var _default = StoryCardView;
exports.default = _default;