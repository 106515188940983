var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useDownlinkNetworkQuality;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("../../components/common/audio-video/AudioVideoProvider");

function useDownlinkNetworkQuality() {
  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      client = _useAudioVideoContext.client;

  var _React$useState = _react.default.useState(0),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      quality = _React$useState2[0],
      setQuality = _React$useState2[1];

  _react.default.useEffect(function () {
    function onNetworkQualityChange(_ref) {
      var downlinkNetworkQuality = _ref.downlinkNetworkQuality;
      setQuality(downlinkNetworkQuality);
    }

    client.on('network-quality', onNetworkQualityChange);
    return function () {
      client.off('network-quality', onNetworkQualityChange);
    };
  }, [client, setQuality]);

  return quality;
}