Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backstageErrorSelector = exports.backstageJoinInProgress = exports.backstageStateSelector = exports.stateSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var stateSelector = function stateSelector(state) {
  return state[_constants.NAME];
};

exports.stateSelector = stateSelector;
var backstageStateSelector = (0, _toolkit.createSelector)(stateSelector, function (state) {
  return state.backstage;
});
exports.backstageStateSelector = backstageStateSelector;
var backstageJoinInProgress = (0, _toolkit.createSelector)(stateSelector, function (state) {
  return state.loading;
});
exports.backstageJoinInProgress = backstageJoinInProgress;
var backstageErrorSelector = (0, _toolkit.createSelector)(stateSelector, function (state) {
  return state.error;
});
exports.backstageErrorSelector = backstageErrorSelector;