Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    messageBar: {
      backgroundColor: theme.palette.approveBarColor.main,
      padding: '10px 25px'
    }
  };
});
var _default = styles;
exports.default = _default;