Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transitionConfigOnlyOpacity = exports.transitionConfigForAnimations = void 0;

var _reactNative = require("react-native");

var transitionConfigForAnimations = function transitionConfigForAnimations() {
  return {
    transitionSpec: {
      duration: 300,
      easing: _reactNative.Easing.inOut(_reactNative.Easing.ease),
      timing: _reactNative.Animated.timing,
      useNativeDriver: true
    },
    screenInterpolator: function screenInterpolator(sceneProps) {
      var layout = sceneProps.layout,
          position = sceneProps.position,
          scene = sceneProps.scene;
      var index = scene.index;
      var height = layout.initHeight;
      var translateY = position.interpolate({
        inputRange: [index - 1, index, index + 1],
        outputRange: [height, 0, 0]
      });
      var opacity = position.interpolate({
        inputRange: [index - 1, index - 0.99, index],
        outputRange: [0, 1, 1]
      });
      return {
        opacity: opacity,
        transform: [{
          translateY: translateY
        }]
      };
    }
  };
};

exports.transitionConfigForAnimations = transitionConfigForAnimations;

var transitionConfigOnlyOpacity = function transitionConfigOnlyOpacity() {
  return {
    transitionSpec: {
      duration: 300,
      easing: _reactNative.Easing.inOut(_reactNative.Easing.ease),
      timing: _reactNative.Animated.timing
    },
    screenInterpolator: function screenInterpolator(sceneProps) {
      var position = sceneProps.position,
          scene = sceneProps.scene;
      var index = scene.index;
      var opacity = position.interpolate({
        inputRange: [index - 1, index],
        outputRange: [0, 1]
      });
      return {
        opacity: opacity
      };
    }
  };
};

exports.transitionConfigOnlyOpacity = transitionConfigOnlyOpacity;