Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIDEO_ENCODER_CONFIGURATIONS = void 0;
var VIDEO_ENCODER_CONFIGURATIONS = {
  0: {
    name: '480p_high',
    values: {
      width: 854,
      height: 480,
      frameRate: 30,
      bitrateMin: 200,
      bitrateMax: 1000
    }
  },
  1: {
    name: '480p_high',
    values: {
      width: 854,
      height: 480,
      frameRate: 30,
      bitrateMin: 200,
      bitrateMax: 1000
    }
  },
  2: {
    name: '360p_high',
    values: {
      width: 640,
      height: 360,
      frameRate: 30,
      bitrateMin: 200,
      bitrateMax: 800
    }
  },
  3: {
    name: '360p_low',
    values: {
      width: 640,
      height: 360,
      frameRate: 15,
      bitrateMin: 200,
      bitrateMax: 400
    }
  },
  4: {
    name: '240p',
    values: {
      width: 432,
      height: 243,
      frameRate: 15,
      bitrateMin: 100,
      bitrateMax: 250
    }
  },
  5: {
    name: '180p',
    values: {
      width: 320,
      height: 180,
      frameRate: 15,
      bitrateMin: 70,
      bitrateMax: 140
    }
  },
  6: {
    name: '140p',
    values: {
      width: 256,
      height: 144,
      frameRate: 15,
      bitrateMin: 50,
      bitrateMax: 100
    }
  }
};
exports.VIDEO_ENCODER_CONFIGURATIONS = VIDEO_ENCODER_CONFIGURATIONS;