var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SQSAnalytics = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _awsSdk = _interopRequireDefault(require("aws-sdk"));

var _SqsConfig = require("./SqsConfig");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_awsSdk.default.config.update(_SqsConfig.config);

var sqs = new _awsSdk.default.SQS({
  apiVersion: '2012-11-05'
});
var queueURL = ['https://sqs.eu-west-1.amazonaws.com/824676277883/soundmix-', process.env.ENVIRONMENT, '-business-metrics'].join('');

var getSendMessageParams = function getSendMessageParams(eventId) {
  var eventParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var data = {
    type: eventId,
    params: _objectSpread({}, eventParams)
  };
  return {
    MessageBody: JSON.stringify(data),
    QueueUrl: queueURL
  };
};

var sendMessage = function sendMessage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return sqs.sendMessage(params, function () {});
};

var SQSAnalytics = {
  logEvent: function logEvent(eventId) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var data = getSendMessageParams(eventId, params);
    return Promise.resolve();
  }
};
exports.SQSAnalytics = SQSAnalytics;