var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeVideo = _interopRequireDefault(require("react-native-video"));

var _reactNativePaperV = require("react-native-paper-v3");

var _iconButton = _interopRequireDefault(require("../actions/icon-button"));

var _videoStyles = _interopRequireDefault(require("./video-styles"));

var VideoView = _react.default.forwardRef(function (props, ref) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      roundness = _useTheme.roundness,
      colors = _useTheme.colors;

  var videoContainer = [_videoStyles.default.container, {
    borderRadius: roundness
  }, props.style];
  return _react.default.createElement(_reactNative.View, {
    style: videoContainer
  }, _react.default.createElement(_reactNativeVideo.default, {
    ref: ref,
    posterResizeMode: "cover",
    resizeMode: "cover",
    source: {
      uri: props.source
    },
    paused: props.paused,
    onProgress: props.onProgress,
    onEnd: props.onEnd,
    style: [_reactNative.StyleSheet.absoluteFill, {
      borderRadius: roundness
    }, _videoStyles.default.video]
  }), props.paused && _react.default.createElement(_reactNative.Image, {
    source: {
      uri: props.poster
    },
    style: [_reactNative.StyleSheet.absoluteFill, {
      borderRadius: roundness
    }, _videoStyles.default.videoPoster]
  }), _react.default.createElement(_reactNative.TouchableWithoutFeedback, {
    onPress: props.onVideoPress
  }, _react.default.createElement(_reactNative.View, {
    style: _videoStyles.default.overlay
  }, _react.default.createElement(_iconButton.default, {
    icon: props.paused ? 'play' : 'pause',
    color: "transparent",
    iconColor: colors.background,
    noPadding: true,
    size: "large",
    onPress: props.onPlayPress
  }))));
});

var _default = VideoView;
exports.default = _default;