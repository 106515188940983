var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayChunks = arrayChunks;
exports.asyncForEach = asyncForEach;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _isArray = _interopRequireDefault(require("lodash/isArray"));

function arrayChunks(array, chunk_size) {
  if (!array) throw Error("'array' parameter required!");
  if (!chunk_size) throw Error("'chunk_size' parameter required!");
  if (!array.length) return [];
  if (!(0, _isArray.default)(array)) return [];
  return Array(Math.ceil(array.length / chunk_size)).fill().map(function (_, index) {
    return index * chunk_size;
  }).map(function (begin) {
    return array.slice(begin, begin + chunk_size);
  });
}

function asyncForEach(_x, _x2) {
  return _asyncForEach.apply(this, arguments);
}

function _asyncForEach() {
  _asyncForEach = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(array, callback) {
    var index;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            index = 0;

          case 1:
            if (!(index < array.length)) {
              _context.next = 7;
              break;
            }

            _context.next = 4;
            return callback(array[index], index, array);

          case 4:
            index++;
            _context.next = 1;
            break;

          case 7:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _asyncForEach.apply(this, arguments);
}