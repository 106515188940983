var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OptimizeRemoteVideo;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _useDownlinkNetworkQuality = _interopRequireDefault(require("../../../utils/hooks/useDownlinkNetworkQuality"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var EMPTY = [];

function OptimizeRemoteVideo(_ref) {
  var _ref$participants = _ref.participants,
      participants = _ref$participants === void 0 ? EMPTY : _ref$participants;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      client = _useAudioVideoContext.client;

  var quality = (0, _useDownlinkNetworkQuality.default)();

  _react.default.useEffect(function () {
    _logger.default.debug("Network quality change: ".concat(quality));

    participants.forEach(function (id) {
      if (quality > 2) {
        client.setRemoteVideoStreamType(id, 1);
      } else {
        client.setRemoteVideoStreamType(id, 0);
      }
    });
  }, [quality, client, participants]);

  return null;
}