Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_ROLES_HIERARCHY = exports.USER_ROLES = void 0;
var USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ORGANIZER: 'ORGANIZER',
  TEAM_MEMBER: 'TEAM_MEMBER',
  STAGE_MANAGER: 'STAGE_MANAGER',
  HOST: 'HOST',
  SPEAKER: 'SPEAKER',
  AUDIENCE: 'AUDIENCE'
};
exports.USER_ROLES = USER_ROLES;
var USER_ROLES_HIERARCHY = {
  SUPER_ADMIN: 7,
  ORGANIZER: 6,
  TEAM_MEMBER: 5,
  STAGE_MANAGER: 4,
  HOST: 3,
  SPEAKER: 2,
  AUDIENCE: 1
};
exports.USER_ROLES_HIERARCHY = USER_ROLES_HIERARCHY;