var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAgoraDevices = useAgoraDevices;
exports.useAgoraAudioInputDevices = useAgoraAudioInputDevices;
exports.useAgoraVideoInputDevices = useAgoraVideoInputDevices;
exports.useAgoraAudioOutputDevices = useAgoraAudioOutputDevices;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _useSafeState3 = _interopRequireDefault(require("./useSafeState"));

var _logger = _interopRequireDefault(require("../../core/services/logger"));

function useAgoraDevices() {
  var _useSafeState = (0, _useSafeState3.default)((0, _react.useState)([])),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      devices = _useSafeState2[0],
      setDevices = _useSafeState2[1];

  (0, _react.useEffect)(function () {
    function getDevices() {
      return _getDevices.apply(this, arguments);
    }

    function _getDevices() {
      _getDevices = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        var devices;
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _logger.default.info("System requirements: ".concat(_agoraRtcSdkNg.default.checkSystemRequirements()));

                _context.next = 3;
                return _agoraRtcSdkNg.default.getDevices();

              case 3:
                devices = _context.sent;

                _logger.default.debug("Found devices: ".concat(devices.length));

                setDevices(devices);

              case 6:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));
      return _getDevices.apply(this, arguments);
    }

    getDevices();

    _agoraRtcSdkNg.default.onMicrophoneChanged = function () {
      getDevices();
    };

    _agoraRtcSdkNg.default.onCameraChanged = function () {
      getDevices();
    };

    _agoraRtcSdkNg.default.onPlaybackDeviceChanged = function () {
      getDevices();
    };

    return function () {
      _agoraRtcSdkNg.default.onMicrophoneChanged = function () {};

      _agoraRtcSdkNg.default.onCameraChanged = function () {};

      _agoraRtcSdkNg.default.onPlaybackDeviceChanged = function () {};
    };
  }, []);
  return devices;
}

function useAgoraAudioInputDevices() {
  var devices = useAgoraDevices();
  return devices.filter(function (device) {
    return device.kind === 'audioinput';
  });
}

function useAgoraVideoInputDevices() {
  var devices = useAgoraDevices();
  return devices.filter(function (device) {
    return device.kind === 'videoinput';
  });
}

function useAgoraAudioOutputDevices() {
  var devices = useAgoraDevices();
  return devices.filter(function (device) {
    return device.kind === 'audiooutput';
  });
}