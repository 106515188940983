var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useAccessToken;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _actions = require("../../core/user-tokens/actions");

var _selectors = require("../../core/user-tokens/selectors");

function useAccessToken(room, client) {
  var _useSelector = (0, _reactRedux.useSelector)((0, _selectors.userAccessTokenByRoomSelector)(room)),
      access = _useSelector.accessToken,
      id = _useSelector.id;

  var dispatch = (0, _reactRedux.useDispatch)();

  _react.default.useEffect(function () {
    function onTokenExpire() {
      return _onTokenExpire.apply(this, arguments);
    }

    function _onTokenExpire() {
      _onTokenExpire = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        var resultAction, accessToken;
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return dispatch((0, _actions.getRoomAccessToken)(room));

              case 2:
                resultAction = _context.sent;

                if (!_actions.getRoomAccessToken.fulfilled.match(resultAction)) {
                  _context.next = 8;
                  break;
                }

                accessToken = resultAction.payload.data.accessToken;
                _context.next = 7;
                return client.renewToken(accessToken);

              case 7:
                console.log(resultAction);

              case 8:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));
      return _onTokenExpire.apply(this, arguments);
    }

    dispatch((0, _actions.getRoomAccessToken)(room));
    client.on('token-privilege-will-expire', onTokenExpire);
    client.on('token-privilege-did-expire', onTokenExpire);
    return function () {
      client.off('token-privilege-will-expire', onTokenExpire);
      client.off('token-privilege-did-expire', onTokenExpire);
    };
  }, [client, dispatch, room]);

  return [access, id];
}