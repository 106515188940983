var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventStateForLive;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _useIsDateBetween = _interopRequireWildcard(require("../../../utils/hooks/useIsDateBetween"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function EventStateForLive(_ref) {
  var beforeEvent = _ref.beforeEvent,
      runningEvent = _ref.runningEvent,
      afterEvent = _ref.afterEvent,
      loading = _ref.loading;
  var event = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var state = (0, _useIsDateBetween.default)(event.startDate, event.endDate);
  var content = [];

  switch (state) {
    case _useIsDateBetween.STATUS.BEFORE:
      content.push(beforeEvent);
      break;

    case _useIsDateBetween.STATUS.DURING:
      content.push(runningEvent);
      break;

    case _useIsDateBetween.STATUS.AFTER:
      content.push(afterEvent);
      break;

    default:
      content.push(loading);
  }

  return _react.default.createElement(_react.default.Fragment, null, content);
}