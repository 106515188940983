var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeviceSettings;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _core = require("@material-ui/core");

var _useAgoraAudioVideoDevice = require("../../../utils/hooks/useAgoraAudioVideoDevice");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _reactFeather = require("react-feather");

var _DeviceSettingsNotSupportedMessage = _interopRequireDefault(require("./DeviceSettingsNotSupportedMessage"));

var useModalStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      right: theme.spacing.m,
      top: theme.spacing.m
    }
  };
}).buildHook();

function DeviceSettings(_ref) {
  var children = _ref.children,
      onClose = _ref.onClose,
      _ref$dialog = _ref.dialog,
      dialog = _ref$dialog === void 0 ? true : _ref$dialog,
      _ref$smallBoxPadding = _ref.smallBoxPadding,
      smallBoxPadding = _ref$smallBoxPadding === void 0 ? false : _ref$smallBoxPadding;

  var transform = function transform(device) {
    return {
      id: device.deviceId,
      value: device.label
    };
  };

  var audioInputDevices = (0, _useAgoraAudioVideoDevice.useAgoraAudioInputDevices)().map(transform);
  var videoInputDevices = (0, _useAgoraAudioVideoDevice.useAgoraVideoInputDevices)().map(transform);
  var audioOutputDevices = (0, _useAgoraAudioVideoDevice.useAgoraAudioOutputDevices)().map(transform);
  var Styles = useModalStyles();

  if (dialog) {
    return _react.default.createElement(_Spacing.default, {
      shaping: "cards",
      pSpacing: "l",
      color: "pageBackground",
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_core.Typography, {
      variant: "h2",
      align: "center"
    }, _i18n.default.t('audiovideoSettings.title')), _react.default.createElement(_Spacing.default, {
      onClick: onClose,
      style: Styles.closeIcon
    }, _react.default.createElement(_reactFeather.X, null)), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), _react.default.createElement(_reactNative.ScrollView, {
      style: {
        paddingLeft: 15,
        paddingRight: 15
      }
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.t('audiovideoSettings.description')), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s', 0]
    }), children({
      audioInputDevices: audioInputDevices,
      videoInputDevices: videoInputDevices,
      audioOutputDevices: audioOutputDevices
    }), _react.default.createElement(_DeviceSettingsNotSupportedMessage.default, null)));
  }

  return _react.default.createElement(_Spacing.default, {
    shaping: "cards",
    pSpacing: smallBoxPadding ? ['s', 0] : ['xl', 'l'],
    color: "pageBackground",
    style: {
      maxWidth: 900,
      minWidth: 350
    },
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_reactNative.ScrollView, {
    style: {
      paddingLeft: 15,
      paddingRight: 15
    }
  }, children({
    audioInputDevices: audioInputDevices,
    videoInputDevices: videoInputDevices,
    audioOutputDevices: audioOutputDevices
  }), _react.default.createElement(_DeviceSettingsNotSupportedMessage.default, null)));
}