var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../../core/events"));

var _SimilarBase = _interopRequireDefault(require("./SimilarBase"));

function mapStateToProps(state, ownProps) {
  var djId = ownProps.djId;

  var upcomingEvents = _events.default.selectors.getDjUpcomingShows(state, djId);

  var similarEvents = _events.default.selectors.getSimilarEventsByMusicGenres(state, djId);

  if (upcomingEvents.length === 0) {
    return {
      events: similarEvents,
      sectionTitle: "Shows of Similar DJ's"
    };
  } else {
    return {
      events: upcomingEvents,
      sectionTitle: 'Upcoming Shows'
    };
  }
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(_SimilarBase.default);

exports.default = _default;