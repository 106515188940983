Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var styles = _reactNative.StyleSheet.create({
  sectionShareMore: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#252525',
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 16
  },
  sectionShareMoreText: {
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexShrink: 1,
    overflow: 'hidden'
  },
  textButton: {
    flexWrap: 'wrap'
  },
  iconContainer: {
    marginRight: 16,
    borderRadius: 20,
    width: 40,
    height: 40,
    borderColor: '#fff',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

var _default = styles;
exports.default = _default;