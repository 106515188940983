Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.concatPriceWithCurrency = concatPriceWithCurrency;

function concatPriceWithCurrency(price, currency) {
  var currencySymbol = currency === 'USD' ? '$' : currency === 'EUR' ? '€' : currency;
  var currencyPosition = currency === 'USD' ? 'before' : 'after';
  var delimiter = currency === 'USD' ? '' : ' ';

  switch (currencyPosition) {
    case 'before':
      return "".concat(currencySymbol).concat(delimiter).concat(price);

    case 'after':
      return "".concat(price).concat(delimiter).concat(currencySymbol);

    default:
      return "".concat(price).concat(delimiter).concat(currencySymbol);
  }
}