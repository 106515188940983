var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _core = require("@material-ui/core");

var _liveSessionStyles = _interopRequireDefault(require("../live-session-styles"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _DefaultGifBackground = _interopRequireDefault(require("../../../protected-pages/live-page/components/DefaultGifBackground"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _styles = _interopRequireDefault(require("./styles"));

var EventFinishedView = function EventFinishedView() {
  var styles = (0, _liveSessionStyles.default)();
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_Spacing.default, {
    mSpacing: "s",
    style: {
      overflow: 'hidden',
      borderRadius: spacing.s
    }
  }, _react.default.createElement(_DefaultGifBackground.default, null, _react.default.createElement(_reactNative.View, {
    style: styles.absoluteContainer
  }, _react.default.createElement(_DynamicSection.default, {
    shaped: true,
    shaping: "cards",
    parentBackground: (0, _colorUtils.default)('#FFFFFF', '#FFFFFF', '#000000'),
    style: styles.innerContainer
  }, function (innerTextColor) {
    return _react.default.createElement(_reactNative.View, {
      color: innerTextColor
    }, _react.default.createElement(_core.Typography, {
      variant: "body1",
      color: "inherit",
      align: "center",
      className: classes.textInfo
    }, _i18n.default.t('liveNow.eventFinished')));
  }))));
};

var _default = EventFinishedView;
exports.default = _default;