var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ChatComponentInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _reactNavigation = require("react-navigation");

var _reactRedux = require("react-redux");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _activity = _interopRequireDefault(require("../../core/activity"));

var _appraisal = _interopRequireDefault(require("../../core/appraisal"));

var _chat = _interopRequireDefault(require("../../core/chat"));

var _live = _interopRequireDefault(require("../../core/live"));

var _Analytics = require("../../core/services/Analytics");

var _user = _interopRequireDefault(require("../../core/user"));

var _OutlinedButton = _interopRequireDefault(require("../../theme/components/buttons/OutlinedButton"));

var _Clap = _interopRequireDefault(require("../../theme/components/icons/Clap"));

var _Input = _interopRequireDefault(require("../../theme/components/inputs/Input"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _TipButton = _interopRequireDefault(require("../common/TipButton"));

var _Paragraph = _interopRequireDefault(require("../common/typography/Paragraph"));

var _LiveShareButtonRedux = _interopRequireDefault(require("../live/LiveShareButtonRedux"));

var _TipTheDjAnimation = _interopRequireDefault(require("../live/TipTheDjAnimation"));

var _CommentsList = _interopRequireDefault(require("./CommentsList"));

var _FloatingFires = _interopRequireDefault(require("./FloatingFires"));

var _style = _interopRequireDefault(require("./style"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var KEYBOARD_SHOW = _reactNative.Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow';
var KEYBOARD_HIDE = _reactNative.Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide';

var ChatComponentInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ChatComponentInternal, _React$PureComponent);

  var _super = _createSuper(ChatComponentInternal);

  function ChatComponentInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, ChatComponentInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.listRef = _react.default.createRef();
    _this.state = {
      comment: '',
      keyboardUp: false,
      minWidth: 0,
      maxWidth: 0,
      inputAnim: new _reactNative.Animated.Value(0),
      newClaps: 0,
      fireAnimation: new _reactNative.Animated.Value(0),
      showBulletAnim: false,
      isReadyFireAnim: true
    };
    _this.pendingCloseFires = null;

    _this.trackAnalyticsEvents = function (actionType) {
      var _this$props = _this.props,
          djName = _this$props.djName,
          djId = _this$props.djId,
          festivalName = _this$props.festivalName,
          festivalId = _this$props.festivalId;
      (0, _Analytics.trackMultipleEvents)(['live_show_engagement', djId && "live_show_engagement_dj_".concat(djName, "_").concat(djId), festivalId && "live_show_engagement_festival_".concat(festivalName, "_").concat(festivalId), "live_show_engagement_".concat(actionType)]);
    };

    _this.handlePressFireButton = function () {
      _this.props.clap();

      _this.trackAnalyticsEvents('clap');

      _this.setState({
        newClaps: _this.state.newClaps + 1,
        showBulletAnim: true
      });

      _this.clearTimer();

      _this.setTimer();

      if (_this.state.isReadyFireAnim === true) {
        _this._startFireAnimation();
      }
    };

    _this._startFireAnimation = function () {
      _this.setState({
        isReadyFireAnim: false,
        showBulletAnim: true
      });

      _reactNative.Animated.timing(_this.state.fireAnimation, {
        duration: 400,
        useNativeDriver: true,
        toValue: 1,
        easing: _reactNative.Easing.elastic(2)
      }).start(function () {
        _this.setTimer();
      });
    };

    _this._stopFireAnimation = function () {
      _this.setState({
        isReadyFireAnim: true,
        showBulletAnim: false,
        newClaps: 0
      });

      _reactNative.Animated.timing(_this.state.fireAnimation, {
        duration: 300,
        useNativeDriver: true,
        toValue: 0
      }).start();
    };

    _this.setTimer = function () {
      if (_this.pendingCloseFires) {
        return;
      }

      _this.pendingCloseFires = setTimeout(function () {
        _this._stopFireAnimation();

        _this.pendingCloseFires = 0;
      }, 1000);
    };

    _this.clearTimer = function () {
      if (_this.pendingCloseFires) {
        clearTimeout(_this.pendingCloseFires);
        _this.pendingCloseFires = 0;
      }
    };

    _this._keyboardDidShow = function () {
      _reactNative.Animated.timing(_this.state.inputAnim, {
        duration: 300,
        toValue: 1,
        easing: _reactNative.Easing.linear
      }).start();

      _this.setState({
        keyboardUp: true
      });
    };

    _this._keyboardDidHide = function () {
      _reactNative.Animated.timing(_this.state.inputAnim, {
        duration: 300,
        toValue: 0,
        easing: _reactNative.Easing.linear
      }).start();

      _this.setState({
        keyboardUp: false
      });
    };

    _this.handleChangeText = function (comment) {
      _this.setState({
        comment: comment
      });
    };

    _this.handleSubmit = function () {
      if (_this.state.comment === '') {
        return;
      }

      _this.trackAnalyticsEvents('write_comment');

      if (_this.props.eventId) {
        _this.props.sendMessage(_this.state.comment, _this.props.eventId);
      } else {
        _this.props.sendMessage(_this.state.comment);
      }

      _this.setState({
        comment: ''
      });
    };

    _this.handleContainerLayout = function (event) {
      _this.setState({
        maxWidth: event.nativeEvent.layout.width - 20
      });
    };

    _this._renderReportOrBlockActions = function () {
      var _this$props2 = _this.props,
          djId = _this$props2.djId,
          djName = _this$props2.djName,
          djPicture = _this$props2.djPicture;

      var handlePress = function handlePress() {
        return _this.props.navigation.navigate('ReportOrBlockModal', {
          fullName: djName,
          avatar: djPicture,
          userId: djId
        });
      };

      return _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "reportButton",
        borderless: false,
        onPress: handlePress,
        style: _style.default.iconButton
      }, _react.default.createElement(_CustomIcon.default, {
        name: "more",
        size: 24,
        color: "#fff"
      }));
    };

    _this.handleSetMinWidth = function (event) {
      if (!_this.state.minWidth || _this.state.minWidth > event.nativeEvent.layout.width) {
        _this.setState({
          minWidth: event.nativeEvent.layout.width
        });
      }
    };

    _this.handleChangeAutoReadMode = function (enabled) {
      _this.props.setAutoReadMode(enabled);
    };

    _this.handleCloseKeyboard = _reactNative.Keyboard.dismiss;
    return _this;
  }

  (0, _createClass2.default)(ChatComponentInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _reactNative.Keyboard.addListener(KEYBOARD_SHOW, this._keyboardDidShow);

      _reactNative.Keyboard.addListener(KEYBOARD_HIDE, this._keyboardDidHide);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _reactNative.Keyboard.removeListener(KEYBOARD_SHOW, this._keyboardDidShow);

      _reactNative.Keyboard.removeListener(KEYBOARD_HIDE, this._keyboardDidHide);

      if (this.pendingCloseFires) {
        clearTimeout(this.pendingCloseFires);
      }
    }
  }, {
    key: "numberFormatter",
    value: function numberFormatter(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(2).replace(/\.00$/, '') + 'B';
      } else if (num >= 1000000) {
        return (num / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
      } else if (num >= 1000) {
        return (num / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
      } else if (num <= 0) {
        return null;
      }

      return num;
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      if (prevState.keyboardUp !== this.state.keyboardUp && this.listRef.current) {
        this.listRef.current.getWrappedInstance().handleJumpToEnd(false);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;
      var sendButtonOpacity = this.state.inputAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      });
      var inputExtendWidth = this.state.inputAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [this.state.minWidth, this.state.maxWidth]
      });
      return _react.default.createElement(_reactNative.KeyboardAvoidingView, {
        style: [_style.default.KeyboardAvoidingViewContainer, this.props.style],
        behavior: _reactNative.Platform.OS === 'ios' ? 'padding' : null,
        enabled: _reactNative.Platform.OS !== 'web'
      }, _react.default.createElement(_reactNativeSafeAreaView.default, {
        style: [_style.default.container, this.props.style],
        forceInset: {
          top: 'never',
          bottom: 'always'
        }
      }, _react.default.createElement(_reactNative.View, {
        onLayout: this.handleContainerLayout,
        style: {
          flex: 1
        }
      }, _react.default.createElement(_FloatingFires.default, {
        count: this.props.numClaps,
        color: colors.primary
      }), _react.default.createElement(_CommentsList.default, {
        isSameOrientationChat: this.props.isSameOrientationChat,
        ref: this.listRef,
        loggedUserId: this.props.loggedUserId,
        comments: this.props.comments,
        unreadCount: this.props.unreadCount,
        onChangeAutoReadMode: this.handleChangeAutoReadMode
      }), _react.default.createElement(_reactNative.View, {
        style: _style.default.bottomBar
      }, _react.default.createElement(_reactNative.Animated.View, {
        style: [_style.default.textInputWrapper, {
          minWidth: inputExtendWidth
        }]
      }, _react.default.createElement(_Input.default, {
        placeholder: _i18n.default.t('lobby.writeHere'),
        color: "alternativeText",
        style: _style.default.textInputStyle,
        value: this.state.comment,
        onChangeText: this.handleChangeText,
        onSubmitEditing: this.handleSubmit,
        onLayout: this.handleSetMinWidth,
        returnKeyType: "send",
        shaped: true
      }), _reactNative.Platform.OS !== 'web' && _react.default.createElement(_reactNative.Animated.View, {
        style: [_style.default.textInputButtonContainer, {
          opacity: sendButtonOpacity
        }]
      }, _react.default.createElement(_react.default.Fragment, null, !this.state.comment ? _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "closeButton",
        borderless: true,
        style: _style.default.textInputCloseButton,
        onPress: this.handleCloseKeyboard
      }, _react.default.createElement(_CustomIcon.default, {
        name: "close",
        size: 20,
        color: "#fff"
      })) : _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "sendButton",
        borderless: true,
        onPress: this.handleSubmit,
        style: _style.default.textInputSendButton
      }, _react.default.createElement(_Paragraph.default, {
        style: _style.default.textInputSendButtonText
      }, "SEND"))))), _reactNative.Platform.OS === 'android' && _react.default.createElement(_TipButton.default, {
        style: _style.default.tipButton
      }), _reactNative.Platform.OS !== 'web' && _react.default.createElement(_LiveShareButtonRedux.default, {
        style: _style.default.iconButton
      }), _reactNative.Platform.OS !== 'web' && this._renderReportOrBlockActions(), _react.default.createElement(_reactNative.View, null, this.state.showBulletAnim && _react.default.createElement(_reactNative.Animated.View, {
        style: {
          transform: [{
            scale: this.state.fireAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [0.8, 1]
            })
          }],
          opacity: this.state.fireAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1]
          })
        }
      }, _react.default.createElement(_reactNative.View, {
        style: [_style.default.clapsNrContainer, {
          backgroundColor: colors.primary
        }]
      }, _react.default.createElement(_reactNative.Text, {
        style: _style.default.clapsNrText
      }, "+", this.state.newClaps))), !this.state.showBulletAnim && _react.default.createElement(_reactNative.View, {
        style: _style.default.clapsNumber
      }, _react.default.createElement(_reactNative.Text, {
        style: {
          color: colors.black,
          fontSize: 12
        }
      }, this.numberFormatter(this.props.numClaps))), _reactNative.Platform.OS === 'web' ? _react.default.createElement(_OutlinedButton.default, {
        iconLeft: _react.default.createElement(_Clap.default, null),
        color: "alternativeText",
        onPress: this.handlePressFireButton
      }) : _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "touchFloatingFireButton",
        borderless: false,
        onPress: this.handlePressFireButton,
        style: _style.default.clapsButton,
        rippleColor: "".concat(colors.primary, "50")
      }, _react.default.createElement(_reactNative.Image, {
        style: {
          width: 24,
          height: 24
        },
        source: {
          uri: 'https://firebasestorage.googleapis.com/v0/b/dev-eventmix.appspot.com/o/assets%2Fclap.svg?alt=media&token=9a7d8793-3553-4a33-9e53-52f7ab066c77'
        },
        resizeMode: "contain"
      })))), _react.default.createElement(_TipTheDjAnimation.default, null))));
    }
  }]);
  return ChatComponentInternal;
}(_react.default.PureComponent);

exports.ChatComponentInternal = ChatComponentInternal;

function mapStateToProps(state) {
  return {
    comments: _chat.default.selectors.getMessages(state),
    unreadCount: _activity.default.selectors.getUnreadMessagesCount(state),
    numClaps: _appraisal.default.selectors.getNumClaps(state),
    djId: _live.default.selectors.getDjId(state),
    loggedUserId: _user.default.selectors.getFirebaseUserUid(state),
    djName: _live.default.selectors.getDjName(state),
    djPicture: _live.default.selectors.getDjPicture(state),
    festivalId: _live.default.selectors.getFestivalId(state),
    festivalName: _live.default.selectors.getFestivalName(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  sendMessage: _chat.default.actions.sendMessage,
  setAutoReadMode: _activity.default.actions.setAutoReadMode,
  clap: _appraisal.default.actions.clap
})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(ChatComponentInternal)));

exports.default = _default;