var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onChatReactionsChangedById = exports.subscribeChatReactionsChangeById = exports.increaseChatReaction = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _selectors = require("../event-info/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var increaseChatReaction = (0, _toolkit.createAsyncThunk)(_constants.INCREASE_CHAT_REACTION, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(chatRoomId, _ref) {
    var getState, eventId;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            _context.prev = 1;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context.next = 5;
              break;
            }

            throw Error('No event!');

          case 5:
            _context.next = 7;
            return _reactNativeFirebase.default.firestore().collection('events').doc(eventId).collection('chat-reactions').doc(chatRoomId).set({
              clapsCount: _reactNativeFirebase.default.firestore.FieldValue.increment(1)
            }, {
              merge: true
            });

          case 7:
            _context.next = 12;
            break;

          case 9:
            _context.prev = 9;
            _context.t0 = _context["catch"](1);
            console.log(_context.t0);

          case 12:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[1, 9]]);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.increaseChatReaction = increaseChatReaction;
var subscribeChatReactionsChangeById = (0, _toolkit.createAsyncThunk)(_constants.SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(chatRoomId, _ref3) {
    var getState, dispatch, eventId, unsubscribe;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            getState = _ref3.getState, dispatch = _ref3.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context2.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context2.next = 6;
            return new Promise(function (resolve, reject) {
              resolve(_reactNativeFirebase.default.firestore().collection('events').doc(eventId).collection('chat-reactions').doc(chatRoomId).onSnapshot(function (snapShot) {
                return dispatch(onChatReactionsChangedById(_objectSpread({
                  id: snapShot.id
                }, snapShot.data())));
              }, function (err) {
                return reject(err);
              }));
            });

          case 6:
            unsubscribe = _context2.sent;
            return _context2.abrupt("return", unsubscribe);

          case 8:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.subscribeChatReactionsChangeById = subscribeChatReactionsChangeById;
var onChatReactionsChangedById = (0, _toolkit.createAction)(_constants.ON_CHAT_REACTIONS_CHANGED_BY_ID);
exports.onChatReactionsChangedById = onChatReactionsChangedById;