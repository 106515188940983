var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ButtonView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _CustomIcon = _interopRequireDefault(require("../../../src/assets/icons/CustomIcon"));

var _buttonStyles = _interopRequireDefault(require("./button-styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function ButtonView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors;

  var btnStyle = [_buttonStyles.default.heading, props.style];
  var labelStyle = [{
    letterSpacing: 0
  }];
  var btnProps = {
    mode: 'outlined',
    color: colors.neutralLightest,
    uppercase: false,
    theme: {
      dark: false,
      roundness: 4
    },
    loading: false,
    disabled: false,
    icon: undefined,
    onPress: props.onPress
  };

  if (props.icon) {
    btnProps.icon = function (_ref) {
      var size = _ref.size,
          color = _ref.color;
      var mapSizeStyles = {
        regular: {
          fontSize: size
        },
        slim: _buttonStyles.default.slimButtonIcon,
        large: _buttonStyles.default.largeButtonIcon
      };
      return _react.default.createElement(_CustomIcon.default, {
        name: props.icon || '',
        style: [{
          color: props.primary && props.inverted ? colors.primary : color
        }, props.size ? mapSizeStyles[props.size] : null]
      });
    };
  }

  if (props.inverted) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: colors.neutralDarkest
    });
    btnStyle.push({
      borderColor: colors.neutralDarkest
    });
  }

  if (props.primary) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      mode: 'contained',
      uppercase: false,
      color: colors.primary
    });

    if (props.inverted) {
      labelStyle.push({
        color: colors.primary
      });
      btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
        color: colors.neutralDarkest
      });
    }

    if (props.disabled) {
      btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
        color: colors.neutralDarkest
      });
      labelStyle.push({
        color: colors.neutral
      });
    }
  }

  if (props.secondary) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      mode: 'contained',
      uppercase: false,
      color: colors.neutralLightest
    });
  }

  if (props.textButton) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      mode: 'text',
      uppercase: false,
      color: colors.neutralLightest
    });
  }

  if (props.pressed) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      mode: 'contained',
      color: colors.neutral
    });
    labelStyle.push({
      color: colors.neutralLightest
    });
  }

  if (props.fullWidth) {
    btnProps = _objectSpread({}, btnProps);
    btnStyle.push({
      width: '100%'
    });
  }

  if (props.fixedWidth !== undefined) {
    btnProps = _objectSpread({}, btnProps);
    btnStyle.push({
      width: props.fixedWidth
    });
  }

  if (props.loading) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      loading: true
    });
  }

  if (props.disabled || props.loading) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      disabled: true
    });
    btnStyle.push({
      borderColor: colors.neutralLightestOpacity02
    });
  }

  if (props.destructive) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: colors.destructive
    });
    btnStyle.push({
      borderColor: colors.destructive
    });
  }

  if (props.size === 'slim') {
    btnProps = _objectSpread({}, btnProps);
    labelStyle.push({
      lineHeight: 12,
      fontSize: 12,
      marginBottom: 8,
      marginHorizontal: 8
    });
  }

  if (props.size === 'large') {
    btnProps = _objectSpread({}, btnProps);
    labelStyle.push({
      lineHeight: 30,
      fontSize: 18,
      fontWeight: '500',
      color: colors.neutralDarkest
    });
  }

  if (props.fontSize) {
    btnProps = _objectSpread({}, btnProps);
    labelStyle.push({
      fontSize: props.fontSize,
      fontWeight: '500'
    });
  }

  if (props.color) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: props.color
    });
  }

  if (props.textColor) {
    btnProps = _objectSpread({}, btnProps);
    labelStyle.push({
      color: props.textColor
    });
  }

  return _react.default.createElement(_reactNativePaperV.Button, (0, _extends2.default)({}, btnProps, {
    labelStyle: labelStyle,
    style: btnStyle
  }), props.children);
}