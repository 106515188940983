var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSessions = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var getSessions = (0, _toolkit.createAsyncThunk)(_constants.GET_SESSIONS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var getState, event, user, getAccessibleSessions, _yield$getAccessibleS, data;

    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            event = (0, _selectors.selectEventInfo)(getState());
            user = (0, _selectors2.getFirebaseUser)(getState());

            if (!(!event || !event.id)) {
              _context.next = 5;
              break;
            }

            throw Error('No event!');

          case 5:
            _context.prev = 5;
            getAccessibleSessions = _reactNativeFirebase.default.functions.httpsCallable('sessions-getAccessibleSessions');
            _context.next = 9;
            return getAccessibleSessions({
              userId: (user === null || user === void 0 ? void 0 : user.uid) || null,
              eventId: event.id
            });

          case 9:
            _yield$getAccessibleS = _context.sent;
            data = _yield$getAccessibleS.data;

            if (!data.error) {
              _context.next = 14;
              break;
            }

            console.log(data.error);
            return _context.abrupt("return", []);

          case 14:
            return _context.abrupt("return", data.sessions);

          case 17:
            _context.prev = 17;
            _context.t0 = _context["catch"](5);
            console.log(_context.t0);
            return _context.abrupt("return", []);

          case 21:
          case "end":
            return _context.stop();
        }
      }
    }, _callee, null, [[5, 17]]);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getSessions = getSessions;