var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBoughtTickets = exports.getTicketRights = exports.deleteTicketOnHold = exports.getTicketOnHold = exports.setTicketOnHold = exports.getTickets = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _arrayUtils = require("../../utils/array-utils");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _actions = require("../ticket-addons/actions");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var _firebaseCollections = require("../../../functions/constants/firebase-collections");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getTickets = (0, _toolkit.createAsyncThunk)(_constants.GET_TICKETS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(uid, _ref) {
    var getState, dispatch, userId, tickets;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState, dispatch = _ref.dispatch;
            userId = (0, _selectors2.getFirebaseUserUid)(getState()) || uid;

            if (userId) {
              _context.next = 4;
              break;
            }

            throw Error('No logged in user!');

          case 4:
            _context.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.tickets(userId));

          case 6:
            tickets = _context.sent;
            dispatch((0, _actions.getAddons)(uid));
            dispatch(getTicketRights(tickets.map(function (_ref3) {
              var id = _ref3.id,
                  ticketId = _ref3.ticketId,
                  createdDate = _ref3.createdDate;
              return {
                id: id,
                ticketId: ticketId,
                createdDate: createdDate
              };
            })));
            return _context.abrupt("return", tickets);

          case 10:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getTickets = getTickets;
var setTicketOnHold = (0, _toolkit.createAsyncThunk)(_constants.SET_TICKET_ON_HOLD, function () {
  var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(ticketId, _ref4) {
    var getState, dispatch, userId, eventId, tickets, checkTicketOnHold, result, eventTickets;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            getState = _ref4.getState, dispatch = _ref4.dispatch;
            userId = (0, _selectors2.getFirebaseUserUid)(getState());

            if (userId) {
              _context2.next = 4;
              break;
            }

            throw Error('No logged in user!');

          case 4:
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context2.next = 7;
              break;
            }

            throw Error('No event!');

          case 7:
            _context2.prev = 7;
            _context2.next = 10;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.eventTickets(eventId));

          case 10:
            tickets = _context2.sent;
            _context2.next = 13;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).get();

          case 13:
            checkTicketOnHold = _context2.sent;
            _context2.next = 16;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).set({
              user: userId,
              quantity: 1,
              ticket: tickets.map(function (_ref6) {
                var id = _ref6.id;
                return {
                  ticketId: ticketId,
                  id: id
                };
              })
            });

          case 16:
            _context2.next = 18;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).get();

          case 18:
            result = _context2.sent;

            if (!(result.exists !== checkTicketOnHold.exists)) {
              _context2.next = 24;
              break;
            }

            _context2.next = 22;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.eventTickets(eventId).where('id', '==', ticketId));

          case 22:
            eventTickets = _context2.sent;

            _firebaseUtils.firebaseEntities.eventTickets(eventId).doc(ticketId).set({
              available: eventTickets[0].available - 1
            }, {
              merge: true
            });

          case 24:
            return _context2.abrupt("return", result);

          case 27:
            _context2.prev = 27;
            _context2.t0 = _context2["catch"](7);
            return _context2.abrupt("return", '');

          case 30:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2, null, [[7, 27]]);
  }));

  return function (_x3, _x4) {
    return _ref5.apply(this, arguments);
  };
}());
exports.setTicketOnHold = setTicketOnHold;
var getTicketOnHold = (0, _toolkit.createAsyncThunk)(_constants.GET_TICKET_ON_HOLD, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(uid, _ref7) {
    var getState, userId, tickets;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            getState = _ref7.getState;
            userId = (0, _selectors2.getFirebaseUserUid)(getState()) || uid;

            if (userId) {
              _context3.next = 4;
              break;
            }

            throw Error('No logged in user!');

          case 4:
            _context3.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.ticketsOnHold(userId));

          case 6:
            tickets = _context3.sent;
            return _context3.abrupt("return", tickets);

          case 8:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref8.apply(this, arguments);
  };
}());
exports.getTicketOnHold = getTicketOnHold;
var deleteTicketOnHold = (0, _toolkit.createAsyncThunk)(_constants.DELETE_TICKET_ON_HOLD, function () {
  var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(ticketId, _ref9) {
    var getState, userId, eventId, checkTicketOnHold, result, eventTickets;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            getState = _ref9.getState;
            userId = (0, _selectors2.getFirebaseUserUid)(getState());

            if (userId) {
              _context4.next = 4;
              break;
            }

            throw Error('No logged in user!');

          case 4:
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context4.next = 7;
              break;
            }

            throw Error('No event!');

          case 7:
            _context4.prev = 7;
            _context4.next = 10;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).get();

          case 10:
            checkTicketOnHold = _context4.sent;
            _context4.next = 13;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).delete();

          case 13:
            _context4.next = 15;
            return _firebaseUtils.firebaseEntities.ticketsOnHold(userId).doc(ticketId).get();

          case 15:
            result = _context4.sent;

            if (!(result.exists !== checkTicketOnHold.exists)) {
              _context4.next = 21;
              break;
            }

            _context4.next = 19;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.eventTickets(eventId).where('id', '==', ticketId));

          case 19:
            eventTickets = _context4.sent;

            _firebaseUtils.firebaseEntities.eventTickets(eventId).doc(ticketId).set({
              available: eventTickets[0].available + 1
            }, {
              merge: true
            });

          case 21:
            return _context4.abrupt("return", result);

          case 24:
            _context4.prev = 24;
            _context4.t0 = _context4["catch"](7);
            return _context4.abrupt("return", '');

          case 27:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4, null, [[7, 24]]);
  }));

  return function (_x7, _x8) {
    return _ref10.apply(this, arguments);
  };
}());
exports.deleteTicketOnHold = deleteTicketOnHold;
var getTicketRights = (0, _toolkit.createAsyncThunk)(_constants.GET_TICKET_RIGHTS, function () {
  var _ref12 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(ticketIds, _ref11) {
    var getState, eventId, eventTickets, ticketIdsMap;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            getState = _ref11.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context6.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            if (ticketIds.length) {
              _context6.next = 6;
              break;
            }

            return _context6.abrupt("return", []);

          case 6:
            eventTickets = [];
            _context6.next = 9;
            return (0, _arrayUtils.asyncForEach)((0, _arrayUtils.arrayChunks)(ticketIds.map(function (ticket) {
              return ticket.ticketId;
            }), 10), function () {
              var _ref13 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(idsChunk) {
                var tickets;
                return _regenerator.default.wrap(function _callee5$(_context5) {
                  while (1) {
                    switch (_context5.prev = _context5.next) {
                      case 0:
                        _context5.next = 2;
                        return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.eventTickets(eventId).where('id', 'in', idsChunk));

                      case 2:
                        tickets = _context5.sent;
                        eventTickets.push.apply(eventTickets, (0, _toConsumableArray2.default)(tickets));

                      case 4:
                      case "end":
                        return _context5.stop();
                    }
                  }
                }, _callee5);
              }));

              return function (_x11) {
                return _ref13.apply(this, arguments);
              };
            }());

          case 9:
            ticketIdsMap = ticketIds.reduce(function (accum, ticket) {
              return _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, ticket.ticketId, ticket.id));
            }, {});
            return _context6.abrupt("return", eventTickets.map(function (ticket) {
              return {
                id: ticketIdsMap[ticket.id],
                isPending: ticket.isPending,
                changes: {
                  rights: {
                    selectedDays: ticket.selectedDays,
                    selectedStages: ticket.selectedStages,
                    selectedAddons: ticket.selectedAddons,
                    showAllRecordedSessions: ticket.showAllRecordedSessions,
                    selectedAllDays: ticket.selectedAllDays,
                    selectedAllAddons: ticket.selectedAllAddons
                  },
                  description: ticket.description,
                  name: ticket.name,
                  price: ticket.price,
                  startDate: ticket.startDate,
                  endDate: ticket.endDate
                }
              };
            }));

          case 11:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x9, _x10) {
    return _ref12.apply(this, arguments);
  };
}());
exports.getTicketRights = getTicketRights;
var getBoughtTickets = (0, _toolkit.createAsyncThunk)(_constants.GET_BOUGHT_TICKETS, function () {
  var _ref15 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(uid, _ref14) {
    var getState, eventId, userId, eventUnassignedTickets;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            getState = _ref14.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            userId = (0, _selectors2.getFirebaseUserUid)(getState()) || uid;
            _context7.next = 5;
            return _reactNativeFirebase.default.firestore().collection("".concat(_firebaseCollections.UNASSIGNED_TICKETS, "/").concat(eventId, "/").concat(userId)).get();

          case 5:
            eventUnassignedTickets = _context7.sent;
            return _context7.abrupt("return", eventUnassignedTickets.docs.map(function (doc) {
              return _objectSpread(_objectSpread({}, doc.data()), {}, {
                id: doc.id
              });
            }));

          case 7:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));

  return function (_x12, _x13) {
    return _ref15.apply(this, arguments);
  };
}());
exports.getBoughtTickets = getBoughtTickets;