Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _spacing = require("../../../spacing");

var _default = _reactNative.StyleSheet.create({
  titles: {
    height: 50
  },
  leftStyle: {
    marginRight: _spacing.Spacing.tight,
    margin: 0,
    padding: 0
  },
  rightStyle: {
    marginRight: _spacing.Spacing.tight
  }
});

exports.default = _default;