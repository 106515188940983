var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TipButtonInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _appraisal = _interopRequireDefault(require("../../core/appraisal"));

var _live = _interopRequireDefault(require("../../core/live"));

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _Analytics = require("../../core/services/Analytics");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var TipButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(TipButtonInternal, _React$PureComponent);

  var _super = _createSuper(TipButtonInternal);

  function TipButtonInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, TipButtonInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.trackAnalyticsEvents = function () {
      var _this$props = _this.props,
          djName = _this$props.djName,
          djId = _this$props.djId,
          festivalName = _this$props.festivalName,
          festivalId = _this$props.festivalId;
      (0, _Analytics.trackMultipleEvents)(['live_show_engagement', djId && "live_show_engagement_dj_".concat(djName, "_").concat(djId), festivalId && "live_show_engagement_festival_".concat(festivalName, "_").concat(festivalId), 'live_show_engagement_tip_the_dj']);
    };

    _this.handlePress = function () {
      _this.props.tipTheDj().then(function () {
        _this.trackAnalyticsEvents();
      });
    };

    return _this;
  }

  (0, _createClass2.default)(TipButtonInternal, [{
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: [styles.iconButton, this.props.style]
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "giftButton",
        onPress: this.handlePress,
        borderless: false,
        style: {
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.primary
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "gift-outline",
        size: 24,
        color: colors.text
      }))));
    }
  }]);
  return TipButtonInternal;
}(_react.default.PureComponent);

exports.TipButtonInternal = TipButtonInternal;

var styles = _reactNative.StyleSheet.create({
  iconButton: {
    backgroundColor: 'rgba(255,255,255, 0.18)',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    width: 40,
    height: 40,
    margin: 0,
    marginLeft: 10,
    overflow: 'hidden'
  }
});

function mapStateToProps(state) {
  return {
    inProgress: _appraisal.default.selectors.isTipTheDjInProgress(state),
    djId: _live.default.selectors.getDjId(state),
    djName: _live.default.selectors.getDjName(state),
    festivalId: _live.default.selectors.getFestivalId(state),
    festivalName: _live.default.selectors.getFestivalName(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  tipTheDj: _appraisal.default.actions.tipTheDj
})((0, _reactNativePaper.withTheme)(TipButtonInternal));

exports.default = _default;