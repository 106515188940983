Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customStyles = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flexDirection: 'column'
  },
  modalHeader: {
    backgroundColor: '#111',
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  shareButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 24,
    padding: 16
  },
  closeButton: {
    position: 'absolute',
    right: 16
  }
});

exports.default = _default;
var customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.8)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    padding: 0,
    borderColor: 'rgba(0,0,0,0.8)'
  }
};
exports.customStyles = customStyles;