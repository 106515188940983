Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_EVENT_SLUG_COMPLETED = exports.LOAD_EVENT_INFO_COMPLETED = exports.IS_EVENT_INFO_LOADING = exports.NAME = void 0;
var NAME = 'eventInfo';
exports.NAME = NAME;
var IS_EVENT_INFO_LOADING = 'eventInfo/IS_EVENT_INFO_LOADING';
exports.IS_EVENT_INFO_LOADING = IS_EVENT_INFO_LOADING;
var LOAD_EVENT_INFO_COMPLETED = 'eventInfo/LOAD_EVENT_INFO_COMPLETED';
exports.LOAD_EVENT_INFO_COMPLETED = LOAD_EVENT_INFO_COMPLETED;
var LOAD_EVENT_SLUG_COMPLETED = 'eventInfo/LOAD_EVENT_SLUG_COMPLETED';
exports.LOAD_EVENT_SLUG_COMPLETED = LOAD_EVENT_SLUG_COMPLETED;