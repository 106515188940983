Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _colors = require("../../../../design-system/theme/colors");

var _spacing = require("../../../../design-system/spacing");

var _default = _reactNative.StyleSheet.create({
  safeArea: {
    flex: 1
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: _colors.Color.neutralDarkestOpacity08
  },
  surface: {
    backgroundColor: _colors.Color.neutralLightest,
    width: '100%',
    maxWidth: 560,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    elevation: 2
  },
  closeButton: {
    position: 'absolute',
    top: _spacing.Spacing.tight,
    right: _spacing.Spacing.tight
  }
});

exports.default = _default;