var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _createReducer;

var InitialState = {
  roomAccessTokensMap: {}
};
var reducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.removeRoomAccessTokens.type, function (state) {
  state.roomAccessTokensMap = {};
}), (0, _defineProperty2.default)(_createReducer, _actions.getRoomAccessToken.pending, function (state, _ref) {
  var room = _ref.meta.arg;
  state.roomAccessTokensMap[room] = {
    loading: true
  };
}), (0, _defineProperty2.default)(_createReducer, _actions.getRoomAccessToken.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  var room = payload.room,
      _payload$data = payload.data,
      accessToken = _payload$data.accessToken,
      createdAt = _payload$data.createdAt,
      expiration = _payload$data.expiration,
      id = _payload$data.id;
  state.roomAccessTokensMap[room] = {
    id: id,
    accessToken: accessToken,
    createdAt: createdAt,
    expiration: expiration,
    loading: false,
    loaded: true
  };
}), (0, _defineProperty2.default)(_createReducer, _actions.getRoomAccessToken.rejected, function (state, _ref3) {
  var error = _ref3.error,
      room = _ref3.meta.arg;
  state.roomAccessTokensMap[room] = {
    error: error,
    loading: false
  };
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return InitialState;
}), _createReducer));
var _default = reducer;
exports.default = _default;