Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _actions = require("./actions");

var constants = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function createMiddleware(socket) {
  return function (store) {
    socket.on(constants.WEBSOCKET_STATUS_CHANGED_EVENT, onStatusChanged(socket, store));
    return function (next) {
      return function (action) {
        var result = next(action);
        return result;
      };
    };
  };
}

var onStatusChanged = function onStatusChanged(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.statusChanged)(socket.io.opts.query.liveId, response.status, response.liveUrl, response.description, response.hashTags, response.priority));
  };
};