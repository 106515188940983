var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.persistor = exports.api = exports.default = void 0;

var _asyncStorage = _interopRequireDefault(require("@react-native-community/async-storage"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _redux = require("redux");

var _developmentOnly = require("redux-devtools-extension/developmentOnly");

var _reduxPersist = require("redux-persist");

var _reduxPersistTransformImmutable = _interopRequireDefault(require("redux-persist-transform-immutable"));

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _NavigationService = _interopRequireDefault(require("../../navigation/NavigationService"));

var _chat = _interopRequireDefault(require("../chat"));

var _checkout = _interopRequireDefault(require("../checkout"));

var _recordedVideos = _interopRequireDefault(require("../recordedVideos"));

var _api = _interopRequireDefault(require("../services/api"));

var _gamesparks = require("../services/gamesparks");

var _socket = _interopRequireDefault(require("../services/socket"));

var _user = _interopRequireDefault(require("../user"));

var _userRooms = _interopRequireDefault(require("../user-rooms"));

var _reducers = _interopRequireDefault(require("./reducers"));

var _networkingService = _interopRequireDefault(require("../services/networkingService"));

var composeEnhancers = (0, _developmentOnly.composeWithDevTools)({});
var chatHost = process.env.SOUNDMIX_WEBSOCKET_HOST || 'ws://localhost:3210';
var networkingHost = process.env.NETWORKING_WEBSOCKET_HOST || 'ws://localhost:9596';
var gsSocket = (0, _gamesparks.GSProvider)();
var socket = (0, _socket.default)(chatHost);
var api = (0, _api.default)({
  navigation: _NavigationService.default
});
exports.api = api;
var networkingService = new _networkingService.default((0, _socket.default)(networkingHost));
var middlewares = [_reduxThunk.default.withExtraArgument({
  api: api,
  gsSocket: gsSocket,
  networkingService: networkingService
}), _chat.default.createMiddleware({
  socket: socket,
  firebase: _reactNativeFirebase.default
}), _user.default.createMiddleware(), _recordedVideos.default.createMiddleware(), _userRooms.default.createMiddleware({
  networkingService: networkingService
})];
var persistConfig = {
  transforms: [(0, _reduxPersistTransformImmutable.default)()],
  key: 'root',
  version: 1,
  storage: _asyncStorage.default,
  whitelist: [_user.default.constants.NAME, _checkout.default.constants.CHECKOUT_INTENT_NAME]
};
var store = (0, _redux.createStore)((0, _reduxPersist.persistReducer)(persistConfig, _reducers.default), {}, composeEnhancers(_redux.applyMiddleware.apply(void 0, middlewares)));
var _default = store;
exports.default = _default;
var persistor = (0, _reduxPersist.persistStore)(store);
exports.persistor = persistor;