Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  page: {
    lobby: 'Lobby',
    home: 'Home'
  },
  event: {
    past: 'Past Events',
    live: 'Live Events',
    upcoming: 'Upcoming Events'
  },
  login: {
    header: 'Sign In',
    emailRequired: 'E-mail address is required',
    emailValid: 'Add a valid e-mail address',
    passwordRequired: 'Password is required',
    email: 'E-mail*',
    password: 'Password*',
    loginButton: 'Sign in',
    register: 'Register',
    sendLoginEmailButton: 'Sign In with E-mail',
    backToLoginButton: 'Retry Sign In',
    createNewAccount: 'Register for this event',
    forgotPassword: 'Forgot password?',
    login: 'Sign in',
    forgotPasswordRetry: 'I need a new reset link',
    emailOnlyText: "We'll email you a magic link for a password free sign in. Or you can sign in ",
    emailOnlyTextLink: 'using a password instead.',
    emailPassText: 'Sign in using the password you set at registration. Or you can sign in ',
    emailPassTextLink: 'using a magic link sent by e-mail.',
    emailWithLoginUrlSent: 'An email was sent to your e-mail address. Please click the link in the e-mail to sign into the event.',
    confirmYourEmail: 'Please confirm your email address. You have opened the login-link not on the same device/browser.'
  },
  register: {
    passwordMinCharacters: 'The password should contain at least 6 characters',
    passwordMatch: 'The passwords do not match',
    termsAndConditions: 'By registering you confirm that you agree with the',
    passwordConfirm: 'Confirm password*',
    confirmPassword: 'Confirm password*',
    terms: 'Terms of Use',
    and: 'and',
    policy: 'Privacy Policy',
    continue: 'Continue',
    registration: 'Registration',
    fieldRequired: 'This field is required',
    alreadyHaveAccount: 'Already have an account?',
    displayNameUpdate: 'Add your username',
    displayNameUpdateInfo: "Please provide your username - because in the register process it wasn't successfully registered.",
    saveDisplayName: 'Save',
    displayNameRequired: 'A username is required. Please provide one. It should not be your email address',
    ticket: 'Ticket',
    username: 'Full Name*',
    userName2: 'Name*',
    userSurname: 'Surname*',
    company: 'Company*',
    position: 'Position*',
    companyRequired: 'Company is required',
    positionRequired: 'Position is required',
    usernameRequired: 'Name is required',
    email: 'E-mail*',
    emailRequired: 'E-mail address is required',
    emailInvalid: 'Add a valid e-mail address',
    password: 'Password*',
    passwordRequired: 'Password is required',
    passwordConfirmRequired: 'Confirm password is required',
    doNotClose: "Just a sec, we're getting everything ready for you!",
    inProgress: 'Registration in progress...',
    inProgressStepTwo: 'Registration in progress... 2/3 updating user profile',
    inProgressStepThree: 'Registration in progress... 3/3 finishing registration',
    inProgressCompleted: 'Registration complete.',
    error: {
      fileSize: 'The file is too large. Allowed maximum size is 50MiB.',
      upload: 'Something went wrong, please try again!'
    },
    finish: 'finish',
    confirmCloseDialog: 'Attention, you will lose all progress by closing the registration process!'
  },
  contactInformation: {
    passwordMinCharacters: 'The password should contain at least 6 characters',
    passwordMatch: 'The passwords do not match',
    termsAndConditions: 'By registering you confirm that you agree with the',
    confirmPassword: 'Confirm password*',
    terms: 'Terms of Use',
    and: 'and',
    policy: 'Privacy Policy',
    title: 'Registration',
    fieldRequired: 'This field is required',
    alreadyHaveAccount: 'Already have an account?',
    displayNameUpdate: 'Add your username',
    displayNameUpdateInfo: "Please provide your username - because in the register process it wasn't successfully registered.",
    saveDisplayName: 'Save',
    displayNameRequired: 'A username is required. Please provide one. It should not be your email address',
    finish: 'Register',
    username: 'Full Name*',
    userName2: 'Name*',
    userSurname: 'Surname*',
    company: 'Company*',
    position: 'Position*',
    companyRequired: 'Company is required',
    positionRequired: 'Position is required',
    usernameRequired: 'Name is required',
    email: 'E-mail*',
    emailRequired: 'E-mail address is required',
    emailInvalid: 'Add a valid e-mail address',
    password: 'Password*',
    passwordRequired: 'Password is required',
    passwordConfirm: 'Confirm password*',
    passwordConfirmRequired: 'Confirm password is required',
    doNotClose: "Just a sec, we're getting everything ready for you!",
    inProgress: 'Registration in progress...',
    inProgressStepTwo: 'Registration in progress... 2/3 updating user profile',
    inProgressStepThree: 'Registration in progress... 3/3 finishing registration',
    inProgressCompleted: 'Registration complete.',
    error: {
      fileSize: 'The file is too large. Allowed maximum size is 50MiB.',
      upload: 'Something went wrong, please try again!'
    }
  },
  addOns: {
    title: 'Add-Ons',
    multipleAddonsWarning: 'Selected Add-Ons will be applied to all tickets.'
  },
  configureTicket: {
    title: 'Event Access',
    ticket: 'Ticket',
    quantity: 'Quantity',
    owned: 'owned'
  },
  resetPassword: {
    enterEmail: 'Insert the e-mail address used at registration',
    resetPassword: 'Reset password',
    resetActionText: 'Insert your new password below and hit the confirm button to reset your password.',
    invalidCode: 'Expired Password Reset Code',
    invalidCodeText: 'Your password reset code has expired - it is only active about an hour. Please retry the reset process.',
    success: 'Successful Password Reset',
    successText: 'Your password has been successfully reset. You can now try to login with your new password.',
    mailSent: 'A reset password e-mail was sent to your e-mail address.',
    noAccount: 'No account found coresponding to the provided email address'
  },
  header: {
    about: 'About',
    speakers: 'Speakers',
    schedule: 'Schedule',
    benefits: 'Benefits',
    testimonials: 'Testimonials',
    partners: 'Partners',
    booths: 'Booths',
    sponsors: 'Sponsors',
    checklist: 'Checklist',
    myTickets: 'My tickets',
    myProfile: 'My profile',
    logout: 'Sign out',
    myFiles: 'My files',
    lobbyPage: 'Live event'
  },
  leftMenu: {
    home: 'Home',
    liveNow: 'Live now',
    schedule: 'Schedule',
    speakers: 'Speakers',
    videos: 'Videos',
    sponsors: 'Sponsors',
    networking: 'Networking',
    expo: 'Expo'
  },
  rightSidebarMenu: {
    chat: 'Chat',
    booth: 'Booth',
    people: 'People',
    files: 'Files',
    uploadedFiles: 'Uploaded Files',
    speak: 'Speak',
    backstageChat: 'Backstage Chat'
  },
  rightSidebar: {
    noImage: 'No Image'
  },
  footer: {
    termsAndConditions: 'Terms and conditions',
    privacyPolicy: 'Privacy policy',
    owner: 'eventmix'
  },
  global: {
    back: 'Back',
    backToPage: 'Back to page',
    hours: 'hour(s)',
    days: 'day(s)',
    minutes: 'minutes',
    today: '[Today]',
    tomorrow: '[Tomorrow]',
    invalidSpeakerProfile: 'This is not a valid speaker profile.',
    download: 'Download',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel'
  },
  networking: {
    noImage: 'No image',
    joinTable: 'Join table',
    joinTheNetworkingArea: 'Go to Networking Area',
    viewTable: 'Watch',
    leaveTable: 'Leave table',
    noDevices: 'No devices',
    tableIsFull: 'Table is full',
    you: 'You',
    apply: 'Close',
    internalServerError: 'Internal server error',
    ok: 'Ok',
    error: {
      tableIsFull: 'Sorry, this table is full. Please try again once someone leaves the table.',
      internalServerError: 'Something went wrong, please try again later.'
    },
    checkAudioAndVideoSettings: 'Check Audio & Video Settings',
    takeASeat: 'Take a seat',
    off: 'Off',
    browserNotSupported: "Your browser doesn't support our technology",
    openSupportedBrowser: 'Please visit this page with one of the supported browsers.',
    supportDetails: 'You can find more details on the',
    supportedBrowsers: 'supported browsers',
    unknownOS: 'Unfortunately, the operating system of your device could not be identified. Please try an other browser.',
    connectionError: 'Connection Error',
    connectionErrorText: 'The connection failed and the following error was received:',
    inactivityWarning: 'The system will give your place to someone else after {{seconds}} seconds',
    youreInactive: "You're inactive",
    done: 'Done',
    somethingBadHappened: 'Ooops! Something happened!'
  },
  backstage: {
    startThisSession: 'Start this session?',
    startSessionInfo: 'After a session is started, the participants can see and hear the speakers in the session.',
    someone: 'Someone',
    no: 'Cancel',
    start: 'Start',
    sessionStarts: '{{name}} started the session.',
    sessionStarting: 'Session starting...',
    startsIn: 'Starts in:',
    noRequests: 'No requests',
    iAmReady: "I'm ready",
    iAmReadyTooltip: "When you are ready to join the session, click the `I'm ready` button. The session host will be notified that you arrived.",
    iAmReadyTooltipOk: 'Ok, I got it!',
    waitingForApproval: 'Waiting for approval',
    stop: 'Stop',
    ok: 'OK',
    leave: 'Leave',
    leaveLive: 'Leave live',
    cancel: 'Cancel',
    goToBackstage: 'Continue',
    tableIsFull: 'Call room is full',
    internalServerError: 'Internal server error',
    internal: 'Internal server error',
    speakerJoinRequestFailed: 'Go live request failed',
    speakerLeaveRequestFailed: 'Leave request failed',
    adminSpeakerRequestFailed: 'Admit speaker request failed',
    welcomeToTheBackstage: 'Welcome to the Backstage!',
    welcomeToTheBackstageText: "Conversations here are private. The event participants can't see you until the host starts the session.",
    welcomeToTheGreenRoom: 'You are now in the Green Room',
    welcomeToTheGreenRoomText: 'This is the private welcome area for this event.',
    welcomeToTheGreenRoomText2: "You can try the video features before going live. Event participants can't see you yet.",
    continueToGreenRoom: 'Ok, continue',
    checkSettings: 'Check your video/audio settings',
    checkSettingsText: 'Make sure your camera and microphone are working.',
    kickOutSpeaker: 'Are you sure you want to remove {{name}} from this live stream?',
    kick: 'Kick',
    kickUserFromLive: 'Kick {{name}} from live',
    kickUserInfo: "{{name}} immediately goes back to the green room and can't enter, until someone allows the join request!",
    startBackstageSession: 'Start session',
    stopBackstageSession: 'Stop session',
    userDenied: 'Access Denied',
    userDeniedText: 'Your access to the backstage has been denied by the host. Feel free to ask the host in the Backstage Chat.',
    participantsCanSeeYou: 'The event participants can see and hear you.',
    participantsCannotSeeYou: 'The event participants cannot see and hear you yet.',
    readyConfirmationTitle: 'Join to this session?',
    readyConfirmation: 'You can join the current session. The audience will see and hear you.',
    join: 'Join',
    bestOnDesktop: 'Backstage works best on larger screens',
    goBackToLive: 'Back to the Live Event',
    error: {
      somethingWentWrong: 'Something went wrong',
      roomIsFull: 'Sorry, the video call room is full. Please try again later.',
      internalServerError: 'Something went wrong, please try again later.',
      internal: 'Something went wrong, please try again later.',
      speakerJoinRequestFailed: 'Go live request failed. Please try again later.',
      adminSpeakerRequestFailed: 'Something went wrong, speaker request could not be admitted',
      noFeaturePermissionError: "You don't have permission to use this feature.",
      noFeaturesError: 'No allowed features provided.',
      unauthenticated: 'Authentication is needed.',
      backstageIdValidationError: "You don't have permission to use this feature.",
      speakerIdValidationError: "You don't have permission to use this feature.",
      eventIdValidationError: "You don't have permission to use this feature.",
      kickOutSpeakerFailed: 'Speaker could not be kicked out',
      backstageSessionStartFailed: 'Backstage session starting failed. Please try again later.',
      backstageSessionStopFailed: 'Backstage session stopping failed. Please try again later.',
      requestedResourceNotFound: 'The requested resource not found. The URL of this backstage seems to be corrupted. Please check again.',
      maximumNumberOfParticipantsIsReached: 'The backstage room is full. At this moment no more speakers can join this backstage.'
    },
    header: {
      backstage: 'Backstage',
      greenRoom: 'Green Room',
      session: 'Session'
    }
  },
  checkout: {
    conferenceTickets: 'Conference tickets',
    eventRegistration: 'Event registration',
    promoCode: 'Promo Code',
    freeRegiser: 'Register free',
    buyTicket: 'Buy ticket',
    myTickets: 'My tickets',
    otherTickets: 'Other tickets',
    otherAddons: 'Other addons',
    validity: 'Validity',
    buyedOn: 'Bought on',
    title: 'Complete payment',
    ticket: 'Ticket',
    free: 'Free',
    freeRegistration: 'Free registration',
    allDays: 'Full event',
    summary: 'Summary',
    generalAccess: 'General Access',
    addOns: 'Add-ons',
    total: 'Total',
    next: 'Next',
    back: 'Back',
    optionalAddons: 'Optional Add-ons',
    addonsInstruction: 'Please select add-ons that you would like to add to your order',
    approveTicket: 'Your account is pending approval by the event organizer. You will receive an activation e-mail once your account has been approved',
    addToCalendar: 'Add to calendar',
    promoCodeOutOfStock: 'Promocode out of stock',
    soldOut: 'Sold out',
    notAvailable: 'Not available'
  },
  assignTickets: {
    title: 'Assign tickets',
    assign: 'assign',
    pageTitle: 'Assign tickets',
    thankYou: 'Thank you for your purchase!',
    thankYouDescription: 'Please indicate who will have acces to the event, for each ticket.',
    email: 'email',
    fullName: 'Full name',
    thisIsMyTicket: 'This is my ticket',
    ticketAssigned: 'Ticket assigned',
    emailShouldBeUnique: 'E-mail Addresses should be unique',
    youAlreadyHaveThisTicket: 'You already have this ticket',
    assignedTickets: 'Assigned tickets',
    unassignedTickets: 'Unassigned tickets',
    ticket: 'Ticket',
    addons: 'Addons',
    assignedTo: 'Assigned to',
    assignedDate: 'Assigned date'
  },
  lobby: {
    eventStartIn: 'The event starts in:',
    eventStarted: 'The event is ongoing.',
    eventEnded: 'The event has ended.',
    joinLive: 'Join Live',
    with: 'with',
    writeHere: 'Write a message',
    remained: 'Remaining',
    addToCalendar: 'Add to calendar'
  },
  billing: {
    promoCode: 'Promo Code',
    cardInformation: 'Card Information',
    cardDetails: 'Card details',
    billingInformation: 'Billing information',
    cardHolderName: 'Cardholder Name',
    country: 'Country',
    billingAddress: 'Billing Address',
    companyDetails: 'Company Details - Optional',
    pay: 'Pay',
    payWithCard: 'Pay with card',
    email: 'Email',
    currency: 'US',
    termsAndConditions: 'You are purchasing for the account',
    termsAndConditions2: 'By paying for a ticket you agree with our',
    stripe: 'Secure payments powered by',
    create: {
      email: 'Email address is required',
      cardholderName: 'Cardholder name is required',
      billingAddress: 'Billing address is required',
      addressLine1: 'Address Line is required',
      postalCode: 'Postal Code is required',
      city: 'City is required',
      allFields: 'REQUIRED',
      taxIdNumber: '7 characters minimum',
      invalidFormat: 'This is not a valid format',
      cardNumberPlaceholder: 'Card number',
      addressLine1Placeholder: 'Address Line',
      postalCodePlaceholder: 'Postal Code',
      cityPlaceholder: 'City',
      companyNamePlaceholder: 'Company Name',
      taxIdNumberPlaceholder: 'Company VAT/Tax ID (Optional)'
    },
    error: {
      country: 'The billing country seems to be invalid / unprocessable for Stripe.',
      incomplete_number: 'The card number seems to be incomplete.',
      invalid_number: 'This is an invalid card number.',
      incomplete_expiry: 'This is an incomplete expiry date.',
      invalid_expiry_year: 'The expiry date is invalid.',
      incomplete_cvc: 'The CVC field is incomplete.',
      incorrectTaxId: 'The provided Tax ID number could not be validated.',
      incompleteCompanyDetails: 'Incomplete company details.'
    },
    emailWarning: 'This is the e-mail adress where the ticktets will be delivered digitally.'
  },
  liveNow: {
    start: 'Begins',
    fewSeconds: 'few seconds',
    sessionsEnded: 'This session has ended',
    noLiveVideo: 'There are no live videos.',
    noPreview: 'Click here',
    sessionEnded: 'This session has ended',
    sessionDidNotStart: 'This session did not start',
    sessionStarts: 'This session starts:',
    sessionWillStart: 'The session will start soon',
    upgradeTicket: 'Upgrade Ticket',
    noAccess: 'Sorry, your current ticket (or addon selection) doesn’t include access to this content. Please try upgrading your ticket.',
    noAccessNext: 'Sorry, your current ticket (or addon selection) doesn’t include access to the upcoming session. Please try upgrading your ticket or addons.',
    tryAgain: 'Please refresh page',
    upcoming: 'Upcoming sessions',
    past: 'Past sessions',
    refresh: 'Refresh',
    backToNetworking: 'Back to the Networking',
    eventFinished: 'The event has finished. Thank you for participating.',
    sessionFinished: 'The sessions have ended on this stage. Thanks for participating!',
    prerecordedVideoFinished: 'This session has finished. Stay tuned for our next session.',
    prerecordedVideoUnavailable: 'Waiting for the Host (Organizer) to start the (RTMPS) live stream or to associate a pre-recorded video for this session.',
    invalidSession: 'This is not a valid live URL. Sorry. Please navigate back to the "Live Now" page.',
    break: 'Break',
    goToNetworkingArea: 'Go to networking area',
    waitingForStreamStart: 'Please wait. The host is about to start the stream ...',
    waitingForParticipants: 'There are no participants in the networking room. Waiting for the participants to enter ...'
  },
  videos: {
    otherVideos: 'Videos',
    notFoundVideo: 'That video does not exist (or you do not have access rights).',
    noVideos: 'Recordings will be available 24 hours after the event finishes.',
    tryAgain: 'Please try again after the event has finished.',
    noAccessToVideos: 'Sorry, your current ticket doesn’t include access to recordings. Please try upgrading your ticket.'
  },
  payment: {
    success: 'Payment successful!',
    failed: 'Something went wrong with the payment. Please try again!',
    redirect: 'You will be redirected to',
    in: 'in',
    seconds: 'seconds',
    complete: 'Complete payment'
  },
  domain: {
    noEventForDomain: 'Domain with no associated event.',
    noAssociatedEvent: 'At the moment, there is no scheduled event.',
    severalEvents: 'There are several events associated with this domain:'
  },
  audiovideoSettings: {
    title: 'Audio & Video Settings',
    description: 'You are about to use your webcam. For others to see and hear you, your browser will request access to your cam and mic. You can still turn them back off at any time.',
    audioInput: 'Audio Input',
    audioOutput: 'Audio Output',
    videoInput: 'Video Input',
    apply: 'Apply',
    noLocalAudio: 'No Local Audio',
    noLocalVideo: 'No Local Video',
    audioVideoSettingsFirefoxNotSupported: 'Audio & Video Settings is not working properly in Mozilla Firefox, please use Chrome or Microsoft Edge instead.'
  },
  toaster: {
    error: 'Something went wrong',
    success: 'Success'
  },
  errors: {
    error: 'Error',
    firewall: 'Firewall Issue',
    firewall1: 'You may be behind a Firewall that is blocking connections to ',
    firewall2: 'Please contact your network or IT admin and ask to',
    firewall3: 'allow access to the *.googleapis.com domain.',
    error404: '404',
    somethingWentWrong: 'Something went wrong. Please try again!',
    tryAgain: 'Try again',
    eventNotExists: 'This event does not exist.',
    userAlreadyHasThisTicket: 'The selected ticket is already acquired.',
    'auth/admin-restricted-operation': 'This operation is restricted to administrators only.',
    'auth/argument-error': '',
    'auth/app-not-authorized': "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    'auth/app-not-installed': 'The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.',
    'auth/captcha-check-failed': 'The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.',
    'auth/code-expired': 'The SMS code has expired. Please re-send the verification code to try again.',
    'auth/cordova-not-ready': 'Cordova framework is not ready.',
    'auth/cors-unsupported': 'This browser is not supported.',
    'auth/credential-already-in-use': 'This credential is already associated with a different user account.',
    'auth/custom-token-mismatch': 'The custom token corresponds to a different audience.',
    'auth/requires-recent-login': 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
    'auth/dynamic-link-not-activated': 'Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.',
    'auth/email-change-needs-verification': 'Multi-factor users must always have a verified email.',
    'already-exists': 'The email address is already in use by another account.',
    'auth/expired-action-code': 'The action code has expired. ',
    'auth/cancelled-popup-request': 'This operation has been cancelled due to another conflicting popup being opened.',
    'auth/internal-error': 'An internal error has occurred.',
    'auth/invalid-app-credential': 'The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.',
    'auth/invalid-app-id': 'The mobile app identifier is not registed for the current project.',
    'auth/invalid-user-token': "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    'auth/invalid-auth-event': 'An internal error has occurred.',
    'auth/invalid-verification-code': 'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.',
    'auth/invalid-continue-uri': 'The continue URL provided in the request is invalid.',
    'auth/invalid-cordova-configuration': 'The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.',
    'auth/invalid-custom-token': 'The custom token format is incorrect. Please check the documentation.',
    'auth/invalid-dynamic-link-domain': 'The provided dynamic link domain is not configured or authorized for the current project.',
    'auth/invalid-email': 'The email address is badly formatted.',
    'auth/invalid-api-key': 'Your API key is invalid, please check you have copied it correctly.',
    'auth/invalid-cert-hash': 'The SHA-1 certificate hash provided is invalid.',
    'auth/invalid-credential': 'The supplied auth credential is malformed or has expired.',
    'auth/invalid-message-payload': 'The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.',
    'auth/invalid-multi-factor-session': 'The request does not contain a valid proof of first factor successful sign-in.',
    'auth/invalid-oauth-provider': 'EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.',
    'auth/invalid-oauth-client-id': 'The OAuth client ID provided is either invalid or does not match the specified API key.',
    'auth/unauthorized-domain': 'This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.',
    'auth/invalid-action-code': 'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
    'auth/wrong-password': 'The email or the password is invalid or the user does not have a password.',
    'auth/invalid-persistence-type': 'The specified persistence type is invalid. It can only be local, session or none.',
    'auth/invalid-phone-number': 'The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].',
    'auth/invalid-provider-id': 'The specified provider ID is invalid.',
    'auth/invalid-recipient-email': 'The email corresponding to this action failed to send as the provided recipient email address is invalid.',
    'auth/invalid-sender': 'The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.',
    'auth/invalid-verification-id': 'The verification ID used to create the phone auth credential is invalid.',
    'auth/invalid-tenant-id': "The Auth instance's tenant ID is invalid.",
    'auth/multi-factor-info-not-found': 'The user does not have a second factor matching the identifier provided.',
    'auth/multi-factor-auth-required': 'Proof of ownership of a second factor is required to complete sign-in.',
    'auth/missing-android-pkg-name': 'An Android Package Name must be provided if the Android App is required to be installed.',
    'auth/auth-domain-config-required': 'Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.',
    'auth/missing-app-credential': 'The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.',
    'auth/missing-verification-code': 'The phone auth credential was created with an empty SMS verification code.',
    'auth/missing-continue-uri': 'A continue URL must be provided in the request.',
    'auth/missing-iframe-start': 'An internal error has occurred.',
    'auth/missing-ios-bundle-id': 'An iOS Bundle ID must be provided if an App Store ID is provided.',
    'auth/missing-multi-factor-info': 'No second factor identifier is provided.',
    'auth/missing-multi-factor-session': 'The request is missing proof of first factor successful sign-in.',
    'auth/missing-or-invalid-nonce': 'The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.',
    'auth/missing-phone-number': 'To send verification codes, provide a phone number for the recipient.',
    'auth/missing-verification-id': 'The phone auth credential was created with an empty verification ID.',
    'auth/app-deleted': 'This instance of FirebaseApp has been deleted.',
    'auth/account-exists-with-different-credential': 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
    'auth/network-request-failed': 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    'auth/no-auth-event': 'An internal error has occurred.',
    'auth/no-such-provider': 'User was not linked to an account with the given provider.',
    'auth/null-user': 'A null user object was provided as the argument for an operation which requires a non-null user object.',
    'auth/operation-not-allowed': 'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.',
    'auth/operation-not-supported-in-this-environment': 'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    'auth/popup-blocked': 'Unable to establish a connection with the popup. It may have been blocked by the browser.',
    'auth/popup-closed-by-user': 'The popup has been closed by the user before finalizing the operation.',
    'auth/provider-already-linked': 'User can only be linked to one identity for the given provider.',
    'auth/quota-exceeded': "The project's quota for this operation has been exceeded.",
    'auth/redirect-cancelled-by-user': 'The redirect operation has been cancelled by the user before finalizing.',
    'auth/redirect-operation-pending': 'A redirect sign-in operation is already pending.',
    'auth/rejected-credential': 'The request contains malformed or mismatching credentials.',
    'auth/second-factor-already-in-use': 'The second factor is already enrolled on this account.',
    'auth/maximum-second-factor-count-exceeded': 'The maximum allowed number of second factors on a user has been exceeded.',
    'auth/tenant-id-mismatch': "The provided tenant ID does not match the Auth instance's tenant ID",
    'auth/timeout': 'The operation has timed out.',
    'auth/user-token-expired': "The user's credential is no longer valid. The user must sign in again.",
    'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
    'auth/unauthorized-continue-uri': 'The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.',
    'auth/unsupported-first-factor': 'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.',
    'auth/unsupported-persistence-type': 'The current environment does not support the specified persistence type.',
    'auth/unsupported-tenant-operation': 'This operation is not supported in a multi-tenant context.',
    'auth/unverified-email': 'The operation requires a verified email.',
    'auth/user-cancelled': 'The user did not grant your application the permissions it requested.',
    'auth/user-not-found': 'There is no user record corresponding to this identifier. The user may have been deleted.',
    'auth/user-disabled': 'The user account has been disabled by an administrator.',
    'auth/user-mismatch': 'The supplied credentials do not correspond to the previously signed in user.',
    'auth/user-signed-out': '',
    'auth/weak-password': 'The password must be 6 characters long or more.',
    'auth/web-storage-unsupported': 'This browser is not supported or 3rd party cookies and data may be disabled.'
  },
  expo: {
    visitBooth: 'Visit Booth',
    booth: 'Booth',
    files: 'Files',
    noFiles: 'No files...',
    registerMessage: 'By registering your interest you will claim their offer and your contact details will be sent to be contracted by the partner'
  },
  agora: {
    DEVICE_NOT_FOUND: 'No devices or the previously selected device not found. Please select another device or plugin a device!',
    PERMISSION_DENIED: 'Fails to get access to the media device. Click "Allow" in the pop-up window for acquiring device permissions!',
    DEFAULT_DEVICE_IN_USE: 'Another program is using your camera or microphone. Please close that program and refresh this page.',
    'AgoraRTCError UNEXPECTED_ERROR: NotReadableError: Could not start video source': 'Another program is using your camera or microphone. Please close that program and refresh this page.'
  },
  chat: {
    chat: 'Chat',
    deleteComment: 'Delete comment',
    blockUser: 'Block user',
    unblockUser: 'Unblock user',
    people: 'People',
    abuse: 'Abuse',
    youBlockedFromChat: 'Your access to the chat was blocked by the event organizer.',
    youDisconnectedFromChat: 'You got disconnected from the chat. Please retry!',
    youBlockedFromQA: 'Your access to the Q&A section was blocked by the event organizer.',
    youDisconnectedFromQA: 'You got disconnected from the Q&A section. Please retry!',
    retry: 'Retry',
    youNotInChatRoom: 'You are not connected or might be behind a firewall that is blocking the connection!',
    firewallClickHere: 'Click here',
    firewallInstructions: ' for firewall settings instructions.',
    join: 'Retry',
    writeAComment: 'No comments yet. You can go first!',
    writeAQuestion: 'No questions yet. You can go first!',
    yourQuestion: 'Your question...',
    saySomethingNice: 'Say something nice',
    noPermission: "You don't have permission!",
    noUsers: 'No users online',
    noBlockedUsers: 'No blocked users',
    sending: 'Sending...',
    deleting: 'Deleting...',
    reconnecting: 'Reconnecting...',
    unreadMessage: 'new message',
    unreadMessages: 'new messages',
    position: {
      organizer: 'ORGANIZER'
    },
    disabledMessage: 'The chat was disabled by the event organizer.'
  },
  menuItems: {
    chat: 'Chat',
    qa: 'Q & A',
    people: 'People',
    files: 'Files',
    abuse: 'Abuse',
    booth: 'Booth',
    polls: 'Polls',
    backstageChat: 'Backstage Chat',
    requests: 'Requests'
  },
  polls: {
    yourPolls: 'Your Polls',
    addNewPoll: 'Create a new Poll',
    backToPollsList: 'Back to my Polls',
    respondToPolls: 'We invite you to respond to our poll(s) below:',
    alreadyVoted: 'You have already voted on this poll. Thank You.',
    noVotesYet: 'There are no votes on this poll yet. Be the first one to vote.',
    deleteConfirmText: 'Are you sure, that you want to delete this poll?',
    deleteConfirmTextOptions: 'Are you sure, that you want to delete this option from your poll?',
    confirmDelete: 'Yes, delete it',
    cancelDelete: 'Cancel',
    noPollsYet: 'There are no polls created yet by the organizer of this event.',
    new: {
      title: 'The title of your poll',
      titlePlaceholder: 'My Poll',
      option: 'Add an option to your poll',
      titleMin2: 'The title must contain at least 2 characters.',
      titleMax150: 'The title must contain at most 150 characters.',
      titleRequired: 'You have to add a title for your poll.',
      minOptionLength2: 'The option must contain at least 2 characters.',
      maxOptionLength100: 'The option must contain at most 100 characters.',
      minOptions2: 'The poll must contain at least 2 options.',
      maxOptions10: 'The poll must contain at most 10 options.',
      save: 'Save this Poll',
      numberOfOptions: 'The number of poll options',
      op: 'Option',
      invalidFormatTitle: 'Invalid format. Only letters, numbers and some marks are allowed.',
      invalidFormatOption: 'Invalid format. Only letters, numbers and some marks are allowed.',
      containsDuplicates: 'Duplicate options are not allowed in the polls.'
    },
    update: {
      update: 'Update this poll',
      optionAlreadyExists: 'This option already exists at this poll.'
    }
  },
  calendar: {
    liveNow: 'Live Now',
    upcoming: 'Upcoming',
    yourSession: 'Your Session',
    startsAt: 'Starts In',
    endsIn: 'Ends In',
    networkingTextActive: 'The event organizers are hosting a networking session.',
    networkingTextUpcoming: 'Upcoming networking session hosted by the event organizers.',
    errors: {
      eventNotStartedYet: 'The event is not started yet.',
      eventFinished: 'The event has finished. Thank you.',
      noSessionsForToday: 'There are no available sessions for this day on this stage.'
    }
  },
  requests: {
    admit: 'Admit',
    deny: 'Deny',
    isTheStageManager: 'is the Stage Manager'
  },
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  jan: 'jan',
  feb: 'feb',
  mar: 'mar',
  apr: 'apr',
  may: 'may',
  jun: 'jun',
  jul: 'jul',
  aug: 'Aug',
  sep: 'sep',
  oct: 'oct',
  nov: 'nov',
  dec: 'dec',
  profile: {
    displayName: 'Full name',
    headline: 'Headline',
    bio: 'Bio',
    email: 'E-mail',
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram',
    changeAvatar: 'Change Avatar',
    phone: 'Phone number',
    firstNameRequired: 'The "First Name" field is required.',
    lastNameRequired: 'The "Last Name" field is required.',
    headlineRequired: 'The "Headline" field is required.',
    bioRequired: 'The "Bio" field is required.',
    success: 'Profile details successfully saved.',
    phoneInvalid: 'Phone number is not valid.',
    max800: 'Must have a max length of 800 characters.',
    max100: 'Must have a max length of 100 characters.',
    max50: 'Must have a max length of 50 characters.',
    instagramPlaceholder: 'Ex: https://www.instagram.com/your-instagram-page',
    facebookPlaceholder: 'Ex: https://www.facebook.com/your-facebook-page',
    linkedinPlaceholder: 'Ex: https://www.linkedin.com/your-linkedin-page',
    twitterPlaceholder: 'Ex: https://twitter.com/your-twitter-page'
  }
};
exports.default = _default;