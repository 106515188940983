var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.follow = follow;
exports.unfollow = unfollow;
exports.newFollower = newFollower;
exports.lostFollower = lostFollower;
exports.followInProgress = followInProgress;
exports.followFailed = followFailed;
exports.followCompleted = followCompleted;
exports.unfollowCompleted = unfollowCompleted;
exports.clap = clap;
exports.refreshClaps = refreshClaps;
exports.tipTheDj = tipTheDj;
exports.tipTheDjInProgress = tipTheDjInProgress;
exports.tipTheDjCompleted = tipTheDjCompleted;
exports.receiveTip = receiveTip;
exports.tipTheDjFailed = tipTheDjFailed;
exports.consumeTip = consumeTip;
exports.loadMyFollowingList = loadMyFollowingList;
exports.resetMyFollowingList = resetMyFollowingList;
exports.loadPartiallyMyFollowingListCompleted = loadPartiallyMyFollowingListCompleted;
exports.loadNumberOfFollowers = loadNumberOfFollowers;
exports.loadNumberOfFollowersCompleted = loadNumberOfFollowersCompleted;
exports.followFestival = followFestival;
exports.unfollowFestival = unfollowFestival;
exports.followFestivalInProgress = followFestivalInProgress;
exports.followFestivalFailed = followFestivalFailed;
exports.followFestivalCompleted = followFestivalCompleted;
exports.unfollowFestivalCompleted = unfollowFestivalCompleted;
exports.loadMyFollowingFestivalList = loadMyFollowingFestivalList;
exports.loadPartiallyMyFollowingFestivalListCompleted = loadPartiallyMyFollowingFestivalListCompleted;
exports.resetMyFollowingFestivalList = resetMyFollowingFestivalList;

var _actionTypes = require("./actionTypes");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _user = _interopRequireDefault(require("../user"));

var _ = _interopRequireDefault(require("./"));

var _Analytics = require("../services/Analytics");

var RNIap = _interopRequireWildcard(require("react-native-iap"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function follow(userId) {
  userId = parseInt(userId, 10);
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    dispatch(followInProgress(userId));
    return api.put({
      action: "/soundmix_users/follow/".concat(userId)
    }).then(function () {
      var state = getState();

      var currentFollowing = _.default.selectors.getFollowing(state);

      if (currentFollowing.size === 0) {
        (0, _Analytics.trackEvent)('first_follow');
      }

      dispatch(followCompleted(userId));
    }).catch(function () {
      dispatch(followFailed(userId));
    });
  };
}

function unfollow(userId) {
  userId = parseInt(userId, 10);
  return function (dispatch, getState, _ref2) {
    var api = _ref2.api;
    dispatch(followInProgress(userId));
    return api.delete({
      action: "/soundmix_users/follow/".concat(userId)
    }).then(function () {
      dispatch(unfollowCompleted(userId));
    }).catch(function () {
      dispatch(followFailed(userId));
    });
  };
}

function newFollower(userId) {
  return {
    type: _actionTypes.NEW_FOLLOWER,
    userId: userId
  };
}

function lostFollower(userId) {
  return {
    type: _actionTypes.LOST_FOLLOWER,
    userId: userId
  };
}

function followInProgress(userId) {
  return {
    type: _actionTypes.FOLLOW_IN_PROGRESS,
    userId: userId
  };
}

function followFailed(userId) {
  return {
    type: _actionTypes.FOLLOW_FAILED,
    userId: userId
  };
}

function followCompleted(userId) {
  return {
    type: _actionTypes.FOLLOW_COMPLETED,
    userId: userId
  };
}

function unfollowCompleted(userId) {
  return {
    type: _actionTypes.UNFOLLOW_COMPLETED,
    userId: userId
  };
}

function clap() {
  return {
    type: _actionTypes.CLAP
  };
}

function refreshClaps(numClaps) {
  return {
    type: _actionTypes.REFRESH_CLAPS,
    numClaps: numClaps
  };
}

function tipTheDj() {
  return function (dispatch, getState) {
    dispatch(tipTheDjInProgress());
    return RNIap.clearTransaction().then(function () {
      return RNIap.buyProductWithoutFinishTransaction(_reactNativeDeviceInfo.default.getBundleId() + '.tip.the.dj');
    }).then(function (purchase) {
      RNIap.finishTransaction();
      var state = getState();
      dispatch(tipTheDjCompleted({
        usr: _user.default.selectors.getUserId(state),
        fnm: _user.default.selectors.getUserName(state),
        avt: _user.default.selectors.getUserPicture(state),
        iat: Date.now()
      }));
      RNIap.consumePurchase(purchase.purchaseToken);
    }).catch(function () {
      dispatch(tipTheDjFailed());
    });
  };
}

function tipTheDjInProgress() {
  return {
    type: _actionTypes.TIP_IN_PROGRESS
  };
}

function tipTheDjCompleted(userData) {
  return {
    type: _actionTypes.TIP_COMPLETED,
    user: userData
  };
}

function receiveTip(userData) {
  return {
    type: _actionTypes.NEW_TIP,
    user: userData
  };
}

function tipTheDjFailed() {
  return {
    type: _actionTypes.TIP_FAILED
  };
}

function consumeTip() {
  return {
    type: _actionTypes.CONSUME_TIP
  };
}

function loadMyFollowingList(userId) {
  return function (dispatch, getState, _ref3) {
    var api = _ref3.api;
    dispatch(resetMyFollowingList());

    var getPage = function getPage(page) {
      return api.get({
        action: "/soundmix_users/".concat(userId, "/followings"),
        params: {
          page: page
        }
      }).then(function (pagedResponse) {
        dispatch(loadPartiallyMyFollowingListCompleted(pagedResponse.data));
        var range = api.getContentRange(pagedResponse.headers);

        if (range.to < range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function resetMyFollowingList() {
  return {
    type: _actionTypes.RESET_MY_FOLLOWING
  };
}

function loadPartiallyMyFollowingListCompleted(partialFollowingList) {
  return {
    type: _actionTypes.LOAD_PART_OF_MY_FOLLOWING,
    following: partialFollowingList
  };
}

function loadNumberOfFollowers(userId) {
  return function (dispatch, getState, _ref4) {
    var api = _ref4.api;
    return api.head({
      action: "/soundmix_users/".concat(userId, "/followers")
    }).then(function (response) {
      var range = api.getContentRange(response.headers);
      dispatch(loadNumberOfFollowersCompleted(userId, range.total));
    });
  };
}

function loadNumberOfFollowersCompleted(userId, numFollowers) {
  return {
    type: _actionTypes.LOAD_NUMBER_OF_FOLLOWERS,
    userId: userId,
    numFollowers: numFollowers
  };
}

function followFestival(festivalId) {
  return function (dispatch, getState, _ref5) {
    var api = _ref5.api;
    dispatch(followFestivalInProgress(festivalId));
    return api.post({
      action: "/festivals/".concat(festivalId, "/followers")
    }).then(function () {
      dispatch(followFestivalCompleted(festivalId));
    }).catch(function () {
      dispatch(followFestivalFailed(festivalId));
    });
  };
}

function unfollowFestival(festivalId) {
  return function (dispatch, getState, _ref6) {
    var api = _ref6.api;
    dispatch(followFestivalInProgress(festivalId));
    return api.delete({
      action: "/festivals/".concat(festivalId, "/followers")
    }).then(function () {
      dispatch(unfollowFestivalCompleted(festivalId));
    }).catch(function () {
      dispatch(followFestivalFailed(festivalId));
    });
  };
}

function followFestivalInProgress(festivalId) {
  return {
    type: _actionTypes.FOLLOW_FESTIVAL_IN_PROGRESS,
    festivalId: festivalId
  };
}

function followFestivalFailed(festivalId) {
  return {
    type: _actionTypes.FOLLOW_FESTIVAL_FAILED,
    festivalId: festivalId
  };
}

function followFestivalCompleted(festivalId) {
  return {
    type: _actionTypes.FOLLOW_FESTIVAL_COMPLETED,
    festivalId: festivalId
  };
}

function unfollowFestivalCompleted(festivalId) {
  return {
    type: _actionTypes.UNFOLLOW_FESTIVAL_COMPLETED,
    festivalId: festivalId
  };
}

function loadMyFollowingFestivalList(userId) {
  return function (dispatch, getState, _ref7) {
    var api = _ref7.api;
    dispatch(resetMyFollowingFestivalList());

    var getPage = function getPage(page) {
      return api.get({
        action: "/soundmix_users/".concat(userId, "/followed_festivals"),
        params: {
          page: page
        }
      }).then(function (pagedResponse) {
        dispatch(loadPartiallyMyFollowingFestivalListCompleted(pagedResponse.data));
        var range = api.getContentRange(pagedResponse.headers);

        if (range.to < range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function loadPartiallyMyFollowingFestivalListCompleted(partialFollowingFestivalList) {
  return {
    type: _actionTypes.LOAD_PART_OF_MY_FOLLOWING_FESTIVAL,
    followingFestival: partialFollowingFestivalList
  };
}

function resetMyFollowingFestivalList() {
  return {
    type: _actionTypes.RESET_MY_FESTIVAL_FOLLOWING
  };
}