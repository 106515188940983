var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasNameStrip;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

function CanvasNameStrip(_ref) {
  var containerHeight = _ref.containerHeight,
      containerWidth = _ref.containerWidth,
      verticalAlign = _ref.verticalAlign,
      name = _ref.name,
      horizontalAlign = _ref.horizontalAlign,
      _ref$style = _ref.style,
      style = _ref$style === void 0 ? {} : _ref$style;
  var width = containerWidth * 0.25;
  if (width < 100) width = 100;
  if (width > 250) width = 250;
  var height = width * 0.15;
  var fontSize = height * 0.62;
  var padding = 20;
  return _react.default.createElement(_reactKonva.Group, {
    y: verticalAlign === 'top' ? 3 : containerHeight - height - 3,
    x: horizontalAlign === 'left' ? 3 : containerWidth - width - 3,
    width: width,
    height: height
  }, _react.default.createElement(_reactKonva.Rect, {
    fill: "#101010E0",
    width: width,
    height: height,
    cornerRadius: 4
  }), _react.default.createElement(_reactKonva.Text, (0, _extends2.default)({
    x: padding / 2,
    fontSize: fontSize
  }, style, {
    height: height,
    lineHeight: 1.2,
    width: width - padding,
    fill: "#FFF",
    wrap: "none",
    ellipsis: true,
    align: "center",
    verticalAlign: "middle",
    text: name
  })));
}