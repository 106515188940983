var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAutoReadMode = exports.resetUnreadCounter = exports.sendDeleteMessageFailed = exports.sendMessageFailed = exports.sendUserUnblockFailed = exports.sendUserBlockFailed = exports.roomLeaveFailed = exports.roomConnectFailed = exports.receiveConnectionFailedError = exports.connectFailed = exports.receiveUserUnblock = exports.receiveUserBlock = exports.receiveRoomLeave = exports.receiveRoomChange = exports.receiveRoomJoin = exports.receiveUserLeave = exports.receiveUserJoin = exports.receiveDeleteMessage = exports.receiveMessage = exports.receiveConnection = exports.receiveDisconnect = exports.sendRoomChange = exports.sendDisconnect = exports.sendRoomLeave = exports.sendRoomReJoin = exports.sendRoomJoin = exports.sendUserUnblock = exports.sendUserBlock = exports.sendDeleteMessage = exports.sendMessage = exports.sendConnection = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _md = _interopRequireDefault(require("md5"));

var sendConnection = (0, _toolkit.createAction)(_constants.SEND_CONNECT);
exports.sendConnection = sendConnection;
var sendMessage = (0, _toolkit.createAction)(_constants.SEND_MESSAGE, function prepare(msg, usr, fnm, avt) {
  var iat = Date.now();
  return {
    payload: {
      message: {
        msg: msg,
        iat: iat,
        typ: 'text',
        usr: usr,
        fnm: fnm,
        avt: avt,
        sig: (0, _md.default)([usr, msg, iat].join('_'))
      }
    }
  };
});
exports.sendMessage = sendMessage;
var sendDeleteMessage = (0, _toolkit.createAction)(_constants.SEND_DELETE_MESSAGE);
exports.sendDeleteMessage = sendDeleteMessage;
var sendUserBlock = (0, _toolkit.createAction)(_constants.SEND_USER_BLOCK);
exports.sendUserBlock = sendUserBlock;
var sendUserUnblock = (0, _toolkit.createAction)(_constants.SEND_USER_UNBLOCK);
exports.sendUserUnblock = sendUserUnblock;
var sendRoomJoin = (0, _toolkit.createAction)(_constants.SEND_ROOM_JOIN);
exports.sendRoomJoin = sendRoomJoin;
var sendRoomReJoin = (0, _toolkit.createAction)(_constants.SEND_ROOM_REJOIN);
exports.sendRoomReJoin = sendRoomReJoin;
var sendRoomLeave = (0, _toolkit.createAction)(_constants.SEND_ROOM_LEAVE);
exports.sendRoomLeave = sendRoomLeave;
var sendDisconnect = (0, _toolkit.createAction)(_constants.SEND_DISCONNECT);
exports.sendDisconnect = sendDisconnect;
var sendRoomChange = (0, _toolkit.createAction)(_constants.SEND_ROOM_CHANGE);
exports.sendRoomChange = sendRoomChange;
var receiveDisconnect = (0, _toolkit.createAction)(_constants.RECEIVE_DISCONNECT);
exports.receiveDisconnect = receiveDisconnect;
var receiveConnection = (0, _toolkit.createAction)(_constants.RECEIVE_CONNECTION);
exports.receiveConnection = receiveConnection;
var receiveMessage = (0, _toolkit.createAction)(_constants.RECEIVE_MESSAGE);
exports.receiveMessage = receiveMessage;
var receiveDeleteMessage = (0, _toolkit.createAction)(_constants.RECEIVE_DELETE_MESSAGE);
exports.receiveDeleteMessage = receiveDeleteMessage;
var receiveUserJoin = (0, _toolkit.createAction)(_constants.RECEIVE_USER_JOIN);
exports.receiveUserJoin = receiveUserJoin;
var receiveUserLeave = (0, _toolkit.createAction)(_constants.RECEIVE_USER_LEAVE);
exports.receiveUserLeave = receiveUserLeave;
var receiveRoomJoin = (0, _toolkit.createAction)(_constants.RECEIVE_ROOM_JOIN);
exports.receiveRoomJoin = receiveRoomJoin;
var receiveRoomChange = (0, _toolkit.createAction)(_constants.RECEIVE_ROOM_CHANGE);
exports.receiveRoomChange = receiveRoomChange;
var receiveRoomLeave = (0, _toolkit.createAction)(_constants.RECEIVE_ROOM_LEAVE);
exports.receiveRoomLeave = receiveRoomLeave;
var receiveUserBlock = (0, _toolkit.createAction)(_constants.RECEIVE_USER_BLOCK);
exports.receiveUserBlock = receiveUserBlock;
var receiveUserUnblock = (0, _toolkit.createAction)(_constants.RECEIVE_USER_UNBLOCK);
exports.receiveUserUnblock = receiveUserUnblock;
var connectFailed = (0, _toolkit.createAction)(_constants.CONNECT_FAILED);
exports.connectFailed = connectFailed;
var receiveConnectionFailedError = (0, _toolkit.createAction)(_constants.RECEIVE_CONNECTION_FAILED_ERROR);
exports.receiveConnectionFailedError = receiveConnectionFailedError;
var roomConnectFailed = (0, _toolkit.createAction)(_constants.ROOM_CONNECT_FAILED);
exports.roomConnectFailed = roomConnectFailed;
var roomLeaveFailed = (0, _toolkit.createAction)(_constants.ROOM_LEAVE_FAILED);
exports.roomLeaveFailed = roomLeaveFailed;
var sendUserBlockFailed = (0, _toolkit.createAction)(_constants.SEND_USER_BLOCK_FAILED);
exports.sendUserBlockFailed = sendUserBlockFailed;
var sendUserUnblockFailed = (0, _toolkit.createAction)(_constants.SEND_USER_UNBLOCK_FAILED);
exports.sendUserUnblockFailed = sendUserUnblockFailed;
var sendMessageFailed = (0, _toolkit.createAction)(_constants.SEND_MESSAGE_FAILED);
exports.sendMessageFailed = sendMessageFailed;
var sendDeleteMessageFailed = (0, _toolkit.createAction)(_constants.SEND_DELETE_MESSAGE_FAILED);
exports.sendDeleteMessageFailed = sendDeleteMessageFailed;
var resetUnreadCounter = (0, _toolkit.createAction)(_constants.RESET_UNREAD_COUNTER);
exports.resetUnreadCounter = resetUnreadCounter;
var setAutoReadMode = (0, _toolkit.createAction)(_constants.SET_AUTO_READ_MODE);
exports.setAutoReadMode = setAutoReadMode;