Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useMenuItemStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    menuItemSeparator: {
      borderBottomColor: theme.colors.borderColor,
      borderBottomWidth: 1,
      borderStyle: 'solid'
    },
    linkButton: {
      width: '100%'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.bg;
}, function (theme) {
  return {
    menuItem: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing.s,
      paddingBottom: theme.spacing.s
    }
  };
}).buildHook();
var _default = useMenuItemStyles;
exports.default = _default;