Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleFormValuesChange;

var _formik = require("formik");

var _react = require("react");

var _eventRegisterProvider = require("../../event-register-provider");

function HandleFormValuesChange() {
  var _useFormikContext = (0, _formik.useFormikContext)(),
      values = _useFormikContext.values;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      onFormValuesStateChanged = _useEventRegisterCont.onFormValuesStateChanged;

  (0, _react.useEffect)(function () {
    onFormValuesStateChanged(values);
  }, [values, onFormValuesStateChanged]);
  return null;
}