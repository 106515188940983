var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ReferralListInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _reactNativePaper = require("react-native-paper");

var _ShareButton = _interopRequireDefault(require("../common/ShareButton"));

var _referral = _interopRequireDefault(require("../../core/referral"));

var _user = _interopRequireDefault(require("../../core/user"));

var _constants = require("../../core/gameSparks/constants");

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _Headline = _interopRequireDefault(require("../common/typography_v1/Headline"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _shareButtonView = _interopRequireDefault(require("./share-button/share-button-view"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ReferralListInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ReferralListInternal, _React$PureComponent);

  var _super = _createSuper(ReferralListInternal);

  function ReferralListInternal() {
    (0, _classCallCheck2.default)(this, ReferralListInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(ReferralListInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props = this.props,
          loadReferrals = _this$props.loadReferrals,
          userId = _this$props.userId,
          getNewReferralBeatsValue = _this$props.getNewReferralBeatsValue;
      loadReferrals(userId);
      getNewReferralBeatsValue(_constants.REFERRAL_BEATS);
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(item) {
      var _this = this;

      var id = item.get('id');
      var displayName = item.get('displayName');
      var nameInitials = item.get('displayName').split(' ', 2).map(function (i) {
        return i.charAt(0);
      });
      var avatar = item.getIn(['image', 'contentUrl']);
      return _react.default.createElement(_reactNative.View, {
        style: styles.userContainer,
        key: item.get('id')
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: 'avatarTouch',
        borderless: true,
        onPress: function onPress() {
          return _this.props.navigation.push('Profile', {
            userId: id,
            djName: displayName,
            avatar: avatar
          });
        }
      }, _react.default.createElement(_AvatarImage.default, {
        size: 48,
        source: {
          uri: avatar
        },
        defaultLabel: nameInitials,
        style: styles.userAvatar
      })), _react.default.createElement(_reactNative.View, {
        style: styles.userName
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        borderless: false,
        name: 'displayNameTouch',
        onPress: function onPress() {
          return _this.props.navigation.push('Profile', {
            userId: id,
            djName: displayName,
            avatar: avatar
          });
        }
      }, _react.default.createElement(_Paragraph.default, {
        color: "#ffffff",
        style: {
          fontWeight: 'bold'
        },
        numberOfLines: 1
      }, displayName))), _react.default.createElement(_FollowUserButton.default, {
        id: id,
        buttonSize: 'small',
        style: styles.followBtnStyle
      }));
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _this$props2 = this.props,
          referralsList = _this$props2.referralsList,
          referralsBeats = _this$props2.referralsBeats;
      var hasReferrals = referralsList && referralsList.size > 0;
      var beatsEarned = referralsBeats > 0 && referralsList && referralsList.size > 0 ? referralsBeats * referralsList.size : '';
      return _react.default.createElement(_reactNative.ScrollView, {
        alwaysBounceVertical: true,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        contentContainerStyle: {
          paddingHorizontal: 16,
          paddingBottom: 24
        },
        style: styles.mainContainer
      }, hasReferrals && _react.default.createElement(_reactNative.View, {
        style: styles.sectionContainer
      }, _react.default.createElement(_Headline.default, {
        style: styles.sectionTitle
      }, "You just earned ".concat(beatsEarned, " ").concat(_constants.BEATS)), _react.default.createElement(_Paragraph.default, null, "for inviting them")), hasReferrals && this.props.referralsList.toArray().map(function (el) {
        return _this2._renderItem(el);
      }), _react.default.createElement(_ShareButton.default, {
        deepLinkPath: "profile/".concat(this.props.djId),
        ogTitle: this.props.djName,
        ogImageUrl: this.props.avatar,
        ogDescription: this.props.bio,
        message: "Checkout my profile on Soundmix [APP_INVITE_URL]",
        windowTitle: "Share with Friends!"
      }, _react.default.createElement(_shareButtonView.default, null)));
    }
  }]);
  return ReferralListInternal;
}(_react.default.PureComponent);

exports.ReferralListInternal = ReferralListInternal;

ReferralListInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  return {
    headerTintColor: '#fff',
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 13
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    },
    headerLeft: _react.default.createElement(_reactNativePaper.TouchableRipple, {
      borderless: true,
      style: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0
      },
      onPress: function onPress() {
        return navigation.goBack();
      }
    }, _react.default.createElement(_CustomIcon.default, {
      name: "chevron-left",
      size: 16,
      color: "#ffffff"
    })),
    headerRight: _react.default.createElement(_reactNative.View, null)
  };
};

var styles = _reactNative.StyleSheet.create({
  mainContainer: {
    backgroundColor: 'black'
  },
  sectionContainer: {
    marginBottom: 20
  },
  sectionShareMore: {
    marginVertical: 20,
    backgroundColor: '#252525',
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sectionShareMoreText: {
    maxWidth: 200
  },
  sectionTitle: {
    color: 'white'
  },
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginVertical: 12
  },
  userAvatar: {
    marginRight: 8
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  userName: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingRight: 16,
    overflow: 'hidden'
  },
  followBtnStyle: {
    width: 74,
    paddingHorizontal: 0,
    fontSize: 13,
    lineHeight: 13
  }
});

function mapStateToProps(state) {
  return {
    userId: _user.default.selectors.getUserId(state),
    referralsList: _referral.default.selectors.getReferrals(state),
    referralsBeats: _gameSparks.default.selectors.getNewReferralBeatsValue(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadReferrals: _referral.default.actions.loadReferrals,
  getNewReferralBeatsValue: _gameSparks.default.actions.getProperty
})((0, _reactNavigation.withNavigation)(ReferralListInternal));

exports.default = _default;