var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _reactNativeVideo = _interopRequireDefault(require("react-native-video"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _liveStreamVideoContainerStyle = _interopRequireDefault(require("./live-stream-video-container-style"));

var _EventLiveOverlay = _interopRequireDefault(require("../../live/EventLiveOverlay.web"));

var _FullscreenOverlay = _interopRequireDefault(require("./overlays/FullscreenOverlay"));

var _refreshScreen = _interopRequireDefault(require("./screens/refresh-screen"));

var _backToNetworking = _interopRequireDefault(require("./screens/back-to-networking"));

var _sessionTypeConstants = require("../../../constants/session-type-constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var LiveStreamVideoContainer = function LiveStreamVideoContainer(_ref) {
  var live = _ref.live,
      muxMetaDataInfo = _ref.muxMetaDataInfo,
      _ref$videoEndedConten = _ref.videoEndedContent,
      videoEndedContent = _ref$videoEndedConten === void 0 ? null : _ref$videoEndedConten;
  var videoRef = (0, _react.useRef)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      isBuffering = _useState2[0],
      setIsBuffering = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      autoStarted = _useState4[0],
      setAutoStarted = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      videoEnded = _useState6[0],
      setVideoEnded = _useState6[1];

  var _useState7 = (0, _react.useState)(null),
      _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
      pausedAt = _useState8[0],
      setPausedAt = _useState8[1];

  var _useState9 = (0, _react.useState)(true),
      _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
      fullScreenOverlayVisible = _useState10[0],
      setFullScreenOverlayVisible = _useState10[1];

  var _useState11 = (0, _react.useState)('large'),
      _useState12 = (0, _slicedToArray2.default)(_useState11, 2),
      fullscreenOverlaySize = _useState12[0],
      setFullscreenOverlaySize = _useState12[1];

  var _useState13 = (0, _react.useState)(null),
      _useState14 = (0, _slicedToArray2.default)(_useState13, 2),
      error = _useState14[0],
      setError = _useState14[1];

  var _useState15 = (0, _react.useState)(false),
      _useState16 = (0, _slicedToArray2.default)(_useState15, 2),
      hasError = _useState16[0],
      setHasError = _useState16[1];

  var _useState17 = (0, _react.useState)(false),
      _useState18 = (0, _slicedToArray2.default)(_useState17, 2),
      fullscreen = _useState18[0],
      setFullscreen = _useState18[1];

  var _useState19 = (0, _react.useState)(false),
      _useState20 = (0, _slicedToArray2.default)(_useState19, 2),
      muted = _useState20[0],
      setMuted = _useState20[1];

  var Styles = (0, _liveStreamVideoContainerStyle.default)();
  var currentProgressValue = 0;
  (0, _react.useEffect)(function () {
    localStorage.setItem('jwplayer.enableShortcuts', false);
  }, []);
  (0, _react.useEffect)(function () {
    setTimeout(function () {
      var _document$getElementB;

      setFullscreenOverlaySize(((_document$getElementB = document.getElementById('live-video')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.offsetWidth) < 1280 ? 'small' : 'large');
    }, 300);
  }, [fullscreen, videoRef.current]);
  (0, _react.useEffect)(function () {
    setError(null);
    setHasError(false);
    setVideoEnded(false);
  }, [live, setError, setHasError, setVideoEnded]);

  var toggleFullscreen = function toggleFullscreen() {
    if (fullscreen) {
      exitFullscreen().then(function () {
        setFullscreen(false);
      }).catch(function () {
        allowFullscreen();
        setFullscreen(true);
      });
    } else {
      allowFullscreen(function () {
        setFullscreen(true);
      }).catch(function () {
        exitFullscreen();
        setFullscreen(false);
      });
    }

    setFullscreen(!fullscreen);
  };

  function allowFullscreen() {
    return _allowFullscreen.apply(this, arguments);
  }

  function _allowFullscreen() {
    _allowFullscreen = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var elem;
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              elem = document.getElementsByClassName('live-video')[0];

              if (!elem) {
                _context.next = 16;
                break;
              }

              if (!elem.requestFullscreen) {
                _context.next = 6;
                break;
              }

              return _context.abrupt("return", elem.requestFullscreen());

            case 6:
              if (!elem.mozRequestFullScreen) {
                _context.next = 10;
                break;
              }

              return _context.abrupt("return", elem.mozRequestFullScreen());

            case 10:
              if (!elem.webkitRequestFullscreen) {
                _context.next = 14;
                break;
              }

              return _context.abrupt("return", elem.webkitRequestFullscreen());

            case 14:
              if (!elem.msRequestFullscreen) {
                _context.next = 16;
                break;
              }

              return _context.abrupt("return", elem.msRequestFullscreen());

            case 16:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));
    return _allowFullscreen.apply(this, arguments);
  }

  function exitFullscreen() {
    return _exitFullscreen.apply(this, arguments);
  }

  function _exitFullscreen() {
    _exitFullscreen = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!document.exitFullscreen) {
                _context2.next = 4;
                break;
              }

              return _context2.abrupt("return", document.exitFullscreen());

            case 4:
              if (!document.webkitExitFullscreen) {
                _context2.next = 8;
                break;
              }

              return _context2.abrupt("return", document.webkitExitFullscreen());

            case 8:
              if (!document.mozCancelFullScreen) {
                _context2.next = 12;
                break;
              }

              return _context2.abrupt("return", document.mozCancelFullScreen());

            case 12:
              if (!document.msExitFullscreen) {
                _context2.next = 14;
                break;
              }

              return _context2.abrupt("return", document.msExitFullscreen());

            case 14:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));
    return _exitFullscreen.apply(this, arguments);
  }

  function jumpToLive() {
    if (live.type === _sessionTypeConstants.PRE_RECORDED_SESSION_TYPE) {
      var now = new Date();
      var elapsedTime = now - new Date(live.startTime);
      var timeLeftUntilEnd = new Date(live.endTime) - now;

      if (elapsedTime > 10000 && timeLeftUntilEnd > 0) {
        var timePassedFromStart = Math.round(Math.abs(elapsedTime) / 1000);

        if (videoRef.current && videoRef.current.player) {
          videoRef.current.player.seek(timePassedFromStart);
        }
      }
    }
  }

  var handleBuffering = function handleBuffering(e) {
    setIsBuffering(e.reason === 'loading');
  };

  var handleBufferChange = function handleBufferChange() {};

  var handlePlay = function handlePlay() {
    setIsBuffering(false);
    jumpToLive();
  };

  var handleReady = function handleReady() {
    setAutoStarted(false);
  };

  var handleResume = function handleResume() {
    var pauseTime = pausedAt ? new Date() - pausedAt : 0;

    if (pauseTime > 5000) {
      jumpToLive();
    }

    setPausedAt(null);
  };

  var handlePause = function handlePause() {
    setPausedAt(new Date());
  };

  var handleAutoStart = function handleAutoStart() {
    if (!autoStarted) {
      jumpToLive();
      setAutoStarted(true);
    }
  };

  var handleProgress = function handleProgress(progress) {
    if (progress.currentTime > currentProgressValue && isBuffering) {
      setIsBuffering(false);
    } else if (progress.currentTime <= currentProgressValue && !isBuffering) {
      setIsBuffering(true);
    }

    currentProgressValue = progress.currentTime;
    console.log(progress);
  };

  var handleCasting = function handleCasting(castObj) {
    try {
      if (castObj.available && castObj.active) {
        setTimeout(function () {
          setFullScreenOverlayVisible(false);
        }, 1500);
      } else {
        jumpToLive();
        setTimeout(function () {
          setFullScreenOverlayVisible(true);
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (videoEnded && videoEndedContent) return videoEndedContent;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.liveVideoContainer
  }, _react.default.createElement("div", {
    className: "live-video",
    id: "live-video"
  }, !hasError && live && (live === null || live === void 0 ? void 0 : live.streamingPageUrl) && _react.default.createElement(_reactNativeVideo.default, {
    ref: videoRef,
    style: Styles.webVideo,
    source: {
      uri: live.streamingPageUrl
    },
    onBuffer: handleBuffering,
    onBufferChange: handleBufferChange,
    onPlay: handlePlay,
    onReady: handleReady,
    onResume: handleResume,
    onPause: handlePause,
    onMute: function onMute() {
      return setMuted(true);
    },
    onUnMute: function onUnMute() {
      return setMuted(false);
    },
    onAutoStart: handleAutoStart,
    onOneHundredPercent: function onOneHundredPercent() {
      return setVideoEnded(true);
    },
    onProgress: handleProgress,
    onError: function onError(e) {
      if (e && (e.code === 232412 || e.code === 230001 || e.code === 224003 || e.code === 220001)) {
        setHasError(true);
        setError(_i18n.default.t('liveNow.tryAgain'));
      }
    },
    key: live.id,
    onCast: function onCast(castObj) {
      return handleCasting(castObj);
    },
    autoPlay: true,
    muted: muted,
    jwplayer: true,
    muxMetaDataInfo: muxMetaDataInfo
  }), !error && _react.default.createElement(_EventLiveOverlay.default, {
    startDate: live.startTime ? live.startTime : new Date()
  }), !error && !window.navigator.userAgent.match(/iphone|ipod/i) && fullScreenOverlayVisible && _react.default.createElement(_FullscreenOverlay.default, {
    toggleFullscreen: toggleFullscreen,
    overlaySize: fullscreenOverlaySize
  }), hasError && error && _react.default.createElement(_refreshScreen.default, {
    clearErrors: function clearErrors() {
      setHasError(false);
      setError(null);
    }
  }), (!live || !live.streamingPageUrl) && _react.default.createElement(_backToNetworking.default, null))));
};

var _default = LiveStreamVideoContainer;
exports.default = _default;