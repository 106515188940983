var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoteOrLocalScreenShare;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _GridItem = _interopRequireDefault(require("./GridItem"));

var _Video = _interopRequireDefault(require("./Video"));

function RemoteOrLocalScreenShare(_ref) {
  var isLocal = _ref.isLocal,
      videoTrack = _ref.videoTrack,
      grid = _ref.grid,
      children = _ref.children;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      screenShareVideo = _useAudioVideoContext.screenShareVideo;

  return _react.default.createElement(_GridItem.default, grid, _react.default.createElement(_Video.default, (0, _extends2.default)({}, grid, {
    videoTrack: isLocal ? screenShareVideo : videoTrack
  })), children);
}