var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _ImageWithLoading = _interopRequireDefault(require("../../../../../theme/components/image/ImageWithLoading"));

var _DynamicSection = _interopRequireDefault(require("../../../../../theme/components/section/DynamicSection"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../../theme/DesignSystem");

var _absoluteSpinner = _interopRequireDefault(require("../../../../common/absolute-spinner"));

var _speakerStyles = _interopRequireDefault(require("./speaker-styles"));

var _stringFormatter = require("../../../../../utils/string-formatter");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var TableSpeakerView = function TableSpeakerView(_ref) {
  var participant = _ref.participant;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shape = _useTheme.shape,
      spacing = _useTheme.spacing;

  var Styles = (0, _speakerStyles.default)();
  return _react.default.createElement(_ImageWithLoading.default, {
    loadingIndicator: _react.default.createElement(_DynamicSection.default, {
      parentBackground: "pageBackground",
      shaped: true,
      style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
        backgroundColor: 'transparent',
        borderRadius: '100%'
      })
    }, _react.default.createElement(_absoluteSpinner.default, {
      color: "transparent"
    })),
    errorFallback: _react.default.createElement(_DynamicSection.default, {
      parentBackground: "pageBackground",
      shaped: true,
      style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: spacing.xxl,
        borderColor: colors.containerBackground,
        borderWidth: 2
      })
    }, function (textColor) {
      return _react.default.createElement(_reactNative.Text, {
        color: textColor
      }, _react.default.createElement(_core.Typography, {
        align: "center",
        color: "inherit"
      }, participant !== null && participant !== void 0 && participant.name ? (0, _stringFormatter.initials)(participant.name) : 'Anonymus'));
    }),
    containerProps: {
      style: _objectSpread({
        position: 'relative'
      }, shape.cards),
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: spacing.xxl,
      borderColor: colors.containerBackground,
      borderWidth: 2
    },
    imageProps: {
      style: [Styles.image, _objectSpread(_objectSpread({}, shape.cards), {}, {
        backgroundColor: colors.containerBackground,
        borderRadius: spacing.xxl,
        borderColor: colors.containerBackground,
        borderWidth: 2
      })],
      resizeMode: 'cover'
    },
    url: (participant === null || participant === void 0 ? void 0 : participant.picture) || ' '
  });
};

var _default = TableSpeakerView;
exports.default = _default;