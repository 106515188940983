var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MyProfileScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNativePaper = require("react-native-paper");

var _user = _interopRequireDefault(require("../../core/user"));

var _ProfileScreen = _interopRequireDefault(require("./ProfileScreen"));

var _reactNative = require("react-native");

var _Button = _interopRequireDefault(require("../common/Button"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _Title = _interopRequireDefault(require("../common/typography_v1/Title"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var MyProfileScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(MyProfileScreenInternal, _React$PureComponent);

  var _super = _createSuper(MyProfileScreenInternal);

  function MyProfileScreenInternal() {
    (0, _classCallCheck2.default)(this, MyProfileScreenInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(MyProfileScreenInternal, [{
    key: "render",
    value: function render() {
      var _this = this;

      if (this.props.isLoggedIn === false) {
        var colors = this.props.theme.colors;
        return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
          style: {
            backgroundColor: colors.background,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: 16
          },
          testID: "MyProfileScreen"
        }, _react.default.createElement(_Title.default, {
          style: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center'
          }
        }, "To access your account"), _react.default.createElement(_Paragraph.default, {
          style: {
            marginBottom: 20
          }
        }, "Please login"), _react.default.createElement(_Button.default, {
          name: "loginButton",
          mode: "outlined",
          buttonSize: "large",
          onPress: function onPress() {
            return _this.props.navigation.navigate('Login');
          },
          style: {
            width: '100%',
            maxWidth: 400
          }
        }, "Login")));
      }

      return _react.default.createElement(_ProfileScreen.default, {
        userId: this.props.userId,
        isMyProfile: true
      });
    }
  }]);
  return MyProfileScreenInternal;
}(_react.default.PureComponent);

exports.MyProfileScreenInternal = MyProfileScreenInternal;

function mapStateToProps(state) {
  return {
    userId: _user.default.selectors.getFirebaseUserUid(state),
    isLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNativePaper.withTheme)(MyProfileScreenInternal));

exports.default = _default;