var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Card = _interopRequireDefault(require("design-system/structure/Card"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _AvatarImage = _interopRequireDefault(require("../../common/avatar/AvatarImage"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _reactNative = require("react-native");

var _blockedUsersStyles = _interopRequireDefault(require("./blocked-users-styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var BlockedUsersView = function BlockedUsersView(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var blockedUsersList = props.blockedUsersList,
      onPressUnblock = props.onPressUnblock,
      unblockInProgress = props.unblockInProgress;

  var keyExtractor = function keyExtractor(item, index) {
    return index.toString();
  };

  (0, _react.useEffect)(function () {
    return setLoading(unblockInProgress);
  }, [unblockInProgress]);

  var renderItem = function renderItem(_ref) {
    var item = _ref.item;
    var id = item.getIn(['blockedUser', 'id']);
    var displayName = item.getIn(['blockedUser', 'displayName']);
    var imageUrl = item.getIn(['blockedUser', 'image', 'contentUrl']);
    var nameInitials = displayName.split(' ', 2).map(function (i) {
      return i.charAt(0);
    });

    var onPressButton = function onPressButton() {
      return onPressUnblock(id);
    };

    return _react.default.createElement(_section.default, {
      spacingTop: "base"
    }, _react.default.createElement(_Card.default.Title, {
      inverted: true,
      title: displayName.toString(),
      size: "small",
      left: function left() {
        return _react.default.createElement(_AvatarImage.default, {
          size: 40,
          source: {
            uri: imageUrl
          },
          defaultLabel: nameInitials
        });
      },
      right: function right() {
        return _react.default.createElement(_button.default, {
          name: "unblockButton",
          onPress: onPressButton,
          loading: loading
        }, "Unblock");
      }
    }));
  };

  var renderEmptyList = function renderEmptyList() {
    return _react.default.createElement(_section.default, {
      spacingVertical: "loose"
    }, _react.default.createElement(_Stack.default, {
      distribution: "center"
    }, _react.default.createElement(_textView.default, {
      textStyle: "center"
    }, "You aren't blocking any accounts")));
  };

  return _react.default.createElement(_section.default, {
    style: _blockedUsersStyles.default.container,
    testID: "BlockedUsersListView"
  }, _react.default.createElement(_reactNative.FlatList, {
    directionalLockEnabled: true,
    pinchGestureEnabled: false,
    data: blockedUsersList.toArray(),
    renderItem: renderItem,
    ListEmptyComponent: renderEmptyList,
    keyExtractor: keyExtractor
  }));
};

var _default = BlockedUsersView;
exports.default = _default;