var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Separator;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _menuItemStyles = _interopRequireDefault(require("./menu-item-styles"));

function Separator() {
  var Styles = (0, _menuItemStyles.default)();
  return _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    style: Styles.menuItemSeparator
  });
}