var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Chat;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _actions = require("../../core/chat/actions");

var _constants = require("../../core/chat/constants");

var _selectors = require("../../core/chat/selectors");

var _selectors2 = require("../../core/user/selectors");

var _ContainedButton = _interopRequireDefault(require("../../theme/components/buttons/ContainedButton"));

var _OutlinedButton = _interopRequireDefault(require("../../theme/components/buttons/OutlinedButton"));

var _Divider = _interopRequireDefault(require("../../theme/components/divider/Divider"));

var _Clap = _interopRequireDefault(require("../../theme/components/icons/Clap"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _numberUtils = require("../../utils/number-utils");

var _ChatInput = _interopRequireDefault(require("./ChatInput"));

var _ClapCounter = _interopRequireDefault(require("./ClapCounter"));

var _CommentList = _interopRequireDefault(require("./CommentList"));

var _FloatingFires = _interopRequireDefault(require("./FloatingFires"));

var _merge = _interopRequireDefault(require("lodash/merge"));

var _selectors3 = require("../../core/user-roles/selectors");

var _externalLinkUtils = require("../../utils/external-link-utils");

var _LinkButton = _interopRequireDefault(require("../../theme/components/buttons/LinkButton"));

var _actions2 = require("../../core/chat-reactions/actions");

var _selectors4 = require("../../core/user-profile/selectors");

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../common/custom-design-components/MuiLinkButton"));

var _clsx = _interopRequireDefault(require("clsx"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var defaultTranslations = {
  noCommentsYet: 'chat.writeAComment',
  inputText: 'chat.saySomethingNice',
  reconnecting: 'chat.reconnecting',
  blocked: 'chat.youBlockedFromChat',
  disconnected: 'chat.youDisconnectedFromChat',
  disabledMessage: 'chat.disabledMessage'
};
var firewallSupportLink = 'https://support.eventmix.live/en/behind-a-firewall-that-is-blocking-the-event-website';

function Chat(_ref) {
  var id = _ref.id,
      rejoinId = _ref.rejoinId,
      disabled = _ref.disabled,
      disabledMessage = _ref.disabledMessage,
      _ref$translations = _ref.translations,
      translations = _ref$translations === void 0 ? defaultTranslations : _ref$translations;
  var socketStatus = (0, _reactRedux.useSelector)(_selectors.socketStatusSelector);
  var chatRoom = (0, _reactRedux.useSelector)(_selectors.currentRoomSelector);
  var loading = (0, _reactRedux.useSelector)(_selectors.roomLoadingSelector);
  var loaded = (0, _reactRedux.useSelector)(_selectors.roomLoadedSelector);
  var uid = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var fnm = (0, _reactRedux.useSelector)(_selectors4.selectUserDisplayName);
  var avt = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserAvatar);
  var messages = (0, _reactRedux.useSelector)(_selectors.messagesAndStatusSelector);
  var dispatch = (0, _reactRedux.useDispatch)();

  var _React$useState = _react.default.useState(),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      scroll = _React$useState2[0],
      setScroll = _React$useState2[1];

  var isBlocked = (0, _reactRedux.useSelector)(_selectors.imBlockedSelector);

  var retry = _react.default.useRef(0);

  var role = (0, _reactRedux.useSelector)((0, _selectors3.userHighestRoleSelector)(id));
  var classes = (0, _styles.default)();

  var _translations = (0, _merge.default)(_objectSpread({}, defaultTranslations), translations);

  _react.default.useEffect(function () {
    id && dispatch((0, _actions.sendRoomJoin)(id));
    return function () {
      id && dispatch((0, _actions.sendRoomLeave)());
    };
  }, [id]);

  _react.default.useEffect(function () {
    if (!chatRoom && rejoinId && socketStatus === _constants.WEBSOCKET_STATUS.CONNECTED && retry.current < 4) {
      dispatch((0, _actions.sendRoomChange)(rejoinId));
      retry.current = retry.current + 1;
    }

    if (chatRoom) {
      retry.current = 0;
    }
  }, [rejoinId, chatRoom, socketStatus]);

  if (isBlocked) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate(_translations.blocked)));

  if (_constants.WEBSOCKET_STATUS.DISCONNECTED === socketStatus) {
    return _react.default.createElement(_Spacing.default, {
      flex: 1,
      alignItems: "center",
      direction: "column",
      mSpacing: "s",
      justifyContent: "center"
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.translate('chat.youDisconnectedFromChat')), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s']
    }, _react.default.createElement(_ContainedButton.default, {
      onPress: function onPress() {
        return dispatch((0, _actions.sendConnection)());
      }
    }, _i18n.default.translate('chat.retry'))));
  }

  if (loading) {
    return _react.default.createElement(_Spacing.default, {
      flex: 1,
      alignItems: "stretch",
      direction: "column"
    }, _react.default.createElement(_reactNative.ScrollView, {
      showsVerticalScrollIndicator: false
    }, _react.default.createElement(_reactContentLoader.default, {
      speed: 2,
      viewBox: "0 0 500 1080",
      backgroundColor: "#f3f3f3",
      foregroundColor: "#ecebeb"
    }, _react.default.createElement("circle", {
      cx: "70.2",
      cy: "73.2",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "29.5",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "64.7",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "97.8",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "132.3",
      width: "212.5",
      height: "17"
    }), _react.default.createElement("circle", {
      cx: "70.7",
      cy: "243.5",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "199.9",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "235",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "268.2",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "302.6",
      width: "212.5",
      height: "17"
    }), _react.default.createElement("circle", {
      cx: "70.7",
      cy: "412.7",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "369",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "404.2",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "437.3",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "471.8",
      width: "212.5",
      height: "17"
    }), _react.default.createElement("circle", {
      cx: "70.2",
      cy: "608.9",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "565.2",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "599.7",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "634.2",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "129.9",
      y: "668.7",
      width: "212.5",
      height: "17"
    }), _react.default.createElement("circle", {
      cx: "70.7",
      cy: "805.8",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "762.1",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "796.6",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "831.1",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "865.6",
      width: "212.5",
      height: "17"
    }), _react.default.createElement("circle", {
      cx: "70.7",
      cy: "1002.7",
      r: "41.3"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "959",
      width: "125.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "993.5",
      width: "296",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "1028",
      width: "253.5",
      height: "17"
    }), _react.default.createElement("rect", {
      x: "130.4",
      y: "1062.5",
      width: "212.5",
      height: "17"
    }))), _react.default.createElement(_reactContentLoader.default, {
      speed: 2,
      viewBox: "0 0 500 100",
      backgroundColor: "#f3f3f3",
      foregroundColor: "#ecebeb"
    }, _react.default.createElement("rect", {
      x: "25",
      y: "40",
      width: "400",
      height: "35"
    }), _react.default.createElement("rect", {
      x: "435",
      y: "40",
      width: "35",
      height: "35"
    })));
  }

  if (!chatRoom) {
    return _react.default.createElement(_Spacing.default, {
      flex: 1,
      alignItems: "center",
      direction: "column",
      justifyContent: "center"
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.translate('chat.youNotInChatRoom')), _react.default.createElement(_core.Typography, {
      align: "center"
    }, _react.default.createElement(_MuiLinkButton.default, {
      translationKey: "chat.firewallClickHere",
      onClick: function onClick() {
        return (0, _externalLinkUtils.openExternalUrl)(firewallSupportLink);
      },
      variant: "caption"
    }), _i18n.default.translate('chat.firewallInstructions')), _react.default.createElement(_Spacing.default, {
      mSpacing: ['s']
    }, _react.default.createElement(_ContainedButton.default, {
      onPress: function onPress() {
        return dispatch((0, _actions.sendRoomReJoin)(rejoinId));
      }
    }, _i18n.default.translate('chat.join'))));
  }

  var _renderComments = function _renderComments() {
    if (disabled) return _react.default.createElement(_Spacing.default, {
      flex: 1,
      alignItems: "center",
      direction: "column",
      pSpacing: "l",
      justifyContent: "center"
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, disabledMessage || _i18n.default.translate(_translations.disabledMessage, {
      defaultValue: _translations.disabledMessage
    })));
    if (messages.length) return _react.default.createElement(_CommentList.default, {
      onDelete: function onDelete(message) {
        return dispatch((0, _actions.sendDeleteMessage)({
          message: message
        }));
      },
      onBlock: function onBlock(user) {
        return dispatch((0, _actions.sendUserBlock)({
          user: user
        }));
      },
      messages: messages,
      scrollToBottom: scroll,
      role: role
    });
    return _react.default.createElement(_Spacing.default, {
      flex: 1,
      alignItems: "center",
      direction: "column",
      pSpacing: "l",
      justifyContent: "center"
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.translate(loaded ? _translations.noCommentsYet : _translations.reconnecting)));
  };

  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1
  }, _renderComments(), _react.default.createElement(_ClapCounter.default, null, function (claps) {
    return _react.default.createElement(_FloatingFires.default, {
      count: claps
    });
  }), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: {
      position: 'relative'
    }
  }, _react.default.createElement(_Divider.default, {
    width: 1,
    spacing: "s"
  })), _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "center",
    pSpacing: "s",
    justifyContent: "space-between"
  }, _react.default.createElement(_ChatInput.default, {
    disabled: !chatRoom || loading || disabled,
    onMessage: function onMessage(message) {
      dispatch((0, _actions.sendMessage)(message, uid, fnm, avt));
      setScroll(function (oldScroll) {
        return !oldScroll;
      });
    },
    placeholder: _i18n.default.translate(_translations.inputText)
  }), _react.default.createElement(_ClapCounter.default, null, function (claps) {
    return _react.default.createElement(_OutlinedButton.default, {
      iconLeft: _react.default.createElement(_Clap.default, {
        width: "1rem",
        height: "1rem",
        style: {
          position: 'relative',
          top: '1px',
          left: '1px'
        }
      }),
      badge: claps > 0 ? _react.default.createElement(_core.Typography, {
        className: (0, _clsx.default)(classes.badgeText, classes.textTruncate),
        variant: "caption"
      }, (0, _numberUtils.numberFormatter)(claps)) : null,
      badgePosition: "topRight",
      disabled: !chatRoom || loading || disabled,
      color: "alternativeText",
      spacing: "s",
      onPress: function onPress() {
        return dispatch((0, _actions2.increaseChatReaction)(chatRoom));
      }
    });
  })));
}