var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginFinalizeContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _authStyles = _interopRequireDefault(require("../../auth-styles"));

var _selectors = require("../../../../core/auth/selectors");

var _Redirect = _interopRequireDefault(require("../../../../navigation/client/Redirect"));

var _useHeader = _interopRequireDefault(require("../../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _actions = require("../../../../core/user/actions");

var _loginFinalizeView = _interopRequireDefault(require("./login-finalize-view"));

var _Section = _interopRequireDefault(require("../../../../theme/components/section/Section"));

var _reactNative = require("react-native");

var _homeButton = _interopRequireDefault(require("../../home-button"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _actions2 = require("../../../../core/auth/actions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function LoginFinalizeContainer() {
  var Styles = (0, _authStyles.default)();
  var error = (0, _reactRedux.useSelector)(_selectors.getLoginError);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.isLoginLoading);
  var navigation = (0, _useNavigationExtended.default)();
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useState = (0, _react.useState)(window.localStorage.getItem('emailForSignIn')),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loginForEmail = _useState2[0],
      setLoginForEmail = _useState2[1];

  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });
  (0, _react.useEffect)(function () {
    dispatch((0, _actions2.removeLoginError)());
  }, []);
  (0, _react.useEffect)(function () {
    if (loginForEmail) {
      dispatch((0, _actions.firebaseEmailLogin)(loginForEmail));
      window.localStorage.removeItem('emailForSignIn');
    }
  }, [loginForEmail]);

  if (!_reactNativeFirebase.default.auth().isSignInWithEmailLink(window.location.href)) {
    return _react.default.createElement(_Redirect.default, {
      to: _routesConsts.Routes.HOME
    });
  }

  if (!loginForEmail || error) {
    return _react.default.createElement(_Section.default, {
      style: {
        flex: 1
      },
      color: "pageBackground"
    }, _react.default.createElement(_reactNative.ScrollView, {
      contentContainerStyle: Styles.scrollView
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: ['xl', 0, 0, 0]
    }, _react.default.createElement(_homeButton.default, null)), _react.default.createElement(_loginFinalizeView.default, {
      onLogin: function onLogin(email) {
        return setLoginForEmail(email);
      },
      goToRegister: function goToRegister() {
        return navigation.navigate(_routesConsts.Routes.REGISTER);
      },
      goToLogin: function goToLogin() {
        return navigation.navigate(_routesConsts.Routes.LOGIN);
      },
      loginFeedback: error,
      isLoading: isLoading
    })));
  }

  return _react.default.createElement(_react.default.Fragment, null);
}