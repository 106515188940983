var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTestimonials = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _constants = require("./constants");

var getTestimonials = (0, _toolkit.createAsyncThunk)(_constants.GET_TESTIMONIALS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var getState, eventId, testimonials;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.testimonials(eventId));

          case 6:
            testimonials = _context.sent;
            return _context.abrupt("return", testimonials);

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getTestimonials = getTestimonials;