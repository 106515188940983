var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VipPopupInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNavigation = require("react-navigation");

var _reactRedux = require("react-redux");

var _reactNativeRenderHtml = _interopRequireDefault(require("react-native-render-html"));

var _Button = _interopRequireDefault(require("../Button"));

var _Title = _interopRequireDefault(require("../typography_v1/Title"));

var _CustomCachedImage = _interopRequireDefault(require("../CustomCachedImage"));

var _Analytics = require("../../../core/services/Analytics");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var EUR = '€';
var USD = '$';
var RON = 'RON';

var VipPopupInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(VipPopupInternal, _React$PureComponent);

  var _super = _createSuper(VipPopupInternal);

  function VipPopupInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, VipPopupInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePressBuyTicket = function () {};

    _this.handlePressMaybeLater = function () {
      if (_this.props.isFestivalPopup) {
        _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());
      } else {
        _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());

        _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());
      }
    };

    return _this;
  }

  (0, _createClass2.default)(VipPopupInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.isContinue) {
        (0, _Analytics.trackEvent)('present_offer', {
          item_name: this.props.ticket.get('continueTitle')
        });
      } else {
        (0, _Analytics.trackEvent)('present_offer', {
          item_name: this.props.ticket.get('title')
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var ticket = this.props.ticket;
      var price = ticket.get('price');
      var oldPrice = ticket.get('oldPrice');
      var callToAction = ticket.get('callToAction');
      var image = ticket.get('image').get('contentUrl');
      var title = ticket.get('title');
      var description = ticket.get('description');
      var continueTitle = ticket.get('continueTitle');
      var continueDescription = ticket.get('continueDescription');
      var currency = ticket.get('currency');
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.card
      }, _react.default.createElement(_reactNative.View, {
        style: styles.titleParent
      }, _react.default.createElement(_reactNative.Text, {
        style: styles.titleText
      }, this.props.isContinue ? continueTitle ? continueTitle : title : title)), image && _react.default.createElement(_CustomCachedImage.default, {
        style: styles.image,
        source: {
          uri: image
        },
        resizeMode: "cover"
      }), (description || oldPrice || price) && _react.default.createElement(_reactNative.View, {
        style: styles.infoDetails
      }, this.props.isContinue && continueDescription && _react.default.createElement(_reactNative.View, {
        style: {
          paddingVertical: 8,
          paddingHorizontal: 32
        }
      }, _react.default.createElement(_reactNative.Text, {
        style: styles.continueDescriptionText
      }, continueDescription)), description && _react.default.createElement(_reactNative.View, {
        style: styles.descriptionText
      }, _react.default.createElement(_reactNativeRenderHtml.default, {
        html: description,
        tagsStyles: {
          p: {
            color: 'black',
            fontSize: 15,
            lineHeight: 23,
            textAlign: 'center'
          }
        }
      })), oldPrice && _react.default.createElement(_reactNative.View, {
        style: {
          paddingVertical: 8
        },
        testID: "RegularPrice"
      }, currency === RON ? _react.default.createElement(_reactNative.Text, null, _react.default.createElement(_reactNative.Text, {
        style: {
          fontSize: 15,
          lineHeight: 18
        }
      }, 'Regular price:'), _react.default.createElement(_reactNative.Text, {
        style: styles.regularPriceText
      }, ' ', oldPrice, " ", currency)) : currency === 'EUR' ? _react.default.createElement(_reactNative.Text, null, _react.default.createElement(_reactNative.Text, {
        style: {
          fontSize: 15,
          lineHeight: 18
        }
      }, 'Regular price: '), _react.default.createElement(_reactNative.Text, {
        style: styles.regularPriceText
      }, EUR, oldPrice)) : currency === 'USD' ? _react.default.createElement(_reactNative.Text, null, _react.default.createElement(_reactNative.Text, {
        style: {
          fontSize: 15,
          lineHeight: 18
        }
      }, 'Regular price: '), _react.default.createElement(_reactNative.Text, {
        style: styles.regularPriceText
      }, USD, oldPrice)) : _react.default.createElement(_reactNative.Text, null, _react.default.createElement(_reactNative.Text, {
        style: {
          fontSize: 15,
          lineHeight: 18
        }
      }, 'Regular price: '), _react.default.createElement(_reactNative.Text, {
        style: styles.regularPriceText
      }, currency, oldPrice))), price && _react.default.createElement(_reactNative.View, {
        style: {
          paddingVertical: 8
        },
        testID: "Price"
      }, currency === RON ? _react.default.createElement(_Title.default, {
        style: styles.priceText
      }, 'Only ', price, " ", currency) : currency === 'EUR' ? _react.default.createElement(_Title.default, {
        style: styles.priceText
      }, 'Only ', EUR, price) : currency === 'USD' ? _react.default.createElement(_Title.default, {
        style: styles.priceText
      }, 'Only ', USD, price) : _react.default.createElement(_Title.default, {
        style: styles.priceText
      }, 'Only ', currency, price))), _react.default.createElement(_reactNative.View, {
        style: styles.textCallToActionParent
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "callToActionButton",
        onPress: this.handlePressBuyTicket
      }, _react.default.createElement(_reactNative.Text, {
        style: styles.textCallToAction
      }, callToAction)))), _react.default.createElement(_Button.default, {
        mode: "outlined",
        testID: "maybeLaterButton",
        onPress: this.handlePressMaybeLater,
        style: {
          marginTop: 24
        },
        buttonSize: "small",
        color: "#ffffff",
        dark: false
      }, 'Maybe Later')));
    }
  }]);
  return VipPopupInternal;
}(_react.default.PureComponent);

exports.VipPopupInternal = VipPopupInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  card: {
    flexDirection: 'column',
    width: '80%',
    borderRadius: 8
  },
  titleParent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFE10D',
    paddingVertical: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  titleText: {
    color: 'black',
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: -0.3
  },
  image: {
    height: 150,
    width: '100%'
  },
  infoDetails: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12
  },
  continueDescriptionText: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
    textAlign: 'center'
  },
  descriptionText: {
    paddingVertical: 8,
    opacity: 0.5,
    paddingBottom: 20,
    letterSpacing: -0.3
  },
  regularPriceText: {
    fontSize: 15,
    lineHeight: 18,
    textDecorationLine: 'line-through',
    textDecorationColor: '#FF3333',
    letterSpacing: -0.3
  },
  priceText: {
    fontSize: 17,
    lineHeight: 20,
    letterSpacing: -0.3,
    color: 'black',
    fontWeight: 'bold'
  },
  textCallToActionParent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFE10D',
    paddingVertical: 12,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  textCallToAction: {
    color: 'black',
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold'
  }
});

var _default = (0, _reactRedux.connect)(undefined, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(VipPopupInternal)));

exports.default = _default;