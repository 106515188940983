var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.stepperType = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _queryParameter = require("../../../../utils/queryParameter");

var _Steps = _interopRequireDefault(require("../steps/Steps"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _selectors = require("../../../../core/checkout/selectors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var stepperType = {
  login: 'login',
  registration: 'registration'
};
exports.stepperType = stepperType;

var RegistrationStepper = function RegistrationStepper(_ref) {
  var children = _ref.children,
      type = _ref.type;
  var activeStep = (0, _queryParameter.getUrlRoute)(window.location.href);
  var checkoutIntent = (0, _reactRedux.useSelector)(_selectors.checkoutIntentSelector);
  var selectedAddonVariations = (0, _reactRedux.useSelector)(_selectors.selectedAddonVariationsSelector) || [];
  var ticket = (0, _reactRedux.useSelector)(_selectors.selectedTicketSelector);
  var addons = (0, _reactRedux.useSelector)(_selectors.selectedTicketAddonsSortedSelector);
  var areSomeAddonsPaid = selectedAddonVariations.some(function (addon) {
    return addon.price !== 0;
  });
  var createRef = (0, _react.useCallback)(function () {
    var steps = type === stepperType.login ? [] : [{
      route: _routesConsts.Routes.TICKET_CONFIGURATION,
      label: _i18n.default.translate('configureTicket.title')
    }, addons.length ? {
      route: _routesConsts.Routes.TICKET_ADDONS,
      label: _i18n.default.translate('addOns.title')
    } : null, {
      route: _routesConsts.Routes.REGISTER,
      label: _i18n.default.translate('register.registration')
    }, (checkoutIntent === null || checkoutIntent === void 0 ? void 0 : checkoutIntent.type) === 'Paid' || areSomeAddonsPaid ? {
      route: _routesConsts.Routes.PAYMENT_FORM,
      label: _i18n.default.translate('checkout.payment')
    } : null];
    return {
      Stepper: steps.length ? _react.default.createElement(_Steps.default, {
        steps: steps
      }) : null
    };
  }, [activeStep, type, ticket, addons]);
  return children ? children(createRef()) : null;
};

var _default = RegistrationStepper;
exports.default = _default;