var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ToggleAudio;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _Spacing = _interopRequireDefault(require("./../../../theme/components/spacing/Spacing"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user-rooms/actions");

var _selectors = require("../../../core/user/selectors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ToggleAudio(_ref) {
  var roomId = _ref.roomId,
      speakerId = _ref.speakerId,
      color = _ref.color;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      audioEnabled = _useAudioVideoContext.audioEnabled;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      reserved = _useState2[0],
      setReserved = _useState2[1];

  var dispatch = (0, _reactRedux.useDispatch)();
  var requestingUserId = (0, _reactRedux.useSelector)(_selectors.getFirebaseUserUid);
  var Styles = (0, _audioVideoStyles.default)();

  var onToggleAudio = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setReserved(true);
              _context.next = 3;
              return dispatch((0, _actions.muteUnmuteSpeaker)({
                speakerId: speakerId,
                requestingUserId: requestingUserId,
                roomId: roomId
              }));

            case 3:
              setReserved(false);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onToggleAudio() {
      return _ref2.apply(this, arguments);
    };
  }();

  return _react.default.createElement(_ColoredIconButton.default, {
    customColor: color,
    disabled: reserved,
    onClick: onToggleAudio
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, audioEnabled ? _react.default.createElement(_reactFeather.Mic, null) : _react.default.createElement(_reactFeather.MicOff, null)));
}