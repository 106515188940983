var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _CustomIcon = _interopRequireDefault(require("../../../../src/assets/icons/CustomIcon"));

var _titleStyles = _interopRequireDefault(require("./title-styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function CardTitleView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors,
      fonts = _useTheme.fonts;

  var titleIconProps = {
    size: 21,
    color: colors.neutralDarkest
  };

  if (props.titleIcon) {
    titleIconProps = _objectSpread(_objectSpread({}, titleIconProps), {}, {
      left: function left() {
        return _react.default.createElement(_CustomIcon.default, {
          name: props.titleIcon || '',
          size: titleIconProps.size,
          color: titleIconProps.color
        });
      },
      leftStyle: [_titleStyles.default.leftStyle, {
        width: titleIconProps.size,
        height: titleIconProps.size
      }]
    });
  }

  var titleStyleProps = {
    fontFamily: fonts.family.bold,
    fontSize: titleIconProps.size,
    lineHeight: 48,
    color: titleIconProps.color
  };
  var subtitleStyleProps = {
    fontFamily: fonts.family.medium,
    fontSize: 14,
    color: titleIconProps.color
  };

  if (props.inverted) {
    titleStyleProps = {
      color: colors.neutralLightest
    };
    subtitleStyleProps = {
      color: colors.neutralLightest
    };
  }

  if (props.size === 'small') {
    titleStyleProps = {
      fontSize: 16,
      fontWeight: 'bold'
    };
  }

  return _react.default.createElement(_reactNativePaperV.Card.Title, (0, _extends2.default)({}, props, titleIconProps, {
    rightStyle: _titleStyles.default.rightStyle,
    titleStyle: titleStyleProps,
    subtitleStyle: subtitleStyleProps,
    style: [_titleStyles.default.titles, props.style]
  }), props.children);
}

var _default = CardTitleView;
exports.default = _default;