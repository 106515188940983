var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _eventInfoStyles = _interopRequireDefault(require("./event-info-styles"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../common/DefaultHTMLrenderer"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _AddToCalendarButton = _interopRequireDefault(require("../../../theme/components/buttons/AddToCalendarButton"));

var _dateFormatter = require("../../../utils/date-formatter");

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

var EventInfo = function EventInfo(_ref) {
  var title = _ref.title,
      date = _ref.date,
      description = _ref.description,
      banner = _ref.banner,
      showHeaderOverlay = _ref.showHeaderOverlay,
      name = _ref.name,
      start = _ref.start,
      end = _ref.end,
      timezone = _ref.timezone;
  var Styles = (0, _eventInfoStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_reactNative.ImageBackground, {
    source: banner,
    style: Styles.imgBackground
  }, showHeaderOverlay && _react.default.createElement(_reactNative.View, {
    style: Styles.imgBackgroundBlack
  }), _react.default.createElement(_Spacing.default, {
    direction: "column",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "h1",
    className: (0, _clsx.default)(classes.textWhite, classes.title)
  }, title)), _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: (0, _clsx.default)(classes.textWhite, classes.date)
  }, date)), _react.default.createElement(_Spacing.default, {
    mSpacing: ['l', 0, 0, 0],
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_AddToCalendarButton.default, {
    start: (0, _momentTimezone.default)(start).format(_dateFormatter.longDateTimeFormat),
    end: (0, _momentTimezone.default)(end).format(_dateFormatter.longDateTimeFormat),
    timezone: timezone,
    title: name,
    description: description,
    location: window.location.href
  })), !!description && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 0, 0, 0],
    style: Styles.descriptionText
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    className: classes.textWhite
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: description
  })))));
};

var _default = EventInfo;
exports.default = _default;