var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CollapsedView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Button = _interopRequireDefault(require("../../../common/Button"));

var _CustomIcon = _interopRequireDefault(require("../../../../assets/icons/CustomIcon.js"));

var _collapsedViewStyles = _interopRequireDefault(require("./collapsed-view-styles"));

var _footerLoading = _interopRequireDefault(require("../../footer/footer-loading"));

function CollapsedView(props) {
  var wasLoadMorePressed = props.wasLoadMorePressed,
      onPress = props.onPress;
  return _react.default.createElement(_reactNative.View, {
    style: _collapsedViewStyles.default.dividerContainer
  }, _react.default.createElement(_reactNative.View, {
    style: _collapsedViewStyles.default.dividerLine
  }), wasLoadMorePressed ? _react.default.createElement(_footerLoading.default, null) : _react.default.createElement(_Button.default, {
    testID: "leaderboardLoadMoreButton",
    name: "loadMoreButton",
    buttonSize: "small",
    disabled: wasLoadMorePressed,
    onPress: onPress,
    style: _collapsedViewStyles.default.loadMoreButton
  }, _react.default.createElement(_CustomIcon.default, {
    name: "more",
    size: 16,
    color: "#fff"
  })), _react.default.createElement(_reactNative.View, {
    style: _collapsedViewStyles.default.dividerLine
  }));
}