var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ComeBackLaterView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _comeBackLaterStyles = _interopRequireDefault(require("./come-back-later-styles"));

var COME_BACK_TEXT = 'Come back later to see this months top DJs';

function ComeBackLaterView(props) {
  var isFinished = props.isFinished;
  return _react.default.createElement(_reactNative.Text, {
    style: [_comeBackLaterStyles.default.current, isFinished ? _comeBackLaterStyles.default.isFinished : null]
  }, COME_BACK_TEXT);
}