var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBackstage = exports.subscribeToBackstage = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _firebaseUtils = require("../../utils/firebase-utils");

var subscribeToBackstage = (0, _toolkit.createAsyncThunk)(_constants.SUBSCRIBE_TO_BACKSTAGE, function () {
  var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_ref, _ref2) {
    var eventId, backstageId, dispatch, unsubscribe;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            eventId = _ref.eventId, backstageId = _ref.backstageId;
            dispatch = _ref2.dispatch;

            if (eventId) {
              _context.next = 4;
              break;
            }

            throw Error('No Event defined!');

          case 4:
            unsubscribe = _firebaseUtils.firebaseEntities.backstages(eventId).doc(backstageId).onSnapshot(function (doc) {
              return dispatch(updateBackstage(doc.data()));
            });
            return _context.abrupt("return", unsubscribe);

          case 6:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref3.apply(this, arguments);
  };
}());
exports.subscribeToBackstage = subscribeToBackstage;
var updateBackstage = (0, _toolkit.createAction)(_constants.UPDATE_BACKSTAGE, function prepare(backstage) {
  return {
    payload: {
      backstage: backstage
    }
  };
});
exports.updateBackstage = updateBackstage;