var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useLiveSessionStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 0,
      paddingBottom: '56.25%',
      marginVertical: theme.spacing.s,
      marginHorizontal: theme.spacing.m
    },
    noBorder: {
      marginVertical: 0,
      marginHorizontal: 0
    },
    absoluteContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center'
    },
    innerContainer: {
      paddingVertical: theme.spacing.xxl,
      paddingHorizontal: theme.spacing.xl,
      maxWidth: 360,
      margin: theme.spacing.m
    },
    textInfo: {
      marginBottom: theme.spacing.m,
      lineHeight: '2rem'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.md;
}, function (theme) {
  return {
    innerContainer: {
      paddingVertical: theme.spacing.s
    },
    textInfo: _objectSpread({
      marginTop: theme.spacing.none,
      marginBottom: theme.spacing.s
    }, theme.typography.description)
  };
}).buildHook();
var _default = useLiveSessionStyles;
exports.default = _default;