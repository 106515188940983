var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../core/event-info/selectors");

var _Section = _interopRequireDefault(require("../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _consts = require("../../utils/consts");

var _useHeader = _interopRequireDefault(require("../../utils/hooks/useHeader"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _benefits = _interopRequireDefault(require("../benefits"));

var _checklist = _interopRequireDefault(require("../checklist"));

var _checkoutContainer = _interopRequireDefault(require("../checkout/checkout-container"));

var _Poster = _interopRequireDefault(require("../checkout/poster/Poster"));

var _absoluteSpinner = _interopRequireDefault(require("../common/absolute-spinner"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../common/DefaultHTMLrenderer"));

var _footer = _interopRequireDefault(require("../common/footer"));

var _scrollListener = _interopRequireDefault(require("../common/header/scroll-listener"));

var _videoModalView = _interopRequireDefault(require("../common/video-modal/video-modal-view"));

var _letter = _interopRequireDefault(require("../letter"));

var _partners = _interopRequireDefault(require("../partners"));

var _sessions = _interopRequireDefault(require("../sessions"));

var _speakers = _interopRequireDefault(require("../speakers"));

var _testimonials = _interopRequireDefault(require("../testimonials"));

var _checkoutSectionView = _interopRequireDefault(require("./checkout-section/checkout-section-view"));

var _eventInfo = _interopRequireDefault(require("./event-info"));

var _landingScreenStyles = _interopRequireDefault(require("./landing-screen-styles"));

var _scroll = _interopRequireDefault(require("./scroll"));

var _selectors2 = require("../../core/session/selectors");

var _moment = _interopRequireDefault(require("moment"));

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _styles = _interopRequireDefault(require("./styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var SCROLL_LISTENED_SECTIONS = [_consts.SECTION_TYPES.SCHEDULE, _consts.SECTION_TYPES.SPEAKERS, _consts.SECTION_TYPES.BENEFITS, _consts.SECTION_TYPES.SPONSORS];

var LandingScreenContainer = function LandingScreenContainer(props) {
  var _eventInfo$checkoutBl, _eventInfo$checkoutBl2;

  var removePreviousCheckoutIntent = props.removePreviousCheckoutIntent,
      eventInfo = props.eventInfo,
      isLoggedIn = props.isLoggedIn,
      isLoading = props.isLoading,
      sessionsLength = props.sessionsLength,
      speakersLength = props.speakersLength,
      partnersLength = props.partnersLength,
      testimonialsLength = props.testimonialsLength,
      benefitsLength = props.benefitsLength,
      checklistLength = props.checklistLength,
      lettersLength = props.lettersLength,
      sectionSorting = props.sectionSorting;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      modalVisible = _useState2[0],
      setModalVisible = _useState2[1];

  var eventSlug = (0, _reactRedux.useSelector)(_selectors.selectEventSlug);
  var isLandingScreenFocused = (0, _reactNavigationHooks.useIsFocused)();
  var Styles = (0, _landingScreenStyles.default)();
  var classes = (0, _styles.default)();
  var poster = (eventInfo === null || eventInfo === void 0 ? void 0 : (_eventInfo$checkoutBl = eventInfo.checkoutBlock) === null || _eventInfo$checkoutBl === void 0 ? void 0 : _eventInfo$checkoutBl.poster) || (eventInfo === null || eventInfo === void 0 ? void 0 : (_eventInfo$checkoutBl2 = eventInfo.checkoutBlock) === null || _eventInfo$checkoutBl2 === void 0 ? void 0 : _eventInfo$checkoutBl2.defaultVideoThumbnail) || null;
  var eventDates = (0, _reactRedux.useSelector)(_selectors.getEventDates);
  var sessions = (0, _reactRedux.useSelector)(_selectors2.selectSessionsWithSpeakers, _reactRedux.shallowEqual);

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      aboutTitle = _useSelector.About;

  _react.default.useEffect(function () {
    if (!isLoggedIn && isLandingScreenFocused) {
      removePreviousCheckoutIntent();
    }
  }, [isLandingScreenFocused]);

  var sortableSections = sectionSorting ? sectionSorting.filter(function (s) {
    return s.isSortable === true;
  }) : [];
  (0, _useHeader.default)({
    visible: !isLoading,
    withScroll: true,
    withLeftMenu: true
  });

  var getContent = function getContent(section) {
    switch (section.type) {
      case _consts.SECTION_TYPES.SPEAKERS:
        return !!speakersLength ? _react.default.createElement(_speakers.default, null) : null;

      case _consts.SECTION_TYPES.SCHEDULE:
        return !!sessionsLength ? _react.default.createElement(_sessions.default, {
          showJoinLiveButton: false
        }) : null;

      case _consts.SECTION_TYPES.BENEFITS:
        return !!benefitsLength ? _react.default.createElement(_benefits.default, null) : null;

      case _consts.SECTION_TYPES.SPONSORS:
        return !!partnersLength ? _react.default.createElement(_partners.default, null) : null;

      case _consts.SECTION_TYPES.TESTIMONIALS:
        return !!testimonialsLength ? _react.default.createElement(_testimonials.default, null) : null;

      case _consts.SECTION_TYPES.CHECKLIST:
        return !!checklistLength ? _react.default.createElement(_checklist.default, null) : null;

      case _consts.SECTION_TYPES.LETTER:
        return !!lettersLength ? _react.default.createElement(_letter.default, null) : null;

      case _consts.SECTION_TYPES.CUSTOM_SECTIONS:
        return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
          mSpacing: [0, 0, 'xl', 0]
        }, _react.default.createElement(_core.Typography, {
          className: classes.textUppercase,
          variant: "h2"
        }, section.title)), _react.default.createElement(_reactNative.View, null, _react.default.createElement(_DefaultHTMLrenderer.default, {
          source: section.description
        })));
    }
  };

  var startDate = sessions && sessions.length > 0 ? (0, _moment.default)(sessions[0].start) : eventInfo.startDate;
  var endDate = sessions && sessions.length > 0 ? (0, _moment.default)(sessions[sessions.length - 1].end) : eventInfo.endDate;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNativePaperV.Portal.Host, null, isLoading && _react.default.createElement(_absoluteSpinner.default, {
    size: "large"
  }), !isLoading && _react.default.createElement(_react.default.Fragment, null, modalVisible && _react.default.createElement(_reactNativePaperV.Portal, null, _react.default.createElement(_videoModalView.default, {
    video: eventInfo.checkoutBlock.video,
    title: "Promo video",
    hideModal: function hideModal() {
      setModalVisible(false);
    }
  })), _react.default.createElement(_scroll.default, null, _react.default.createElement(_reactNative.View, {
    style: {
      flex: 1
    }
  }, _react.default.createElement(_eventInfo.default, {
    banner: eventInfo.bannerUrl,
    title: eventInfo.headline,
    date: eventDates,
    description: eventInfo.description,
    showHeaderOverlay: typeof eventInfo.showHeaderOverlay === 'undefined' || eventInfo.showHeaderOverlay,
    name: eventInfo.name,
    start: startDate,
    end: endDate,
    timezone: eventInfo.timezone
  }), _react.default.createElement(_checkoutSectionView.default, null, _react.default.createElement(_checkoutContainer.default, {
    poster: poster && _react.default.createElement(_Poster.default, {
      onPress: function onPress() {
        return setModalVisible(true);
      },
      posterUrl: poster,
      withVideo: !!eventInfo.checkoutBlock.video
    }),
    callbackUrl: "https://".concat(window.location.host, "/").concat(eventSlug, "/payment-result"),
    landingPage: true,
    useModalCheckout: eventInfo.useModalCheckout
  })), eventInfo.about && _react.default.createElement(_Section.default, {
    color: "pageBackground",
    onLayout: function onLayout(_ref) {
      var y = _ref.nativeEvent.layout.y;

      _scrollListener.default.onSectionLayout({
        key: _consts.SECTION_TYPES.ABOUT,
        index: 2,
        title: _consts.SECTION_TYPES.ABOUT,
        type: _consts.SECTION_TYPES.ABOUT,
        y: y
      });
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: [Styles.checkoutSpacing, Styles.container],
    pSpacing: ['xl', 'xxl']
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xl', 0]
  }, _react.default.createElement(_core.Typography, {
    className: classes.textUppercase,
    variant: "h2"
  }, aboutTitle || _i18n.default.t('header.about'))), _react.default.createElement(_core.Typography, {
    align: "left",
    variant: "body2"
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: eventInfo.about
  })))) || null, sortableSections.map(function (section) {
    var content = getContent(section);
    var time = new Date();

    if (section.isActive && content) {
      return _react.default.createElement(_Section.default, {
        color: "pageBackground",
        key: section.id + time.getMilliseconds(),
        onLayout: function onLayout(_ref2) {
          var y = _ref2.nativeEvent.layout.y;

          if (SCROLL_LISTENED_SECTIONS.includes(section.type)) {
            _scrollListener.default.onSectionLayout({
              key: section.id,
              index: section.sort,
              title: section.title,
              type: section.type,
              y: y
            });
          }
        }
      }, _react.default.createElement(_Spacing.default, {
        direction: "column",
        wrap: "nowrap",
        alignItems: "stretch",
        style: [Styles.checkoutSpacing, Styles.container],
        pSpacing: ['none', 'xxl']
      }, _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "stretch",
        pSpacing: ['xl', 'none'],
        style: Styles.dividerContainer
      }, content)));
    }
  })), _react.default.createElement(_footer.default, null)))));
};

var _default = LandingScreenContainer;
exports.default = _default;