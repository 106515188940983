var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../../core/user"));

var _queryParameter = require("../../../utils/queryParameter");

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var BackstageLoginGuard = function BackstageLoginGuard(_ref) {
  var children = _ref.children;
  var isLoggedIn = (0, _reactRedux.useSelector)(_user.default.selectors.isFirebaseUserLoggedIn);
  var backstageId = (0, _queryParameter.getQueryParameter)('backstageId');

  if (!isLoggedIn) {
    return children;
  }

  if (backstageId) {
    return _react.default.createElement(_Redirect.default, {
      to: _routesConsts.Routes.BACKSTAGE,
      params: {
        backstageId: backstageId
      }
    });
  } else {
    return _react.default.createElement(_Redirect.default, {
      to: _routesConsts.Routes.LOBBY
    });
  }
};

var _default = BackstageLoginGuard;
exports.default = _default;