var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextButton;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../typography/Typography"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function TextButton(_ref) {
  var iconLeft = _ref.iconLeft,
      iconRight = _ref.iconRight,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'pageText' : _ref$color,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      _ref$elevation = _ref.elevation,
      elevation = _ref$elevation === void 0 ? 0 : _ref$elevation,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? 's' : _ref$spacing,
      children = _ref.children,
      onPress = _ref.onPress,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? 'paragraph' : _ref$variant;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape,
      shadows = _useTheme.shadows,
      themeSpacing = _useTheme.spacing;

  return _react.default.createElement(_reactNative.TouchableHighlight, {
    disabled: disabled,
    onPress: onPress,
    underlayColor: "".concat(color, "13"),
    style: [_objectSpread(_objectSpread({}, shape.buttons), {}, {
      boxShadow: shadows[elevation],
      alignItems: 'center',
      justifyContent: 'center'
    }), disabled && {
      opacity: 0.3
    }]
  }, _react.default.createElement(_reactNative.View, {
    style: {
      alignItems: 'center',
      justifyContent: 'center'
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "nowrap",
    pSpacing: spacing,
    alignItems: "center",
    justifyContent: "center"
  }, iconLeft && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    style: {
      height: themeSpacing.l
    },
    pSpacing: [0, 's', 0, 0]
  }, _react.default.createElement(_Typography.default, {
    variant: variant,
    color: color
  }, iconLeft)), _react.default.createElement(_Typography.default, {
    variant: variant,
    color: color
  }, children), iconRight && _react.default.createElement(_Spacing.default, {
    self: "middle",
    alignItems: "center",
    justifyContent: "center",
    style: {
      height: themeSpacing.l
    },
    pSpacing: [0, 0, 0, 's']
  }, _react.default.createElement(_Typography.default, {
    variant: variant,
    color: color
  }, iconRight)))));
}