var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ScheduleEventListInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../../core/events"));

var _FestivalEventItem = _interopRequireDefault(require("../../helpers/FestivalEventItem"));

var _Paragraph = _interopRequireDefault(require("../../../common/typography_v1/Paragraph"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ScheduleEventListInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ScheduleEventListInternal, _React$PureComponent);

  var _super = _createSuper(ScheduleEventListInternal);

  function ScheduleEventListInternal() {
    (0, _classCallCheck2.default)(this, ScheduleEventListInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(ScheduleEventListInternal, [{
    key: "render",
    value: function render() {
      return React.createElement(React.Fragment, null, this.props.events.length > 0 ? React.createElement(_reactNative.FlatList, {
        horizontal: false,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        showsHorizontalScrollIndicator: false,
        contentContainerStyle: {
          flexGrow: 1,
          justifyContent: 'center',
          paddingHorizontal: 16,
          paddingTop: 16
        },
        data: this.props.events,
        renderItem: function renderItem(_ref) {
          var item = _ref.item;
          return React.createElement(_FestivalEventItem.default, {
            eventId: item.get('id'),
            key: item.get('id')
          });
        },
        initialNumToRender: 5,
        removeClippedSubviews: true,
        keyExtractor: function keyExtractor(item, index) {
          return index.toString();
        }
      }) : React.createElement(React.Fragment, null, React.createElement(_reactNative.View, {
        style: {
          flex: 1,
          height: '100%',
          justifyContent: 'center',
          paddingHorizontal: 65,
          marginTop: 40,
          alignContent: 'center'
        }
      }, React.createElement(_Paragraph.default, {
        style: {
          textAlign: 'center',
          color: 'rgba(255,255,255,0.5)',
          lineHeight: 22,
          fontSize: 15,
          marginBottom: 16,
          maxWidth: 600
        }
      }, "There are no shows scheduled for this day."))));
    }
  }]);
  return ScheduleEventListInternal;
}(React.PureComponent);

exports.ScheduleEventListInternal = ScheduleEventListInternal;

function mapStateToProps(state, ownProps) {
  var date = ownProps.date;
  var id = ownProps.festivalId;
  return {
    events: _events.default.selectors.getScheduleFestivalEvents(state, date, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(ScheduleEventListInternal);

exports.default = _default;