var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listenSelectedStage = exports.listenStageChangesById = exports.getEventStages = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getEventStages = (0, _toolkit.createAsyncThunk)(_constants.GET_EVENT_STAGES, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var getState, eventId, stages;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.stages(eventId));

          case 6:
            stages = _context.sent;
            return _context.abrupt("return", stages);

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getEventStages = getEventStages;
var listenStageChangesById = (0, _toolkit.createAsyncThunk)(_constants.LISTEN_STAGE_CHANGES_BY_ID, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(id, _ref3) {
    var dispatch, getState, eventId, unsubscribe;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            dispatch = _ref3.dispatch, getState = _ref3.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context2.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            if (id) {
              _context2.next = 6;
              break;
            }

            throw Error('No stage id!');

          case 6:
            unsubscribe = _firebaseUtils.firebaseEntities.stages(eventId).doc(id).onSnapshot(function (querySnapshot) {
              dispatch({
                type: _constants.STAGE_STATUS_CHANGED,
                payload: {
                  id: querySnapshot.id,
                  changes: _objectSpread({}, querySnapshot.data())
                }
              });
            });
            return _context2.abrupt("return", function () {
              return unsubscribe();
            });

          case 8:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.listenStageChangesById = listenStageChangesById;
var listenSelectedStage = (0, _toolkit.createAction)(_constants.SELECTED_STAGE_CHANGED);
exports.listenSelectedStage = listenSelectedStage;