Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useBackstageComponentsStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    liveStatus: {
      color: theme.colors.topMenuBackground
    },
    liveStatusDescription: {
      color: '#FFFFFF',
      opacity: 0.6
    },
    iconButtonSize: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  liveStatus: {
    fontSize: 15
  },
  iconButtonSize: {
    width: 16,
    height: 16
  }
}).buildHook();
var _default = useBackstageComponentsStyle;
exports.default = _default;