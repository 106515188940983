var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EditProfileScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNavigation = require("react-navigation");

var _profile = _interopRequireDefault(require("../../core/profile"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var EditProfileScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EditProfileScreenInternal, _React$PureComponent);

  var _super = _createSuper(EditProfileScreenInternal);

  function EditProfileScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EditProfileScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.userNameRef = _react.default.createRef();
    _this.bioRef = _react.default.createRef();
    _this.twitterRef = _react.default.createRef();
    _this.facebookRef = _react.default.createRef();
    _this.soundcloudRef = _react.default.createRef();
    _this.mixcloudRef = _react.default.createRef();
    _this.instagramRef = _react.default.createRef();
    _this.state = {
      inProgress: false,
      userNameText: _this.props.userName,
      bioText: _this.props.bio,
      twitterText: _this.props.twitterUrl,
      facebookText: _this.props.facebookUrl,
      soundcloudText: _this.props.soundcloudUrl,
      mixcloudText: _this.props.mixcloudUrl,
      instagramText: _this.props.instagramUrl
    };

    _this.handleSave = function () {
      _this.setState({
        inProgress: true
      }, function () {
        _this.props.navigation.setParams({
          inProgress: _this.state.inProgress
        });
      });

      var _this$state = _this.state,
          userNameText = _this$state.userNameText,
          bioText = _this$state.bioText,
          twitterText = _this$state.twitterText,
          facebookText = _this$state.facebookText,
          soundcloudText = _this$state.soundcloudText,
          mixcloudText = _this$state.mixcloudText,
          instagramText = _this$state.instagramText;
      var profileData = {
        userId: _this.props.userId,
        displayName: userNameText,
        bio: bioText,
        twitterProfileLink: twitterText,
        facebookProfileLink: facebookText,
        soundcloudProfileLink: soundcloudText,
        mixcloudProfileLink: mixcloudText,
        instagramProfileLink: instagramText
      };

      _this.props.updateProfile(profileData).then(function () {
        _this.setState({
          inProgress: false
        }, function () {
          _this.props.navigation.setParams({
            inProgress: _this.state.inProgress
          });
        });

        _this.props.navigation.goBack();
      });
    };

    return _this;
  }

  (0, _createClass2.default)(EditProfileScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.props.navigation.setParams({
        saveButtonAction: this.handleSave
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var colors = this.props.theme.colors;

      if (this.props.userName === undefined) {
        return _react.default.createElement(_reactNative.View, {
          style: styles.loading
        }, _react.default.createElement(_reactNative.ActivityIndicator, {
          size: "large",
          color: "#fff"
        }));
      }

      var nameInitials = this.props.userName.split(' ', 2).map(function (i) {
        return i.charAt(0);
      });
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "#000",
        translucent: false
      }), _react.default.createElement(_reactNative.ScrollView, {
        style: {
          backgroundColor: colors.background
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_AvatarImage.default, {
        size: 96,
        source: {
          uri: this.props.avatar
        },
        defaultLabel: nameInitials,
        style: styles.userAvatar
      }), _react.default.createElement(_reactNative.KeyboardAvoidingView, {
        style: styles.formContainer,
        behavior: _reactNative.Platform.OS === 'ios' ? 'padding' : null
      }, _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Name",
        testID: "username",
        placeholder: "Your name",
        mode: "flat",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'next',
        ref: this.userNameRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        value: this.state.userNameText,
        onChangeText: function onChangeText(userNameText) {
          return _this2.setState({
            userNameText: userNameText
          });
        },
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.bioRef.current) {
            _this2.bioRef.current.focus();
          }
        },
        style: styles.textInput
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Bio",
        testID: "bio",
        placeholder: "Bio",
        mode: "flat",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'next',
        ref: this.bioRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        multiline: true,
        style: styles.textInput,
        value: this.state.bioText,
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.twitterRef.current) {
            _this2.twitterRef.current.focus();
          }
        },
        onChangeText: function onChangeText(bioText) {
          return _this2.setState({
            bioText: bioText
          });
        }
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Twitter",
        testID: "twitter",
        mode: "flat",
        placeholder: "Twitter profile link",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'next',
        ref: this.twitterRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        value: this.state.twitterText,
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.facebookRef.current) {
            _this2.facebookRef.current.focus();
          }
        },
        onChangeText: function onChangeText(twitterText) {
          return _this2.setState({
            twitterText: twitterText
          });
        },
        style: styles.textInput
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Facebook",
        testID: "facebook",
        mode: "flat",
        placeholder: "Facebook profile link",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'next',
        ref: this.facebookRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        value: this.state.facebookText,
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.instagramRef.current) {
            _this2.instagramRef.current.focus();
          }
        },
        onChangeText: function onChangeText(facebookText) {
          return _this2.setState({
            facebookText: facebookText
          });
        },
        style: styles.textInput
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Instagram",
        testID: "instagram",
        mode: "flat",
        placeholder: "Instagram profile link",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'next',
        ref: this.instagramRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        value: this.state.instagramText,
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.soundcloudRef.current) {
            _this2.soundcloudRef.current.focus();
          }
        },
        onChangeText: function onChangeText(instagramText) {
          return _this2.setState({
            instagramText: instagramText
          });
        },
        style: styles.textInput
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Soundcloud",
        testID: "soundcloud",
        mode: "flat",
        placeholder: "Soundcloud profile link",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'done',
        ref: this.soundcloudRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        onSubmitEditing: function onSubmitEditing() {
          if (_this2.mixcloudRef.current) {
            _this2.mixcloudRef.current.focus();
          }
        },
        value: this.state.soundcloudText,
        onChangeText: function onChangeText(soundcloudText) {
          return _this2.setState({
            soundcloudText: soundcloudText
          });
        },
        style: styles.textInput
      }), _react.default.createElement(_reactNativePaper.TextInput, {
        label: "Mixcloud",
        testID: "mixcloud",
        mode: "flat",
        placeholder: "Mixcloud profile link",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'done',
        ref: this.mixcloudRef,
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: colors.text,
            primary: colors.text
          }
        },
        onSubmitEditing: this.handleSave,
        value: this.state.mixcloudText,
        onChangeText: function onChangeText(mixcloudText) {
          return _this2.setState({
            mixcloudText: mixcloudText
          });
        },
        style: styles.textInput
      })))));
    }
  }]);
  return EditProfileScreenInternal;
}(_react.default.PureComponent);

exports.EditProfileScreenInternal = EditProfileScreenInternal;

EditProfileScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    title: 'Edit Profile',
    headerTintColor: '#fff',
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 13
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    },
    headerRight: _react.default.createElement(_reactNativePaper.TouchableRipple, {
      testID: "saveButton",
      borderless: true,
      style: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0
      },
      disabled: state.params ? state.params.inProgress : false,
      onPress: state.params ? state.params.saveButtonAction : undefined
    }, _react.default.createElement(_CustomIcon.default, {
      name: "check",
      size: 20,
      color: "#0E7AF9"
    })),
    headerLeft: (0, _NavigationButtons.backButton)(navigation)
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 16
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  userAvatar: {
    marginBottom: 16
  },
  formContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start'
  },
  textInput: {}
});

function mapStateToProps(state, ownProps) {
  var userId = ownProps.userId;
  return {
    key: userId,
    userName: _profile.default.selectors.getUserName(state, userId),
    bio: _profile.default.selectors.getBio(state, userId),
    avatar: _profile.default.selectors.getUserPicture(state, userId),
    twitterUrl: _profile.default.selectors.getTwitterUrl(state, userId),
    facebookUrl: _profile.default.selectors.getFacebookUrl(state, userId),
    soundcloudUrl: _profile.default.selectors.getSoundcloudUrl(state, userId),
    mixcloudUrl: _profile.default.selectors.getMixCloudUrl(state, userId),
    instagramUrl: _profile.default.selectors.getInstagramUrl(state, userId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  updateProfile: _profile.default.actions.updateProfile
})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(EditProfileScreenInternal)));

exports.default = _default;