var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CircleGroup;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

function CircleGroup(_ref) {
  var children = _ref.children,
      width = _ref.width,
      height = _ref.height,
      x = _ref.x,
      y = _ref.y,
      _ref$fill = _ref.fill,
      fill = _ref$fill === void 0 ? '#fff' : _ref$fill;
  return _react.default.createElement(_reactKonva.Group, {
    width: width,
    height: height,
    x: x,
    y: y,
    clipFunc: function clipFunc(ctx) {
      return ctx.arc(width / 2, height / 2, Math.abs(Math.min(width / 2, height / 2)), 0, Math.PI * 2, false);
    }
  }, _react.default.createElement(_reactKonva.Rect, {
    fill: fill,
    width: width,
    height: height
  }), children);
}