var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.chatReactionsEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var chatReactionsEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.chatReactionsEntityAdapter = chatReactionsEntityAdapter;
var InitialState = chatReactionsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
var chatReactionsReducer = (0, _toolkit.createReducer)(InitialState, (0, _defineProperty2.default)({}, _actions.onChatReactionsChangedById.type, function (state, _ref) {
  var payload = _ref.payload;
  chatReactionsEntityAdapter.upsertOne(state, payload);
}));
var _default = chatReactionsReducer;
exports.default = _default;