var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalScreenInternal = exports.iosTopMargin = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _color = _interopRequireDefault(require("color"));

var _reactRedux = require("react-redux");

var _festival = _interopRequireDefault(require("../../core/festival"));

var _events = _interopRequireDefault(require("../../core/events"));

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _FestivalHeader = _interopRequireDefault(require("./render/FestivalHeader"));

var _Button = _interopRequireDefault(require("../common/Button"));

var _FestivalLiveNowScreen = _interopRequireDefault(require("./render/livenow/FestivalLiveNowScreen"));

var _FestivalScheduleScreen = _interopRequireDefault(require("./render/schedule/FestivalScheduleScreen"));

var _FestivalLineupScreen = _interopRequireDefault(require("./render/FestivalLineupScreen"));

var _MyFestivalScreen = _interopRequireDefault(require("./render/myfestival/MyFestivalScreen"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _FollowFestivalButton = _interopRequireDefault(require("../common/follow/FollowFestivalButton"));

var _LoadingScreen = _interopRequireDefault(require("../LoadingScreen"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 0;
exports.iosTopMargin = iosTopMargin;

var FestivalScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalScreenInternal, _React$PureComponent);

  var _super = _createSuper(FestivalScreenInternal);

  function FestivalScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FestivalScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.scrollY = new _reactNative.Animated.Value(0);
    _this.changingBgColor = 0;
    _this.state = {
      refreshing: false,
      renderLiveNow: _this.props.isLiveFestival ? true : false,
      renderLineup: _this.props.isLiveFestival ? false : true,
      renderSchedule: false,
      renderMyFestival: false
    };

    _this.handlePressLiveNow = function () {
      _this.setState({
        renderLiveNow: true,
        renderLineup: false,
        renderSchedule: false,
        renderMyFestival: false
      });
    };

    _this.handlePressLineup = function () {
      _this.setState({
        renderLiveNow: false,
        renderLineup: true,
        renderSchedule: false,
        renderMyFestival: false
      });
    };

    _this.handlePressSchedule = function () {
      _this.setState({
        renderLiveNow: false,
        renderLineup: false,
        renderSchedule: true,
        renderMyFestival: false
      });
    };

    _this.handlePressMyFestival = function () {
      _this.setState({
        renderLiveNow: false,
        renderLineup: false,
        renderSchedule: false,
        renderMyFestival: true
      });
    };

    _this.handlePullToRefresh = function () {
      _this.setState({
        refreshing: true
      });

      _this.props.loadFestival(_this.props.festivalId).then(function () {
        _this.setState({
          refreshing: false
        });
      }).catch(function () {
        _this.setState({
          refreshing: false
        });
      });
    };

    return _this;
  }

  (0, _createClass2.default)(FestivalScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.scrollY = new _reactNative.Animated.Value(0);
      this.changingBgColor = this.scrollY.interpolate({
        inputRange: [0, 90, 150],
        outputRange: ['transparent', 'transparent', 'black'],
        extrapolate: 'clamp'
      });
      this.props.navigation.setParams({
        bgColor: this.changingBgColor
      });
      this.props.navigation.setParams({
        festivalId: this.props.festivalId
      });
    }
  }, {
    key: "render",
    value: function render() {
      if (this.props.festivalThemeColor === undefined) {
        return React.createElement(_reactNative.View, {
          style: styles.LoadingScreen,
          testID: this.props.festivalId.toString()
        }, React.createElement(_LoadingScreen.default, null));
      }

      var renderButtonColorInactive = (0, _color.default)('white').alpha(0.2).rgb().string();
      return React.createElement(React.Fragment, null, React.createElement(_reactNative.Animated.ScrollView, {
        testID: this.props.festivalId.toString(),
        onScroll: _reactNative.Animated.event([{
          nativeEvent: {
            contentOffset: {
              y: this.scrollY
            }
          }
        }]),
        scrollEventThrottle: 1,
        showsVerticalScrollIndicator: false,
        style: {
          backgroundColor: 'black'
        },
        contentContainerStyle: {
          paddingTop: iosTopMargin,
          paddingBottom: 24
        },
        refreshControl: React.createElement(_reactNative.RefreshControl, {
          refreshing: this.state.refreshing,
          tintColor: 'white',
          onRefresh: this.handlePullToRefresh
        })
      }, React.createElement(_FestivalHeader.default, {
        festivalId: this.props.festivalId
      }), React.createElement(_reactNative.View, {
        style: {
          width: '100%',
          paddingHorizontal: 16,
          justifyContent: 'center',
          alignItems: 'center'
        }
      }, React.createElement(_FollowFestivalButton.default, {
        buttonSize: "large",
        color: "#ffffff",
        uppercase: true,
        id: this.props.festivalId,
        style: {
          width: '100%',
          maxWidth: 400
        }
      })), React.createElement(_reactNative.ScrollView, {
        horizontal: true,
        showsHorizontalScrollIndicator: false,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        contentContainerStyle: {
          paddingHorizontal: 16
        },
        style: _objectSpread({}, _reactNative.Platform.select({
          web: {
            flexGrow: 0
          }
        }))
      }, this.props.isLiveFestival && React.createElement(_Button.default, {
        mode: "contained",
        testID: "liveNowFilter",
        onPress: this.handlePressLiveNow,
        pulseDot: true,
        style: styles.filterButton,
        dark: this.state.renderLiveNow ? false : true,
        color: this.state.renderLiveNow ? this.props.festivalThemeColor : renderButtonColorInactive
      }, "LIVE NOW"), React.createElement(_Button.default, {
        mode: "contained",
        testID: "lineupFilter",
        onPress: this.handlePressLineup,
        style: styles.filterButton,
        dark: this.state.renderLineup ? false : true,
        color: this.state.renderLineup ? this.props.festivalThemeColor : renderButtonColorInactive
      }, "LINEUP"), React.createElement(_Button.default, {
        mode: "contained",
        testID: "scheduleFilter",
        onPress: this.handlePressSchedule,
        style: styles.filterButton,
        dark: this.state.renderSchedule ? false : true,
        color: this.state.renderSchedule ? this.props.festivalThemeColor : renderButtonColorInactive
      }, "SCHEDULE"), React.createElement(_Button.default, {
        mode: "contained",
        testID: "myFestivalFilter",
        onPress: this.handlePressMyFestival,
        style: styles.filterButton,
        dark: this.state.renderMyFestival ? false : true,
        color: this.state.renderMyFestival ? this.props.festivalThemeColor : renderButtonColorInactive
      }, "MY FESTIVAL")), this.state.renderLiveNow && React.createElement(_FestivalLiveNowScreen.default, {
        festivalId: this.props.festivalId
      }), this.state.renderLineup && React.createElement(_FestivalLineupScreen.default, {
        festivalId: this.props.festivalId
      }), this.state.renderSchedule && React.createElement(_FestivalScheduleScreen.default, {
        festivalId: this.props.festivalId
      }), this.state.renderMyFestival && React.createElement(_MyFestivalScreen.default, {
        festivalId: this.props.festivalId,
        onPressLineup: this.handlePressLineup
      })));
    }
  }]);
  return FestivalScreenInternal;
}(React.PureComponent);

exports.FestivalScreenInternal = FestivalScreenInternal;

FestivalScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: (0, _NavigationButtons.aboutButton)(navigation),
    headerTransparent: true,
    headerStyle: {
      backgroundColor: navigation.getParam('BackgroundColor', state.params.bgColor)
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  LoadingScreen: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  },
  filterContainer: {
    height: 24,
    marginVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filterButton: {
    marginRight: 8,
    marginVertical: 24
  }
});

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;
  return {
    isLiveFestival: _festival.default.selectors.isLiveFestival(state, id),
    festivalThemeColor: _festival.default.selectors.getThemeColor(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadFestivalEvents: _events.default.actions.loadScheduledEvents,
  loadFestival: _festival.default.actions.loadFestival
})(FestivalScreenInternal);

exports.default = _default;