Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useSpeakerPageStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  container: {
    alignItems: 'center'
  }
}).buildHook();
var _default = useSpeakerPageStyles;
exports.default = _default;