var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _liveStreamErrors = _interopRequireDefault(require("./live-stream-errors"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _absoluteSpinner = _interopRequireDefault(require("../../absolute-spinner"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var BackToNetworkingScreen = function BackToNetworkingScreen() {
  var navigation = (0, _useNavigationExtended.default)();
  var Styles = (0, _liveStreamErrors.default)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      fired = _useState2[0],
      setFired = _useState2[1];

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  return _react.default.createElement(_reactNative.View, {
    style: Styles.actionContainer
  }, _react.default.createElement(_reactNative.View, {
    style: Styles.buttonContainer
  }, fired ? _react.default.createElement(_absoluteSpinner.default, {
    color: colors.button
  }) : _react.default.createElement(_ContainedButton.default, {
    onPress: function onPress() {
      setFired(true);
      setTimeout(function () {
        navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
      }, 100);
    }
  }, _i18n.default.t('liveNow.backToNetworking'))));
};

var _default = BackToNetworkingScreen;
exports.default = _default;