var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _reportOrBlockContainer = _interopRequireDefault(require("./report-or-block-container"));

var _reactNativePaper = require("react-native-paper");

var _chat = _interopRequireDefault(require("../../../core/chat"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _reactNavigation = require("react-navigation");

function mapStateToProps(state, ownProps) {
  var fullName = ownProps.fullName,
      avatar = ownProps.avatar,
      message = ownProps.message,
      messageId = ownProps.messageId,
      userId = ownProps.userId;
  return {
    fullName: fullName,
    avatar: avatar,
    message: message,
    messageId: messageId,
    userId: userId,
    loggedUser: _user.default.selectors.getUserId(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  reportMessage: _chat.default.actions.reportMessage,
  blockUser: _user.default.actions.blockUser
})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(_reportOrBlockContainer.default)));

exports.default = _default;