var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _agoraConstants = require("../../constants/agora-constants");

var _asyncActionHandler = _interopRequireDefault(require("../../utils/redux/async-action-handler"));

var _constants = require("../event-info/constants");

var _constants2 = require("../user/constants");

var _actions = require("./actions");

var _selectors = require("./selectors");

function createMiddleware(_ref) {
  var networkingService = _ref.networkingService;
  return function (_ref2) {
    var dispatch = _ref2.dispatch,
        getState = _ref2.getState;
    var subscriptions = new Map();
    var initializationHandler = new _asyncActionHandler.default(function () {
      dispatch((0, _actions.connect)());
    }).waitEvery({
      type: _constants2.FIREBASE_AUTH_STATE_CHANGED
    }).waitOnce({
      type: _constants.LOAD_EVENT_INFO_COMPLETED
    });
    var logoutHandler = new _asyncActionHandler.default(function () {
      dispatch((0, _actions.disconnect)());
      Array.from(subscriptions.values()).forEach(function (unsubscribe) {
        return unsubscribe();
      });
    }).waitEvery({
      type: _constants2.START_USER_LOGOUT
    });
    networkingService.onDisconnect(function () {
      dispatch((0, _actions.disconnected)());
    });
    networkingService.onReconnect(function () {
      var roomsStates = (0, _selectors.roomsStateSelector)(getState());
      Promise.all(roomsStates.filter(function (_ref3) {
        var arg = _ref3.arg;
        return arg.event === _agoraConstants.JOIN_EVENT;
      }).map(function (_ref4) {
        var arg = _ref4.arg;
        return arg;
      }).map(function () {
        var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(arg) {
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  dispatch((0, _actions.joinRoom)(arg));

                case 1:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        return function (_x) {
          return _ref5.apply(this, arguments);
        };
      }()));
      Promise.all(roomsStates.filter(function (_ref6) {
        var arg = _ref6.arg;
        return arg.event === _agoraConstants.WATCH_EVENT;
      }).map(function (_ref7) {
        var arg = _ref7.arg;
        return arg;
      }).map(function () {
        var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(arg) {
          return _regenerator.default.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  dispatch((0, _actions.watchRoom)(arg));

                case 1:
                case "end":
                  return _context2.stop();
              }
            }
          }, _callee2);
        }));

        return function (_x2) {
          return _ref8.apply(this, arguments);
        };
      }()));
    });
    networkingService.onConnect(function () {
      var roomsStates = (0, _selectors.roomsStateSelector)(getState());
      Promise.all(roomsStates.filter(function (_ref9) {
        var arg = _ref9.arg;
        return arg.event === _agoraConstants.JOIN_EVENT;
      }).map(function (_ref10) {
        var arg = _ref10.arg;
        return arg;
      }).map(function () {
        var _ref11 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(arg) {
          return _regenerator.default.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  dispatch((0, _actions.joinRoom)(arg));

                case 1:
                case "end":
                  return _context3.stop();
              }
            }
          }, _callee3);
        }));

        return function (_x3) {
          return _ref11.apply(this, arguments);
        };
      }()));
      Promise.all(roomsStates.filter(function (_ref12) {
        var arg = _ref12.arg;
        return arg.event === _agoraConstants.WATCH_EVENT;
      }).map(function (_ref13) {
        var arg = _ref13.arg;
        return arg;
      }).map(function () {
        var _ref14 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(arg) {
          return _regenerator.default.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  dispatch((0, _actions.watchRoom)(arg));

                case 1:
                case "end":
                  return _context4.stop();
              }
            }
          }, _callee4);
        }));

        return function (_x4) {
          return _ref14.apply(this, arguments);
        };
      }()));
    });
    return function (next) {
      return function () {
        var _ref15 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(action) {
          var unsubscribe, _unsubscribe, result;

          return _regenerator.default.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  if (_actions.subscribeParticipantsChanges.match(action)) {
                    subscriptions.set("".concat(action.type, "/").concat(action.payload), networkingService.onParticipantsChange(action.payload, function (res) {
                      dispatch((0, _actions.participantsChanged)(res));
                    }));
                  }

                  if (_actions.unsubscribeParticipantsChanges.match(action)) {
                    unsubscribe = subscriptions.get("".concat(action.type, "/").concat(action.payload));

                    if (unsubscribe) {
                      unsubscribe();
                    }
                  }

                  if (_actions.subscribeRoomChanges.match(action)) {
                    subscriptions.set("".concat(action.type, "/").concat(action.payload), networkingService.onRoomChange(action.payload, function (res) {
                      dispatch((0, _actions.roomChanged)(res));
                    }));
                  }

                  if (_actions.unsubscribeRoomChanges.match(action)) {
                    _unsubscribe = subscriptions.get("".concat(action.type, "/").concat(action.payload));

                    if (_unsubscribe) {
                      _unsubscribe();
                    }
                  }

                  result = next(action);
                  initializationHandler.next(action);
                  logoutHandler.next(action);
                  return _context5.abrupt("return", result);

                case 8:
                case "end":
                  return _context5.stop();
              }
            }
          }, _callee5);
        }));

        return function (_x5) {
          return _ref15.apply(this, arguments);
        };
      }();
    };
  };
}