var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _mybeatsScreenRedux.default;
  }
});

var _mybeatsScreenRedux = _interopRequireDefault(require("./mybeats-screen-redux"));