Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.alignmentStyles = exports.distributionStyles = exports.spacingStyles = void 0;

var _reactNative = require("react-native");

var _spacing = require("../../spacing");

function createStackSpacing(spacing) {
  return _reactNative.StyleSheet.create({
    container: {
      marginTop: -1 * spacing,
      marginLeft: -1 * spacing
    },
    item: {
      marginTop: spacing,
      marginLeft: spacing
    }
  });
}

var spacingStyles = {
  none: createStackSpacing(_spacing.Spacing.none),
  extraTight: createStackSpacing(_spacing.Spacing.extraTight),
  tight: createStackSpacing(_spacing.Spacing.tight),
  base: createStackSpacing(_spacing.Spacing.base),
  loose: createStackSpacing(_spacing.Spacing.loose),
  extraLoose: createStackSpacing(_spacing.Spacing.extraLoose)
};
exports.spacingStyles = spacingStyles;
var distributionStyles = {
  equalSpacing: _reactNative.StyleSheet.create({
    container: {
      justifyContent: 'space-between'
    }
  }),
  leading: _reactNative.StyleSheet.create({
    container: {
      justifyContent: 'flex-start'
    }
  }),
  trailing: _reactNative.StyleSheet.create({
    container: {
      justifyContent: 'flex-end'
    }
  }),
  center: _reactNative.StyleSheet.create({
    container: {
      justifyContent: 'center'
    }
  }),
  fill: _reactNative.StyleSheet.create({
    item: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto'
    }
  })
};
exports.distributionStyles = distributionStyles;
var alignmentStyles = {
  leading: _reactNative.StyleSheet.create({
    container: {
      alignItems: 'flex-start'
    }
  }),
  trailing: _reactNative.StyleSheet.create({
    container: {
      alignItems: 'flex-end'
    }
  }),
  center: _reactNative.StyleSheet.create({
    container: {
      alignItems: 'center'
    }
  }),
  fill: _reactNative.StyleSheet.create({
    container: {
      alignItems: 'stretch'
    },
    item: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto'
    }
  }),
  baseline: _reactNative.StyleSheet.create({
    container: {
      alignItems: 'baseline'
    }
  })
};
exports.alignmentStyles = alignmentStyles;

var _default = _reactNative.StyleSheet.create({
  default: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch'
  },
  vertical: {
    flexDirection: 'column',
    marginLeft: _spacing.Spacing.none
  },
  wrap: {
    flexWrap: 'wrap'
  },
  defaultItem: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    minWidth: 0,
    alignItems: 'flex-start'
  },
  verticalItem: {
    marginLeft: _spacing.Spacing.none
  },
  fillItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto'
  }
});

exports.default = _default;