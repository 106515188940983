var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ButtonView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _CustomIcon = _interopRequireDefault(require("../../../src/assets/icons/CustomIcon"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function ButtonView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors,
      roundness = _useTheme.roundness;

  var buttonSize = {
    slim: 20,
    regular: 26,
    large: 34
  };
  var btnStyle = [{
    borderRadius: roundness,
    margin: 0
  }, props.style];
  var labelStyle = [{
    marginRight: 0
  }];
  var btnProps = {
    color: colors.neutralLightest,
    size: buttonSize.regular,
    icon: function icon(_ref) {
      var size = _ref.size,
          color = _ref.color;
      return _react.default.createElement(_CustomIcon.default, {
        name: props.icon || '',
        size: size,
        color: color
      });
    },
    onPress: props.onPress
  };

  if (props.size) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      size: props.size ? buttonSize[props.size] : null
    });
  }

  if (props.withBackground) {
    btnStyle.push({
      backgroundColor: colors.neutralDarkestOpacity07
    });
    btnProps = _objectSpread({}, btnProps);
  }

  if (props.inverted) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: colors.neutralDarkest
    });
  }

  if (props.pressed) {
    btnStyle.push({
      backgroundColor: colors.neutralDarkestOpacity07
    });
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: colors.neutralLight
    });
  }

  if (props.disabled) {
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      disabled: true
    });
  }

  if (props.iconColor && props.color) {
    btnStyle.push({
      backgroundColor: props.color
    });
    btnProps = _objectSpread(_objectSpread({}, btnProps), {}, {
      color: props.iconColor
    });
  }

  if (props.circleButton) {
    btnStyle.push({
      borderRadius: props.size ? buttonSize[props.size] : buttonSize.regular
    });
  }

  return _react.default.createElement(_reactNativePaperV.IconButton, (0, _extends2.default)({}, btnProps, {
    labelStyle: labelStyle,
    style: btnStyle
  }));
}