var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasFreeWatchTimeAvailable = exports.getTimeSpentInLive = exports.getSeenPushNotificationSuggestion = exports.getUnreadMessagesCount = exports.getLastReadMessageKey = void 0;

var _constants = require("./constants");

var _chat = _interopRequireDefault(require("../chat"));

var _live = _interopRequireDefault(require("../live"));

var getLastReadMessageKey = function getLastReadMessageKey(state, liveId) {
  return state[_constants.NAME].getIn(['lastReadMessages', liveId]);
};

exports.getLastReadMessageKey = getLastReadMessageKey;

var getUnreadMessagesCount = function getUnreadMessagesCount(state) {
  return _chat.default.selectors.getUnreadMessagesCount(state, getLastReadMessageKey(state, _live.default.selectors.getLiveId(state)));
};

exports.getUnreadMessagesCount = getUnreadMessagesCount;

var getSeenPushNotificationSuggestion = function getSeenPushNotificationSuggestion(state) {
  return state[_constants.NAME].get('hasSeenPushNotificationSuggestion');
};

exports.getSeenPushNotificationSuggestion = getSeenPushNotificationSuggestion;

var getTimeSpentInLive = function getTimeSpentInLive(state) {
  return state[_constants.NAME].getIn([_live.default.selectors.getLiveId(state), 'freeWatchTime']);
};

exports.getTimeSpentInLive = getTimeSpentInLive;

var hasFreeWatchTimeAvailable = function hasFreeWatchTimeAvailable(state, liveWatchTime) {
  var currentWatchTimeStored = state[_constants.NAME].getIn([_live.default.selectors.getLiveId(state), 'freeWatchTime']);

  if (liveWatchTime > currentWatchTimeStored) {
    return true;
  }

  return false;
};

exports.hasFreeWatchTimeAvailable = hasFreeWatchTimeAvailable;