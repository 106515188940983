Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useHeaderStyle = new _DesignSystem.MakeStyle().addConstantStyle({
  headerLeft: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 2
  },
  buttonContainer: {
    flexDirection: 'row',
    flex: 2
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 10
  },
  menuContainerBackstage: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10
  },
  menuBox: {
    transform: [{
      translateY: 35
    }]
  },
  menuButton: {
    backgroundColor: '#eee',
    borderRadius: 4
  },
  menuItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid'
  },
  logo: {
    height: 60,
    width: 183
  }
}).addThemeBasedStyle(function (theme) {
  return {
    headerLeft: {
      paddingLeft: theme.spacing.base * 3
    },
    container: {
      paddingLeft: theme.spacing.base * 8,
      paddingRight: theme.spacing.base * 8
    },
    buttonContainer: {
      marginLeft: theme.spacing.base * 8,
      marginRight: theme.spacing.base * 8
    },
    icon: {
      marginRight: theme.spacing.base
    },
    burgerBtn: {
      marginRight: theme.spacing.base * 3
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.lg;
}, function (theme) {
  return {
    buttonContainer: {
      marginLeft: theme.spacing.base,
      marginRight: theme.spacing.base
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      paddingLeft: theme.spacing.base * 3,
      paddingRight: theme.spacing.base * 3,
      height: 65,
      position: 'relative'
    },
    logo: {
      height: 45,
      width: 160
    },
    buttonContainer: {
      display: 'none'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.UP, function (_ref3) {
  var breakpoints = _ref3.breakpoints;
  return breakpoints.bg;
}, {
  burgerBtn: {
    display: 'none'
  }
}).buildHook();
var _default = useHeaderStyle;
exports.default = _default;