var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllEvents = getAllEvents;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _firebaseUtils = require("../../utils/firebase-utils");

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[typeof Symbol === "function" ? Symbol.iterator : "@@iterator"] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function getAllEvents(_x) {
  return _getAllEvents.apply(this, arguments);
}

function _getAllEvents() {
  _getAllEvents = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(userId) {
    var tickets, promises, _iterator, _step, ticket, ticketData, promise;

    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.next = 2;
            return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/tickets")).get();

          case 2:
            tickets = _context.sent;
            promises = [];
            _iterator = _createForOfIteratorHelper(tickets.docs);

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                ticket = _step.value;
                ticketData = ticket.data();
                promise = _reactNativeFirebase.default.firestore().doc("events/".concat(ticketData.eventId)).get().then(function (doc) {
                  var eventData = doc.data();

                  if (eventData) {
                    return (0, _firebaseUtils.formatEventData)(eventData);
                  }

                  return null;
                });
                promises.push(promise);
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            return _context.abrupt("return", Promise.all(promises).then(function (values) {
              return values.filter(function (value) {
                return !!value;
              });
            }));

          case 7:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _getAllEvents.apply(this, arguments);
}