var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSafeState;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

function useSafeState(_ref) {
  var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      state = _ref2[0],
      setState = _ref2[1];

  var mounted = (0, _react.useRef)(true);
  (0, _react.useEffect)(function () {
    return function () {
      mounted.current = false;
    };
  }, []);
  var setSafeState = (0, _react.useCallback)(function (data) {
    mounted.current && setState(data);
  }, []);
  return [state, setSafeState];
}