Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetActionSucceeded = exports.getResetPasswordConfirmError = exports.isValidResetCode = exports.getResetPasswordActionError = exports.isResetPasswordActionLoading = exports.getPhoneLoginError = exports.isPhoneLoginLoading = exports.getLoginError = exports.isLoginMailSent = exports.isLoginLoading = exports.getResetPasswordError = exports.isResetPasswordLoading = exports.getRegistrationProgress = exports.isRegistrationLoading = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var authStateSelector = function authStateSelector(state) {
  return state[_constants.NAME];
};

var isRegistrationLoading = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.registrationLoading;
});
exports.isRegistrationLoading = isRegistrationLoading;
var getRegistrationProgress = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.registrationProgress;
});
exports.getRegistrationProgress = getRegistrationProgress;
var isResetPasswordLoading = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetPasswordLoading;
});
exports.isResetPasswordLoading = isResetPasswordLoading;
var getResetPasswordError = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetPasswordError;
});
exports.getResetPasswordError = getResetPasswordError;
var isLoginLoading = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.loginLoading;
});
exports.isLoginLoading = isLoginLoading;
var isLoginMailSent = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.showMailSentAuthMessage;
});
exports.isLoginMailSent = isLoginMailSent;
var getLoginError = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.loginError;
});
exports.getLoginError = getLoginError;
var isPhoneLoginLoading = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.loginPhoneLoading;
});
exports.isPhoneLoginLoading = isPhoneLoginLoading;
var getPhoneLoginError = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.loginPhoneError;
});
exports.getPhoneLoginError = getPhoneLoginError;
var isResetPasswordActionLoading = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetPasswordActionLoading;
});
exports.isResetPasswordActionLoading = isResetPasswordActionLoading;
var getResetPasswordActionError = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetPasswordActionError;
});
exports.getResetPasswordActionError = getResetPasswordActionError;
var isValidResetCode = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.validResetCode;
});
exports.isValidResetCode = isValidResetCode;
var getResetPasswordConfirmError = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetConfirmError;
});
exports.getResetPasswordConfirmError = getResetPasswordConfirmError;
var resetActionSucceeded = (0, _toolkit.createSelector)(authStateSelector, function (state) {
  return state.resetActionSucceeded;
});
exports.resetActionSucceeded = resetActionSucceeded;