var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _festival = _interopRequireDefault(require("../festival"));

var InitialState = _immutable.default.OrderedMap();

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _festival.default.constants.LOAD_FESTIVALS_COMPLETED:
      for (var i in action.festival) {
        newState = newState.set(action.festival[i].id, InitialState);
        newState = newState.setIn([action.festival[i].id], _immutable.default.fromJS(action.festival[i].stages));
      }

      break;

    default:
      break;
  }

  return newState;
}