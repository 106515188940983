var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ShareModalView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactShare = require("react-share");

var _shareModalStyles = _interopRequireWildcard(require("./share-modal-styles.web"));

var _reactModal = _interopRequireDefault(require("react-modal"));

var _Paragraph = _interopRequireDefault(require("../../typography_v1/Paragraph"));

var _CustomIcon = _interopRequireDefault(require("../../../../assets/icons/CustomIcon.js"));

var _reactNativePaper = require("react-native-paper");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ShareModalView(props) {
  var parsedMessage = props.message ? props.message.replace('[APP_INVITE_URL]', props.url) : props.message;
  return _react.default.createElement(_reactModal.default, {
    isOpen: true,
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEsc: true,
    onRequestClose: props.onClose,
    style: _shareModalStyles.customStyles,
    contentLabel: "Share with friends!"
  }, _react.default.createElement(_reactNative.View, {
    style: _shareModalStyles.default.container
  }, _react.default.createElement(_reactNative.View, {
    style: _shareModalStyles.default.modalHeader
  }, _react.default.createElement(_Paragraph.default, {
    style: _shareModalStyles.default.headerTitle
  }, "Share with friends!"), _react.default.createElement(_reactNativePaper.TouchableRipple, {
    testID: "closeButton",
    onClick: props.onClose,
    style: _shareModalStyles.default.closeButton
  }, _react.default.createElement(_CustomIcon.default, {
    name: "close",
    size: 24,
    color: "#fff"
  }))), _react.default.createElement(_reactNative.View, {
    style: _shareModalStyles.default.shareButtons
  }, _react.default.createElement(_reactShare.FacebookShareButton, {
    quote: parsedMessage,
    url: props.url
  }, _react.default.createElement(_reactShare.FacebookIcon, {
    round: true,
    size: 48
  })), _react.default.createElement(_reactShare.TwitterShareButton, {
    title: parsedMessage,
    url: props.url
  }, _react.default.createElement(_reactShare.TwitterIcon, {
    round: true,
    size: 48
  })), _react.default.createElement(_reactShare.WhatsappShareButton, {
    url: props.url,
    title: parsedMessage,
    separator: " - "
  }, _react.default.createElement(_reactShare.WhatsappIcon, {
    round: true,
    size: 48
  })), _react.default.createElement(_reactShare.EmailShareButton, {
    url: props.url,
    subject: parsedMessage,
    body: parsedMessage,
    separator: " - "
  }, _react.default.createElement(_reactShare.EmailIcon, {
    round: true,
    size: 48
  })))));
}