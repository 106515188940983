var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ClientState;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _useClientState3 = _interopRequireDefault(require("../../../utils/hooks/useClientState"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function ClientState(_ref) {
  var _ref$disconnectedStat = _ref.disconnectedState,
      disconnectedState = _ref$disconnectedStat === void 0 ? null : _ref$disconnectedStat,
      _ref$connectingState = _ref.connectingState,
      connectingState = _ref$connectingState === void 0 ? null : _ref$connectingState,
      _ref$connectedState = _ref.connectedState,
      connectedState = _ref$connectedState === void 0 ? null : _ref$connectedState,
      _ref$disconnectingSta = _ref.disconnectingState,
      disconnectingState = _ref$disconnectingSta === void 0 ? null : _ref$disconnectingSta,
      _ref$reconnectingOver = _ref.reconnectingOverlay,
      reconnectingOverlay = _ref$reconnectingOver === void 0 ? null : _ref$reconnectingOver;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      client = _useAudioVideoContext.client;

  var _useClientState = (0, _useClientState3.default)(client),
      _useClientState2 = (0, _slicedToArray2.default)(_useClientState, 2),
      prevState = _useClientState2[0],
      state = _useClientState2[1];

  var currentState = state === 'RECONNECTING' ? prevState : state;

  _react.default.useEffect(function () {
    _logger.default.debug("The current client state is: ".concat(currentState, "."));
  }, [currentState]);

  var content = [];

  switch (currentState) {
    case 'DISCONNECTED':
      content.push(disconnectedState);
      break;

    case 'DISCONNECTING':
      content.push(disconnectingState);
      break;

    case 'CONNECTING':
      content.push(connectingState);
      break;

    case 'CONNECTED':
      content.push(connectedState);
      break;

    case 'RECONNECTING':
      content.push(reconnectingOverlay);
      break;

    default:
      content.push(connectingState);
      break;
  }

  return _react.default.createElement(_react.default.Fragment, null, content);
}