Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useEventInfoStyle = new _DesignSystem.MakeStyle().addConstantStyle({
  imgBackground: {
    flex: 1,
    justifyContent: 'center'
  },
  imgBackgroundBlack: {
    backgroundColor: 'black',
    position: 'absolute',
    opacity: 0.65,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}).addThemeBasedStyle(function (theme) {
  return {
    container: {
      padding: theme.spacing.base * 8,
      paddingBottom: theme.spacing.base * 10,
      marginRight: theme.spacing.checkout + theme.spacing.base * 6,
      alignItems: 'flex-start'
    },
    descriptionText: {
      zIndex: -1
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, 1152, function (theme) {
  return {
    container: {
      marginRight: 0,
      paddingTop: theme.spacing.base * 3,
      paddingRight: theme.spacing.base * 3,
      paddingBottom: theme.spacing.base * 3,
      paddingLeft: theme.spacing.base * 3
    }
  };
}).buildHook();
var _default = useEventInfoStyle;
exports.default = _default;