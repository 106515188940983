var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasIcon;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

function CanvasIcon(_ref) {
  var size = _ref.size,
      icon = _ref.icon,
      color = _ref.color,
      _ref$reversed = _ref.reversed,
      reversed = _ref$reversed === void 0 ? false : _ref$reversed,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 0 : _ref$x,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 0 : _ref$y;
  var scale = reversed ? -1 : 1;
  return _react.default.createElement(_reactKonva.Text, {
    x: x - scale * size,
    y: y,
    text: icon,
    fontSize: size,
    scaleX: scale,
    fontFamily: '"Font Awesome 5 Free"',
    fill: color,
    fontStyle: '900'
  });
}

CanvasIcon.icons = {
  MIC_OFF: "\uF131",
  NO_SOUND: "\uF6A9",
  NO_USER: "\uF235",
  EYE: "\uF06E"
};