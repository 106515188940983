Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useBenefitsStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    benefit: {
      maxWidth: 268
    },
    benefits: {
      margin: -theme.spacing.l
    }
  };
}).buildHook();
var _default = useBenefitsStyles;
exports.default = _default;