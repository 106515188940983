var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConfirmationDialog;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _Dialog = _interopRequireDefault(require("@material-ui/core/Dialog"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _DialogActions = _interopRequireDefault(require("@material-ui/core/DialogActions"));

var _DialogContent = _interopRequireDefault(require("@material-ui/core/DialogContent"));

var _DialogContentText = _interopRequireDefault(require("@material-ui/core/DialogContentText"));

var _DialogTitle = _interopRequireDefault(require("@material-ui/core/DialogTitle"));

var _Typography = _interopRequireDefault(require("@material-ui/core/Typography"));

function ConfirmationDialog(_ref) {
  var onConfirm = _ref.onConfirm,
      children = _ref.children,
      title = _ref.title,
      content = _ref.content,
      yes = _ref.yes,
      no = _ref.no;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  return _react.default.createElement("div", null, children(function () {
    return setOpen(true);
  }), _react.default.createElement(_Dialog.default, {
    open: open,
    onClose: function onClose() {
      return setOpen(false);
    },
    "aria-labelledby": "alert-dialog-title",
    "aria-describedby": "alert-dialog-description"
  }, _react.default.createElement(_DialogTitle.default, {
    id: "alert-dialog-title",
    disableTypography: true
  }, _react.default.createElement(_Typography.default, {
    variant: "h5",
    align: "left"
  }, title)), _react.default.createElement(_DialogContent.default, null, _react.default.createElement(_DialogContentText.default, {
    align: "left",
    id: "alert-dialog-description"
  }, content)), _react.default.createElement(_DialogActions.default, null, _react.default.createElement(_Button.default, {
    onClick: function onClick() {
      return setOpen(false);
    },
    color: "primary"
  }, no), _react.default.createElement(_Button.default, {
    disableElevation: true,
    color: "primary",
    variant: "contained",
    onClick: function onClick() {
      setOpen(false);
      onConfirm();
    }
  }, yes))));
}