var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _avatars = _interopRequireDefault(require("../../common/avatars"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user/selectors");

var _quickAccessButton = _interopRequireDefault(require("../button/quick-access-button"));

var _sessionUtils = require("../../../utils/session-utils");

var _liveDetailsAndPreviewStyles = _interopRequireDefault(require("./live-details-and-preview-styles"));

var _liveNowSingle = _interopRequireDefault(require("../../protected-pages/live-now-page/live-now-single"));

var _videoTypeConstants = require("../../../constants/video-type-constants");

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

var CalendarLivePreviewAndDetails = function CalendarLivePreviewAndDetails(_ref) {
  var live = _ref.live,
      selectedDay = _ref.selectedDay,
      onAvatarNavigation = _ref.onAvatarNavigation,
      onLiveSessionNavigation = _ref.onLiveSessionNavigation,
      eventDateFormat = _ref.eventDateFormat,
      onNetworkingSessionNavigation = _ref.onNetworkingSessionNavigation;
  var Styles = (0, _liveDetailsAndPreviewStyles.default)();
  var classes = (0, _styles.default)();
  var isLoggedIn = (0, _reactRedux.useSelector)(_selectors.isFirebaseUserLoggedIn);
  if (!live) return null;
  if ((0, _momentTimezone.default)(selectedDay).format('MM-DD') !== (0, _momentTimezone.default)().format('MM-DD')) return null;
  var sessionToShow = live.currentSession || live.nextSession;
  if (!sessionToShow) return null;

  var _getSessionDetailsFor = (0, _sessionUtils.getSessionDetailsForCalendar)(sessionToShow, eventDateFormat),
      isLive = _getSessionDetailsFor.isLive,
      startHour = _getSessionDetailsFor.startHour,
      endHour = _getSessionDetailsFor.endHour,
      zoneAbbreviation = _getSessionDetailsFor.zoneAbbreviation,
      speakerUrlsAndIds = _getSessionDetailsFor.speakerUrlsAndIds;

  var renderSessionBadge = function renderSessionBadge() {
    var badgeStyles = isLive ? classes.liveNow : classes.upcoming;
    return _react.default.createElement(_core.Typography, {
      variant: "overline",
      className: (0, _clsx.default)(badgeStyles, classes.text)
    }, _i18n.default.t(isLive ? 'calendar.liveNow' : 'calendar.upcoming'));
  };

  return _react.default.createElement(_Spacing.default, {
    alignItems: "stretch",
    key: "live-preview",
    style: Styles.videoSection
  }, !(sessionToShow.videoType === _videoTypeConstants.NETWORKING) && _react.default.createElement(_liveNowSingle.default, {
    stage: live,
    key: live.id,
    showTitleSection: false,
    flex: 1
  }), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.detailsSection,
    flex: 1
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "flex-start"
  }, renderSessionBadge(), _react.default.createElement(_core.Typography, {
    className: classes.text,
    variant: "overline"
  }, "".concat(startHour, " - ").concat(endHour, " (").concat(zoneAbbreviation, ")"))), _react.default.createElement(_core.Typography, {
    className: classes.title
  }, sessionToShow.title), sessionToShow.videoType === _videoTypeConstants.NETWORKING && _react.default.createElement(_core.Typography, {
    className: classes.networkingText
  }, _i18n.default.t(isLive ? 'calendar.networkingTextActive' : 'calendar.networkingTextUpcoming')), _react.default.createElement(_avatars.default, {
    style: Styles.avatars,
    onPress: onAvatarNavigation,
    urls: speakerUrlsAndIds,
    isCentered: false
  }), _react.default.createElement(_core.Typography, null, sessionToShow.description), isLoggedIn && isLive && _react.default.createElement(_quickAccessButton.default, {
    alignment: "left",
    session: sessionToShow,
    liveNavigation: function liveNavigation() {
      return onLiveSessionNavigation(live.id);
    },
    networkingNavigation: function networkingNavigation() {
      return onNetworkingSessionNavigation(sessionToShow.networkTable);
    }
  })));
};

var _default = CalendarLivePreviewAndDetails;
exports.default = _default;