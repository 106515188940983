var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackstageState;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

function BackstageState(_ref) {
  var greenRoomState = _ref.greenRoomState,
      requestState = _ref.requestState,
      liveRoomState = _ref.liveRoomState,
      liveLoadingState = _ref.liveLoadingState,
      liveState = _ref.liveState,
      backstageId = _ref.backstageId;
  var userIsInGreenRoom = (0, _reactRedux.useSelector)((0, _selectors.userIsInParticipants)(backstageId));
  var userIsInLiveRoom = (0, _reactRedux.useSelector)((0, _selectors.userIsInLiveParticipantsSelector)(backstageId));
  var isLive = (0, _reactRedux.useSelector)((0, _selectors.isRoomLiveStartedSelector)(backstageId));
  var isLiveLoading = (0, _reactRedux.useSelector)((0, _selectors.isRoomLiveStartingSelector)(backstageId));
  var userHasRequest = (0, _reactRedux.useSelector)((0, _selectors.userHasRequestSelector)(backstageId));
  var stateView = null;

  if (userIsInGreenRoom) {
    stateView = userHasRequest ? requestState : greenRoomState;
  }

  if (userIsInLiveRoom) {
    if (isLive) {
      stateView = liveState;
    } else {
      stateView = isLiveLoading ? liveLoadingState : liveRoomState;
    }
  }

  return _react.default.createElement(_react.default.Fragment, null, stateView);
}