var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WelcomeNotification;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

function WelcomeNotification(_ref) {
  var children = _ref.children,
      id = _ref.id,
      notification = _ref.notification,
      _ref$showInitially = _ref.showInitially,
      showInitially = _ref$showInitially === void 0 ? true : _ref$showInitially;

  var _React$useState = _react.default.useState(function () {
    var result = localStorage.getItem(id);

    if (result) {
      var _JSON$parse = JSON.parse(result),
          show = _JSON$parse.show,
          expiration = _JSON$parse.expiration;

      if (expiration < Date.now()) {
        localStorage.removeItem(id);
        return showInitially;
      }

      return show;
    }

    return showInitially;
  }),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      showNotification = _React$useState2[0],
      setShowNotification = _React$useState2[1];

  _react.default.useEffect(function () {
    if (showNotification) {
      localStorage.setItem(id, JSON.stringify({
        show: false,
        id: id,
        expiration: Date.now() + 86400000
      }));
    }
  }, [showNotification]);

  if (showNotification) {
    return _react.default.createElement(_react.default.Fragment, null, notification(function () {
      return setShowNotification(false);
    }));
  }

  return _react.default.createElement(_react.default.Fragment, null, children);
}