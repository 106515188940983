var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DeferredDeepLinkContext = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var DeferredDeepLinkContext = React.createContext({
  releaseDeferredDeepLink: function releaseDeferredDeepLink() {}
});
exports.DeferredDeepLinkContext = DeferredDeepLinkContext;

var DeferredDeepLinkProvider = function (_React$PureComponent) {
  (0, _inherits2.default)(DeferredDeepLinkProvider, _React$PureComponent);

  var _super = _createSuper(DeferredDeepLinkProvider);

  function DeferredDeepLinkProvider() {
    var _this;

    (0, _classCallCheck2.default)(this, DeferredDeepLinkProvider);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this._deferredDeepLink = undefined;

    _this._handleOpenURL = function (event) {
      var regex = /^soundmix.*:\/\//gm;

      if (regex.test(event.url)) {
        _this._deferredDeepLink = event.url;
      }
    };

    _this.releaseDeferredDeepLink = function () {
      _reactNative.Linking.removeEventListener('url', _this._handleOpenURL);

      if (_this._deferredDeepLink) {
        _reactNative.Linking.openURL(_this._deferredDeepLink);

        _this._deferredDeepLink = undefined;
      }
    };

    return _this;
  }

  (0, _createClass2.default)(DeferredDeepLinkProvider, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _reactNative.Linking.addEventListener('url', this._handleOpenURL);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _reactNative.Linking.removeEventListener('url', this._handleOpenURL);
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(DeferredDeepLinkContext.Provider, {
        value: {
          releaseDeferredDeepLink: this.releaseDeferredDeepLink
        }
      }, this.props.children);
    }
  }]);
  return DeferredDeepLinkProvider;
}(React.PureComponent);

exports.default = DeferredDeepLinkProvider;