var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CalendarStageView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../theme/DesignSystem");

var _styles = _interopRequireDefault(require("./styles"));

function CalendarStageView(_ref) {
  var stage = _ref.stage,
      selected = _ref.selected,
      stageSelected = _ref.stageSelected;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var classes = (0, _styles.default)();
  return _react.default.createElement(_reactNative.TouchableHighlight, {
    underlayColor: "".concat(colors.buttonHover, "55"),
    style: [{
      borderBottomWidth: 4,
      borderBottomStyle: 'solid'
    }, selected ? {
      borderBottomColor: colors.button
    } : {
      borderBottomColor: "transparent"
    }],
    onPress: function onPress() {
      return stageSelected(stage);
    }
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Spacing.default, {
    pSpacing: ['m', 'l']
  }, _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: classes.textBold
  }, stage.name))));
}