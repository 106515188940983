Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEBSOCKET_STATUS = exports.WEBSOCKET_EVENTS = void 0;
var WEBSOCKET_EVENTS = {
  CONNECT: 'connect',
  CONNECTING: 'connecting',
  DISCONNECT: 'disconnect',
  CONNECT_FAILED: 'connect_failed',
  ERROR: 'error',
  RECONNECT: 'reconnect',
  RECONNECTING: 'reconnecting',
  RECONNECT_FAILED: 'reconnect_failed'
};
exports.WEBSOCKET_EVENTS = WEBSOCKET_EVENTS;
var WEBSOCKET_STATUS = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected',
  DISCONNECTING: 'disconnecting'
};
exports.WEBSOCKET_STATUS = WEBSOCKET_STATUS;