var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReadMoreView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _Button = _interopRequireDefault(require("../../../common/Button"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

var _readMoreStyles = _interopRequireDefault(require("./read-more-styles"));

function ReadMoreView() {
  var navigation = (0, _reactNavigationHooks.useNavigation)();
  return _react.default.createElement(_reactNative.View, {
    style: _readMoreStyles.default.headerBanner
  }, _react.default.createElement(_Title.default, {
    style: _readMoreStyles.default.bannerTitle
  }, "Entertain your fans and win cash every month"), _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Button.default, {
    testID: 'TapGoToLeaderboardAbout',
    mode: "contained",
    color: "#000",
    onPress: function onPress() {
      return navigation.navigate('LeaderboardAbout');
    }
  }, "Learn more")));
}