var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ExploreScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _reactRedux = require("react-redux");

var _DjExploreSection = _interopRequireDefault(require("../common/dj/DjExploreSection"));

var _FeaturedEventsFeed = _interopRequireDefault(require("./FeaturedEventsFeed"));

var _events = _interopRequireDefault(require("../../core/events"));

var _profile = _interopRequireDefault(require("../../core/profile"));

var _hashtags = _interopRequireDefault(require("../../core/hashtags"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _LoadingScreen = _interopRequireDefault(require("../LoadingScreen"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ExploreScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ExploreScreenInternal, _React$PureComponent);

  var _super = _createSuper(ExploreScreenInternal);

  function ExploreScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, ExploreScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      refreshing: false,
      djFeedLoaded: _this.props.hasDjLoaded,
      featuredEventsLoaded: _this.props.hasFeaturedEventsLoaded,
      eventHashtagsLoaded: _this.props.hasHashtagsLoaded
    };
    return _this;
  }

  (0, _createClass2.default)(ExploreScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate() {}
  }, {
    key: "hasReloaded",
    value: function hasReloaded() {
      if (this.state.djFeedLoaded && this.state.featuredEventsLoaded && this.state.eventHashtagsLoaded) {
        this.setState({
          refreshing: false
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return React.createElement(React.Fragment, null, React.createElement(_reactNativeSafeAreaView.default, {
        style: styles.mainBackground,
        forceInset: {
          top: 'always',
          bottom: 'always'
        }
      }, React.createElement(_reactNative.ScrollView, {
        style: styles.mainBackground,
        refreshControl: React.createElement(_reactNative.RefreshControl, {
          refreshing: this.state.refreshing,
          tintColor: 'white',
          onRefresh: function onRefresh() {
            _this2.setState({
              refreshing: true,
              djFeedLoaded: false,
              featuredEventsLoaded: false,
              eventHashtagsLoaded: false
            });
          }
        })
      }, React.createElement(_reactNative.View, {
        style: styles.moreSection
      }, React.createElement(_reactNative.View, {
        style: styles.dividerContainer
      }, React.createElement(_reactNative.View, {
        style: styles.dividerLine
      }), React.createElement(_Paragraph.default, {
        style: styles.dividerText
      }, "All Caught Up!"), React.createElement(_reactNative.View, {
        style: styles.dividerLine
      })), React.createElement(_reactNative.View, {
        style: styles.moreButtonContainer
      }, React.createElement(_Paragraph.default, {
        style: {
          marginBottom: 16,
          textAlign: 'center',
          opacity: 0.5
        }
      }, "More events will be announced soon!"))))));
    }
  }]);
  return ExploreScreenInternal;
}(React.PureComponent);

exports.ExploreScreenInternal = ExploreScreenInternal;

var styles = _reactNative.StyleSheet.create({
  mainBackground: {
    backgroundColor: 'black',
    flex: 1
  },
  LoadingScreen: {
    flex: 1,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%'
  },
  dividerContainer: {
    height: 24,
    marginVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dividerLine: {
    flex: 1,
    height: 24,
    borderBottomWidth: 1,
    borderColor: 'rgba(255,255,255,0.2)',
    transform: [{
      translateY: -12
    }]
  },
  dividerText: {
    paddingHorizontal: 16
  },
  moreSection: {
    alignItems: 'center',
    marginBottom: 20
  },
  moreButtonContainer: {}
});

function mapStateToProps(state) {
  return {
    hasFeaturedEventsLoaded: _events.default.selectors.hasLoaded(state),
    hasDjLoaded: _profile.default.selectors.hasDjLoaded(state),
    hasHashtagsLoaded: _hashtags.default.selectors.hasHashTagsLoaded(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(ExploreScreenInternal);

exports.default = _default;