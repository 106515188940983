var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RegisterProgress;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../../../core/auth/selectors");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function listener(event) {
  event.preventDefault();
  event.returnValue = 'Are you sure?';
}

function RegisterProgress() {
  var loading = (0, _reactRedux.useSelector)(_selectors.isRegistrationLoading);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  _react.default.useEffect(function () {
    if (loading) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }

    return function () {
      window.removeEventListener('beforeunload', listener);
    };
  }, [loading]);

  if (!loading) return null;
  return _react.default.createElement(_Spacing.default, {
    color: "pageBackground",
    alignItems: "center",
    justifyContent: "center",
    direction: "column",
    style: _objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
      zIndex: 999
    })
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, _i18n.default.translate('register.doNotClose')), _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0]
  }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('register.inProgress'))), _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0]
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    color: colors.button,
    size: "large"
  })));
}