Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useUserBackstageFeatures;

var _reactRedux = require("react-redux");

var _selectors = require("../../core/user-roles/selectors");

function useUserBackstageFeatures(backstageId, allowedFeatures) {
  var featuresByBackstage = (0, _reactRedux.useSelector)((0, _selectors.hasUserFeatureAccessSelector)(allowedFeatures, backstageId));

  if (!backstageId) {
    return false;
  }

  return featuresByBackstage;
}