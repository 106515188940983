Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ASSIGNED_FIELD = exports.FULLNAME_FIELD = exports.EMAIL_FIELD = exports.GET_BOUGHT_TICKETS = exports.GET_UNASSIGNED_TICKETS = exports.DELETE_TICKET_ON_HOLD = exports.SET_TICKET_ON_HOLD = exports.GET_TICKET_ON_HOLD = exports.GET_TICKET_RIGHTS = exports.GET_TICKETS = exports.NAME = void 0;
var NAME = 'tickets';
exports.NAME = NAME;
var GET_TICKETS = 'tickets/GET_TICKETS';
exports.GET_TICKETS = GET_TICKETS;
var GET_TICKET_RIGHTS = 'tickets/GET_TICKET_RIGHTS';
exports.GET_TICKET_RIGHTS = GET_TICKET_RIGHTS;
var GET_TICKET_ON_HOLD = 'tickets/GET_TICKET_ON_HOLD';
exports.GET_TICKET_ON_HOLD = GET_TICKET_ON_HOLD;
var SET_TICKET_ON_HOLD = 'tickets/SET_TICKET_ON_HOLD';
exports.SET_TICKET_ON_HOLD = SET_TICKET_ON_HOLD;
var DELETE_TICKET_ON_HOLD = 'tickets/DELETE_TICKET_ON_HOLD';
exports.DELETE_TICKET_ON_HOLD = DELETE_TICKET_ON_HOLD;
var GET_UNASSIGNED_TICKETS = 'tickets/GET_UNASSIGNED_TICKETS';
exports.GET_UNASSIGNED_TICKETS = GET_UNASSIGNED_TICKETS;
var GET_BOUGHT_TICKETS = 'tickets/GET_BOUGHT_TICKETS';
exports.GET_BOUGHT_TICKETS = GET_BOUGHT_TICKETS;
var EMAIL_FIELD = 'email';
exports.EMAIL_FIELD = EMAIL_FIELD;
var FULLNAME_FIELD = 'fullName';
exports.FULLNAME_FIELD = FULLNAME_FIELD;
var ASSIGNED_FIELD = 'assigned';
exports.ASSIGNED_FIELD = ASSIGNED_FIELD;