Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useAuthStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  scrollView: {
    flex: 1,
    alignItems: 'center'
  },
  container: {
    width: '100%',
    maxWidth: 540
  }
}).addThemeBasedStyle(function (theme) {
  return {
    snackbar: {
      backgroundColor: theme.colors.successColor,
      bottom: -80
    },
    snackbarText: {
      color: '#FFFFFF'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    authBox: {
      marginLeft: theme.spacing.l,
      marginRight: theme.spacing.l,
      marginTop: theme.spacing.m,
      marginBottom: theme.spacing.m
    }
  };
}).buildHook();
var _default = useAuthStyles;
exports.default = _default;