var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUserMutedSelector = exports.audienceCountSelector = exports.liveStartedAtSelector = exports.liveStartedBySelector = exports.startLiveCountDownSelector = exports.roomLoadedSelector = exports.roomLoadingSelector = exports.roomScreenShareIdSelector = exports.roomHasScreenShareSelector = exports.roomScreenSharerSelector = exports.roomErrorSelector = exports.isRoomLiveStartingSelector = exports.isRoomLiveStartedSelector = exports.userHasDeclineSelector = exports.userHasRequestSelector = exports.userSelector = exports.userRoomParticipants = exports.userIsInLiveParticipantsSelector = exports.userIsInParticipants = exports.userIsInRoomSelector = exports.roomParticipantsSelector = exports.liveParticipantsSelector = exports.participantsWithRequestSelector = exports.roomAllParticipantsSelector = exports.roomsStateSelector = exports.roomStateSelector = exports.userIdSelector = exports.accessTokensSelector = exports.roomsConnectionErrorSelector = exports.roomsConnectionStatusSelector = exports.roomStatesSelector = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var trackingStateSelector = function trackingStateSelector(state) {
  return state[_constants.NAME];
};

var roomStatesSelector = (0, _toolkit.createSelector)(trackingStateSelector, function (state) {
  return state.roomStates;
});
exports.roomStatesSelector = roomStatesSelector;

var _roomStateByIdSelector = function _roomStateByIdSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStatesSelector, function (state) {
    return state[roomId] || {};
  });
};

var roomsConnectionStatusSelector = (0, _toolkit.createSelector)(trackingStateSelector, function (state) {
  return state.status;
});
exports.roomsConnectionStatusSelector = roomsConnectionStatusSelector;
var roomsConnectionErrorSelector = (0, _toolkit.createSelector)(trackingStateSelector, function (state) {
  return state.error;
});
exports.roomsConnectionErrorSelector = roomsConnectionErrorSelector;

var accessTokensSelector = function accessTokensSelector(roomId) {
  return (0, _toolkit.createSelector)(_roomStateByIdSelector(roomId), function (state) {
    return state.tokens || {};
  });
};

exports.accessTokensSelector = accessTokensSelector;

var userIdSelector = function userIdSelector(roomId) {
  return (0, _toolkit.createSelector)(accessTokensSelector(roomId), function (tokens) {
    return tokens.id;
  });
};

exports.userIdSelector = userIdSelector;

var roomStateSelector = function roomStateSelector(roomId) {
  return (0, _toolkit.createSelector)(_roomStateByIdSelector(roomId), function (state) {
    return state.room || {};
  });
};

exports.roomStateSelector = roomStateSelector;
var roomsStateSelector = (0, _toolkit.createSelector)(roomStatesSelector, function (state) {
  return Object.values(state || {});
});
exports.roomsStateSelector = roomsStateSelector;

var roomAllParticipantsSelector = function roomAllParticipantsSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.participants || {};
  });
};

exports.roomAllParticipantsSelector = roomAllParticipantsSelector;

var participantsWithRequestSelector = function participantsWithRequestSelector(roomId) {
  return (0, _toolkit.createSelector)(roomAllParticipantsSelector(roomId), function (participants) {
    return Object.entries(participants).reduce(function (accum, _ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return value.isRequesting ? _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, key, value)) : accum;
    }, {});
  });
};

exports.participantsWithRequestSelector = participantsWithRequestSelector;

var liveParticipantsSelector = function liveParticipantsSelector(roomId) {
  return (0, _toolkit.createSelector)(roomAllParticipantsSelector(roomId), function (participants) {
    return Object.entries(participants).reduce(function (accum, _ref3) {
      var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
          key = _ref4[0],
          value = _ref4[1];

      return value.isInLive ? _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, key, value)) : accum;
    }, {});
  });
};

exports.liveParticipantsSelector = liveParticipantsSelector;

var roomParticipantsSelector = function roomParticipantsSelector(roomId) {
  return (0, _toolkit.createSelector)(roomAllParticipantsSelector(roomId), function (participants) {
    return Object.entries(participants).reduce(function (accum, _ref5) {
      var _ref6 = (0, _slicedToArray2.default)(_ref5, 2),
          key = _ref6[0],
          value = _ref6[1];

      return !value.isInLive ? _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, key, value)) : accum;
    }, {});
  });
};

exports.roomParticipantsSelector = roomParticipantsSelector;

var userIsInRoomSelector = function userIsInRoomSelector(roomId) {
  return (0, _toolkit.createSelector)(userIdSelector(roomId), roomAllParticipantsSelector(roomId), function (uid, participants) {
    return Boolean(participants[uid]);
  });
};

exports.userIsInRoomSelector = userIsInRoomSelector;

var userIsInParticipants = function userIsInParticipants(roomId) {
  return (0, _toolkit.createSelector)(userIdSelector(roomId), roomParticipantsSelector(roomId), function (uid, participants) {
    return Boolean(participants[uid]);
  });
};

exports.userIsInParticipants = userIsInParticipants;

var userIsInLiveParticipantsSelector = function userIsInLiveParticipantsSelector(roomId) {
  return (0, _toolkit.createSelector)(userIdSelector(roomId), liveParticipantsSelector(roomId), function (uid, participants) {
    return Boolean(participants[uid]);
  });
};

exports.userIsInLiveParticipantsSelector = userIsInLiveParticipantsSelector;

var userRoomParticipants = function userRoomParticipants(roomId) {
  return (0, _toolkit.createSelector)(userIdSelector(roomId), roomAllParticipantsSelector(roomId), function (uid, participants) {
    var user = participants[uid];
    if (!user) return {};
    return Object.entries(participants).reduce(function (accum, _ref7) {
      var _ref8 = (0, _slicedToArray2.default)(_ref7, 2),
          key = _ref8[0],
          value = _ref8[1];

      if (user.isInLive === value.isInLive) {
          return _objectSpread(_objectSpread({}, accum), {}, (0, _defineProperty2.default)({}, key, value));
        } else {
        return accum;
      }
    }, {});
  });
};

exports.userRoomParticipants = userRoomParticipants;

var userSelector = function userSelector(roomId) {
  return (0, _toolkit.createSelector)(userIdSelector(roomId), roomAllParticipantsSelector(roomId), function (uid, participants) {
    return participants[uid] || {};
  });
};

exports.userSelector = userSelector;

var userHasRequestSelector = function userHasRequestSelector(roomId) {
  return (0, _toolkit.createSelector)(userSelector(roomId), function (user) {
    return user.isRequesting;
  });
};

exports.userHasRequestSelector = userHasRequestSelector;

var userHasDeclineSelector = function userHasDeclineSelector(roomId) {
  return (0, _toolkit.createSelector)(userSelector(roomId), function (user) {
    return user.isDeclined;
  });
};

exports.userHasDeclineSelector = userHasDeclineSelector;

var isRoomLiveStartedSelector = function isRoomLiveStartedSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.liveStatus === 'started';
  });
};

exports.isRoomLiveStartedSelector = isRoomLiveStartedSelector;

var isRoomLiveStartingSelector = function isRoomLiveStartingSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.liveStatus === 'starting';
  });
};

exports.isRoomLiveStartingSelector = isRoomLiveStartingSelector;

var roomErrorSelector = function roomErrorSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.error;
  });
};

exports.roomErrorSelector = roomErrorSelector;

var roomScreenSharerSelector = function roomScreenSharerSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.screenSharer || {};
  });
};

exports.roomScreenSharerSelector = roomScreenSharerSelector;

var roomHasScreenShareSelector = function roomHasScreenShareSelector(roomId) {
  return (0, _toolkit.createSelector)(roomScreenSharerSelector(roomId), function (screenSharer) {
    return Boolean(screenSharer.id);
  });
};

exports.roomHasScreenShareSelector = roomHasScreenShareSelector;

var roomScreenShareIdSelector = function roomScreenShareIdSelector(roomId) {
  return (0, _toolkit.createSelector)(roomScreenSharerSelector(roomId), function (screenSharer) {
    return screenSharer.screenShareId;
  });
};

exports.roomScreenShareIdSelector = roomScreenShareIdSelector;

var roomLoadingSelector = function roomLoadingSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.loading;
  });
};

exports.roomLoadingSelector = roomLoadingSelector;

var roomLoadedSelector = function roomLoadedSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.loaded;
  });
};

exports.roomLoadedSelector = roomLoadedSelector;

var startLiveCountDownSelector = function startLiveCountDownSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.countDown;
  });
};

exports.startLiveCountDownSelector = startLiveCountDownSelector;

var liveStartedBySelector = function liveStartedBySelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.liveStartedBy;
  });
};

exports.liveStartedBySelector = liveStartedBySelector;

var liveStartedAtSelector = function liveStartedAtSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return new Date(room.liveStartedAt);
  });
};

exports.liveStartedAtSelector = liveStartedAtSelector;

var audienceCountSelector = function audienceCountSelector(roomId) {
  return (0, _toolkit.createSelector)(roomStateSelector(roomId), function (room) {
    return room.viewersCount || 0;
  });
};

exports.audienceCountSelector = audienceCountSelector;

var isUserMutedSelector = function isUserMutedSelector(roomId, userId) {
  return (0, _toolkit.createSelector)(roomAllParticipantsSelector(roomId), function (participants) {
    var _participants$userId;

    return (_participants$userId = participants[userId]) === null || _participants$userId === void 0 ? void 0 : _participants$userId.isMuted;
  });
};

exports.isUserMutedSelector = isUserMutedSelector;