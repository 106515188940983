Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closeDialog = exports.openDialog = exports.stopGlobalLoading = exports.startGlobalLoading = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var startGlobalLoading = (0, _toolkit.createAction)(_constants.START_LOADING);
exports.startGlobalLoading = startGlobalLoading;
var stopGlobalLoading = (0, _toolkit.createAction)(_constants.STOP_LOADING);
exports.stopGlobalLoading = stopGlobalLoading;
var openDialog = (0, _toolkit.createAction)(_constants.OPEN_DIALOG);
exports.openDialog = openDialog;
var closeDialog = (0, _toolkit.createAction)(_constants.CLOSE_DIALOG);
exports.closeDialog = closeDialog;