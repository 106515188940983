Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var _window = window,
    height = _window.innerHeight;
var useMobileMenuStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
      padding: theme.spacing.xl,
      zIndex: 90
    },
    menuContainer: {
      backgroundColor: theme.colors.containerBackground,
      position: 'absolute',
      width: '100vw',
      minHeight: height,
      top: 0,
      left: 0
    },
    menuInnerContainer: {
      minWidth: '100vw',
      minHeight: height,
      padding: theme.spacing.l,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    menuItem: {
      padding: theme.spacing.s,
      alignItems: 'flex-start'
    },
    login: {
      borderBottomColor: theme.colors.alternativeText,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid'
    },
    copyright: {
      marginTop: theme.spacing.s
    },
    footerPrivacy: {
      marginLeft: theme.spacing.s
    },
    termsLink: {
      color: theme.colors.alternativeText,
      textDecorationLine: 'none'
    }
  };
}).buildHook();
var _default = useMobileMenuStyles;
exports.default = _default;