var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _UsersListScreen = _interopRequireDefault(require("./UsersListScreen"));

var _profile = _interopRequireDefault(require("../../core/profile"));

function mapStateToProps(state, ownProps) {
  var userId = ownProps.userId;
  return {
    users: _profile.default.selectors.getFollowing(state, userId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  load: _profile.default.actions.loadFollowing
})(_UsersListScreen.default);

exports.default = _default;