var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useLiveNowPageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    innerContainer: {
      marginLeft: -theme.spacing.l,
      marginRight: -theme.spacing.l,
      marginTop: -theme.spacing.l,
      flexWrap: 'wrap',
      alignItems: 'flex-start'
    },
    header: {
      paddingBottom: theme.spacing.m
    },
    imgBackground: _objectSpread({
      flex: 1,
      justifyContent: 'center'
    }, theme.shape.cards),
    imageStyle: _objectSpread({}, theme.shape.cards),
    imgBackgroundBlack: _objectSpread({
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      position: 'absolute',
      opacity: 0.75,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }, theme.shape.cards)
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      marginLeft: 0,
      paddingTop: theme.spacing.s,
      paddingBottom: theme.spacing.s,
      paddingLeft: theme.spacing.m,
      paddingRight: theme.spacing.m
    },
    innerContainer: {
      marginLeft: -theme.spacing.m,
      marginRight: -theme.spacing.m
    }
  };
}).buildHook();
var _default = useLiveNowPageStyles;
exports.default = _default;