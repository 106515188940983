var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _eventInfo = _interopRequireDefault(require("../../../core/event-info"));

var _partners = _interopRequireDefault(require("../../../core/partners"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _headerContainer = _interopRequireDefault(require("./header-container"));

var _benefits = _interopRequireDefault(require("../../../core/benefits"));

var _testimonials = _interopRequireDefault(require("../../../core/testimonials"));

var _selectors = require("../../../core/user-profile/selectors");

function mapStateToProps(state) {
  return {
    logoUrl: _eventInfo.default.selectors.selectEventLogoUrl(state),
    userName: (0, _selectors.selectUserDisplayName)(state),
    isLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state),
    partnersLength: _partners.default.selectors.selectPartnersLength(state),
    benefitsLength: _benefits.default.selectors.selectBenefitsLength(state),
    testimonialsLength: _testimonials.default.selectors.selectTestimonialLength(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  logout: _user.default.actions.userLogout
})(_headerContainer.default);

exports.default = _default;