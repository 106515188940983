var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ToggleScreenShare;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _ScreenShare = _interopRequireDefault(require("@material-ui/icons/ScreenShare"));

var _StopScreenShare = _interopRequireDefault(require("@material-ui/icons/StopScreenShare"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _useIsMobileDevice2 = _interopRequireDefault(require("../../../utils/hooks/useIsMobileDevice"));

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _Spacing = _interopRequireDefault(require("./../../../theme/components/spacing/Spacing"));

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user-rooms/actions");

var _selectors = require("../../../core/user-rooms/selectors");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ToggleScreenShare(_ref) {
  var color = _ref.color,
      room = _ref.room;
  var Styles = (0, _audioVideoStyles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var hasScreenShare = (0, _reactRedux.useSelector)((0, _selectors.roomHasScreenShareSelector)(room));

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      start = _useAudioVideoContext.startScreenShare,
      stop = _useAudioVideoContext.stopScreenShare,
      screenShareLoading = _useAudioVideoContext.screenShareLoading,
      hasLocalScreenShare = _useAudioVideoContext.hasScreenShare,
      screenShareVideo = _useAudioVideoContext.screenShareVideo;

  (0, _react.useEffect)(function () {
    if (!screenShareVideo && hasLocalScreenShare) {
      dispatch((0, _actions.stopScreenShare)(room));
    }
  }, [screenShareVideo, hasScreenShare]);

  var onClick = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!hasScreenShare) {
                _context2.next = 6;
                break;
              }

              if (hasLocalScreenShare) {
                stop();
              }

              _context2.next = 4;
              return dispatch((0, _actions.stopScreenShare)(room));

            case 4:
              _context2.next = 7;
              break;

            case 6:
              start(room, function () {
                dispatch((0, _actions.stopScreenShare)(room));
              }, (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
                return _regenerator.default.wrap(function _callee$(_context) {
                  while (1) {
                    switch (_context.prev = _context.next) {
                      case 0:
                        _context.next = 2;
                        return dispatch((0, _actions.startScreenShare)(room));

                      case 2:
                        return _context.abrupt("return", _context.sent);

                      case 3:
                      case "end":
                        return _context.stop();
                    }
                  }
                }, _callee);
              })));

            case 7:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function onClick() {
      return _ref2.apply(this, arguments);
    };
  }();

  var _useIsMobileDevice = (0, _useIsMobileDevice2.default)(),
      isMobile = _useIsMobileDevice.isMobile;

  if (isMobile) return null;
  return _react.default.createElement(_ColoredIconButton.default, {
    disabled: screenShareLoading || (hasLocalScreenShare ? false : hasScreenShare),
    customColor: color,
    onClick: onClick
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, hasScreenShare ? _react.default.createElement(_StopScreenShare.default, null) : _react.default.createElement(_ScreenShare.default, null)));
}