var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _events = _interopRequireDefault(require("../../../../core/events"));

var _SimilarBase = _interopRequireDefault(require("./SimilarBase"));

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;

  var djId = _events.default.selectors.getDjId(state, eventId);

  return {
    events: _events.default.selectors.getEventsBySameDjAndSameMusicType(state, eventId, djId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(_SimilarBase.default);

exports.default = _default;