var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getElapsedSeconds = getElapsedSeconds;
exports.getElapsedTime = exports.getPosterStartPosition = exports.isToday = exports.isNowInDateTimeRange = exports.isStarted = exports.isComingSoon = exports.printMinuteDifference = exports.printHoursAndMinutes = exports.getRemainingTime = exports.getDateBetween = exports.getTimeBasedOnDateFormat = exports.longDateTimeFormat = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _i18n = _interopRequireDefault(require("./i18n"));

var _shortMonthNames = _interopRequireDefault(require("./short-month-names.js"));

var _timezoneUtils = require("../utils/timezone-utils");

var _timeConstants = require("./time-constants");

var longDateTimeFormat = 'DD-MM-YYYY hh:mm A';
exports.longDateTimeFormat = longDateTimeFormat;

var getTimeBasedOnDateFormat = function getTimeBasedOnDateFormat(time, dateFormat) {
  return (0, _momentTimezone.default)(time).format(dateFormat === _timeConstants.AM_PM ? 'hh:mm A' : 'HH:mm');
};

exports.getTimeBasedOnDateFormat = getTimeBasedOnDateFormat;

var getDateBetween = function getDateBetween(startDate, endDate, timezone, dateFormat) {
  var start = (0, _momentTimezone.default)(startDate);
  var end = (0, _momentTimezone.default)(endDate);
  var shortTimezone = (0, _timezoneUtils.getZoneAbbreviation)();
  var startingTime = getTimeBasedOnDateFormat(start, dateFormat);
  var endingTime = getTimeBasedOnDateFormat(end, dateFormat);

  if (start.diff(end, 'days') === 0 && start.day() === end.day()) {
    return "".concat(start.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[start.month()], {
      defaultValue: _shortMonthNames.default[start.month()]
    }).toUpperCase(), ", ").concat(startingTime, " - ").concat(endingTime, " ").concat(shortTimezone);
  } else if (start.month() !== end.month() && start.year() === end.year()) {
    return "".concat(start.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[start.month()], {
      defaultValue: _shortMonthNames.default[start.month()]
    }).toUpperCase(), ", ").concat(startingTime, "  -  ").concat(end.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[end.month()], {
      defaultValue: _shortMonthNames.default[end.month()]
    }).toUpperCase(), ", ").concat(endingTime, " ").concat(shortTimezone);
  } else if (start.year() !== end.year()) {
    return "".concat(start.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[start.month()], {
      defaultValue: _shortMonthNames.default[start.month()]
    }).toUpperCase(), ", ").concat(startingTime, " - ").concat(end.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[end.month()], {
      defaultValue: _shortMonthNames.default[end.month()]
    }).toUpperCase(), ", ").concat(endingTime, " ").concat(shortTimezone);
  }

  return "".concat(start.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[start.month()], {
    defaultValue: _shortMonthNames.default[start.month()]
  }).toUpperCase(), ", ").concat(startingTime, " - ").concat(end.date(), " ").concat(_i18n.default.translate(_shortMonthNames.default[start.month()], {
    defaultValue: _shortMonthNames.default[start.month()]
  }).toUpperCase(), ", ").concat(endingTime, " ").concat(shortTimezone);
};

exports.getDateBetween = getDateBetween;

var getRemainingTime = function getRemainingTime(date) {
  var inDate = new Date(date);

  var _seconds = inDate.getTime() / 1000;

  var now = Date.now() / 1000;

  if (now > _seconds) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  var days = Math.floor((_seconds - now) / 86400);
  var hours = Math.floor((_seconds - now) % 86400 / 3600);
  var minutes = Math.floor((_seconds - now) % 86400 % 3600 / 60);
  var seconds = Math.floor((_seconds - now) % 86400 % 3600 % 60);
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
};

exports.getRemainingTime = getRemainingTime;

var printHoursAndMinutes = function printHoursAndMinutes(dateTime) {
  var date = _momentTimezone.default.unix(dateTime);

  return "".concat(date.hours() || '00', ":").concat(date.minutes() || '00');
};

exports.printHoursAndMinutes = printHoursAndMinutes;

var printMinuteDifference = function printMinuteDifference(start, end, dateFormat) {
  var startDateTime = new Date(start);
  var endDateTime = new Date(end);
  var startDate = (0, _momentTimezone.default)(start);
  var endDate = (0, _momentTimezone.default)(end);
  var startDay = startDate.date();
  var startMonth = startDate.month();
  var endMonth = endDate.month();
  var endDay = endDate.date();
  var formattedStartDateTime = getTimeBasedOnDateFormat(startDateTime, dateFormat);
  var formattedEndDateTime = getTimeBasedOnDateFormat(endDateTime, dateFormat);
  var formattedEndDate = getTimeBasedOnDateFormat(endDate, dateFormat);

  if (startMonth !== endMonth) {
    return "".concat(startDay, " ").concat(startDate.format('MMMM'), " ").concat(formattedStartDateTime, " - ").concat(endDay, " ").concat(formattedEndDate);
  } else if (startDay !== endDay) {
    return "".concat(startDay, ". ").concat(formattedStartDateTime, " - ").concat(endDay, ". ").concat(formattedEndDateTime);
  } else {
    return "".concat(formattedStartDateTime, " - ").concat(formattedEndDateTime);
  }
};

exports.printMinuteDifference = printMinuteDifference;

var isComingSoon = function isComingSoon(dateTime, delay) {
  var date = (0, _momentTimezone.default)(dateTime);
  var comingSoonDate = (0, _momentTimezone.default)(dateTime).subtract(delay, 'minutes');
  var now = (0, _momentTimezone.default)();
  return comingSoonDate <= now && date >= now;
};

exports.isComingSoon = isComingSoon;

var isStarted = function isStarted(dateTime) {
  return (0, _momentTimezone.default)(dateTime) <= (0, _momentTimezone.default)();
};

exports.isStarted = isStarted;

var isNowInDateTimeRange = function isNowInDateTimeRange(startDateTime, endDateTime) {
  var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var start = (0, _momentTimezone.default)(startDateTime).seconds(0).milliseconds(0);
  var end = (0, _momentTimezone.default)(endDateTime).seconds(0).milliseconds(0);
  var now = (0, _momentTimezone.default)().add(delay, 'seconds').add(1, 'seconds').millisecond(1);
  return start <= now && end >= now;
};

exports.isNowInDateTimeRange = isNowInDateTimeRange;

var isToday = function isToday(someDate) {
  var today = new Date();
  return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
};

exports.isToday = isToday;

var getPosterStartPosition = function getPosterStartPosition(date) {
  var startDate = new Date(date);
  var diff = (new Date().getTime() - startDate.getTime()) / 1000 - 10;
  return Math.max(diff, 0);
};

exports.getPosterStartPosition = getPosterStartPosition;

var getElapsedTime = function getElapsedTime(_from, _to) {
  var from = (0, _momentTimezone.default)(_from);
  var to = _to ? (0, _momentTimezone.default)(_to) : (0, _momentTimezone.default)();
  var diff = Math.abs(to.diff(from));
  var seconds = diff / 1000;
  var minutes = seconds / 60;
  var hours = minutes / 60;
  var days = hours / 24;
  var weeks = days / 7;
  var months = weeks / 31;
  var years = months / 12;

  if (Math.trunc(years) !== 0) {
    return "".concat(years.toFixed(0), "y");
  }

  if (Math.trunc(months) !== 0) {
    return "".concat(months.toFixed(0), "m");
  }

  if (Math.trunc(weeks) !== 0) {
    return "".concat(weeks.toFixed(0), "w");
  }

  if (Math.trunc(days) !== 0) {
    return "".concat(days.toFixed(0), "d");
  }

  if (Math.trunc(hours) !== 0) {
    return "".concat(hours.toFixed(0), "h");
  }

  return "".concat(minutes.toFixed(0), "min");
};

exports.getElapsedTime = getElapsedTime;

function getElapsedSeconds(_from, _to) {
  var from = (0, _momentTimezone.default)(_from);
  var to = _to ? (0, _momentTimezone.default)(_to) : (0, _momentTimezone.default)();
  var diff = to.diff(from);
  var secondDivider = 1000;
  var minuteDivider = secondDivider * 60;
  var hourDivider = minuteDivider * 60;
  var dayDivider = hourDivider * 24;
  var days = Math.floor(diff / dayDivider);
  var hours = Math.floor(diff % dayDivider / hourDivider);
  var minutes = Math.floor(diff % hourDivider / minuteDivider);
  var seconds = Math.floor(diff % minuteDivider / secondDivider);

  function concat(fullTime, time, label) {
    if (time > 0) {
      if (fullTime) {
        fullTime += " ".concat(time).concat(label);
      } else {
        fullTime = "".concat(time).concat(label);
      }
    }

    return fullTime;
  }

  return concat(concat(concat(concat(null, days, 'd'), hours, 'h'), minutes, 'm'), seconds, 's');
}