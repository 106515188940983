var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _createReducer;

var InitialState = {
  loading: false,
  loaded: false,
  error: null,
  backstage: {
    participants: {},
    id: null
  }
};
var reducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.subscribeToBackstage.pending, function (state, _ref) {
  var arg = _ref.meta.arg;

  if (state.backstage) {
    state.backstage.id = arg.backstageId;
  }
}), (0, _defineProperty2.default)(_createReducer, _actions.updateBackstage.type, function (state, _ref2) {
  var payload = _ref2.payload;
  state.backstage = payload.backstage;
  state.error = payload.error;
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return InitialState;
}), _createReducer));
var _default = reducer;
exports.default = _default;