Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadHashTags = loadHashTags;
exports.loadHashtagsCompleted = loadHashtagsCompleted;

var _actionTypes = require("./actionTypes");

function loadHashTags() {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    return api.get({
      action: '/hashtags?priority[gt]=0&pagination=false'
    }).then(function (data) {
      dispatch(loadHashtagsCompleted(data.data));
    });
  };
}

function loadHashtagsCompleted(hashTags) {
  return {
    hashTags: hashTags,
    type: _actionTypes.LOAD_HASHTAGS_COMPLETED
  };
}