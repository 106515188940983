Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  djContainer: {
    marginVertical: 12,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  djTouchable: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    width: 0,
    flexGrow: 1,
    flex: 1,
    borderRadius: 8
  },
  winnerTouchable: {
    paddingBottom: 8
  },
  rank: {
    width: 40
  },
  avatarContainer: {
    marginRight: 16,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  avatar: {
    overflow: 'hidden'
  },
  winnerAvatar: {
    borderColor: 'white',
    borderWidth: 1
  },
  prize: {
    position: 'absolute',
    bottom: -8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8E71B',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 2
  },
  prizeText: {
    marginLeft: 2,
    color: 'black',
    lineHeight: 20,
    height: 20,
    fontWeight: 'bold',
    textAlignVertical: 'center'
  },
  djDetails: {
    marginRight: 16,
    width: 0,
    flexGrow: 1,
    flex: 1
  },
  djNameBold: {
    fontWeight: 'bold'
  },
  djBeats: {
    color: 'rgba(255,255,255,0.5)'
  },
  follow: {
    width: 76
  }
});

exports.default = _default;