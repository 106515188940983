var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNativePaper = require("react-native-paper");

var _fonts = _interopRequireDefault(require("./fonts"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var theme = _objectSpread(_objectSpread({}, _reactNativePaper.DefaultTheme), {}, {
  dark: true,
  roundness: 4,
  colors: _objectSpread(_objectSpread({}, _reactNativePaper.DefaultTheme.colors), {}, {
    primary: '#FF5151',
    white: '#ffffff',
    black: '#000000',
    accent: '#0E7AF9',
    background: '#000000',
    surface: '#1B1B1B',
    text: '#ffffff',
    snackbarBG: '#1B1B1B',
    facebookColor: '#3968AF'
  }),
  fonts: _fonts.default
});

var _default = theme;
exports.default = _default;