Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectCustomFieldsByFormId = exports.selectCustomRegistrationLoaded = exports.selectCustomRegistrationLoading = exports.selectCustomRegistration = exports.selectCustomRegistrationState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectCustomRegistrationState = function selectCustomRegistrationState(state) {
  return state[_constants.NAME];
};

exports.selectCustomRegistrationState = selectCustomRegistrationState;

var selectCustomRegistration = _reducer.customRegistrationEntityAdapter.getSelectors(selectCustomRegistrationState).selectAll;

exports.selectCustomRegistration = selectCustomRegistration;
var selectCustomRegistrationLoading = (0, _toolkit.createSelector)(selectCustomRegistrationState, function (state) {
  return state.loading;
});
exports.selectCustomRegistrationLoading = selectCustomRegistrationLoading;
var selectCustomRegistrationLoaded = (0, _toolkit.createSelector)(selectCustomRegistrationState, function (state) {
  return state.loaded;
});
exports.selectCustomRegistrationLoaded = selectCustomRegistrationLoaded;

var selectCustomFieldsByFormId = function selectCustomFieldsByFormId(formId) {
  return (0, _toolkit.createSelector)(selectCustomRegistration, function (fields) {
    return fields.filter(function (f) {
      return f.registrationFormId === formId;
    });
  });
};

exports.selectCustomFieldsByFormId = selectCustomFieldsByFormId;