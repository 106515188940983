var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var Redirect = function Redirect(_ref) {
  var _ref$to = _ref.to,
      to = _ref$to === void 0 ? 'Home' : _ref$to,
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params;

  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      navigate = _useNavigationExtende.navigate;

  _react.default.useEffect(function () {
    if (navigate) {
      navigate(to, params);
    }
  }, [navigate]);

  return _react.default.createElement(_reactNative.Text, null, "Loading...");
};

var _default = Redirect;
exports.default = _default;