var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AnimatedShape = exports.FloatingFires = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Clap = _interopRequireDefault(require("../../theme/components/icons/Clap"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var startCount = 1;

var FloatingFires = function (_React$PureComponent) {
  (0, _inherits2.default)(FloatingFires, _React$PureComponent);

  var _super = _createSuper(FloatingFires);

  function FloatingFires() {
    var _this;

    (0, _classCallCheck2.default)(this, FloatingFires);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      fires: [],
      remainingFires: _this.props.count,
      prevFires: _this.props.count,
      height: null,
      hasLoadedNumberFires: false
    };

    _this.createFire = function () {
      startCount += 1;

      _this.state.fires.push({
        id: startCount,
        right: 12
      });

      _this.setState(_this.state);
    };

    _this.removeFire = function (v) {
      var index = _this.state.fires.findIndex(function (fire) {
        return fire.id === v;
      });

      _this.state.fires.splice(index, 1);

      _this.setState(_this.state);
    };

    _this.handleOnLayout = function (e) {
      var height = e.nativeEvent.layout.height - 60;

      _this.setState({
        height: height
      });
    };

    _this.remainingFiresAnimation = undefined;

    _this.checkIfStateNeedsToBeUpdated = function () {
      var count = _this.props.count;
      var oldCount = _this.state.prevFires;
      var numFires = count - oldCount;

      if (!_this.state.hasLoadedNumberFires) {
        if (count !== 0) {
          _this.setState({
            prevFires: count,
            hasLoadedNumberFires: true
          });
        }
      }

      if (numFires <= 0 || _this.state.prevFires === 0) {
        return;
      }

      _this.setState({
        prevFires: count
      });

      _this.setState({
        remainingFires: numFires
      }, function () {
        _this.showRemainingFires();
      });
    };

    _this.showRemainingFires = function () {
      if (_this.state.remainingFires > 0) {
        _this.createFire();

        _this.setState({
          remainingFires: _this.state.remainingFires - 1
        });

        _this.remainingFiresAnimation = setTimeout(function () {
          _this.showRemainingFires();
        }, getRandomNumber(100, 500));
      }
    };

    return _this;
  }

  (0, _createClass2.default)(FloatingFires, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      clearTimeout(this.remainingFiresAnimation);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate() {
      this.checkIfStateNeedsToBeUpdated();
    }
  }, {
    key: "render",
    value: function render() {
      var height = this.state.height;
      var isReady = height !== null;
      return _react.default.createElement(_reactNative.View, {
        pointerEvents: "none",
        style: styles.container,
        onLayout: this.handleOnLayout
      }, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, isReady && this.state.fires.map(function (v, i) {
        return _react.default.createElement(AnimatedShape, {
          key: v.id,
          height: height,
          onComplete: this.removeFire.bind(this, v.id),
          style: {
            right: this.state.fires[i].right
          }
        }, _react.default.createElement(_Clap.default, {
          width: 40,
          height: 40
        }));
      }, this)));
    }
  }]);
  return FloatingFires;
}(_react.default.PureComponent);

exports.FloatingFires = FloatingFires;

var AnimatedShape = function (_React$PureComponent2) {
  (0, _inherits2.default)(AnimatedShape, _React$PureComponent2);

  var _super2 = _createSuper(AnimatedShape);

  function AnimatedShape() {
    var _this2;

    (0, _classCallCheck2.default)(this, AnimatedShape);

    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    _this2 = _super2.call.apply(_super2, [this].concat(args));
    _this2.state = {
      position: new _reactNative.Animated.Value(0)
    };
    _this2._yAnimation = new _reactNative.Animated.Value(0);
    _this2._opacityAnimation = undefined;
    _this2._scaleAnimation = undefined;
    _this2._xAnimation = undefined;
    _this2._rotateAnimation = undefined;
    return _this2;
  }

  (0, _createClass2.default)(AnimatedShape, [{
    key: "componentWillMount",
    value: function componentWillMount() {
      var height = Math.max(0, this.props.height);
      this._yAnimation = this.state.position.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 0]
      });
      this._opacityAnimation = this._yAnimation.interpolate({
        inputRange: [0, height],
        outputRange: [1, 0]
      });
      this._scaleAnimation = this._yAnimation.interpolate({
        inputRange: [0, 15, 30],
        outputRange: [0, 1.9, 1],
        extrapolate: 'clamp'
      });
      this._xAnimation = this._yAnimation.interpolate({
        inputRange: [0, height / 2, height],
        outputRange: [0, getRandomNumber(-70, 10), -50]
      });
      this._rotateAnimation = this._yAnimation.interpolate({
        inputRange: [0, height / 4, height / 3, height / 2, height],
        outputRange: ['0deg', '-10deg', '0deg', '10deg', '0deg']
      });
    }
  }, {
    key: "getFireAnimationStyle",
    value: function getFireAnimationStyle() {
      return {
        transform: [{
          translateY: this.state.position
        }, {
          translateX: this._xAnimation
        }, {
          scale: this._scaleAnimation
        }, {
          rotate: this._rotateAnimation
        }],
        opacity: this._opacityAnimation
      };
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _reactNative.Animated.timing(this.state.position, {
        duration: 2000,
        useNativeDriver: true,
        toValue: this.props.height * -1
      }).start(this.props.onComplete);
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNative.Animated.View, {
        style: [styles.shapeWrap, this.getFireAnimationStyle(), this.props.style]
      }, this.props.children);
    }
  }]);
  return AnimatedShape;
}(_react.default.PureComponent);

exports.AnimatedShape = AnimatedShape;

var styles = _reactNative.StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 40,
    position: 'absolute'
  },
  shapeWrap: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent'
  }
});

var getRandomNumber = function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
};

var _default = FloatingFires;
exports.default = _default;