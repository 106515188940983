Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    position: 'absolute',
    left: 16,
    top: 16,
    width: 80,
    height: 24,
    backgroundColor: '#FF0000',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingVertical: 4
  },
  icon: {
    marginRight: 4
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    color: '#ffffff',
    marginVertical: 0,
    paddingBottom: 0
  }
});

exports.default = _default;