Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  family: {
    regular: 'GothamNarrow-Book',
    bold: 'GothamNarrow-Bold',
    medium: 'GothamNarrow-Medium',
    light: 'GothamNarrow-Light',
    thin: 'GothamNarrow-Thin'
  }
};
exports.default = _default;