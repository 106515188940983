var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventItemInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _reactNative = require("react-native");

var _events = _interopRequireDefault(require("../../core/events"));

var _reactNativePaper = require("react-native-paper");

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _Caption = _interopRequireDefault(require("../common/typography_v1/Caption"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _VipBadge = _interopRequireDefault(require("../common/vip/VipBadge"));

var _SmartDate = _interopRequireDefault(require("../common/SmartDate"));

var _constants = require("../../core/events/constants");

var _CustomCachedImage = _interopRequireDefault(require("../common/CustomCachedImage"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var EventItemInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventItemInternal, _React$PureComponent);

  var _super = _createSuper(EventItemInternal);

  function EventItemInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EventItemInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: _reactNative.Dimensions.get('window').width
    };
    return _this;
  }

  (0, _createClass2.default)(EventItemInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var date = this.props.startDate;
      var smallDeviceWidth = this.state.screenWidth < 375 ? true : false;
      var itemWidth = this.props.countOneEvent ? this.state.screenWidth - 32 : smallDeviceWidth ? 250 : 300;
      var colors = this.props.theme.colors;
      return React.createElement(_reactNative.View, {
        key: this.props.eventId,
        style: [styles.itemContainer, {
          width: itemWidth
        }]
      }, React.createElement(_reactNative.TouchableHighlight, {
        testID: 'TapImageEvent' + this.props.eventId,
        onPress: function onPress() {
          return _this2.props.navigation.push('EventDetail', {
            eventId: _this2.props.eventId
          });
        },
        style: styles.itemImageContainer
      }, React.createElement(_CustomCachedImage.default, {
        style: styles.itemImage,
        source: {
          uri: this.props.coverImage
        },
        resizeMode: "cover"
      })), this.props.showVipBadge && React.createElement(_VipBadge.default, {
        eventId: this.props.eventId,
        style: {
          right: 0,
          top: 0
        }
      }), this.props.numFollowers > 0 && React.createElement(_reactNative.View, {
        style: styles.tagViewsContainer
      }, React.createElement(_CustomIcon.default, {
        name: "users",
        size: 12,
        color: "#ffffff",
        style: {
          marginRight: 4
        }
      }), React.createElement(_Caption.default, {
        style: styles.tagViewsText
      }, this.props.numFollowers)), React.createElement(_reactNative.View, {
        style: styles.eventDetails
      }, React.createElement(_reactNative.View, {
        style: styles.itemTitleSection
      }, React.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'TapDj' + this.props.djId,
        onPress: function onPress() {
          return _this2.props.navigation.navigate('Profile', {
            userId: _this2.props.djId.toString(),
            djName: _this2.props.djName,
            avatar: _this2.props.djPicture
          });
        }
      }, React.createElement(_Paragraph.default, {
        numberOfLines: 1,
        ellipsizeMode: "tail",
        style: styles.itemTitle
      }, this.props.djName)), this.props.status === _constants.LIVE_STATUS.ENDED ? React.createElement(_reactNative.Text, {
        testID: "EndedText",
        style: {
          fontSize: 13,
          color: 'rgba(255, 255, 255, 0.6)'
        }
      }, "Ended") : React.createElement(_SmartDate.default, {
        date: date,
        displayDirection: "row",
        fontSize: 13,
        color: "rgba(255, 255, 255, 0.6)"
      })), React.createElement(_reactNative.View, null, React.createElement(_FollowUserButton.default, {
        buttonSize: "small",
        isFixedButton: true,
        style: {
          width: 76
        },
        color: colors.white,
        id: this.props.djId,
        eventId: this.props.eventId
      }))));
    }
  }]);
  return EventItemInternal;
}(React.PureComponent);

exports.EventItemInternal = EventItemInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  itemContainer: {
    marginRight: 16
  },
  itemImageContainer: {},
  itemImage: {
    aspectRatio: 16 / 9,
    width: '100%',
    borderRadius: 4,
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.5 : 0
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  },
  eventDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12
  },
  itemTitleSection: {
    maxWidth: 200,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  itemTitle: {
    lineHeight: 15,
    fontSize: 15,
    marginBottom: 1
  },
  itemPeriod: {
    color: 'rgba(255,255,255,0.6)',
    lineHeight: 13,
    fontSize: 13
  },
  numUsersLive: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0, 0.4)',
    paddingHorizontal: 8,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2
  },
  numUsersLiveText: {
    color: '#fff',
    marginLeft: 5,
    lineHeight: 11,
    height: 11,
    marginVertical: 0,
    paddingBottom: 0
  },
  tagViewsContainer: {
    position: 'absolute',
    left: 8,
    top: 8,
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 5,
    paddingVertical: 4
  },
  tagViewsText: {
    height: 11,
    lineHeight: 11,
    color: '#ffffff',
    marginVertical: 0,
    paddingBottom: 0
  }
});

function mapStateToProps(state, ownProps) {
  var eventId = ownProps.eventId;
  return {
    showVipBadge: _events.default.selectors.hasAccessTicket(state, eventId),
    coverImage: _events.default.selectors.getCoverImage(state, eventId),
    numFollowers: _events.default.selectors.getNumFollowers(state, eventId),
    startDate: _events.default.selectors.getStartDate(state, eventId),
    status: _events.default.selectors.getStatus(state, eventId),
    djId: _events.default.selectors.getDjId(state, eventId),
    djName: _events.default.selectors.getDjName(state, eventId),
    djPicture: _events.default.selectors.getDjPicture(state, eventId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(EventItemInternal)));

exports.default = _default;