Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skipLoginFlag = exports.eventDetailFlag = exports.festivalFlag = exports.disablePaymentRegionFestivalFlag = exports.ufVariation = void 0;

var _constants = require("./constants");

var ufVariation = function ufVariation(state) {
  return state[_constants.NAME].getIn(['allFlags', 'user-feature'], false);
};

exports.ufVariation = ufVariation;

var disablePaymentRegionFestivalFlag = function disablePaymentRegionFestivalFlag(state) {
  return state[_constants.NAME].getIn(['allFlags', 'disablePaymentRegionFestival'], true);
};

exports.disablePaymentRegionFestivalFlag = disablePaymentRegionFestivalFlag;

var festivalFlag = function festivalFlag(state) {
  return state[_constants.NAME].getIn(['allFlags', 'festival'], false);
};

exports.festivalFlag = festivalFlag;

var eventDetailFlag = function eventDetailFlag(state) {
  return state[_constants.NAME].getIn(['allFlags', 'eventDetail'], false);
};

exports.eventDetailFlag = eventDetailFlag;

var skipLoginFlag = function skipLoginFlag(state) {
  return state[_constants.NAME].getIn(['allFlags', 'showSkipLogin'], false);
};

exports.skipLoginFlag = skipLoginFlag;