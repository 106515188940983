var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.partnerEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var partnerEntityAdapter = (0, _toolkit.createEntityAdapter)({
  sortComparer: function sortComparer(a, b) {
    return a.sort !== undefined && b.sort !== undefined ? a.sort - b.sort : new Date(a.createdDate) > new Date(b.createdDate);
  }
});
exports.partnerEntityAdapter = partnerEntityAdapter;
var InitialState = partnerEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
var partnerReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getPartners.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPartners.rejected, function (state, _ref) {
  var error = _ref.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPartners.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;
  partnerEntityAdapter.addMany(state, payload);
}), _createReducer));
var _default = partnerReducer;
exports.default = _default;