var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createYupSchema = createYupSchema;
exports.VALIDATIONS = exports.VALIDATION_TYPES = exports.INPUT_TYPES = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function createYupSchema(schema, config) {
  var id = config.id,
      validationType = config.validationType,
      _config$validations = config.validations,
      validations = _config$validations === void 0 ? [] : _config$validations;

  if (!yup[validationType]) {
    return schema;
  }

  var validator = yup[validationType]();
  validations.forEach(function (validation) {
    var _validator;

    var params = validation.params,
        type = validation.type;

    if (!validator[type]) {
      return;
    }

    validator = (_validator = validator)[type].apply(_validator, (0, _toConsumableArray2.default)(params));
  });
  schema[id] = validator;
  return schema;
}

var INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  FILE: 'file',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  MULTISELECT: 'multiselect'
};
exports.INPUT_TYPES = INPUT_TYPES;
var VALIDATION_TYPES = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  OBJECT: 'object',
  ARRAY: 'array'
};
exports.VALIDATION_TYPES = VALIDATION_TYPES;
var VALIDATION = {
  REQUIRED: 'required',
  TRIM: 'trim',
  EMAIL: 'email',
  MIN: 'min',
  ONE_OF: 'oneOf',
  NULLABLE: 'nullable',
  TEST: 'test'
};
var VALIDATIONS = {
  required: function required(params) {
    return {
      type: VALIDATION.REQUIRED,
      params: params
    };
  },
  trim: {
    type: VALIDATION.TRIM,
    params: []
  },
  email: function email(params) {
    return {
      type: VALIDATION.EMAIL,
      params: params
    };
  },
  min: function min(params) {
    return {
      type: VALIDATION.MIN,
      params: params
    };
  },
  oneOf: function oneOf(params) {
    return {
      type: VALIDATION.ONE_OF,
      params: params
    };
  },
  nullable: {
    type: VALIDATION.NULLABLE,
    params: []
  },
  test: function test(params) {
    return {
      type: VALIDATION.TEST,
      params: params
    };
  }
};
exports.VALIDATIONS = VALIDATIONS;