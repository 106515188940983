var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _scrollListener = _interopRequireDefault(require("../../components/common/header/scroll-listener"));

function useScrollListener() {
  var _useState = (0, _react.useState)({
    sectionMap: {}
  }),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      sections = _useState2[0],
      setSections = _useState2[1];

  (0, _react.useEffect)(function () {
    var sub = _scrollListener.default.sections$.subscribe(function (sectionConfig) {
      setSections(sectionConfig);
    });

    return function () {
      return sub.unsubscribe();
    };
  }, []);
  return sections;
}

var _default = useScrollListener;
exports.default = _default;