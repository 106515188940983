var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetPasswordView;

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var yup = _interopRequireWildcard(require("yup"));

var _eventLogo = _interopRequireDefault(require("../../common/event-logo"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Input = _interopRequireDefault(require("../../../theme/components/inputs/Input"));

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _LinkButton = _interopRequireDefault(require("../../../theme/components/buttons/LinkButton"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _toaster = _interopRequireDefault(require("../../common/toaster/toaster"));

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../../common/custom-design-components/MuiLinkButton"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ResetPasswordView(_ref) {
  var onResetPassword = _ref.onResetPassword,
      goToRegister = _ref.goToRegister,
      goToLogin = _ref.goToLogin,
      isLoading = _ref.isLoading,
      _ref$status = _ref.status,
      status = _ref$status === void 0 ? {
    success: false,
    failed: false
  } : _ref$status,
      onCloseToaster = _ref.onCloseToaster;
  var Styles = (0, _authStyles.default)();
  var classes = (0, _styles.default)();
  var success = status.success,
      failed = status.failed;
  var visible = success || failed;
  var severity = success && 'success' || failed && 'error' || 'error';
  var title = success ? _i18n.default.t('resetPassword.mailSent') : failed ? _i18n.default.t('resetPassword.noAccount') : '';
  return _react.default.createElement(_Spacing.default, {
    alignItems: "stretch",
    style: Styles.container,
    mSpacing: [0, 0, 'xxl', 0],
    direction: "column"
  }, _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_eventLogo.default, null)), _react.default.createElement(_Section.default, {
    shaped: true,
    shaping: "forms",
    color: "containerBackground"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'xxl'],
    style: Styles.authBox,
    direction: "column",
    alignItems: "stretch",
    justifyContent: "center"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0],
    direction: "column",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.fontWeightNormal
  }, _i18n.default.translate('resetPassword.resetPassword'))), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0]
  }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('resetPassword.enterEmail'))), _react.default.createElement(_formik.Formik, {
    initialValues: {
      email: ''
    },
    onSubmit: function onSubmit(_ref2) {
      var email = _ref2.email;
      onResetPassword(email);
    },
    validationSchema: yup.object().shape({
      email: yup.string().trim().email(_i18n.default.t('login.emailValid')).required(_i18n.default.t('login.emailRequired'))
    })
  }, function (_ref3) {
    var values = _ref3.values,
        handleChange = _ref3.handleChange,
        errors = _ref3.errors,
        setFieldTouched = _ref3.setFieldTouched,
        touched = _ref3.touched,
        isValid = _ref3.isValid,
        submitForm = _ref3.submitForm;
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'l', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      type: "email",
      value: values.email,
      onChange: handleChange,
      onKeyPress: function onKeyPress(_ref4) {
        var key = _ref4.nativeEvent.key;
        return key === 'Enter' && submitForm();
      },
      name: "email",
      returnKeyType: 'next',
      error: !!errors.email && touched.email,
      onBlur: function onBlur() {
        return setFieldTouched('email');
      },
      placeholder: _i18n.default.translate('login.email'),
      leftIcon: _react.default.createElement(_reactFeather.Mail, null)
    }), !!errors.email && touched.email && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.email))), _react.default.createElement(_ContainedButton.default, {
      disabled: isLoading,
      onPress: function onPress() {
        if (isValid) {
          submitForm();
        }
      }
    }, isLoading ? _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: "#fff"
    }) : _i18n.default.t('resetPassword.resetPassword')));
  }))), _react.default.createElement(_toaster.default, {
    visible: visible,
    severity: severity,
    onClose: function onClose() {
      onCloseToaster();
    },
    title: title
  }), _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 0, 'l', 0],
    direction: "column",
    alignItems: "center",
    wrap: "wrap",
    justifyContent: "space-between"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0]
  }, _react.default.createElement(_MuiLinkButton.default, {
    translationKey: "login.login",
    variant: "caption",
    onClick: goToLogin
  })), _react.default.createElement(_Spacing.default, {
    mSpacing: ['s', 0]
  }, _react.default.createElement(_MuiLinkButton.default, {
    translationKey: "login.createNewAccount",
    variant: "caption",
    onClick: goToRegister
  }))));
}