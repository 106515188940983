Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeviceIdFromTrack = getDeviceIdFromTrack;
exports.isEnabledTrack = isEnabledTrack;

var _agoraConstants = require("../constants/agora-constants");

function getDeviceIdFromTrack(devices, track, trackType) {
  if (!devices) return null;
  var device = undefined;
  var savedDeviceId = trackType === 'video' ? localStorage.getItem(_agoraConstants.DEFAULT_VIDEO_DEVICE) : localStorage.getItem(_agoraConstants.DEFAULT_AUDIO_DEVICE);
  device = savedDeviceId ? devices.find(function (dvc) {
    return dvc.deviceId === savedDeviceId;
  }) : devices.find(function (dvc) {
    return track && dvc.label === (track === null || track === void 0 ? void 0 : track._deviceName);
  });
  if (!device) return null;
  return device.deviceId;
}

function isEnabledTrack(track) {
  if (!track) return false;
  return track.getMediaStreamTrack().enabled;
}