Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  leaderboardHeader: {
    padding: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16
  },
  headerHeadline: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 16
  }
});

exports.default = _default;