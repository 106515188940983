var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useClientAutoConnect;

var _react = _interopRequireDefault(require("react"));

var _logger = _interopRequireDefault(require("../../core/services/logger"));

function useClientAutoConnect(client, uid, accessToken, room) {
  _react.default.useEffect(function () {
    if (uid && room && accessToken) {
      try {
        client.startProxyServer(3);
      } catch (error) {
        _logger.default.error("Error on proxy server start: ".concat(error.code || error.message));
      }

      client.join(process.env.AGORA_APP_ID, room, accessToken, Number(uid));

      _logger.default.debug("Join backstage live: ".concat(room));

      return function () {
        client.leave().finally(function () {
          client.stopProxyServer();

          _logger.default.debug("Leave backstage live: ".concat(room, "!"));
        });
      };
    }
  }, [client, accessToken, room, uid]);
}