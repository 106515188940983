Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dialogsSelector = exports.isGlobalLoadingSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var globalStateSelector = function globalStateSelector(state) {
  return state[_constants.NAME];
};

var dialogsStateSelector = (0, _toolkit.createSelector)(globalStateSelector, function (state) {
  return state.dialogs;
});

var _dialogEntityAdapter$ = _constants.dialogEntityAdapter.getSelectors(),
    selectAll = _dialogEntityAdapter$.selectAll;

var isGlobalLoadingSelector = (0, _toolkit.createSelector)(globalStateSelector, function (state) {
  return state.loading;
});
exports.isGlobalLoadingSelector = isGlobalLoadingSelector;
var dialogsSelector = (0, _toolkit.createSelector)(dialogsStateSelector, selectAll);
exports.dialogsSelector = dialogsSelector;