"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _utils = require("../utils");

var propTypes = {
  actions: _propTypes.default.object,
  player: _propTypes.default.object,
  children: _propTypes.default.any,
  startTime: _propTypes.default.number,
  loop: _propTypes.default.bool,
  muted: _propTypes.default.bool,
  autoPlay: _propTypes.default.bool,
  playsInline: _propTypes.default.bool,
  src: _propTypes.default.string,
  poster: _propTypes.default.string,
  className: _propTypes.default.string,
  preload: _propTypes.default.oneOf(["auto", "metadata", "none"]),
  crossOrigin: _propTypes.default.string,
  onLoadStart: _propTypes.default.func,
  onWaiting: _propTypes.default.func,
  onCanPlay: _propTypes.default.func,
  onCanPlayThrough: _propTypes.default.func,
  onPlaying: _propTypes.default.func,
  onEnded: _propTypes.default.func,
  onSeeking: _propTypes.default.func,
  onSeeked: _propTypes.default.func,
  onPlay: _propTypes.default.func,
  onPause: _propTypes.default.func,
  onProgress: _propTypes.default.func,
  onDurationChange: _propTypes.default.func,
  onError: _propTypes.default.func,
  onSuspend: _propTypes.default.func,
  onAbort: _propTypes.default.func,
  onEmptied: _propTypes.default.func,
  onStalled: _propTypes.default.func,
  onLoadedMetadata: _propTypes.default.func,
  onLoadedData: _propTypes.default.func,
  onTimeUpdate: _propTypes.default.func,
  onRateChange: _propTypes.default.func,
  onVolumeChange: _propTypes.default.func,
  onResize: _propTypes.default.func
};

var Video = function (_Component) {
  (0, _inherits2.default)(Video, _Component);

  function Video(props) {
    var _this;

    (0, _classCallCheck2.default)(this, Video);
    _this = (0, _possibleConstructorReturn2.default)(this, (0, _getPrototypeOf2.default)(Video).call(this, props));
    _this.video = null;
    _this.play = _this.play.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.pause = _this.pause.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.seek = _this.seek.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.forward = _this.forward.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.replay = _this.replay.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.toggleFullscreen = _this.toggleFullscreen.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.getProperties = _this.getProperties.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.renderChildren = _this.renderChildren.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleLoadStart = _this.handleLoadStart.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleCanPlay = _this.handleCanPlay.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleCanPlayThrough = _this.handleCanPlayThrough.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handlePlay = _this.handlePlay.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handlePlaying = _this.handlePlaying.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handlePause = _this.handlePause.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleEnded = _this.handleEnded.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleWaiting = _this.handleWaiting.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleSeeking = _this.handleSeeking.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleSeeked = _this.handleSeeked.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleFullscreenChange = _this.handleFullscreenChange.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleError = _this.handleError.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleSuspend = _this.handleSuspend.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleAbort = _this.handleAbort.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleEmptied = _this.handleEmptied.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleStalled = _this.handleStalled.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleLoadedMetaData = _this.handleLoadedMetaData.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleLoadedData = _this.handleLoadedData.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleTimeUpdate = _this.handleTimeUpdate.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleRateChange = _this.handleRateChange.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleVolumeChange = _this.handleVolumeChange.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleDurationChange = _this.handleDurationChange.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    _this.handleProgress = (0, _utils.throttle)(_this.handleProgress.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this))), 250);
    _this.handleKeypress = _this.handleKeypress.bind((0, _assertThisInitialized2.default)((0, _assertThisInitialized2.default)(_this)));
    return _this;
  }

  (0, _createClass2.default)(Video, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.forceUpdate();
    }
  }, {
    key: "getProperties",
    value: function getProperties() {
      var _this2 = this;

      if (!this.video) {
        return null;
      }

      return _utils.mediaProperties.reduce(function (properties, key) {
        properties[key] = _this2.video[key];
        return properties;
      }, {});
    }
  }, {
    key: "play",
    value: function play() {
      var promise = this.video.play();

      if (promise !== undefined) {
        promise.catch(function () {}).then(function () {});
      }
    }
  }, {
    key: "pause",
    value: function pause() {
      var promise = this.video.pause();

      if (promise !== undefined) {
        promise.catch(function () {}).then(function () {});
      }
    }
  }, {
    key: "load",
    value: function load() {
      this.video.load();
    }
  }, {
    key: "addTextTrack",
    value: function addTextTrack() {
      var _this$video;

      (_this$video = this.video).addTextTrack.apply(_this$video, arguments);
    }
  }, {
    key: "canPlayType",
    value: function canPlayType() {
      var _this$video2;

      (_this$video2 = this.video).canPlayType.apply(_this$video2, arguments);
    }
  }, {
    key: "togglePlay",
    value: function togglePlay() {
      if (this.video.paused) {
        this.play();
      } else {
        this.pause();
      }
    }
  }, {
    key: "seek",
    value: function seek(time) {
      try {
        this.video.currentTime = time;
      } catch (e) {}
    }
  }, {
    key: "forward",
    value: function forward(seconds) {
      this.seek(this.video.currentTime + seconds);
    }
  }, {
    key: "replay",
    value: function replay(seconds) {
      this.forward(-seconds);
    }
  }, {
    key: "toggleFullscreen",
    value: function toggleFullscreen() {
      var _this$props = this.props,
          player = _this$props.player,
          actions = _this$props.actions;
    }
  }, {
    key: "handleLoadStart",
    value: function handleLoadStart() {
      var _this$props2 = this.props,
          actions = _this$props2.actions,
          onLoadStart = _this$props2.onLoadStart;

      if (onLoadStart) {
        onLoadStart.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleCanPlay",
    value: function handleCanPlay() {
      var _this$props3 = this.props,
          actions = _this$props3.actions,
          onCanPlay = _this$props3.onCanPlay;

      if (onCanPlay) {
        onCanPlay.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleCanPlayThrough",
    value: function handleCanPlayThrough() {
      var _this$props4 = this.props,
          actions = _this$props4.actions,
          onCanPlayThrough = _this$props4.onCanPlayThrough;

      if (onCanPlayThrough) {
        onCanPlayThrough.apply(void 0, arguments);
      }
    }
  }, {
    key: "handlePlaying",
    value: function handlePlaying() {
      var _this$props5 = this.props,
          actions = _this$props5.actions,
          onPlaying = _this$props5.onPlaying;

      if (onPlaying) {
        onPlaying.apply(void 0, arguments);
      }
    }
  }, {
    key: "handlePlay",
    value: function handlePlay() {
      var _this$props6 = this.props,
          actions = _this$props6.actions,
          onPlay = _this$props6.onPlay;

      if (onPlay) {
        onPlay.apply(void 0, arguments);
      }
    }
  }, {
    key: "handlePause",
    value: function handlePause() {
      var _this$props7 = this.props,
          actions = _this$props7.actions,
          onPause = _this$props7.onPause;

      if (onPause) {
        onPause.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleDurationChange",
    value: function handleDurationChange() {
      var _this$props8 = this.props,
          actions = _this$props8.actions,
          onDurationChange = _this$props8.onDurationChange;

      if (onDurationChange) {
        onDurationChange.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleProgress",
    value: function handleProgress() {
      var _this$props9 = this.props,
          actions = _this$props9.actions,
          onProgress = _this$props9.onProgress;

      if (this.video) {}

      if (onProgress) {
        onProgress.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleEnded",
    value: function handleEnded() {
      var _this$props10 = this.props,
          loop = _this$props10.loop,
          player = _this$props10.player,
          actions = _this$props10.actions,
          onEnded = _this$props10.onEnded;

      if (loop) {
        this.seek(0);
        this.play();
      } else if (!player.paused) {
        this.pause();
      }

      if (onEnded) {
        onEnded.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleWaiting",
    value: function handleWaiting() {
      var _this$props11 = this.props,
          actions = _this$props11.actions,
          onWaiting = _this$props11.onWaiting;

      if (onWaiting) {
        onWaiting.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleSeeking",
    value: function handleSeeking() {
      var _this$props12 = this.props,
          actions = _this$props12.actions,
          onSeeking = _this$props12.onSeeking;

      if (onSeeking) {
        onSeeking.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleSeeked",
    value: function handleSeeked() {
      var _this$props13 = this.props,
          actions = _this$props13.actions,
          onSeeked = _this$props13.onSeeked;

      if (onSeeked) {
        onSeeked.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleFullscreenChange",
    value: function handleFullscreenChange() {}
  }, {
    key: "handleSuspend",
    value: function handleSuspend() {
      var _this$props14 = this.props,
          actions = _this$props14.actions,
          onSuspend = _this$props14.onSuspend;

      if (onSuspend) {
        onSuspend.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleAbort",
    value: function handleAbort() {
      var _this$props15 = this.props,
          actions = _this$props15.actions,
          onAbort = _this$props15.onAbort;

      if (onAbort) {
        onAbort.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleEmptied",
    value: function handleEmptied() {
      var _this$props16 = this.props,
          actions = _this$props16.actions,
          onEmptied = _this$props16.onEmptied;

      if (onEmptied) {
        onEmptied.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleStalled",
    value: function handleStalled() {
      var _this$props17 = this.props,
          actions = _this$props17.actions,
          onStalled = _this$props17.onStalled;

      if (onStalled) {
        onStalled.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleLoadedMetaData",
    value: function handleLoadedMetaData() {
      var _this$props18 = this.props,
          actions = _this$props18.actions,
          onLoadedMetadata = _this$props18.onLoadedMetadata,
          startTime = _this$props18.startTime;

      if (startTime && startTime > 0) {
        this.video.currentTime = startTime;
      }

      if (onLoadedMetadata) {
        onLoadedMetadata.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleLoadedData",
    value: function handleLoadedData() {
      var _this$props19 = this.props,
          actions = _this$props19.actions,
          onLoadedData = _this$props19.onLoadedData;

      if (onLoadedData) {
        onLoadedData.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleTimeUpdate",
    value: function handleTimeUpdate() {
      var _this$props20 = this.props,
          actions = _this$props20.actions,
          onTimeUpdate = _this$props20.onTimeUpdate;

      if (onTimeUpdate) {
        onTimeUpdate.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleRateChange",
    value: function handleRateChange() {
      var _this$props21 = this.props,
          actions = _this$props21.actions,
          onRateChange = _this$props21.onRateChange;

      if (onRateChange) {
        onRateChange.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleVolumeChange",
    value: function handleVolumeChange() {
      var _this$props22 = this.props,
          actions = _this$props22.actions,
          onVolumeChange = _this$props22.onVolumeChange;

      if (onVolumeChange) {
        onVolumeChange.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleError",
    value: function handleError() {
      var _this$props23 = this.props,
          actions = _this$props23.actions,
          onError = _this$props23.onError;

      if (onError) {
        onError.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleResize",
    value: function handleResize() {
      var _this$props24 = this.props,
          actions = _this$props24.actions,
          onResize = _this$props24.onResize;

      if (onResize) {
        onResize.apply(void 0, arguments);
      }
    }
  }, {
    key: "handleKeypress",
    value: function handleKeypress() {}
  }, {
    key: "renderChildren",
    value: function renderChildren() {
      var _this3 = this;

      var props = (0, _objectSpread2.default)({}, this.props, {
        video: this.video
      });

      if (!this.video) {
        return null;
      }

      return _react.default.Children.toArray(this.props.children).filter(_utils.isVideoChild).map(function (c) {
        var cprops;

        if (typeof c.type === "string") {
          if (c.type === "source") {
            cprops = (0, _objectSpread2.default)({}, c.props);
            var preOnError = cprops.onError;

            cprops.onError = function () {
              if (preOnError) {
                preOnError.apply(void 0, arguments);
              }

              _this3.handleError.apply(_this3, arguments);
            };
          }
        } else {
          cprops = props;
        }

        return _react.default.cloneElement(c, cprops);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;

      var _this$props25 = this.props,
          loop = _this$props25.loop,
          poster = _this$props25.poster,
          preload = _this$props25.preload,
          src = _this$props25.src,
          autoPlay = _this$props25.autoPlay,
          playsInline = _this$props25.playsInline,
          muted = _this$props25.muted,
          crossOrigin = _this$props25.crossOrigin,
          videoId = _this$props25.videoId;
      return _react.default.createElement("video", {
        className: (0, _classnames.default)("video-react-video", this.props.className),
        id: videoId,
        crossOrigin: crossOrigin,
        ref: function ref(c) {
          _this4.video = c;
        },
        muted: muted,
        preload: preload,
        loop: loop,
        playsInline: playsInline,
        autoPlay: autoPlay,
        poster: poster,
        src: src,
        onLoadStart: this.handleLoadStart,
        onWaiting: this.handleWaiting,
        onCanPlay: this.handleCanPlay,
        onCanPlayThrough: this.handleCanPlayThrough,
        onPlaying: this.handlePlaying,
        onEnded: this.handleEnded,
        onSeeking: this.handleSeeking,
        onSeeked: this.handleSeeked,
        onPlay: this.handlePlay,
        onPause: this.handlePause,
        onProgress: this.handleProgress,
        onDurationChange: this.handleDurationChange,
        onError: this.handleError,
        onSuspend: this.handleSuspend,
        onAbort: this.handleAbort,
        onEmptied: this.handleEmptied,
        onStalled: this.handleStalled,
        onLoadedMetadata: this.handleLoadedMetaData,
        onLoadedData: this.handleLoadedData,
        onTimeUpdate: this.handleTimeUpdate,
        onRateChange: this.handleRateChange,
        onVolumeChange: this.handleVolumeChange,
        tabIndex: "-1"
      }, this.renderChildren());
    }
  }, {
    key: "playbackRate",
    get: function get() {
      return this.video.playbackRate;
    },
    set: function set(rate) {
      this.video.playbackRate = rate;
    }
  }, {
    key: "muted",
    get: function get() {
      return this.video.muted;
    },
    set: function set(val) {
      this.video.muted = val;
    }
  }, {
    key: "volume",
    get: function get() {
      return this.video.volume;
    },
    set: function set(val) {
      if (val > 1) {
        val = 1;
      }

      if (val < 0) {
        val = 0;
      }

      this.video.volume = val;
    }
  }, {
    key: "videoWidth",
    get: function get() {
      return this.video.videoWidth;
    }
  }, {
    key: "videoHeight",
    get: function get() {
      return this.video.videoHeight;
    }
  }]);
  return Video;
}(_react.Component);

exports.default = Video;
Video.propTypes = propTypes;
Video.displayName = "Video";