"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ForwardReplayControl = _interopRequireDefault(require("./ForwardReplayControl"));

var ForwardControl = (0, _ForwardReplayControl.default)("forward");
ForwardControl.displayName = "ForwardControl";
var _default = ForwardControl;
exports.default = _default;