Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var fonts = _reactNative.Platform.select({
  android: {
    regular: 'GothamNarrow-Book',
    bold: 'GothamNarrow-Bold',
    medium: 'GothamNarrow-Medium',
    light: 'GothamNarrow-Light',
    thin: 'GothamNarrow-Thin'
  },
  ios: {
    regular: 'GothamNarrow-Book',
    bold: 'GothamNarrow-Bold',
    medium: 'GothamNarrow-Medium',
    light: 'GothamNarrow-Light',
    thin: 'GothamNarrow-Thin'
  },
  default: {
    regular: 'GothamNarrow-Book',
    bold: 'GothamNarrow-Bold',
    medium: 'GothamNarrow-Medium',
    light: 'GothamNarrow-Light',
    thin: 'GothamNarrow-Thin'
  }
});

var _default = fonts;
exports.default = _default;