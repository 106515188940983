var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _headingView = _interopRequireDefault(require("../../../../design-system/typography/heading-view"));

var _textView = _interopRequireDefault(require("../../../../design-system/typography/text-view"));

var _button = _interopRequireDefault(require("../../../../design-system/actions/button"));

var _iconButton = _interopRequireDefault(require("../../../../design-system/actions/icon-button"));

var _Stack = _interopRequireDefault(require("../../../../design-system/structure/Stack"));

var _withdrawModalViewStyles = _interopRequireDefault(require("./withdraw-modal-view-styles"));

var _useIntercom = _interopRequireDefault(require("../../../utils/hooks/useIntercom"));

var _section = _interopRequireDefault(require("../../../../design-system/structure/section"));

function WithdrawView(props) {
  var beatsBalance = props.beatsBalance;
  var navigation = (0, _reactNavigationHooks.useNavigation)();
  var Intercom = (0, _useIntercom.default)(props);

  var handleClose = function handleClose() {
    return navigation.goBack();
  };

  var handleContact = function handleContact() {
    return Intercom.displayMessageComposer();
  };

  return _react.default.createElement(_reactNativeSafeAreaView.default, {
    style: _withdrawModalViewStyles.default.safeArea,
    forceInset: {
      top: 'never',
      bottom: 'always',
      horizontal: 'always'
    }
  }, _react.default.createElement(_reactNative.View, {
    style: _withdrawModalViewStyles.default.container
  }, _react.default.createElement(_reactNative.TouchableWithoutFeedback, {
    onPress: handleClose
  }, _react.default.createElement(_reactNative.View, {
    style: _withdrawModalViewStyles.default.overlay
  })), _react.default.createElement(_reactNative.View, {
    style: _withdrawModalViewStyles.default.surface
  }, _react.default.createElement(_section.default, {
    spacing: "base",
    spacingBottom: "none"
  }, _react.default.createElement(_headingView.default, {
    inverted: true
  }, "Withdraw")), _react.default.createElement(_iconButton.default, {
    icon: "close",
    inverted: true,
    onPress: handleClose,
    style: _withdrawModalViewStyles.default.closeButton
  }), beatsBalance < 25000 ? _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_textView.default, {
    inverted: true
  }, "You need to acumulate at least", ' ', _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, "25000 Beats"), ' ', "in order to withdraw the funds to your bank account.")) : _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_Stack.default, {
    vertical: true
  }, _react.default.createElement(_textView.default, {
    inverted: true
  }, "To withdraw, please contact the support team."), _react.default.createElement(_button.default, {
    primary: true,
    inverted: true,
    size: "large",
    onPress: handleContact
  }, "Contact us"))))));
}

WithdrawView.navigationOptions = {};
var _default = WithdrawView;
exports.default = _default;