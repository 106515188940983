Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSpeaker = exports.selectSpeakersLength = exports.selectSpeakerById = exports.selectSpeakersIsLoaded = exports.selectSpeakersIsLoading = exports.selectSpeakersList = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../user/selectors");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectSpeakersState = function selectSpeakersState(state) {
  return state[_constants.NAME];
};

var selectSpeakersList = _reducer.speakersEntityAdapter.getSelectors(selectSpeakersState).selectAll;

exports.selectSpeakersList = selectSpeakersList;
var selectSpeakersIsLoading = (0, _toolkit.createSelector)(selectSpeakersState, function (state) {
  return state.loading;
});
exports.selectSpeakersIsLoading = selectSpeakersIsLoading;
var selectSpeakersIsLoaded = (0, _toolkit.createSelector)(selectSpeakersState, function (state) {
  return state.loaded;
});
exports.selectSpeakersIsLoaded = selectSpeakersIsLoaded;

var selectSpeakerById = _reducer.speakersEntityAdapter.getSelectors(selectSpeakersState).selectById;

exports.selectSpeakerById = selectSpeakerById;
var selectSpeakersLength = (0, _toolkit.createSelector)(selectSpeakersList, function (state) {
  return state.length;
});
exports.selectSpeakersLength = selectSpeakersLength;
var isSpeaker = (0, _toolkit.createSelector)(selectSpeakersList, _selectors.getFirebaseUser, function (speakers, user) {
  if (!user) {
    return false;
  }

  var speakerUserIds = speakers.map(function (speaker) {
    return speaker.userId;
  });
  return speakerUserIds.includes(user.uid);
});
exports.isSpeaker = isSpeaker;