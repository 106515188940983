var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GenreDetailScreenInternal = exports.iosTopMargin = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _events = _interopRequireDefault(require("../../core/events"));

var _Title = _interopRequireDefault(require("../common/typography_v1/Title"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _constants = require("../../core/events/constants");

var _EventItem = _interopRequireDefault(require("../event/EventItem"));

var _LiveEventItem = _interopRequireDefault(require("../common/event/LiveEventItem"));

var _DjProfileSection = _interopRequireDefault(require("../common/dj/DjProfileSection"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 0;
exports.iosTopMargin = iosTopMargin;

var GenreDetailScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(GenreDetailScreenInternal, _React$PureComponent);

  var _super = _createSuper(GenreDetailScreenInternal);

  function GenreDetailScreenInternal() {
    (0, _classCallCheck2.default)(this, GenreDetailScreenInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(GenreDetailScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.props.navigation.setParams({
        hashtag: this.props.hashtag
      });
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(React.Fragment, null, React.createElement(_reactNative.View, {
        style: {
          flex: 1,
          backgroundColor: 'black'
        }
      }, React.createElement(_reactNative.ScrollView, null, React.createElement(_DjProfileSection.default, {
        titleSection: "Top Artists",
        djId: this.props.djId,
        musicType: this.props.hashtag
      }), this.props.events.size > 0 && React.createElement(_reactNative.View, {
        style: styles.sectionContainer
      }, React.createElement(_Title.default, {
        style: styles.sectionTitle
      }, 'Upcoming Shows'), React.createElement(_reactNative.View, {
        style: {
          alignItems: 'center',
          paddingLeft: 16
        }
      }, this.props.events.toArray().map(function (el) {
        if (el.get('status') === _constants.LIVE_STATUS.PLAYING) {
          return React.createElement(_reactNative.View, {
            style: {
              marginBottom: 16
            },
            key: el.get('id')
          }, React.createElement(_LiveEventItem.default, {
            eventId: el.get('id'),
            countOneLiveEvent: true
          }));
        } else {
          return React.createElement(_reactNative.View, {
            style: {
              marginBottom: 16
            },
            key: el.get('id')
          }, React.createElement(_EventItem.default, {
            eventId: el.get('id'),
            countOneEvent: true
          }));
        }
      }))))));
    }
  }]);
  return GenreDetailScreenInternal;
}(React.PureComponent);

exports.GenreDetailScreenInternal = GenreDetailScreenInternal;

GenreDetailScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: React.createElement(_reactNative.View, null),
    title: '#' + navigation.getParam('hashtag', state.params.hashtag),
    headerTitleStyle: {
      color: 'white',
      textAlign: 'center',
      width: '100%'
    },
    headerStyle: {
      backgroundColor: '#000',
      borderBottomColor: '#000'
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    marginBottom: 16,
    paddingHorizontal: 16
  }
});

function mapStateToProps(state, ownProps) {
  var hashtag = ownProps.hashtag;
  return {
    events: _events.default.selectors.getEventsByHashtag(state, hashtag)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadEventsByHashtag: _events.default.actions.loadEventsByHashtag
})((0, _reactNavigation.withNavigation)(GenreDetailScreenInternal));

exports.default = _default;