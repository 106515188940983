var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useScreenShareToggle;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _useSafeState9 = _interopRequireDefault(require("./useSafeState"));

var SCREEN_SHARE_POPUP_CANCEL = 'PERMISSION_DENIED';

function useScreenShareToggle() {
  var onError = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};

  var _useSafeState = (0, _useSafeState9.default)(_react.default.useState(_agoraRtcSdkNg.default.createClient({
    codec: 'vp8',
    mode: 'live'
  }))),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 1),
      client = _useSafeState2[0];

  var _useSafeState3 = (0, _useSafeState9.default)(_react.default.useState(null)),
      _useSafeState4 = (0, _slicedToArray2.default)(_useSafeState3, 2),
      screenShareVideo = _useSafeState4[0],
      setScreenShareVideo = _useSafeState4[1];

  var _useSafeState5 = (0, _useSafeState9.default)(_react.default.useState(false)),
      _useSafeState6 = (0, _slicedToArray2.default)(_useSafeState5, 2),
      screenShareLoading = _useSafeState6[0],
      setScreenShareLoading = _useSafeState6[1];

  var _useSafeState7 = (0, _useSafeState9.default)(_react.default.useState(false)),
      _useSafeState8 = (0, _slicedToArray2.default)(_useSafeState7, 2),
      hasScreenShare = _useSafeState8[0],
      setHasScreenShare = _useSafeState8[1];

  var startScreenShare = _react.default.useCallback(function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(room) {
      var handleError,
          handleNetworkingServerConnection,
          _result$payload,
          _result$payload2,
          video,
          result,
          _args = arguments;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              handleError = _args.length > 1 && _args[1] !== undefined ? _args[1] : function () {};
              handleNetworkingServerConnection = _args.length > 2 && _args[2] !== undefined ? _args[2] : function () {};
              _context.prev = 2;
              setHasScreenShare(true);
              _context.next = 6;
              return client.setClientRole('host');

            case 6:
              _context.next = 8;
              return _agoraRtcSdkNg.default.createScreenVideoTrack({
                encoderConfig: '720p_2',
                optimizationMode: 'detail'
              }, 'disable').catch(function (e) {
                client.leave();
                throw Error(SCREEN_SHARE_POPUP_CANCEL);
              });

            case 8:
              video = _context.sent;
              setScreenShareVideo(video);
              _context.next = 12;
              return handleNetworkingServerConnection();

            case 12:
              result = _context.sent;
              _context.next = 15;
              return client.join(process.env.AGORA_APP_ID, room, result === null || result === void 0 ? void 0 : (_result$payload = result.payload) === null || _result$payload === void 0 ? void 0 : _result$payload.screenShareToken, result === null || result === void 0 ? void 0 : (_result$payload2 = result.payload) === null || _result$payload2 === void 0 ? void 0 : _result$payload2.screenShareId);

            case 15:
              _context.next = 17;
              return client.publish(video);

            case 17:
              video.on('track-ended', function () {
                client.leave();
                setScreenShareVideo(null);
                setHasScreenShare(false);
              });
              _context.next = 25;
              break;

            case 20:
              _context.prev = 20;
              _context.t0 = _context["catch"](2);
              handleError(_context.t0);
              setHasScreenShare(false);
              if (_context.t0.message !== SCREEN_SHARE_POPUP_CANCEL) onError(_context.t0);

            case 25:
              _context.prev = 25;
              setScreenShareLoading(false);
              return _context.finish(25);

            case 28:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[2, 20, 25, 28]]);
    }));

    return function (_x) {
      return _ref.apply(this, arguments);
    };
  }(), [setScreenShareLoading, setHasScreenShare, client, setScreenShareVideo, onError]);

  var stopScreenShare = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
    var handleError,
        _args2 = arguments;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            handleError = _args2.length > 0 && _args2[0] !== undefined ? _args2[0] : function () {};
            _context2.prev = 1;
            setScreenShareLoading(true);
            _context2.next = 5;
            return client.leave();

          case 5:
            if (screenShareVideo) {
              screenShareVideo.stop();
              screenShareVideo.close();
            }

            _context2.next = 12;
            break;

          case 8:
            _context2.prev = 8;
            _context2.t0 = _context2["catch"](1);
            onError(_context2.t0);
            handleError(_context2.t0);

          case 12:
            _context2.prev = 12;
            setScreenShareLoading(false);
            setHasScreenShare(false);
            return _context2.finish(12);

          case 16:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2, null, [[1, 8, 12, 16]]);
  })), [setScreenShareLoading, client, screenShareVideo, setHasScreenShare, onError]);

  return {
    screenShareLoading: screenShareLoading,
    hasScreenShare: hasScreenShare,
    client: client,
    screenShareVideo: screenShareVideo,
    startScreenShare: startScreenShare,
    stopScreenShare: stopScreenShare
  };
}