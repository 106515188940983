var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImageWithLoading;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LOAD_PENDING = 'LOAD_PENDING';
var LOAD_SUCCEEDED = 'LOAD_SUCCEEDED';
var LOAD_REJECTED = 'LOAD_REJECTED';
var RESET_STATE = 'RESET_STATE';

function ImageWithLoading(_ref) {
  var errorFallback = _ref.errorFallback,
      loadingIndicator = _ref.loadingIndicator,
      _onError = _ref.onError,
      _onLoadEnd = _ref.onLoadEnd,
      _onLoadStart = _ref.onLoadStart,
      imageProps = _ref.imageProps,
      containerProps = _ref.containerProps,
      url = _ref.url;

  var _React$useReducer = _react.default.useReducer(function (state, action) {
    switch (action.type) {
      case LOAD_PENDING:
        return {
          loading: true,
          error: false,
          loaded: false
        };

      case LOAD_SUCCEEDED:
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          loaded: true
        });

      case LOAD_REJECTED:
        return {
          loading: false,
          loaded: true,
          error: true
        };

      case RESET_STATE:
        return _objectSpread(_objectSpread({}, state), {}, {
          loaded: false,
          error: false
        });
    }
  }, {
    loading: true,
    loaded: false,
    error: false
  }),
      _React$useReducer2 = (0, _slicedToArray2.default)(_React$useReducer, 2),
      _React$useReducer2$ = _React$useReducer2[0],
      loading = _React$useReducer2$.loading,
      loaded = _React$useReducer2$.loaded,
      error = _React$useReducer2$.error,
      dispatch = _React$useReducer2[1];

  _react.default.useEffect(function () {
    dispatch({
      type: RESET_STATE
    });
  }, [dispatch, url]);

  return _react.default.createElement(_Spacing.default, containerProps, _react.default.createElement(_reactNative.Image, (0, _extends2.default)({
    onLoadStart: function onLoadStart() {
      dispatch({
        type: LOAD_PENDING
      });
      _onLoadStart && _onLoadStart();
    },
    onLoadEnd: function onLoadEnd() {
      dispatch({
        type: LOAD_SUCCEEDED
      });
      _onLoadEnd && _onLoadEnd();
    },
    onError: function onError() {
      dispatch({
        type: LOAD_REJECTED
      });
      _onError && _onError();
    }
  }, imageProps, {
    source: {
      uri: url
    }
  })), error && loaded && errorFallback, loading && !loaded && loadingIndicator);
}