var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SectionView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Paragraph = _interopRequireDefault(require("../../../../src/components/common/typography_v1/Paragraph"));

var _sectionStyles = _interopRequireDefault(require("./section-styles"));

function SectionView(props) {
  return _react.default.createElement(_reactNative.View, {
    style: [_sectionStyles.default.container]
  }, _react.default.createElement(_Paragraph.default, {
    style: _sectionStyles.default.title
  }, props.title), _react.default.createElement(_Paragraph.default, {
    style: _sectionStyles.default.subtitle
  }, props.subtitle), props.children);
}