var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

(function (factory) {
  module.exports = factory(_cryptoJs.default);
})(function (cryptoJS) {
  var GameSparks = function GameSparks() {};

  GameSparks.prototype = {
    init: function init(options) {
      this.options = options;
      this.socketUrl = options.url;
      this.pendingRequests = {};
      this.pendingRequestsBeforeInit = {};
      this.requestCounter = 0;
    },
    buildServiceUrl: function buildServiceUrl(options, stage) {
      var urlAddition = options.key;
      var credential;
      var index;

      if (!options.credential || options.credential.length === 0) {
        credential = 'device';
      } else {
        credential = options.credential;
      }

      if (options.secret) {
        index = options.secret.indexOf(':');

        if (index > 0) {
          credential = 'secure';
          urlAddition = options.secret.substr(0, index) + '/' + urlAddition;
        }
      }

      return 'wss://' + stage + '-' + urlAddition + '.ws.gamesparks.net/ws/' + credential + '/' + urlAddition;
    },
    initByEnv: function initByEnv(options) {
      var stage;

      if (process.env.ENVIRONMENT === 'live') {
        stage = 'live';
      } else {
        stage = 'preview';
      }

      options.url = this.buildServiceUrl(options, stage);
      this.init(options);
    },
    reset: function reset() {
      this.initialised = false;
      this.connected = false;
      this.error = false;

      if (this.webSocket != null) {
        this.webSocket.onclose = null;
        this.webSocket.close();
      }
    },
    connect: function connect() {
      this.reset();

      try {
        this.webSocket = new WebSocket(this.socketUrl);
        this.webSocket.onopen = this.onWebSocketOpen.bind(this);
        this.webSocket.onclose = this.onWebSocketClose.bind(this);
        this.webSocket.onerror = this.onWebSocketError.bind(this);
        this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
      } catch (e) {
        this.log(e.message);
      }
    },
    setupConfig: function setupConfig(options) {
      this.options = (0, _extends2.default)(this.options, _objectSpread({}, options));
    },
    connectWithAuthToken: function connectWithAuthToken(authToken, options) {
      if (!authToken && options.onError) {
        options.onError('WebSocket onError: no_authToken');
      }

      if (this.authToken !== authToken) {
        options && this.setupConfig(options);
        this.setAuthToken(authToken);
        this.connect();
      }
    },
    connectWithDeviceId: function connectWithDeviceId(params, callback) {
      this.sendWithData('DeviceAuthenticationRequest', params, function () {
        return callback();
      });
    },
    disconnect: function disconnect() {
      if (this.webSocket && this.connected) {
        this.connected = false;
        this.pendingRequestsBeforeInit = {};
        this.webSocket.close();
      }
    },
    onWebSocketOpen: function onWebSocketOpen(ev) {
      this.log('WebSocket onOpen');

      if (this.options.onOpen) {
        this.options.onOpen(ev);
      }

      this.connected = true;
    },
    onWebSocketClose: function onWebSocketClose(ev) {
      this.log('WebSocket onClose');

      if (this.options.onClose) {
        this.options.onClose(ev);
      }

      this.connected = false;

      if (!this.error && this.connected) {
        this.connect();
      }
    },
    onWebSocketError: function onWebSocketError(ev) {
      this.log('WebSocket onError: Sorry, but there is some problem with your socket or the server is down');

      if (this.options.onError) {
        this.options.onError(ev);
      }

      this.socketUrl = this.options.url;
      this.error = true;
    },
    onWebSocketMessage: function onWebSocketMessage(message) {
      this.log('WebSocket onMessage: ' + message.data);
      var result;

      try {
        result = JSON.parse(message.data);
      } catch (e) {
        this.log('An error ocurred while parsing the JSON Data: ' + message + '; Error: ' + e);
        return;
      }

      if (this.options.onMessage) {
        this.options.onMessage(result);
      }

      if (result.authToken) {
        this.authToken = result.authToken;
        delete result.authToken;
      }

      if (result.userId) {
        this.userId = result.userId;
      }

      if (result.connectUrl) {
        this.socketUrl = result.connectUrl;
        this.connect();
      }

      var resultType = result['@class'];

      if (resultType === '.AuthenticatedConnectResponse') {
        this.handshake(result);
      } else if (resultType.match(/Response$/)) {
        if (result.requestId) {
          var requestId = result.requestId;
          delete result.requestId;

          if (this.pendingRequests[requestId]) {
            this.pendingRequests[requestId](result);
            this.pendingRequests[requestId] = null;
          }
        }
      }
    },
    executeAfterAuth: function executeAfterAuth(reqObject, requestId) {
      var requestType = reqObject.requestType,
          json = reqObject.json,
          onResponse = reqObject.onResponse;
      this.sendWithData(requestType, json, onResponse);
      delete this.pendingRequestsBeforeInit[requestId];
    },
    handshake: function handshake(result) {
      if (result.nonce) {
        var hmac;

        if (this.options.onNonce) {
          hmac = this.options.onNonce(result.nonce);
        } else {
          hmac = cryptoJS.enc.Base64.stringify(cryptoJS.HmacSHA256(result.nonce, this.options.secret));
        }

        var toSend = (0, _extends2.default)({}, {
          '@class': '.AuthenticatedConnectRequest',
          hmac: hmac
        });

        if (this.authToken) {
          toSend.authToken = this.authToken;
        }

        if (this.sessionId) {
          toSend.sessionId = this.sessionId;
        }

        var browserData = this.getBrowserData();
        toSend.platform = browserData.browser;
        toSend.os = browserData.operatingSystem;
        this.webSocketSend(toSend);
      } else if (result.sessionId) {
        this.sessionId = result.sessionId;
        this.initialised = true;

        if (this.options.onInit) {
          this.options.onInit();
        }

        if (result.userId) {
          for (var key in this.pendingRequestsBeforeInit) {
            if (this.pendingRequestsBeforeInit.hasOwnProperty(key)) {
              this.executeAfterAuth(this.pendingRequestsBeforeInit[key], key);
            }
          }
        }

        this.keepAliveInterval = window.setInterval(this.keepAlive.bind(this), 30000);
      }
    },
    keepAlive: function keepAlive() {
      if (this.initialised && this.connected) {
        this.webSocket.send(' ');
      }
    },
    send: function send(requestType, onResponse) {
      this.sendWithData(requestType, {}, onResponse);
    },
    sendWithData: function sendWithData(requestType, json, onResponse) {
      if (!this.initialised) {
        var requestId = new Date().getTime() + '_' + ++this.requestCounter;
        this.pendingRequestsBeforeInit[requestId] = {
          requestType: requestType,
          json: json,
          onResponse: onResponse
        };
        return;
      }

      if (requestType.indexOf('.') !== 0) {
        requestType = '.' + requestType;
      }

      json['@class'] = requestType;
      json.requestId = new Date().getTime() + '_' + ++this.requestCounter;

      if (onResponse != null) {
        this.pendingRequests[json.requestId] = onResponse;
        setTimeout(function () {
          if (this.pendingRequests[json.requestId]) {
            this.pendingRequests[json.requestId]({
              error: 'NO_RESPONSE'
            });
          }
        }.bind(this), 32000);
      }

      this.webSocketSend(json);
    },
    webSocketSend: function webSocketSend(data) {
      if (this.options.onSend) {
        this.options.onSend(data);
      }

      var requestString = JSON.stringify(data);
      this.log('WebSocket send: ' + requestString);
      this.webSocket.send(requestString);
    },
    getSocketUrl: function getSocketUrl() {
      return this.socketUrl;
    },
    getSessionId: function getSessionId() {
      return this.sessionId;
    },
    getLoggedUser: function getLoggedUser() {
      return this.userId;
    },
    getAuthToken: function getAuthToken() {
      return this.authToken;
    },
    setAuthToken: function setAuthToken(authToken) {
      this.authToken = authToken;
    },
    isConnected: function isConnected() {
      return this.connected;
    },
    log: function log(message) {
      if (this.options.logger) {
        this.options.logger(message);
      }
    },
    getBrowserData: function getBrowserData() {
      var browsers = [{
        string: navigator.userAgent,
        subString: 'Chrome',
        identity: 'Chrome'
      }, {
        string: navigator.userAgent,
        subString: 'OmniWeb',
        versionSearch: 'OmniWeb/',
        identity: 'OmniWeb'
      }, {
        string: navigator.vendor,
        subString: 'Apple',
        identity: 'Safari',
        versionSearch: 'Version'
      }, {
        prop: window.opera,
        identity: 'Opera',
        subString: 'Opera',
        versionSearch: 'Version'
      }, {
        string: navigator.vendor,
        subString: 'iCab',
        identity: 'iCab'
      }, {
        string: navigator.vendor,
        subString: 'KDE',
        identity: 'Konqueror'
      }, {
        string: navigator.userAgent,
        subString: 'Firefox',
        identity: 'Firefox'
      }, {
        string: navigator.vendor,
        subString: 'Camino',
        identity: 'Camino'
      }, {
        string: navigator.userAgent,
        subString: 'Netscape',
        identity: 'Netscape'
      }, {
        string: navigator.userAgent,
        subString: 'MSIE',
        identity: 'Explorer',
        versionSearch: 'MSIE'
      }, {
        string: navigator.userAgent,
        subString: 'Gecko',
        identity: 'Mozilla',
        versionSearch: 'rv'
      }, {
        string: navigator.userAgent,
        subString: 'Mozilla',
        identity: 'Netscape',
        versionSearch: 'Mozilla'
      }];
      var operatingSystems = [{
        string: navigator.platform,
        subString: 'Win',
        identity: 'Windows'
      }, {
        string: navigator.platform,
        subString: 'Mac',
        identity: 'Mac'
      }, {
        string: navigator.userAgent,
        subString: 'iPhone',
        identity: 'iPhone/iPod'
      }, {
        string: navigator.platform,
        subString: 'Linux',
        identity: 'Linux'
      }];

      function searchForIdentity(data) {
        for (var i = 0; i < data.length; i++) {
          var string = data[i].string;
          var prop = data[i].prop;

          if (string) {
            if (string.indexOf(data[i].subString) !== -1) {
              return data[i].identity;
            }
          } else if (prop) {
            return data[i].identity;
          }
        }
      }

      return {
        browser: searchForIdentity(browsers),
        operatingSystem: searchForIdentity(operatingSystems)
      };
    }
  };
  return GameSparks;
});