Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var liveStreamVideoContainerStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    liveVideoContainer: {
      paddingTop: 1,
      borderRadius: 10,
      overflow: 'hidden',
      marginVertical: theme.spacing.s,
      marginHorizontal: theme.spacing.ml
    },
    webVideo: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.smd;
}, function (theme) {
  return {
    liveVideoContainer: {
      marginHorizontal: theme.spacing.s
    }
  };
}).buildHook();
var _default = liveStreamVideoContainerStyle;
exports.default = _default;