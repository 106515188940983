var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.fromJS({
  hashtags: {},
  hashtagsRow1: {},
  hashtagsRow2: {}
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOAD_HASHTAGS_COMPLETED:
      {
        var hashtags;
        hashtags = action.hashTags.length > 0 ? action.hashTags.sort(function (a, b) {
          return a.priority > b.priority ? 1 : -1;
        }) : action.hashTags;
        newState = newState.set('hashtags', _immutable.default.fromJS(hashtags));
        var row1 = hashtags.filter(function (tag) {
          return parseInt(tag.priority / 1000, 10) === 1;
        });
        var row2 = hashtags.filter(function (tag) {
          return parseInt(tag.priority / 1000, 10) === 2;
        });
        newState = newState.set('hashtagsRow1', _immutable.default.fromJS(row1));
        newState = newState.set('hashtagsRow2', _immutable.default.fromJS(row2));
        break;
      }

    default:
      break;
  }

  return newState;
}