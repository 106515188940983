var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _stripeJs = require("@stripe/stripe-js");

var _reactNative = require("react-native");

var PaymentService = function () {
  function PaymentService(token, cloudFunctionUrl) {
    var _this = this;

    (0, _classCallCheck2.default)(this, PaymentService);
    this.stripePromise = void 0;
    this.cloudFunctionUrl = void 0;
    this.connectedAccountId = void 0;
    this.token = void 0;

    this.purchaseFreeTicketOrUseExternalPayment = function () {
      var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(ticketId, eventId, clientReferenceId, variationsGroupedByAddonId, firebaseToken, promoCodeId) {
        var params, response, er, _yield$response$json, externalPaymentLink;

        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                params = {
                  ticketId: ticketId,
                  eventId: eventId,
                  clientReferenceId: clientReferenceId,
                  variationsGroupedByAddonId: variationsGroupedByAddonId,
                  promoCodeId: promoCodeId
                };
                _context.next = 3;
                return fetch("".concat(_this.cloudFunctionUrl, "/payment-freeTicketPurchaseOrExternalPayment"), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer ".concat(firebaseToken)
                  },
                  body: JSON.stringify(params)
                });

              case 3:
                response = _context.sent;

                if (response.ok) {
                  _context.next = 9;
                  break;
                }

                _context.next = 7;
                return response.json();

              case 7:
                er = _context.sent;
                return _context.abrupt("return", {
                  result: false,
                  error: er.message
                });

              case 9:
                _context.next = 11;
                return response.json();

              case 11:
                _yield$response$json = _context.sent;
                externalPaymentLink = _yield$response$json.externalPaymentLink;

                if (externalPaymentLink) {
                  if (_reactNative.Platform.OS === 'web') {
                    window.location.href = externalPaymentLink.includes('http') || externalPaymentLink.includes('https') ? externalPaymentLink : "https://".concat(externalPaymentLink);
                  } else {
                    _reactNative.Linking.openURL(externalPaymentLink.includes('http') || externalPaymentLink.includes('https') ? externalPaymentLink : "https://".concat(externalPaymentLink));
                  }
                }

                return _context.abrupt("return", {
                  result: true,
                  error: null
                });

              case 15:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function (_x, _x2, _x3, _x4, _x5, _x6) {
        return _ref.apply(this, arguments);
      };
    }();

    this.startCheckOutPaidTicket = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(payloadInfo, firebaseToken) {
        var response, error, _yield$response$json2, clientSecret, stripeFee, paymentInfo, parsedError;

        return _regenerator.default.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.prev = 0;
                _context2.next = 3;
                return fetch("".concat(_this.cloudFunctionUrl, "/payment-paidTicketCharge"), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer ".concat(firebaseToken)
                  },
                  body: JSON.stringify(payloadInfo)
                });

              case 3:
                response = _context2.sent;

                if (response.ok) {
                  _context2.next = 9;
                  break;
                }

                _context2.next = 7;
                return response.json();

              case 7:
                error = _context2.sent;
                throw new Error(JSON.stringify(error));

              case 9:
                _context2.next = 11;
                return response.json();

              case 11:
                _yield$response$json2 = _context2.sent;
                clientSecret = _yield$response$json2.clientSecret;
                stripeFee = _yield$response$json2.stripeFee;
                paymentInfo = {
                  clientSecret: clientSecret,
                  stripeFee: stripeFee
                };
                return _context2.abrupt("return", paymentInfo);

              case 18:
                _context2.prev = 18;
                _context2.t0 = _context2["catch"](0);
                parsedError = JSON.parse(_context2.t0.message);
                console.error("[PAYMENT ERROR]: An error has occured while running function \"payment-paidTicketCharge\" with error code: ".concat(parsedError.code));
                return _context2.abrupt("return", parsedError);

              case 23:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2, null, [[0, 18]]);
      }));

      return function (_x7, _x8) {
        return _ref2.apply(this, arguments);
      };
    }();

    this.createFreeUnassignedTickets = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(payloadInfo, firebaseToken) {
        var response, error, parsedError;
        return _regenerator.default.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.prev = 0;
                _context3.next = 3;
                return fetch("".concat(_this.cloudFunctionUrl, "/payment-createFreeUnassignedTickets"), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer ".concat(firebaseToken)
                  },
                  body: JSON.stringify(payloadInfo)
                });

              case 3:
                response = _context3.sent;

                if (response.ok) {
                  _context3.next = 11;
                  break;
                }

                _context3.next = 7;
                return response.json();

              case 7:
                error = _context3.sent;
                throw new Error(JSON.stringify(error));

              case 11:
                return _context3.abrupt("return", {
                  success: true
                });

              case 12:
                _context3.next = 19;
                break;

              case 14:
                _context3.prev = 14;
                _context3.t0 = _context3["catch"](0);
                parsedError = JSON.parse(_context3.t0.message);
                console.error("[PAYMENT ERROR]: An error has occured while running function \"payment-paidTicketCharge\" with error code: ".concat(parsedError.code));
                return _context3.abrupt("return", parsedError);

              case 19:
              case "end":
                return _context3.stop();
            }
          }
        }, _callee3, null, [[0, 14]]);
      }));

      return function (_x9, _x10) {
        return _ref3.apply(this, arguments);
      };
    }();

    this.assignTickets = function () {
      var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(payloadInfo, firebaseToken) {
        var response, JSONResponse, parsedError;
        return _regenerator.default.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.prev = 0;
                _context4.next = 3;
                return fetch("".concat(_this.cloudFunctionUrl, "/payment-assignTickets"), {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer ".concat(firebaseToken)
                  },
                  body: JSON.stringify(payloadInfo)
                });

              case 3:
                response = _context4.sent;
                _context4.next = 6;
                return response.json();

              case 6:
                JSONResponse = _context4.sent;

                if (response.ok) {
                  _context4.next = 11;
                  break;
                }

                throw new Error(JSON.stringify(JSONResponse));

              case 11:
                return _context4.abrupt("return", {
                  success: true,
                  unassignedTicketResult: JSONResponse.groupedTickets
                });

              case 12:
                _context4.next = 19;
                break;

              case 14:
                _context4.prev = 14;
                _context4.t0 = _context4["catch"](0);
                parsedError = JSON.parse(_context4.t0.message);
                console.error("[PAYMENT ERROR]: An error has occured while running function \"payment-assignTickets\" with error code: ".concat(parsedError.code));
                return _context4.abrupt("return", parsedError);

              case 19:
              case "end":
                return _context4.stop();
            }
          }
        }, _callee4, null, [[0, 14]]);
      }));

      return function (_x11, _x12) {
        return _ref4.apply(this, arguments);
      };
    }();

    this.cloudFunctionUrl = cloudFunctionUrl;
    this.token = token;
  }

  (0, _createClass2.default)(PaymentService, [{
    key: "initStripe",
    value: function initStripe(connectedAccountId) {
      if (!this.connectedAccountId || this.connectedAccountId !== connectedAccountId) {
        this.connectedAccountId = connectedAccountId;
        this.stripePromise = (0, _stripeJs.loadStripe)(this.token, {
          stripeAccount: connectedAccountId
        });
      }
    }
  }]);
  return PaymentService;
}();

var _default = new PaymentService(process.env.STRIPE_TOKEN, process.env.FIREBASE_WEB_APP_functionUrl);

exports.default = _default;