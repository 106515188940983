var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GridItem;

var _react = _interopRequireDefault(require("react"));

function GridItem(_ref) {
  var _ref$x = _ref.x,
      x = _ref$x === void 0 ? 0 : _ref$x,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 0 : _ref$y,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 1 : _ref$width,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? 1 : _ref$height,
      children = _ref.children;
  return _react.default.createElement("div", {
    style: {
      position: 'absolute',
      overflow: 'hidden',
      backgroundColor: 'black',
      width: width,
      height: height,
      top: y,
      left: x,
      transition: 'all 300ms ease-in-out'
    }
  }, children);
}