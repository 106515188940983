var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Audio;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function Audio(_ref) {
  var audioTrack = _ref.audioTrack;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      sinkId = _useAudioVideoContext.sinkId;

  _react.default.useEffect(function () {
    if (!audioTrack) return;
    audioTrack.play();
    return function () {
      audioTrack.stop();
    };
  }, [audioTrack]);

  _react.default.useEffect(function () {
    if (audioTrack && sinkId) {
      audioTrack.setPlaybackDevice(sinkId);
    }
  }, [sinkId, audioTrack]);

  return null;
}