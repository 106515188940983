var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _FacebookLoginButton = _interopRequireDefault(require("./FacebookLoginButton"));

var _AppleSignInButton = _interopRequireDefault(require("./AppleSignInButton"));

var _GoogleLoginButton = _interopRequireDefault(require("./GoogleLoginButton"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var LoginScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LoginScreenInternal, _React$PureComponent);

  var _super = _createSuper(LoginScreenInternal);

  function LoginScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, LoginScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this._isSucces = false;
    _this.state = {
      resolveFuncs: [],
      rejectFuncs: []
    };

    _this.handleSuccess = function () {
      _this.state.resolveFuncs.forEach(function (resolveFunc) {
        return resolveFunc();
      });

      _this._isSucces = true;

      _this.props.navigation.goBack();
    };

    return _this;
  }

  (0, _createClass2.default)(LoginScreenInternal, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (!this._isSucces) {
        this.state.rejectFuncs.forEach(function (rejectFunc) {
          return rejectFunc({
            isCancelled: true
          });
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNativePaper.Surface, {
        style: styles.surface
      }, _react.default.createElement(_reactNativePaper.Subheading, {
        style: {
          marginBottom: 20,
          fontWeight: 'bold'
        }
      }, "You need to log in to continue."), _react.default.createElement(_reactNative.TouchableOpacity, {
        onPress: function onPress() {
          return _this2.props.navigation.goBack();
        },
        style: styles.backButton
      }, _react.default.createElement(_CustomIcon.default, {
        name: "close",
        size: 16,
        color: "#fff"
      })), _react.default.createElement(_reactNative.View, {
        style: styles.socialLoginContainer
      }, _reactNative.Platform.OS === 'ios' ? _react.default.createElement(_AppleSignInButton.default, {
        onSuccess: this.handleSuccess
      }) : null, _react.default.createElement(_FacebookLoginButton.default, {
        onSuccess: this.handleSuccess
      }), _react.default.createElement(_GoogleLoginButton.default, {
        onSuccess: this.handleSuccess
      }))));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(props, state) {
      if (props.navigation) {
        var newState = {};
        var resolveFunc = props.navigation.getParam('resolve');

        if (resolveFunc && state.resolveFuncs.indexOf(resolveFunc) === -1) {
          newState.resolveFuncs = [].concat((0, _toConsumableArray2.default)(state.resolveFuncs), [resolveFunc]);
        }

        var rejectFunc = props.navigation.getParam('reject');

        if (rejectFunc && state.rejectFuncs.indexOf(rejectFunc) === -1) {
          newState.rejectFuncs = [].concat((0, _toConsumableArray2.default)(state.rejectFuncs), [rejectFunc]);
        }

        return Object.keys(newState).length > 0 ? newState : null;
      }

      return null;
    }
  }]);
  return LoginScreenInternal;
}(_react.default.PureComponent);

exports.default = LoginScreenInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  surface: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 50,
    paddingBottom: 30,
    width: '100%',
    elevation: 2
  },
  backButton: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    top: 0,
    width: 48,
    height: 48
  },
  socialLoginContainer: {
    flexDirection: 'row'
  }
});