var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorBlock = ErrorBlock;
exports.InputLabel = InputLabel;

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _react = _interopRequireDefault(require("react"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    labelStyle: {
      marginBottom: 8,
      color: theme.palette.alternativeText.main,
      textAlign: 'left'
    },
    errorBlockStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: 8,
      color: theme.palette.error.main
    }
  };
});

function ErrorBlock(_ref) {
  var errorMessage = _ref.errorMessage;
  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.errorBlockStyle
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, errorMessage));
}

function InputLabel(_ref2) {
  var label = _ref2.label;
  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.labelStyle
  }, _react.default.createElement(_core.Typography, null, _i18n.default.translate(label)));
}