Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useExposStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginTop: -theme.spacing.l,
      marginHorizontal: -theme.spacing.l,
      marginBottom: theme.spacing.xl,
      alignItems: 'stretch'
    },
    header: {
      paddingBottom: theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.lg;
}, {
  container: {
    justifyContent: 'center'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  container: {
    flexDirection: 'column',
    justifyContent: 'center'
  }
}).buildHook();
var _default = useExposStyles;
exports.default = _default;