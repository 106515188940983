var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactRedux = require("react-redux");

var _partners = _interopRequireDefault(require("../../core/partners"));

var _partner = _interopRequireDefault(require("./partner"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _partnersStyles = _interopRequireDefault(require("./partners-styles"));

var _selectors = require("../../core/landing-page-sections/selectors");

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _category = _interopRequireDefault(require("../../core/category"));

var _styles = _interopRequireDefault(require("./styles"));

var NO_CATEGORY = 'aaaaaa';

var Partners = function Partners() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _partnersStyles.default)();
  var classes = (0, _styles.default)();
  var partnersArray = (0, _reactRedux.useSelector)(_partners.default.selectors.selectPartnersList);
  var partnersSection = (0, _reactRedux.useSelector)(_selectors.sectionByTypeSelector);
  var isLoading = (0, _reactRedux.useSelector)(_partners.default.selectors.selectPartnersLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_partners.default.selectors.selectPartnersLoaded);
  var isLoadedCategories = (0, _reactRedux.useSelector)(_category.default.selectors.selectCategoriesLoaded);

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      sponsorsTitle = _useSelector.Sponsors;

  var categoriesArray = (0, _reactRedux.useSelector)(_category.default.selectors.selectCategoriesList);
  var groupedPartners = [];

  _react.default.useEffect(function () {
    dispatch(_category.default.actions.getCategories());
    dispatch(_partners.default.actions.getPartners());
  }, [dispatch]);

  if (isLoaded && !isLoading && !partnersArray.length && !isLoadedCategories) return null;
  if (partnersSection && !partnersSection.isActive) return null;
  var partnersWithoutCategory = partnersArray.filter(function (p) {
    return p.selectedCategory === null;
  });
  var partnersWithCategory = partnersArray.filter(function (p) {
    return p === null || p === void 0 ? void 0 : p.selectedCategory;
  });
  partnersWithCategory.forEach(function (partner) {
    var key = partner.selectedCategory.name;
    if (!groupedPartners[key]) groupedPartners[key] = [];
    groupedPartners[key].push(partner);
  });
  var groupedCategories = {};

  if (categoriesArray && categoriesArray.length > 0) {
    groupedCategories = categoriesArray.reduce(function (accumulator, current) {
      accumulator[current.name] = current.sort;
      return accumulator;
    }, {});
  }

  return _react.default.createElement(_react.default.Fragment, null, isLoaded && Object.entries(partnersArray).length !== 0 && _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xl', 0],
    style: Styles.header
  }, _react.default.createElement(_core.Typography, {
    className: classes.textUppercase,
    variant: "h2"
  }, sponsorsTitle || _i18n.default.t('header.sponsors'))), _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    style: Styles.noCategoryMargin
  }, partnersWithoutCategory.map(function (partner) {
    return _react.default.createElement(_partner.default, {
      key: partner.id,
      partner: partner
    });
  })), Object.entries(groupedPartners).sort(function (a, b) {
    if (groupedCategories[a[0]] > groupedCategories[b[0]]) return 1;
    if (groupedCategories[a[0]] < groupedCategories[b[0]]) return -1;
    return 0;
  }).map(function (_ref, index) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        key = _ref2[0],
        partners = _ref2[1];

    return _react.default.createElement(_react.default.Fragment, {
      key: "P".concat(index)
    }, key !== NO_CATEGORY && _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'xl', 0],
      style: Styles.subHeader
    }, _react.default.createElement(_core.Typography, {
      className: classes.textUppercase,
      variant: "h3"
    }, key)), _react.default.createElement(_Spacing.default, {
      wrap: "wrap",
      alignItems: "center",
      style: Styles.container
    }, partners.map(function (partner) {
      return _react.default.createElement(_partner.default, {
        key: partner.id,
        partner: partner
      });
    })));
  }), isLoading && !isLoaded && Array(4).fill('').map(function (_, index) {
    return _react.default.createElement(_reactContentLoader.default, {
      key: index,
      viewBox: "0 0 171 96",
      height: 96,
      width: 171,
      style: Styles.loaderIndicator,
      backgroundColor: "#e0e0e0"
    }, _react.default.createElement("rect", {
      x: "0",
      y: "0",
      rx: "0",
      ry: "0",
      width: "171",
      height: "96"
    }));
  }));
};

var _default = Partners;
exports.default = _default;