var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LiveCountDown;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _backstageComponentsStyle = _interopRequireDefault(require("./backstageComponents-style"));

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

function LiveCountDown(_ref) {
  var backstageId = _ref.backstageId;
  var Styles = (0, _backstageComponentsStyle.default)();
  var classes = (0, _styles.default)();
  var liveStartedBy = (0, _reactRedux.useSelector)((0, _selectors.liveStartedBySelector)(backstageId));
  var countdown = (0, _reactRedux.useSelector)((0, _selectors.startLiveCountDownSelector)(backstageId));
  return _react.default.createElement(_core.Backdrop, {
    style: {
      zIndex: 100,
      flexDirection: 'column',
      position: 'absolute'
    },
    open: Boolean(true)
  }, countdown && countdown !== 'START' && _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: (0, _clsx.default)(classes.textWhite, classes.description)
  }, _react.default.createElement("strong", null, _i18n.default.translate('backstage.sessionStarts', {
    name: liveStartedBy ? liveStartedBy : _i18n.default.translate('backstage.someone')
  }), _react.default.createElement("br", null), _i18n.default.translate('backstage.startsIn'))) || null, _react.default.createElement(_core.Typography, {
    variant: "h1",
    className: (0, _clsx.default)(classes.textWhite, classes.counter)
  }, _react.default.createElement("strong", null, String(countdown || '').toUpperCase())));
}