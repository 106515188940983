var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var Orientation = function Orientation() {
  (0, _classCallCheck2.default)(this, Orientation);
};

exports.default = Orientation;

Orientation.lockToPortrait = function () {};