var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _formik = require("formik");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _core = require("@material-ui/core");

var _excluded = ["onBlur"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Select(_ref) {
  var error = _ref.error,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'pageText' : _ref$color,
      _ref$dense = _ref.dense,
      dense = _ref$dense === void 0 ? false : _ref$dense,
      value = _ref.value,
      leftIcon = _ref.leftIcon,
      rightIcon = _ref.rightIcon,
      name = _ref.name,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? [] : _ref$options,
      _ref$containerStyle = _ref.containerStyle,
      containerStyle = _ref$containerStyle === void 0 ? {
    position: 'relative',
    alignItems: 'center'
  } : _ref$containerStyle;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      focused = _React$useState2[0],
      setFocused = _React$useState2[1];

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape,
      colors = _useTheme.colors,
      typography = _useTheme.typography,
      spacing = _useTheme.spacing;

  var activeColor = error ? 'errorColor' : focused ? 'button' : color;
  var padding = dense ? spacing.s : spacing.m;
  return _react.default.createElement(_formik.Field, {
    id: name,
    name: name
  }, function (_ref2) {
    var _ref2$field = _ref2.field,
        _onBlur = _ref2$field.onBlur,
        rest = (0, _objectWithoutProperties2.default)(_ref2$field, _excluded),
        form = _ref2.form;
    return _react.default.createElement(_Spacing.default, {
      style: containerStyle,
      flex: 1,
      direction: "row",
      alignItems: "flex-start",
      justifyContent: "center"
    }, leftIcon ? _react.default.createElement(_reactNative.Text, {
      style: {
        position: 'absolute',
        left: padding,
        top: padding
      },
      color: activeColor
    }, _react.default.createElement(_core.Typography, {
      color: "inherit"
    }, leftIcon)) : null, _react.default.createElement("select", (0, _extends2.default)({
      name: name,
      onFocus: function onFocus() {
        return setFocused(true);
      },
      onBlur: function onBlur(e) {
        setFocused(false);

        _onBlur(e);
      },
      id: name
    }, rest, {
      value: value,
      style: _objectSpread(_objectSpread(_objectSpread({}, typography.paragraph), {}, {
        outlineColor: 'transparent',
        outlineWidth: 0,
        color: colors[color] || color,
        paddingTop: padding,
        paddingBottom: padding,
        paddingLeft: padding + (leftIcon ? padding + spacing.m : 0),
        paddingRight: padding + (rightIcon ? padding + spacing.m : 0),
        borderStyle: 'solid',
        borderColor: focused ? colors.button : colors[color] || color,
        width: '100%',
        borderWidth: 2
      }, shape.buttons), error ? {
        borderColor: colors.errorColor
      } : {})
    }), _react.default.createElement("option", {
      value: ''
    }, ''), options.map(function (option) {
      return _react.default.createElement("option", {
        key: option.id,
        value: option.id
      }, option.value);
    })), rightIcon ? _react.default.createElement(_reactNative.Text, {
      style: {
        position: 'absolute',
        right: padding
      },
      color: activeColor
    }, _react.default.createElement(_core.Typography, {
      color: "inherit"
    }, rightIcon)) : null);
  });
}

var _default = Select;
exports.default = _default;