var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Divider;

var _react = _interopRequireDefault(require("react"));

var _DesignSystem = require("../../DesignSystem");

var _Section = _interopRequireDefault(require("../section/Section"));

function Divider(_ref) {
  var _ref$direction = _ref.direction,
      direction = _ref$direction === void 0 ? 'horizontal' : _ref$direction,
      width = _ref.width,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'borderColor' : _ref$color,
      style = _ref.style,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? 's' : _ref$spacing;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      themeSpacing = _useTheme.spacing;

  var _style = direction === 'horizontal' ? {
    width: '100%',
    height: width,
    marginVertical: themeSpacing[spacing]
  } : {
    width: width,
    marginHorizontal: themeSpacing[spacing]
  };

  return _react.default.createElement(_Section.default, {
    color: color,
    style: [_style, style]
  });
}