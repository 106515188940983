var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Participants;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

var _useParticipants = require("../../../utils/hooks/useParticipants");

var _HandleMuteSpeaker = _interopRequireDefault(require("./HandleMuteSpeaker"));

var _Layout = _interopRequireDefault(require("./Layout"));

var _LayoutStrategies = require("./LayoutStrategies");

var _NameStrip = _interopRequireDefault(require("./NameStrip"));

var _Participant = _interopRequireDefault(require("./Participant"));

var _RemainedParticipants = _interopRequireDefault(require("./RemainedParticipants"));

var _RemoteOrLocalScreenShare = _interopRequireDefault(require("./RemoteOrLocalScreenShare"));

function Participants(_ref) {
  var roomId = _ref.roomId,
      participantsInfo = _ref.participantsInfo,
      uid = _ref.uid,
      canUserMuteSpeaker = _ref.canUserMuteSpeaker;
  var screenSharer = (0, _reactRedux.useSelector)((0, _selectors.roomScreenSharerSelector)(roomId));
  var hasScreenShare = (0, _reactRedux.useSelector)((0, _selectors.roomHasScreenShareSelector)(roomId));
  var webRtcParticipants = (0, _useParticipants.useContextParticipants)();
  var participants = Object.entries(participantsInfo).sort(function (_ref2, _ref3) {
    var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
        aData = _ref4[1];

    var _ref5 = (0, _slicedToArray2.default)(_ref3, 2),
        bData = _ref5[1];

    return new Date(aData.joinedAt) - new Date(bData.joinedAt);
  }).map(function (_ref6) {
    var _ref7 = (0, _slicedToArray2.default)(_ref6, 1),
        id = _ref7[0];

    return {
      id: Number(id),
      screenShareId: Number(screenSharer.id) === Number(id) ? screenSharer.screenShareId : null,
      isConnectedViaWebRTC: !!webRtcParticipants.get(Number(id)),
      isLocal: uid === Number(id) || uid === screenSharer.id
    };
  }).filter(function (p) {
    return p.isConnectedViaWebRTC || p.isLocal;
  });
  return _react.default.createElement(_core.Box, {
    m: 2
  }, _react.default.createElement(_HandleMuteSpeaker.default, {
    roomId: roomId,
    userId: uid
  }), _react.default.createElement(_Layout.default, {
    items: participants,
    hasScreenShare: hasScreenShare
  }, function (_ref8) {
    var grids = _ref8.grids;
    return grids.map(function (grid) {
      switch (grid.type) {
        case _LayoutStrategies.GRID_TYPES.PARTICIPANT:
          {
            return _react.default.createElement(_Participant.default, {
              key: grid.id,
              info: participantsInfo[grid.id],
              participant: webRtcParticipants.get(grid.id),
              isLocal: grid.id === uid,
              grid: grid,
              id: grid === null || grid === void 0 ? void 0 : grid.id,
              roomId: roomId,
              canUserMuteSpeaker: canUserMuteSpeaker
            });
          }

        case _LayoutStrategies.GRID_TYPES.SCREEN_SHARE:
          {
            var info = participantsInfo[screenSharer.id];
            var webRtcParticipant = webRtcParticipants.get(grid.id);
            return _react.default.createElement(_RemoteOrLocalScreenShare.default, {
              key: grid.id,
              grid: grid,
              isLocal: uid === screenSharer.id,
              videoTrack: webRtcParticipant === null || webRtcParticipant === void 0 ? void 0 : webRtcParticipant.videoTrack
            }, _react.default.createElement(_NameStrip.default, {
              name: info ? "".concat(info.name, " (screen)") : '(screen)'
            }));
          }

        case _LayoutStrategies.GRID_TYPES.REMAINED_PARTICIPANTS:
          {
            return _react.default.createElement(_RemainedParticipants.default, {
              webRtcParticipants: webRtcParticipants,
              grid: grid
            });
          }

        default:
          {
            return _react.default.createElement(_Participant.default, {
              key: grid.id,
              info: participantsInfo[grid.id],
              participant: webRtcParticipants.get(grid.id),
              isLocal: grid.id === uid,
              grid: grid,
              id: grid === null || grid === void 0 ? void 0 : grid.id,
              roomId: roomId,
              canUserMuteSpeaker: canUserMuteSpeaker
            });
          }
      }
    });
  }));
}