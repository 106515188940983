var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.chatUserProfilesEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var chatUserProfilesEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.chatUserProfilesEntityAdapter = chatUserProfilesEntityAdapter;
var InitialState = chatUserProfilesEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
var chatUserProfileReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getChatUserProfileInfo.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getChatUserProfileInfo.rejected, function (state, _ref) {
  var error = _ref.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getChatUserProfileInfo.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;
  chatUserProfilesEntityAdapter.upsertOne(state, payload);
}), _createReducer));
var _default = chatUserProfileReducer;
exports.default = _default;