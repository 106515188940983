var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GoLiveCardView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Card = _interopRequireDefault(require("design-system/structure/Card"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _textWithIconView = _interopRequireDefault(require("design-system/typography/text-with-icon-view"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _constants = require("../../../../constants");

function GoLiveCardView(props) {
  var liveReferralsBeats = props.liveReferralsBeats,
      liveReferralsMessage = props.liveReferralsMessage;

  var handleGoLive = function handleGoLive() {
    _reactNative.Linking.openURL(_constants.DJ_PLATFORM);
  };

  return _react.default.createElement(_Card.default, {
    style: props.style
  }, _react.default.createElement(_Card.default.Title, {
    title: "Go live now!",
    titleIcon: "video-camera",
    right: function right() {
      return _react.default.createElement(_textWithIconView.default, {
        icon: "beats",
        textStyle: "strong",
        inverted: true
      }, "".concat(liveReferralsBeats !== undefined ? liveReferralsBeats : '', " B/viewer"));
    }
  }), _react.default.createElement(_Card.default.Content, null, _react.default.createElement(_textView.default, {
    inverted: true
  }, liveReferralsMessage)), _react.default.createElement(_Card.default.Actions, null, _react.default.createElement(_button.default, {
    primary: true,
    inverted: true,
    onPress: handleGoLive
  }, "Go live")));
}