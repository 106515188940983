Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectAddonsIsLoaded = exports.selectAddonsIsLoading = exports.selectAddonsList = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectAddonsState = function selectAddonsState(state) {
  return state[_constants.NAME];
};

var selectAddonsList = _reducer.addonsEntityAdapter.getSelectors(selectAddonsState).selectAll;

exports.selectAddonsList = selectAddonsList;
var selectAddonsIsLoading = (0, _toolkit.createSelector)(selectAddonsState, function (state) {
  return state.loading;
});
exports.selectAddonsIsLoading = selectAddonsIsLoading;
var selectAddonsIsLoaded = (0, _toolkit.createSelector)(selectAddonsState, function (state) {
  return state.loaded;
});
exports.selectAddonsIsLoaded = selectAddonsIsLoaded;