var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleJoin;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function HandleJoin(_ref) {
  var children = _ref.children,
      token = _ref.token,
      uid = _ref.uid,
      room = _ref.room;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      isQuerying = _useAudioVideoContext.isQuerying,
      join = _useAudioVideoContext.join;

  return _react.default.createElement(_react.default.Fragment, null, children({
    isQuerying: isQuerying,
    onJoin: function () {
      var _onJoin = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return join(room, token, uid);

              case 2:
                return _context.abrupt("return", _context.sent);

              case 3:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function onJoin() {
        return _onJoin.apply(this, arguments);
      }

      return onJoin;
    }()
  }));
}