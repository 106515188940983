var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasVideo;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactKonva = require("react-konva");

var _canvasUtils = require("../../../../utils/canvas-utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function CanvasVideo(_ref) {
  var track = _ref.track,
      width = _ref.width,
      height = _ref.height;

  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      video = _useState2[0],
      setVideo = _useState2[1];

  var _useState3 = (0, _react.useState)({
    width: width,
    height: height
  }),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      size = _useState4[0],
      setSize = _useState4[1];

  (0, _react.useEffect)(function () {
    if (!track) return;
    var div = document.createElement('div');
    track.play(div, {
      fit: 'contain',
      mirror: false
    });
    var agoraContainer = div.children.item(0);
    if (!agoraContainer) return;

    var _video = agoraContainer.children.item(0);

    if (_video) {
      setVideo(_video);

      _video.addEventListener('resize', function () {
        var _track$getMediaStream = track.getMediaStreamTrack().getSettings(),
            _track$getMediaStream2 = _track$getMediaStream.width,
            width = _track$getMediaStream2 === void 0 ? 0 : _track$getMediaStream2,
            _track$getMediaStream3 = _track$getMediaStream.height,
            height = _track$getMediaStream3 === void 0 ? 0 : _track$getMediaStream3;

        setSize({
          width: width,
          height: height
        });
      });
    }

    track.on('first-frame-decoded', function () {
      var _track$getMediaStream4 = track.getMediaStreamTrack().getSettings(),
          _track$getMediaStream5 = _track$getMediaStream4.width,
          width = _track$getMediaStream5 === void 0 ? 0 : _track$getMediaStream5,
          _track$getMediaStream6 = _track$getMediaStream4.height,
          height = _track$getMediaStream6 === void 0 ? 0 : _track$getMediaStream6;

      setSize({
        width: width,
        height: height
      });
    });
    return function () {
      track.stop();
      div.remove();
      setVideo(undefined);
    };
  }, [track, setVideo, setSize]);
  return _react.default.createElement(_reactKonva.Image, {
    width: width,
    height: height,
    crop: (0, _canvasUtils.alignImage)(size, {
      width: width,
      height: height
    }),
    image: video
  });
}