var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _spacing = require("../spacing");

function TextContainer(props) {
  var textContainerProps = {};
  return _react.default.createElement(_reactNative.View, textContainerProps, _react.default.Children.map(props.children, function (child, index) {
    var margin;
    margin = index === 0 ? 0 : _spacing.Spacing.base;

    if (props.spacing === 'tight') {
      margin = index === 0 ? 0 : _spacing.Spacing.tight;
    }

    if (props.spacing === 'loose') {
      margin = index === 0 ? 0 : _spacing.Spacing.loose;
    }

    return _react.default.cloneElement(child, {
      style: {
        marginTop: margin
      }
    });
  }));
}

var _default = TextContainer;
exports.default = _default;