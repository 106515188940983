Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LD_VARIATION_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LD_VARIATION_COMPLETED;
  }
});

var _constants = require("./constants");