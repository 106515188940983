var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("babel-polyfill");

var _react = _interopRequireDefault(require("react"));

var _reactNativePaper = require("react-native-paper");

var _reactNativePaperV = require("react-native-paper-v3");

var _reactNativeSafeAreaContext = require("react-native-safe-area-context");

var _FontAwesome = _interopRequireDefault(require("react-native-vector-icons/Fonts/FontAwesome.ttf"));

var _reactNativeWeb = require("react-native-web");

var _reactRedux = require("react-redux");

var _theme = _interopRequireDefault(require("../design-system/theme"));

var _App = _interopRequireDefault(require("../src/App"));

var _GothamNarrowBook = _interopRequireDefault(require("../src/assets/fonts/GothamNarrow-Book.otf"));

var _icomoon = _interopRequireDefault(require("../src/assets/fonts/icomoon.ttf"));

var _ErrorBoundary = require("../src/components/errors/ErrorBoundary");

var _DeferredDeepLinkProvider = _interopRequireDefault(require("../src/components/referral/DeferredDeepLinkProvider"));

var _GetSocialProvider = _interopRequireDefault(require("../src/components/referral/GetSocialProvider"));

var _store = _interopRequireDefault(require("../src/core/store"));

var _theme2 = _interopRequireDefault(require("../src/theme"));

var _ThemeProvider = _interopRequireDefault(require("../src/theme/ThemeProvider"));

require('dotenv').config();

window.__DEV__ = false;

function init() {
  return _react.default.createElement(_reactRedux.Provider, {
    store: _store.default
  }, _react.default.createElement(_reactNativePaperV.Provider, {
    theme: _theme.default
  }, _react.default.createElement(_reactNativePaper.Provider, {
    theme: _theme2.default
  }, _react.default.createElement(_GetSocialProvider.default, null, _react.default.createElement(_DeferredDeepLinkProvider.default, null, _react.default.createElement(_reactNativeSafeAreaContext.SafeAreaProvider, null, _react.default.createElement(_ThemeProvider.default, null, _react.default.createElement(_ErrorBoundary.ErrorBoundary, null, _react.default.createElement(_App.default, null)))))))));
}

var iconFontStyles = "@font-face {\n  src: url(".concat(_FontAwesome.default, ");\n  font-family: FontAwesome;\n}");
var icoMoonFontStyles = "@font-face {\n  src: url(".concat(_icomoon.default, ");\n  font-family: icomoon;\n}");
var GothamNarrowBookFontStyles = "@font-face {\n  src: url(".concat(_GothamNarrowBook.default, ");\n  font-family: GothamNarrow-Book;\n}");
var style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
  style.styleSheet.cssText = icoMoonFontStyles;
  style.styleSheet.cssText = GothamNarrowBookFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
  style.appendChild(document.createTextNode(icoMoonFontStyles));
  style.appendChild(document.createTextNode(GothamNarrowBookFontStyles));
}

document.head.appendChild(style);

_reactNativeWeb.AppRegistry.registerComponent('soundmix', function () {
  return init;
});

_reactNativeWeb.AppRegistry.runApplication('soundmix', {
  initialProps: {},
  rootTag: document.getElementById('root')
});