var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalScheduleScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _festival = _interopRequireDefault(require("../../../../core/festival"));

var _events = _interopRequireDefault(require("../../../../core/events"));

var _moment = _interopRequireDefault(require("moment"));

var _ScheduleEventList = _interopRequireDefault(require("./ScheduleEventList"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var getDates = function getDates(startDate, endDate) {
  var now = (0, _moment.default)().format('DD/MM/YYYY');
  var dateArray = [];
  var start = (0, _moment.default)(startDate);
  var end = (0, _moment.default)(endDate);

  while (start <= end) {
    if (now === (0, _moment.default)(start).format('DD/MM/YYYY')) {
      dateArray.push({
        dayString: (0, _moment.default)(start).format('ddd').toUpperCase(),
        date: (0, _moment.default)(start).format('DD/MM/YYYY'),
        selected: true
      });
    } else {
      dateArray.push({
        dayString: (0, _moment.default)(start).format('ddd').toUpperCase(),
        date: (0, _moment.default)(start).format('DD/MM/YYYY'),
        selected: false
      });
    }

    start = (0, _moment.default)(start).add(1, 'days');
  }

  return dateArray;
};

var FestivalScheduleScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalScheduleScreenInternal, _React$PureComponent);

  var _super = _createSuper(FestivalScheduleScreenInternal);

  function FestivalScheduleScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FestivalScheduleScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      festivalDays: getDates(_this.props.startDate, _this.props.endDate),
      renderFilters: false,
      selectedDate: (0, _moment.default)()
    };

    _this.selectDate = function (item, index) {
      _this.setState({
        renderFilters: false
      });

      var festivalDays = _this.state.festivalDays;

      for (var i = 0; i < festivalDays.length; i++) {
        festivalDays[i].selected = false;
      }

      festivalDays[index].selected = true;

      _this.setState({
        festivalDays: festivalDays
      }, function () {
        return _this.setState({
          renderFilters: true,
          selectedDate: (0, _moment.default)(item.date, 'DD/MM/YYYY')
        });
      });
    };

    return _this;
  }

  (0, _createClass2.default)(FestivalScheduleScreenInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var now = (0, _moment.default)();
      var festivalStartDate = (0, _moment.default)(this.props.startDate);

      if (now.isBefore(festivalStartDate)) {
        this.selectDate(this.state.festivalDays[0], 0);
      }
    }
  }, {
    key: "_renderFilterDay",
    value: function _renderFilterDay(item, index) {
      var _this2 = this;

      var now = (0, _moment.default)();
      var filterParentStyle, textStringStyle, textDigitStyle;

      if (item.selected) {
        filterParentStyle = [styles.filterParentActive, {
          backgroundColor: this.props.festivalThemeColor
        }];
        textStringStyle = styles.textStringActive;
        textDigitStyle = styles.textDigitsActive;
      } else {
        if (now.isAfter((0, _moment.default)(item.date, 'DD/MM/YYYY'))) {
          filterParentStyle = styles.filterParentPassed;
          textStringStyle = styles.textStringPassed;
          textDigitStyle = styles.textDigitsPassed;
        } else if (now.isBefore((0, _moment.default)(item.date, 'DD/MM/YYYY')) || now.isSame((0, _moment.default)(item.date, 'DD/MM/YYYY'))) {
          filterParentStyle = [styles.filterParentFuture, {
            borderColor: this.props.festivalThemeColor
          }];
          textStringStyle = [styles.textStringFuture, {
            color: this.props.festivalThemeColor
          }];
          textDigitStyle = styles.textDigitsFuture;
        }
      }

      return React.createElement(_reactNativePaper.TouchableRipple, {
        name: 'filterDayButton' + index,
        borderless: true,
        style: filterParentStyle,
        key: index,
        onPress: function onPress() {
          return _this2.selectDate(item, index);
        }
      }, React.createElement(_reactNative.View, null, React.createElement(_reactNative.Text, {
        style: textStringStyle
      }, item.dayString), React.createElement(_reactNative.Text, {
        style: textDigitStyle
      }, (0, _moment.default)(item.date, 'DD/MM/YYYY').date())));
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      return React.createElement(React.Fragment, null, React.createElement(_reactNative.ScrollView, {
        style: styles.container,
        contentContainerStyle: {}
      }, React.createElement(_reactNative.ScrollView, {
        horizontal: true,
        showsHorizontalScrollIndicator: false,
        directionalLockEnabled: true,
        pinchGestureEnabled: false,
        contentContainerStyle: {
          flexGrow: 1,
          justifyContent: 'center'
        }
      }, this.state.festivalDays.map(function (el, index) {
        return _this3._renderFilterDay(el, index);
      })), React.createElement(_ScheduleEventList.default, {
        date: this.state.selectedDate,
        festivalId: this.props.festivalId
      })));
    }
  }]);
  return FestivalScheduleScreenInternal;
}(React.PureComponent);

exports.FestivalScheduleScreenInternal = FestivalScheduleScreenInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  },
  filterParentActive: {
    width: 52,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginHorizontal: 4
  },
  filterParentFuture: {
    width: 52,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: 4,
    borderWidth: 1,
    marginHorizontal: 4
  },
  filterParentPassed: {
    width: 52,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: 4,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    borderWidth: 1,
    marginHorizontal: 4
  },
  textStringActive: {
    fontSize: 11,
    textAlign: 'center',
    paddingBottom: 4
  },
  textStringFuture: {
    fontSize: 11,
    textAlign: 'center',
    paddingBottom: 4
  },
  textStringPassed: {
    fontSize: 11,
    textAlign: 'center',
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.6)'
  },
  textDigitsActive: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  textDigitsFuture: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white'
  },
  textDigitsPassed: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white'
  }
});

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;
  return {
    startDate: _festival.default.selectors.getStartDate(state, id),
    endDate: _events.default.selectors.getFestivalEndDate(state, id),
    festivalThemeColor: _festival.default.selectors.getThemeColor(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(FestivalScheduleScreenInternal);

exports.default = _default;