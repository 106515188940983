var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleLeave;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function HandleLeave() {
  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      leaveAndRemoveTracks = _useAudioVideoContext.leaveAndRemoveTracks;

  var leaveRef = _react.default.useRef(leaveAndRemoveTracks);

  _react.default.useEffect(function () {
    leaveRef.current = leaveAndRemoveTracks;
  }, [leaveAndRemoveTracks]);

  _react.default.useEffect(function () {
    return function () {
      leaveRef.current();
    };
  }, []);

  return null;
}