var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCustomRegistration = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _firebaseUtils = require("../../utils/firebase-utils");

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getCustomRegistration = (0, _toolkit.createAsyncThunk)(_constants.GET_FIELDS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_ref) {
    var eventId, selectedTicket, registrationFormId, path, formFields;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            eventId = _ref.eventId, selectedTicket = _ref.selectedTicket;

            if (eventId) {
              _context.next = 3;
              break;
            }

            throw Error('No event!');

          case 3:
            registrationFormId = selectedTicket && selectedTicket.registrationFormId ? selectedTicket.registrationFormId : '--default--';
            path = _firebaseUtils.firebaseEntities.customRegistration(eventId, registrationFormId);
            _context.next = 7;
            return (0, _firebaseUtils.collectionPromise)(path);

          case 7:
            formFields = _context.sent;
            return _context.abrupt("return", formFields.map(function (f) {
              return _objectSpread(_objectSpread({}, f), {}, {
                registrationFormId: registrationFormId
              });
            }));

          case 9:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getCustomRegistration = getCustomRegistration;