var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventCard;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _eventCardStyles = _interopRequireDefault(require("./event-card-styles"));

var moment = require('moment-timezone');

function EventCard(_ref) {
  var _event$image;

  var event = _ref.event;
  var Styles = (0, _eventCardStyles.default)();
  var domain = "".concat(window.location.protocol, "//").concat(window.location.host);
  var startDate = moment(event.startDate.toDate()).tz(event.timezone).format('Do MMMM YYYY hh:mm a');
  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    justifyContent: "center",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "stretch",
    style: Styles.innerContainer
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    color: "containerBackground",
    style: (Styles.content, Styles.medium)
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    onPress: function onPress() {
      return window.location.replace("".concat(domain, "/").concat(event.id));
    }
  }, _react.default.createElement(_reactNative.Image, {
    resizeMode: 'cover',
    style: Styles.image,
    source: {
      uri: (((_event$image = event.image) === null || _event$image === void 0 ? void 0 : _event$image.url) == null ? event.image : event.image.url) || ''
    }
  })), _react.default.createElement(_Spacing.default, {
    mSpacing: ['l'],
    alignItems: "stretch",
    style: [Styles.infoContainer, Styles.smallinfoContainer]
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 's', 0],
    direction: "column",
    alignItems: "stretch",
    style: Styles.infoDescription
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 's', 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "h4"
  }, startDate)), _react.default.createElement(_Spacing.default, null, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, event.name)))))));
}