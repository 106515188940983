var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FooterView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _footerStyles = _interopRequireDefault(require("./footer-styles"));

function FooterView(props) {
  return _react.default.createElement(_reactNative.View, {
    style: [_footerStyles.default.container, props.direction !== 'column' ? _footerStyles.default.row : _footerStyles.default.column]
  }, props.children);
}