var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScreenShareWithParticipantsLayoutStrategy = exports.GridLayoutForManyParticipantsStrategy = exports.GridLayoutStrategy = exports.GRID_TYPES = exports.LAYOUT_TYPES = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _chunk = _interopRequireDefault(require("lodash/chunk"));

var _remove = _interopRequireDefault(require("lodash/remove"));

var _agoraConstants = require("../../../constants/agora-constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LAYOUT_TYPES = {
  GRID_LAYOUT: 'GRID_LAYOUT',
  SCREEN_SHARE_WITH_PARTICIPANTS_LAYOUT: 'SCREEN_SHARE_WITH_PARTICIPANTS_LAYOUT'
};
exports.LAYOUT_TYPES = LAYOUT_TYPES;
var GRID_TYPES = {
  PARTICIPANT: 'PARTICIPANT',
  SCREEN_SHARE: 'SCREEN_SHARE',
  REMAINED_PARTICIPANTS: 'REMAINED_PARTICIPANTS'
};
exports.GRID_TYPES = GRID_TYPES;

var GridLayoutStrategy = function () {
  function GridLayoutStrategy() {
    var padding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var br = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
    (0, _classCallCheck2.default)(this, GridLayoutStrategy);
    this.TYPE = LAYOUT_TYPES.GRID_LAYOUT;
    this.padding = 0;
    this.breakpoint = 'default';
    this.layout = {
      1: {
        default: {
          rowLength: 1,
          rows: 1
        }
      },
      2: {
        xs: {
          rowLength: 1,
          rows: 2
        },
        xsm: {
          rowLength: 1,
          rows: 2
        },
        sm: {
          rowLength: 1,
          rows: 2
        },
        default: {
          rowLength: 2,
          rows: 1
        }
      },
      3: {
        xs: {
          rowLength: 1,
          rows: 3
        },
        xsm: {
          rowLength: 1,
          rows: 3
        },
        sm: {
          rowLength: 1,
          rows: 3
        },
        default: {
          rowLength: 2,
          rows: 2
        }
      },
      4: {
        xs: {
          rowLength: 1,
          rows: 4
        },
        xsm: {
          rowLength: 1,
          rows: 4
        },
        sm: {
          rowLength: 1,
          rows: 4
        },
        default: {
          rowLength: 2,
          rows: 2
        }
      },
      5: {
        xs: {
          rowLength: 2,
          rows: 3
        },
        xsm: {
          rowLength: 2,
          rows: 3
        },
        sm: {
          rowLength: 2,
          rows: 3
        },
        default: {
          rowLength: 3,
          rows: 2
        }
      },
      6: {
        xs: {
          rowLength: 2,
          rows: 3
        },
        xsm: {
          rowLength: 2,
          rows: 3
        },
        default: {
          rowLength: 3,
          rows: 2
        }
      },
      7: {
        xs: {
          rowLength: 2,
          rows: 4
        },
        xsm: {
          rowLength: 2,
          rows: 4
        },
        default: {
          rowLength: 3,
          rows: 3
        }
      },
      8: {
        xs: {
          rowLength: 2,
          rows: 4
        },
        xsm: {
          rowLength: 2,
          rows: 4
        },
        default: {
          rowLength: 3,
          rows: 3
        }
      },
      9: {
        xs: {
          rowLength: 2,
          rows: 5
        },
        xsm: {
          rowLength: 2,
          rows: 5
        },
        default: {
          rowLength: 3,
          rows: 3
        }
      }
    };
    this.padding = padding;
    this.breakpoint = br;
  }

  (0, _createClass2.default)(GridLayoutStrategy, [{
    key: "setPadding",
    value: function setPadding(padding) {
      this.padding = padding;
    }
  }, {
    key: "setBreakpoint",
    value: function setBreakpoint(br) {
      this.breakpoint = br;
    }
  }, {
    key: "calculate",
    value: function calculate(items, containerWidth) {
      var _this = this;

      if (items.length > Object.keys(this.layout).sort(function (a, b) {
        return a > b ? -1 : 1;
      })[0]) {
        return new GridLayoutForManyParticipantsStrategy(this.padding, this.breakpoint).calculate(items, containerWidth);
      }

      var containerHeight = containerWidth / _agoraConstants.ASPECT_RATIO;
      var currentLayout = this.layout[items.length] || {
        default: function () {
          var _rowLength = Math.round(Math.sqrt(items.length));

          var _rows = (0, _chunk.default)(items, _rowLength).length;
          return {
            rowLength: _rowLength,
            rows: _rows
          };
        }(),
        xs: {
          rowLength: 2,
          rows: Math.round(items.length / 2)
        },
        xsm: {
          rowLength: 2,
          rows: Math.round(items.length / 2)
        }
      };

      var _ref = currentLayout[this.breakpoint] || currentLayout['default'],
          rowLength = _ref.rowLength,
          rows = _ref.rows;

      var elementWidth = (containerWidth - this.padding * (rowLength - 1)) / rowLength;
      var elementHeight = elementWidth / _agoraConstants.ASPECT_RATIO;
      var newContainerHeight = elementHeight * rows + (rows - 1) * this.padding;
      var result = (0, _chunk.default)(items, rowLength).reduce(function (grids, row, rowIndex) {
        var remainingCount = rowLength - row.length;
        var remainingWidth = Math.max(remainingCount * elementWidth + remainingCount * _this.padding, 0);
        var remainingHeight = Math.max(containerHeight - newContainerHeight, 0);
        row.forEach(function (_ref2, elementIndex) {
          var id = _ref2.id;
          var x = elementIndex * elementWidth + elementIndex * _this.padding;
          var y = rowIndex * elementHeight + rowIndex * _this.padding;

          if (remainingWidth) {
            x += remainingWidth / 2;
          }

          if (remainingHeight) {
            y += remainingHeight / 2;
          }

          grids.push({
            id: id,
            width: elementWidth,
            height: elementHeight,
            x: x,
            y: y
          });
        });
        return grids;
      }, []);
      return {
        grids: result.map(round),
        containerWidth: containerWidth,
        containerHeight: Math.max(newContainerHeight, containerHeight),
        type: this.TYPE
      };
    }
  }]);
  return GridLayoutStrategy;
}();

exports.GridLayoutStrategy = GridLayoutStrategy;

var GridLayoutForManyParticipantsStrategy = function () {
  function GridLayoutForManyParticipantsStrategy() {
    var padding = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var br = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
    (0, _classCallCheck2.default)(this, GridLayoutForManyParticipantsStrategy);
    this.TYPE = LAYOUT_TYPES.GRID_LAYOUT;
    this.padding = 0;
    this.breakpoint = 'default';
    this.layout = {
      10: {
        xs: {
          rowLength: 2,
          rows: 5
        },
        xsm: {
          rowLength: 2,
          rows: 5
        },
        default: {
          rowLength: 4,
          rows: 3
        }
      },
      11: {
        xs: {
          rowLength: 2,
          rows: 6
        },
        xsm: {
          rowLength: 2,
          rows: 6
        },
        default: {
          rowLength: 4,
          rows: 3
        }
      },
      12: {
        xs: {
          rowLength: 2,
          rows: 6
        },
        xsm: {
          rowLength: 2,
          rows: 6
        },
        default: {
          rowLength: 4,
          rows: 3
        }
      },
      13: {
        xs: {
          rowLength: 2,
          rows: 7
        },
        xsm: {
          rowLength: 2,
          rows: 5
        },
        default: {
          rowLength: 5,
          rows: 3
        }
      },
      14: {
        xs: {
          rowLength: 2,
          rows: 7
        },
        xsm: {
          rowLength: 2,
          rows: 5
        },
        default: {
          rowLength: 5,
          rows: 3
        }
      },
      15: {
        xs: {
          rowLength: 2,
          rows: 8
        },
        xsm: {
          rowLength: 2,
          rows: 8
        },
        default: {
          rowLength: 5,
          rows: 3
        }
      }
    };
    this.padding = padding;
    this.breakpoint = br;
  }

  (0, _createClass2.default)(GridLayoutForManyParticipantsStrategy, [{
    key: "setPadding",
    value: function setPadding(padding) {
      this.padding = padding;
    }
  }, {
    key: "setBreakpoint",
    value: function setBreakpoint(br) {
      this.breakpoint = br;
    }
  }, {
    key: "calculate",
    value: function calculate(_items, containerWidth) {
      var _this2 = this;

      var items = _items.map(function (_ref3) {
        var id = _ref3.id;
        return id;
      });

      var containerHeight = containerWidth / _agoraConstants.ASPECT_RATIO;
      var currentLayout = this.layout[items.length] || {
        default: function () {
          var _rowLength = Math.round(Math.sqrt(items.length));

          var _rows = (0, _chunk.default)(items, _rowLength).length;
          return {
            rowLength: _rowLength,
            rows: _rows
          };
        }(),
        xs: {
          rowLength: 2,
          rows: Math.round(items.length / 2)
        },
        xsm: {
          rowLength: 2,
          rows: Math.round(items.length / 2)
        }
      };

      var _ref4 = currentLayout[this.breakpoint] || currentLayout['default'],
          rowLength = _ref4.rowLength,
          rows = _ref4.rows;

      var numbOfRowsWithLessParticipants = items.length % rows > 0 ? rows - items.length % rows : items.length % rows;
      var smallerElementWidth = (containerWidth - this.padding * (rowLength - 1)) / rowLength;
      var smallerElementHeight = smallerElementWidth / _agoraConstants.ASPECT_RATIO;
      var biggerElementWidth = (containerWidth - this.padding * (rowLength - 2)) / (rowLength - 1);
      var biggerElementHeight = biggerElementWidth / _agoraConstants.ASPECT_RATIO;
      var newContainerHeight = smallerElementHeight * (rows - numbOfRowsWithLessParticipants) + biggerElementHeight * numbOfRowsWithLessParticipants + (rows - 1) * this.padding;
      var result = this.chunkToRows(items, rowLength, rows, numbOfRowsWithLessParticipants).reduce(function (grids, row, rowIndex) {
        var elementWidth = row.length < rowLength ? biggerElementWidth : smallerElementWidth;
        var elementHeight = row.length < rowLength ? biggerElementHeight : smallerElementHeight;
        var remainingHeight = Math.max(containerHeight - newContainerHeight, 0);
        row.forEach(function (id, elementIndex) {
          var numberOfSmallRows = rows - numbOfRowsWithLessParticipants;
          var numbOfSmallRowsBeforeActualRow = rowIndex >= numberOfSmallRows ? numberOfSmallRows : rowIndex;
          var numbOfBigRowsBeforeActualRow = rowIndex >= numberOfSmallRows ? rowIndex - numberOfSmallRows : 0;
          var x = elementIndex * elementWidth + elementIndex * _this2.padding;
          var heightOfSmallRowsBeforeActualRow = numbOfSmallRowsBeforeActualRow > 0 ? numbOfSmallRowsBeforeActualRow * smallerElementHeight : 0;
          var heightOfBigRowsBeforeActualRow = numbOfBigRowsBeforeActualRow > 0 ? numbOfBigRowsBeforeActualRow * biggerElementHeight : 0;
          var y = heightOfSmallRowsBeforeActualRow + heightOfBigRowsBeforeActualRow + rowIndex * _this2.padding;

          if (remainingHeight) {
            y += remainingHeight / 2;
          }

          grids.push({
            id: id,
            width: elementWidth,
            height: elementHeight,
            x: x,
            y: y
          });
        });
        return grids;
      }, []);
      return {
        grids: result.map(round),
        containerWidth: containerWidth,
        containerHeight: Math.max(newContainerHeight, containerHeight),
        type: this.TYPE
      };
    }
  }, {
    key: "chunkToRows",
    value: function chunkToRows(items, rowLength, numbOfRows, numbOfRowsWithLessParticipants) {
      return items.reduce(function (rows, item, index) {
        var numbOfRowsWithFullParticipants = numbOfRows - numbOfRowsWithLessParticipants;
        var realRowLength = index >= numbOfRowsWithFullParticipants * rowLength ? rowLength - 1 : rowLength;
        var rowIndex = index < numbOfRowsWithFullParticipants * rowLength ? Math.floor(index / rowLength) : Math.floor((index - numbOfRowsWithFullParticipants) / (rowLength - 1));

        if (!rows[rowIndex]) {
          rows[rowIndex] = [];
        }

        rows[rowIndex].push(item);
        return rows;
      }, []);
    }
  }]);
  return GridLayoutForManyParticipantsStrategy;
}();

exports.GridLayoutForManyParticipantsStrategy = GridLayoutForManyParticipantsStrategy;

function round(grid) {
  return _objectSpread(_objectSpread({}, grid), {}, {
    width: Math.max(1, Math.floor(grid.width)),
    height: Math.max(1, Math.floor(grid.height)),
    x: Math.max(1, Math.floor(grid.x)),
    y: Math.max(1, Math.floor(grid.y))
  });
}

var ScreenShareWithParticipantsLayoutStrategy = function () {
  function ScreenShareWithParticipantsLayoutStrategy() {
    (0, _classCallCheck2.default)(this, ScreenShareWithParticipantsLayoutStrategy);
    this.TYPE = LAYOUT_TYPES.SCREEN_SHARE_WITH_PARTICIPANTS_LAYOUT;
    this.padding = 0;
    this.breakpoint = 'default';
  }

  (0, _createClass2.default)(ScreenShareWithParticipantsLayoutStrategy, [{
    key: "setPadding",
    value: function setPadding(padding) {
      this.padding = padding;
    }
  }, {
    key: "setBreakpoint",
    value: function setBreakpoint(br) {
      this.breakpoint = br;
    }
  }, {
    key: "calculate",
    value: function calculate(items, containerWidth) {
      var _items = (0, _toConsumableArray2.default)(items);

      var containerHeight = containerWidth / _agoraConstants.ASPECT_RATIO;
      var screenShareHeight = containerHeight * _agoraConstants.SCREEN_SHARE.MAIN_PORTION;
      var screenShareWidth = screenShareHeight * _agoraConstants.ASPECT_RATIO;
      var participantWidth = containerWidth * _agoraConstants.SCREEN_SHARE.PARTICIPANTS_PORTION;
      var participantsHeight = containerHeight * _agoraConstants.SCREEN_SHARE.PARTICIPANTS_PORTION;

      var screenSharer = _items.find(function (_ref5) {
        var screenShareId = _ref5.screenShareId;
        return Boolean(screenShareId);
      });

      if (!screenSharer) {
        return new GridLayoutStrategy(this.padding, this.breakpoint).calculate(items, containerWidth);
      } else {
        (0, _remove.default)(_items, function (_ref6) {
          var screenShareId = _ref6.screenShareId;
          return Boolean(screenShareId);
        });
      }

      var grids = [];
      grids.push({
        id: screenSharer.screenShareId,
        type: GRID_TYPES.SCREEN_SHARE,
        height: screenShareHeight - this.padding,
        width: screenShareWidth,
        x: (containerWidth - screenShareWidth) / 2,
        y: 0
      });
      grids.push({
        id: screenSharer.id,
        type: GRID_TYPES.PARTICIPANT,
        height: participantsHeight,
        width: participantWidth,
        y: screenShareHeight,
        x: containerWidth - participantWidth
      });
      grids.push.apply(grids, (0, _toConsumableArray2.default)((0, _remove.default)(_items, function (_, index) {
        return index < _agoraConstants.SCREEN_SHARE.VISIBLE_PARTICIPANTS - 1;
      }).map(function (_ref7, index) {
        var id = _ref7.id;
        return {
          id: id,
          type: GRID_TYPES.PARTICIPANT,
          height: participantsHeight,
          width: participantWidth,
          y: screenShareHeight,
          x: containerWidth - participantWidth * (index + 2)
        };
      })));

      if (_items.length) {
        grids.push({
          id: _agoraConstants.REMAINED_PARTICIPANTS,
          type: GRID_TYPES.REMAINED_PARTICIPANTS,
          height: participantsHeight,
          width: participantWidth,
          y: screenShareHeight,
          x: 0,
          remainedParticipants: _items
        });
      }

      return {
        grids: grids,
        containerHeight: containerHeight,
        containerWidth: containerWidth,
        type: this.TYPE
      };
    }
  }]);
  return ScreenShareWithParticipantsLayoutStrategy;
}();

exports.ScreenShareWithParticipantsLayoutStrategy = ScreenShareWithParticipantsLayoutStrategy;