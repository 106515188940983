var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectUnnaAssignedTickets = exports.selectAssignedTickets = exports.selectBoughtTickets = exports.selectBoughtTicketsLoading = exports.selectPendingTicketForEvent = exports.selectAccessRecordedSessions = exports.selectAccessByStage = exports.selectAccessByDay = exports.selectUserRights = exports.selectRightsIsLoaded = exports.selectRightsIsLoading = exports.selectEventTicketIds = exports.selectTicketsForEvent = exports.userHasTicketsForEvent = exports.selectTicketsIsLoaded = exports.selectTicketsIsLoading = exports.selectTicketsList = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _selectors = require("../event-info/selectors");

var _constants = require("./constants");

var _reducer = require("./reducer");

var _selectors2 = require("../team/selectors");

var _selectors3 = require("../user-roles/selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var selectTicketsState = function selectTicketsState(state) {
  return state[_constants.NAME];
};

var selectTicketsList = _reducer.ticketsEntityAdapter.getSelectors(selectTicketsState).selectAll;

exports.selectTicketsList = selectTicketsList;
var selectTicketsIsLoading = (0, _toolkit.createSelector)(selectTicketsState, function (state) {
  return state.loading;
});
exports.selectTicketsIsLoading = selectTicketsIsLoading;
var selectTicketsIsLoaded = (0, _toolkit.createSelector)(selectTicketsState, function (state) {
  return state.loaded;
});
exports.selectTicketsIsLoaded = selectTicketsIsLoaded;
var userHasTicketsForEvent = (0, _toolkit.createSelector)(selectTicketsList, _selectors.selectEventInfoId, function (tickets, eventId) {
  return !!tickets.filter(function (ticket) {
    return (ticket === null || ticket === void 0 ? void 0 : ticket.eventId) === eventId && !(ticket !== null && ticket !== void 0 && ticket.isPending);
  }).length;
});
exports.userHasTicketsForEvent = userHasTicketsForEvent;
var selectTicketsForEvent = (0, _toolkit.createSelector)(selectTicketsList, _selectors.selectEventInfoId, function (tickets, eventId) {
  return tickets.filter(function (ticket) {
    return ticket.eventId === eventId;
  });
});
exports.selectTicketsForEvent = selectTicketsForEvent;
var selectEventTicketIds = (0, _toolkit.createSelector)(selectTicketsList, function (tickets) {
  return tickets.map(function (ticket) {
    return ticket.ticketId;
  });
});
exports.selectEventTicketIds = selectEventTicketIds;
var selectRightsIsLoading = (0, _toolkit.createSelector)(selectTicketsState, function (state) {
  return state.rightsLoading;
});
exports.selectRightsIsLoading = selectRightsIsLoading;
var selectRightsIsLoaded = (0, _toolkit.createSelector)(selectTicketsState, function (state) {
  return state.rightsLoaded;
});
exports.selectRightsIsLoaded = selectRightsIsLoaded;
var selectUserRights = (0, _toolkit.createSelector)(selectTicketsForEvent, function (tickets) {
  return tickets.filter(function (ticket) {
    return ticket.rights;
  }).map(function (ticket) {
    return ticket.rights;
  });
});
exports.selectUserRights = selectUserRights;
var selectAccessByDay = (0, _toolkit.createSelector)(selectUserRights, _selectors.selectEventInfo, function (rights, eventInfo) {
  var now = (0, _momentTimezone.default)();
  return !!rights.filter(function (right) {
    return right.selectedDays.reduce(function (accum, day) {
      var start = _momentTimezone.default.tz(day.name, eventInfo.timezone).startOf('day');

      var end = _momentTimezone.default.tz(day.name, eventInfo.timezone).endOf('day');

      return accum || now.isBetween(start, end, null, '[]');
    }, false);
  }).length;
});
exports.selectAccessByDay = selectAccessByDay;
var selectAccessByStage = (0, _toolkit.createSelector)(selectUserRights, function (_, stageId) {
  return stageId;
}, function (rights, stageId) {
  if (!stageId) return false;
  return !!rights.filter(function (right) {
    return right.selectedStages.map(function (stage) {
      return stage.id;
    }).includes(stageId);
  }).length;
});
exports.selectAccessByStage = selectAccessByStage;
var selectAccessRecordedSessions = (0, _toolkit.createSelector)(_selectors3.userRolesSelector, selectUserRights, _selectors2.isStaffMember, function (userRoles, rights, staffMember) {
  return (userRoles === null || userRoles === void 0 ? void 0 : userRoles.length) || staffMember || rights.reduce(function (accum, right) {
    return accum || right.showAllRecordedSessions;
  }, false);
});
exports.selectAccessRecordedSessions = selectAccessRecordedSessions;
var selectPendingTicketForEvent = (0, _toolkit.createSelector)(selectTicketsList, _selectors.selectEventInfoId, function (tickets, eventId) {
  return tickets.find(function (ticket) {
    return ticket.eventId === eventId && ticket.isPending === true;
  });
});
exports.selectPendingTicketForEvent = selectPendingTicketForEvent;
var selectBoughtTicketsLoading = (0, _toolkit.createSelector)(selectTicketsState, function (state) {
  return state.boughtTicketsLoading;
});
exports.selectBoughtTicketsLoading = selectBoughtTicketsLoading;

var selectBoughtTickets = function selectBoughtTickets(eventTickets) {
  return (0, _toolkit.createSelector)(selectTicketsState, function (state) {
    return state.boughtTickets.map(function (ticket) {
      var ticketInfo = eventTickets.find(function (eventTicket) {
        return eventTicket.id === ticket.ticketId;
      });
      return _objectSpread(_objectSpread({}, ticket), {}, {
        ticketInfo: ticketInfo
      });
    });
  });
};

exports.selectBoughtTickets = selectBoughtTickets;

var selectAssignedTickets = function selectAssignedTickets(eventTickets) {
  return (0, _toolkit.createSelector)(selectTicketsState, function (state) {
    return state.boughtTickets.filter(function (ticket) {
      return ticket.assigned;
    }).map(function (ticket) {
      var ticketInfo = eventTickets.find(function (eventTicket) {
        return eventTicket.id === ticket.ticketId;
      });
      return _objectSpread(_objectSpread({}, ticket), {}, {
        ticketInfo: ticketInfo
      });
    });
  });
};

exports.selectAssignedTickets = selectAssignedTickets;

var selectUnnaAssignedTickets = function selectUnnaAssignedTickets(eventTickets) {
  return (0, _toolkit.createSelector)(selectTicketsState, function (state) {
    return state.boughtTickets.filter(function (ticket) {
      return !ticket.assigned;
    }).map(function (ticket) {
      var ticketInfo = eventTickets.find(function (eventTicket) {
        return eventTicket.id === ticket.ticketId;
      });
      return _objectSpread(_objectSpread({}, ticket), {}, {
        ticketInfo: ticketInfo
      });
    });
  });
};

exports.selectUnnaAssignedTickets = selectUnnaAssignedTickets;