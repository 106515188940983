var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _mybeatsScreenView = _interopRequireDefault(require("./mybeats-screen-view"));

var _mybeatsInfoScreenView = _interopRequireDefault(require("./mybeats-info-screen-view"));

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function MyBeatsScreenContainer(props) {
  var MY_BEATS_SCREEN_PROPERTIES = _gameSparks.default.constants.MY_BEATS_SCREEN_PROPERTIES;
  var loadGsPlayer = props.loadGsPlayer,
      getGsPropertySet = props.getGsPropertySet,
      isDj = props.isDj;
  return _react.default.createElement(_react.default.Fragment, null, isDj ? _react.default.createElement(_mybeatsScreenView.default, props) : _react.default.createElement(_mybeatsInfoScreenView.default, null));
}

var _default = MyBeatsScreenContainer;
exports.default = _default;