var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _useLiveSessionState = require("../../../utils/hooks/useLiveSessionState");

var _reactNative = require("react-native");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _DynamicSection = _interopRequireDefault(require("../../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _dateFormatter = require("../../../utils/date-formatter");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _liveNowView = _interopRequireDefault(require("./live-now/live-now-view"));

var _videoTypeConstants = require("../../../constants/video-type-constants");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _liveNowPageStyles = _interopRequireDefault(require("./live-now-page-styles"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _nextSessionCountdown = _interopRequireDefault(require("../../live/components/next-session-view/next-session-countdown"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LiveNowSinglePreview = function LiveNowSinglePreview(_ref) {
  var stage = _ref.stage,
      _ref$showTitleSection = _ref.showTitleSection,
      showTitleSection = _ref$showTitleSection === void 0 ? true : _ref$showTitleSection;
  var Styles = (0, _liveNowPageStyles.default)();
  var navigation = (0, _useNavigationExtended.default)();
  var live = (0, _useLiveSessionState.useLiveSessionState)(stage);
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape;

  var currentSession = (live === null || live === void 0 ? void 0 : live.currentSession) || null;
  var nextSession = (live === null || live === void 0 ? void 0 : live.nextSession) || null;

  var _renderLiveNow = function _renderLiveNow(session) {
    var _stage$feeds$;

    var thumbnailUrl = session.thumbnailUrl || ((_stage$feeds$ = stage.feeds[0]) === null || _stage$feeds$ === void 0 ? void 0 : _stage$feeds$.thumbnailImage);
    return _react.default.createElement(_liveNowView.default, {
      onPress: function onPress() {
        switch (session.videoType) {
          case _videoTypeConstants.LECTURE:
          case _videoTypeConstants.BREAK:
            navigation.navigate(_routesConsts.Routes.EVENT_LIVE, {
              liveId: stage.id
            });
            break;

          case _videoTypeConstants.NETWORKING:
            if (session.networkTable === _videoTypeConstants.NETWORKING_AREA) {
              navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
            } else {
              navigation.navigate(_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
                tableId: session.networkTable
              });
            }

            break;

          default:
            break;
        }
      },
      key: stage.id,
      stage: stage.name,
      posterUrl: "".concat(thumbnailUrl, "?start=").concat((0, _dateFormatter.getPosterStartPosition)(session.start), "&fps=7"),
      sessionTitle: session.title,
      sessionDescription: session.description,
      starting: (0, _dateFormatter.printMinuteDifference)(session.start, session.end, eventInfo.dateFormat),
      videoType: session.videoType,
      showTitleSection: showTitleSection,
      mode: "live"
    });
  };

  var _renderLiveNext = function _renderLiveNext(session) {
    return _react.default.createElement(_liveNowView.default, {
      onPress: function onPress() {
        switch (session.videoType) {
          case _videoTypeConstants.LECTURE:
          case _videoTypeConstants.BREAK:
            navigation.navigate(_routesConsts.Routes.EVENT_LIVE, {
              liveId: stage.id
            });
            break;

          case _videoTypeConstants.NETWORKING:
            if (session.networkTable === _videoTypeConstants.NETWORKING_AREA) {
              navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
            } else {
              navigation.navigate(_routesConsts.Routes.NETWORKING_TABLE_LIVE_PAGE, {
                tableId: session.networkTable
              });
            }

            break;

          default:
            break;
        }
      },
      key: stage.id,
      reRender: "minute",
      stage: stage.name,
      poster: _react.default.createElement(_reactNative.ImageBackground, {
        source: eventInfo.bannerUrl,
        style: [Styles.imgBackground, {
          width: '100%',
          height: 0,
          paddingBottom: '56.25%',
          borderRadius: shape.cards.borderRadius
        }],
        imageStyle: Styles.imageStyle
      }, _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "center",
        justifyContent: "center",
        style: [_objectSpread(_objectSpread({}, _reactNative.StyleSheet.absoluteFillObject), {}, {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          borderRadius: shape.cards.borderRadius
        })]
      }, _react.default.createElement(_nextSessionCountdown.default, {
        session: session,
        textColor: '#FFFFFF',
        role: "thumbnail"
      }))),
      sessionTitle: session.title,
      sessionDescription: session.description,
      starting: "".concat((0, _dateFormatter.printMinuteDifference)(session.start, session.end, eventInfo.dateFormat)),
      videoType: session.videoType,
      showTitleSection: showTitleSection,
      mode: "upcoming"
    });
  };

  var _renderLiveEnd = _react.default.createElement(_liveNowView.default, {
    key: stage.id,
    stage: stage.name,
    poster: _react.default.createElement(_DynamicSection.default, {
      parentBackground: "pageBackground",
      lightColor: "#FFFFFF",
      darkColor: "#000000",
      shaped: true,
      shaping: "cards",
      style: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%'
      }
    }, function (textColor) {
      return _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "center",
        justifyContent: "center",
        style: _objectSpread({}, _reactNative.StyleSheet.absoluteFillObject)
      }, _react.default.createElement(_Spacing.default, {
        mSpacing: ['s', 0]
      }, _react.default.createElement(_Typography.default, {
        variant: "description",
        color: textColor
      }, _i18n.default.t('liveNow.sessionsEnded'))));
    }),
    dateFormatter: function dateFormatter() {
      return '';
    },
    showTitleSection: showTitleSection,
    mode: "ended"
  });

  if (currentSession) {
    return _renderLiveNow(currentSession);
  } else if (nextSession) {
    return _renderLiveNext(nextSession);
  } else {
    return _renderLiveEnd;
  }
};

var _default = LiveNowSinglePreview;
exports.default = _default;