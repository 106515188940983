Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useSessionListStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    addToCalendarButton: {
      marginLeft: theme.spacing.s,
      marginRight: theme.spacing.s,
      marginTop: theme.spacing.s,
      marginBottom: theme.spacing.s,
      paddingLeft: theme.spacing.m,
      zIndex: 99
    },
    descriptionText: {
      borderColor: theme.colors.alternativeBackground,
      borderStyle: 'solid',
      borderWidth: 1,
      marginBottom: theme.spacing.xs
    },
    description: {
      marginVertical: theme.spacing.s,
      fontSize: theme.fontSize
    },
    avatars: {
      alignItems: 'flex-start'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    row: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textContainer: {
      marginLeft: theme.spacing.s,
      marginRight: theme.spacing.s,
      marginTop: theme.spacing.s,
      marginBottom: theme.spacing.s,
      alignItems: 'center',
      justifyContent: 'center'
    },
    avatars: {
      alignItems: 'center'
    },
    addToCalendarButton: {
      paddingLeft: theme.spacing.none
    }
  };
}).buildHook();
var _default = useSessionListStyles;
exports.default = _default;