var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MyBeatsCardView;

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _Card = _interopRequireDefault(require("../../../../design-system/structure/Card"));

var _Stack = _interopRequireDefault(require("../../../../design-system/structure/Stack"));

var _textView = _interopRequireDefault(require("../../../../design-system/typography/text-view"));

var _button = _interopRequireDefault(require("../../../../design-system/actions/button"));

var _iconButton = _interopRequireDefault(require("../../../../design-system/actions/icon-button"));

var _section = _interopRequireDefault(require("../../../../design-system/structure/section"));

var _Analytics = require("../../../core/services/Analytics");

function MyBeatsCardView(props) {
  var navigation = (0, _reactNavigationHooks.useNavigation)();
  var beats = props.userId !== null && (props.beatsBalance || props.beatsBalance === 0) ? props.beatsBalance : undefined;
  var beatsThisMonth = props.userId !== null && (props.beatsThisMonth || props.beatsThisMonth === 0) ? props.beatsThisMonth : undefined;
  var totalBalance = props.userId !== null && (props.totalBalance || props.totalBalance === 0) ? props.totalBalance : undefined;

  var handleWithdrawModal = function handleWithdrawModal() {
    navigation.navigate('WithdrawModal', {
      beatsBalance: beats,
      userId: props.userId,
      userName: props.userName
    });
    (0, _Analytics.trackEvent)('withdraw_press');
  };

  var handleLearnMore = function handleLearnMore() {
    return navigation.navigate('MyBeatsInfo');
  };

  return _react.default.createElement(_Card.default, {
    primary: true
  }, _react.default.createElement(_Card.default.Title, {
    title: "My Beats",
    titleIcon: "beats",
    right: function right() {
      return _react.default.createElement(_iconButton.default, {
        icon: "info-outline",
        inverted: true,
        onPress: handleLearnMore
      });
    }
  }), _react.default.createElement(_Card.default.Content, null, _react.default.createElement(_textView.default, {
    inverted: true
  }, "Beats are the way DJs make cash using Soundmix"), _react.default.createElement(_section.default, {
    spacingTop: "tight"
  }, _react.default.createElement(_Stack.default, null, _react.default.createElement(_Stack.default.Item, {
    fill: true
  }, _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, "All Time Balance:")), _react.default.createElement(_Stack.default.Item, null, totalBalance !== undefined ? _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, totalBalance, " B") : _react.default.createElement(_textView.default, {
    inverted: true
  }, "--")))), _react.default.createElement(_section.default, {
    spacingTop: "tight"
  }, _react.default.createElement(_Stack.default, null, _react.default.createElement(_Stack.default.Item, {
    fill: true
  }, _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, "This Month:")), _react.default.createElement(_Stack.default.Item, null, beatsThisMonth !== undefined ? _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, beatsThisMonth, " B") : _react.default.createElement(_textView.default, {
    inverted: true
  }, "--")))), _react.default.createElement(_section.default, {
    spacingTop: "tight"
  }, _react.default.createElement(_Stack.default, null, _react.default.createElement(_Stack.default.Item, {
    fill: true
  }, _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, "Current Balance:")), _react.default.createElement(_Stack.default.Item, null, beats !== undefined ? _react.default.createElement(_textView.default, {
    textStyle: "strong",
    inverted: true
  }, beats, " B") : _react.default.createElement(_textView.default, {
    inverted: true
  }, "--"))))), _react.default.createElement(_Card.default.Actions, null, _react.default.createElement(_Stack.default, null, _react.default.createElement(_button.default, {
    primary: true,
    inverted: true,
    onPress: handleWithdrawModal
  }, "Withdraw"))));
}