var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ActionsView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _actionsStyle = _interopRequireDefault(require("./actions-style"));

function ActionsView(props) {
  return _react.default.createElement(_reactNative.View, {
    style: [_actionsStyle.default.container, props.fullWidth && _actionsStyle.default.fullWidth]
  }, props.children);
}