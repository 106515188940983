Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useLandingPageStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    checkoutSpacing: {
      marginRight: theme.spacing.checkout + theme.spacing.xxl
    },
    dividerContainer: {
      borderTopColor: theme.colors.borderColor,
      borderTopWidth: '1px'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, 1152, {
  checkoutSpacing: {
    marginRight: 0
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.HEIGHT, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.xsm;
}, {
  checkoutSpacing: {
    marginRight: 0
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    container: {
      paddingTop: theme.spacing.m,
      paddingBottom: theme.spacing.m,
      paddingLeft: theme.spacing.l,
      paddingRight: theme.spacing.l
    }
  };
}).buildHook();
var _default = useLandingPageStyle;
exports.default = _default;