var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Video;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Video(_ref) {
  var _ref$width = _ref.width,
      width = _ref$width === void 0 ? '100%' : _ref$width,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? '100%' : _ref$height,
      videoTrack = _ref.videoTrack,
      _ref$mirror = _ref.mirror,
      mirror = _ref$mirror === void 0 ? false : _ref$mirror,
      _ref$fit = _ref.fit,
      fit = _ref$fit === void 0 ? 'cover' : _ref$fit,
      _ref$absoluteFill = _ref.absoluteFill,
      absoluteFill = _ref$absoluteFill === void 0 ? false : _ref$absoluteFill;

  var ref = _react.default.useRef(null);

  _react.default.useEffect(function () {
    if (!videoTrack) return;
    videoTrack.play(ref.current, {
      fit: fit,
      mirror: mirror
    });
    return function () {
      videoTrack.stop();
    };
  }, [videoTrack]);

  var absoluteFillStyle = absoluteFill ? _reactNative.StyleSheet.absoluteFillObject : {};
  return _react.default.createElement("div", {
    ref: ref,
    style: _objectSpread({
      width: width,
      height: height,
      transition: 'all 300ms ease-in-out'
    }, absoluteFillStyle)
  });
}