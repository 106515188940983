var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNative = require("react-native");

var _reactNativeMediaQueries = require("react-native-media-queries");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var base = {
  backdrop: _objectSpread({
    zIndex: 3,
    paddingTop: 20,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.82)'
  }, _reactNative.Platform.select({
    web: {
      overflow: 'auto',
      position: 'fixed'
    }
  })),
  modalView: {
    position: 'relative',
    margin: 'auto',
    padding: 0,
    width: '70%'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  close: _objectSpread({
    color: 'white',
    fontWeight: 'bold'
  }, _reactNative.Platform.select({
    web: {
      cursor: 'pointer',
      fontSize: '28px'
    }
  })),
  modalBody: _objectSpread({
    position: 'relative',
    height: 0
  }, _reactNative.Platform.select({
    web: {
      overflow: 'hidden',
      paddingBottom: '58%'
    }
  }))
};
var Styles = (0, _reactNativeMediaQueries.createStyles)(base, (0, _reactNativeMediaQueries.maxWidth)(768, {
  modalView: {
    width: '90%'
  }
}));
var _default = Styles;
exports.default = _default;