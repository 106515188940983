var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleError;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectDestructuringEmpty2 = _interopRequireDefault(require("@babel/runtime/helpers/objectDestructuringEmpty"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var useClasses = (0, _core.makeStyles)({
  paper: {
    textAlign: 'left'
  },
  pre: {
    fontSize: 10,
    backgroundColor: '#dcdcdc',
    padding: 5
  }
}, []);

function HandleError(_ref) {
  (0, _objectDestructuringEmpty2.default)(_ref);

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      error = _useAudioVideoContext.error;

  if (!error) {
    return null;
  }

  var errorCode = error.code === 'UNEXPECTED_ERROR' ? error.message : error.code;
  return _react.default.createElement(AutoOpenDialog, null, function (_ref2) {
    var onClose = _ref2.onClose;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.DialogTitle, null, _i18n.default.translate('networking.somethingBadHappened')), _react.default.createElement(_core.DialogContent, null, _react.default.createElement(_core.Typography, null, _i18n.default.translate("agora.".concat(errorCode), {
      defaultValue: error.message
    }))), _react.default.createElement(_core.DialogActions, null, _react.default.createElement(_ContainedButton.default, {
      onPress: onClose
    }, "Ok")));
  });
}

function AutoOpenDialog(_ref3) {
  var children = _ref3.children;

  var _React$useState = _react.default.useState(true),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var classes = useClasses();
  return _react.default.createElement(_core.Dialog, {
    classes: {
      paper: classes.paper
    },
    onBackdropClick: function onBackdropClick() {
      return setOpen(false);
    },
    open: open
  }, children({
    onClose: function onClose() {
      return setOpen(false);
    }
  }));
}