Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _colors = require("../../../design-system/theme/colors");

var _spacing = require("../../../design-system/spacing");

var _default = _reactNative.StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: _colors.Color.neutralDarkest
  },
  spacingHorizontal: {
    paddingHorizontal: _spacing.Spacing.base
  }
});

exports.default = _default;