var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-roles/selectors");

function ShowComponentByBackstageFeatureAccess(_ref) {
  var backstageId = _ref.backstageId,
      allowedFeatures = _ref.allowedFeatures,
      children = _ref.children;
  var showComponent = (0, _reactRedux.useSelector)((0, _selectors.hasUserFeatureAccessSelector)(allowedFeatures, backstageId));
  if (!showComponent) return null;
  return _react.default.createElement(_react.default.Fragment, null, children);
}

var _default = ShowComponentByBackstageFeatureAccess;
exports.default = _default;