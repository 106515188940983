var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moreButton = exports.aboutButton = exports.xButton = exports.settingsButton = exports.backButtonWithCustomAction = exports.backButton = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../assets/icons/CustomIcon.js"));

var _reactNavigation = require("react-navigation");

var backButton = function backButton(navigation) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      left: 8,
      top: 0
    },
    onPress: function onPress() {
      return navigation.dispatch(_reactNavigation.NavigationActions.back());
    }
  }, _react.default.createElement(_CustomIcon.default, {
    name: "chevron-left",
    size: 20,
    color: "#ffffff"
  }));
};

exports.backButton = backButton;

var backButtonWithCustomAction = function backButtonWithCustomAction(action) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    testID: "backButtonWithCustomAction",
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      left: 8,
      marginTop: 16
    },
    onPress: action
  }, _react.default.createElement(_CustomIcon.default, {
    name: "chevron-left",
    size: 20,
    color: "#ffffff"
  }));
};

exports.backButtonWithCustomAction = backButtonWithCustomAction;

var settingsButton = function settingsButton(navigation) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      right: 8,
      top: 0
    },
    onPress: function onPress() {
      return navigation.navigate('Settings');
    }
  }, _react.default.createElement(_CustomIcon.default, {
    name: "settings-outline",
    size: 20,
    color: "#ffffff"
  }));
};

exports.settingsButton = settingsButton;

var xButton = function xButton(navigation) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      right: 8
    },
    onPress: function onPress() {
      return navigation.goBack();
    }
  }, _react.default.createElement(_CustomIcon.default, {
    name: "close",
    size: 20,
    color: "#ffffff"
  }));
};

exports.xButton = xButton;

var aboutButton = function aboutButton(navigation) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      right: 8
    },
    onPress: function onPress() {
      return navigation.navigate('FestivalAboutScreen', {
        festivalId: navigation.getParam('festivalId')
      });
    }
  }, _react.default.createElement(_CustomIcon.default, {
    name: "info-outline",
    size: 20,
    color: "#ffffff"
  }));
};

exports.aboutButton = aboutButton;

var moreButton = function moreButton(navigation) {
  return _react.default.createElement(_reactNativePaper.TouchableRipple, {
    borderless: true,
    style: {
      width: 40,
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      right: 8
    },
    onPress: function onPress() {
      return navigation.navigate('ReportOrBlockModal', {
        fullName: navigation.getParam('djName'),
        avatar: navigation.getParam('avatar'),
        userId: navigation.getParam('userId')
      });
    }
  }, _react.default.createElement(_CustomIcon.default, {
    name: "more-vert",
    size: 24,
    color: "#ffffff"
  }));
};

exports.moreButton = moreButton;