var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _i18n = _interopRequireDefault(require("../../../../../utils/i18n"));

var _hamburgerMenuFooterStyles = _interopRequireDefault(require("./hamburger-menu-footer-styles"));

var _Spacing = _interopRequireDefault(require("../../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _selectors = require("../../../../../core/event-info/selectors");

var _reactRedux = require("react-redux");

var _LinkButton = _interopRequireDefault(require("../../../../../theme/components/buttons/LinkButton"));

var _externalLinkUtils = require("../../../../../utils/external-link-utils");

var _useNavigationExtended = _interopRequireDefault(require("../../../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../../../navigation/client/routes-consts");

var _MuiLinkButton = _interopRequireDefault(require("./../../../../common/custom-design-components/MuiLinkButton"));

var HamburgerMenuFooter = function HamburgerMenuFooter() {
  var _useSelector = (0, _reactRedux.useSelector)(_selectors.selectEventInfo),
      footer = _useSelector.footer;

  var Styles = (0, _hamburgerMenuFooterStyles.default)();
  var navigation = (0, _useNavigationExtended.default)();
  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    mSpacing: "s"
  }, _react.default.createElement(_Spacing.default, {
    direction: "row"
  }, (footer === null || footer === void 0 ? void 0 : footer.showTermsAndConditions) && _react.default.createElement(_Spacing.default, null, _react.default.createElement(_MuiLinkButton.default, {
    onClick: function onClick() {
      navigation.navigate(_routesConsts.Routes.TERMS);
    },
    translationKey: "footer.termsAndConditions",
    variant: "caption"
  })), (footer === null || footer === void 0 ? void 0 : footer.showPrivacyPolicy) && _react.default.createElement(_Spacing.default, {
    style: Styles.footerPrivacy
  }, _react.default.createElement(_MuiLinkButton.default, {
    onClick: function onClick() {
      navigation.navigate(_routesConsts.Routes.PRIVACY_POLICY);
    },
    translationKey: "footer.privacyPolicy",
    variant: "caption"
  }))), (footer === null || footer === void 0 ? void 0 : footer.showPoweredByEventMix) && _react.default.createElement(_Spacing.default, {
    direction: "row",
    style: Styles.copyright
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, " \xA9 Copyright\xA0"), _react.default.createElement(_MuiLinkButton.default, {
    onClick: function onClick() {
      return (0, _externalLinkUtils.openExternalUrl)('http://eventmix.live/');
    },
    translationKey: "footer.owner",
    variant: "caption"
  })));
};

var _default = HamburgerMenuFooter;
exports.default = _default;