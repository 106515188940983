var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CommentsListInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _Comment = _interopRequireDefault(require("./Comment"));

var _style = _interopRequireDefault(require("./style"));

var _chat = _interopRequireDefault(require("../../core/chat"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var THRESHOLD = 20;

var CommentsListInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(CommentsListInternal, _React$PureComponent);

  var _super = _createSuper(CommentsListInternal);

  function CommentsListInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, CommentsListInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.listRef = _react.default.createRef();
    _this.state = {
      userDidScroll: false,
      screenWidth: _reactNative.Dimensions.get('window').width
    };

    _this.handleContentSizeChange = function () {
      if (!_this.state.userDidScroll && _this.listRef.current) {
        _this.listRef.current.scrollToEnd({
          animated: false
        });
      }
    };

    _this.handleScrollBeginDrag = function () {
      if (!_this.state.userDidScroll) {
        _this.setState({
          userDidScroll: true
        });

        _this.props.onChangeAutoReadMode(false);
      }
    };

    _this.handleScrollEndDrag = function (e) {
      var listHeight = e.nativeEvent.layoutMeasurement.height;
      var contentOffsetY = e.nativeEvent.contentOffset.y;
      var contentHeight = e.nativeEvent.contentSize.height;

      if (listHeight + contentOffsetY > contentHeight - THRESHOLD) {
        _this.setState({
          userDidScroll: false
        });

        _this.props.onChangeAutoReadMode(true);
      }
    };

    _this.handleJumpToEnd = function () {
      var animated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (_this.listRef.current) {
        _this.listRef.current.scrollToEnd({
          animated: animated
        });
      }

      _this.setState({
        userDidScroll: false
      });

      _this.props.onChangeAutoReadMode(true);
    };

    _this.handleSetLayoutSize = function (event) {
      _this.setState({
        screenWidth: event.nativeEvent.layout.width
      });
    };

    _this._renderItem = function (item) {
      return _react.default.createElement(_Comment.default, {
        loggedUserId: _this.props.loggedUserId,
        comment: item,
        key: item.get('key')
      });
    };

    return _this;
  }

  (0, _createClass2.default)(CommentsListInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.props.onChangeAutoReadMode(true);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.props.onChangeAutoReadMode(false);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(oldProps) {
      var comments = oldProps.comments;
      var oldLast = comments && comments.last();
      var newComments = this.props.comments;
      var newLast = newComments && newComments.last();

      if (oldLast && newLast && oldLast.toJS().sig !== newLast.toJS().sig) {
        this.handleContentSizeChange();
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNative.View, {
        style: _style.default.commentsContainer,
        onLayout: this.handleSetLayoutSize
      }, _react.default.createElement(_reactNative.ScrollView, {
        ref: this.listRef,
        style: _style.default.commentsList,
        contentContainerStyle: {
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          minWidth: '100%'
        },
        onContentSizeChange: this.handleContentSizeChange,
        onLayout: this.handleContentSizeChange,
        onScrollBeginDrag: this.handleScrollBeginDrag,
        onScrollEndDrag: this.handleScrollEndDrag,
        showsVerticalScrollIndicator: false
      }, this.props.comments.map(this._renderItem)), this.props.unreadCount > 0 && _react.default.createElement(_reactNative.View, {
        style: _style.default.unreadMessagesWrapper
      }, _react.default.createElement(_reactNative.TouchableOpacity, {
        onPress: this.handleJumpToEnd,
        testID: 'unreadTouch'
      }, _react.default.createElement(_reactNative.View, {
        style: _style.default.jumpToEnd
      }, _react.default.createElement(_reactNative.Text, {
        style: _style.default.jumpToEndText
      }, this.props.unreadCount, this.props.unreadCount >= _chat.default.constants.MAX_MESSAGES && '+', ' ', "unread messages")))));
    }
  }]);
  return CommentsListInternal;
}(_react.default.PureComponent);

exports.CommentsListInternal = CommentsListInternal;

var _default = (0, _reactNativePaper.withTheme)(CommentsListInternal);

exports.default = _default;