var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../core/speakers/selectors");

var _routesConsts = require("../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _speaker = _interopRequireDefault(require("./speaker"));

var _speakerStyles = _interopRequireDefault(require("./speaker-styles"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _styles = _interopRequireDefault(require("./styles"));

var SpeakersContainer = function SpeakersContainer() {
  var navigation = (0, _useNavigationExtended.default)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectSpeakersIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectSpeakersIsLoaded);
  var speakers = (0, _reactRedux.useSelector)(_selectors.selectSpeakersList);
  var Styles = (0, _speakerStyles.default)();
  var classes = (0, _styles.default)();

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      speakersTitle = _useSelector.Speakers;

  if (!speakers) return null;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
    align: "left",
    variant: "h2",
    className: classes.header
  }, speakersTitle || _i18n.default.t('header.speakers')), _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0, 0, 0],
    direction: "row",
    alignItems: "center",
    style: Styles.container,
    wrap: "wrap"
  }, isLoading && [_react.default.createElement(_Spacing.default, {
    key: 1,
    style: [Styles.cardBox, {
      alignItems: 'center',
      justifyContent: 'center'
    }]
  }, _react.default.createElement(_reactContentLoader.default, {
    viewBox: "0 0 200 280",
    height: 280,
    width: 160,
    backgroundColor: "#e0e0e0"
  }, _react.default.createElement("circle", {
    cx: "100",
    cy: "64",
    r: "64"
  }), _react.default.createElement("rect", {
    x: "30",
    y: "140",
    rx: "0",
    ry: "0",
    width: "140",
    height: "20"
  }), _react.default.createElement("rect", {
    x: "5",
    y: "170",
    rx: "0",
    ry: "0",
    width: "190",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "0",
    y: "190",
    rx: "0",
    ry: "0",
    width: "200",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "10",
    y: "210",
    rx: "0",
    ry: "0",
    width: "180",
    height: "16"
  }))), _react.default.createElement(_Spacing.default, {
    key: 2,
    style: [Styles.cardBox, {
      alignItems: 'center',
      justifyContent: 'center'
    }]
  }, _react.default.createElement(_reactContentLoader.default, {
    viewBox: "0 0 200 280",
    height: 280,
    width: 160,
    backgroundColor: "#e0e0e0"
  }, _react.default.createElement("circle", {
    cx: "100",
    cy: "64",
    r: "64"
  }), _react.default.createElement("rect", {
    x: "30",
    y: "140",
    rx: "0",
    ry: "0",
    width: "140",
    height: "20"
  }), _react.default.createElement("rect", {
    x: "5",
    y: "170",
    rx: "0",
    ry: "0",
    width: "190",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "0",
    y: "190",
    rx: "0",
    ry: "0",
    width: "200",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "10",
    y: "210",
    rx: "0",
    ry: "0",
    width: "180",
    height: "16"
  }))), _react.default.createElement(_Spacing.default, {
    key: 3,
    style: [Styles.cardBox, {
      alignItems: 'center',
      justifyContent: 'center'
    }]
  }, _react.default.createElement(_reactContentLoader.default, {
    viewBox: "0 0 200 280",
    height: 280,
    width: 160,
    backgroundColor: "#e0e0e0"
  }, _react.default.createElement("circle", {
    cx: "100",
    cy: "64",
    r: "64"
  }), _react.default.createElement("rect", {
    x: "30",
    y: "140",
    rx: "0",
    ry: "0",
    width: "140",
    height: "20"
  }), _react.default.createElement("rect", {
    x: "5",
    y: "170",
    rx: "0",
    ry: "0",
    width: "190",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "0",
    y: "190",
    rx: "0",
    ry: "0",
    width: "200",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "10",
    y: "210",
    rx: "0",
    ry: "0",
    width: "180",
    height: "16"
  }))), _react.default.createElement(_Spacing.default, {
    key: 4,
    style: [Styles.cardBox, {
      alignItems: 'center',
      justifyContent: 'center'
    }]
  }, _react.default.createElement(_reactContentLoader.default, {
    viewBox: "0 0 200 280",
    height: 280,
    width: 160,
    backgroundColor: "#e0e0e0"
  }, _react.default.createElement("circle", {
    cx: "100",
    cy: "64",
    r: "64"
  }), _react.default.createElement("rect", {
    x: "30",
    y: "140",
    rx: "0",
    ry: "0",
    width: "140",
    height: "20"
  }), _react.default.createElement("rect", {
    x: "5",
    y: "170",
    rx: "0",
    ry: "0",
    width: "190",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "0",
    y: "190",
    rx: "0",
    ry: "0",
    width: "200",
    height: "16"
  }), _react.default.createElement("rect", {
    x: "10",
    y: "210",
    rx: "0",
    ry: "0",
    width: "180",
    height: "16"
  })))], isLoaded && !speakers.length && _react.default.createElement(_reactNative.Text, null, "No speakers..."), speakers.map(function (_ref) {
    var id = _ref.id,
        name = _ref.name,
        photo = _ref.photo,
        organization = _ref.organization,
        position = _ref.position,
        shortBio = _ref.shortBio,
        isModerator = _ref.isModerator;
    return _react.default.createElement(_Spacing.default, {
      pSpacing: ['m'],
      key: id,
      style: Styles.cardBox
    }, _react.default.createElement(_speaker.default, {
      name: name,
      photo: photo,
      organization: organization,
      position: position,
      shortBio: shortBio,
      isModerator: isModerator,
      onNavigate: function onNavigate() {
        return navigation.navigate(_routesConsts.Routes.SPEAKER_PAGE, {
          speakerId: id
        });
      }
    }));
  })));
};

var _default = SpeakersContainer;
exports.default = _default;