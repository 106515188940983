Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectChecklistLength = exports.selectChecklistItemById = exports.selectChecklistIsLoaded = exports.selectChecklistIsLoading = exports.selectChecklist = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectChecklistState = function selectChecklistState(state) {
  return state[_constants.NAME];
};

var selectChecklist = _reducer.checklistEntityAdapter.getSelectors(selectChecklistState).selectAll;

exports.selectChecklist = selectChecklist;
var selectChecklistIsLoading = (0, _toolkit.createSelector)(selectChecklistState, function (state) {
  return state.loading;
});
exports.selectChecklistIsLoading = selectChecklistIsLoading;
var selectChecklistIsLoaded = (0, _toolkit.createSelector)(selectChecklistState, function (state) {
  return state.loaded;
});
exports.selectChecklistIsLoaded = selectChecklistIsLoaded;

var selectChecklistItemById = _reducer.checklistEntityAdapter.getSelectors(selectChecklistState).selectById;

exports.selectChecklistItemById = selectChecklistItemById;
var selectChecklistLength = (0, _toolkit.createSelector)(selectChecklist, function (state) {
  return state.length;
});
exports.selectChecklistLength = selectChecklistLength;