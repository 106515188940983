Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSpeakerRequestStyles = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useSpeakerRequestStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      position: 'relative',
      borderColor: theme.colors.borderColor,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 10
    },
    videoContainer: {
      borderRadius: 10,
      overflow: 'hidden'
    },
    avatarContainer: {
      backgroundColor: 'black',
      borderRadius: 10,
      width: '100%',
      position: 'relative',
      paddingTop: '56.25%'
    }
  };
}).buildHook();
exports.useSpeakerRequestStyles = useSpeakerRequestStyles;