var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areEventsLoading = areEventsLoading;
exports.eventsLoaded = eventsLoaded;
exports.getEvents = getEvents;
exports.loadScheduledEvents = loadScheduledEvents;
exports.loadScheduledEventsCompleted = loadScheduledEventsCompleted;
exports.loadEventsMusicType = loadEventsMusicType;
exports.loadEventsMusicTypeCompleted = loadEventsMusicTypeCompleted;
exports.loadDjScheduleEvents = loadDjScheduleEvents;
exports.loadDjScheduleEventsCompleted = loadDjScheduleEventsCompleted;
exports.loadScheduleEventsMusicTypes = loadScheduleEventsMusicTypes;
exports.loadScheduleEventsMusicTypesCompleted = loadScheduleEventsMusicTypesCompleted;
exports.loadEventsByHashtag = loadEventsByHashtag;
exports.loadEventsByHashtagCompleted = loadEventsByHashtagCompleted;
exports.loadLiveNowEvents = loadLiveNowEvents;
exports.loadLiveNowEventsCompleted = loadLiveNowEventsCompleted;
exports.loadUpcomingEvents = loadUpcomingEvents;
exports.loadUpcomingEventsCompleted = loadUpcomingEventsCompleted;
exports.loadPastEvents = loadPastEvents;
exports.loadPastEventsCompleted = loadPastEventsCompleted;
exports.loadPromotedUpcomingEvents = loadPromotedUpcomingEvents;
exports.loadPromotedUpcomingEventsCompleted = loadPromotedUpcomingEventsCompleted;
exports.statusChanged = statusChanged;
exports.loadEvent = loadEvent;
exports.loadEventCompleted = loadEventCompleted;
exports.loadStageEvents = loadStageEvents;
exports.loadPartiallyStageEventsListCompleted = loadPartiallyStageEventsListCompleted;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _eventsData = require("../firebase/events-data");

var _profile = _interopRequireDefault(require("../profile"));

var _user = _interopRequireDefault(require("../user"));

var _actionTypes = require("./actionTypes");

var _constants = require("./constants");

function areEventsLoading() {
  return {
    loading: true,
    type: _constants.ARE_EVENTS_LOADING
  };
}

function eventsLoaded(events) {
  return {
    events: events,
    type: _constants.LOAD_EVENTS_COMPLETED
  };
}

function getEvents() {
  return function () {
    var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(dispatch, getState) {
      var state, userId, events;
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              dispatch(areEventsLoading());
              state = getState();
              userId = _user.default.selectors.getFirebaseUserUid(state);

              if (!userId) {
                _context.next = 8;
                break;
              }

              _context.next = 6;
              return (0, _eventsData.getAllEvents)(userId);

            case 6:
              events = _context.sent;
              dispatch(eventsLoaded(events));

            case 8:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x, _x2) {
      return _ref.apply(this, arguments);
    };
  }();
}

function loadScheduledEvents() {
  return function (dispatch, getState, _ref2) {
    var api = _ref2.api;
    return api.get({
      action: '/lives?status[]=scheduled&status[]=paused&order[startDate]=asc'
    }).then(function (_ref3) {
      var eventList = _ref3.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
          dispatch(_profile.default.actions.loadFollowersNumber(eventList[i].dj.id));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadScheduledEventsCompleted(fullEventList));
    });
  };
}

function loadScheduledEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_SCHEDULED_EVENTS_COMPLETED
  };
}

function loadEventsMusicType(musicType) {
  return function (dispatch, getState, _ref4) {
    var api = _ref4.api;
    return api.get({
      action: "/lives?hashTags[]=".concat(musicType, "&order[startDate]=asc")
    }).then(function (_ref5) {
      var eventList = _ref5.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadEventsMusicTypeCompleted(fullEventList));
    });
  };
}

function loadEventsMusicTypeCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_MUSIC_TYPE_EVENTS_COMPLETED
  };
}

function loadDjScheduleEvents(djId) {
  return function (dispatch, getState, _ref6) {
    var api = _ref6.api;
    var url = "/lives?dj=".concat(djId, "&status[]=scheduled&order[startDate]=asc");
    return api.get({
      action: url
    }).then(function (_ref7) {
      var eventList = _ref7.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadDjScheduleEventsCompleted(fullEventList));
    });
  };
}

function loadDjScheduleEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_DJ_UPCOMING_EVENTS_COMPLETED
  };
}

function loadScheduleEventsMusicTypes(hashtags) {
  return function (dispatch, getState, _ref8) {
    var api = _ref8.api;
    var url = "/lives?order[startDate]=asc&".concat(hashtags.map(function (t) {
      return "hashTags[]=".concat(t);
    }).join('&'));
    return api.get({
      action: url
    }).then(function (_ref9) {
      var eventList = _ref9.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadScheduleEventsMusicTypesCompleted(fullEventList));
    });
  };
}

function loadScheduleEventsMusicTypesCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED
  };
}

function loadEventsByHashtag(hashtag) {
  return function (dispatch, getState, _ref10) {
    var api = _ref10.api;
    var range;

    var getPage = function getPage(page) {
      var url = "/lives?order[startDate]=asc&hashTags[]=".concat(hashtag, "&page=").concat(page);
      return api.get({
        action: url
      }).then(function (pagedResponse) {
        var eventList = pagedResponse.data;
        var apiCalls = [];

        for (var i = 0; i < eventList.length; i++) {
          if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
            apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
          }

          eventList[i].id = eventList[i].id.toString();
        }

        range = api.getContentRange(pagedResponse.headers);
        return Promise.all(apiCalls).then(function () {
          return eventList;
        });
      }).then(function (fullEventList) {
        dispatch(loadEventsByHashtagCompleted(fullEventList));

        if (range.to <= range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function loadEventsByHashtagCompleted(eventList) {
  return {
    type: _actionTypes.LOAD_EVENTS_BY_HASHTAG,
    events: eventList
  };
}

function loadLiveNowEvents() {
  return function (dispatch, getState, _ref11) {
    var api = _ref11.api;
    return api.get({
      action: '/lives?status[]=playing&order[startDate]=asc&pagination=false'
    }).then(function (_ref12) {
      var eventList = _ref12.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadLiveNowEventsCompleted(fullEventList));
    });
  };
}

function loadLiveNowEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_LIVE_NOW_EVENTS_COMPLETED
  };
}

function loadUpcomingEvents() {
  return function (dispatch, getState, _ref13) {
    var api = _ref13.api;
    return api.get({
      action: '/lives?status[]=scheduled&status[]=playing&order[startDate]=asc&perPage=30'
    }).then(function (_ref14) {
      var eventList = _ref14.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
          dispatch(_profile.default.actions.loadFollowersNumber(eventList[i].dj.id));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadUpcomingEventsCompleted(fullEventList));
    });
  };
}

function loadUpcomingEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_UPCOMING_EVENTS_COMPLETED
  };
}

function loadPastEvents(forDjId) {
  var url = '/replays';

  if (forDjId) {
    url = "/replays?dj=".concat(forDjId);
  }

  return function (dispatch, getState, _ref15) {
    var api = _ref15.api;
    return api.get({
      action: url
    }).then(function (_ref16) {
      var eventList = _ref16.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        var djId = eventList[i].dj.id;

        if (djId && !_profile.default.selectors.hasLoaded(getState(), djId)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(djId)));
          dispatch(_profile.default.actions.loadFollowersNumber(djId));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadPastEventsCompleted(fullEventList));
    });
  };
}

function loadPastEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_PAST_EVENTS_COMPLETED
  };
}

function loadPromotedUpcomingEvents() {
  return function (dispatch, getState, _ref17) {
    var api = _ref17.api;
    return api.get({
      action: '/lives?status[]=scheduled&order[startDate]=asc&priority[gt]=0'
    }).then(function (_ref18) {
      var eventList = _ref18.data;
      var apiCalls = [];

      for (var i = 0; i < eventList.length; i++) {
        if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
          apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
          dispatch(_profile.default.actions.loadFollowersNumber(eventList[i].dj.id));
        }

        eventList[i].id = eventList[i].id.toString();
      }

      return Promise.all(apiCalls).then(function () {
        return eventList;
      });
    }).then(function (fullEventList) {
      dispatch(loadPromotedUpcomingEventsCompleted(fullEventList));
    });
  };
}

function loadPromotedUpcomingEventsCompleted(eventList) {
  return {
    events: eventList,
    type: _actionTypes.LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED
  };
}

function statusChanged(eventId, status, liveUrl, description, hashTags, priority) {
  return {
    type: _actionTypes.STATUS_CHANGED,
    eventId: eventId,
    status: status,
    liveUrl: liveUrl,
    description: description,
    hashTags: hashTags,
    priority: priority
  };
}

function loadEvent(eventId) {
  return function (dispatch, getState, _ref19) {
    var api = _ref19.api;
    return api.get({
      action: "/lives/".concat(eventId)
    }).then(function (_ref20) {
      var response = _ref20.data;
      response.id = response.id.toString();
      return dispatch(_profile.default.actions.loadProfile(response.dj.id)).then(function () {
        return response;
      });
    }).then(function (event) {
      dispatch(loadEventCompleted(event));
    });
  };
}

function loadEventCompleted(event) {
  return {
    event: event,
    type: _actionTypes.LOAD_EVENT_COMPLETED
  };
}

function loadStageEvents(stageId) {
  return function (dispatch, getState, _ref21) {
    var api = _ref21.api;
    var range;

    var getPage = function getPage(page) {
      return api.get({
        action: '/lives',
        params: {
          festivalStage: stageId,
          page: page
        }
      }).then(function (pagedResponse) {
        var eventList = pagedResponse.data;
        var apiCalls = [];

        for (var i = 0; i < eventList.length; i++) {
          if (!_profile.default.selectors.hasLoaded(getState(), eventList[i].dj.id)) {
            apiCalls.push(dispatch(_profile.default.actions.loadProfile(eventList[i].dj.id)));
          }

          eventList[i].id = eventList[i].id.toString();
        }

        range = api.getContentRange(pagedResponse.headers);
        return Promise.all(apiCalls).then(function () {
          return eventList;
        });
      }).then(function (fullEventList) {
        dispatch(loadPartiallyStageEventsListCompleted(fullEventList));

        if (range.to <= range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function loadPartiallyStageEventsListCompleted(eventList) {
  return {
    type: _actionTypes.LOAD_STAGE_PARTIAL_EVENTS_COMPLETED,
    events: eventList
  };
}