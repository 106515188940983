Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _default = (0, _core.withStyles)(function (theme) {
  return {
    root: {
      width: function width(_ref) {
        var sizes = _ref.sizes;
        return sizes;
      },
      height: function height(_ref2) {
        var sizes = _ref2.sizes;
        return sizes;
      },
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      backgroundColor: theme.palette.grey[500],
      '& img': {
        objectFit: 'fill'
      },
      fontSize: function fontSize(_ref3) {
        var sizes = _ref3.sizes;
        return sizes.split('px')[0] / 2;
      }
    }
  };
})(_core.Avatar);

exports.default = _default;