Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIVE_STATUS = exports.WEBSOCKET_STATUS_CHANGED_EVENT = exports.LOAD_EVENTS_BY_HASHTAG = exports.LOAD_DJ_UPCOMING_EVENTS_COMPLETED = exports.LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED = exports.LOAD_MUSIC_TYPE_EVENTS_COMPLETED = exports.LOAD_STAGE_PARTIAL_EVENTS_COMPLETED = exports.LOAD_EVENT_COMPLETED = exports.LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED = exports.LOAD_PAST_EVENTS_COMPLETED = exports.LOAD_UPCOMING_EVENTS_COMPLETED = exports.LOAD_LIVE_NOW_EVENTS_COMPLETED = exports.LOAD_SCHEDULED_EVENTS_COMPLETED = exports.LOAD_EVENTS_COMPLETED = exports.ARE_EVENTS_LOADING = exports.NAME = void 0;
var NAME = 'events';
exports.NAME = NAME;
var ARE_EVENTS_LOADING = 'events/ARE_EVENTS_LOADING';
exports.ARE_EVENTS_LOADING = ARE_EVENTS_LOADING;
var LOAD_EVENTS_COMPLETED = 'events/LOAD_EVENTS_COMPLETED';
exports.LOAD_EVENTS_COMPLETED = LOAD_EVENTS_COMPLETED;
var LOAD_SCHEDULED_EVENTS_COMPLETED = 'events/LOAD_SCHEDULED_EVENTS_COMPLETED';
exports.LOAD_SCHEDULED_EVENTS_COMPLETED = LOAD_SCHEDULED_EVENTS_COMPLETED;
var LOAD_LIVE_NOW_EVENTS_COMPLETED = 'events/LOAD_LIVE_NOW_EVENTS_COMPLETED';
exports.LOAD_LIVE_NOW_EVENTS_COMPLETED = LOAD_LIVE_NOW_EVENTS_COMPLETED;
var LOAD_UPCOMING_EVENTS_COMPLETED = 'events/LOAD_UPCOMING_EVENTS_COMPLETED';
exports.LOAD_UPCOMING_EVENTS_COMPLETED = LOAD_UPCOMING_EVENTS_COMPLETED;
var LOAD_PAST_EVENTS_COMPLETED = 'events/LOAD_PAST_EVENTS_COMPLETED';
exports.LOAD_PAST_EVENTS_COMPLETED = LOAD_PAST_EVENTS_COMPLETED;
var LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED = 'events/LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED';
exports.LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED = LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED;
var LOAD_EVENT_COMPLETED = 'events/LOAD_EVENT_COMPLETED';
exports.LOAD_EVENT_COMPLETED = LOAD_EVENT_COMPLETED;
var LOAD_STAGE_PARTIAL_EVENTS_COMPLETED = 'events/LOAD_STAGE_PARTIAL_EVENTS_COMPLETED';
exports.LOAD_STAGE_PARTIAL_EVENTS_COMPLETED = LOAD_STAGE_PARTIAL_EVENTS_COMPLETED;
var LOAD_MUSIC_TYPE_EVENTS_COMPLETED = 'events/LOAD_MUSIC_TYPE_EVENTS_COMPLETED';
exports.LOAD_MUSIC_TYPE_EVENTS_COMPLETED = LOAD_MUSIC_TYPE_EVENTS_COMPLETED;
var LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED = 'events/LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED';
exports.LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED = LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED;
var LOAD_DJ_UPCOMING_EVENTS_COMPLETED = 'events/LOAD_DJ_UPCOMING_EVENTS_COMPLETED';
exports.LOAD_DJ_UPCOMING_EVENTS_COMPLETED = LOAD_DJ_UPCOMING_EVENTS_COMPLETED;
var LOAD_EVENTS_BY_HASHTAG = 'events/LOAD_EVENTS_BY_HASHTAG';
exports.LOAD_EVENTS_BY_HASHTAG = LOAD_EVENTS_BY_HASHTAG;
var WEBSOCKET_STATUS_CHANGED_EVENT = 'liveStatusChanged';
exports.WEBSOCKET_STATUS_CHANGED_EVENT = WEBSOCKET_STATUS_CHANGED_EVENT;
var LIVE_STATUS = {
  SCHEDULED: 'scheduled',
  PLAYING: 'playing',
  PAUSED: 'paused',
  ENDED: 'ended'
};
exports.LIVE_STATUS = LIVE_STATUS;