var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _Card = _interopRequireDefault(require("design-system/structure/Card"));

var _headingView = _interopRequireDefault(require("design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("design-system/typography/subheading-view"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _video = _interopRequireDefault(require("design-system/video"));

var _mybeatsScreenStyles = _interopRequireDefault(require("./mybeats-screen-styles"));

var _Analytics = require("../../core/services/Analytics");

var _LoadingScreen = _interopRequireDefault(require("../LoadingScreen"));

var _immutable = require("immutable");

var _reactRedux = require("react-redux");

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

function MyBeatsInfoScreenView(props) {
  var handlePlayStart = function handlePlayStart() {
    (0, _Analytics.trackEvent)('beats_info_video_play');
  };

  var _props$infoScreenProp = props.infoScreenProps,
      infoScreenProps = _props$infoScreenProp === void 0 ? (0, _immutable.Map)() : _props$infoScreenProp,
      _props$isGsPropertySe = props.isGsPropertySetLoading,
      isGsPropertySetLoading = _props$isGsPropertySe === void 0 ? true : _props$isGsPropertySe;
  var subheading = infoScreenProps.get('subheading');
  var video = infoScreenProps.get('video');
  var poster = infoScreenProps.getIn(['video', 'poster']);
  var source = infoScreenProps.getIn(['video', 'source']);
  var heading = infoScreenProps.get('heading');
  var contentMap = infoScreenProps.get('content');
  var content = [];

  if (contentMap && contentMap.size) {
    content = contentMap.toArray();
  }

  return isGsPropertySetLoading ? _react.default.createElement(_LoadingScreen.default, null) : _react.default.createElement(_reactNativeSafeAreaView.default, {
    style: [_mybeatsScreenStyles.default.container],
    forceInset: {
      top: 'always',
      bottom: 'always',
      horizontal: 'always'
    },
    testID: "MyBeatsInfoScreenContainer"
  }, _react.default.createElement(_reactNative.ScrollView, {
    showsVerticalScrollIndicator: false
  }, _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_subheadingView.default, {
    textStyle: "center"
  }, subheading))), video ? _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_video.default, {
    poster: poster,
    source: source,
    onPlayStart: handlePlayStart
  })) : null, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_headingView.default, null, heading)), _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_Stack.default, {
    vertical: true,
    spacing: "tight"
  }, content.map(function (item, index) {
    var cardHeading = item.get('heading');
    var text = item.get('text');
    var cardSubheading = item.get('subheading');
    var paragraphs = item.get('paragraphs');
    return text ? _react.default.createElement(_react.default.Fragment, {
      key: index
    }, _react.default.createElement(_Card.default, {
      style: {
        marginBottom: 10
      }
    }, _react.default.createElement(_Card.default.Content, null, _react.default.createElement(_headingView.default, {
      inverted: true
    }, cardHeading), _react.default.createElement(_section.default, {
      spacingTop: "tight"
    }, _react.default.createElement(_textView.default, {
      inverted: true
    }, text))))) : _react.default.createElement(_react.default.Fragment, {
      key: index
    }, _react.default.createElement(_Card.default, {
      style: {
        marginBottom: 10
      }
    }, _react.default.createElement(_Card.default.Content, null, _react.default.createElement(_headingView.default, {
      inverted: true
    }, cardHeading), _react.default.createElement(_section.default, {
      spacingTop: "tight"
    }, cardSubheading ? _react.default.createElement(_subheadingView.default, {
      inverted: true,
      textStyle: "strong"
    }, cardSubheading) : null, paragraphs.map(function (p, i) {
      var paragraphTitle = p.get('title');
      var textList = p.get('text');
      return _react.default.createElement(_react.default.Fragment, {
        key: "p - ".concat(i)
      }, _react.default.createElement(_textView.default, {
        inverted: true,
        textStyle: "strong"
      }, paragraphTitle), _react.default.createElement(_section.default, {
        spacingLeft: "base"
      }, textList.map(function (t, j) {
        return _react.default.createElement(_react.default.Fragment, {
          key: "t - ".concat(j)
        }, _react.default.createElement(_textView.default, {
          inverted: true
        }, t));
      })));
    })))));
  })))));
}

MyBeatsInfoScreenView.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
};

function mapStateToProps(state) {
  return {
    infoScreenProps: _gameSparks.default.selectors.getMyBeatsInfoScreenProps(state),
    isGsPropertySetLoading: _gameSparks.default.selectors.isGsPropertySetLoading(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(MyBeatsInfoScreenView);

exports.default = _default;