Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userAccessTokenByRoomSelector = exports.userRoomAccessTokensSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var userTokensStateSelector = function userTokensStateSelector(state) {
  return state[_constants.NAME];
};

var userRoomAccessTokensSelector = (0, _toolkit.createSelector)(userTokensStateSelector, function (state) {
  return state.roomAccessTokensMap;
});
exports.userRoomAccessTokensSelector = userRoomAccessTokensSelector;

var userAccessTokenByRoomSelector = function userAccessTokenByRoomSelector(room) {
  return (0, _toolkit.createSelector)(userRoomAccessTokensSelector, function (map) {
    return map[room] || {};
  });
};

exports.userAccessTokenByRoomSelector = userAccessTokenByRoomSelector;