var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DateFormat;

var _moment = _interopRequireDefault(require("moment"));

function DateFormat(startDate, endDate) {
  var period;
  var startMonth = (0, _moment.default)(startDate).format('MM');
  var startDay = (0, _moment.default)(startDate).format('D');
  var endMonth = (0, _moment.default)(endDate).format('MM');
  var endDay = (0, _moment.default)(endDate).format('D');
  var endYear = (0, _moment.default)(endDate).format('YYYY');

  if (startMonth === endMonth) {
    period = startDay + ' - ' + endDay + ' ' + (0, _moment.default)(endDate).format('MMM') + ' ' + endYear;
  } else {
    period = startDay + ' ' + (0, _moment.default)(startDate).format('MMM');
    period = period + ' - ' + endDay + ' ' + (0, _moment.default)(endDate).format('MMM') + ' ' + endYear;
  }

  return period.toUpperCase();
}