Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _spacing = require("../../../spacing");

var _default = _reactNative.StyleSheet.create({
  container: {
    padding: _spacing.Spacing.base,
    flex: 1,
    alignItems: 'flex-end'
  }
});

exports.default = _default;