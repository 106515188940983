var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.sessionEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var sessionEntityAdapter = (0, _toolkit.createEntityAdapter)({
  sortComparer: function sortComparer(a, b) {
    return new Date(a.start) - new Date(b.start);
  }
});
exports.sessionEntityAdapter = sessionEntityAdapter;
var initialState = sessionEntityAdapter.getInitialState({
  loaded: false,
  loading: false,
  error: null
});
var sessionReducer = (0, _toolkit.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getSessions.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getSessions.rejected, function (state, _ref) {
  var error = _ref.error;
  state.error = error;
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.getSessions.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;
  sessionEntityAdapter.removeAll(state);
  sessionEntityAdapter.addMany(state, payload);
}), _createReducer));
var _default = sessionReducer;
exports.default = _default;