var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StageContainerInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _events = _interopRequireDefault(require("../../../../core/events"));

var _festival = _interopRequireDefault(require("../../../../core/festival"));

var _MainEvent = _interopRequireDefault(require("./MainEvent"));

var _EventsBox = _interopRequireDefault(require("./EventsBox"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var StageContainerInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(StageContainerInternal, _React$PureComponent);

  var _super = _createSuper(StageContainerInternal);

  function StageContainerInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, StageContainerInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      screenWidth: 0,
      stages: null
    };

    _this.handleSetLayoutSize = function (event) {
      _this.setState({
        screenWidth: event.nativeEvent.layout.width
      });
    };

    return _this;
  }

  (0, _createClass2.default)(StageContainerInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var eventsNo = this.props.events.length;
      var countOneLiveEvent = eventsNo === 1 ? true : false;
      var boxArray = [];
      var b = 0;
      return React.createElement(React.Fragment, null, eventsNo > 0 && React.createElement(_reactNative.View, {
        style: styles.sectionContainer,
        onLayout: this.handleSetLayoutSize,
        key: this.props.stageId
      }, React.createElement(_Title.default, {
        style: [styles.sectionTitle, {
          color: this.props.festivalThemeColor
        }]
      }, this.props.stageName), this.props.events && React.createElement(_reactNative.ScrollView, {
        testID: "LiveNowFeed",
        horizontal: !countOneLiveEvent,
        directionalLockEnabled: true,
        contentContainerStyle: {
          paddingLeft: 16
        },
        pinchGestureEnabled: false,
        showsHorizontalScrollIndicator: false
      }, this.props.events.map(function (ev, index) {
        if (index === 0) {
          return React.createElement(_MainEvent.default, {
            eventId: ev.get('id'),
            isContinuousStreaming: _this2.props.stageType === 'continuous_streaming',
            countOneLiveEvent: countOneLiveEvent,
            screenWidth: _this2.state.screenWidth,
            key: ev.get('id')
          });
        } else {
          if (index % 3 === 1) {
            boxArray = [];
            b++;
            boxArray.push(ev);

            if (index + 1 === eventsNo) {
              return React.createElement(_EventsBox.default, {
                boxArray: boxArray,
                b: b,
                key: 'box' + b,
                screenWidth: _this2.state.screenWidth
              });
            }
          } else if (index % 3 === 0) {
            boxArray.push(ev);
            return React.createElement(_EventsBox.default, {
              boxArray: boxArray,
              b: b,
              key: 'box' + b,
              screenWidth: _this2.state.screenWidth
            });
          } else {
            boxArray.push(ev);

            if (index + 1 === eventsNo) {
              return React.createElement(_EventsBox.default, {
                boxArray: boxArray,
                b: b,
                key: 'box' + b,
                screenWidth: _this2.state.screenWidth
              });
            }
          }

          return null;
        }
      }))));
    }
  }]);
  return StageContainerInternal;
}(React.PureComponent);

exports.StageContainerInternal = StageContainerInternal;

var styles = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginTop: 16,
    marginBottom: 24
  },
  sectionTitle: {
    fontSize: 17,
    lineHeight: 17,
    color: '#FFF',
    paddingHorizontal: 16,
    marginBottom: 16
  }
});

function mapStateToProps(state, ownProps) {
  var stageId = ownProps.stageId;
  var festivalId = ownProps.festivalId;
  return {
    events: _events.default.selectors.getLiveEventFromStage(state, stageId),
    festivalThemeColor: _festival.default.selectors.getThemeColor(state, festivalId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNativePaper.withTheme)(StageContainerInternal));

exports.default = _default;