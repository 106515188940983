var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _core = require("@material-ui/core");

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/user/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var EventLogoContainer = function EventLogoContainer() {
  var logoUrl = (0, _reactRedux.useSelector)(_selectors.selectEventLogoUrl);
  var isLoggedIn = (0, _reactRedux.useSelector)(_selectors2.isFirebaseUserLoggedIn);
  var navigation = (0, _useNavigationExtended.default)();

  var _useState = (0, _react.useState)(80),
      _useState2 = (0, _slicedToArray2.default)(_useState, 1),
      imgHeight = _useState2[0];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      imgWidth = _useState4[0],
      setImgWidth = _useState4[1];

  (0, _react.useEffect)(function () {
    _reactNative.Image.getSize(logoUrl, function (width, height) {
      setImgWidth(width * (imgHeight / height));
    });
  }, [imgHeight, logoUrl]);
  if (!logoUrl) return null;
  return _react.default.createElement(_core.Box, {
    my: 5
  }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
    onPress: function onPress() {
      navigation.navigate(isLoggedIn ? _routesConsts.Routes.LOBBY : _routesConsts.Routes.HOME);
    }
  }, _react.default.createElement(_reactNative.Image, {
    source: {
      uri: logoUrl
    },
    style: {
      height: imgHeight,
      width: imgWidth
    },
    resizeMode: "contain"
  })));
};

var _default = EventLogoContainer;
exports.default = _default;