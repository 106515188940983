var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useCheckoutSectionStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  fixed: {
    position: 'fixed',
    top: '15%',
    maxHeight: '65vh',
    right: 64,
    zIndex: 3,
    maxWidth: 436,
    width: '100%'
  },
  inline: {
    width: '100%'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.HEIGHT, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.lg;
}, {
  fixed: {
    top: '4%',
    maxHeight: '80vh'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.smd;
}, {
  fixed: {
    bottom: 0,
    left: 0,
    top: 'auto',
    maxHeight: 210,
    position: 'fixed',
    width: '100vw'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.UP, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    inline: _objectSpread(_objectSpread({}, theme.shape.forms), {}, {
      boxShadow: theme.shadows[16],
      maxWidth: 436,
      marginVertical: theme.spacing.xxl,
      marginHorizontal: 'auto'
    })
  };
}).buildHook();
var _default = useCheckoutSectionStyles;
exports.default = _default;