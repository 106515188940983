var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unreadMessagesSelector = exports.numberOfOnlineUsersSelector = exports.imBlockedSelector = exports.roomUsersAndStatusesSelector = exports.messagesAndStatusSelector = exports.messagesSelector = exports.blockedUsersSelector = exports.roomUsersSelector = exports.roomLoadedSelector = exports.roomLoadingSelector = exports.currentRoomSelector = exports.messageStateSelector = exports.blockedUserStateSelector = exports.userStateSelector = exports.socketStatusSelector = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../user/selectors");

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var rawStateSelector = function rawStateSelector(state) {
  return state[_constants.NAME];
};

var _userEntityAdapter$ge = _constants.userEntityAdapter.getSelectors(),
    selectAllUser = _userEntityAdapter$ge.selectAll;

var _blockedUserEntityAda = _constants.blockedUserEntityAdapter.getSelectors(),
    selectAllBlockedUser = _blockedUserEntityAda.selectAll;

var _messageEntityAdapter = _constants.messageEntityAdapter.getSelectors(),
    selectAllMessage = _messageEntityAdapter.selectAll;

var socketStatusSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.socketStatus;
});
exports.socketStatusSelector = socketStatusSelector;
var userStateSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.users;
});
exports.userStateSelector = userStateSelector;
var blockedUserStateSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.blockedUsers;
});
exports.blockedUserStateSelector = blockedUserStateSelector;
var messageStateSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.messages;
});
exports.messageStateSelector = messageStateSelector;
var currentRoomSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.chatRoom;
});
exports.currentRoomSelector = currentRoomSelector;
var roomLoadingSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.roomLoading;
});
exports.roomLoadingSelector = roomLoadingSelector;
var roomLoadedSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return state.roomLoaded;
});
exports.roomLoadedSelector = roomLoadedSelector;
var roomUsersSelector = (0, _toolkit.createSelector)(userStateSelector, selectAllUser);
exports.roomUsersSelector = roomUsersSelector;
var blockedUsersSelector = (0, _toolkit.createSelector)(blockedUserStateSelector, selectAllBlockedUser);
exports.blockedUsersSelector = blockedUsersSelector;
var messagesSelector = (0, _toolkit.createSelector)(messageStateSelector, selectAllMessage);
exports.messagesSelector = messagesSelector;
var messagesAndStatusSelector = (0, _toolkit.createSelector)(messagesSelector, blockedUsersSelector, function (messages, blockedUsers) {
  return messages.map(function (message) {
    return _objectSpread(_objectSpread({}, message), {}, {
      isBlocked: blockedUsers.map(function (user) {
        return user.id;
      }).includes(message.usr)
    });
  });
});
exports.messagesAndStatusSelector = messagesAndStatusSelector;
var roomUsersAndStatusesSelector = (0, _toolkit.createSelector)(roomUsersSelector, blockedUsersSelector, function (users, blockedUsers) {
  return users.map(function (user) {
    return _objectSpread(_objectSpread({}, user), {}, {
      isBlocked: !!blockedUsers.find(function (bUser) {
        return bUser.id === user.id;
      })
    });
  });
});
exports.roomUsersAndStatusesSelector = roomUsersAndStatusesSelector;
var imBlockedSelector = (0, _toolkit.createSelector)(_selectors.getFirebaseUserUid, blockedUsersSelector, function (uid, users) {
  return !!users.find(function (user) {
    return user.id === uid;
  });
});
exports.imBlockedSelector = imBlockedSelector;
var numberOfOnlineUsersSelector = (0, _toolkit.createSelector)(roomUsersSelector, function (users) {
  return users.length;
});
exports.numberOfOnlineUsersSelector = numberOfOnlineUsersSelector;
var unreadMessagesSelector = (0, _toolkit.createSelector)(rawStateSelector, function (state) {
  return Object.values(state.unreadCounter).reduce(function (sum, value) {
    return sum + value;
  }, 0);
});
exports.unreadMessagesSelector = unreadMessagesSelector;