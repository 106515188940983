var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UpdateProfileScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _theme = _interopRequireDefault(require("design-system/theme"));

var _user = _interopRequireDefault(require("../../core/user"));

var _profile = _interopRequireDefault(require("../../core/profile"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _headingView = _interopRequireDefault(require("design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("design-system/typography/subheading-view"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _constants = require("../../../constants");

var _selectors = require("../../core/user-profile/selectors");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var UpdateProfileScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(UpdateProfileScreenInternal, _React$PureComponent);

  var _super = _createSuper(UpdateProfileScreenInternal);

  function UpdateProfileScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, UpdateProfileScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      displayName: _this.props.userName,
      iconState: 'round-check-off-outline',
      saveDisabled: true,
      isLoading: false
    };

    _this.handleUpdateProfile = function () {
      var _this$props = _this.props,
          updateProfile = _this$props.updateProfile,
          userId = _this$props.userId;

      _this.setState({
        isLoading: true
      }, function () {
        updateProfile({
          userId: userId,
          displayName: _this.state.displayName,
          acceptedTerms: true
        });
      });
    };

    _this.handleChangeText = function (name) {
      _this.setState(function (prevState) {
        return {
          displayName: name,
          saveDisabled: !name || prevState.iconState === 'round-check-off-outline'
        };
      });
    };

    _this.handleTermsPress = function () {
      _this.setState(function (prevState) {
        if (prevState.iconState === 'round-check-on-outline') {
          return {
            iconState: 'round-check-off-outline',
            saveDisabled: true
          };
        }

        return {
          iconState: 'round-check-on-outline',
          saveDisabled: !prevState.displayName
        };
      });
    };

    return _this;
  }

  (0, _createClass2.default)(UpdateProfileScreenInternal, [{
    key: "render",
    value: function render() {
      var _this$state = this.state,
          iconState = _this$state.iconState,
          displayName = _this$state.displayName,
          saveDisabled = _this$state.saveDisabled,
          isLoading = _this$state.isLoading;
      return _react.default.createElement(_reactNativeSafeAreaView.default, {
        style: [style.container, {
          backgroundColor: _theme.default.colors.background
        }],
        forceInset: {
          top: 'always',
          bottom: 'always'
        }
      }, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "transparent",
        translucent: true
      }), _react.default.createElement(_section.default, {
        spacing: "base",
        spacingTop: "extraLoose"
      }, _react.default.createElement(_Stack.default, {
        vertical: true,
        alignment: "center"
      }, _react.default.createElement(_headingView.default, null, "Thank you for registering"), _react.default.createElement(_subheadingView.default, null, "How should we call you ?")), _react.default.createElement(_section.default, {
        spacingTop: "loose"
      }, _react.default.createElement(_Stack.default, {
        vertical: true
      }, _react.default.createElement(_reactNativePaper.TextInput, {
        mode: "flat",
        underlineColor: "rgba(255,255,255,0.2)",
        returnKeyType: 'done',
        theme: {
          colors: {
            placeholder: 'rgba(255,255,255,0.5)',
            text: _theme.default.colors.text,
            primary: _theme.default.colors.text
          }
        },
        label: "Display Name",
        testID: "displayName",
        placeholder: "Display Name",
        value: displayName,
        onSubmitEditing: this.handleUpdateProfile,
        onChangeText: this.handleChangeText
      }), !displayName ? _react.default.createElement(_textView.default, {
        style: style.errorMessage
      }, "Please add a Display name") : _react.default.createElement(_react.default.Fragment, null), _react.default.createElement(_section.default, {
        spacingVertical: "base"
      }, _react.default.createElement(_Stack.default, null, _react.default.createElement(_Stack.default.Item, null, _react.default.createElement(_iconButton.default, {
        testID: 'termsCheck',
        icon: iconState,
        onPress: this.handleTermsPress
      })), _react.default.createElement(_Stack.default.Item, null, _react.default.createElement(_textView.default, {
        style: {
          textAlign: 'center'
        }
      }, "By continuing you agree with our"), _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'row',
          justifyContent: 'center'
        }
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: 'termsTouch',
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.TERMS_OF_USE);
        }
      }, _react.default.createElement(_textView.default, {
        style: style.agreementMessageLink
      }, "Terms of Use")), _react.default.createElement(_textView.default, {
        style: style.agreementMessage
      }, "and"), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: 'privacyTouch',
        borderless: false,
        onPress: function onPress() {
          _reactNative.Linking.openURL(_constants.PRIVACY_POLICY);
        }
      }, _react.default.createElement(_textView.default, {
        style: style.agreementMessageLink
      }, "Privacy Policy")))))), _react.default.createElement(_button.default, {
        testID: "updateProfileButton",
        loading: isLoading,
        style: {
          backgroundColor: '#fff'
        },
        disabled: saveDisabled,
        secondary: true,
        onPress: this.handleUpdateProfile
      }, "Save")))));
    }
  }]);
  return UpdateProfileScreenInternal;
}(_react.default.PureComponent);

exports.UpdateProfileScreenInternal = UpdateProfileScreenInternal;

var style = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center'
  },
  errorMessage: {
    color: '#FF5151',
    margin: 2
  },
  agreementMessage: {
    color: '#fff',
    margin: 2
  },
  agreementMessageLink: {
    color: '#fff',
    margin: 2,
    textDecorationLine: 'underline'
  }
});

function mapStateToProps(state) {
  return {
    userId: _user.default.selectors.getFirebaseUserUid(state),
    userName: (0, _selectors.selectUserDisplayName)(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  updateProfile: _profile.default.actions.updateProfile
})(UpdateProfileScreenInternal);

exports.default = _default;