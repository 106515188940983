var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LiveEventDetailsInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _live = _interopRequireDefault(require("../../core/live"));

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _Headline = _interopRequireDefault(require("../common/typography/Headline"));

var _Paragraph = _interopRequireDefault(require("../common/typography/Paragraph"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var STATUSBAR_HEIGHT = _reactNative.Platform.OS === 'android' ? _reactNative.StatusBar.currentHeight : _reactNativeDeviceInfo.default.hasNotch() ? 40 : 20;

var LiveEventDetailsInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LiveEventDetailsInternal, _React$PureComponent);

  var _super = _createSuper(LiveEventDetailsInternal);

  function LiveEventDetailsInternal() {
    (0, _classCallCheck2.default)(this, LiveEventDetailsInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LiveEventDetailsInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.props.loadUsers();
    }
  }, {
    key: "_renderItem",
    value: function _renderItem(item) {
      var _this = this;

      var nameInitials = item.get('fnm').split(' ', 2).map(function (i) {
        return i.charAt(0);
      });
      return _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'TapUser' + item.get('usr'),
        key: item.get('usr'),
        name: 'userTouch',
        borderless: false,
        onPress: function onPress() {
          return _this.props.navigation.navigate('Profile', {
            userId: item.get('usr'),
            djName: item.get('fnm'),
            avatar: item.get('avt')
          });
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.userContainer
      }, _react.default.createElement(_AvatarImage.default, {
        size: 40,
        source: {
          uri: item.get('avt')
        },
        defaultLabel: nameInitials,
        style: styles.userAvatar
      }), _react.default.createElement(_Paragraph.default, {
        style: styles.userName
      }, item.get('fnm'))));
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var goBack = this.props.navigation.goBack;
      var otherUsers = this.props.numUsersLive - this.props.users.toArray().length;
      return _react.default.createElement(_reactNativeLinearGradient.default, {
        locations: [0, 0.5, 0.9],
        colors: ['rgba(0, 0, 0, 0.70)', 'rgba(0, 0, 0, 0.95)', 'rgba(0, 0, 0, 0.95)'],
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.closeModalContainer
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "closeScreen",
        name: 'closeLiveDetailsModal',
        onPress: function onPress() {
          return goBack();
        },
        borderless: false,
        style: styles.closeModal
      }, _react.default.createElement(_CustomIcon.default, {
        name: "chevron-down",
        size: 20,
        color: "#fff"
      }))), _react.default.createElement(_reactNative.ScrollView, null, _react.default.createElement(_reactNative.View, {
        style: styles.mainInfo
      }, _react.default.createElement(_Headline.default, null, this.props.numTotalUsers), _react.default.createElement(_Paragraph.default, null, "TOTAL VIEWERS")), _react.default.createElement(_reactNative.View, {
        style: styles.listInfo
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.sectionTitle
      }, "LIVE VIEWERS NOW"), this.props.users.toArray().map(function (el) {
        return _this2._renderItem(el);
      }), otherUsers > 0 && _react.default.createElement(_reactNative.View, {
        style: styles.otherUsersContainer
      }, _react.default.createElement(_reactNative.View, {
        style: styles.otherUsers
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.otherUsersText
      }, "+", otherUsers)), _react.default.createElement(_Paragraph.default, {
        style: [styles.otherUsersText, {
          fontWeight: 'bold'
        }]
      }, "More viewers")))));
    }
  }]);
  return LiveEventDetailsInternal;
}(_react.default.PureComponent);

exports.LiveEventDetailsInternal = LiveEventDetailsInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1
  },
  closeModalContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  closeModal: {
    marginTop: STATUSBAR_HEIGHT,
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainInfo: {
    paddingBottom: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: 'rgba(255,255,255,0.1)',
    borderBottomWidth: 1
  },
  listInfo: {
    paddingVertical: 24,
    justifyContent: 'flex-start'
  },
  sectionTitle: {
    paddingHorizontal: 16
  },
  userContainer: {
    marginVertical: 12,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  userAvatar: {
    marginRight: 16
  },
  userName: {
    fontWeight: 'bold'
  },
  otherUsersContainer: {
    paddingHorizontal: 16,
    marginVertical: 12,
    flexDirection: 'row',
    alignItems: 'center'
  },
  otherUsers: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'rgba(255,255,255,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16
  },
  otherUsersText: {
    color: 'rgba(255,255,255,0.5)'
  }
});

function mapStateToProps(state) {
  return {
    numUsersLive: _live.default.selectors.getNumUsers(state),
    numTotalUsers: _live.default.selectors.getNumTotalUsers(state),
    users: _live.default.selectors.getUsers(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadUsers: _live.default.actions.loadUsers
})((0, _reactNativePaper.withTheme)(LiveEventDetailsInternal));

exports.default = _default;