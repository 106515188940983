var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NetworkingTableLiveView;

var _react = _interopRequireDefault(require("react"));

var _ResponsiveLiveContainer = _interopRequireDefault(require("../../live/components/ResponsiveLiveContainer"));

var _ResponsiveWebVideoContainer = _interopRequireDefault(require("../../live/components/ResponsiveWebVideoContainer"));

var _waitingForStreamView = _interopRequireDefault(require("../../live/components/waiting-for-stream-view"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _networkingTableLiveViewStyle = _interopRequireDefault(require("./networking-table-live-view-style"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../../theme/DesignSystem");

var _NetworkingLive = _interopRequireDefault(require("./components/NetworkingLive"));

var _BaseVideo = _interopRequireDefault(require("../../common/audio-video/BaseVideo"));

var _styles = _interopRequireDefault(require("./styles"));

function NetworkingTableLiveView(_ref) {
  var id = _ref.id,
      name = _ref.name,
      participants = _ref.participants,
      maxParticipants = _ref.maxParticipants,
      screenSharer = _ref.screenSharer,
      loading = _ref.loading,
      disabled = _ref.disabled,
      _ref$onJoin = _ref.onJoin,
      onJoin = _ref$onJoin === void 0 ? function () {} : _ref$onJoin,
      _ref$onLeave = _ref.onLeave,
      onLeave = _ref$onLeave === void 0 ? function () {} : _ref$onLeave;
  var Styles = (0, _networkingTableLiveViewStyle.default)();
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var participantsCount = Object.keys(participants || {}).length;
  var isFull = participantsCount >= maxParticipants;
  return _react.default.createElement(_ResponsiveLiveContainer.default, null, _react.default.createElement(_ResponsiveWebVideoContainer.default, null, _react.default.createElement(_Spacing.default, {
    shaping: "cards",
    color: colors.alternativeBackground,
    direction: "row",
    wrap: "wrap",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    style: Styles.speakerContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.title
  }, name), _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    style: Styles.speakerButtons
  }, _react.default.createElement(_ContainedButton.default, {
    style: Styles.button,
    onPress: function onPress() {
      onLeave();
    }
  }, _i18n.default.translate('networking.leaveTable')), _react.default.createElement(_ContainedButton.default, {
    style: Styles.button,
    iconLeft: loading && _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: colors.buttonText
    }) || null,
    disabled: isFull || loading || disabled,
    onPress: function onPress() {
      return onJoin();
    }
  }, _i18n.default.translate(isFull ? 'networking.tableIsFull' : 'networking.joinTable')))), _react.default.createElement(_NetworkingLive.default, {
    screenSharer: screenSharer || {},
    participantsInfo: participants || {},
    room: id
  }, function (stream, streamStarted) {
    return streamStarted ? _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 's'],
      shaping: "cards",
      style: {
        overflow: 'hidden'
      }
    }, _react.default.createElement(_BaseVideo.default, {
      src: stream
    })) : _react.default.createElement(_waitingForStreamView.default, {
      role: "networking"
    });
  })));
}