Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  surface: {
    backgroundColor: 'white',
    width: '100%',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    elevation: 2
  },
  title: {
    textAlign: 'center',
    color: 'black',
    marginBottom: 24,
    fontWeight: 'bold'
  },
  text: {
    color: 'black',
    marginBottom: 20,
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8
  },
  actionButton: {
    width: '100%',
    maxWidth: 450,
    paddingVertical: 3,
    borderColor: 'black'
  },
  comment: {
    flexDirection: 'row',
    marginBottom: 24,
    paddingHorizontal: 16
  },
  commentAvatar: {
    marginRight: 8
  },
  commentText: {
    flexDirection: 'column'
  },
  commentUserName: {
    color: '#000',
    fontWeight: 'bold',
    marginVertical: 0
  },
  commentMessage: {
    color: '#000',
    marginVertical: 0
  }
});

exports.default = _default;