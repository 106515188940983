var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventChatSection = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _ChatComponent = _interopRequireDefault(require("../chat/ChatComponent"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var EventChatSection = function (_React$PureComponent) {
  (0, _inherits2.default)(EventChatSection, _React$PureComponent);

  var _super = _createSuper(EventChatSection);

  function EventChatSection() {
    (0, _classCallCheck2.default)(this, EventChatSection);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(EventChatSection, [{
    key: "render",
    value: function render() {
      var goBack = this.props.navigation.goBack;
      return _react.default.createElement(_reactNative.View, {
        style: {
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'center'
        }
      }, _react.default.createElement(_reactNative.View, {
        style: {
          height: 32,
          width: '100%',
          backgroundColor: 'black',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        onPress: function onPress() {
          return goBack();
        },
        borderless: true,
        style: {
          width: 40,
          height: 40,
          justifyContent: 'center',
          alignItems: 'center',
          top: 12,
          right: 5
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "chevron-down",
        size: 20,
        color: "#fff"
      }))), _react.default.createElement(_ChatComponent.default, {
        style: {
          maxHeight: '70%'
        }
      }));
    }
  }]);
  return EventChatSection;
}(_react.default.PureComponent);

exports.EventChatSection = EventChatSection;
var _default = EventChatSection;
exports.default = _default;