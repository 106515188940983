Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _DesignSystem = require("../../../theme/DesignSystem");

var useProfileStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  avatar: {
    borderRadius: _reactNative.Platform.OS === 'web' ? '50%' : 68,
    width: 135,
    height: 135
  },
  changeAvatar: {
    whiteSpace: 'nowrap'
  },
  newAvatarImageName: {
    maxWidth: 120
  }
}).addThemeBasedStyle(function (theme) {
  return {
    avatar: {
      marginBottom: theme.spacing.l
    },
    snackbar: {
      backgroundColor: theme.colors.successColor,
      bottom: -80
    },
    snackbarText: {
      color: '#FFFFFF'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.lg;
}, function (theme) {
  return {
    modalContent: {
      marginTop: theme.spacing.m,
      marginLeft: theme.spacing.m,
      marginBottom: theme.spacing.m,
      marginRight: theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.md;
}, function (theme) {
  return {
    modalContent: {
      flexDirection: 'column',
      alignItems: 'stretch',
      marginTop: theme.spacing.s,
      marginLeft: theme.spacing.s,
      marginBottom: theme.spacing.s,
      marginRight: theme.spacing.s
    },
    column: {
      marginLeft: theme.spacing.none,
      marginRight: theme.spacing.none
    },
    doubleField: {
      flexDirection: 'column'
    },
    fieldsSet: {
      marginBottom: theme.spacing.none
    },
    saveButton: {
      justifyContent: 'center'
    }
  };
}).buildHook();
var _default = useProfileStyles;
exports.default = _default;