Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    marginTop: 8
  },
  fullWidth: {
    width: '100%'
  }
});

exports.default = _default;