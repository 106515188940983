var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var InitialState = {
  roles: [],
  features: [],
  rolesByResource: {},
  featuresByResource: {},
  loading: false,
  loaded: false,
  error: null
};
var userRolesReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getUserRoles.pending, function (state) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getUserRoles.fulfilled, function (state, _ref) {
  var payload = _ref.payload;
  (0, _extends2.default)(state, payload);
  state.loading = false;
  state.loaded = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getUserRoles.rejected, function (state, _ref2) {
  var error = _ref2.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return _objectSpread(_objectSpread({}, InitialState), {}, {
    loading: false
  });
}), _createReducer));
var _default = userRolesReducer;
exports.default = _default;