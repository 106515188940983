Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var usePartnersStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      margin: -theme.spacing.xl
    },
    noCategoryMargin: {
      marginVertical: -theme.spacing.sm,
      marginLeft: -theme.spacing.xl
    },
    header: {
      paddingBottom: theme.spacing.m
    },
    subHeader: {
      paddingBottom: theme.spacing.m,
      marginTop: theme.spacing.xl
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  container: {
    flexDirection: 'column',
    justifyContent: 'center'
  }
}).buildHook();
var _default = usePartnersStyles;
exports.default = _default;