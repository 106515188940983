var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RECORDING_TYPES = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _moment = _interopRequireDefault(require("moment"));

var _react = _interopRequireDefault(require("react"));

var _reactContentLoader = _interopRequireDefault(require("react-content-loader"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../core/recordedVideos/selectors");

var _selectors2 = require("../../core/tickets/selectors");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _noAccessToVideos = _interopRequireDefault(require("./components/no-access-to-videos"));

var _noVideosAvailable = _interopRequireDefault(require("./components/no-videos-available"));

var _recordedVideoItemView = _interopRequireDefault(require("./components/recorded-video-item-view"));

var _recordedVideosStyles = _interopRequireDefault(require("./recorded-videos-styles"));

var _monthNames = _interopRequireDefault(require("../../utils/month-names.js"));

var _styles = _interopRequireDefault(require("./styles"));

var RECORDING_TYPES = {
  PRE_RECORDED: 'PreRecorded',
  LIVE_RECORDED: 'LiveRecorded',
  PRESENTATION: 'Presentation'
};
exports.RECORDING_TYPES = RECORDING_TYPES;

var RecordedVideosContainer = function RecordedVideosContainer() {
  var recordedVideos = (0, _reactRedux.useSelector)(_selectors.selectAccessedVideos);
  var accessRecordedSessions = (0, _reactRedux.useSelector)(_selectors2.selectAccessRecordedSessions);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectRecordedVideosIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectRecordedVideosIsLoaded);
  var Styles = (0, _recordedVideosStyles.default)();
  var classes = (0, _styles.default)();
  var recordedVideosMap = {};
  var filteredVideos = recordedVideos.filter(function (video) {
    var unassingedPrerec = video.type === RECORDING_TYPES.PRE_RECORDED && (!video.stageId || !video.startDateTime);
    var lobbyVideo = video.type === RECORDING_TYPES.PRESENTATION && video.displayOnLobby;
    return !unassingedPrerec && !lobbyVideo;
  });
  filteredVideos.forEach(function (video) {
    var key = '';

    if (video.type === RECORDING_TYPES.PRESENTATION) {
      key = video.noCategory ? video.noCategory : _i18n.default.t('videos.otherVideos');
    } else {
      var videoDate = _moment.default.unix(video.date);

      key = "".concat(_i18n.default.translate(_monthNames.default[videoDate.month()]), " ").concat(videoDate.format('D'), ".");
    }

    if (!recordedVideosMap[key]) recordedVideosMap[key] = [];
    recordedVideosMap[key].push(video);
  });

  if (isLoading && !isLoaded) {
    return _react.default.createElement(_Spacing.default, {
      direction: "row",
      alignItems: "center",
      wrap: "wrap",
      style: Styles.container
    }, Array(4).fill('').map(function (_, index) {
      return _react.default.createElement(_Spacing.default, {
        key: index,
        mSpacing: "m"
      }, _react.default.createElement(_reactContentLoader.default, {
        viewBox: "0 0 171 96",
        height: 200,
        width: 340,
        backgroundColor: "#e0e0e0"
      }, _react.default.createElement("rect", {
        x: "0",
        y: "0",
        rx: "0",
        ry: "0",
        width: "171",
        height: "96"
      })));
    }));
  }

  if (!accessRecordedSessions) {
    return _react.default.createElement(_noAccessToVideos.default, null);
  }

  if (filteredVideos.length === 0 && isLoaded) {
    return _react.default.createElement(_noVideosAvailable.default, null);
  }

  if (filteredVideos.length) {
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch"
    }, Object.entries(recordedVideosMap).sort(function (a, b) {
      if (a[0] > b[0]) return 1;else if (a[0] < b[0]) return -1;else return 0;
    }).map(function (_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          videos = _ref2[1];

      return _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "stretch",
        key: key
      }, _react.default.createElement(_core.Typography, {
        variant: "h3",
        className: classes.border
      }, key !== 'noCategory' ? key : _i18n.default.t('videos.otherVideos')), _react.default.createElement(_Spacing.default, {
        direction: "row",
        alignItems: "flex-start",
        wrap: "wrap",
        style: Styles.container
      }, videos.filter(function (video) {
        return video.displayOnLobby === false || [RECORDING_TYPES.LIVE_RECORDED, RECORDING_TYPES.PRE_RECORDED].includes(video.type);
      }).map(function (video) {
        return _react.default.createElement(_recordedVideoItemView.default, {
          video: video,
          key: video.id
        });
      })));
    }));
  }

  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }, _react.default.createElement(_reactNative.ActivityIndicator, null));
};

var _default = RecordedVideosContainer;
exports.default = _default;