var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRegistrationProgress = exports.removeResetPasswordError = exports.removeLoginMailMessage = exports.removeLoginError = exports.launchPasswordResetAction = exports.verifyPasswordResetCode = exports.resetPassword = exports.eventRegistration = exports.userRegistration = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _selectors = require("../event-info/selectors");

var _constants = require("./constants");

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("../user-profile/actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var userRegistration = (0, _toolkit.createAsyncThunk)(_constants.REGISTRATION, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(data, _ref) {
    var dispatch, getState, eventId, registration, customFields, _yield$registration, token, response;

    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            dispatch = _ref.dispatch, getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            registration = _reactNativeFirebase.default.functions.httpsCallable('auth-registration-client');
            customFields = (data === null || data === void 0 ? void 0 : data.customFields) || [];
            _context.next = 6;
            return registration(_objectSpread(_objectSpread({}, data), {}, {
              customFields: customFields.map(function (field) {
                if (field.type !== '3') return field;
                return _objectSpread(_objectSpread(_objectSpread({}, field), field.value), {}, {
                  type: '3'
                });
              }),
              eventId: eventId
            }));

          case 6:
            _yield$registration = _context.sent;
            token = _yield$registration.data;
            _context.next = 10;
            return _reactNativeFirebase.default.auth().signInWithCustomToken(token);

          case 10:
            _context.next = 12;
            return dispatch((0, _actions.getUserProfile)());

          case 12:
            response = _context.sent;
            return _context.abrupt("return", response.payload);

          case 14:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.userRegistration = userRegistration;
var eventRegistration = (0, _toolkit.createAsyncThunk)(_constants.EVENT_REGISTRATION, function () {
  var _ref3 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(data) {
    var customFields, isLoggedIn, email, eventId, saveCustomFields, _yield$saveCustomFiel, response;

    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            customFields = data.customFields, isLoggedIn = data.isLoggedIn, email = data.email, eventId = data.eventId;
            saveCustomFields = _reactNativeFirebase.default.functions.httpsCallable('auth-registration-saveCustomFields');
            _context2.next = 4;
            return saveCustomFields({
              isLoggedIn: isLoggedIn,
              email: email,
              eventId: eventId,
              customFields: customFields.map(function (field) {
                if (field.type !== '3') return field;
                return _objectSpread(_objectSpread(_objectSpread({}, field), field.value), {}, {
                  type: '3'
                });
              })
            });

          case 4:
            _yield$saveCustomFiel = _context2.sent;
            response = _yield$saveCustomFiel.data;
            return _context2.abrupt("return", response);

          case 7:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3) {
    return _ref3.apply(this, arguments);
  };
}());
exports.eventRegistration = eventRegistration;
var resetPassword = (0, _toolkit.createAsyncThunk)(_constants.RESET_PASSWORD, function () {
  var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(_ref4) {
    var email, eventId, sendThePasswordResetEmail;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            email = _ref4.email, eventId = _ref4.eventId;
            sendThePasswordResetEmail = _reactNativeFirebase.default.functions.httpsCallable('emails-sendThePasswordResetEmail');
            _context3.prev = 2;
            _context3.next = 5;
            return sendThePasswordResetEmail({
              eventId: eventId,
              email: email
            });

          case 5:
            return _context3.abrupt("return", true);

          case 8:
            _context3.prev = 8;
            _context3.t0 = _context3["catch"](2);
            console.error('[RESET-PASSWORD]', _context3.t0.message);
            return _context3.abrupt("return", false);

          case 12:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3, null, [[2, 8]]);
  }));

  return function (_x4) {
    return _ref5.apply(this, arguments);
  };
}());
exports.resetPassword = resetPassword;
var verifyPasswordResetCode = (0, _toolkit.createAsyncThunk)(_constants.VERIFY_RESET_PASSWORD, function () {
  var _ref6 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(code) {
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            _context4.next = 2;
            return _reactNativeFirebase.default.auth().verifyPasswordResetCode(code).then(function () {
              return true;
            }).catch(function () {
              return false;
            });

          case 2:
            return _context4.abrupt("return", _context4.sent);

          case 3:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x5) {
    return _ref6.apply(this, arguments);
  };
}());
exports.verifyPasswordResetCode = verifyPasswordResetCode;
var launchPasswordResetAction = (0, _toolkit.createAsyncThunk)(_constants.CONFIRM_RESET_PASSWORD, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(_ref7) {
    var newPassword, code;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            newPassword = _ref7.newPassword, code = _ref7.code;
            _context5.next = 3;
            return _reactNativeFirebase.default.auth().confirmPasswordReset(code, newPassword).then(function () {
              return {
                success: true,
                error: ''
              };
            }).catch(function (error) {
              return {
                success: false,
                error: error.toString()
              };
            });

          case 3:
            return _context5.abrupt("return", _context5.sent);

          case 4:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x6) {
    return _ref8.apply(this, arguments);
  };
}());
exports.launchPasswordResetAction = launchPasswordResetAction;
var removeLoginError = (0, _toolkit.createAction)(_constants.REMOVE_LOGIN_ERROR);
exports.removeLoginError = removeLoginError;
var removeLoginMailMessage = (0, _toolkit.createAction)(_constants.REMOVE_LOGIN_MAIL_MESSAGE);
exports.removeLoginMailMessage = removeLoginMailMessage;
var removeResetPasswordError = (0, _toolkit.createAction)(_constants.REMOVE_RESET_PASSWORD_ERROR);
exports.removeResetPasswordError = removeResetPasswordError;
var setRegistrationProgress = (0, _toolkit.createAction)(_constants.SET_REGISTRATION_PROGRESS);
exports.setRegistrationProgress = setRegistrationProgress;