var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventChatInternal = exports.iosTopMargin = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _ChatComponent = _interopRequireDefault(require("../chat/ChatComponent"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 16;
exports.iosTopMargin = iosTopMargin;

var EventChatInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventChatInternal, _React$PureComponent);

  var _super = _createSuper(EventChatInternal);

  function EventChatInternal() {
    (0, _classCallCheck2.default)(this, EventChatInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(EventChatInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_ChatComponent.default, {
        eventId: this.props.eventId,
        style: {
          maxHeight: '100%',
          marginTop: 44 + iosTopMargin
        }
      })));
    }
  }]);
  return EventChatInternal;
}(_react.default.PureComponent);

exports.EventChatInternal = EventChatInternal;

EventChatInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: _react.default.createElement(_reactNative.View, null),
    headerTransparent: true,
    title: 'Chat',
    headerTitleStyle: {
      color: 'white',
      textAlign: 'center',
      width: '100%'
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'black'
  }
});

var _default = EventChatInternal;
exports.default = _default;