var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeHtmlview = _interopRequireDefault(require("react-native-htmlview"));

var _LinkButton = _interopRequireDefault(require("../../theme/components/buttons/LinkButton"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var DefaultHTMLRenderer = function DefaultHTMLRenderer(_ref) {
  var source = _ref.source,
      stylesheet = _ref.stylesheet;
  return _react.default.createElement(_reactNativeHtmlview.default, {
    value: source,
    stylesheet: _objectSpread({
      h4: {
        fontWeight: 'bold',
        textAlign: 'inherit',
        color: 'inherit'
      },
      p: {
        textAlign: 'inherit',
        fontSize: 'inherit',
        color: 'inherit'
      },
      b: {
        fontWeight: 'bold',
        color: 'inherit'
      },
      strong: {
        fontWeight: 'bold',
        color: 'inherit'
      }
    }, stylesheet),
    addLineBreaks: false,
    onLinkPress: function onLinkPress(url) {},
    renderNode: function renderNode(node, index, siblings, parent, defaultRenderer) {
      if (node.name === 'br') {
        return _react.default.createElement(_reactNative.View, {
          key: index,
          style: {
            height: 16
          }
        });
      }

      if (node.name === 'ul' || node.name === 'ol') {
        return _react.default.createElement(_reactNative.View, {
          style: {
            paddingLeft: 32
          },
          key: index
        }, defaultRenderer(node.children, parent));
      }

      if (node.name === 'a') {
        var _node$children$find;

        return _react.default.createElement(_LinkButton.default, {
          color: "link",
          onPress: function onPress() {
            var url = node.attribs.href;
            var modifiedUrl = '';

            if (url.includes('wwww') || url.includes('http')) {
              modifiedUrl = url;
            } else {
              modifiedUrl = "https://".concat(url);
            }

            window && window.open(modifiedUrl, '_blank');
          },
          key: index
        }, ((_node$children$find = node.children.find(function () {
          return true;
        })) === null || _node$children$find === void 0 ? void 0 : _node$children$find.data) || node.attribs.href);
      }

      if (node.name === 'li') {
        return _react.default.createElement(_reactNative.Text, {
          key: index
        }, defaultRenderer(node.children.map(function (child) {
          return _objectSpread(_objectSpread({}, child), {}, {
            data: "".concat(node.parent && node.parent.name === 'ol' ? "".concat(index + 1, ".") : '•', " ").concat(child.data)
          });
        }), parent));
      }
    }
  });
};

var _default = DefaultHTMLRenderer;
exports.default = _default;