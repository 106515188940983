var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Footer;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _readMore = _interopRequireDefault(require("../utils/read-more"));

var _comeBackLater = _interopRequireDefault(require("../utils/come-back-later"));

var _footerLoading = _interopRequireDefault(require("./footer-loading"));

var _footerStyles = _interopRequireDefault(require("./footer-styles"));

function Footer(props) {
  var isFinished = props.isFinished,
      isEmpty = props.isEmpty,
      isLoading = props.isLoading;
  return _react.default.createElement(_reactNative.View, {
    style: _footerStyles.default.container
  }, isFinished && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_readMore.default, null), _react.default.createElement(_comeBackLater.default, {
    isFinished: isFinished
  })), !isFinished && isEmpty && _react.default.createElement(_comeBackLater.default, null), isLoading && !isEmpty && _react.default.createElement(_footerLoading.default, null));
}