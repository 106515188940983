Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  winnersTitle: {
    color: '#F8E71B',
    fontWeight: 'bold',
    textAlign: 'center'
  }
});

exports.default = _default;