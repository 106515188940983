var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireWildcard(require("immutable"));

var _actionTypes = require("./actionTypes");

var _user = _interopRequireDefault(require("../user"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var InitialState = _immutable.default.fromJS({
  lastReadMessages: (0, _immutable.Map)(),
  hasSeenPushNotificationSuggestion: false
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.READ_NEW_MESSAGES:
      newState = newState.setIn(['lastReadMessages', action.liveId], action.lastMessageKey);
      break;

    case _actionTypes.HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION:
      newState = newState.set('hasSeenPushNotificationSuggestion', true);
      break;

    case _user.default.constants.USER_LOGOUT:
      newState = InitialState;
      break;

    case _actionTypes.TIME_SPENT_IN_LIVE:
      newState = newState.updateIn([action.eventId, 'freeWatchTime'], action.timeSpent, function (value) {
        return value + action.timeSpent;
      });
      break;

    default:
      break;
  }

  return newState;
}