var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.fromJS({
  eventInfo: _immutable.default.Map(),
  eventSlug: '',
  loading: false,
  loaded: false
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.IS_EVENT_INFO_LOADING:
      newState = newState.set('loading', _immutable.default.fromJS(action.loading));
      break;

    case _actionTypes.LOAD_EVENT_INFO_COMPLETED:
      newState = newState.set('loading', _immutable.default.fromJS(false));
      newState = newState.set('eventInfo', _immutable.default.fromJS(action.event));
      newState = newState.set('loaded', _immutable.default.fromJS(true));
      break;

    case _actionTypes.LOAD_EVENT_SLUG_COMPLETED:
      newState = newState.set('eventSlug', _immutable.default.fromJS(action.eventSlug));
      break;

    default:
      break;
  }

  return newState;
}