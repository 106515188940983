var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var appsFlyer = {};
var eventsMap = {};
appsFlyer.trackEvent = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
  return _regenerator.default.wrap(function _callee$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
        case "end":
          return _context.stop();
      }
    }
  }, _callee);
}));

appsFlyer.trackAppLaunch = function () {};

appsFlyer.trackLocation = function (longitude, latitude, callback) {};

appsFlyer.setUserEmails = function (options, successC, errorC) {};

appsFlyer.setAdditionalData = function (additionalData, successC) {};

appsFlyer.getAppsFlyerUID = function (callback) {};

appsFlyer.sendDeepLinkData = function (callback) {};

appsFlyer.setGCMProjectNumber = function (gcmProjectNumber, successC, errorC) {};

appsFlyer.enableUninstallTracking = function (gcmProjectNumber, successC) {};

appsFlyer.updateServerUninstallToken = function (token, successC) {};

appsFlyer.setCustomerUserId = function (userId, successC) {};

appsFlyer.stopTracking = function (isStopTracking, successC) {};

appsFlyer.setCollectIMEI = function (isCollect, successC) {};

appsFlyer.setCollectAndroidID = function (isCollect, successC) {};

appsFlyer.setAppInviteOneLinkID = function (oneLinkID, successC) {};

appsFlyer.generateInviteLink = function (args, successC, errorC) {};

appsFlyer.trackCrossPromotionImpression = function (appId, campaign) {};

appsFlyer.trackAndOpenStore = function (appId, campaign, params) {};

appsFlyer.onInstallConversionData = function (callback) {};

appsFlyer.onAppOpenAttribution = function (callback) {};

function AFParseJSONException(_message, _data) {
  this.message = _message;
  this.data = _data;
  this.name = "AFParseJSONException";
}

var _default = appsFlyer;
exports.default = _default;