Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.progressBarStyles = exports.headerTintColor = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column'
  },
  listContentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start'
  },
  headerTitleStyle: {
    flex: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 13
  },
  headerStyle: {
    backgroundColor: '#000',
    borderBottomColor: '#000'
  },
  loader: {
    flex: 1,
    justifyContent: 'center'
  },
  loadingContainer: {
    position: 'absolute',
    justifyContent: 'flex-start',
    width: '100%',
    left: 0,
    top: 0
  },
  leaderboardHeader: {
    padding: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16
  },
  headerHeadline: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 16
  },
  headerBanner: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8E71B',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    overflow: 'hidden'
  },
  bannerTitle: {
    color: 'black',
    width: 0,
    flexGrow: 1,
    flex: 1
  },
  announcingWinnersContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
    flexWrap: 'wrap',
    marginHorizontal: 16,
    paddingBottom: 16,
    marginBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255,255,255,0.2)'
  },
  announcingWinnersTitle: {
    fontWeight: 'bold'
  },
  winnersTitle: {
    color: '#F8E71B',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  leaderboardCountdown: {
    fontWeight: 'bold'
  }
});

exports.default = _default;
var headerTintColor = '#fff';
exports.headerTintColor = headerTintColor;
var progressBarStyles = {
  progress: 0.3,
  width: null,
  height: 2,
  borderWidth: 0,
  borderRadius: 0,
  color: '#fff',
  unfilledColor: 'rgba(0,0,0,0.5)'
};
exports.progressBarStyles = progressBarStyles;