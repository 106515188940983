var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocalVideo;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _Video = _interopRequireDefault(require("./Video"));

function LocalVideo(_ref) {
  var width = _ref.width,
      height = _ref.height,
      _ref$absoluteFill = _ref.absoluteFill,
      absoluteFill = _ref$absoluteFill === void 0 ? false : _ref$absoluteFill,
      _ref$children = _ref.children,
      children = _ref$children === void 0 ? null : _ref$children,
      _ref$playingAnotherPl = _ref.playingAnotherPlace,
      playingAnotherPlace = _ref$playingAnotherPl === void 0 ? false : _ref$playingAnotherPl,
      _ref$showSettingsPrev = _ref.showSettingsPreview,
      showSettingsPreview = _ref$showSettingsPrev === void 0 ? false : _ref$showSettingsPrev;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      videoTrack = _useAudioVideoContext.videoTrack,
      videoEnabled = _useAudioVideoContext.videoEnabled,
      settingsDialogOpen = _useAudioVideoContext.settingsDialogOpen;

  if (!videoTrack) return null;
  if (!videoEnabled) return _react.default.createElement(_react.default.Fragment, null, children);

  var videoComponent = _react.default.createElement(_Video.default, {
    videoTrack: videoTrack,
    width: width,
    height: height,
    mirror: false,
    absoluteFill: absoluteFill
  });

  if (showSettingsPreview) {
    return videoComponent;
  }

  if (playingAnotherPlace) {
    if (settingsDialogOpen) return _react.default.createElement(_react.default.Fragment, null, children);
  } else {
    if (!settingsDialogOpen) return _react.default.createElement(_react.default.Fragment, null, children);
  }

  return videoComponent;
}