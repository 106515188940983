var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/global/selectors");

function DialogService() {
  var dialogs = (0, _reactRedux.useSelector)(_selectors.dialogsSelector);
  return dialogs.map(function (_ref) {
    var id = _ref.id,
        content = _ref.content;
    return _react.default.createElement("div", {
      key: id
    }, content);
  });
}

var _default = _react.default.memo(DialogService);

exports.default = _default;