var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _asyncActionHandler = _interopRequireDefault(require("../../utils/redux/async-action-handler"));

var _constants = require("../event-info/constants");

var _actions = require("../session/actions");

var _actions2 = require("../tickets/actions");

var _actions3 = require("../user-roles/actions");

var _constants2 = require("./constants");

function createMiddleware() {
  return function (store) {
    var asyncActionHandler = new _asyncActionHandler.default(function () {
      store.dispatch((0, _actions3.getUserRoles)());
      store.dispatch((0, _actions2.getTickets)());
      store.dispatch((0, _actions.getSessions)());
      store.dispatch((0, _actions2.getBoughtTickets)());
    }).waitEvery({
      type: _constants2.FIREBASE_AUTH_STATE_CHANGED
    }).waitOnce({
      type: _constants.LOAD_EVENT_INFO_COMPLETED
    });
    return function (next) {
      return function () {
        var _ref = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(action) {
          var result;
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  result = next(action);
                  asyncActionHandler.next(action);
                  return _context.abrupt("return", result);

                case 3:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        return function (_x) {
          return _ref.apply(this, arguments);
        };
      }();
    };
  };
}