var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _react = _interopRequireWildcard(require("react"));

var _dateFormatter = require("../../utils/date-formatter");

var _calendarButtonView = _interopRequireDefault(require("./days/calendar-button-view"));

var _calendarSessionListView = _interopRequireDefault(require("./list/calendar-session-list-view"));

var _sessionCalendarTypes = require("./session-calendar-types");

var _calendarStageView = _interopRequireDefault(require("./stages/calendar-stage-view"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _sessionsCalendarStyles = _interopRequireDefault(require("./sessions-calendar-styles"));

var _reactRedux = require("react-redux");

var _eventSettings = _interopRequireDefault(require("../../core/event-settings"));

var _liveDetailsAndPreview = _interopRequireDefault(require("./preview-with-details/live-details-and-preview"));

var _useLiveSessionState = require("../../utils/hooks/useLiveSessionState");

var _routesConsts = require("../../navigation/client/routes-consts");

var _reactNavigationHooks = require("react-navigation-hooks");

var _actions = require("../../core/event-stages/actions");

var _selectors = require("../../core/event-stages/selectors");

var _styles = _interopRequireDefault(require("./styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var DATE_ONLY = 'YYYY/MM/DD';

var SessionsCalendarView = function SessionsCalendarView(props) {
  var stages = props.stages,
      sessions = props.sessions,
      startDate = props.startDate,
      endDate = props.endDate,
      improvedWithPreview = props.improvedWithPreview,
      showJoinLiveButton = props.showJoinLiveButton,
      onAvatarNavigation = props.onAvatarNavigation,
      onLiveSessionNavigation = props.onLiveSessionNavigation,
      onNetworkingSessionNavigation = props.onNetworkingSessionNavigation,
      hasTicket = props.hasTicket,
      eventName = props.eventName,
      eventTimezone = props.eventTimezone,
      eventDateFormat = props.eventDateFormat;
  var Styles = (0, _sessionsCalendarStyles.default)();
  var classes = (0, _styles.default)();
  var liveId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.LIVE_ID);
  var backstageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BACKSTAGE_ID);
  var tableId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);

  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      selectedDay = _useState2[0],
      setSelectedDay = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      selectedStage = _useState4[0],
      setSelectedStage = _useState4[1];

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      scheduleTitle = _useSelector.Schedule;

  var selectedStageId = (0, _reactRedux.useSelector)(_selectors.selectSelectedStage);
  var live = (0, _useLiveSessionState.useLiveSessionState)(selectedStage);

  var onSelectingStage = function onSelectingStage(selectedStage) {
    dispatch((0, _actions.listenSelectedStage)(selectedStage.id));
  };

  var dispatch = (0, _reactRedux.useDispatch)();
  var sessionForTableId = (0, _react.useMemo)(function () {
    var _sessions$find;

    return (_sessions$find = sessions.find(function (session) {
      return tableId && session.networkTable === tableId;
    })) === null || _sessions$find === void 0 ? void 0 : _sessions$find.resourceId;
  }, [sessions, tableId]);
  var stageId = (0, _react.useMemo)(function () {
    if (selectedStageId) {
      return selectedStageId;
    }

    if (sessionForTableId) {
      return sessionForTableId;
    }

    if (liveId) {
      return liveId;
    }

    if (backstageId) {
      return backstageId;
    }

    return null;
  }, [sessionForTableId, selectedStageId, liveId, backstageId]);
  (0, _react.useEffect)(function () {
    setSelectedStage(stageId ? stages.find(function (stage) {
      return stage.id === stageId;
    }) : stages.find(function () {
      return true;
    }));
  }, [stages, stageId, setSelectedStage]);
  (0, _react.useEffect)(function () {
    setSelectedDay((0, _dateFormatter.isNowInDateTimeRange)(startDate, endDate) && eventHasSessionsToday() ? (0, _momentTimezone.default)().format('YYYY/MM/DD') : getLastDayWithSessions());
  }, [endDate, startDate, sessions]);

  var getLastDayWithSessions = function getLastDayWithSessions() {
    var lastDayWithSessions = startDate;
    sessions.forEach(function (session) {
      if (session.start >= lastDayWithSessions) {
        lastDayWithSessions = session.start;
      }
    });
    return lastDayWithSessions;
  };

  var eventHasSessionsToday = function eventHasSessionsToday() {
    return sessions.some(function (session) {
      return (0, _momentTimezone.default)(session.start).format('YYYY/MM/DD') === (0, _momentTimezone.default)().format('YYYY/MM/DD');
    });
  };

  var stage = selectedStage ? selectedStage : stages[0];

  var getEventDays = function getEventDays() {
    var start = (0, _momentTimezone.default)(startDate);
    var end = (0, _momentTimezone.default)(endDate);
    var dateArray = [];

    while (start.isSameOrBefore(end, 'day')) {
      dateArray.push(start.toDate());
      start = (0, _momentTimezone.default)(start).add(1, 'days');
    }

    var filteredDateArray = dateArray.filter(function (s) {
      return sessions.some(function (x) {
        return (0, _momentTimezone.default)(x.start).isSame((0, _momentTimezone.default)(s), 'day');
      });
    });
    return filteredDateArray;
  };

  function isSelectedStage(stage) {
    if (selectedStage) {
      return selectedStage.id === stage.id;
    }

    return false;
  }

  var eventDays = getEventDays();
  return _react.default.createElement(_react.default.Fragment, null, sessions && sessions.length > 0 && _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xl', 0],
    style: Styles.header
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, scheduleTitle || _i18n.default.t('header.schedule'))), _react.default.createElement(_Spacing.default, {
    style: Styles.daySelection,
    key: "days"
  }, eventDays.map(function (day) {
    var selected = (0, _momentTimezone.default)(selectedDay).format(DATE_ONLY) === (0, _momentTimezone.default)(day).format(DATE_ONLY);
    return _react.default.createElement(_calendarButtonView.default, {
      day: day,
      key: "".concat(day.getMonth()).concat(day.getDate()),
      selected: selected,
      daySelected: function daySelected(day) {
        return setSelectedDay(day);
      }
    });
  })), stages.length > 1 && !backstageId && _react.default.createElement(_Spacing.default, {
    style: Styles.stageSelection,
    self: "left",
    key: "stages"
  }, stages.map(function (stage) {
    return _react.default.createElement(_calendarStageView.default, {
      stage: stage,
      key: stage.id,
      selected: isSelectedStage(stage),
      stageSelected: function stageSelected(selectedStage) {
        onSelectingStage(selectedStage);
      }
    });
  })), improvedWithPreview && _react.default.createElement(_liveDetailsAndPreview.default, {
    live: live,
    selectedDay: selectedDay,
    onAvatarNavigation: onAvatarNavigation,
    onLiveSessionNavigation: onLiveSessionNavigation,
    eventDateFormat: eventDateFormat,
    onNetworkingSessionNavigation: onNetworkingSessionNavigation
  }), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    key: "session-list"
  }, _react.default.createElement(_calendarSessionListView.default, {
    sessions: sessions,
    selectedDay: selectedDay,
    selectedStage: selectedStage,
    onAvatarNavigation: onAvatarNavigation,
    hasTicket: hasTicket,
    onLiveSessionNavigation: onLiveSessionNavigation,
    onNetworkingSessionNavigation: onNetworkingSessionNavigation,
    eventName: eventName,
    eventTimezone: eventTimezone,
    eventDateFormat: eventDateFormat,
    improved: improvedWithPreview,
    showJoinLiveButton: showJoinLiveButton
  }))));
};

var _default = SessionsCalendarView;
exports.default = _default;