var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactJwPlayer = _interopRequireDefault(require("react-jw-player"));

var _reactNative = require("react-native");

var _videoReact = require("../video-react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var VideoComponent = function (_React$PureComponent) {
  (0, _inherits2.default)(VideoComponent, _React$PureComponent);

  var _super = _createSuper(VideoComponent);

  function VideoComponent() {
    var _this;

    (0, _classCallCheck2.default)(this, VideoComponent);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.player = void 0;
    _this.state = {
      isPlayerReady: false,
      id: "id-".concat(Math.random())
    };

    _this.onReady = function () {
      _this.player = window.jwplayer(_this.state.id);
      var muxMetaDataInfo = _this.props.muxMetaDataInfo;

      if (muxMetaDataInfo) {
        var data = _objectSpread({
          env_key: process.env.MUX_DATA_KEY,
          player_name: 'JWPlayer',
          player_version: _this.player.version,
          player_init_time: Date.now()
        }, muxMetaDataInfo);

        initJWPlayerMux(_this.player, {
          debug: false,
          data: data
        });
      }

      _this.setState({
        isPlayerReady: true
      });

      _this.player.setControls(true);

      _this.props.onReady();
    };

    return _this;
  }

  (0, _createClass2.default)(VideoComponent, [{
    key: "setNativeProps",
    value: function setNativeProps(nativeProps) {
      if (this._root && _reactNative.Platform.OS === 'web') this._root.setNativeProps(nativeProps);
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      if (this.state.isPlayerReady) {
        this.player.setMute(nextProps.muted);
      }
    }
  }, {
    key: "render",
    value: function render() {
      if (this.props.jwplayer) {
        return _react.default.createElement(_reactJwPlayer.default, {
          playerId: this.state.id,
          onReady: this.onReady,
          playerScript: "https://cdn.jwplayer.com/libraries/q0w9ppJ0.js",
          onError: this.props.onError,
          onBuffer: this.props.onBuffer,
          onBufferChange: this.props.onBufferChange,
          onProgress: this.props.onProgress,
          onPlay: this.props.onPlay,
          onPause: this.props.onPause,
          onCast: this.props.onCast,
          onResume: this.props.onResume,
          onAutoStart: this.props.onAutoStart,
          onMute: this.props.onMute,
          onUnmute: this.props.onUnmute,
          isMuted: this.props.muted,
          file: this.props.source.uri,
          isAutoPlay: this.props.autoPlay,
          onComplete: this.props.onComplete,
          onOneHundredPercent: this.props.onOneHundredPercent,
          repeat: false,
          customProps: {
            repeat: false
          }
        });
      } else {
        return _react.default.createElement(_videoReact.Video, {
          autoPlay: true,
          muted: this.props.muted,
          width: '100%',
          height: '100%',
          loop: true,
          src: this.props.source.uri,
          fluid: true
        }, _react.default.createElement(_videoReact.ControlBar, {
          disableCompletely: true
        }));
      }
    }
  }]);
  return VideoComponent;
}(_react.default.PureComponent);

var _default = VideoComponent;
exports.default = _default;