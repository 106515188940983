var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _profile = _interopRequireDefault(require("../../../core/profile"));

var _DjBase = _interopRequireDefault(require("./DjBase"));

function mapStateToProps(state) {
  return {
    djs: _profile.default.selectors.getDjs(state),
    djExplore: true
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadDjs: _profile.default.actions.loadDjs
})(_DjBase.default);

exports.default = _default;