Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  headerBanner: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8E71B',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    overflow: 'hidden'
  },
  bannerTitle: {
    color: 'black',
    flexGrow: 1,
    flex: 1,
    marginRight: 16
  }
});

exports.default = _default;