var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Checkbox;

var _formik = require("formik");

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Typography = _interopRequireDefault(require("../typography/Typography"));

function Checkbox(_ref) {
  var name = _ref.name,
      color = _ref.color,
      activeColor = _ref.activeColor,
      variant = _ref.variant,
      label = _ref.label,
      labelColor = _ref.labelColor,
      onChange = _ref.onChange,
      value = _ref.value;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_formik.Field, {
    name: name,
    id: name,
    onChange: onChange,
    type: "checkbox"
  }, function (_ref2) {
    var field = _ref2.field,
        form = _ref2.form;
    return _react.default.createElement(_reactNative.TouchableHighlight, {
      underlayColor: "#FFFFFFFF",
      style: {
        maxWidth: 'max-content'
      },
      onPress: function onPress() {
        form.setFieldValue(name, !Boolean(value));
        field.onChange(name);
        field.onBlur(name);
      }
    }, _react.default.createElement(_reactNative.View, {
      style: {
        maxWidth: 'max-content',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
      }
    }, _react.default.createElement(_Typography.default, {
      style: {
        lineHeight: 1
      },
      color: Boolean(value) ? activeColor : color,
      variant: variant
    }, Boolean(value) ? _react.default.createElement(_reactFeather.CheckSquare, {
      fontSize: "1rem"
    }) : _react.default.createElement(_reactFeather.Square, {
      fontSize: "1rem"
    })), label && _react.default.createElement(_Typography.default, {
      style: {
        marginLeft: spacing.s
      },
      variant: variant,
      color: labelColor
    }, label) || null));
  });
}