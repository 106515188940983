var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.headerForEvent = exports.headerMyProfile = exports.headerWithLeftBackArrow = exports.disableHeader = exports.mapNavigationStateParamsToProps = exports.headerStyleBlack = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _web = require("@react-navigation/web");

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _reactNavigationStack = require("react-navigation-stack");

var _reactNavigationTabs = require("react-navigation-tabs");

var _CustomIcon = _interopRequireDefault(require("../assets/icons/CustomIcon.js"));

var _reportOrBlock = _interopRequireDefault(require("../components/chat/report-or-block"));

var _ReportChatMessage = _interopRequireDefault(require("../components/chat/ReportChatMessage"));

var _ErrorPopup = _interopRequireDefault(require("../components/common/ErrorPopup"));

var _PaymentPopup = _interopRequireDefault(require("../components/common/PaymentPopup"));

var _ThankyouPopup = _interopRequireDefault(require("../components/common/ThankyouPopup"));

var _VipBadge = _interopRequireDefault(require("../components/common/vip/VipBadge"));

var _VipPopup = _interopRequireDefault(require("../components/common/vip/VipPopup"));

var _EventChat = _interopRequireDefault(require("../components/event/EventChat"));

var _EventChatSection = _interopRequireDefault(require("../components/event/EventChatSection"));

var _EventDetail = _interopRequireDefault(require("../components/event/EventDetail"));

var _ExploreScreen = _interopRequireDefault(require("../components/explore/ExploreScreen"));

var _NoEventsPopup = _interopRequireDefault(require("../components/explore/NoEventsPopup"));

var _FestivalAboutScreen = _interopRequireDefault(require("../components/festival/FestivalAboutScreen"));

var _FestivalScreen = _interopRequireDefault(require("../components/festival/FestivalScreen"));

var _HomeScreen = _interopRequireDefault(require("../components/homepage/HomeScreen"));

var _leaderboardScreen = _interopRequireDefault(require("../components/leaderboard-screen"));

var _aboutScreen = _interopRequireDefault(require("../components/leaderboard-screen/about-screen"));

var _EventLive = _interopRequireDefault(require("../components/live/EventLive"));

var _LiveEventDetails = _interopRequireDefault(require("../components/live/LiveEventDetails"));

var _mybeatsScreen = _interopRequireDefault(require("../components/mybeats-screen"));

var _mybeatsInfoScreenView = _interopRequireDefault(require("../components/mybeats-screen/mybeats-info-screen-view"));

var _withdraw = _interopRequireDefault(require("../components/mybeats-screen/withdraw"));

var _DjProfileScreen = _interopRequireDefault(require("../components/profile/DjProfileScreen"));

var _EditProfileScreen = _interopRequireDefault(require("../components/profile/EditProfileScreen"));

var _FollowersScreen = _interopRequireDefault(require("../components/profile/FollowersScreen"));

var _FollowingScreen = _interopRequireDefault(require("../components/profile/FollowingScreen"));

var _GenreDetailScreen = _interopRequireDefault(require("../components/profile/GenreDetailScreen"));

var _MyProfileScreen = _interopRequireDefault(require("../components/profile/MyProfileScreen"));

var _ProfileScreen = _interopRequireDefault(require("../components/profile/ProfileScreen"));

var _ReferralList = _interopRequireDefault(require("../components/referral/ReferralList"));

var _WithDeferredDeepLink = _interopRequireDefault(require("../components/referral/WithDeferredDeepLink"));

var _blockedUsers = _interopRequireDefault(require("../components/settings/blocked-users"));

var _GiftsPaymentsScreen = _interopRequireDefault(require("../components/settings/GiftsPaymentsScreen"));

var _SettingsScreen = _interopRequireDefault(require("../components/settings/SettingsScreen"));

var _AllowPermissionsInstructions = _interopRequireDefault(require("../components/user/AllowPermissionsInstructions"));

var _LoginScreen = _interopRequireDefault(require("../components/user/LoginScreen"));

var _NavigationButtons = require("./NavigationButtons");

var _NavigationStyle = require("./NavigationStyle");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var headerStyleBlack = {
  backgroundColor: '#000',
  borderBottomColor: '#000'
};
exports.headerStyleBlack = headerStyleBlack;

var homeTabIcon = function homeTabIcon(_ref) {
  var tintColor = _ref.tintColor,
      focused = _ref.focused;
  return focused ? _react.default.createElement(_CustomIcon.default, {
    name: "tickets-on",
    size: 24,
    color: tintColor
  }) : _react.default.createElement(_CustomIcon.default, {
    name: "tickets",
    size: 24,
    color: tintColor
  });
};

var exploreTabIcon = function exploreTabIcon(_ref2) {
  var tintColor = _ref2.tintColor,
      focused = _ref2.focused;
  return focused ? _react.default.createElement(_CustomIcon.default, {
    name: "discover-on",
    size: 24,
    color: tintColor
  }) : _react.default.createElement(_CustomIcon.default, {
    name: "discover",
    size: 24,
    color: tintColor
  });
};

var leaderboardTabIcon = function leaderboardTabIcon(_ref3) {
  var tintColor = _ref3.tintColor,
      focused = _ref3.focused;
  return focused ? _react.default.createElement(_CustomIcon.default, {
    name: "leaderboard-on",
    size: 24,
    color: tintColor
  }) : _react.default.createElement(_CustomIcon.default, {
    name: "leaderboard",
    size: 24,
    color: tintColor
  });
};

var myBeatsTabIcon = function myBeatsTabIcon(_ref4) {
  var tintColor = _ref4.tintColor,
      focused = _ref4.focused;
  return focused ? _react.default.createElement(_CustomIcon.default, {
    name: "beats",
    size: 24,
    color: tintColor
  }) : _react.default.createElement(_CustomIcon.default, {
    name: "beats-outline",
    size: 24,
    color: tintColor
  });
};

var profileTabIcon = function profileTabIcon(_ref5) {
  var tintColor = _ref5.tintColor,
      focused = _ref5.focused;
  return focused ? _react.default.createElement(_CustomIcon.default, {
    name: "profile-on",
    size: 24,
    color: tintColor
  }) : _react.default.createElement(_CustomIcon.default, {
    name: "profile",
    size: 24,
    color: tintColor
  });
};

var mapNavigationStateParamsToProps = function mapNavigationStateParamsToProps(ScreenComponent) {
  var _class, _temp;

  return _temp = _class = function (_Component) {
    (0, _inherits2.default)(_class, _Component);

    var _super = _createSuper(_class);

    function _class() {
      (0, _classCallCheck2.default)(this, _class);
      return _super.apply(this, arguments);
    }

    (0, _createClass2.default)(_class, [{
      key: "render",
      value: function render() {
        var params = this.props.navigation.state.params;
        return _react.default.createElement(ScreenComponent, (0, _extends2.default)({}, this.props, params));
      }
    }]);
    return _class;
  }(_react.Component), _class.displayName = "mapNavigationStateParamsToProps(".concat(ScreenComponent.displayName || ScreenComponent.name, ")"), _class.navigationOptions = ScreenComponent.navigationOptions, _temp;
};

exports.mapNavigationStateParamsToProps = mapNavigationStateParamsToProps;

var disableHeader = function disableHeader() {
  return {
    header: null,
    headerStyle: headerStyleBlack
  };
};

exports.disableHeader = disableHeader;

var headerWithLeftBackArrow = function headerWithLeftBackArrow(_ref6) {
  var navigation = _ref6.navigation;
  return {
    headerStyle: headerStyleBlack,
    headerTitleStyle: {
      flex: 1,
      fontSize: 14,
      textAlign: 'center'
    },
    headerRight: _react.default.createElement(_reactNative.View, null),
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerTransparent: false,
    headerTintColor: 'white'
  };
};

exports.headerWithLeftBackArrow = headerWithLeftBackArrow;

var headerMyProfile = function headerMyProfile(_ref7) {
  var navigation = _ref7.navigation;
  return {
    headerStyle: headerStyleBlack,
    headerTitleStyle: {
      flex: 1,
      textAlign: 'center',
      alignSelf: 'center',
      fontSize: 13
    },
    headerLeft: _react.default.createElement(_reactNative.View, null),
    headerRight: (0, _NavigationButtons.settingsButton)(navigation),
    title: 'My Profile',
    headerTintColor: 'white'
  };
};

exports.headerMyProfile = headerMyProfile;

var headerForEvent = function headerForEvent(_ref8) {
  var navigation = _ref8.navigation;
  return {
    headerLeft: _react.default.createElement(_reactNative.View, null),
    headerRight: (0, _NavigationButtons.xButton)(navigation),
    headerTransparent: true
  };
};

exports.headerForEvent = headerForEvent;
var MyProfileStack = (0, _reactNavigationStack.createStackNavigator)({
  MyProfile: {
    screen: _MyProfileScreen.default,
    path: 'myaccount',
    navigationOptions: headerMyProfile,
    headerTransparent: true
  },
  Settings: {
    screen: _SettingsScreen.default,
    path: 'settings',
    headerTransparent: true
  },
  GiftsPayments: {
    screen: mapNavigationStateParamsToProps(_GiftsPaymentsScreen.default)
  },
  BlockedUsers: {
    screen: mapNavigationStateParamsToProps(_blockedUsers.default)
  },
  EditProfile: {
    screen: mapNavigationStateParamsToProps(_EditProfileScreen.default)
  },
  Followers: {
    screen: mapNavigationStateParamsToProps(_FollowersScreen.default)
  },
  Following: {
    screen: mapNavigationStateParamsToProps(_FollowingScreen.default)
  }
}, {
  navigationOptions: {
    tabBarLabel: 'Profile',
    tabBarIcon: profileTabIcon
  }
});
var EventStack = (0, _reactNavigationStack.createStackNavigator)({
  EventDetail: {
    screen: mapNavigationStateParamsToProps(_EventDetail.default),
    path: 'event/:eventId',
    headerTransparent: true
  },
  ChatModal: _EventChatSection.default
}, {
  transparentCard: true,
  mode: 'modal',
  headerMode: 'none',
  defaultNavigationOptions: {
    gesturesEnabled: true
  },
  transitionConfig: _NavigationStyle.transitionConfigForAnimations
});
var FestivalStack = (0, _reactNavigationStack.createStackNavigator)({
  Festival: {
    screen: mapNavigationStateParamsToProps(_FestivalScreen.default),
    path: 'festival/:festivalId',
    headerTransparent: true
  },
  FestivalAboutScreen: {
    screen: mapNavigationStateParamsToProps(_FestivalAboutScreen.default),
    headerTransparent: true
  }
}, {
  transparentCard: true,
  mode: 'modal',
  defaultNavigationOptions: {
    gesturesEnabled: true
  },
  transitionConfig: _NavigationStyle.transitionConfigForAnimations
});
var EventLiveStack = (0, _reactNavigationStack.createStackNavigator)({
  EventLive: {
    screen: mapNavigationStateParamsToProps(_EventLive.default),
    path: 'live/:liveId'
  },
  LiveDetailsModal: _LiveEventDetails.default
}, {
  transparentCard: true,
  mode: 'modal',
  headerMode: 'none',
  defaultNavigationOptions: {
    gesturesEnabled: true
  },
  transitionConfig: _NavigationStyle.transitionConfigForAnimations
});
var HomeStack = (0, _reactNavigationStack.createStackNavigator)({
  Home: {
    screen: _HomeScreen.default,
    navigationOptions: disableHeader,
    path: 'feed'
  }
});
var FeedStack = (0, _reactNavigationStack.createStackNavigator)({
  Home: {
    screen: HomeStack,
    navigationOptions: disableHeader,
    headerTransparent: true
  },
  Festival: {
    screen: FestivalStack,
    navigationOptions: disableHeader,
    path: 'festival/:festivalId',
    headerTransparent: true
  },
  EventLive: {
    screen: EventLiveStack,
    navigationOptions: disableHeader,
    path: 'live/:liveId'
  },
  EventDetail: {
    screen: EventStack,
    path: 'event/:eventId',
    navigationOptions: headerForEvent,
    headerTransparent: true
  },
  EventChat: {
    screen: mapNavigationStateParamsToProps(_EventChat.default),
    headerTransparent: true
  },
  Profile: {
    screen: mapNavigationStateParamsToProps(_ProfileScreen.default),
    path: 'profile/:userId',
    headerTransparent: true
  },
  DjProfile: {
    screen: mapNavigationStateParamsToProps(_DjProfileScreen.default),
    path: 'profile/:userId',
    headerTransparent: true
  },
  GenreDetail: {
    screen: mapNavigationStateParamsToProps(_GenreDetailScreen.default),
    path: 'events/:hashtag',
    headerTransparent: true
  },
  Followers: {
    screen: mapNavigationStateParamsToProps(_FollowersScreen.default)
  },
  Following: {
    screen: mapNavigationStateParamsToProps(_FollowingScreen.default)
  },
  ReferralList: {
    screen: mapNavigationStateParamsToProps(_ReferralList.default),
    path: 'referrals'
  }
}, {
  disableRouteNamePaths: true,
  navigationOptions: {
    tabBarLabel: 'Feed',
    tabBarIcon: homeTabIcon
  }
});

FeedStack.navigationOptions = function (_ref9) {
  var navigation = _ref9.navigation;
  var routeName = navigation.state.routes[navigation.state.index].routeName;
  var navigationOptions = {
    tabBarLabel: 'My events',
    tabBarIcon: homeTabIcon,
    tabBarVisible: true
  };

  if (routeName === 'EventLive') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'EventDetail') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'EventChat') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'DjProfile') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'Profile') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'GenreDetail') {
    navigationOptions.tabBarVisible = false;
  }

  return navigationOptions;
};

var ExploreStack = (0, _reactNavigationStack.createStackNavigator)({
  Explore: {
    screen: _ExploreScreen.default,
    navigationOptions: disableHeader,
    headerTransparent: true
  },
  EventLive: {
    screen: EventLiveStack,
    navigationOptions: disableHeader,
    path: 'live/:liveId'
  },
  EventDetail: {
    screen: EventStack,
    path: 'event/:eventId',
    navigationOptions: headerForEvent,
    headerTransparent: true
  },
  Profile: {
    screen: mapNavigationStateParamsToProps(_ProfileScreen.default),
    path: 'profile/:userId',
    headerTransparent: true
  },
  DjProfile: {
    screen: mapNavigationStateParamsToProps(_DjProfileScreen.default),
    path: 'profile/:userId',
    headerTransparent: true
  },
  Followers: {
    screen: mapNavigationStateParamsToProps(_FollowersScreen.default)
  },
  Following: {
    screen: mapNavigationStateParamsToProps(_FollowingScreen.default)
  }
}, {
  disableRouteNamePaths: true,
  navigationOptions: {
    tabBarLabel: 'Discover',
    tabBarIcon: exploreTabIcon
  }
});
var LeaderboardStack = (0, _reactNavigationStack.createStackNavigator)({
  LeaderboardList: {
    screen: _leaderboardScreen.default,
    path: 'leaderboard',
    headerTransparent: true
  },
  Profile: {
    screen: mapNavigationStateParamsToProps(_ProfileScreen.default),
    path: 'profile/:userId',
    headerTransparent: true
  },
  LeaderboardAbout: _aboutScreen.default
}, {
  disableRouteNamePaths: true,
  navigationOptions: {
    tabBarLabel: 'Leaderboard',
    tabBarIcon: leaderboardTabIcon
  }
});

LeaderboardStack.navigationOptions = function (_ref10) {
  var navigation = _ref10.navigation;
  var routeName = navigation.state.routes[navigation.state.index].routeName;
  var navigationOptions = {
    tabBarLabel: 'Leaderboard',
    tabBarIcon: leaderboardTabIcon,
    tabBarVisible: true
  };

  if (routeName === 'Profile') {
    navigationOptions.tabBarVisible = false;
  }

  if (routeName === 'LeaderboardAbout') {
    navigationOptions.tabBarVisible = false;
  }

  return navigationOptions;
};

var MyBeatsStack = (0, _reactNavigationStack.createStackNavigator)({
  MyBeats: {
    screen: _mybeatsScreen.default,
    path: 'mybeats',
    navigationOptions: disableHeader,
    headerTransparent: true
  },
  MyBeatsInfo: {
    screen: mapNavigationStateParamsToProps(_mybeatsInfoScreenView.default),
    navigationOptions: headerWithLeftBackArrow,
    headerTransparent: true
  }
}, {
  disableRouteNamePaths: true,
  navigationOptions: {
    tabBarLabel: 'My Beats',
    tabBarIcon: myBeatsTabIcon
  }
});

MyBeatsStack.navigationOptions = function (_ref11) {
  var navigation = _ref11.navigation;
  var routeName = navigation.state.routes[navigation.state.index].routeName;
  var navigationOptions = {
    tabBarLabel: 'My Beats',
    tabBarIcon: myBeatsTabIcon,
    tabBarVisible: true
  };

  if (routeName === 'My beats info') {
    navigationOptions.tabBarVisible = true;
  }

  return navigationOptions;
};

var TabNavigator = (0, _reactNavigationTabs.createBottomTabNavigator)({
  Feed: FeedStack,
  Explore: ExploreStack,
  MyProfile: MyProfileStack
}, {
  disableRouteNamePaths: true,
  tabBarOptions: {
    activeTintColor: '#141414',
    inactiveBackgroundColor: '#fff',
    activeBackgroundColor: '#fff',
    safeAreaInset: {
      top: 'never',
      bottom: 'always',
      left: 'never',
      right: 'never'
    },
    showLabel: true,
    labelStyle: {
      fontSize: 13,
      padding: 0,
      marginBottom: 5,
      marginTop: -5
    },
    style: {
      backgroundColor: '#fff',
      height: 64,
      borderTopColor: 'white'
    }
  }
});
var AppWithModalStack = (0, _reactNavigationStack.createStackNavigator)({
  TabNavigator: TabNavigator,
  Login: _LoginScreen.default,
  PermissionsInstructions: _AllowPermissionsInstructions.default,
  ReportChatMessage: mapNavigationStateParamsToProps(_ReportChatMessage.default),
  ReportOrBlockModal: mapNavigationStateParamsToProps(_reportOrBlock.default),
  PaymentPopup: mapNavigationStateParamsToProps(_PaymentPopup.default),
  NoEventsPopup: _NoEventsPopup.default,
  VipBadge: mapNavigationStateParamsToProps(_VipBadge.default),
  VipPopup: mapNavigationStateParamsToProps(_VipPopup.default),
  ThankyouPopup: mapNavigationStateParamsToProps(_ThankyouPopup.default),
  ErrorPopup: mapNavigationStateParamsToProps(_ErrorPopup.default),
  WithdrawModal: mapNavigationStateParamsToProps(_withdraw.default)
}, {
  disableRouteNamePaths: true,
  transparentCard: true,
  mode: 'modal',
  headerMode: 'none',
  defaultNavigationOptions: {
    gesturesEnabled: false
  },
  headerStyle: {
    backgroundColor: '#000'
  },
  transitionConfig: _NavigationStyle.transitionConfigOnlyOpacity
});
var createApp = (0, _reactNavigation.createAppContainer)(AppWithModalStack);

if (_reactNative.Platform.OS === 'web') {
  createApp = (0, _web.createBrowserApp)(AppWithModalStack);
}

var _default = (0, _WithDeferredDeepLink.default)(createApp);

exports.default = _default;