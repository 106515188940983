"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ForwardReplayControl = _interopRequireDefault(require("./ForwardReplayControl"));

var ReplayControl = (0, _ForwardReplayControl.default)("replay");
ReplayControl.displayName = "ReplayControl";
var _default = ReplayControl;
exports.default = _default;