var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLDVariations = getLDVariations;
exports.ldVariationCompleted = ldVariationCompleted;

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _actionTypes = require("./actionTypes");

function getLDVariations(user) {
  return function (dispatch) {
    if (user && user.id) {
      _reactNativeFirebase.default.analytics().setUserId(user.uid);

      _reactNativeFirebase.default.analytics().setUserProperty('userId', user.uid);
    }

    var duration = 3600;

    if (['alpha', 'beta'].indexOf(process.env.ENVIRONMENT) > -1) {
      _reactNativeFirebase.default.config().enableDeveloperMode();

      duration = 0;
    }

    return _reactNativeFirebase.default.config().fetch(duration).then(function () {
      return _reactNativeFirebase.default.config().activateFetched();
    }).then(function () {
      return _reactNativeFirebase.default.config().getKeysByPrefix();
    }).then(function (keys) {
      return _reactNativeFirebase.default.config().getValues(keys);
    }).then(function (objects) {
      var data = {};
      Object.keys(objects).forEach(function (key) {
        data[key] = objects[key].val();
      });
      dispatch(ldVariationCompleted(data));
    }).catch(function () {});
  };
}

function ldVariationCompleted(res) {
  return {
    allFlags: res,
    type: _actionTypes.LD_VARIATION_COMPLETED
  };
}