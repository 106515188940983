Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.networkingErrorSelector = exports.networkingSelectedTableIdSelector = exports.tableByIdSelector = exports.tablesListLengthSelector = exports.networkingLoadedSelector = exports.networkingLoadingSelector = exports.tableListSelector = exports.networkingStateSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var networkingStateSelector = function networkingStateSelector(state) {
  return state[_constants.NAME];
};

exports.networkingStateSelector = networkingStateSelector;
var networkingTablesStateSelector = (0, _toolkit.createSelector)(networkingStateSelector, function (state) {
  return state.tables;
});

var networkingTableSelectors = _reducer.tableEntityAdapter.getSelectors(networkingTablesStateSelector);

var tableListSelector = _reducer.tableEntityAdapter.getSelectors(networkingTablesStateSelector).selectAll;

exports.tableListSelector = tableListSelector;
var networkingLoadingSelector = (0, _toolkit.createSelector)(networkingTablesStateSelector, function (state) {
  return state.loading;
});
exports.networkingLoadingSelector = networkingLoadingSelector;
var networkingLoadedSelector = (0, _toolkit.createSelector)(networkingTablesStateSelector, function (state) {
  return state.loaded;
});
exports.networkingLoadedSelector = networkingLoadedSelector;
var tablesListLengthSelector = (0, _toolkit.createSelector)(tableListSelector, function (state) {
  return state.length;
});
exports.tablesListLengthSelector = tablesListLengthSelector;
var tableByIdSelector = networkingTableSelectors.selectById;
exports.tableByIdSelector = tableByIdSelector;
var networkingSelectedTableIdSelector = (0, _toolkit.createSelector)(networkingTablesStateSelector, function (state) {
  return state.selectedTable;
});
exports.networkingSelectedTableIdSelector = networkingSelectedTableIdSelector;
var networkingErrorSelector = (0, _toolkit.createSelector)(networkingTablesStateSelector, function (state) {
  return state.error;
});
exports.networkingErrorSelector = networkingErrorSelector;