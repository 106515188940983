var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function FileInput(_ref) {
  var children = _ref.children,
      _ref$parser = _ref.parser,
      parser = _ref$parser === void 0 ? function () {} : _ref$parser,
      _ref$multiple = _ref.multiple,
      multiple = _ref$multiple === void 0 ? false : _ref$multiple,
      accept = _ref.accept,
      _ref$hidden = _ref.hidden,
      hidden = _ref$hidden === void 0 ? true : _ref$hidden,
      style = _ref.style,
      className = _ref.className;

  var _React$useState = _react.default.useState(''),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      value = _React$useState2[0],
      setValue = _React$useState2[1];

  var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      loading = _React$useState4[0],
      setLoading = _React$useState4[1];

  var localInputRef = _react.default.useRef(null);

  var inputChangeHandler = function inputChangeHandler(e) {
    var files = e.target.files;
    parser(Array.from(files || []), setLoading);
    setValue('');
  };

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("input", {
    ref: localInputRef,
    multiple: multiple,
    accept: accept,
    type: "file",
    value: value,
    style: _objectSpread({
      display: hidden ? 'none' : 'block'
    }, style),
    className: className,
    onChange: inputChangeHandler
  }), children(value, setValue, localInputRef, loading));
}

var _default = FileInput;
exports.default = _default;