var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNativePaperV = require("react-native-paper-v3");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/tickets/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _NavigationService = _interopRequireDefault(require("../../../navigation/NavigationService"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _headerLink = _interopRequireDefault(require("../../landing-screen/header-link"));

var _loginButtonView = _interopRequireDefault(require("../../landing-screen/login-button/login-button-view"));

var _headerStyles = _interopRequireDefault(require("./header-styles"));

var _useScrollListener2 = _interopRequireDefault(require("../../../utils/hooks/useScrollListener"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _DesignSystem2 = require("./../../../theme/DesignSystem");

var _OutlinedButton = _interopRequireDefault(require("../../../theme/components/buttons/OutlinedButton.v2"));

var _ticketApproveMessage = _interopRequireDefault(require("../../landing-screen/ticket-approve-message"));

var _Modal = _interopRequireDefault(require("../../../theme/components/modal/Modal"));

var _profileModal = _interopRequireDefault(require("../../profile/profile-modal"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _useOpenState3 = _interopRequireDefault(require("../side-menu/useOpenState"));

var _eventSettings = _interopRequireDefault(require("../../../core/event-settings"));

var _backstageSelectorContainer = _interopRequireDefault(require("./backstage/backstage-selector-container"));

var _queryParameter = require("../../../utils/queryParameter");

var _actions = require("../../../core/user-profile/actions");

var _styles = _interopRequireDefault(require("./styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var HeaderContainer = function HeaderContainer(_ref) {
  var isLoggedIn = _ref.isLoggedIn,
      withScroll = _ref.withScroll,
      withLeftMenu = _ref.withLeftMenu,
      userName = _ref.userName,
      logout = _ref.logout,
      logoUrl = _ref.logoUrl;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      menuVisible = _useState2[0],
      setMenuVisibility = _useState2[1];

  var hasUserTickets = (0, _reactRedux.useSelector)(_selectors.userHasTicketsForEvent);
  var Styles = (0, _headerStyles.default)();
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var _useScrollListener = (0, _useScrollListener2.default)(),
      sectionMap = _useScrollListener.sectionMap,
      currentSection = _useScrollListener.currentSection,
      scrollTo = _useScrollListener.scrollTo;

  var labels = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector);

  var _useState3 = (0, _react.useState)((0, _queryParameter.getBackstageIdParam)(window.location.href)),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      backstageId = _useState4[0],
      setBackstageId = _useState4[1];

  var match = (0, _DesignSystem2.useMediaQuery)(_DesignSystem2.MediaConstants.direction.WIDTH, _DesignSystem2.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.xsm;
  });
  var responsiveUserName = match && userName ? userName.split(' ').reduce(function (accum, value, index) {
    var _value$, _value$2;

    return index === 0 ? "".concat((_value$ = value[0]) === null || _value$ === void 0 ? void 0 : _value$.toUpperCase()) : "".concat(accum).concat((_value$2 = value[0]) === null || _value$2 === void 0 ? void 0 : _value$2.toUpperCase());
  }, '') : userName;

  var navigateFromHeader = function navigateFromHeader(to) {
    _NavigationService.default.navigateExtended(to);

    setMenuVisibility(false);
  };

  var match2 = (0, _DesignSystem2.useMediaQuery)(_DesignSystem2.MediaConstants.direction.WIDTH, _DesignSystem2.MediaConstants.operator.UP, function (_ref3) {
    var breakpoints = _ref3.breakpoints;
    return breakpoints.bg;
  });

  var _useOpenState = (0, _useOpenState3.default)('headerbol'),
      _useOpenState2 = (0, _slicedToArray2.default)(_useOpenState, 2),
      open = _useOpenState2[0],
      setOpen = _useOpenState2[1];

  (0, _react.useEffect)(function () {
    dispatch((0, _actions.getUserProfile)());
  }, [isLoggedIn]);
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ticketApproveMessage.default, null), (!open || match2) && _react.default.createElement(_Section.default, {
    color: "topMenuBackground",
    style: [Styles.container]
  }, _react.default.createElement(_Spacing.default, null, withLeftMenu && _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    style: Styles.burgerBtn,
    onPress: function onPress() {
      return setOpen(true);
    }
  }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_reactFeather.Menu, {
    color: colors.topMenuLinks
  }))), _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    onPress: function onPress() {
      return scrollTo && scrollTo({
        x: 0,
        y: 0,
        animated: true
      });
    }
  }, logoUrl && typeof logoUrl === 'string' ? _react.default.createElement(_reactNative.View, {
    style: Styles.menuHeader
  }, _react.default.createElement(_reactNative.Image, {
    nativeID: "imageLogo",
    source: {
      uri: logoUrl
    },
    resizeMode: "contain",
    style: Styles.logo
  })) : _react.default.createElement(_reactNative.View, null))), withScroll && _react.default.createElement(_reactNative.View, {
    style: Styles.buttonContainer
  }, Object.entries(sectionMap).sort(function (_ref4, _ref5) {
    var _ref6 = (0, _slicedToArray2.default)(_ref4, 2),
        _ = _ref6[0],
        aValue = _ref6[1];

    var _ref7 = (0, _slicedToArray2.default)(_ref5, 2),
        __ = _ref7[0],
        bValue = _ref7[1];

    return aValue.index - bValue.index;
  }).map(function (_ref8) {
    var _ref9 = (0, _slicedToArray2.default)(_ref8, 2),
        key = _ref9[0],
        value = _ref9[1];

    return [key, _objectSpread(_objectSpread({}, value), {}, {
      label: labels[value.title]
    })];
  }).map(function (_ref10) {
    var _ref11 = (0, _slicedToArray2.default)(_ref10, 2),
        key = _ref11[0],
        value = _ref11[1];

    return _react.default.createElement(_headerLink.default, {
      key: key,
      isActive: currentSection === key,
      onPress: value.scrollTo
    }, value.label ? value.label : _i18n.default.t("header.".concat(value.type)));
  })), isLoggedIn ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNativePaper.Provider, null, _react.default.createElement(_reactNative.View, {
    style: backstageId ? Styles.menuContainerBackstage : Styles.menuContainer
  }, _react.default.createElement("div", {
    id: "BackstageStepper"
  }), _react.default.createElement(_Spacing.default, {
    justifyContent: "flex-end",
    style: {
      maxWidth: '100%'
    }
  }, _react.default.createElement(_backstageSelectorContainer.default, {
    onChange: setBackstageId
  }), _react.default.createElement(_Modal.default, {
    content: _react.default.createElement(_profileModal.default, null)
  }, function (_ref12) {
    var onOpen = _ref12.onOpen;
    return _react.default.createElement(_reactNativePaper.Menu, {
      visible: menuVisible,
      onDismiss: function onDismiss() {
        return setMenuVisibility(false);
      },
      style: Styles.menuBox,
      anchor: _react.default.createElement(_OutlinedButton.default, {
        color: "topMenuLinks",
        buttonProps: {
          startIcon: _react.default.createElement(_reactFeather.User, null),
          onClick: function onClick() {
            return setMenuVisibility(true);
          }
        }
      }, _react.default.createElement(_core.Typography, {
        color: "inherit",
        variant: "caption",
        className: classes.userName
      }, responsiveUserName))
    }, _react.default.createElement(_reactNativePaper.Menu.Item, {
      onPress: onOpen,
      title: _i18n.default.t('header.myProfile'),
      style: Styles.menuItem
    }), hasUserTickets && _react.default.createElement(_reactNativePaper.Menu.Item, {
      onPress: function onPress() {
        return navigateFromHeader(_routesConsts.Routes.MY_TICKETS);
      },
      title: _i18n.default.t('header.myTickets'),
      style: Styles.menuItem
    }), hasUserTickets && _react.default.createElement(_reactNativePaper.Menu.Item, {
      onPress: function onPress() {
        return navigateFromHeader(_routesConsts.Routes.MY_FILES);
      },
      title: _i18n.default.t('header.myFiles'),
      style: Styles.menuItem
    }), _react.default.createElement(_reactNativePaper.Menu.Item, {
      onPress: logout,
      title: _i18n.default.t('header.logout')
    }));
  }))))) : _react.default.createElement(_loginButtonView.default, {
    onPress: function onPress() {
      return navigateFromHeader(_routesConsts.Routes.LOGIN);
    }
  })));
};

var _default = HeaderContainer;
exports.default = _default;