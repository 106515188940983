var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SettingsButton;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _AudioVideoDeviceSelector = _interopRequireDefault(require("./AudioVideoDeviceSelector"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _reactFeather = require("react-feather");

var _useSafeState3 = _interopRequireDefault(require("../../../utils/hooks/useSafeState"));

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _Spacing = _interopRequireDefault(require("./../../../theme/components/spacing/Spacing"));

function SettingsButton(_ref) {
  var _ref$iconButton = _ref.iconButton,
      iconButton = _ref$iconButton === void 0 ? false : _ref$iconButton,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#F3F3F3' : _ref$color;
  var Styles = (0, _audioVideoStyles.default)();

  var _useSafeState = (0, _useSafeState3.default)(_react.default.useState(false)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      open = _useSafeState2[0],
      setOpen = _useSafeState2[1];

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      setSettingsDialogOpen = _useAudioVideoContext.setSettingsDialogOpen;

  return _react.default.createElement(_react.default.Fragment, null, iconButton ? _react.default.createElement(_ColoredIconButton.default, {
    customColor: color,
    onClick: function onClick() {
      return setOpen(true);
    }
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, _react.default.createElement(_reactFeather.Settings, null))) : _react.default.createElement(_ContainedButton.default, {
    style: {
      backgroundColor: '#000000'
    },
    onPress: function onPress() {
      return setOpen(true);
    },
    size: "large"
  }, _i18n.default.translate('networking.checkAudioAndVideoSettings')), _react.default.createElement(_AudioVideoDeviceSelector.default, {
    onEnter: function onEnter() {
      return setSettingsDialogOpen(true);
    },
    onExited: function onExited() {
      return setSettingsDialogOpen(false);
    },
    open: open,
    onClose: function onClose() {
      return setOpen(false);
    }
  }));
}