Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LOAD_SCHEDULED_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_SCHEDULED_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_LIVE_NOW_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_LIVE_NOW_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_UPCOMING_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_UPCOMING_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_PAST_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_PAST_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_EVENT_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_EVENT_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_STAGE_PARTIAL_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_STAGE_PARTIAL_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_MUSIC_TYPE_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_MUSIC_TYPE_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_DJ_UPCOMING_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_DJ_UPCOMING_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_EVENTS_BY_HASHTAG", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_EVENTS_BY_HASHTAG;
  }
});
Object.defineProperty(exports, "LOAD_EVENTS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_EVENTS_COMPLETED;
  }
});
Object.defineProperty(exports, "ARE_EVENTS_LOADING", {
  enumerable: true,
  get: function get() {
    return _constants.ARE_EVENTS_LOADING;
  }
});
exports.STATUS_CHANGED = void 0;

var _constants = require("./constants");

var STATUS_CHANGED = 'events/STATUS_CHANGED';
exports.STATUS_CHANGED = STATUS_CHANGED;