Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var FullscreenButtonOverlay = function FullscreenButtonOverlay(props) {
  var toggleFullscreen = props.toggleFullscreen,
      overlaySize = props.overlaySize;
  return React.createElement(_reactNative.View, null, React.createElement(_reactNativePaper.TouchableRipple, {
    onPress: toggleFullscreen,
    style: overlaySize === 'small' ? styles.smallOverlay : styles.largeOverlay
  }, React.createElement(_reactNative.View, null)));
};

var _default = FullscreenButtonOverlay;
exports.default = _default;

var styles = _reactNative.StyleSheet.create({
  largeOverlay: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 20,
    right: 48,
    cursor: 'pointer'
  },
  smallOverlay: {
    height: 42,
    width: 44,
    position: 'absolute',
    bottom: 2,
    right: 12,
    cursor: 'pointer'
  }
});