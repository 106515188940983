var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _excluded = ["classes", "size", "align"];
var AbsoluteBox = (0, _core.withStyles)({
  root: {
    position: 'absolute'
  }
})(function (_ref) {
  var classes = _ref.classes,
      size = _ref.size,
      _ref$align = _ref.align;
  _ref$align = _ref$align === void 0 ? {
    vertical: 'top',
    horizontal: 'right'
  } : _ref$align;
  var vertical = _ref$align.vertical,
      horizontal = _ref$align.horizontal,
      props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return _react.default.createElement(_core.Box, (0, _extends2.default)({
    display: "flex",
    alignItems: "center",
    p: {
      xs: 1,
      sm: 1.2,
      md: 1.5,
      lg: 2
    }
  }, alignPosition(vertical, horizontal, 2), {
    justifyContent: "center",
    bgcolor: "#00000080",
    className: classes.root
  }, props));
});

function alignPosition(vertical, horizontal, value) {
  var _ref2;

  return _ref2 = {}, (0, _defineProperty2.default)(_ref2, vertical, value), (0, _defineProperty2.default)(_ref2, horizontal, value), _ref2;
}

var _default = AbsoluteBox;
exports.default = _default;