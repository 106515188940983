var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InviteDjCardView;

var _react = _interopRequireDefault(require("react"));

var _Card = _interopRequireDefault(require("../../../../design-system/structure/Card"));

var _Stack = _interopRequireDefault(require("../../../../design-system/structure/Stack"));

var _textView = _interopRequireDefault(require("../../../../design-system/typography/text-view"));

var _textWithIconView = _interopRequireDefault(require("../../../../design-system/typography/text-with-icon-view"));

var _ShareButton = _interopRequireDefault(require("../../common/ShareButton"));

var _button = _interopRequireDefault(require("../../../../design-system/actions/button"));

function InviteDjCardView(props) {
  var userId = props.userId,
      userName = props.userName,
      avatar = props.avatar,
      bio = props.bio,
      referralsBeats = props.referralsBeats,
      referralsBeatsDj = props.referralsBeatsDj,
      referralsDjMessage = props.referralsDjMessage;
  return _react.default.createElement(_Card.default, {
    width: 280,
    style: props.style
  }, _react.default.createElement(_Card.default.Title, {
    title: "Invite DJs",
    right: function right() {
      return _react.default.createElement(_textWithIconView.default, {
        icon: "beats",
        textStyle: "strong",
        inverted: true
      }, "".concat(referralsBeats + referralsBeatsDj, " B/new user"));
    }
  }), _react.default.createElement(_Card.default.Content, null, _react.default.createElement(_textView.default, {
    inverted: true
  }, referralsDjMessage)), _react.default.createElement(_Card.default.Actions, null, _react.default.createElement(_Stack.default, {
    vertical: true,
    distribution: "center"
  }, _react.default.createElement(_ShareButton.default, {
    deepLinkPath: "profile/".concat(userId),
    ogTitle: userName,
    ogImageUrl: avatar,
    ogDescription: bio,
    message: "Checkout my profile on Soundmix [APP_INVITE_URL]",
    windowTitle: "Share with Friends!"
  }, _react.default.createElement(_button.default, {
    icon: "share",
    primary: true,
    inverted: true,
    fullWidth: true
  }, "Share your invite link")))));
}