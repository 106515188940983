var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _stackItemView = _interopRequireDefault(require("./stack-item-view"));

var _stackStyles = _interopRequireWildcard(require("./stack-styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function StackView(props) {
  var stackStyles = [_stackStyles.default.default, _stackStyles.spacingStyles.base.container];
  var itemStyles = [_stackStyles.spacingStyles.base.item];

  if (props.spacing) {
    stackStyles.push(_stackStyles.spacingStyles[props.spacing].container);
    itemStyles.push(_stackStyles.spacingStyles[props.spacing].item);
  }

  if (props.distribution) {
    stackStyles.push(_stackStyles.distributionStyles[props.distribution].container);
    itemStyles.push(_stackStyles.distributionStyles[props.distribution].item);
  }

  if (props.vertical) {
    stackStyles.push(_stackStyles.default.vertical);
    itemStyles.push(_stackStyles.default.verticalItem);
  }

  if (props.alignment) {
    stackStyles.push(_stackStyles.alignmentStyles[props.alignment].container);
  }

  if (props.wrap) {
    stackStyles.push(_stackStyles.default.wrap);
  }

  return _react.default.createElement(_reactNative.View, {
    style: stackStyles
  }, _react.default.Children.map(props.children, function (child, index) {
    return _react.default.cloneElement(child, {
      style: [itemStyles, child.props.style]
    });
  }));
}

StackView.Item = _stackItemView.default;
var _default = StackView;
exports.default = _default;