Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initials = initials;
exports.placeholderImage = placeholderImage;
exports.getInitials = void 0;

var _formattingUtils = require("./formatting-utils");

var getInitials = function getInitials(name) {
  if (!name) return '';

  if ((0, _formattingUtils.isAnEmailAddress)(name)) {
    name = name.split('@')[0];
  }

  name = name.toString().replace(/[\.\-,?!@#$]/g, ' ').replace(/\s\s+/g, ' ').trim();
  var initials = name ? name.split(' ').reduce(function (accum, value) {
    return accum + value[0];
  }, '').toUpperCase() : '';

  if (initials.length <= 3) {
    return initials;
  }

  return initials.substr(0, 2) + initials.charAt(initials.length - 1);
};

exports.getInitials = getInitials;

function initials(name) {
  return name.match(/(\b\S)?/g).join('').toUpperCase();
}

function placeholderImage() {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '64x64';
  var text = arguments.length > 1 ? arguments[1] : undefined;
  var backgroundColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'FFFFFF';
  var textColor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '000000';
  return "https://via.placeholder.com/".concat(size, "/").concat(backgroundColor, "/").concat(textColor, "/?text=").concat(text);
}