var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventLiveOverlayContentInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _moment = _interopRequireDefault(require("moment"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _index = _interopRequireDefault(require("../../core/chat/index.js"));

var _LiveTimeCounter = require("../common/LiveTimeCounter");

var _PulseDot = _interopRequireDefault(require("../common/pulse/PulseDot"));

var _Caption = _interopRequireDefault(require("../common/typography_v1/Caption"));

var _EventLiveOverlayContent = _interopRequireDefault(require("./EventLiveOverlayContent.styles"));

var _eventInfo = _interopRequireDefault(require("../../core/event-info"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var EventLiveOverlayContentInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(EventLiveOverlayContentInternal, _React$PureComponent);

  var _super = _createSuper(EventLiveOverlayContentInternal);

  function EventLiveOverlayContentInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, EventLiveOverlayContentInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      videoPositionType: 'relative',
      timeCount: undefined
    };
    _this.liveTimeCounter = undefined;

    _this.updateLiveTimeCounter = function () {
      _this.setState({
        timeCount: (0, _LiveTimeCounter.TimeCount)((0, _moment.default)(_this.props.startDate))
      });
    };

    _this.updateTimer = function () {
      var date = (0, _moment.default)(_this.props.startDate);

      _this.setState({
        timeCount: (0, _LiveTimeCounter.TimeCount)(date)
      });
    };

    _this._renderLiveIndicator = function () {
      var colors = _this.props.theme.colors;
      var labelText = 'LIVE';
      var eventInfo = _this.props.eventInfo;
      return _react.default.createElement(_react.default.Fragment, null, _this.state.videoPositionType === 'relative' && _react.default.createElement(_reactNative.View, {
        style: [_EventLiveOverlayContent.default.livelabelContainer]
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: "openLiveDetailsModal",
        borderless: true,
        style: {
          flexDirection: 'row'
        }
      }, _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'row'
        }
      }, _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: _EventLiveOverlayContent.default.liveLabel
      }, _react.default.createElement(_PulseDot.default, null), _react.default.createElement(_Caption.default, {
        style: _EventLiveOverlayContent.default.liveLabelText
      }, labelText)), eventInfo && !eventInfo.hideUsersLiveCount && _react.default.createElement(_reactNative.View, {
        style: _EventLiveOverlayContent.default.numUsersLive
      }, _react.default.createElement(_CustomIcon.default, {
        name: "eye",
        size: 12,
        color: colors.text
      }), _react.default.createElement(_Caption.default, {
        style: _EventLiveOverlayContent.default.numUsersLiveText
      }, _this.props.numUsersLive)), _this.state.timeCount && _react.default.createElement(_reactNative.View, {
        style: _EventLiveOverlayContent.default.timeCount
      }, _react.default.createElement(_Caption.default, {
        testID: "timeCount",
        style: _EventLiveOverlayContent.default.numUsersLiveText
      }, _this.state.timeCount)))))));
    };

    return _this;
  }

  (0, _createClass2.default)(EventLiveOverlayContentInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.liveTimeCounter = setInterval(this.updateLiveTimeCounter, 1000);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.liveTimeCounter) {
        clearInterval(this.liveTimeCounter);
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement(_reactNative.View, {
        style: [_EventLiveOverlayContent.default.container]
      }, this._renderLiveIndicator());
    }
  }]);
  return EventLiveOverlayContentInternal;
}(_react.default.PureComponent);

exports.EventLiveOverlayContentInternal = EventLiveOverlayContentInternal;

function mapStateToProps(state) {
  return {
    numUsersLive: _index.default.selectors.numberOfOnlineUsersSelector(state),
    eventInfo: _eventInfo.default.selectors.selectEventInfo(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNativePaper.withTheme)(EventLiveOverlayContentInternal));

exports.default = _default;