Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEventDates = exports.getEventName = exports.getEventTitle = exports.getEventTimezone = exports.getCurrency = exports.getCheckoutDetails = exports.eventCreatorIdSelector = exports.selectEventSlug = exports.selectEventInfoId = exports.selectEventLogoUrl = exports.selectEventInfoLoaded = exports.selectEventInfoLoading = exports.selectEventInfo = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _immutable = require("immutable");

var _constants = require("./constants");

var _dateFormatter = require("../../utils/date-formatter");

var selectEventInfoState = function selectEventInfoState(state) {
  return state[_constants.NAME];
};

var selectEventInfo = (0, _toolkit.createSelector)(selectEventInfoState, function (eventInfoState) {
  return eventInfoState.get('eventInfo', (0, _immutable.Map)()).toJS();
});
exports.selectEventInfo = selectEventInfo;
var selectEventInfoLoading = (0, _toolkit.createSelector)(selectEventInfoState, function (state) {
  return state.get('loading');
});
exports.selectEventInfoLoading = selectEventInfoLoading;
var selectEventInfoLoaded = (0, _toolkit.createSelector)(selectEventInfoState, function (state) {
  return state.get('loaded');
});
exports.selectEventInfoLoaded = selectEventInfoLoaded;
var selectEventLogoUrl = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.logoUrl;
});
exports.selectEventLogoUrl = selectEventLogoUrl;
var selectEventInfoId = (0, _toolkit.createSelector)(selectEventInfoState, function (state) {
  return state.get('eventInfo').get('id');
});
exports.selectEventInfoId = selectEventInfoId;
var selectEventSlug = (0, _toolkit.createSelector)(selectEventInfoState, function (state) {
  return state.get('eventSlug') || state.get('eventInfo').get('id');
});
exports.selectEventSlug = selectEventSlug;
var eventCreatorIdSelector = (0, _toolkit.createSelector)(selectEventInfoState, function (state) {
  return state.get('eventInfo').get('createdBy');
});
exports.eventCreatorIdSelector = eventCreatorIdSelector;
var getCheckoutDetails = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.checkoutBlock;
});
exports.getCheckoutDetails = getCheckoutDetails;
var getCurrency = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.currency;
});
exports.getCurrency = getCurrency;
var getEventTimezone = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.timezone;
});
exports.getEventTimezone = getEventTimezone;
var getEventTitle = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.headline;
});
exports.getEventTitle = getEventTitle;
var getEventName = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  return eventInfo.name;
});
exports.getEventName = getEventName;
var getEventDates = (0, _toolkit.createSelector)(selectEventInfo, function (eventInfo) {
  var eventDates = (0, _dateFormatter.getDateBetween)(eventInfo.startDate, eventInfo.endDate, eventInfo.timezone, eventInfo.dateFormat);

  if (eventInfo.name) {
    document.title = "".concat(eventInfo.name, ", ").concat(eventDates);
  }

  return eventDates;
});
exports.getEventDates = getEventDates;