var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MediaStreamVideoPlayer;
exports.useStyles = void 0;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    container: {
      width: '100%',
      position: 'relative',
      paddingTop: '56.25%',
      borderRadius: 10,
      overflow: 'hidden'
    },
    innerContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0
    },
    video: {
      width: '100%',
      top: '50%',
      left: '50%',
      position: 'relative',
      transform: 'translate(-50%, -50%)',
      minHeight: '100%'
    }
  };
});
exports.useStyles = useStyles;

function MediaStreamVideoPlayer(_ref) {
  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? null : _ref$children,
      videoTrack = _ref.videoTrack;
  var classes = useStyles();

  var ref = _react.default.useRef(null);

  _react.default.useEffect(function () {
    if (!videoTrack) return;
    var mediaStream = new MediaStream();
    mediaStream.addTrack(videoTrack.getMediaStreamTrack());
    ref.current.srcObject = mediaStream;
  }, [videoTrack]);

  if (!videoTrack) return _react.default.createElement(_react.default.Fragment, null, children);
  return _react.default.createElement("div", {
    className: classes.container
  }, _react.default.createElement("div", {
    className: classes.innerContainer
  }, _react.default.createElement("video", {
    ref: ref,
    autoPlay: true,
    controls: false,
    className: classes.video
  })));
}