var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StopSessionButton;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _core = require("@material-ui/core");

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _useSafeState3 = _interopRequireDefault(require("../../../utils/hooks/useSafeState"));

var _actions = require("../../../core/user-rooms/actions");

var _reactNavigationHooks = require("react-navigation-hooks");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _backstageComponentsStyle = _interopRequireDefault(require("./backstageComponents-style"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function StopSessionButton() {
  var Styles = (0, _backstageComponentsStyle.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var dispatch = (0, _reactRedux.useDispatch)();

  var _useSafeState = (0, _useSafeState3.default)((0, _react.useState)(false)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      isLoading = _useSafeState2[0],
      setIsLoading = _useSafeState2[1];

  var backstageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BACKSTAGE_ID);
  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.mlg;
  });

  var sendSessionStopRequest = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setIsLoading(true);
              _context.next = 3;
              return dispatch((0, _actions.stopLive)(backstageId));

            case 3:
              setIsLoading(false);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function sendSessionStopRequest() {
      return _ref2.apply(this, arguments);
    };
  }();

  if (match) return _react.default.createElement(_ColoredIconButton.default, {
    customColor: colors.errorColor,
    onClick: sendSessionStopRequest,
    disabled: isLoading
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.iconButtonSize
  }, _react.default.createElement(_reactFeather.Play, null)));
  return _react.default.createElement(_ContainedButton.default, {
    onPress: sendSessionStopRequest,
    style: {
      backgroundColor: colors.errorColor
    },
    disabled: isLoading,
    iconLeft: isLoading ? _react.default.createElement(_core.CircularProgress, {
      size: 20,
      style: {
        color: '#fff'
      }
    }) : undefined
  }, _i18n.default.translate('backstage.stopBackstageSession'));
}