var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _headerLinkStyles = _interopRequireDefault(require("./header-link-styles"));

var _styles = _interopRequireDefault(require("./styles"));

var _excluded = ["children", "isActive"];

var HeaderLink = function HeaderLink(_ref) {
  var children = _ref.children,
      isActive = _ref.isActive,
      props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var Styles = (0, _headerLinkStyles.default)();
  var classes = (0, _styles.default)();
  return _react.default.createElement(_reactNativePaperV.TouchableRipple, (0, _extends2.default)({
    style: [Styles.button]
  }, props), _react.default.createElement(_reactNative.Text, {
    style: [Styles.buttonLabel, isActive && Styles.active]
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    color: "inherit",
    className: classes.header
  }, children)));
};

var _default = HeaderLink;
exports.default = _default;