var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NetworkingLive;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _useAccessToken3 = _interopRequireDefault(require("../../../../utils/hooks/useAccessToken"));

var _useClient = _interopRequireDefault(require("../../../../utils/hooks/useClient"));

var _useClientAutoConnect = _interopRequireDefault(require("../../../../utils/hooks/useClientAutoConnect"));

var _useParticipants = require("../../../../utils/hooks/useParticipants");

var _RegisterParticipantAudioInStream = _interopRequireDefault(require("../../../common/audio-video/RegisterParticipantAudioInStream"));

var _ParticipantsCanvasStream = _interopRequireDefault(require("../../../common/audio-video/canvas/ParticipantsCanvasStream"));

var _AudioVideoProvider = require("../../../common/audio-video/AudioVideoProvider");

var _SubscriptionsByRoom = _interopRequireDefault(require("../../../common/audio-video/SubscriptionsByRoom"));

function NetworkingLive(_ref) {
  var room = _ref.room,
      participantsInfo = _ref.participantsInfo,
      children = _ref.children,
      screenSharer = _ref.screenSharer;
  var client = (0, _useClient.default)();

  var _useAccessToken = (0, _useAccessToken3.default)(room, client),
      _useAccessToken2 = (0, _slicedToArray2.default)(_useAccessToken, 2),
      accessToken = _useAccessToken2[0],
      uid = _useAccessToken2[1];

  (0, _useClientAutoConnect.default)(client, uid, accessToken, room);

  var stream = _react.default.useMemo(function () {
    return new MediaStream();
  }, []);

  var audioContext = _react.default.useMemo(function () {
    return new AudioContext();
  }, []);

  var audioDestinationStream = _react.default.useMemo(function () {
    return audioContext.createMediaStreamDestination();
  }, [audioContext]);

  var participants = (0, _useParticipants.useParticipants)(client);
  var roomParticipantIds = Object.keys(participantsInfo).map(Number);

  _react.default.useEffect(function () {
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }

    var interval = setInterval(function () {
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }
    }, 2500);
    return function () {
      clearInterval(interval);
    };
  }, [audioContext]);

  _react.default.useEffect(function () {
    return stream.addTrack(audioDestinationStream.stream.getTracks()[0]);
  }, [stream, audioDestinationStream]);

  var streamStarted = !!roomParticipantIds.length;
  return _react.default.createElement(_react.default.Fragment, null, children(stream, streamStarted), _react.default.createElement(_AudioVideoProvider.AudioVideoContext.Provider, {
    value: {
      client: client
    }
  }, _react.default.createElement(_SubscriptionsByRoom.default, {
    screenSharer: screenSharer,
    roomParticipantIds: roomParticipantIds
  })), roomParticipantIds.map(function (id) {
    var _participants$get;

    return _react.default.createElement(_RegisterParticipantAudioInStream.default, {
      key: id,
      audioTrack: (_participants$get = participants.get(id)) === null || _participants$get === void 0 ? void 0 : _participants$get.audioTrack,
      context: audioContext,
      destinationStream: audioDestinationStream
    });
  }), _react.default.createElement(_ParticipantsCanvasStream.default, {
    screenSharer: screenSharer,
    webRtcParticipants: participants,
    stream: stream,
    participantsInfo: participantsInfo
  }));
}