var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoleStrip;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _roleConstants = require("../../../constants/role-constants");

var _DesignSystem = require("../../../theme/DesignSystem");

var _excluded = ["font", "fs"];
var RoleContainerDiv = (0, _core.withStyles)({
  root: {
    position: 'absolute',
    right: 6,
    bottom: 3,
    color: 'white',
    whiteSpace: 'nowrap',
    width: 'auto',
    borderRadius: 2,
    borderColor: '#FFFFFF',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingRight: 6,
    paddingLeft: 6,
    fontFamily: function fontFamily(_ref) {
      var font = _ref.font;
      return font;
    }
  }
})(function (_ref2) {
  var font = _ref2.font,
      fs = _ref2.fs,
      props = (0, _objectWithoutProperties2.default)(_ref2, _excluded);
  return _react.default.createElement(_core.Box, props);
});

function RoleStrip(_ref3) {
  var role = _ref3.role,
      width = _ref3.width;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      fontFamily = _useTheme.typography.fontFamily;

  var fontSize = Math.min(Math.round(width / 100 * 8), 13);
  if (!role || role === _roleConstants.USER_ROLES.SPEAKER) return null;
  return _react.default.createElement(RoleContainerDiv, {
    font: fontFamily,
    p: {
      xs: 0.1,
      sm: 0.1,
      md: 0.2,
      lg: 0.5
    },
    fontSize: fontSize,
    textOverflow: "ellipsis",
    overflow: "hidden",
    bgcolor: "#000000CC"
  }, role);
}