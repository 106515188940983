var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UsersListContainer;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/chat/actions");

var _selectors = require("../../../core/chat/selectors");

var _selectors2 = require("../../../core/event-info/selectors");

var _selectors3 = require("../../../core/user/selectors");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _user = _interopRequireDefault(require("../user"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

function UsersListContainer() {
  var users = (0, _reactRedux.useSelector)(_selectors.roomUsersAndStatusesSelector);
  var isBlocked = (0, _reactRedux.useSelector)(_selectors.imBlockedSelector);
  var createdBy = (0, _reactRedux.useSelector)(_selectors2.eventCreatorIdSelector);
  var uid = (0, _reactRedux.useSelector)(_selectors3.getFirebaseUserUid);
  var dispatch = (0, _reactRedux.useDispatch)();
  var isAdmin = createdBy === uid;

  var onBlock = function onBlock(user) {
    return dispatch((0, _actions.sendUserBlock)({
      user: user
    }));
  };

  var onUnblock = function onUnblock(user) {
    return dispatch((0, _actions.sendUserUnblock)({
      user: user
    }));
  };

  if (isBlocked) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('chat.youBlockedFromChat')));
  if (!users.length) return _react.default.createElement(_Spacing.default, {
    flex: 1,
    alignItems: "center",
    direction: "column",
    mSpacing: "s",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('chat.noUsers')));
  return _react.default.createElement(_reactNativeGestureHandler.ScrollView, {
    direction: "column",
    alignItems: "stretch"
  }, users.map(function (user) {
    return _react.default.createElement(_user.default, (0, _extends2.default)({
      onBlock: isAdmin ? function () {
        return onBlock(user);
      } : null,
      onUnblock: isAdmin ? function () {
        return onUnblock(user);
      } : null,
      key: user.usr
    }, user));
  }));
}