var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = KickOutButton;
exports.useStyles = void 0;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _blockIconButton = _interopRequireDefault(require("../../common/block-icon-button/block-icon-button"));

var useStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      position: 'absolute',
      zIndex: 10,
      top: 18,
      right: 18
    }
  };
}).buildHook();
exports.useStyles = useStyles;

function KickOutButton(_ref) {
  var onClick = _ref.onClick,
      isLoading = _ref.isLoading,
      disabled = _ref.disabled,
      style = _ref.style;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      errorColor = _useTheme.colors.errorColor;

  var styles = useStyles();
  return _react.default.createElement(_Spacing.default, {
    style: style ? style : styles.container
  }, isLoading ? _react.default.createElement(_core.CircularProgress, {
    size: 24,
    style: {
      color: errorColor
    }
  }) : _react.default.createElement(_blockIconButton.default, {
    disabled: disabled,
    onClick: onClick
  }));
}