Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flexShrink: 1,
    paddingLeft: 8,
    marginBottom: 15
  },
  title: {
    lineHeight: 28,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 28,
    color: 'white'
  },
  subtitle: {
    lineHeight: 22,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white'
  }
});

exports.default = _default;