var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleRequestDecline;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _toaster = _interopRequireDefault(require("../../common/toaster/toaster"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

var _useSafeState3 = _interopRequireDefault(require("../../../utils/hooks/useSafeState"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function HandleRequestDecline(_ref) {
  var roomId = _ref.roomId;
  var userIsDeclined = (0, _reactRedux.useSelector)((0, _selectors.userHasDeclineSelector)(roomId));

  var _useSafeState = (0, _useSafeState3.default)((0, _react.useState)(false)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      hasDecline = _useSafeState2[0],
      setHasDecline = _useSafeState2[1];

  (0, _react.useEffect)(function () {
    if (userIsDeclined) {
      setHasDecline(true);
    }
  }, [userIsDeclined, setHasDecline]);
  return _react.default.createElement(_toaster.default, {
    visible: hasDecline,
    onClose: function onClose() {
      return setHasDecline(false);
    },
    title: _i18n.default.translate('backstage.userDenied'),
    text: _i18n.default.translate('backstage.userDeniedText')
  });
}