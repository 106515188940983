var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _Subscription = _interopRequireDefault(require("./Subscription"));

var _useParticipants = require("../../../utils/hooks/useParticipants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function SubscriptionsByRoom(_ref) {
  var roomParticipantIds = _ref.roomParticipantIds,
      _ref$shouldSubscribe = _ref.shouldSubscribe,
      shouldSubscribe = _ref$shouldSubscribe === void 0 ? true : _ref$shouldSubscribe,
      screenSharer = _ref.screenSharer;
  var webRtcParticipants = (0, _useParticipants.useContextParticipants)();
  if (!shouldSubscribe) return;
  return _react.default.createElement(_react.default.Fragment, null, roomParticipantIds.map(function (id) {
    var webRtcParticipant = webRtcParticipants.get(id);
    var webRtcParticipantScreenShare = webRtcParticipants.get(Number(screenSharer.screenShareId));
    var subscriptions = [];

    if (webRtcParticipantScreenShare && id === Number(screenSharer.id)) {
      if (webRtcParticipantScreenShare.hasVideo) {
        subscriptions.push(_react.default.createElement(_Subscription.default, {
          key: "screen-video-".concat(id),
          participant: webRtcParticipantScreenShare,
          mediaType: "video"
        }));
      }
    }

    if (webRtcParticipant) {
      if (webRtcParticipant.hasVideo) {
        subscriptions.push(_react.default.createElement(_Subscription.default, {
          key: "video-".concat(id),
          participant: webRtcParticipant,
          mediaType: "video"
        }));
      }

      if (webRtcParticipant.hasAudio) {
        subscriptions.push(_react.default.createElement(_Subscription.default, {
          key: "audio-".concat(id),
          participant: webRtcParticipant,
          mediaType: "audio"
        }));
      }
    }

    return _react.default.createElement(_react.default.Fragment, {
      key: id
    }, subscriptions);
  }));
}

var _default = (0, _react.memo)(SubscriptionsByRoom);

exports.default = _default;