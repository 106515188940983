var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SuccessfulAssignment;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    assignedBox: {
      height: 50,
      marginTop: 16,
      borderRadius: 8,
      backgroundColor: "".concat(theme.palette.successColor.main, "70"),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

function SuccessfulAssignment() {
  var classes = useStyles();
  return _react.default.createElement(_core.Box, {
    className: classes.assignedBox
  }, _react.default.createElement(_core.Typography, {
    variant: "h3",
    className: classes.assignedText
  }, _i18n.default.translate('assignTickets.ticketAssigned')));
}