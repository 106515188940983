var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupUnasignedTicketsById = groupUnasignedTicketsById;
exports.verifyIfAllFieldsAreFilled = verifyIfAllFieldsAreFilled;
exports.areEmailsRepeated = areEmailsRepeated;
exports.BREAKPOINTS = exports.INITIAL_STEPS = exports.LAST_STEP_LABELS = exports.STEPS_IDS = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _configureTicketStep = _interopRequireDefault(require("./event-registration-components/configure-ticket-step"));

var _contactInformationStep = _interopRequireDefault(require("./event-registration-components/contact-information-step/contact-information-step"));

var _ticketAddonsStep = _interopRequireDefault(require("./event-registration-components/ticket-addons-step"));

var _checkoutStep = _interopRequireDefault(require("./event-registration-components/checkout-step"));

var _assignTicketsStep = _interopRequireDefault(require("./event-registration-components/assign-tickets-step"));

var _constants = require("../../core/tickets/constants");

var _LAST_STEP_LABELS;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var STEPS_IDS = {
  configureTickets: 'configure-ticket',
  ticketAddons: 'ticket-addons',
  contactInformation: 'contact-information',
  checkout: 'checkout',
  assignTickets: 'assign-tickets'
};
exports.STEPS_IDS = STEPS_IDS;
var LAST_STEP_LABELS = (_LAST_STEP_LABELS = {}, (0, _defineProperty2.default)(_LAST_STEP_LABELS, STEPS_IDS.contactInformation, 'contactInformation.finish'), (0, _defineProperty2.default)(_LAST_STEP_LABELS, STEPS_IDS.checkout, 'billing.pay'), (0, _defineProperty2.default)(_LAST_STEP_LABELS, STEPS_IDS.assignTickets, 'assignTickets.title'), _LAST_STEP_LABELS);
exports.LAST_STEP_LABELS = LAST_STEP_LABELS;
var INITIAL_STEPS = [{
  id: STEPS_IDS.configureTickets,
  title: 'configureTicket.title',
  Component: _react.default.createElement(_configureTicketStep.default, null),
  isEnabled: true
}, {
  id: STEPS_IDS.ticketAddons,
  title: 'addOns.title',
  Component: _react.default.createElement(_ticketAddonsStep.default, null),
  isEnabled: true
}, {
  id: STEPS_IDS.contactInformation,
  title: 'contactInformation.title',
  lastStep: {
    label: 'contactInformation.finish',
    value: ''
  },
  Component: _react.default.createElement(_contactInformationStep.default, null),
  isEnabled: true,
  hasFormikWrapper: true
}, {
  id: STEPS_IDS.checkout,
  title: 'checkout.title',
  lastStep: {
    label: 'billing.pay',
    value: ''
  },
  Component: _react.default.createElement(_checkoutStep.default, null),
  isEnabled: true,
  hasFormikWrapper: true
}, {
  id: STEPS_IDS.assignTickets,
  title: 'assignTickets.title',
  lastStep: {
    label: 'assignTickets.assign',
    value: ''
  },
  Component: _react.default.createElement(_assignTicketsStep.default, null),
  isEnabled: true,
  hasFormikWrapper: true
}];
exports.INITIAL_STEPS = INITIAL_STEPS;
var BREAKPOINTS = {
  layoutChange: 'md'
};
exports.BREAKPOINTS = BREAKPOINTS;

function groupUnasignedTicketsById(values, successfulAssigmentsIds) {
  var result = [];

  for (var key in values) {
    if (key.startsWith("".concat(_constants.FULLNAME_FIELD, "-"))) {
      var id = key.split('-')[1];

      if (!successfulAssigmentsIds.includes(id)) {
        var emailKey = "".concat(_constants.EMAIL_FIELD, "-") + id;
        var fullNameKey = "".concat(_constants.FULLNAME_FIELD, "-") + id;

        if (values[emailKey] || values[fullNameKey]) {
          result.push({
            email: values[emailKey] || '',
            fullName: values[key] || '',
            unassignedTicketId: id
          });
        }
      }
    }
  }

  return result;
}

function verifyIfAllFieldsAreFilled(values) {
  var errors = {};
  values.forEach(function (_ref) {
    var email = _ref.email,
        fullName = _ref.fullName,
        unassignedTicketId = _ref.unassignedTicketId;

    if (email === '') {
      errors = _objectSpread(_objectSpread({}, errors), {}, (0, _defineProperty2.default)({}, "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicketId), 'E-mail is required'));
    }

    if (fullName === '') {
      errors = _objectSpread(_objectSpread({}, errors), {}, (0, _defineProperty2.default)({}, "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicketId), 'Full name is required'));
    }
  });
  return {
    hasErrors: !!Object.keys(errors).length > 0,
    errors: errors
  };
}

function areEmailsRepeated(values) {
  var emailsArray = values.map(function (value) {
    return value.email;
  });
  return emailsArray.some(function (item, idx) {
    return emailsArray.indexOf(item) != idx;
  });
}