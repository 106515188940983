var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AssignedTickets;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _reactRedux = require("react-redux");

var _reactFeather = require("react-feather");

var _selectors = require("../../../../core/tickets/selectors");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _RenderUnassignedAddons = require("../../../common/RenderUnassignedAddons");

var _moment = _interopRequireDefault(require("moment"));

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function AssignedTickets(_ref) {
  var assignedTickets = _ref.assignedTickets;
  var classes = (0, _styles.default)();
  var boughtTicketsLoading = (0, _reactRedux.useSelector)(_selectors.selectBoughtTicketsLoading);

  var _useState = (0, _react.useState)(assignedTickets.length <= 10),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showList = _useState2[0],
      setShowList = _useState2[1];

  var toggleShowList = function toggleShowList() {
    setShowList(function (prev) {
      return !prev;
    });
  };

  if (boughtTicketsLoading) return null;
  return _react.default.createElement(_core.Box, {
    component: _core.Card,
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    p: 2,
    className: classes.arrowContainer,
    onClick: toggleShowList
  }, showList ? _react.default.createElement(_reactFeather.ChevronDown, null) : _react.default.createElement(_reactFeather.ChevronRight, null)), showList && assignedTickets.map(function (assignedTicket, index) {
    var _assignedTicket$ticke, _assignedTicket$selec, _assignedTicket$creat;

    return _react.default.createElement(_core.Box, {
      py: 2,
      px: 3,
      className: (0, _clsx.default)(classes.assignTicketBox, assignedTickets.length - 1 !== index && classes.borderBottom),
      key: assignedTicket.id
    }, _react.default.createElement(_core.Typography, null, _i18n.default.t('assignTickets.ticket'), ":", _react.default.createElement("span", {
      className: classes.boldText
    }, (_assignedTicket$ticke = assignedTicket.ticketInfo) === null || _assignedTicket$ticke === void 0 ? void 0 : _assignedTicket$ticke.name)), ((_assignedTicket$selec = assignedTicket.selectedAddons) === null || _assignedTicket$selec === void 0 ? void 0 : _assignedTicket$selec.length) >= 1 && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, null, _i18n.default.t('assignTickets.addons'), ":"), _react.default.createElement(_RenderUnassignedAddons.RenderUnassignedAddons, {
      ticketAddons: assignedTicket === null || assignedTicket === void 0 ? void 0 : assignedTicket.selectedAddons
    })), _react.default.createElement(_core.Typography, null, _i18n.default.t('assignTickets.assignedTo'), ":", _react.default.createElement("span", {
      className: classes.boldText
    }, assignedTicket.assignedToEmail)), _react.default.createElement(_core.Typography, null, _i18n.default.t('assignTickets.assignedDate'), ":", _react.default.createElement("span", {
      className: classes.boldText
    }, _moment.default.unix(assignedTicket === null || assignedTicket === void 0 ? void 0 : (_assignedTicket$creat = assignedTicket.createdDate) === null || _assignedTicket$creat === void 0 ? void 0 : _assignedTicket$creat.seconds).format('DD/MM/YYYY HH:mm'))));
  }));
}