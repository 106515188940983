var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-settings/selectors");

var _constants = require("../../../core/polls/constants");

var _selectors2 = require("../../../core/team/selectors");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _AutoConnect = _interopRequireDefault(require("../../chat/AutoConnect"));

var _blockedUsersList = _interopRequireDefault(require("../../chat/blocked-users-list"));

var _Chat = _interopRequireDefault(require("../../chat/Chat"));

var _usersList = _interopRequireDefault(require("../../chat/users-list"));

var _polls = _interopRequireDefault(require("../../common/polls"));

var _rightSideMenu = require("../../common/right-side-menu");

var _menuItem = _interopRequireDefault(require("../../common/right-side-menu/menu-item/menu-item"));

var LivePageMenu = function LivePageMenu(_ref) {
  var _chatSettings$disable, _qaSettings$disabled, _peopleSettings$disab;

  var live = _ref.live;
  var isAdmin = (0, _reactRedux.useSelector)(_selectors2.isStaffMember);
  var chatSettings = (0, _reactRedux.useSelector)(_selectors.chatSettingsSelector);
  var qaSettings = (0, _reactRedux.useSelector)(_selectors.qaSettingsSelector);
  var peopleSettings = (0, _reactRedux.useSelector)(_selectors.peopleSettingsSelector);
  var chatDisabled = !!((_chatSettings$disable = chatSettings.disabled) !== null && _chatSettings$disable !== void 0 && _chatSettings$disable.stages);
  var qaDisabled = !!((_qaSettings$disabled = qaSettings.disabled) !== null && _qaSettings$disabled !== void 0 && _qaSettings$disabled.stages);
  var peopleDisabled = !!((_peopleSettings$disab = peopleSettings.disabled) !== null && _peopleSettings$disab !== void 0 && _peopleSettings$disab.stages);
  var menuBuilder = new _rightSideMenu.MenuBuilder();
  menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref2) {
    var isSelected = _ref2.isSelected,
        onSelect = _ref2.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Chat",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.MessageCircle, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.chat'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
    id: live === null || live === void 0 ? void 0 : live.id
  }, _react.default.createElement(_Chat.default, {
    rejoinId: live === null || live === void 0 ? void 0 : live.id,
    disabled: chatDisabled,
    disabledMessage: chatSettings.disabledMessage
  }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.PEOPLES, function (_ref3) {
    var isSelected = _ref3.isSelected,
        onSelect = _ref3.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "People",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.Users, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.people'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.PEOPLES, _react.default.createElement(_AutoConnect.default, {
    id: live === null || live === void 0 ? void 0 : live.id
  }, _react.default.createElement(_usersList.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.QA, function (_ref4) {
    var isSelected = _ref4.isSelected,
        onSelect = _ref4.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "QandA",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.HelpCircle, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.qa'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.QA, _react.default.createElement(_AutoConnect.default, {
    id: "".concat(live === null || live === void 0 ? void 0 : live.id, "-QA")
  }, _react.default.createElement(_Chat.default, {
    rejoinId: "".concat(live === null || live === void 0 ? void 0 : live.id, "-QA"),
    disabled: qaDisabled,
    disabledMessage: qaSettings.disabledMessage,
    translations: {
      noCommentsYet: 'chat.writeAQuestion',
      inputText: 'chat.yourQuestion',
      blocked: 'chat.youBlockedFromQA',
      disconnected: 'chat.youDisconnectedFromQA'
    }
  }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.POLLS, function (_ref5) {
    var isSelected = _ref5.isSelected,
        onSelect = _ref5.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Polls",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.BarChart, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.polls'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.POLLS, _react.default.createElement(_polls.default, {
    location: _constants.POLL_TYPES.LIVE
  })).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PEOPLES, function (_ref6) {
    var isSelected = _ref6.isSelected,
        onSelect = _ref6.onSelect;
    return _react.default.createElement(_menuItem.default, {
      key: "Abuse",
      onPress: onSelect,
      icon: _react.default.createElement(_reactFeather.Shield, null),
      color: isSelected ? 'button' : 'alternativeText'
    }, _i18n.default.translate('menuItems.abuse'));
  }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PEOPLES, _react.default.createElement(_AutoConnect.default, {
    id: live === null || live === void 0 ? void 0 : live.id
  }, _react.default.createElement(_blockedUsersList.default, null)));
  if (isAdmin) return menuBuilder.buildAdminMenu({
    chatDisabled: chatDisabled,
    peopleDisabled: peopleDisabled
  });
  return menuBuilder.buildClientMenu({
    chatDisabled: chatDisabled,
    peopleDisabled: peopleDisabled
  });
};

var _default = LivePageMenu;
exports.default = _default;