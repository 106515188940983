var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventCardView = EventCardView;
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactNativePaper = require("react-native-paper");

var _StoryCard = _interopRequireDefault(require("design-system/structure/StoryCard"));

var _moment = _interopRequireDefault(require("moment"));

function EventCardView(props) {
  var navigation = (0, _reactNavigationHooks.useNavigation)();

  var handleCardPress = function handleCardPress() {
    var navigateRoute = props.navigateRoute;
    var eventIdKey = navigateRoute === 'EventLive' ? 'liveId' : 'eventId';
    navigation.navigate(navigateRoute || 'EventDetail', (0, _defineProperty2.default)({}, eventIdKey, props.eventId));
  };

  var subtitle = "".concat((0, _moment.default)(props.startDate).format('D'), " - ").concat((0, _moment.default)(props.endDate).format('D MMMM YYYY'));
  return _react.default.createElement(_StoryCard.default, (0, _extends2.default)({}, props, {
    key: props.eventId,
    testID: 'TapEvent' + props.eventId,
    onPress: handleCardPress,
    image: props.image
  }), props.isLive > 0 && !props.isReplay && _react.default.createElement(_StoryCard.default.Badge, {
    label: props.label
  }), _react.default.createElement(_StoryCard.default.Footer, null, _react.default.createElement(_StoryCard.default.Section, {
    testID: 'Event' + props.eventId,
    subtitle: subtitle,
    title: props.name
  })));
}

var _default = (0, _reactNativePaper.withTheme)(EventCardView);

exports.default = _default;