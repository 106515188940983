Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectEventFilesIsLoaded = exports.selectEventFilesIsLoading = exports.selectEventFilesList = void 0;

var _reducer = require("./reducer");

var _constants = require("./constants");

var _toolkit = require("@reduxjs/toolkit");

var selectEventFilesState = function selectEventFilesState(state) {
  return state[_constants.NAME];
};

var selectEventFilesList = _reducer.eventFilesEntityAdapter.getSelectors(selectEventFilesState).selectAll;

exports.selectEventFilesList = selectEventFilesList;
var selectEventFilesIsLoading = (0, _toolkit.createSelector)(selectEventFilesState, function (state) {
  return state.loading;
});
exports.selectEventFilesIsLoading = selectEventFilesIsLoading;
var selectEventFilesIsLoaded = (0, _toolkit.createSelector)(selectEventFilesState, function (state) {
  return state.loaded;
});
exports.selectEventFilesIsLoaded = selectEventFilesIsLoaded;