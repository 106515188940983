var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventRegistrationNavigation;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _core = require("@material-ui/core");

var _react = _interopRequireWildcard(require("react"));

var _eventRegistrationUtils = require("../../event-registration-utils");

var _eventRegisterProvider = require("../../event-register-provider");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _formik = require("formik");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var useStyle = (0, _core.makeStyles)(function (theme) {
  return {
    navigationContainer: (0, _defineProperty2.default)({
      gridArea: 'navigation',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      justifySelf: 'center',
      width: '100%'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      maxWidth: 500
    }),
    buttonContainer: (0, _defineProperty2.default)({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      maxWidth: 235
    }),
    button: {
      width: '80%',
      height: '100%',
      padding: '6px 10px',
      borderRadius: 8
    },
    exitButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    exitButton: {
      width: '80%',
      height: '100%',
      padding: '6px 10px',
      borderRadius: 8
    }
  };
});

function EventRegistrationNavigation() {
  var _activeStepInfo$lastS, _activeStepInfo$lastS2;

  var classes = useStyle();

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      activeStep = _useEventRegisterCont.activeStep,
      closeDialog = _useEventRegisterCont.closeDialog,
      steps = _useEventRegisterCont.steps,
      assignmentIsComplete = _useEventRegisterCont.assignmentIsComplete,
      onNext = _useEventRegisterCont.onNext,
      onBack = _useEventRegisterCont.onBack,
      handleBlockNavigationChange = _useEventRegisterCont.handleBlockNavigationChange,
      isLastStep = _useEventRegisterCont.isLastStep,
      isTransactionOngoing = _useEventRegisterCont.isTransactionOngoing;

  var context = (0, _formik.useFormikContext)();
  var activeStepInfo = steps[activeStep];
  var hasFormikWrapper = !!(activeStepInfo !== null && activeStepInfo !== void 0 && activeStepInfo.hasFormikWrapper);
  (0, _react.useEffect)(function () {
    handleBlockNavigationChange();
  }, []);
  var backLabel = 'checkout.back';
  var nextLabel = isLastStep() ? "".concat(_i18n.default.translate(activeStepInfo === null || activeStepInfo === void 0 ? void 0 : (_activeStepInfo$lastS = activeStepInfo.lastStep) === null || _activeStepInfo$lastS === void 0 ? void 0 : _activeStepInfo$lastS.label), " ").concat(activeStepInfo === null || activeStepInfo === void 0 ? void 0 : (_activeStepInfo$lastS2 = activeStepInfo.lastStep) === null || _activeStepInfo$lastS2 === void 0 ? void 0 : _activeStepInfo$lastS2.value) : _i18n.default.translate('checkout.next');
  var inProgress = (context === null || context === void 0 ? void 0 : context.isSubmitting) || isTransactionOngoing;
  return _react.default.createElement(_core.Box, {
    className: classes.navigationContainer
  }, assignmentIsComplete ? _react.default.createElement(_core.Box, {
    className: classes.exitButtonContainer
  }, _react.default.createElement(_core.Button, {
    className: classes.exitButton,
    color: "primary",
    variant: "contained",
    onClick: closeDialog
  }, "Exit")) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
    className: classes.buttonContainer
  }, _react.default.createElement(_core.Button, {
    className: classes.button,
    color: "primary",
    variant: "contained",
    disabled: inProgress,
    onClick: onBack
  }, isLastStep() ? 'Skip' : _i18n.default.translate(backLabel))), _react.default.createElement(_core.Box, {
    className: classes.buttonContainer
  }, hasFormikWrapper ? _react.default.createElement(ButtonWithFormik, {
    nextLabel: nextLabel
  }) : _react.default.createElement(_core.Button, {
    className: classes.button,
    color: "primary",
    variant: "contained",
    onClick: (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              onNext();

            case 1:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))
  }, nextLabel))));
}

function ButtonWithFormik(_ref2) {
  var nextLabel = _ref2.nextLabel;

  var _useEventRegisterCont2 = (0, _eventRegisterProvider.useEventRegisterContext)(),
      blockNavigation = _useEventRegisterCont2.blockNavigation,
      isTransactionOngoing = _useEventRegisterCont2.isTransactionOngoing;

  var classes = useStyle();

  var _useFormikContext = (0, _formik.useFormikContext)(),
      submitForm = _useFormikContext.submitForm,
      isSubmitting = _useFormikContext.isSubmitting;

  var inProgress = isTransactionOngoing || isSubmitting;
  return _react.default.createElement(_core.Button, {
    className: classes.button,
    color: "primary",
    variant: "contained",
    onClick: (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
      return _regenerator.default.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!blockNavigation) {
                submitForm();
              }

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    })),
    disabled: inProgress
  }, inProgress ? _react.default.createElement(_core.CircularProgress, {
    size: "1.5rem"
  }) : nextLabel);
}