Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useSpeakersStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  cardBox: {
    minWidth: 200,
    flexBasis: '22%',
    alignSelf: 'stretch'
  }
}).addThemeBasedStyle(function (theme) {
  return {
    container: {
      margin: -theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.lg;
}, {
  cardBox: {
    minWidth: 'calc(100%/3)',
    flexBasis: 'calc(100%/3)'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  cardBox: {
    width: '100%',
    flexBasis: '100%'
  }
}).buildHook();
var _default = useSpeakersStyles;
exports.default = _default;