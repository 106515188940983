Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MUTE_UNMUTE_SPEAKER = exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION = exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE = exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT = exports.DECLINE_JOIN_LIVE_REQUEST_BY_SESSION = exports.DECLINE_JOIN_LIVE_REQUEST_BY_STAGE = exports.CANCEL_JOIN_LIVE_REQUEST = exports.DECLINE_JOIN_LIVE_REQUEST = exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION = exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE = exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS = exports.START_STOP_LIVE_SESSION_BY_SESSION = exports.START_STOP_LIVE_SESSION_BY_STAGE = exports.START_STOP_LIVE_SESSION = exports.ALLOW_JOIN_LIVE_REQUEST_BY_SESSION = exports.ALLOW_JOIN_LIVE_REQUEST_BY_STAGE = exports.ALLOW_JOIN_LIVE_REQUEST = exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION = exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE = exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS = exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_SESSION = exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_STAGE = exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW = exports.JOIN_BACKSTAGE_LIVE_REQUEST = exports.LEAVE_BACKSTAGE_LIVE = exports.LEAVE_BACKSTAGE = exports.JOIN_BACKSTAGE_BY_SESSION = exports.JOIN_BACKSTAGE_BY_STAGE = exports.JOIN_BACKSTAGE = void 0;
var JOIN_BACKSTAGE = 'joinBackstage';
exports.JOIN_BACKSTAGE = JOIN_BACKSTAGE;
var JOIN_BACKSTAGE_BY_STAGE = 'joinBackstageByStage';
exports.JOIN_BACKSTAGE_BY_STAGE = JOIN_BACKSTAGE_BY_STAGE;
var JOIN_BACKSTAGE_BY_SESSION = 'joinBackstageBySession';
exports.JOIN_BACKSTAGE_BY_SESSION = JOIN_BACKSTAGE_BY_SESSION;
var LEAVE_BACKSTAGE = 'leaveBackstage';
exports.LEAVE_BACKSTAGE = LEAVE_BACKSTAGE;
var LEAVE_BACKSTAGE_LIVE = 'leaveBackstageLive';
exports.LEAVE_BACKSTAGE_LIVE = LEAVE_BACKSTAGE_LIVE;
var JOIN_BACKSTAGE_LIVE_REQUEST = 'joinBackstageLiveRequest';
exports.JOIN_BACKSTAGE_LIVE_REQUEST = JOIN_BACKSTAGE_LIVE_REQUEST;
var JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW = 'joinBackstageLiveRequestWithAllow';
exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW = JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW;
var JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_STAGE = 'joinBackstageLiveRequestWithAllowByStage';
exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_STAGE = JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_STAGE;
var JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_SESSION = 'joinBackstageLiveRequestWithAllowBySession';
exports.JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_SESSION = JOIN_BACKSTAGE_LIVE_REQUEST_WITH_ALLOW_BY_SESSION;
var SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS = 'showKickOutSpeakerFromLiveUiComponents';
exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS = SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS;
var SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE = 'showKickOutSpeakerFromLiveUiComponentsByStage';
exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE = SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE;
var SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION = 'showKickOutSpeakerFromLiveUiComponentsBySession';
exports.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION = SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION;
var ALLOW_JOIN_LIVE_REQUEST = 'allowJoinLiveRequest';
exports.ALLOW_JOIN_LIVE_REQUEST = ALLOW_JOIN_LIVE_REQUEST;
var ALLOW_JOIN_LIVE_REQUEST_BY_STAGE = 'allowJoinLiveRequestByStage';
exports.ALLOW_JOIN_LIVE_REQUEST_BY_STAGE = ALLOW_JOIN_LIVE_REQUEST_BY_STAGE;
var ALLOW_JOIN_LIVE_REQUEST_BY_SESSION = 'allowJoinLiveRequestBySession';
exports.ALLOW_JOIN_LIVE_REQUEST_BY_SESSION = ALLOW_JOIN_LIVE_REQUEST_BY_SESSION;
var START_STOP_LIVE_SESSION = 'startStopLiveSession';
exports.START_STOP_LIVE_SESSION = START_STOP_LIVE_SESSION;
var START_STOP_LIVE_SESSION_BY_STAGE = 'startStopLiveSessionByStage';
exports.START_STOP_LIVE_SESSION_BY_STAGE = START_STOP_LIVE_SESSION_BY_STAGE;
var START_STOP_LIVE_SESSION_BY_SESSION = 'startStopLiveSessionBySession';
exports.START_STOP_LIVE_SESSION_BY_SESSION = START_STOP_LIVE_SESSION_BY_SESSION;
var SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS = 'showJoinLiveRequestUiComponents';
exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS = SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS;
var SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE = 'showJoinLiveRequestUiComponentsByStage';
exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE = SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE;
var SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION = 'showJoinLiveRequestUiComponentsBySession';
exports.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION = SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION;
var DECLINE_JOIN_LIVE_REQUEST = 'declineJoinLiveRequest';
exports.DECLINE_JOIN_LIVE_REQUEST = DECLINE_JOIN_LIVE_REQUEST;
var CANCEL_JOIN_LIVE_REQUEST = 'cancelJoinLiveRequest';
exports.CANCEL_JOIN_LIVE_REQUEST = CANCEL_JOIN_LIVE_REQUEST;
var DECLINE_JOIN_LIVE_REQUEST_BY_STAGE = 'declineJoinLiveRequestByStage';
exports.DECLINE_JOIN_LIVE_REQUEST_BY_STAGE = DECLINE_JOIN_LIVE_REQUEST_BY_STAGE;
var DECLINE_JOIN_LIVE_REQUEST_BY_SESSION = 'declineJoinLiveRequestBySession';
exports.DECLINE_JOIN_LIVE_REQUEST_BY_SESSION = DECLINE_JOIN_LIVE_REQUEST_BY_SESSION;
var SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT = 'showBackstageSelectorUiComponent';
exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT = SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT;
var SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE = 'showBackstageSelectorUiComponentByStage';
exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE = SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_STAGE;
var SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION = 'showBackstageSelectorUiComponentBySession';
exports.SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION = SHOW_BACKSTAGE_SELECTOR_UI_COMPONENT_BY_SESSION;
var MUTE_UNMUTE_SPEAKER = 'muteUnmuteSpeaker';
exports.MUTE_UNMUTE_SPEAKER = MUTE_UNMUTE_SPEAKER;