var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _lodash = _interopRequireDefault(require("lodash"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/user/selectors");

var _menuItem = _interopRequireDefault(require("../../common/right-side-menu/menu-item/menu-item"));

var _reactFeather = require("react-feather");

var _Chat = _interopRequireDefault(require("../../chat/Chat"));

var _usersList = _interopRequireDefault(require("../../chat/users-list"));

var _blockedUsersList = _interopRequireDefault(require("../../chat/blocked-users-list"));

var _rightSideMenu = require("../../common/right-side-menu");

var _selectors3 = require("../../../core/team/selectors");

var _selectors4 = require("../../../core/event-settings/selectors");

var _AutoConnect = _interopRequireDefault(require("../../chat/AutoConnect"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _routesConsts = require("../../../navigation/client/routes-consts");

var menuBuilder = new _rightSideMenu.MenuBuilder();

var NetworkingTableMenu = function NetworkingTableMenu() {
  var createdBy = (0, _reactRedux.useSelector)(_selectors.eventCreatorIdSelector);
  var uid = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUserUid);
  var adminAccess = (0, _reactRedux.useSelector)(_selectors3.isStaffMember);
  var chatSettings = (0, _reactRedux.useSelector)(_selectors4.chatSettingsSelector);
  var peopleSettings = (0, _reactRedux.useSelector)(_selectors4.peopleSettingsSelector);
  var isAdmin = createdBy === uid || adminAccess;
  var tableId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);

  var menu = _react.default.useMemo(function () {
    var _chatSettings$disable, _peopleSettings$disab;

    var chatDisabled = Boolean((_chatSettings$disable = chatSettings.disabled) === null || _chatSettings$disable === void 0 ? void 0 : _chatSettings$disable.networking);
    var peopleDisabled = Boolean((_peopleSettings$disab = peopleSettings.disabled) === null || _peopleSettings$disab === void 0 ? void 0 : _peopleSettings$disab.networking);
    menuBuilder.add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.CHAT, function (_ref) {
      var isSelected = _ref.isSelected,
          onSelect = _ref.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Chat",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.MessageCircle, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.chat'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.CHAT, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_Chat.default, {
      rejoinId: tableId,
      disabled: chatDisabled,
      disabledMessage: chatSettings.disabledMessage
    }))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.PEOPLES, function (_ref2) {
      var isSelected = _ref2.isSelected,
          onSelect = _ref2.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "People",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Users, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, _i18n.default.translate('menuItems.people'));
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.PEOPLES, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_usersList.default, null))).add(_rightSideMenu.MenuBuilder.TYPES.MENU_ITEM, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PEOPLES, function (_ref3) {
      var isSelected = _ref3.isSelected,
          onSelect = _ref3.onSelect;
      return _react.default.createElement(_menuItem.default, {
        key: "Abuse",
        onPress: onSelect,
        icon: _react.default.createElement(_reactFeather.Shield, null),
        color: isSelected ? 'button' : 'alternativeText'
      }, "Abuse");
    }).add(_rightSideMenu.MenuBuilder.TYPES.CONTENT, _rightSideMenu.MenuBuilder.MENUS.BLOCKED_PEOPLES, _react.default.createElement(_AutoConnect.default, {
      id: tableId
    }, _react.default.createElement(_blockedUsersList.default, null)));
    if (isAdmin) return menuBuilder.buildAdminMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled
    });
    return menuBuilder.buildClientMenu({
      chatDisabled: chatDisabled,
      peopleDisabled: peopleDisabled
    });
  }, [isAdmin, chatSettings, tableId, peopleSettings]);

  return menu;
};

var _default = NetworkingTableMenu;
exports.default = _default;