Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.join = join;
exports.joinCompleted = joinCompleted;
exports.leave = leave;
exports.refreshListeners = refreshListeners;
exports.loadUsers = loadUsers;
exports.loadUsersCompleted = loadUsersCompleted;

var _actionTypes = require("./actionTypes");

function join(liveId) {
  return {
    type: _actionTypes.JOIN,
    liveId: liveId
  };
}

function joinCompleted(liveId) {
  return {
    type: _actionTypes.JOIN_COMPLETED,
    liveId: liveId
  };
}

function leave() {
  return {
    type: _actionTypes.LEAVE
  };
}

function refreshListeners(numUsers, numTotalUsers) {
  return {
    type: _actionTypes.REFRESH_LISTENERS,
    numUsers: numUsers,
    numTotalUsers: numTotalUsers
  };
}

function loadUsers() {
  return {
    type: _actionTypes.LOAD_USERS
  };
}

function loadUsersCompleted(users) {
  return {
    type: _actionTypes.LOAD_USERS_COMPLETED,
    users: users
  };
}