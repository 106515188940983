Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    width: '100%',
    aspectRatio: 16 / 9
  },
  video: {},
  overlay: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  videoPoster: {}
});

exports.default = _default;