var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AppInternal = exports.HeaderContext = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeAppsflyer = _interopRequireDefault(require("react-native-appsflyer"));

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _reactNativeOrientationLocker = _interopRequireDefault(require("react-native-orientation-locker"));

var _reactNativePaper = require("react-native-paper");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _reactRedux = require("react-redux");

var _RegisterProgress = _interopRequireDefault(require("./components/auth/register/register-progress/RegisterProgress"));

var _absoluteSpinner = _interopRequireDefault(require("./components/common/absolute-spinner"));

var _dialogService = _interopRequireDefault(require("./components/common/dialog-service/dialog-service"));

var _header = _interopRequireDefault(require("./components/common/header"));

var _Page = _interopRequireDefault(require("./components/common/page/Page"));

var _SplashScreen = _interopRequireDefault(require("./components/SplashScreen"));

var _DeprecatedBanner = _interopRequireDefault(require("./components/upgrade/DeprecatedBanner"));

var _NewVersionNotification = _interopRequireDefault(require("./components/upgrade/NewVersionNotification"));

var _NewVersionSnackBar = _interopRequireDefault(require("./components/upgrade/NewVersionSnackBar"));

var _ObsoleteScreen = _interopRequireDefault(require("./components/upgrade/ObsoleteScreen"));

var _EnableNotificationsRequest = _interopRequireDefault(require("./components/welcome/EnableNotificationsRequest"));

var _UpdateProfileScreen = _interopRequireDefault(require("./components/welcome/UpdateProfileScreen"));

var _WelcomeScreen = _interopRequireDefault(require("./components/welcome/WelcomeScreen"));

var _activity = _interopRequireDefault(require("./core/activity"));

var _benefits = _interopRequireDefault(require("./core/benefits"));

var _checklist = _interopRequireDefault(require("./core/checklist"));

var _checkout = _interopRequireDefault(require("./core/checkout"));

var _eventInfo = _interopRequireDefault(require("./core/event-info"));

var _eventStages = _interopRequireDefault(require("./core/event-stages"));

var _expo = _interopRequireDefault(require("./core/expo"));

var _gameSparks = _interopRequireDefault(require("./core/gameSparks"));

var _global = _interopRequireDefault(require("./core/global"));

var _landingPageSections = _interopRequireDefault(require("./core/landing-page-sections"));

var _letter = _interopRequireDefault(require("./core/letter"));

var _networking = _interopRequireDefault(require("./core/networking"));

var _notifications = _interopRequireDefault(require("./core/notifications"));

var _partners = _interopRequireDefault(require("./core/partners"));

var _session = _interopRequireDefault(require("./core/session"));

var _speakers = _interopRequireDefault(require("./core/speakers"));

var _team = _interopRequireDefault(require("./core/team"));

var _testimonials = _interopRequireDefault(require("./core/testimonials"));

var _user = _interopRequireDefault(require("./core/user"));

var _eventSettings = _interopRequireDefault(require("./core/event-settings"));

var _userProfile = _interopRequireDefault(require("./core/user-profile"));

var _RootNavigationStack = _interopRequireDefault(require("./navigation/client/RootNavigationStack"));

var _Navigation = _interopRequireDefault(require("./navigation/Navigation"));

var _NavigationService = _interopRequireDefault(require("./navigation/NavigationService"));

var _queryParameter = require("./utils/queryParameter");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var HeaderContext = _react.default.createContext({
  options: {
    visible: true,
    withScroll: true,
    withLeftMenu: true
  },
  section: '',
  setCurrentSection: function setCurrentSection(currentSection) {},
  sectionName: '',
  setCurrentSectionName: function setCurrentSectionName(_setCurrentSectionName) {},
  collapsed: true,
  setCollapsed: function setCollapsed(_setCollapsed) {}
});

exports.HeaderContext = HeaderContext;

var AppInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(AppInternal, _React$PureComponent);

  var _super = _createSuper(AppInternal);

  function AppInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, AppInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      appState: _reactNative.AppState.currentState,
      headerContextState: {
        options: {
          visible: true,
          withScroll: false,
          withLeftMenu: true
        },
        section: '',
        sectionName: '',
        collapsed: true,
        setCurrentSection: function setCurrentSection(section) {
          _this.setState(function (oldState) {
            return {
              headerContextState: _objectSpread(_objectSpread({}, oldState.headerContextState), {}, {
                section: section
              })
            };
          });
        },
        setCurrentSectionName: function setCurrentSectionName(sectionName) {
          _this.setState(function (oldState) {
            return {
              headerContextState: _objectSpread(_objectSpread({}, oldState.headerContextState), {}, {
                sectionName: sectionName
              })
            };
          });
        },
        setOptions: function setOptions(options) {
          _this.setState(function (oldState) {
            return {
              headerContextState: _objectSpread(_objectSpread({}, oldState.headerContextState), {}, {
                options: options
              })
            };
          });
        },
        setCollapsed: function setCollapsed(collapsed) {
          _this.setState(function (oldState) {
            return {
              headerContextState: _objectSpread(_objectSpread({}, oldState.headerContextState), {}, {
                collapsed: collapsed
              })
            };
          });
        }
      },
      eventError: null
    };

    _this._handleAppStateChange = function (nextAppState) {
      var _this$props = _this.props,
          checkForUpdates = _this$props.checkForUpdates,
          gsAccessToken = _this$props.gsAccessToken,
          handleGsConnection = _this$props.handleGsConnection;

      if (_this.state.appState && _this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
        handleGsConnection(gsAccessToken);
        checkForUpdates();

        if (_reactNative.Platform.OS === 'ios') {
          _reactNativeAppsflyer.default.trackAppLaunch();
        }
      }

      _this.setState({
        appState: nextAppState
      });
    };

    return _this;
  }

  (0, _createClass2.default)(AppInternal, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;

      _reactNativeOrientationLocker.default.lockToPortrait();

      _reactNative.AppState.addEventListener('change', this._handleAppStateChange);

      !this.props.isFirebaseUserLoggedIn && this.props.getLDVariationsWithoutUser();

      if (process.env.ENV && process.env.ENV === 'production') {
        console.log('Redirect Prepared');

        if (window && window.location.protocol !== 'https:') {
          console.log('Protocol', window.location.protocol);
          window.location.replace("https:".concat(window.location.href.substring(window.location.protocol.length)));
        }
      }

      var eventId = (0, _queryParameter.getEventIdParam)(window.location.href);
      var domain = window.location.host;
      this.props.getEventInfo(eventId, domain).then(function () {
        (0, _reactRedux.batch)(function () {
          _this2.props.getEventSections();

          _this2.props.getEventStages();

          _this2.props.getEventSessions();

          _this2.props.getEventSpeakers();

          _this2.props.getEventPartners();

          _this2.props.getEventTestimonials();

          _this2.props.getEventBenefits();

          _this2.props.getEventChecklist();

          _this2.props.getEventLetters();

          _this2.props.getEventTickets();

          _this2.props.getPromoCodes();

          _this2.props.getExpos();

          _this2.props.getTables();

          _this2.props.getUserProfile();

          _this2.props.getTeamMembers();

          _this2.props.getEventSettings();
        });
      }).catch(function (error) {
        _this2.setState({
          eventError: error
        });
      });
      this.props.startFirebaseAuthListening();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _reactNative.AppState.removeEventListener('change', this._handleAppStateChange);
    }
  }, {
    key: "componentWillMount",
    value: function componentWillMount() {}
  }, {
    key: "renderWelcomeScreen",
    value: function renderWelcomeScreen() {
      var colors = this.props.theme.colors;

      if (_reactNative.Platform.OS === 'web') {
        return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(HeaderContext.Provider, {
          value: this.state.headerContextState
        }, this.state.headerContextState.options.visible && _react.default.createElement(_header.default, {
          withScroll: this.state.headerContextState.options.withScroll,
          withLeftMenu: this.state.headerContextState.options.withLeftMenu
        }), _react.default.createElement(_Page.default, null, _react.default.createElement(_RootNavigationStack.default, {
          ref: function ref(navigatorRef) {
            return _NavigationService.default.setTopLevelNavigator(navigatorRef);
          }
        })), this.props.isGlobalLoading && _react.default.createElement(_absoluteSpinner.default, {
          color: "pageBackground"
        }), _react.default.createElement(_RegisterProgress.default, null), _react.default.createElement(_dialogService.default, null)));
      } else {
        return _react.default.createElement(_reactNative.View, {
          style: [style.container, {
            backgroundColor: colors.background
          }]
        }, _react.default.createElement(_reactNative.StatusBar, {
          barStyle: "light-content",
          hidden: false,
          backgroundColor: "transparent",
          translucent: true
        }), _react.default.createElement(_reactNativeLinearGradient.default, {
          start: {
            x: 0.0,
            y: 0.5
          },
          end: {
            x: 1.0,
            y: 1.0
          },
          colors: ['#0175FF', '#2757DF', '#473EC5', '#6426AD', '#8F0489'],
          useAngle: true,
          angle: 100,
          style: {
            position: 'absolute',
            width: '100%',
            height: '100%'
          }
        }), _react.default.createElement(_WelcomeScreen.default, null));
      }
    }
  }, {
    key: "renderHomepage",
    value: function renderHomepage() {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "#000",
        translucent: false
      }), _react.default.createElement(_DeprecatedBanner.default, null), _react.default.createElement(_NewVersionNotification.default, null), _react.default.createElement(_Navigation.default, {
        ref: function ref(navigatorRef) {
          return _NavigationService.default.setTopLevelNavigator(navigatorRef);
        }
      }), _react.default.createElement(_NewVersionSnackBar.default, null));
    }
  }, {
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;

      if (this.state.eventError) {
        throw this.state.eventError;
      }

      if (this.props.isObsolete) {
        return _react.default.createElement(_ObsoleteScreen.default, null);
      }

      if (!this.props.stateRehydrated) {
        return _react.default.createElement(_SplashScreen.default, null);
      }

      if (this.props.loginSkipped) {
        return this.renderHomepage();
      }

      if (!this.props.isFirebaseUserLoggedIn) {
        return this.renderWelcomeScreen();
      }

      if (this.props.hasIncompleteProfile && !_reactNative.Platform.OS === 'web') {
        return _react.default.createElement(_UpdateProfileScreen.default, null);
      }

      if (!this.props.hasSeenPushNotificationSuggestion && !_reactNative.Platform.OS === 'web') {
        return _react.default.createElement(_reactNativeSafeAreaView.default, {
          style: [style.container, {
            backgroundColor: colors.background
          }],
          forceInset: {
            top: 'never',
            bottom: 'never'
          }
        }, _react.default.createElement(_reactNative.StatusBar, {
          barStyle: "light-content",
          hidden: false,
          backgroundColor: "#000",
          translucent: false
        }), _react.default.createElement(_EnableNotificationsRequest.default, null));
      }

      if (_reactNative.Platform.OS === 'web') {
        return this.renderWelcomeScreen();
      }

      return this.renderHomepage();
    }
  }]);
  return AppInternal;
}(_react.default.PureComponent);

exports.AppInternal = AppInternal;
AppInternal.defaultProps = {
  loginSkipped: false
};

var style = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center'
  }
});

function mapStateToProps(state) {
  return {
    gsAccessToken: _gameSparks.default.selectors.getGSAccessToken(state),
    isObsolete: _notifications.default.selectors.isAppObsolete(state),
    stateRehydrated: state._persist.rehydrated,
    isFirebaseUserLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state),
    userId: _user.default.selectors.getFirebaseUserUid(state),
    loginSkipped: _user.default.selectors.isLoginSkipped(state),
    hasIncompleteProfile: _user.default.selectors.hasIncompleteProfile(state),
    hasSeenPushNotificationSuggestion: _activity.default.selectors.getSeenPushNotificationSuggestion(state),
    isGlobalLoading: _global.default.selectors.isGlobalLoadingSelector(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  checkForUpdates: _notifications.default.actions.checkForUpdates,
  getLDVariationsWithoutUser: _user.default.actions.getLDVariationsWithoutUser,
  handleGsConnection: _gameSparks.default.actions.handleGamesparksConnection,
  getEventInfo: _eventInfo.default.actions.getEventInfo,
  startFirebaseAuthListening: _user.default.actions.startFirebaseAuthListening,
  getEventSections: _landingPageSections.default.actions.getLandingPageSections,
  getEventStages: _eventStages.default.actions.getEventStages,
  getEventSessions: _session.default.actions.getSessions,
  getEventSpeakers: _speakers.default.actions.getSpeakers,
  getEventPartners: _partners.default.actions.getPartners,
  getEventTestimonials: _testimonials.default.actions.getTestimonials,
  getEventBenefits: _benefits.default.actions.getBenefits,
  getEventChecklist: _checklist.default.actions.getChecklist,
  getEventLetters: _letter.default.actions.getLetters,
  getEventTickets: _checkout.default.actions.getEventTickets,
  getTables: _networking.default.actions.getTables,
  getPromoCodes: _checkout.default.actions.getPromoCodes,
  getExpos: _expo.default.actions.getExpos,
  getUserProfile: _userProfile.default.actions.getUserProfile,
  getTeamMembers: _team.default.actions.getTeams,
  getEventSettings: _eventSettings.default.actions.getEventSettings
})((0, _reactNativePaper.withTheme)(AppInternal));

exports.default = _default;