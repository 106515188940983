var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../theme/DesignSystem");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var LiveStateMarker = function LiveStateMarker(_ref) {
  var live = _ref.live;
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing,
      colors = _useTheme.colors;

  return _react.default.createElement(_core.Grid, {
    container: true,
    direction: "column",
    alignItems: "center",
    style: {
      marginTop: spacing.base,
      marginBottom: spacing.base
    }
  }, _react.default.createElement(_core.Grid, {
    item: true,
    container: true,
    direction: "row",
    alignItems: "center"
  }, _react.default.createElement(_core.Grid, {
    style: {
      color: live ? colors.successColor : colors.errorColor,
      paddingRight: spacing.m
    }
  }, _react.default.createElement("svg", {
    width: "11",
    height: "10",
    viewBox: "0 0 11 10",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react.default.createElement("circle", {
    cx: "5.60449",
    cy: "5",
    r: "5",
    fill: "currentColor"
  }))), _react.default.createElement(_core.Grid, null, _react.default.createElement(_core.Typography, {
    align: "left",
    variant: "paragraph",
    className: classes.liveStatus
  }, live ? 'LIVE' : 'OFF AIR'))), _react.default.createElement(_core.Hidden, {
    mdDown: true
  }, _react.default.createElement(_core.Grid, {
    item: true
  }, _react.default.createElement(_core.Typography, {
    align: "left",
    variant: "body2",
    className: classes.liveStatusDescription
  }, live ? _i18n.default.translate('backstage.participantsCanSeeYou') : _i18n.default.translate('backstage.participantsCannotSeeYou')))));
};

var _default = LiveStateMarker;
exports.default = _default;