var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.finishEventFreeRegistration = exports.finishCheckout = exports.setPromoCode = exports.removeCheckoutIntent = exports.resetSelectedAddonVariations = exports.setSelectedAddonVariations = exports.startCheckout = exports.finishCheckoutPaidTicket = exports.assignTickets = exports.createFreeUnassignedTickets = exports.startCheckoutPaidTicket = exports.getPromoCodes = exports.getEventTickets = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _paymentService = _interopRequireDefault(require("../services/paymentService.web"));

var _actions = require("../tickets/actions");

var _actions2 = require("../session/actions");

var _routesConsts = require("../../navigation/client/routes-consts");

var _NavigationService = _interopRequireDefault(require("../../navigation/NavigationService"));

var _selectors2 = require("../user/selectors");

var _actionTypes = require("./actionTypes");

var _constants = require("./constants");

var _selectors3 = require("./selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getEventTickets = (0, _toolkit.createAsyncThunk)(_constants.GET_EVENT_TICKETS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var getState, eventId, tickets;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.eventTickets(eventId).where('isHidden', '==', false).where('isArchived', '==', false).where('isPublished', '==', true));

          case 6:
            tickets = _context.sent;
            return _context.abrupt("return", tickets.filter(function (ticket) {
              return ticket.available || ticket.available === '';
            }).map(function (ticket) {
              return _objectSpread(_objectSpread({}, ticket), {}, {
                startDate: ticket.startDate.toDate(),
                endDate: ticket.endDate.toDate(),
                modifiedDate: ticket.modifiedDate ? ticket.modifiedDate.toDate() : ticket.startDate.toDate(),
                createdDate: ticket.createdDate.toDate()
              });
            }));

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getEventTickets = getEventTickets;
var getPromoCodes = (0, _toolkit.createAsyncThunk)(_constants.GET_PROMO_CODES, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_, _ref3) {
    var getState, eventId, promoCodes;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            getState = _ref3.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context2.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context2.next = 6;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.promoCodes(eventId));

          case 6:
            promoCodes = _context2.sent;
            return _context2.abrupt("return", promoCodes.map(function (code) {
              var _code$startDate, _code$endDate, _code$modifiedDate, _code$createdDate;

              return _objectSpread(_objectSpread({}, code), {}, {
                startDate: (_code$startDate = code.startDate) === null || _code$startDate === void 0 ? void 0 : _code$startDate.toDate(),
                endDate: (_code$endDate = code.endDate) === null || _code$endDate === void 0 ? void 0 : _code$endDate.toDate(),
                modifiedDate: (_code$modifiedDate = code.modifiedDate) === null || _code$modifiedDate === void 0 ? void 0 : _code$modifiedDate.toDate(),
                createdDate: (_code$createdDate = code.createdDate) === null || _code$createdDate === void 0 ? void 0 : _code$createdDate.toDate()
              });
            }));

          case 8:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.getPromoCodes = getPromoCodes;
var startCheckoutPaidTicket = (0, _toolkit.createAsyncThunk)(_constants.START_CHECKOUT_PAID_TICKET, function () {
  var _ref6 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(payloadInfo, _ref5) {
    var getState, eventId, isLoggedIn, firebaseToken, clientInfo;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            getState = _ref5.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context3.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            isLoggedIn = (0, _selectors2.isFirebaseUserLoggedIn)(getState());

            if (isLoggedIn) {
              _context3.next = 7;
              break;
            }

            return _context3.abrupt("return", Promise.resolve(true));

          case 7:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            _context3.next = 10;
            return _paymentService.default.startCheckOutPaidTicket(payloadInfo, firebaseToken);

          case 10:
            clientInfo = _context3.sent;
            return _context3.abrupt("return", clientInfo);

          case 12:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref6.apply(this, arguments);
  };
}());
exports.startCheckoutPaidTicket = startCheckoutPaidTicket;
var createFreeUnassignedTickets = (0, _toolkit.createAsyncThunk)(_constants.CREATE_FREE_UNASSIGNED_TICKETS, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(payloadInfo, _ref7) {
    var getState, eventId, isLoggedIn, firebaseToken, result;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            getState = _ref7.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context4.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            isLoggedIn = (0, _selectors2.isFirebaseUserLoggedIn)(getState());

            if (isLoggedIn) {
              _context4.next = 7;
              break;
            }

            return _context4.abrupt("return", Promise.resolve(true));

          case 7:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            _context4.next = 10;
            return _paymentService.default.createFreeUnassignedTickets(payloadInfo, firebaseToken);

          case 10:
            result = _context4.sent;
            return _context4.abrupt("return", result);

          case 12:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x7, _x8) {
    return _ref8.apply(this, arguments);
  };
}());
exports.createFreeUnassignedTickets = createFreeUnassignedTickets;
var assignTickets = (0, _toolkit.createAsyncThunk)(_constants.ASSIGN_TICKETS, function () {
  var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(payloadInfo, _ref9) {
    var getState, eventId, isLoggedIn, firebaseToken, result;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            getState = _ref9.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context5.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            isLoggedIn = (0, _selectors2.isFirebaseUserLoggedIn)(getState());

            if (isLoggedIn) {
              _context5.next = 7;
              break;
            }

            return _context5.abrupt("return", Promise.resolve(true));

          case 7:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            _context5.next = 10;
            return _paymentService.default.assignTickets(payloadInfo, firebaseToken);

          case 10:
            result = _context5.sent;
            return _context5.abrupt("return", result);

          case 12:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x9, _x10) {
    return _ref10.apply(this, arguments);
  };
}());
exports.assignTickets = assignTickets;
var finishCheckoutPaidTicket = (0, _toolkit.createAsyncThunk)(_constants.FINISH_CHECKOUT_PAID_TICKET, function () {
  var _ref12 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(_, _ref11) {
    var getState, dispatch, eventId, isLoggedIn;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            getState = _ref11.getState, dispatch = _ref11.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context6.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            isLoggedIn = (0, _selectors2.isFirebaseUserLoggedIn)(getState());

            if (isLoggedIn) {
              _context6.next = 7;
              break;
            }

            return _context6.abrupt("return", Promise.resolve(true));

          case 7:
            dispatch((0, _actions.getTickets)());
            dispatch((0, _actions2.getSessions)());

          case 9:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x11, _x12) {
    return _ref12.apply(this, arguments);
  };
}());
exports.finishCheckoutPaidTicket = finishCheckoutPaidTicket;
var startCheckout = (0, _toolkit.createAsyncThunk)(_constants.START_CHECKOUT, function () {
  var _ref15 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(_ref13, _ref14) {
    var ticketId, callbackUrl, _ref13$promoCodeId, promoCodeId, getState, dispatch, eventId, eventInfo, firebaseToken, firebaseUser, promoCode, variationsGroupedByAddonId, _yield$PaymentService, error;

    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            ticketId = _ref13.ticketId, callbackUrl = _ref13.callbackUrl, _ref13$promoCodeId = _ref13.promoCodeId, promoCodeId = _ref13$promoCodeId === void 0 ? '' : _ref13$promoCodeId;
            getState = _ref14.getState, dispatch = _ref14.dispatch;
            _context7.prev = 2;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context7.next = 6;
              break;
            }

            throw Error('No event!');

          case 6:
            eventInfo = (0, _selectors.selectEventInfo)(getState());

            if (eventInfo) {
              _context7.next = 9;
              break;
            }

            throw Error('No event info!');

          case 9:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            firebaseUser = (0, _selectors2.getFirebaseUser)(getState());
            promoCode = (0, _selectors3.promoCodeByIdSelector)(getState(), promoCodeId);

            if (!(promoCode && promoCode.percent === 100)) {
              _context7.next = 25;
              break;
            }

            variationsGroupedByAddonId = (0, _selectors3.selectedAddonVariationsGroupedByAddonIdSelector)(getState());
            _context7.next = 16;
            return _paymentService.default.purchaseFreeTicketOrUseExternalPayment(ticketId, eventId, firebaseUser.uid, variationsGroupedByAddonId, firebaseToken, promoCodeId);

          case 16:
            _yield$PaymentService = _context7.sent;
            error = _yield$PaymentService.error;

            if (!error) {
              _context7.next = 20;
              break;
            }

            return _context7.abrupt("return", Promise.reject(error));

          case 20:
            dispatch(removeCheckoutIntent());
            _context7.next = 23;
            return dispatch((0, _actions.getTickets)());

          case 23:
            _context7.next = 25;
            return dispatch((0, _actions2.getSessions)());

          case 25:
            return _context7.abrupt("return", Promise.resolve(true));

          case 28:
            _context7.prev = 28;
            _context7.t0 = _context7["catch"](2);
            return _context7.abrupt("return", Promise.reject(_context7.t0));

          case 31:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7, null, [[2, 28]]);
  }));

  return function (_x13, _x14) {
    return _ref15.apply(this, arguments);
  };
}());
exports.startCheckout = startCheckout;
var setSelectedAddonVariations = (0, _toolkit.createAction)(_constants.SET_SELECTED_ADDONS_VARIATIONS, function prepare(_ref16) {
  var selectedAddonVariations = _ref16.selectedAddonVariations,
      variationsPrice = _ref16.variationsPrice,
      totalPrice = _ref16.totalPrice;
  return {
    payload: {
      selectedAddonVariations: selectedAddonVariations,
      variationsPrice: variationsPrice,
      totalPrice: totalPrice
    }
  };
});
exports.setSelectedAddonVariations = setSelectedAddonVariations;
var resetSelectedAddonVariations = (0, _toolkit.createAction)(_constants.RESET_SELECTED_ADDONS_VARIATIONS);
exports.resetSelectedAddonVariations = resetSelectedAddonVariations;
var removeCheckoutIntent = (0, _toolkit.createAction)(_actionTypes.REMOVE_CHECKOUT_INTENT);
exports.removeCheckoutIntent = removeCheckoutIntent;
var setPromoCode = (0, _toolkit.createAction)(_constants.SET_PROMO_CODE);
exports.setPromoCode = setPromoCode;
var finishCheckout = (0, _toolkit.createAsyncThunk)(_constants.FINISH_CHECKOUT, function () {
  var _ref18 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(_, _ref17) {
    var getState, dispatch, eventId, eventInfo, ticketId, callbackUrl, firebaseToken, firebaseUser, ticket, totalPrice, eventSlug, variationsGroupedByAddonId, _yield$PaymentService2, result, error;

    return _regenerator.default.wrap(function _callee8$(_context8) {
      while (1) {
        switch (_context8.prev = _context8.next) {
          case 0:
            getState = _ref17.getState, dispatch = _ref17.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context8.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            eventInfo = (0, _selectors.selectEventInfo)(getState());

            if (eventInfo) {
              _context8.next = 7;
              break;
            }

            throw Error('No event info!');

          case 7:
            ticketId = (0, _selectors3.selectedTicketIdSelector)(getState());

            if (ticketId) {
              _context8.next = 10;
              break;
            }

            return _context8.abrupt("return", true);

          case 10:
            callbackUrl = (0, _selectors3.callbackUrlSelector)(getState());

            if (callbackUrl) {
              _context8.next = 13;
              break;
            }

            throw Error('No callback provided for stripe redirect!');

          case 13:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            firebaseUser = (0, _selectors2.getFirebaseUser)(getState());
            ticket = (0, _selectors3.selectedTicketByIdSelector)(getState(), ticketId);
            totalPrice = (0, _selectors3.ticketTotalPriceSelector)(getState());
            eventSlug = (0, _selectors.selectEventSlug)(getState());

            if (!(ticket && (ticket.type !== 'Free' || totalPrice) && !ticket.externalPaymentLink)) {
              _context8.next = 20;
              break;
            }

            return _context8.abrupt("return", _NavigationService.default.navigate(_routesConsts.Routes.PAYMENT_FORM, {
              ticketId: ticketId,
              eventId: eventSlug ? eventSlug : eventId
            }));

          case 20:
            variationsGroupedByAddonId = (0, _selectors3.selectedAddonVariationsGroupedByAddonIdSelector)(getState());
            _context8.next = 23;
            return _paymentService.default.purchaseFreeTicketOrUseExternalPayment(ticketId, eventId, firebaseUser.uid, variationsGroupedByAddonId, firebaseToken);

          case 23:
            _yield$PaymentService2 = _context8.sent;
            result = _yield$PaymentService2.result;
            error = _yield$PaymentService2.error;
            dispatch(removeCheckoutIntent());

            if (!error) {
              _context8.next = 29;
              break;
            }

            return _context8.abrupt("return", Promise.reject(error));

          case 29:
            if (!(result && eventInfo.externalPaymentSuccessPage)) {
              _context8.next = 33;
              break;
            }

            window.location.href = eventInfo.externalPaymentSuccessPage;
            _context8.next = 37;
            break;

          case 33:
            _context8.next = 35;
            return dispatch((0, _actions.getTickets)());

          case 35:
            _context8.next = 37;
            return dispatch((0, _actions2.getSessions)());

          case 37:
            if (result) _NavigationService.default.navigate(_routesConsts.Routes.LOBBY);
            return _context8.abrupt("return", true);

          case 39:
          case "end":
            return _context8.stop();
        }
      }
    }, _callee8);
  }));

  return function (_x15, _x16) {
    return _ref18.apply(this, arguments);
  };
}());
exports.finishCheckout = finishCheckout;
var finishEventFreeRegistration = (0, _toolkit.createAsyncThunk)(_constants.FINISH_EVENT_REGISTRATION, function () {
  var _ref21 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(_ref19, _ref20) {
    var ticketId, eventId, variationsGroupedByAddonId, _ref19$promoCodeId, promoCodeId, getState, dispatch, firebaseToken, firebaseUser, _yield$PaymentService3, result, error;

    return _regenerator.default.wrap(function _callee9$(_context9) {
      while (1) {
        switch (_context9.prev = _context9.next) {
          case 0:
            ticketId = _ref19.ticketId, eventId = _ref19.eventId, variationsGroupedByAddonId = _ref19.variationsGroupedByAddonId, _ref19$promoCodeId = _ref19.promoCodeId, promoCodeId = _ref19$promoCodeId === void 0 ? '' : _ref19$promoCodeId;
            getState = _ref20.getState, dispatch = _ref20.dispatch;

            if (eventId) {
              _context9.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            if (ticketId) {
              _context9.next = 6;
              break;
            }

            return _context9.abrupt("return", true);

          case 6:
            firebaseToken = (0, _selectors2.getFirebaseAccessToken)(getState());
            firebaseUser = (0, _selectors2.getFirebaseUser)(getState());
            _context9.next = 10;
            return _paymentService.default.purchaseFreeTicketOrUseExternalPayment(ticketId, eventId, firebaseUser.uid, variationsGroupedByAddonId, firebaseToken, promoCodeId);

          case 10:
            _yield$PaymentService3 = _context9.sent;
            result = _yield$PaymentService3.result;
            error = _yield$PaymentService3.error;

            if (!error) {
              _context9.next = 15;
              break;
            }

            return _context9.abrupt("return", Promise.reject(error));

          case 15:
            if (!result) {
              _context9.next = 21;
              break;
            }

            _context9.next = 18;
            return dispatch((0, _actions.getTickets)());

          case 18:
            _context9.next = 20;
            return dispatch((0, _actions2.getSessions)());

          case 20:
            return _context9.abrupt("return", true);

          case 21:
          case "end":
            return _context9.stop();
        }
      }
    }, _callee9);
  }));

  return function (_x17, _x18) {
    return _ref21.apply(this, arguments);
  };
}());
exports.finishEventFreeRegistration = finishEventFreeRegistration;