var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useTimeout;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _reactNative = require("react-native");

var MAX_TIMEOUT = 2147483647;

function useTimeout(callback, _timeout) {
  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      leaveTimestamp = _useState2[0],
      setLeaveTimestamp = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      comebackTimestamp = _useState4[0],
      setComebackTimestamp = _useState4[1];

  var timeout = Math.min(_timeout, MAX_TIMEOUT);
  (0, _react.useEffect)(function () {
    function visibilityChange() {
      if (_reactNative.Platform.OS === 'web') {
        return;
      }

      if (window.document.visibilityState === 'hidden') {
        setLeaveTimestamp(new Date());
      } else {
        setComebackTimestamp(new Date());
      }
    }

    window.document.addEventListener('visibilitychange', visibilityChange);
    return function () {
      window.document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [setLeaveTimestamp, setComebackTimestamp]);
  (0, _react.useEffect)(function () {
    if (leaveTimestamp && comebackTimestamp) {
      var _id2 = setTimeout(callback, timeout - (comebackTimestamp - leaveTimestamp));

      return function () {
        return clearTimeout(_id2);
      };
    } else {}

    var _id = setTimeout(callback, timeout);

    return function () {
      return clearTimeout(_id);
    };
  }, [callback, timeout, leaveTimestamp, comebackTimestamp]);
}