var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetPolls = exports.onPollsChanged = exports.subscribeToPollChanges = exports.deleteOption = exports.updatePoll = exports.castVote = exports.deletePoll = exports.savePoll = exports.getPolls = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _selectors2 = require("./selectors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getPolls = (0, _toolkit.createAsyncThunk)(_constants.GET_EVENT_POLLS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(location, _ref) {
    var getState, eventId, result;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context.next = 4;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.polls(eventId).orderBy('createdAt', 'asc'));

          case 4:
            result = _context.sent;
            return _context.abrupt("return", result.filter(function (poll) {
              return !poll.isArchived && poll.location === location;
            }));

          case 6:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getPolls = getPolls;
var savePoll = (0, _toolkit.createAsyncThunk)(_constants.SAVE_EVENT_POLL, function () {
  var _ref5 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_ref3, _ref4) {
    var pollData, location, getState, eventId, options, result, createdPoll;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            pollData = _ref3.pollData, location = _ref3.location;
            getState = _ref4.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            options = {};
            pollData.options.map(function (option, index) {
              options[option] = {
                votes: 0,
                createdAt: Date.now() + index
              };
            });
            _context2.next = 7;
            return _firebaseUtils.firebaseEntities.polls(eventId).add({
              title: pollData.title,
              location: location,
              options: options,
              createdAt: _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp(),
              modifiedAt: _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp()
            });

          case 7:
            result = _context2.sent;
            _context2.next = 10;
            return result.get();

          case 10:
            createdPoll = _context2.sent;
            return _context2.abrupt("return", _objectSpread({
              id: createdPoll.id
            }, createdPoll.data()));

          case 12:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref5.apply(this, arguments);
  };
}());
exports.savePoll = savePoll;
var deletePoll = (0, _toolkit.createAsyncThunk)(_constants.DELETE_EVENT_POLL, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(_ref6, _ref7) {
    var pollId, getState, eventId;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            pollId = _ref6.pollId;
            getState = _ref7.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context3.next = 5;
            return _firebaseUtils.firebaseEntities.polls(eventId).doc(pollId).update({
              isArchived: true,
              modifiedAt: _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp()
            });

          case 5:
            return _context3.abrupt("return", pollId);

          case 6:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref8.apply(this, arguments);
  };
}());
exports.deletePoll = deletePoll;
var castVote = (0, _toolkit.createAsyncThunk)(_constants.VOTE_ON_EVENT_POLL, function () {
  var _ref11 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(_ref9, _ref10) {
    var _firebaseEntities$pol;

    var pollId, option, getState, eventId, selectedPoll, options, modifiedAt;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            pollId = _ref9.pollId, option = _ref9.option;
            getState = _ref10.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            selectedPoll = (0, _selectors2.selectPollById)(getState(), pollId);
            options = selectedPoll.options;
            modifiedAt = _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp();
            _context4.next = 8;
            return _firebaseUtils.firebaseEntities.polls(eventId).doc(pollId).update((_firebaseEntities$pol = {}, (0, _defineProperty2.default)(_firebaseEntities$pol, "options.".concat(option, ".votes"), _reactNativeFirebase.default.firestore.FieldValue.increment(1)), (0, _defineProperty2.default)(_firebaseEntities$pol, "modifiedAt", modifiedAt), _firebaseEntities$pol));

          case 8:
            return _context4.abrupt("return", {
              id: pollId,
              changes: _objectSpread(_objectSpread({}, selectedPoll), {}, {
                modifiedAt: modifiedAt,
                options: _objectSpread(_objectSpread({}, options), {}, (0, _defineProperty2.default)({}, option, _objectSpread(_objectSpread({}, options[option]), {}, {
                  votes: options[option].votes + 1
                })))
              })
            });

          case 9:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x7, _x8) {
    return _ref11.apply(this, arguments);
  };
}());
exports.castVote = castVote;
var updatePoll = (0, _toolkit.createAsyncThunk)(_constants.UPDATE_POLL, function () {
  var _ref14 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(_ref12, _ref13) {
    var pollData, getState, eventId, selectedPoll, options, modifiedAt, additionalOptions;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            pollData = _ref12.pollData;
            getState = _ref13.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            selectedPoll = (0, _selectors2.selectPollById)(getState(), pollData.id);
            options = selectedPoll.options;
            modifiedAt = _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp();
            additionalOptions = {};
            pollData.additionalOptions.map(function (option, index) {
              additionalOptions[option] = {
                votes: 0,
                createdAt: Date.now() + index
              };
            });
            _context5.next = 10;
            return _firebaseUtils.firebaseEntities.polls(eventId).doc(pollData.id).update({
              title: pollData.title,
              options: _objectSpread(_objectSpread({}, options), additionalOptions),
              modifiedAt: modifiedAt
            });

          case 10:
            return _context5.abrupt("return", {
              id: pollData.id,
              changes: _objectSpread(_objectSpread({}, selectedPoll), {}, {
                title: pollData.title,
                modifiedAt: modifiedAt,
                options: _objectSpread(_objectSpread({}, options), additionalOptions)
              })
            });

          case 11:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x9, _x10) {
    return _ref14.apply(this, arguments);
  };
}());
exports.updatePoll = updatePoll;
var deleteOption = (0, _toolkit.createAsyncThunk)(_constants.DELETE_EVENT_POLL_OPTION, function () {
  var _ref17 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(_ref15, _ref16) {
    var _firebaseEntities$pol2;

    var pollId, option, getState, eventId, selectedPoll, modifiedAt;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            pollId = _ref15.pollId, option = _ref15.option;
            getState = _ref16.getState;
            eventId = (0, _selectors.selectEventInfoId)(getState());
            selectedPoll = (0, _selectors2.selectPollById)(getState(), pollId);
            modifiedAt = _reactNativeFirebase.default.firestore.FieldValue.serverTimestamp();
            _context6.next = 7;
            return _firebaseUtils.firebaseEntities.polls(eventId).doc(pollId).update((_firebaseEntities$pol2 = {}, (0, _defineProperty2.default)(_firebaseEntities$pol2, "options.".concat(option), _reactNativeFirebase.default.firestore.FieldValue.delete()), (0, _defineProperty2.default)(_firebaseEntities$pol2, "modifiedAt", modifiedAt), _firebaseEntities$pol2));

          case 7:
            return _context6.abrupt("return", {
              id: pollId,
              changes: _objectSpread(_objectSpread({}, selectedPoll), {}, {
                modifiedAt: modifiedAt,
                options: _objectSpread({}, selectedPoll.options)
              })
            });

          case 8:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x11, _x12) {
    return _ref17.apply(this, arguments);
  };
}());
exports.deleteOption = deleteOption;
var subscribeToPollChanges = (0, _toolkit.createAsyncThunk)(_constants.SUBSCRIBE_TO_POLL_CHANGES, function () {
  var _ref19 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(location, _ref18) {
    var getState, dispatch, eventId, unsubscribe;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            getState = _ref18.getState, dispatch = _ref18.dispatch;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (eventId) {
              _context7.next = 4;
              break;
            }

            throw Error('No event!');

          case 4:
            _context7.next = 6;
            return new Promise(function (resolve, reject) {
              var _unsubscribe = _firebaseUtils.firebaseEntities.polls(eventId).where('location', '==', location).orderBy('modifiedAt', 'desc').limit(1).onSnapshot(function (_ref20) {
                var docs = _ref20.docs;
                dispatch(onPollsChanged(docs.map(function (doc) {
                  return _objectSpread({
                    id: doc.id
                  }, doc.data());
                })));
              }, function (err) {
                return reject(err);
              });

              resolve(_unsubscribe);
            });

          case 6:
            unsubscribe = _context7.sent;
            return _context7.abrupt("return", unsubscribe);

          case 8:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));

  return function (_x13, _x14) {
    return _ref19.apply(this, arguments);
  };
}());
exports.subscribeToPollChanges = subscribeToPollChanges;
var onPollsChanged = (0, _toolkit.createAction)(_constants.ON_POLLS_CHANGED);
exports.onPollsChanged = onPollsChanged;
var resetPolls = (0, _toolkit.createAction)(_constants.RESET_POLLS);
exports.resetPolls = resetPolls;