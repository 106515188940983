Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customStyles = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  headerLeft: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 24
  },
  headerLeftText: {
    fontSize: 27,
    color: 'white'
  },
  headerStyle: {
    backgroundColor: '#000'
  },
  contentContainerStyle: {
    paddingVertical: 40,
    paddingHorizontal: 24,
    backgroundColor: 'black'
  },
  container: {
    flex: 1,
    backgroundColor: 'black'
  },
  content: {
    marginTop: 32
  },
  headline: {
    marginBottom: 16,
    fontWeight: 'bold'
  },
  title: {
    marginBottom: 16,
    fontWeight: 'bold'
  },
  paragraph: {
    marginBottom: 16
  }
});

exports.default = _default;
var customStyles = {
  loaderColor: '#fff',
  loaderSize: 'large'
};
exports.customStyles = customStyles;