var _classCallCheck = require("@babel/runtime/helpers/classCallCheck");

var _createClass = require("@babel/runtime/helpers/createClass");

var IntercomClient = function () {
  "use strict";

  function IntercomClient() {
    _classCallCheck(this, IntercomClient);

    this.Notifications = {
      UNREAD_COUNT: null,
      WINDOW_DID_HIDE: null,
      WINDOW_DID_SHOW: null
    };
    this._eventEmitter = void 0;
    this._eventHandlers = void 0;
    this._eventHandlers = {};
  }

  _createClass(IntercomClient, [{
    key: "registerIdentifiedUser",
    value: function registerIdentifiedUser(options) {}
  }, {
    key: "sendTokenToIntercom",
    value: function sendTokenToIntercom(token) {}
  }, {
    key: "updateUser",
    value: function updateUser(options) {}
  }, {
    key: "registerUnidentifiedUser",
    value: function registerUnidentifiedUser() {}
  }, {
    key: "logout",
    value: function logout() {}
  }, {
    key: "logEvent",
    value: function logEvent(eventName, metaData) {}
  }, {
    key: "handlePushMessage",
    value: function handlePushMessage() {}
  }, {
    key: "displayMessenger",
    value: function displayMessenger() {}
  }, {
    key: "hideMessenger",
    value: function hideMessenger() {}
  }, {
    key: "displayMessageComposer",
    value: function displayMessageComposer() {}
  }, {
    key: "displayMessageComposerWithInitialMessage",
    value: function displayMessageComposerWithInitialMessage(message) {}
  }, {
    key: "displayConversationsList",
    value: function displayConversationsList() {}
  }, {
    key: "getUnreadConversationCount",
    value: function getUnreadConversationCount() {}
  }, {
    key: "displayHelpCenter",
    value: function displayHelpCenter() {}
  }, {
    key: "setLauncherVisibility",
    value: function setLauncherVisibility(visibility) {}
  }, {
    key: "setInAppMessageVisibility",
    value: function setInAppMessageVisibility(visibility) {}
  }, {
    key: "setupAPN",
    value: function setupAPN(deviceToken) {}
  }, {
    key: "registerForPush",
    value: function registerForPush() {}
  }, {
    key: "setUserHash",
    value: function setUserHash(userHash) {}
  }, {
    key: "setBottomPadding",
    value: function setBottomPadding(padding) {}
  }, {
    key: "addEventListener",
    value: function addEventListener(type, handler) {}
  }, {
    key: "removeEventListener",
    value: function removeEventListener(type, handler) {}
  }]);

  return IntercomClient;
}();

IntercomClient.Visibility = {
  VISIBLE: "VISIBLE",
  GONE: "GONE"
};
module.exports = new IntercomClient();