var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.firebaseFileUpload = firebaseFileUpload;
exports.createCleanupFileDoc = createCleanupFileDoc;
exports.removeCleanupFileDoc = removeCleanupFileDoc;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _moment = _interopRequireDefault(require("moment"));

var CLEANUP_COLLECTION = 'cleanups';

function firebaseFileUpload(_x, _x2, _x3) {
  return _firebaseFileUpload.apply(this, arguments);
}

function _firebaseFileUpload() {
  _firebaseFileUpload = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(path, file, type) {
    var fileRef, uploadTask, downloadURL;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            fileRef = _reactNativeFirebase.default.storage().ref(path);
            _context.next = 3;
            return fileRef.put(file, {
              contentType: type
            });

          case 3:
            uploadTask = _context.sent;
            _context.next = 6;
            return uploadTask.ref.getDownloadURL();

          case 6:
            downloadURL = _context.sent;
            return _context.abrupt("return", [downloadURL, fileRef]);

          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _firebaseFileUpload.apply(this, arguments);
}

function createCleanupFileDoc(_x4, _x5, _x6, _x7, _x8) {
  return _createCleanupFileDoc.apply(this, arguments);
}

function _createCleanupFileDoc() {
  _createCleanupFileDoc = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(registrationId, fileId, path, basePath, eventId) {
    var doc, now;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            doc = _reactNativeFirebase.default.firestore().doc("".concat(CLEANUP_COLLECTION, "/").concat(registrationId));
            now = (0, _moment.default)();
            now.add(1, 'day');
            _context2.next = 5;
            return doc.set({
              id: registrationId,
              eventId: eventId,
              path: basePath,
              files: (0, _defineProperty2.default)({}, fileId, path),
              expirationDate: now.toDate()
            }, {
              merge: true
            });

          case 5:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));
  return _createCleanupFileDoc.apply(this, arguments);
}

function removeCleanupFileDoc(_x9) {
  return _removeCleanupFileDoc.apply(this, arguments);
}

function _removeCleanupFileDoc() {
  _removeCleanupFileDoc = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(registrationId) {
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            _context3.next = 2;
            return _reactNativeFirebase.default.firestore().doc("".concat(CLEANUP_COLLECTION, "/").concat(registrationId)).delete();

          case 2:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));
  return _removeCleanupFileDoc.apply(this, arguments);
}