var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _actionsStyles = _interopRequireDefault(require("./actions-styles"));

function CardActionsView(props) {
  var actionsStyles = [_actionsStyles.default.container];
  return _react.default.createElement(_reactNativePaperV.Card.Actions, {
    style: actionsStyles
  }, props.children);
}

var _default = CardActionsView;
exports.default = _default;