var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatEventData = exports.firebaseEntities = exports.collectionPromise = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ID = 'id';
var START_DATE = 'startDate';
var END_DATE = 'endDate';
var DATE_FORMAT = 'dateFormat';
var NAME = 'name';
var HEADLINE = 'headline';
var ABOUT = 'about';
var DESCRIPTION = 'description';
var CHECKOUT_BLOCK = 'checkoutBlock';
var CURRENCY = 'currency';
var LOGO_URL = 'logoUrl';
var BANNER_URL = 'bannerUrl';
var SHOW_HEADER_OVERLAY = 'showHeaderOverlay';
var CONNECTED_ACCOUNT_ID = 'connectedAccountId';
var FOOTER = 'footer';
var CUSTOMIZATION = 'customization';
var CREATED_BY = 'createdBy';
var TIMEZONE = 'timezone';
var LANGUAGE_ID = 'languageId';
var HIDE_USERS_LIVE_COUNT = 'hideUsersLiveCount';
var USE_MODAL_CHECKOUT = 'useModalCheckout';
var EXTERNAL_PAYMENT_SUCCESS_PAGE = 'externalPaymentSuccessPage';
var PASSWORD_LESS_REGISTRATION = 'passwordLessRegistration';
var CUSTOM_REGISTRATION_ORDER = 'customRegistrationOrder';

var collectionPromise = function collectionPromise(path) {
  return new Promise(function (resolve, reject) {
    var entities = [];
    path.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        return entities.push(_objectSpread({
          id: doc.id
        }, doc.data()));
      });
      resolve(entities);
    }).catch(function (error) {
      return reject(error);
    });
  });
};

exports.collectionPromise = collectionPromise;
var firebaseEntities = {
  speakers: function speakers(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/speakers"));
  },
  sponsors: function sponsors(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/sponsors"));
  },
  sponsorCategories: function sponsorCategories(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/sponsorCategories"));
  },
  booths: function booths(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/booths"));
  },
  tickets: function tickets(userId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/tickets"));
  },
  ticketsOnHold: function ticketsOnHold(userId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/ticketsOnHold"));
  },
  addons: function addons(userId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/addons"));
  },
  eventTickets: function eventTickets(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/tickets"));
  },
  stages: function stages(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/stages"));
  },
  backstages: function backstages(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/backstages"));
  },
  sessions: function sessions(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/sessions"));
  },
  videos: function videos(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/videos"));
  },
  promoCodes: function promoCodes(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/promoCodes"));
  },
  testimonials: function testimonials(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/testimonials"));
  },
  benefits: function benefits(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/benefits"));
  },
  checklist: function checklist(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/checklist"));
  },
  letters: function letters(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/letter"));
  },
  sections: function sections(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/landingPage"));
  },
  legalInformations: function legalInformations(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/legal-informations"));
  },
  customRegistration: function customRegistration(eventId, formId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/custom-registration-forms/").concat(formId, "/custom-form-fields"));
  },
  customRegistrationUserFields: function customRegistrationUserFields(userId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/custom-registration-fields"));
  },
  eventFiles: function eventFiles(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/event-files"));
  },
  networkingTables: function networkingTables(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/networking-tables"));
  },
  teams: function teams() {
    return _reactNativeFirebase.default.firestore().collection("teams");
  },
  polls: function polls(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/polls"));
  },
  settings: function settings(eventId) {
    return _reactNativeFirebase.default.firestore().collection("events/".concat(eventId, "/settings"));
  },
  userRoles: function userRoles(userId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/userRoles"));
  },
  userFeatureRoles: function userFeatureRoles(userId, eventId) {
    return _reactNativeFirebase.default.firestore().collection("users/".concat(userId, "/userRoles/").concat(eventId, "/rolesByResource"));
  }
};
exports.firebaseEntities = firebaseEntities;

var formatEventData = function formatEventData(eventData) {
  var _eventData$CHECKOUT_B, _eventData$CHECKOUT_B2, _eventData$CHECKOUT_B3, _eventData$CHECKOUT_B4, _eventData$CHECKOUT_B5, _eventData$CHECKOUT_B6, _eventData$CHECKOUT_B7, _ref;

  return _ref = {}, (0, _defineProperty2.default)(_ref, ID, eventData[ID]), (0, _defineProperty2.default)(_ref, START_DATE, eventData[START_DATE] ? eventData[START_DATE].toDate() : ''), (0, _defineProperty2.default)(_ref, END_DATE, eventData[END_DATE] ? eventData[END_DATE].toDate() : ''), (0, _defineProperty2.default)(_ref, TIMEZONE, eventData[TIMEZONE] || ''), (0, _defineProperty2.default)(_ref, DATE_FORMAT, eventData[DATE_FORMAT] || 'AM-PM'), (0, _defineProperty2.default)(_ref, NAME, eventData[NAME]), (0, _defineProperty2.default)(_ref, HEADLINE, eventData[HEADLINE]), (0, _defineProperty2.default)(_ref, CUSTOMIZATION, eventData[CUSTOMIZATION]), (0, _defineProperty2.default)(_ref, ABOUT, eventData[ABOUT]), (0, _defineProperty2.default)(_ref, DESCRIPTION, eventData[DESCRIPTION]), (0, _defineProperty2.default)(_ref, BANNER_URL, eventData[BANNER_URL]), (0, _defineProperty2.default)(_ref, SHOW_HEADER_OVERLAY, eventData[SHOW_HEADER_OVERLAY]), (0, _defineProperty2.default)(_ref, LOGO_URL, eventData[LOGO_URL]), (0, _defineProperty2.default)(_ref, CURRENCY, eventData[CURRENCY]), (0, _defineProperty2.default)(_ref, CREATED_BY, eventData[CREATED_BY]), (0, _defineProperty2.default)(_ref, CONNECTED_ACCOUNT_ID, eventData[CONNECTED_ACCOUNT_ID]), (0, _defineProperty2.default)(_ref, CHECKOUT_BLOCK, {
    video: (_eventData$CHECKOUT_B = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B === void 0 ? void 0 : _eventData$CHECKOUT_B.video,
    poster: (_eventData$CHECKOUT_B2 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B2 === void 0 ? void 0 : _eventData$CHECKOUT_B2.poster,
    title: (_eventData$CHECKOUT_B3 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B3 === void 0 ? void 0 : _eventData$CHECKOUT_B3.title,
    description: (_eventData$CHECKOUT_B4 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B4 === void 0 ? void 0 : _eventData$CHECKOUT_B4.description,
    defaultVideoThumbnail: (_eventData$CHECKOUT_B5 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B5 === void 0 ? void 0 : _eventData$CHECKOUT_B5.defaultVideoThumbnail,
    buyTicketButtonLabel: (_eventData$CHECKOUT_B6 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B6 === void 0 ? void 0 : _eventData$CHECKOUT_B6.buyTicketButtonLabel,
    registerFreeButtonLabel: (_eventData$CHECKOUT_B7 = eventData[CHECKOUT_BLOCK]) === null || _eventData$CHECKOUT_B7 === void 0 ? void 0 : _eventData$CHECKOUT_B7.registerFreeButtonLabel
  }), (0, _defineProperty2.default)(_ref, FOOTER, {
    copyrightText: eventData[FOOTER] ? eventData[FOOTER].copyrightText : '',
    showPoweredByEventMix: eventData[FOOTER] ? eventData[FOOTER].showPoweredByEventMix : true,
    showPrivacyPolicy: eventData[FOOTER] ? eventData[FOOTER].showPrivacyPolicy : true,
    showTermsAndConditions: eventData[FOOTER] ? eventData[FOOTER].showTermsAndConditions : true
  }), (0, _defineProperty2.default)(_ref, LANGUAGE_ID, eventData[LANGUAGE_ID]), (0, _defineProperty2.default)(_ref, HIDE_USERS_LIVE_COUNT, eventData[HIDE_USERS_LIVE_COUNT] || false), (0, _defineProperty2.default)(_ref, USE_MODAL_CHECKOUT, eventData[USE_MODAL_CHECKOUT] || eventData[USE_MODAL_CHECKOUT] === false ? eventData[USE_MODAL_CHECKOUT] : false), (0, _defineProperty2.default)(_ref, EXTERNAL_PAYMENT_SUCCESS_PAGE, eventData[EXTERNAL_PAYMENT_SUCCESS_PAGE]), (0, _defineProperty2.default)(_ref, PASSWORD_LESS_REGISTRATION, eventData[PASSWORD_LESS_REGISTRATION]), (0, _defineProperty2.default)(_ref, CUSTOM_REGISTRATION_ORDER, eventData[CUSTOM_REGISTRATION_ORDER]), _ref;
};

exports.formatEventData = formatEventData;