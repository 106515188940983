var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeVideo = _interopRequireDefault(require("react-native-video"));

var _recordedVideosContainer = require("../../recorded-videos/recorded-videos-container");

var _videoModalStyles = _interopRequireDefault(require("./video-modal-styles"));

var VideoModalView = function VideoModalView(props) {
  var videoType = props.video && props.video.includes('.m3u8') ? 'HLS' : 'NON-HLS';
  var isPresentation = props.type && props.type === _recordedVideosContainer.RECORDING_TYPES.PRESENTATION ? true : false;
  var isLiveRecorded = props.type && props.type === _recordedVideosContainer.RECORDING_TYPES.LIVE_RECORDED ? true : false;
  return _react.default.createElement(_reactNative.View, {
    style: _videoModalStyles.default.backdrop
  }, _react.default.createElement(_reactNative.View, {
    style: _videoModalStyles.default.modalView
  }, _react.default.createElement(_reactNative.View, {
    style: _videoModalStyles.default.modalHeader
  }, _react.default.createElement(_reactNative.Text, {
    style: _videoModalStyles.default.close,
    onPress: function onPress() {
      return props.hideModal();
    }
  }, "\xD7")), _react.default.createElement(_reactNative.View, {
    style: _videoModalStyles.default.modalBody
  }, function () {
    switch (videoType) {
      case 'NON-HLS':
        {
          if (isPresentation || isLiveRecorded) {
            return _react.default.createElement("video", {
              autoPlay: true,
              controls: true,
              controlsList: "nodownload"
            }, _react.default.createElement("source", {
              src: props.video,
              type: "video/mp4"
            }), "Your browser does not support HTML video.");
          } else {
            return _react.default.createElement("iframe", {
              title: props.title,
              height: "100%",
              width: "100%",
              style: {
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              },
              src: props.video,
              frameBorder: "0",
              allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
              allowFullScreen: true
            });
          }
        }

      case 'HLS':
        {
          return _react.default.createElement(_reactNativeVideo.default, {
            style: _videoModalStyles.default.webVideo,
            source: {
              uri: props.video
            },
            playInBackground: false,
            key: "recorded-video",
            autoPlay: true,
            muted: false,
            jwplayer: true
          });
        }

      default:
        {
          return null;
        }
    }
  }())));
};

var _default = VideoModalView;
exports.default = _default;