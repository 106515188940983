Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  dividerContainer: {
    height: 24,
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dividerLine: {
    flex: 1,
    height: 24,
    borderBottomWidth: 1,
    borderColor: 'rgba(255,255,255,0.2)',
    transform: [{
      translateY: -12
    }]
  },
  loadMoreButton: {
    backgroundColor: '#2E2E2E',
    width: 48
  }
});

exports.default = _default;