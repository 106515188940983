Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    text: {
      padding: 5,
      marginBottom: 15
    },
    title: {
      fontWeight: 'bold',
      marginBottom: 15
    },
    networkingText: {
      marginBottom: 15
    },
    liveNow: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.headerText.main
    },
    upcoming: {
      backgroundColor: theme.palette.badgeUpcoming.main,
      color: theme.palette.headerText.main
    }
  };
});
var _default = styles;
exports.default = _default;