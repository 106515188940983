Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useFooterStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    border: {
      borderTopColor: theme.colors.borderColor,
      borderTopStyle: 'solid',
      borderTopWidth: 1
    },
    footerLinks: {
      marginRight: theme.spacing.footerRightLinks
    },
    footerPrivacy: {
      marginLeft: theme.spacing.l
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    footerSpacing: {
      marginLeft: theme.spacing.none,
      marginRight: theme.spacing.none
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.xsm;
}, function (theme) {
  return {
    footerLinks: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    footerPrivacy: {
      marginLeft: theme.spacing.none,
      marginTop: theme.spacing.sm
    }
  };
}).buildHook();
var _default = useFooterStyles;
exports.default = _default;