Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useNoVideosStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginBottom: theme.spacing.xl,
      marginTop: theme.spacing.m,
      marginHorizontal: -theme.spacing.m
    },
    noVideContainer: {
      maxWidth: 400
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.md;
}, function (theme) {
  return {
    lobbyCard: {
      margin: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.xsm;
}, {
  container: {
    justifyContent: 'center'
  }
}).buildHook();
var _default = useNoVideosStyles;
exports.default = _default;