var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    modalContent: {
      maxHeight: '100%',
      overflowY: 'auto'
    },
    modalBox: (0, _defineProperty2.default)({
      position: 'relative',
      margin: 'auto',
      padding: 0,
      maxHeight: '90%',
      maxWidth: '90%',
      outline: 'none'
    }, theme.breakpoints.down('md'), {
      width: '90%'
    }),
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1.5),
      padding: theme.spacing(0.5)
    }
  };
});
var _default = styles;
exports.default = _default;