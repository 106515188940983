var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PhoneValidationView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _horizontalWhiteNewLogo = _interopRequireDefault(require("../../common/logo/horizontalWhiteNewLogo.png"));

var _NavigationButtons = require("../../../navigation/NavigationButtons");

var _phoneLoginStyles = _interopRequireDefault(require("./phoneLogin-styles"));

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _reactNativeConfirmationCodeField = require("react-native-confirmation-code-field");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function PhoneValidationView(phoneProps) {
  var onVerification = phoneProps.onVerification,
      onPhoneAuth = phoneProps.onPhoneAuth,
      onBackToPhoneView = phoneProps.onBackToPhoneView,
      loading = phoneProps.loading;

  var _useState = (0, _react.useState)(''),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var CELL_COUNT = 6;
  var ref = (0, _reactNativeConfirmationCodeField.useBlurOnFulfill)({
    value: value,
    cellCount: CELL_COUNT
  });

  var _useClearByFocusCell = (0, _reactNativeConfirmationCodeField.useClearByFocusCell)({
    value: value,
    setValue: setValue
  }),
      _useClearByFocusCell2 = (0, _slicedToArray2.default)(_useClearByFocusCell, 2),
      props = _useClearByFocusCell2[0],
      getCellOnLayoutHandler = _useClearByFocusCell2[1];

  var handleOnPressBack = (0, _react.useCallback)(function () {
    setValue('');
    onBackToPhoneView();
  }, [onBackToPhoneView]);
  var handleOnPress = (0, _react.useCallback)(function (text) {
    setValue('');
    onVerification(value);
  }, [value, onVerification]);
  var handleOnPressResend = (0, _react.useCallback)(function (text) {
    setValue('');
    onPhoneAuth();
  }, [onPhoneAuth]);
  var renderResend = (0, _react.useMemo)(function () {
    return _react.default.createElement(_reactNative.View, {
      testID: "resendButton"
    }, _react.default.createElement(_reactNativePaperV.Paragraph, {
      style: _phoneLoginStyles.default.resendMessage
    }, "Didn't receive the text message?"), _react.default.createElement(_reactNativePaperV.TouchableRipple, {
      testID: "resendTouchable",
      name: 'resendCode',
      disabled: loading,
      onPress: handleOnPressResend
    }, _react.default.createElement(_reactNativePaperV.Paragraph, {
      style: _phoneLoginStyles.default.resendLink
    }, "Request code again")));
  }, [handleOnPressResend, loading]);
  return _react.default.createElement(_react.default.Fragment, null, (0, _NavigationButtons.backButtonWithCustomAction)(handleOnPressBack), _react.default.createElement(_reactNative.View, {
    style: _phoneLoginStyles.default.verificationContainer,
    testID: "verifContainer"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_reactNative.Image, {
    source: _horizontalWhiteNewLogo.default,
    resizeMode: 'contain',
    style: _phoneLoginStyles.default.logo
  })), _react.default.createElement(_section.default, {
    spacingHorizontal: "extraLoose"
  }, _react.default.createElement(_section.default, {
    spacingVertical: "extraLoose"
  }, _react.default.createElement(_reactNativePaperV.Paragraph, {
    style: _phoneLoginStyles.default.paragraph
  }, "Enter code from message:"), _react.default.createElement(_reactNativeConfirmationCodeField.CodeField, (0, _extends2.default)({
    ref: ref
  }, props, {
    value: value,
    onChangeText: setValue,
    cellCount: CELL_COUNT,
    returnKeyType: 'done',
    keyboardType: "number-pad",
    renderCell: function renderCell(_ref) {
      var index = _ref.index,
          symbol = _ref.symbol,
          isFocused = _ref.isFocused;
      return _react.default.createElement(_reactNative.View, {
        onLayout: getCellOnLayoutHandler(index),
        key: index,
        style: [_phoneLoginStyles.default.cellRoot, isFocused && _phoneLoginStyles.default.focusCell]
      }, _react.default.createElement(_reactNative.Text, {
        style: _phoneLoginStyles.default.cellText
      }, symbol));
    }
  }))), _react.default.createElement(_button.default, {
    testID: "confirmButton",
    size: "large",
    textButton: true,
    style: _phoneLoginStyles.default.submit,
    loading: loading,
    textColor: "#000",
    disabled: !value || loading,
    onPress: handleOnPress
  }, "Confirm"), _react.default.createElement(_section.default, {
    spacingVertical: "extraLoose"
  }, renderResend)), _react.default.createElement(_section.default, null), _react.default.createElement(_section.default, null)));
}