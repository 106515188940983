Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customStyles = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    position: 'relative',
    paddingVertical: 20,
    marginTop: 10,
    marginBottom: 10
  }
});

exports.default = _default;
var customStyles = {
  loaderColor: '#fff',
  loaderSize: 'large'
};
exports.customStyles = customStyles;