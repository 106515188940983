var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _typographyStyles = _interopRequireDefault(require("./typography-styles"));

function TextView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors;

  var textStyles = [_typographyStyles.default.text, props.style];

  if (props.inverted) {
    textStyles.push({
      color: colors.neutralDarkest
    });
  }

  if (props.textStyle === 'subdued') {
    textStyles.push({
      color: colors.grayLighter
    });
  }

  if (props.textStyle === 'positive') {
    textStyles.push({
      color: colors.positive
    });
  }

  if (props.textStyle === 'negative') {
    textStyles.push({
      color: colors.destructive
    });
  }

  if (props.textStyle === 'strong') {
    textStyles.push({
      fontWeight: 'bold'
    });
  }

  if (props.textStyle === 'center') {
    textStyles.push({
      textAlign: 'center'
    });
  }

  return _react.default.createElement(_reactNativePaperV.Text, {
    style: textStyles
  }, props.children);
}

var _default = TextView;
exports.default = _default;