var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PhoneLoginContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _phoneLoginStyles = _interopRequireDefault(require("./phoneLogin-styles"));

var _phoneNumberView = _interopRequireDefault(require("./phoneNumber-view"));

var _phoneValidationView = _interopRequireDefault(require("./phoneValidation-view"));

var _user2 = _interopRequireDefault(require("../../../core/user"));

var _Analytics = require("../../../core/services/Analytics");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/user/actions");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function PhoneLoginContainer(props) {
  var onGoBack = props.onGoBack,
      onSuccess = props.onSuccess,
      phoneLogin = props.phoneLogin;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      confirmResult = _useState4[0],
      setConfirmResult = _useState4[1];

  var _useState5 = (0, _react.useState)(null),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      idToken = _useState6[0],
      setIdToken = _useState6[1];

  var _useState7 = (0, _react.useState)(''),
      _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
      lastNr = _useState8[0],
      setLastNr = _useState8[1];

  var dispatch = (0, _reactRedux.useDispatch)();
  var handlePhoneNumberLogin = (0, _react.useCallback)(function (user) {
    if (idToken) {
      return;
    }

    dispatch((0, _actions.firebaseLoginInProgress)());
    dispatch((0, _actions.firebaseLoginCompleted)(user));
    setLoading(false);
  }, [onSuccess, phoneLogin, idToken]);
  (0, _react.useEffect)(function () {
    if (idToken || _reactNative.Platform.OS === 'ios' || _reactNative.Platform.OS === 'web') {
      return;
    }

    var unsubscribe = _reactNativeFirebase.default.auth().onAuthStateChanged(function (updatedUser) {
      if (updatedUser) {
        var _updatedUser$_user = updatedUser._user,
            _user = _updatedUser$_user === void 0 ? {} : _updatedUser$_user;

        if (_user.phoneNumber && _user.phoneNumber === lastNr.replace(/\s+/g, '')) {
          handlePhoneNumberLogin();
        }
      }
    });

    return function () {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [lastNr, handlePhoneNumberLogin, idToken]);
  var handleBackToPhoneView = (0, _react.useCallback)(function () {
    setConfirmResult(null);
  }, []);
  var handlePhoneAuth = (0, _react.useCallback)(function (number) {
    var nr = number || lastNr;

    if (idToken || loading) {
      return;
    }

    if (number) {
      setLastNr(number);
    }

    (0, _Analytics.trackEvent)('login_press', {
      provider: _user2.default.constants.LOGIN_PROVIDERS.FIREBASE
    });
    var secondParam = true;

    if (_reactNative.Platform.OS === 'web') {
      window.recaptchaVerifier = new _reactNativeFirebase.default.auth.RecaptchaVerifier('recaptcha-container');
      secondParam = window.recaptchaVerifier;
    }

    setLoading(true);

    _reactNativeFirebase.default.auth().signInWithPhoneNumber(nr, secondParam).then(function (confirmRes) {
      setConfirmResult(confirmRes);
      setLoading(false);
    }).catch(function (error) {
      setLoading(false);

      _reactNative.Alert.alert('Phone Auth Error', error.message);
    });
  }, [loading, lastNr, idToken]);
  var handleVerification = (0, _react.useCallback)(function (verification) {
    if (idToken) {
      return;
    }

    if (!loading && confirmResult) {
      setLoading(true);
      confirmResult.confirm(verification).then(function (user) {
        handlePhoneNumberLogin(user);
      }).catch(function (error) {
        setLoading(false);

        if (error.code && error.code === 'auth/invalid-verification-code') {
          _reactNative.Alert.alert('Ooops... ', 'Verification code may be incorrect. Please check the received code or try to request another one.');
        }
      });
    }
  }, [loading, confirmResult, handlePhoneNumberLogin, idToken]);
  return _react.default.createElement(_reactNativeSafeAreaView.default, {
    testID: "safeArea",
    style: _phoneLoginStyles.default.container,
    forceInset: {
      top: 'never',
      bottom: 'always'
    }
  }, _react.default.createElement(_reactNative.StatusBar, {
    barStyle: "light-content",
    hidden: false,
    backgroundColor: "transparent",
    translucent: true
  }), confirmResult ? _react.default.createElement(_phoneValidationView.default, {
    testID: "PhoneValidationView",
    loading: loading,
    onBackToPhoneView: handleBackToPhoneView,
    onPhoneAuth: handlePhoneAuth,
    onVerification: handleVerification
  }) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_phoneNumberView.default, {
    testID: "PhoneNumberView",
    onGoBack: onGoBack,
    loading: loading,
    onPhoneAuth: handlePhoneAuth
  }), _reactNative.Platform.OS === 'web' ? _react.default.createElement(_reactNative.View, {
    style: _phoneLoginStyles.default.recaptchaContainer,
    nativeID: "recaptcha-container"
  }) : null));
}