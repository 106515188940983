var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.muteUnmuteSpeaker = exports.stopScreenShare = exports.startScreenShare = exports.kickSpeakerFromLive = exports.declineLiveRequest = exports.allowLiveRequest = exports.stopLive = exports.startLive = exports.leaveLive = exports.cancelLiveRequest = exports.joinLiveRequest = exports.participantsChanged = exports.roomChanged = exports.unsubscribeRoomChanges = exports.unsubscribeParticipantsChanges = exports.subscribeRoomChanges = exports.subscribeParticipantsChanges = exports.disconnected = exports.unwatchRoom = exports.watchRoom = exports.leaveRoom = exports.joinRoom = exports.disconnect = exports.connect = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var connect = (0, _toolkit.createAsyncThunk)(_constants.CONNECT, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_, _ref) {
    var networkingService, getState, token, eventId;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            networkingService = _ref.extra.networkingService, getState = _ref.getState;
            token = (0, _selectors2.getFirebaseAccessToken)(getState());
            eventId = (0, _selectors.selectEventInfoId)(getState());
            _context.next = 5;
            return networkingService.connect({
              token: token,
              eventId: eventId
            });

          case 5:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.connect = connect;
var disconnect = (0, _toolkit.createAsyncThunk)(_constants.DISCONNECT, function () {
  var _ref4 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2(_, _ref3) {
    var networkingService;
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            networkingService = _ref3.extra.networkingService;
            _context2.next = 3;
            return networkingService.disconnect();

          case 3:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function (_x3, _x4) {
    return _ref4.apply(this, arguments);
  };
}());
exports.disconnect = disconnect;
var joinRoom = (0, _toolkit.createAsyncThunk)(_constants.JOIN, function () {
  var _ref6 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3(data, _ref5) {
    var networkingService, dispatch, result;
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            networkingService = _ref5.extra.networkingService, dispatch = _ref5.dispatch;
            dispatch(subscribeParticipantsChanges(data.roomId));
            dispatch(subscribeRoomChanges(data.roomId));
            _context3.next = 5;
            return networkingService.joinRoom(data);

          case 5:
            result = _context3.sent;
            return _context3.abrupt("return", result);

          case 7:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));

  return function (_x5, _x6) {
    return _ref6.apply(this, arguments);
  };
}());
exports.joinRoom = joinRoom;
var leaveRoom = (0, _toolkit.createAsyncThunk)(_constants.LEAVE, function () {
  var _ref8 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4(roomId, _ref7) {
    var networkingService, dispatch;
    return _regenerator.default.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            networkingService = _ref7.extra.networkingService, dispatch = _ref7.dispatch;
            _context4.next = 3;
            return networkingService.leaveRoom(roomId);

          case 3:
            dispatch(unsubscribeParticipantsChanges(roomId));
            dispatch(unsubscribeRoomChanges(roomId));

          case 5:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));

  return function (_x7, _x8) {
    return _ref8.apply(this, arguments);
  };
}());
exports.leaveRoom = leaveRoom;
var watchRoom = (0, _toolkit.createAsyncThunk)(_constants.WATCH, function () {
  var _ref10 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee5(data, _ref9) {
    var networkingService, dispatch, result;
    return _regenerator.default.wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            networkingService = _ref9.extra.networkingService, dispatch = _ref9.dispatch;
            dispatch(subscribeParticipantsChanges(data.roomId));
            dispatch(subscribeRoomChanges(data.roomId));
            _context5.next = 5;
            return networkingService.watch(data);

          case 5:
            result = _context5.sent;
            return _context5.abrupt("return", result);

          case 7:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));

  return function (_x9, _x10) {
    return _ref10.apply(this, arguments);
  };
}());
exports.watchRoom = watchRoom;
var unwatchRoom = (0, _toolkit.createAsyncThunk)(_constants.UNWATCH, function () {
  var _ref12 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee6(roomId, _ref11) {
    var networkingService, dispatch;
    return _regenerator.default.wrap(function _callee6$(_context6) {
      while (1) {
        switch (_context6.prev = _context6.next) {
          case 0:
            networkingService = _ref11.extra.networkingService, dispatch = _ref11.dispatch;
            _context6.next = 3;
            return networkingService.unwatch(roomId);

          case 3:
            console.log('once unsub');
            dispatch(unsubscribeParticipantsChanges(roomId));
            dispatch(unsubscribeRoomChanges(roomId));

          case 6:
          case "end":
            return _context6.stop();
        }
      }
    }, _callee6);
  }));

  return function (_x11, _x12) {
    return _ref12.apply(this, arguments);
  };
}());
exports.unwatchRoom = unwatchRoom;
var disconnected = (0, _toolkit.createAction)(_constants.DISCONNECTED);
exports.disconnected = disconnected;
var subscribeParticipantsChanges = (0, _toolkit.createAction)(_constants.SUBSCRIBE_PARTICIPANTS_CHANGES);
exports.subscribeParticipantsChanges = subscribeParticipantsChanges;
var subscribeRoomChanges = (0, _toolkit.createAction)(_constants.SUBSCRIBE_ROOM_CHANGES);
exports.subscribeRoomChanges = subscribeRoomChanges;
var unsubscribeParticipantsChanges = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_PARTICIPANTS_CHANGES);
exports.unsubscribeParticipantsChanges = unsubscribeParticipantsChanges;
var unsubscribeRoomChanges = (0, _toolkit.createAction)(_constants.UNSUBSCRIBE_ROOM_CHANGES);
exports.unsubscribeRoomChanges = unsubscribeRoomChanges;
var roomChanged = (0, _toolkit.createAction)(_constants.ROOM_CHANGED);
exports.roomChanged = roomChanged;
var participantsChanged = (0, _toolkit.createAction)(_constants.PARTICIPANTS_CHANGED);
exports.participantsChanged = participantsChanged;
var joinLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.JOIN_LIVE_REQUEST, function () {
  var _ref14 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee7(roomId, _ref13) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee7$(_context7) {
      while (1) {
        switch (_context7.prev = _context7.next) {
          case 0:
            networkingService = _ref13.extra.networkingService;
            _context7.next = 3;
            return networkingService.joinLiveRequest(roomId);

          case 3:
            result = _context7.sent;
            return _context7.abrupt("return", result);

          case 5:
          case "end":
            return _context7.stop();
        }
      }
    }, _callee7);
  }));

  return function (_x13, _x14) {
    return _ref14.apply(this, arguments);
  };
}());
exports.joinLiveRequest = joinLiveRequest;
var cancelLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.CANCEL_LIVE_REQUEST, function () {
  var _ref16 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee8(roomId, _ref15) {
    var networkingService;
    return _regenerator.default.wrap(function _callee8$(_context8) {
      while (1) {
        switch (_context8.prev = _context8.next) {
          case 0:
            networkingService = _ref15.extra.networkingService;
            _context8.next = 3;
            return networkingService.cancelLiveRequest(roomId);

          case 3:
          case "end":
            return _context8.stop();
        }
      }
    }, _callee8);
  }));

  return function (_x15, _x16) {
    return _ref16.apply(this, arguments);
  };
}());
exports.cancelLiveRequest = cancelLiveRequest;
var leaveLive = (0, _toolkit.createAsyncThunk)(_constants.LEAVE_LIVE, function () {
  var _ref18 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee9(roomId, _ref17) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee9$(_context9) {
      while (1) {
        switch (_context9.prev = _context9.next) {
          case 0:
            networkingService = _ref17.extra.networkingService;
            _context9.next = 3;
            return networkingService.leaveLive(roomId);

          case 3:
            result = _context9.sent;
            return _context9.abrupt("return", result);

          case 5:
          case "end":
            return _context9.stop();
        }
      }
    }, _callee9);
  }));

  return function (_x17, _x18) {
    return _ref18.apply(this, arguments);
  };
}());
exports.leaveLive = leaveLive;
var startLive = (0, _toolkit.createAsyncThunk)(_constants.START_LIVE, function () {
  var _ref20 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee10(roomId, _ref19) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee10$(_context10) {
      while (1) {
        switch (_context10.prev = _context10.next) {
          case 0:
            networkingService = _ref19.extra.networkingService;
            _context10.next = 3;
            return networkingService.startLive(roomId);

          case 3:
            result = _context10.sent;
            return _context10.abrupt("return", result);

          case 5:
          case "end":
            return _context10.stop();
        }
      }
    }, _callee10);
  }));

  return function (_x19, _x20) {
    return _ref20.apply(this, arguments);
  };
}());
exports.startLive = startLive;
var stopLive = (0, _toolkit.createAsyncThunk)(_constants.STOP_LIVE, function () {
  var _ref22 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee11(roomId, _ref21) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee11$(_context11) {
      while (1) {
        switch (_context11.prev = _context11.next) {
          case 0:
            networkingService = _ref21.extra.networkingService;
            _context11.next = 3;
            return networkingService.stopLive(roomId);

          case 3:
            result = _context11.sent;
            return _context11.abrupt("return", result);

          case 5:
          case "end":
            return _context11.stop();
        }
      }
    }, _callee11);
  }));

  return function (_x21, _x22) {
    return _ref22.apply(this, arguments);
  };
}());
exports.stopLive = stopLive;
var allowLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.ALLOW_LIVE_REQUEST, function () {
  var _ref25 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee12(_ref23, _ref24) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee12$(_context12) {
      while (1) {
        switch (_context12.prev = _context12.next) {
          case 0:
            roomId = _ref23.roomId, speakerId = _ref23.speakerId;
            networkingService = _ref24.extra.networkingService;
            _context12.next = 4;
            return networkingService.allowLiveRequest(roomId, speakerId);

          case 4:
            result = _context12.sent;
            return _context12.abrupt("return", result);

          case 6:
          case "end":
            return _context12.stop();
        }
      }
    }, _callee12);
  }));

  return function (_x23, _x24) {
    return _ref25.apply(this, arguments);
  };
}());
exports.allowLiveRequest = allowLiveRequest;
var declineLiveRequest = (0, _toolkit.createAsyncThunk)(_constants.DECLINE_LIVE_REQUEST, function () {
  var _ref28 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee13(_ref26, _ref27) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee13$(_context13) {
      while (1) {
        switch (_context13.prev = _context13.next) {
          case 0:
            roomId = _ref26.roomId, speakerId = _ref26.speakerId;
            networkingService = _ref27.extra.networkingService;
            _context13.next = 4;
            return networkingService.declineLiveRequest(roomId, speakerId);

          case 4:
            result = _context13.sent;
            return _context13.abrupt("return", result);

          case 6:
          case "end":
            return _context13.stop();
        }
      }
    }, _callee13);
  }));

  return function (_x25, _x26) {
    return _ref28.apply(this, arguments);
  };
}());
exports.declineLiveRequest = declineLiveRequest;
var kickSpeakerFromLive = (0, _toolkit.createAsyncThunk)(_constants.KICK_SPEAKER_FROM_LIVE, function () {
  var _ref31 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee14(_ref29, _ref30) {
    var roomId, speakerId, networkingService, result;
    return _regenerator.default.wrap(function _callee14$(_context14) {
      while (1) {
        switch (_context14.prev = _context14.next) {
          case 0:
            roomId = _ref29.roomId, speakerId = _ref29.speakerId;
            networkingService = _ref30.extra.networkingService;
            _context14.next = 4;
            return networkingService.kickSpeakerFromLive(roomId, speakerId);

          case 4:
            result = _context14.sent;
            return _context14.abrupt("return", result);

          case 6:
          case "end":
            return _context14.stop();
        }
      }
    }, _callee14);
  }));

  return function (_x27, _x28) {
    return _ref31.apply(this, arguments);
  };
}());
exports.kickSpeakerFromLive = kickSpeakerFromLive;
var startScreenShare = (0, _toolkit.createAsyncThunk)(_constants.START_SCREEN_SHARE, function () {
  var _ref33 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee15(roomId, _ref32) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee15$(_context15) {
      while (1) {
        switch (_context15.prev = _context15.next) {
          case 0:
            networkingService = _ref32.extra.networkingService;
            _context15.next = 3;
            return networkingService.startScreenShare(roomId);

          case 3:
            result = _context15.sent;
            return _context15.abrupt("return", result);

          case 5:
          case "end":
            return _context15.stop();
        }
      }
    }, _callee15);
  }));

  return function (_x29, _x30) {
    return _ref33.apply(this, arguments);
  };
}());
exports.startScreenShare = startScreenShare;
var stopScreenShare = (0, _toolkit.createAsyncThunk)(_constants.STOP_SCREEN_SHARE, function () {
  var _ref35 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee16(roomId, _ref34) {
    var networkingService, result;
    return _regenerator.default.wrap(function _callee16$(_context16) {
      while (1) {
        switch (_context16.prev = _context16.next) {
          case 0:
            networkingService = _ref34.extra.networkingService;
            _context16.next = 3;
            return networkingService.stopScreenShare(roomId);

          case 3:
            result = _context16.sent;
            return _context16.abrupt("return", result);

          case 5:
          case "end":
            return _context16.stop();
        }
      }
    }, _callee16);
  }));

  return function (_x31, _x32) {
    return _ref35.apply(this, arguments);
  };
}());
exports.stopScreenShare = stopScreenShare;
var muteUnmuteSpeaker = (0, _toolkit.createAsyncThunk)(_constants.MUTE_UNMUTE_SPEAKER, function () {
  var _ref38 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee17(_ref36, _ref37) {
    var roomId, speakerId, _ref36$requestingUser, requestingUserId, networkingService, result;

    return _regenerator.default.wrap(function _callee17$(_context17) {
      while (1) {
        switch (_context17.prev = _context17.next) {
          case 0:
            roomId = _ref36.roomId, speakerId = _ref36.speakerId, _ref36$requestingUser = _ref36.requestingUserId, requestingUserId = _ref36$requestingUser === void 0 ? null : _ref36$requestingUser;
            networkingService = _ref37.extra.networkingService;
            _context17.next = 4;
            return networkingService.muteUnmuteSpeaker(roomId, {
              speakerId: speakerId,
              requestingUserId: requestingUserId
            });

          case 4:
            result = _context17.sent;
            return _context17.abrupt("return", result);

          case 6:
          case "end":
            return _context17.stop();
        }
      }
    }, _callee17);
  }));

  return function (_x33, _x34) {
    return _ref38.apply(this, arguments);
  };
}());
exports.muteUnmuteSpeaker = muteUnmuteSpeaker;