var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _reactNativeAndroidOpenSettings = _interopRequireDefault(require("react-native-android-open-settings"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var AllowPermissionsInstructions = function (_React$PureComponent) {
  (0, _inherits2.default)(AllowPermissionsInstructions, _React$PureComponent);

  var _super = _createSuper(AllowPermissionsInstructions);

  function AllowPermissionsInstructions() {
    var _this;

    (0, _classCallCheck2.default)(this, AllowPermissionsInstructions);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePress = function () {
      if (_reactNative.Platform.OS === 'ios') {
        _reactNative.Linking.canOpenURL('app-settings:').then(function (supported) {
          if (supported) {
            return _reactNative.Linking.openURL('app-settings:');
          }
        }).catch();
      } else {
        _reactNativeAndroidOpenSettings.default.appDetailsSettings();
      }
    };

    return _this;
  }

  (0, _createClass2.default)(AllowPermissionsInstructions, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.surface
      }, _react.default.createElement(_reactNative.View, {
        style: {
          marginBottom: 24
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "bell",
        size: 48,
        color: "#242425"
      })), _react.default.createElement(_reactNativePaper.Subheading, {
        style: styles.title
      }, "Enable Notifications"), _react.default.createElement(_reactNativePaper.Paragraph, {
        style: styles.text
      }, "Soundmix needs your permision to send you notifications when the DJ is broadcasting live."), _react.default.createElement(_reactNative.TouchableOpacity, {
        onPress: function onPress() {
          return _this2.props.navigation.goBack();
        },
        style: styles.closeButton
      }, _react.default.createElement(_CustomIcon.default, {
        name: "close",
        size: 16,
        color: "rgba(0,0,0,0.1)"
      })), _react.default.createElement(_reactNativePaper.Button, {
        mode: "outlined",
        onPress: this.handlePress,
        style: styles.actionButton,
        color: "black"
      }, "Allow Permisions")));
    }
  }]);
  return AllowPermissionsInstructions;
}(_react.default.PureComponent);

exports.default = AllowPermissionsInstructions;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  surface: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    width: '80%',
    maxWidth: 560,
    borderRadius: 3,
    elevation: 2
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'black'
  },
  text: {
    color: 'black',
    marginBottom: 20,
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    top: 0,
    width: 48,
    height: 48
  },
  actionButton: {
    width: '100%',
    maxWidth: 250,
    paddingVertical: 5,
    borderColor: 'black'
  }
});