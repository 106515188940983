var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _iconButtonView.default;
  }
});

var _iconButtonView = _interopRequireDefault(require("./icon-button-view.js"));