Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useEmitEvent;

var _react = require("react");

function useEmitEvent(observer) {
  var emit = (0, _react.useCallback)(function (event) {
    observer.publish(event);
  }, [observer]);
  return emit;
}