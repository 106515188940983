Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isStaffMember = exports.selectTeamsLoaded = exports.selectTeamsLoading = exports.selectTeamsList = exports.selectTeamsState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectTeamsState = function selectTeamsState(state) {
  return state[_constants.NAME];
};

exports.selectTeamsState = selectTeamsState;

var selectTeamsList = _reducer.teamEntityAdapter.getSelectors(selectTeamsState).selectAll;

exports.selectTeamsList = selectTeamsList;
var selectTeamsLoading = (0, _toolkit.createSelector)(selectTeamsState, function (state) {
  return state.loading;
});
exports.selectTeamsLoading = selectTeamsLoading;
var selectTeamsLoaded = (0, _toolkit.createSelector)(selectTeamsState, function (state) {
  return state.loaded;
});
exports.selectTeamsLoaded = selectTeamsLoaded;
var isStaffMember = (0, _toolkit.createSelector)(_selectors.selectEventInfo, selectTeamsList, _selectors2.getFirebaseUser, function (eventInfo, teamMembers, user) {
  if (!user) {
    return false;
  }

  var isEventOwner = eventInfo && eventInfo.createdBy === user.uid;
  var teamMemberIds = teamMembers.map(function (member) {
    return member.userId;
  });
  return isEventOwner || teamMemberIds.includes(user.uid);
});
exports.isStaffMember = isStaffMember;