var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../../../theme/DesignSystem");

var BACKGROUND_GIF = 'https://firebasestorage.googleapis.com/v0/b/eventmix-prod.appspot.com/o/general%2Floop.gif?alt=media&token=fd62c75c-a872-4bc5-9636-e87d540b20c0';

var DefaultGifBackground = function DefaultGifBackground(_ref) {
  var children = _ref.children;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape;

  return _react.default.createElement(_reactNative.ImageBackground, {
    source: BACKGROUND_GIF,
    style: [{
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
      borderRadius: shape.cards.borderRadius
    }]
  }, children);
};

var _default = DefaultGifBackground;
exports.default = _default;