var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _avatars = _interopRequireDefault(require("../../../common/avatars"));

var _SmartCountDown = _interopRequireDefault(require("../../../common/SmartCountDown"));

var _nextSessionStyles = _interopRequireDefault(require("./next-session-styles"));

var _sessionUtils = require("../../../../utils/session-utils");

var _DesignSystem = require("../../../../theme/DesignSystem");

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var NextSessionCountDown = function NextSessionCountDown(_ref) {
  var session = _ref.session,
      textColor = _ref.textColor,
      _ref$role = _ref.role,
      role = _ref$role === void 0 ? 'live' : _ref$role;
  var styles = (0, _nextSessionStyles.default)();

  var _getSessionDetailsFor = (0, _sessionUtils.getSessionDetailsForCalendar)(session),
      speakerUrlsAndIds = _getSessionDetailsFor.speakerUrlsAndIds;

  var navigation = (0, _useNavigationExtended.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_DynamicSection.default, {
    shaped: true,
    shaping: "cards",
    parentBackground: (0, _colorUtils.default)(textColor, '#FFFFFF', '#000000'),
    lightColor: "#FFFFFF",
    darkColor: "#000000",
    style: [styles.countDownInnerContainer, {
      padding: role === 'thumbnail' ? spacing.l : spacing.l
    }]
  }, function (innerTextColor) {
    var _session$speakers;

    return _react.default.createElement(_react.default.Fragment, null, session !== null && session !== void 0 && (_session$speakers = session.speakers) !== null && _session$speakers !== void 0 && _session$speakers.length ? _react.default.createElement(_avatars.default, {
      urls: speakerUrlsAndIds,
      onPress: function onPress(id) {
        navigation.navigate(_routesConsts.Routes.SPEAKER_PAGE, {
          speakerId: id
        });
      },
      style: styles.avatars
    }) : null, _react.default.createElement(_Typography.default, {
      textAlign: "center",
      color: innerTextColor,
      style: {
        fontWeight: 'bold'
      }
    }, session.title), _react.default.createElement(_Spacing.default, {
      mSpacing: "s"
    }, _react.default.createElement(_Typography.default, {
      variant: "description",
      color: innerTextColor
    }, _i18n.default.t('liveNow.sessionStarts'))), _react.default.createElement(_SmartCountDown.default, {
      untilDate: new Date(session.start),
      color: innerTextColor,
      size: role === 'thumbnail' ? 20 : 25,
      style: styles.countdownTimer,
      timeElapsedMessage: "in a few moments",
      digitsOnly: true,
      onFinish: function onFinish() {}
    }));
  });
};

var _default = NextSessionCountDown;
exports.default = _default;