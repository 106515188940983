Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectLettersLength = exports.selectLetterById = exports.selectLettersIsLoaded = exports.selectLettersIsLoading = exports.selectLetters = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectLettersState = function selectLettersState(state) {
  return state[_constants.NAME];
};

var selectLetters = _reducer.lettersEntityAdapter.getSelectors(selectLettersState).selectAll;

exports.selectLetters = selectLetters;
var selectLettersIsLoading = (0, _toolkit.createSelector)(selectLettersState, function (state) {
  return state.loading;
});
exports.selectLettersIsLoading = selectLettersIsLoading;
var selectLettersIsLoaded = (0, _toolkit.createSelector)(selectLettersState, function (state) {
  return state.loaded;
});
exports.selectLettersIsLoaded = selectLettersIsLoaded;

var selectLetterById = _reducer.lettersEntityAdapter.getSelectors(selectLettersState).selectById;

exports.selectLetterById = selectLetterById;
var selectLettersLength = (0, _toolkit.createSelector)(selectLetters, function (state) {
  return state.length;
});
exports.selectLettersLength = selectLettersLength;