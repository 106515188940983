Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chatReactionByIdSelector = void 0;

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectChatReactionsStateSelector = function selectChatReactionsStateSelector(state) {
  return state[_constants.NAME];
};

var chatReactionSelector = _reducer.chatReactionsEntityAdapter.getSelectors(selectChatReactionsStateSelector);

var chatReactionByIdSelector = chatReactionSelector.selectById;
exports.chatReactionByIdSelector = chatReactionByIdSelector;