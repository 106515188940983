var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BadgeView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _badgeStyles = _interopRequireDefault(require("./badge-styles"));

var _CustomIcon = _interopRequireDefault(require("../../../../src/assets/icons/CustomIcon.js"));

var _Caption = _interopRequireDefault(require("../../../../src/components/common/typography_v1/Caption"));

function BadgeView(props) {
  return _react.default.createElement(_reactNative.View, {
    style: _badgeStyles.default.container
  }, props.iconName && _react.default.createElement(_CustomIcon.default, {
    name: props.iconName,
    size: 12,
    color: "#ffffff",
    style: _badgeStyles.default.icon
  }), _react.default.createElement(_Caption.default, {
    style: _badgeStyles.default.label
  }, props.label));
}