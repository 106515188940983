var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formik = require("formik");

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var yup = _interopRequireWildcard(require("yup"));

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var DisplayNameModalContent = function DisplayNameModalContent(_ref) {
  var onDisplayNameUpdate = _ref.onDisplayNameUpdate;
  return _react.default.createElement(_core.Box, {
    mb: 1,
    mx: 1
  }, _react.default.createElement(_core.Box, {
    mb: 1,
    align: "left"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, _i18n.default.translate('register.displayNameUpdate'))), _react.default.createElement(_core.Box, {
    mb: 1,
    align: "left"
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.translate('register.displayNameUpdateInfo'))), _react.default.createElement(_formik.Formik, {
    initialValues: {
      displayName: ''
    },
    onSubmit: function onSubmit(values) {
      return onDisplayNameUpdate(values);
    },
    validationSchema: yup.object().shape({
      displayName: yup.string().trim().required(_i18n.default.t('register.displayNameRequired'))
    })
  }, function (_ref2) {
    var values = _ref2.values,
        handleChange = _ref2.handleChange,
        errors = _ref2.errors,
        setFieldTouched = _ref2.setFieldTouched,
        touched = _ref2.touched,
        isValid = _ref2.isValid,
        submitForm = _ref2.submitForm;
    return _react.default.createElement(_react.Fragment, null, _react.default.createElement(_core.Box, {
      align: "left",
      display: "flex",
      flexDirection: "column"
    }, _react.default.createElement(_Input.default, {
      shaped: true,
      type: "text",
      color: "alternativeText",
      value: values.displayName,
      onChange: handleChange,
      onKeyPress: function onKeyPress(_ref3) {
        var key = _ref3.nativeEvent.key;
        return key === 'Enter' && submitForm();
      },
      name: "displayName",
      returnKeyType: 'next',
      error: !!errors.displayName && touched.displayName,
      onBlur: function onBlur() {
        return setFieldTouched('displayName');
      },
      placeholder: 'John Doe',
      leftIcon: _react.default.createElement(_reactFeather.User, null)
    }), !!errors.displayName && touched.displayName && _react.default.createElement(_core.Box, {
      mt: 1
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.displayName)), _react.default.createElement(_core.Box, {
      mt: 1,
      alignSelf: "flex-start"
    }, _react.default.createElement(_ContainedButton.default, {
      onPress: function onPress() {
        if (isValid) {
          submitForm();
        }
      }
    }, _i18n.default.t('register.saveDisplayName')))));
  }));
};

var _default = DisplayNameModalContent;
exports.default = _default;