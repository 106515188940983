var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProfileScreenInternal = exports.iosTopMargin = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactNavigation = require("react-navigation");

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var _profile = _interopRequireDefault(require("../../core/profile"));

var _user = _interopRequireDefault(require("../../core/user"));

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _Button = _interopRequireDefault(require("../common/Button"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _Headline = _interopRequireDefault(require("../common/typography_v1/Headline"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _ShareButton = _interopRequireDefault(require("../common/ShareButton"));

var _DjProfileScreen = _interopRequireDefault(require("./DjProfileScreen"));

var _SocialMedia = _interopRequireDefault(require("../common/SocialMedia"));

var _selectors = require("../../core/user-profile/selectors");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var iosTopMargin = _reactNative.Platform.OS === 'ios' ? _reactNativeDeviceInfo.default.hasNotch() ? 32 : 16 : 32;
exports.iosTopMargin = iosTopMargin;

var ProfileScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ProfileScreenInternal, _React$PureComponent);

  var _super = _createSuper(ProfileScreenInternal);

  function ProfileScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, ProfileScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handleEditProfilePress = function () {
      _this.props.navigation.navigate('EditProfile', {
        userId: _this.props.userId,
        userName: _this.props.userName,
        bio: _this.props.bio,
        twitterUrl: _this.props.twitterUrl,
        facebookUrl: _this.props.facebookUrl,
        soundcloudUrl: _this.props.soundcloudUrl,
        mixcloudUrl: _this.props.mixcloudUrl,
        instagramUrl: _this.props.instagramUrl
      });
    };

    return _this;
  }

  (0, _createClass2.default)(ProfileScreenInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var colors = this.props.theme.colors;

      if (this.props.userName === undefined) {
        return _react.default.createElement(_reactNative.View, {
          style: styles.loading,
          testID: "ProfileScreen"
        }, _react.default.createElement(_reactNative.ActivityIndicator, {
          size: "large",
          color: "#fff"
        }));
      }

      if (this.props.isDj) {
        return _react.default.createElement(_DjProfileScreen.default, {
          djId: this.props.userId,
          isMyProfile: this.props.isMyProfile
        });
      }

      var nameInitials = this.props.userName.split(' ', 2).map(function (i) {
        return i.charAt(0);
      });
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "#000",
        translucent: false
      }), _react.default.createElement(_reactNative.ScrollView, {
        style: {
          backgroundColor: colors.background
        },
        contentContainerStyle: this.props.isMyProfile ? {
          paddingTop: 0
        } : {
          paddingTop: iosTopMargin + 16 + 16
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_AvatarImage.default, {
        size: 96,
        source: {
          uri: this.props.avatar
        },
        defaultLabel: nameInitials,
        style: styles.userAvatar
      }), _react.default.createElement(_Headline.default, {
        style: styles.userName
      }, this.props.userName), _react.default.createElement(_reactNative.View, {
        style: styles.statsContainer
      }, _react.default.createElement(_reactNative.View, {
        style: styles.statsItem
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "followersTouch",
        borderless: true,
        style: {
          justifyContent: 'center',
          alignItems: 'center'
        },
        onPress: function onPress() {
          return _this2.props.navigation.navigate('Followers', {
            userId: _this2.props.userId,
            title: 'Followers'
          });
        }
      }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Headline.default, null, this.props.numFollowers), _react.default.createElement(_Paragraph.default, {
        style: styles.statsItemDesc
      }, "Followers")))), _react.default.createElement(_reactNative.View, {
        style: styles.statsItem
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "followingTouch",
        borderless: true,
        style: {
          justifyContent: 'center',
          alignItems: 'center'
        },
        onPress: function onPress() {
          return _this2.props.navigation.navigate('Following', {
            userId: _this2.props.userId,
            title: 'Following'
          });
        }
      }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_Headline.default, null, this.props.numFollowing), _react.default.createElement(_Paragraph.default, {
        style: styles.statsItemDesc
      }, "Following"))))), _react.default.createElement(_reactNative.View, {
        style: styles.actionButton
      }, this.props.isMyProfile === false && this.props.userId !== this.props.loggedInUserId && _react.default.createElement(_FollowUserButton.default, {
        id: this.props.userId
      }), this.props.isMyProfile === true && _react.default.createElement(_Button.default, {
        name: "editButton",
        mode: "outlined",
        onPress: this.handleEditProfilePress
      }, "Edit Profile"), _react.default.createElement(_ShareButton.default, {
        style: styles.shareBtnStyle,
        contentStyle: styles.shareBtnContentStyle,
        borderless: false,
        deepLinkPath: "profile/".concat(this.props.userId),
        ogTitle: this.props.userName,
        ogImageUrl: this.props.avatar,
        ogDescription: this.props.bio,
        message: this.props.userId === this.props.loggedInUserId ? 'Checkout my profile on Soundmix [APP_INVITE_URL]' : "Checkout ".concat(this.props.userName, " profile on Soundmix [APP_INVITE_URL]"),
        windowTitle: "Share with Friends!"
      })), this.props.bio != null && this.props.bio !== '' && _react.default.createElement(_Paragraph.default, {
        style: styles.bioText
      }, this.props.bio), _react.default.createElement(_SocialMedia.default, {
        userId: this.props.userId
      }))));
    }
  }]);
  return ProfileScreenInternal;
}(_react.default.PureComponent);

exports.ProfileScreenInternal = ProfileScreenInternal;
ProfileScreenInternal.defaultProps = {
  isMyProfile: false
};

ProfileScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: (0, _NavigationButtons.moreButton)(navigation),
    headerTransparent: true,
    headerStyle: {
      backgroundColor: navigation.getParam('BackgroundColor', state.params.bgColor)
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 16
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  userAvatar: {
    marginBottom: 16
  },
  userName: {
    marginBottom: 16,
    textAlign: 'center'
  },
  statsContainer: {
    flexDirection: 'row',
    marginBottom: 24
  },
  statsItem: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 16
  },
  statsItemDesc: {
    color: 'rgba(255,255,255, 0.5)'
  },
  actionButton: {
    marginBottom: 24,
    flexDirection: 'row'
  },
  shareBtnStyle: {
    marginLeft: 8
  },
  shareBtnContentStyle: {
    borderRadius: 4,
    borderColor: '#fff',
    borderWidth: 1
  },
  bioText: {
    textAlign: 'center',
    marginBottom: 24,
    maxWidth: 600
  }
});

function mapStateToProps(state, ownProps) {
  var userId = ownProps.userId;
  return {
    key: userId,
    userName: (0, _selectors.selectUserDisplayName)(state),
    bio: _profile.default.selectors.getBio(state, userId),
    avatar: _profile.default.selectors.getUserPicture(state, userId),
    numFollowers: _profile.default.selectors.getNumFollowers(state, userId),
    numFollowing: _profile.default.selectors.getNumFollowing(state, userId),
    twitterUrl: _profile.default.selectors.getTwitterUrl(state, userId),
    facebookUrl: _profile.default.selectors.getFacebookUrl(state, userId),
    soundcloudUrl: _profile.default.selectors.getSoundcloudUrl(state, userId),
    mixcloudUrl: _profile.default.selectors.getMixCloudUrl(state, userId),
    instagramUrl: _profile.default.selectors.getInstagramUrl(state, userId),
    loggedInUserId: _user.default.selectors.getFirebaseUserUid(state),
    isDj: _profile.default.selectors.isDj(state, userId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadProfile: _profile.default.actions.loadProfile,
  loadFollowingsNumber: _profile.default.actions.loadFollowingsNumber,
  loadFollowersNumber: _profile.default.actions.loadFollowersNumber,
  loadGsPlayer: _gameSparks.default.actions.loadPlayerData
})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(ProfileScreenInternal)));

exports.default = _default;