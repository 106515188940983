var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = QuickAccessButton;

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _quickAccessButtonStyles = _interopRequireDefault(require("./quick-access-button-styles"));

var _require = require('../../../constants/video-type-constants'),
    NETWORKING = _require.NETWORKING,
    LECTURE = _require.LECTURE,
    BREAK = _require.BREAK;

function QuickAccessButton(_ref) {
  var alignment = _ref.alignment,
      session = _ref.session,
      liveNavigation = _ref.liveNavigation,
      networkingNavigation = _ref.networkingNavigation;
  var Styles = (0, _quickAccessButtonStyles.default)();
  var networkingPageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);

  switch (session.videoType) {
    case LECTURE:
      return _react.default.createElement(_Spacing.default, {
        style: Styles.button,
        self: alignment
      }, _react.default.createElement(_ContainedButton.default, {
        onPress: liveNavigation
      }, _i18n.default.t('lobby.joinLive')));

    case NETWORKING:
      if (!session.networkTable || networkingPageId) {
        return null;
      }

      return _react.default.createElement(_Spacing.default, {
        style: Styles.button,
        self: alignment
      }, _react.default.createElement(_ContainedButton.default, {
        onPress: networkingNavigation
      }, _i18n.default.t('networking.joinTheNetworkingArea')));

    case BREAK:
    default:
      return _react.default.createElement(_Spacing.default, {
        style: Styles.button,
        self: alignment
      }, _react.default.createElement(_ContainedButton.default, {
        onPress: liveNavigation
      }, _i18n.default.t('lobby.joinLive')));
  }
}