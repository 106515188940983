var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RightSideMenuContainer;
exports.MenuBuilder = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _OutlinedButton = _interopRequireDefault(require("../../../theme/components/buttons/OutlinedButton"));

var _Divider = _interopRequireDefault(require("../../../theme/components/divider/Divider"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _rightSideMenuStyles = _interopRequireDefault(require("./right-side-menu-styles"));

var _DesignSystem = require("../../../theme/DesignSystem");

function RightSideMenuContainer(_ref) {
  var items = _ref.items,
      contents = _ref.contents,
      _ref$backstage = _ref.backstage,
      backstage = _ref$backstage === void 0 ? false : _ref$backstage;

  var _React$useState = _react.default.useState(0),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      collapsed = _React$useState4[0],
      setCollapsed = _React$useState4[1];

  var Styles = (0, _rightSideMenuStyles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });
  return _react.default.createElement(_Spacing.default, {
    color: "containerBackground",
    mSpacing: ['s', 0],
    shaping: "cards",
    style: [Styles.container, match ? {} : {
      width: collapsed ? spacing.rightSidebarMenu : spacing.rightSidebar
    }, match ? {} : {}],
    direction: "row",
    wrap: "nowrap",
    alignItems: "stretch",
    justifyContent: "flex-start"
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.itemContainer,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.collapse,
    mSpacing: "s"
  }, _react.default.createElement(_OutlinedButton.default, {
    color: "alternativeText",
    onPress: function onPress() {
      return setCollapsed(function (old) {
        return !old;
      });
    },
    spacing: 3,
    iconLeft: collapsed ? _react.default.createElement(_reactFeather.ArrowLeft, {
      size: "0.875rem"
    }) : _react.default.createElement(_reactFeather.ArrowRight, {
      size: "0.875rem"
    })
  })), _react.default.createElement(_reactNative.ScrollView, {
    horizontal: match,
    showsVerticalScrollIndicator: match
  }, items.map(function (item, i) {
    return _react.default.createElement(_Spacing.default, {
      key: i,
      style: Styles.menuItem
    }, item({
      isSelected: selected === i,
      onSelect: function onSelect() {
        return selected !== i && setSelected(i);
      }
    }));
  }))), (!collapsed || match) && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Divider.default, {
    spacing: 0,
    width: 1,
    direction: "vertical"
  }), _react.default.createElement(_Spacing.default, {
    style: Styles.content,
    flex: 1,
    direction: "column",
    alignItems: "stretch"
  }, contents[selected] || null)));
}

var MenuBuilder = function () {
  function MenuBuilder() {
    (0, _classCallCheck2.default)(this, MenuBuilder);
    this.menuItems = new Map();
    this.contents = new Map();
  }

  (0, _createClass2.default)(MenuBuilder, [{
    key: "add",
    value: function add(type, key, content) {
      if (type === MenuBuilder.TYPES.CONTENT) {
        this.contents.set(key, content);
      }

      if (type === MenuBuilder.TYPES.MENU_ITEM) {
        this.menuItems.set(key, content);
      }

      return this;
    }
  }, {
    key: "buildClientMenu",
    value: function buildClientMenu(_ref3) {
      var chatDisabled = _ref3.chatDisabled,
          peopleDisabled = _ref3.peopleDisabled;
      this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
      this.contents.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values())
      });
    }
  }, {
    key: "buildAdminMenu",
    value: function buildAdminMenu(_ref4) {
      var chatDisabled = _ref4.chatDisabled,
          peopleDisabled = _ref4.peopleDisabled;

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values())
      });
    }
  }, {
    key: "buildBackstageMenu",
    value: function buildBackstageMenu(_ref5) {
      var chatDisabled = _ref5.chatDisabled,
          requestsDisabled = _ref5.requestsDisabled,
          separatorDisabled = _ref5.separatorDisabled,
          peopleDisabled = _ref5.peopleDisabled;

      if (chatDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
        this.menuItems.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.BLOCKED_PEOPLES);
      }

      if (peopleDisabled) {
        this.menuItems.delete(MenuBuilder.MENUS.PEOPLES);
        this.contents.delete(MenuBuilder.MENUS.PEOPLES);
      }

      if (requestsDisabled) {
        this.menuItems.delete(MenuBuilder.BACKSTAGE_MENUS.REQUESTS);
        this.contents.delete(MenuBuilder.BACKSTAGE_MENUS.REQUESTS);
      }

      if (separatorDisabled) {
        this.menuItems.delete(MenuBuilder.BACKSTAGE_MENUS.SEPARATOR);
        this.contents.delete(MenuBuilder.BACKSTAGE_MENUS.SEPARATOR);
      }

      return _react.default.createElement(RightSideMenuContainer, {
        items: Array.from(this.menuItems.values()),
        contents: Array.from(this.contents.values()),
        backstage: true
      });
    }
  }]);
  return MenuBuilder;
}();

exports.MenuBuilder = MenuBuilder;
MenuBuilder.MENUS = {
  BOOTH: 1,
  CHAT: 2,
  QA: 3,
  PEOPLES: 4,
  POLLS: 5,
  FILES: 6,
  BLOCKED_PEOPLES: 7
};
MenuBuilder.BACKSTAGE_MENUS = {
  REQUESTS: 20,
  INTERNAL_CHAT: 21,
  SEPARATOR: 22
};
MenuBuilder.TYPES = {
  CONTENT: 'CONTENT',
  MENU_ITEM: 'MENU_ITEM'
};