Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.labelsSettingsSelector = exports.peopleSettingsSelector = exports.qaSettingsSelector = exports.chatSettingsSelector = exports.settingsListSelector = exports.eventSettingsStateSelector = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var eventSettingsStateSelector = function eventSettingsStateSelector(state) {
  return state[_constants.NAME];
};

exports.eventSettingsStateSelector = eventSettingsStateSelector;

var settingsListSelector = _constants.settingsEntityAdapter.getSelectors(eventSettingsStateSelector).selectAll;

exports.settingsListSelector = settingsListSelector;
var chatSettingsSelector = (0, _toolkit.createSelector)(settingsListSelector, function (settings) {
  return settings.find(function (setting) {
    return setting.id === _constants.CHAT_ID;
  }) || {};
});
exports.chatSettingsSelector = chatSettingsSelector;
var qaSettingsSelector = (0, _toolkit.createSelector)(settingsListSelector, function (settings) {
  return settings.find(function (setting) {
    return setting.id === _constants.QA_ID;
  }) || {};
});
exports.qaSettingsSelector = qaSettingsSelector;
var peopleSettingsSelector = (0, _toolkit.createSelector)(settingsListSelector, function (settings) {
  return settings.find(function (setting) {
    return setting.id === _constants.PEOPLE_ID;
  }) || {};
});
exports.peopleSettingsSelector = peopleSettingsSelector;
var labelsSettingsSelector = (0, _toolkit.createSelector)(settingsListSelector, function (settings) {
  return settings.find(function (setting) {
    return setting.id === _constants.LABELS_ID;
  }) || {};
});
exports.labelsSettingsSelector = labelsSettingsSelector;