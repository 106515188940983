var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WinnersAnnouncement;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

var _headerStyles = _interopRequireDefault(require("../utils/header-styles"));

var _winnersAnnouncementStyles = _interopRequireDefault(require("./winners-announcement-styles"));

function WinnersAnnouncement() {
  return _react.default.createElement(_reactNative.View, {
    style: _headerStyles.default.announcingWinnersContainer
  }, _react.default.createElement(_Title.default, {
    style: _winnersAnnouncementStyles.default.winnersTitle
  }, "LAST MONTH'S WINNERS"));
}