var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NavLinks;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _core = require("@material-ui/core");

var _LinkButton = _interopRequireDefault(require("../../../../theme/components/buttons/LinkButton"));

var _contactInformationStepStyle = _interopRequireDefault(require("./contact-information-step-style"));

var _eventRegisterProvider = require("../../event-register-provider");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function NavLinks() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      goToLogin = _useEventRegisterCont.goToLogin;

  var classes = (0, _contactInformationStepStyle.default)();
  return _react.default.createElement(_core.Box, {
    className: classes.navLinks
  }, _react.default.createElement(_core.Typography, {
    sx: {
      color: 'alternativeText.main'
    },
    variant: "h4"
  }, _i18n.default.translate('contactInformation.alreadyHaveAccount'), "\xA0"), _react.default.createElement(_LinkButton.default, {
    onPress: goToLogin
  }, function (style, color) {
    return _react.default.createElement(_core.Typography, {
      style: _objectSpread(_objectSpread({}, style), {}, {
        color: color
      }),
      variant: "caption"
    }, _i18n.default.translate('login.login'));
  }));
}