var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/speakers/selectors");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _core = require("@material-ui/core");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _backButton = _interopRequireDefault(require("../../auth/back-button"));

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../common/DefaultHTMLrenderer"));

var _eventLogo = _interopRequireDefault(require("../../common/event-logo"));

var _speaker = _interopRequireDefault(require("../speaker"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _speakerPageStyles = _interopRequireDefault(require("./speaker-page-styles"));

var _styles = _interopRequireDefault(require("./styles"));

var SpeakerPage = function SpeakerPage() {
  var id = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.SPEAKER_ID);
  var speaker = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectSpeakerById)(state, id);
  });
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectSpeakersIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectSpeakersIsLoaded);
  var Styles = (0, _speakerPageStyles.default)();
  var classes = (0, _styles.default)();
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: true
  });

  var open = function open(url) {
    return _reactNative.Platform.OS === 'web' ? window.open(url.includes('http') || url.includes('https') ? url : "https://".concat(url), '_blank') : _reactNative.Linking.openURL(url.includes('http') || url.includes('https') ? url : "https://".concat(url));
  };

  return _react.default.createElement(_core.Box, {
    flexGrow: 1,
    className: classes.pageBg,
    p: 1,
    overflow: "auto"
  }, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: Styles.container
  }, _react.default.createElement(_core.Box, {
    my: 2
  }, _react.default.createElement(_backButton.default, null)), _react.default.createElement(_eventLogo.default, null), _react.default.createElement(_core.Card, {
    className: classes.card,
    elevation: 0
  }, _react.default.createElement(_core.Box, {
    my: 5,
    mx: 8,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }, isLoading && _react.default.createElement(_reactNative.ActivityIndicator, {
    size: "large"
  }), isLoaded && !speaker && _react.default.createElement(_core.Typography, null, _i18n.default.translate('global.invalidSpeakerProfile')), isLoaded && speaker && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, null, _react.default.createElement(_speaker.default, {
    color: 'alternativeText',
    name: speaker.name,
    organization: speaker.organization,
    position: speaker.position,
    photo: speaker.photo,
    isModerator: speaker.isModerator
  })), _react.default.createElement(_core.Typography, {
    align: "center"
  }, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: speaker.longBio
  })), _react.default.createElement(_core.Box, {
    mt: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_core.Box, {
    className: classes.mediaSection
  }, speaker.website && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Globe, {
    onClick: function onClick() {
      return open(speaker.website);
    }
  })), speaker.facebook && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Facebook, {
    onClick: function onClick() {
      return open(speaker.facebook);
    }
  })), speaker.twitter && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Twitter, {
    onClick: function onClick() {
      return open(speaker.twitter);
    }
  })), speaker.instagram && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Instagram, {
    onClick: function onClick() {
      return open(speaker.instagram);
    }
  })), speaker.linkedin && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.Linkedin, {
    onClick: function onClick() {
      return open(speaker.linkedin);
    }
  })), speaker.other && _react.default.createElement(_core.Box, {
    className: classes.social,
    m: 0.5
  }, _react.default.createElement(_reactFeather.AtSign, {
    onClick: function onClick() {
      return open(speaker.other);
    }
  })))))))));
};

var _default = SpeakerPage;
exports.default = _default;