var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Modal;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactFeather = require("react-feather");

var _core = require("@material-ui/core");

var _lodash = _interopRequireDefault(require("lodash"));

var _customModal = _interopRequireDefault(require("./../../../components/common/custom-modal/custom-modal"));

var _styles = _interopRequireDefault(require("./styles"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function Modal(_ref) {
  var children = _ref.children,
      content = _ref.content,
      autoOpen = _ref.autoOpen;
  var classes = (0, _styles.default)();

  var _useState = (0, _react.useState)(autoOpen || false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var onOpen = function onOpen() {
    return setOpen(true);
  };

  var onClose = function onClose() {
    return setOpen(false);
  };

  var handleClose = (0, _react.useCallback)(function () {
    setOpen(false);
  }, []);
  return _react.default.createElement(_react.default.Fragment, null, children({
    onOpen: onOpen,
    onClose: onClose
  }), open && _react.default.createElement(_customModal.default, {
    onClose: handleClose
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    className: classes.modalBox,
    component: _core.Paper,
    elevation: 0
  }, _react.default.createElement(_core.IconButton, {
    onClick: handleClose,
    className: classes.closeIcon
  }, _react.default.createElement(_reactFeather.X, null)), _react.default.createElement(_core.Box, {
    className: classes.modalContent,
    mt: 4,
    mb: 2,
    px: {
      xs: 1,
      md: 4
    }
  }, _lodash.default.isFunction(content) ? content({
    onOpen: onOpen,
    onClose: onClose
  }) : content))));
}