var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../../core/user"));

var _shareButtonContainer = _interopRequireDefault(require("./share-button-container"));

function mapStateToProps(state) {
  return {
    referrerId: _user.default.selectors.getUserId(state),
    referrerName: _user.default.selectors.getUserName(state),
    referrerPicture: _user.default.selectors.getUserPicture(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(_shareButtonContainer.default);

exports.default = _default;