var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EventRegistrationContainer;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _core = require("@material-ui/core");

var _eventRegistrationContainerStyle = _interopRequireDefault(require("./event-registration-container-style"));

var _eventRegistrationNavigation = _interopRequireDefault(require("./event-registration-components/common/event-registration-navigation"));

var _eventRegistrationStepper = _interopRequireDefault(require("./event-registration-components/common/event-registration-stepper"));

var _orderSummary = _interopRequireDefault(require("./event-registration-components/common/order-summary"));

var _eventRegisterProvider = _interopRequireWildcard(require("./event-register-provider"));

var _eventRegistrationFormikWrapper = _interopRequireDefault(require("./event-registration-formik-wrapper"));

var _requestErrorBlock = _interopRequireDefault(require("./event-registration-components/common/request-error-block"));

var _reactRedux = require("react-redux");

var _selectors = require("../../core/event-info/selectors");

var _stripeJs = require("@stripe/stripe-js");

var _reactStripeJs = require("@stripe/react-stripe-js");

var _reactFeather = require("react-feather");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function EventRegistrationContainer(_ref) {
  var initialTicketId = _ref.initialTicketId,
      closeDialog = _ref.closeDialog,
      callBacks = _ref.callBacks;
  var theme = (0, _core.useTheme)();
  var fullScreen = (0, _core.useMediaQuery)(theme.breakpoints.down('xs'));
  var classes = (0, _eventRegistrationContainerStyle.default)();
  var stripePublicKey = process.env.STRIPE_TOKEN;
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var eventSlug = (0, _reactRedux.useSelector)(_selectors.selectEventSlug);

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      stripe = _useState2[0],
      setStripe = _useState2[1];

  (0, _react.useEffect)(function () {
    var initStripe = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.t0 = setStripe;
                _context.next = 3;
                return (0, _stripeJs.loadStripe)(stripePublicKey, {
                  stripeAccount: eventInfo.connectedAccountId
                });

              case 3:
                _context.t1 = _context.sent;
                (0, _context.t0)(_context.t1);

              case 5:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function initStripe() {
        return _ref2.apply(this, arguments);
      };
    }();

    initStripe();
  }, []);

  var handleCloseButton = function handleCloseButton() {
    var isConfirmed = window.confirm(_i18n.default.translate('register.confirmCloseDialog'));

    if (isConfirmed) {
      closeDialog();
    }
  };

  return _react.default.createElement(_reactStripeJs.Elements, {
    stripe: stripe
  }, _react.default.createElement(_eventRegisterProvider.default, {
    initialTicketId: initialTicketId,
    eventInfo: _objectSpread(_objectSpread({}, eventInfo), {}, {
      eventSlug: eventSlug
    }),
    closeDialog: closeDialog,
    callBacks: callBacks
  }, _react.default.createElement(_core.Dialog, {
    maxWidth: "lg",
    open: true,
    fullScreen: fullScreen,
    PaperProps: {
      className: classes.dialogContainer
    }
  }, _react.default.createElement(_eventRegistrationFormikWrapper.default, null, _react.default.createElement(RegisterComponents, {
    fullScreen: fullScreen,
    handleCloseButton: handleCloseButton
  })))));
}

function ActiveComponent() {
  var _steps$activeStep;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      steps = _useEventRegisterCont.steps,
      activeStep = _useEventRegisterCont.activeStep;

  var Component = ((_steps$activeStep = steps[activeStep]) === null || _steps$activeStep === void 0 ? void 0 : _steps$activeStep.Component) || null;
  return Component;
}

function RegisterComponents(_ref3) {
  var fullScreen = _ref3.fullScreen,
      handleCloseButton = _ref3.handleCloseButton;

  var _useEventRegisterCont2 = (0, _eventRegisterProvider.useEventRegisterContext)(),
      isLastStep = _useEventRegisterCont2.isLastStep;

  var classes = (0, _eventRegistrationContainerStyle.default)({
    isLastStep: isLastStep()
  });
  return _react.default.createElement(_core.Box, {
    className: classes.paperContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.closeContainer
  }, _react.default.createElement(_core.IconButton, {
    className: classes.closeButton,
    onClick: handleCloseButton
  }, _react.default.createElement(_reactFeather.X, null))), _react.default.createElement(_eventRegistrationStepper.default, {
    fullScreen: fullScreen
  }), _react.default.createElement(_core.Box, {
    className: classes.mainSection
  }, _react.default.createElement(_core.Box, {
    className: classes.contentContainer
  }, _react.default.createElement(ActiveComponent, null))), !isLastStep() && _react.default.createElement(_core.Box, {
    className: classes.paymentSummaryContainer
  }, _react.default.createElement(_orderSummary.default, null)), _react.default.createElement(_requestErrorBlock.default, null), _react.default.createElement(_eventRegistrationNavigation.default, null));
}