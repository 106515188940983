var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SocialMediaInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _profile = _interopRequireDefault(require("../../core/profile"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var SocialMediaInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(SocialMediaInternal, _React$PureComponent);

  var _super = _createSuper(SocialMediaInternal);

  function SocialMediaInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, SocialMediaInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.onSocialImagePress = function (url) {
      _reactNative.Linking.canOpenURL(url).then(function (supported) {
        if (supported) {
          return _reactNative.Linking.openURL(url);
        }
      }).catch(function () {});
    };

    return _this;
  }

  (0, _createClass2.default)(SocialMediaInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: styles.socialMediaContainer
      }, this.props.facebookUrl != null && this.props.facebookUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "facebookTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.facebookUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "facebook",
        size: 20,
        color: "#ffffff"
      })), this.props.twitterUrl != null && this.props.twitterUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "twitterTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.twitterUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "twitter",
        size: 20,
        color: "#ffffff"
      })), this.props.instagramUrl != null && this.props.instagramUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "instagramTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.instagramUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "instagram",
        size: 20,
        color: "#ffffff"
      })), this.props.soundcloudUrl != null && this.props.soundcloudUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "soundcloudTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.soundcloudUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "soundcloud",
        size: 20,
        color: "#ffffff"
      })), this.props.mixcloudUrl != null && this.props.mixcloudUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "mixcloudTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.mixcloudUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "mixcloud",
        size: 20,
        color: "#ffffff"
      })), this.props.beatPortUrl != null && this.props.beatPortUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "beatPortTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.beatPortUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "beatport",
        size: 20,
        color: "#ffffff"
      })), this.props.residentAdvisorUrl != null && this.props.residentAdvisorUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "residentAdvisorTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.residentAdvisorUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "resident-advisor",
        size: 20,
        color: "#ffffff"
      })), this.props.websiteUrl != null && this.props.websiteUrl !== '' && _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "linkTouch",
        borderless: false,
        style: [styles.iconBtn, styles.socialBtn],
        onPress: function onPress() {
          return _this2.onSocialImagePress(_this2.props.websiteUrl);
        }
      }, _react.default.createElement(_CustomIcon.default, {
        name: "link",
        size: 20,
        color: "#ffffff"
      }))));
    }
  }]);
  return SocialMediaInternal;
}(_react.default.PureComponent);

exports.SocialMediaInternal = SocialMediaInternal;

var styles = _reactNative.StyleSheet.create({
  socialMediaContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 0,
    paddingHorizontal: 16
  },
  iconBtn: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255, 0.14)'
  },
  socialBtn: {
    marginRight: 8,
    marginBottom: 8
  }
});

function mapStateToProps(state, ownProps) {
  var userId = ownProps.userId;
  return {
    twitterUrl: _profile.default.selectors.getTwitterUrl(state, userId),
    facebookUrl: _profile.default.selectors.getFacebookUrl(state, userId),
    soundcloudUrl: _profile.default.selectors.getSoundcloudUrl(state, userId),
    mixcloudUrl: _profile.default.selectors.getMixCloudUrl(state, userId),
    instagramUrl: _profile.default.selectors.getInstagramUrl(state, userId),
    beatPortUrl: _profile.default.selectors.getBeatPortUrl(state, userId),
    residentAdvisorUrl: _profile.default.selectors.getResidentAdvisorUrl(state, userId),
    websiteUrl: _profile.default.selectors.getWebsiteUrl(state, userId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(SocialMediaInternal)));

exports.default = _default;