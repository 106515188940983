var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormikWrapper;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _eventRegistrationUtils = require("./event-registration-utils");

var _formik = require("formik");

var Yup = _interopRequireWildcard(require("yup"));

var _contactInformationStepUtils = require("./event-registration-components/contact-information-step/contact-information-step-utils");

var _eventRegisterProvider = require("./event-register-provider");

var _yupUtils = require("../../utils/yup-utils");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _constants = require("../../core/tickets/constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[typeof Symbol === "function" ? Symbol.iterator : "@@iterator"] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function FormikWrapper(_ref) {
  var children = _ref.children;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      activeStep = _useEventRegisterCont.activeStep,
      currentTransactionUnassignedTickets = _useEventRegisterCont.currentTransactionUnassignedTickets,
      steps = _useEventRegisterCont.steps,
      formValuesState = _useEventRegisterCont.formValuesState,
      isLoggedIn = _useEventRegisterCont.isLoggedIn,
      nonstandardFields = _useEventRegisterCont.nonstandardFields,
      onNext = _useEventRegisterCont.onNext,
      userProfile = _useEventRegisterCont.userProfile,
      summaryPrices = _useEventRegisterCont.summaryPrices,
      passwordLessRegistration = _useEventRegisterCont.passwordLessRegistration,
      ticketQuantity = _useEventRegisterCont.ticketQuantity;

  var currentStep = steps[activeStep];
  var fields = nonstandardFields.map(_contactInformationStepUtils.createStandardizedFormField);

  var getInitialValues = function getInitialValues() {
    var CHECKOUT_INITIAL_VALUES = {
      country: '',
      cardholderName: '',
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      city: ''
    };
    var customFieldsInitialValues = fields.reduce(_contactInformationStepUtils.createFormikSchema, {});
    var userCredentialsInitialValues = isLoggedIn ? {
      username: userProfile === null || userProfile === void 0 ? void 0 : userProfile.displayName,
      email: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email
    } : _contactInformationStepUtils.REGISTRATION_FORM.reduce(_contactInformationStepUtils.createFormikSchema, {});

    if (formValuesState) {
      return formValuesState;
    }

    return _objectSpread(_objectSpread(_objectSpread({}, userCredentialsInitialValues), customFieldsInitialValues), CHECKOUT_INITIAL_VALUES);
  };

  var assignTicketsValidationSchema = {};

  var _iterator = _createForOfIteratorHelper(currentTransactionUnassignedTickets),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _objectSpread2;

      var unassignedTicket = _step.value;
      assignTicketsValidationSchema = _objectSpread(_objectSpread({}, assignTicketsValidationSchema), {}, (_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id), Yup.string()), (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id), Yup.string().email(_i18n.default.t('billing.create.invalidFormat'))), _objectSpread2));
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var getValidationSchema = function getValidationSchema() {
    if (summaryPrices.totalWithPromotion === 0) return;

    switch (currentStep.id) {
      case _eventRegistrationUtils.STEPS_IDS.contactInformation:
        {
          var CURRENT_REGISTRATION_FORM = isLoggedIn ? [{}] : _contactInformationStepUtils.REGISTRATION_FORM;
          return Yup.object().shape(_objectSpread(_objectSpread({}, CURRENT_REGISTRATION_FORM.reduce(_yupUtils.createYupSchema, {})), ticketQuantity === 1 && fields.reduce(_yupUtils.createYupSchema, {})));
        }

      case _eventRegistrationUtils.STEPS_IDS.checkout:
        {
          return Yup.object().shape({
            email: Yup.string().required(_i18n.default.t('billing.create.email')).trim().email(_i18n.default.t('billing.create.invalidFormat')),
            cardholderName: Yup.string().required(_i18n.default.t('billing.create.cardholderName')),
            addressLine1: Yup.string().required(_i18n.default.t('billing.create.addressLine1')),
            addressLine2: Yup.string().notRequired(_i18n.default.t('billing.create.addressLine2')),
            postalCode: Yup.string().required(_i18n.default.t('billing.create.postalCode')),
            city: Yup.string().required(_i18n.default.t('billing.create.city'))
          });
        }

      case _eventRegistrationUtils.STEPS_IDS.assignTickets:
        {
          return Yup.object().shape(_objectSpread(_objectSpread({}, assignTicketsValidationSchema), {}, {
            isMyTicket: Yup.boolean().notRequired()
          }));
        }

      default:
        return {};
    }
  };

  var initialValues = getInitialValues();
  return currentStep !== null && currentStep !== void 0 && currentStep.hasFormikWrapper ? _react.default.createElement(_formik.Formik, {
    validateOnChange: false,
    onSubmit: onNext,
    initialValues: initialValues,
    validationSchema: getValidationSchema()
  }, children) : children;
}