var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.legalInformationsEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _createReducer;

var legalInformationsEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.legalInformationsEntityAdapter = legalInformationsEntityAdapter;
var InitialState = legalInformationsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
var legalInformationsReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getLegalInformations.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getLegalInformations.rejected, function (state, _ref) {
  var error = _ref.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getLegalInformations.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;
  legalInformationsEntityAdapter.addMany(state, payload);
}), _createReducer));
var _default = legalInformationsReducer;
exports.default = _default;