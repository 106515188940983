Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasHashTagsAvailable = exports.hasHashTagsLoaded = exports.hasHashTags = exports.getHashtagsRow2 = exports.getHashtagsRow1 = exports.getAllHashTags = void 0;

var _constants = require("./constants");

var getAllHashTags = function getAllHashTags(state) {
  return state[_constants.NAME].get('hashtags', {});
};

exports.getAllHashTags = getAllHashTags;

var getHashtagsRow1 = function getHashtagsRow1(state) {
  return state[_constants.NAME].get('hashtagsRow1', {});
};

exports.getHashtagsRow1 = getHashtagsRow1;

var getHashtagsRow2 = function getHashtagsRow2(state) {
  return state[_constants.NAME].get('hashtagsRow2', {});
};

exports.getHashtagsRow2 = getHashtagsRow2;

var hasHashTags = function hasHashTags(state) {
  return state[_constants.NAME].get('hashtags').size > 0;
};

exports.hasHashTags = hasHashTags;

var hasHashTagsLoaded = function hasHashTagsLoaded(state) {
  return state[_constants.NAME].size > 0;
};

exports.hasHashTagsLoaded = hasHashTagsLoaded;

var hasHashTagsAvailable = function hasHashTagsAvailable(state) {
  return state[_constants.NAME].get('hashtagsRow1', {}).size > 0 && state[_constants.NAME].get('hashtagsRow2', {}).size > 0;
};

exports.hasHashTagsAvailable = hasHashTagsAvailable;