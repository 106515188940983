Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useDetailsAndPreviewStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    videoSection: {
      flexDirection: 'row',
      marginTop: theme.spacing.m
    },
    detailsSection: {
      paddingHorizontal: theme.spacing.l
    },
    avatars: {
      marginBottom: theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.lg;
}, function (theme) {
  return {
    videoSection: {
      flexDirection: 'column'
    },
    detailsSection: {
      paddingHorizontal: theme.spacing.m
    }
  };
}).buildHook();
var _default = useDetailsAndPreviewStyles;
exports.default = _default;