var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailLoginContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _emailLoginStyles = _interopRequireDefault(require("./emailLogin-styles"));

var _emailLoginView = _interopRequireDefault(require("./emailLogin-view"));

var _forgotPasswordView = _interopRequireDefault(require("./forgotPassword-view"));

var _reactRedux = require("react-redux");

var _auth = _interopRequireDefault(require("../../../core/auth"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function EmailLoginContainer(props) {
  var onGoBack = props.onGoBack,
      login = props.login,
      resetPassword = props.resetPassword;
  var loading = (0, _reactRedux.useSelector)(_auth.default.selectors.isLoginLoading);
  var forgotPasswordLoading = (0, _reactRedux.useSelector)(_auth.default.selectors.isResetPasswordLoading);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      forgotPasswordFlag = _useState2[0],
      setForgotPasswordFlag = _useState2[1];

  var handleEmailAuth = function handleEmailAuth(email, password) {
    var credentials = {
      email: email,
      password: password
    };
    login(credentials);
  };

  var handleForgotPassword = (0, _react.useCallback)(function (email) {
    resetPassword(email);

    _reactNative.Alert.alert('', 'We have send you an email to recover your password');
  }, []);
  return _react.default.createElement(_reactNativeSafeAreaView.default, {
    testID: "safeArea",
    style: _emailLoginStyles.default.container,
    forceInset: {
      top: 'never',
      bottom: 'always'
    }
  }, _react.default.createElement(_reactNative.StatusBar, {
    barStyle: "light-content",
    hidden: false,
    backgroundColor: "transparent",
    translucent: true
  }), forgotPasswordFlag ? _react.default.createElement(_forgotPasswordView.default, {
    onGoBack: function onGoBack() {
      return setForgotPasswordFlag(!forgotPasswordFlag);
    },
    onForgotPassword: handleForgotPassword,
    loading: forgotPasswordLoading
  }) : _react.default.createElement(_emailLoginView.default, {
    testID: 'EmailLoginView',
    onGoBack: onGoBack,
    loading: loading,
    onEmailAuth: handleEmailAuth,
    onForgotPassword: function onForgotPassword() {
      return setForgotPasswordFlag(!forgotPasswordFlag);
    }
  }));
}