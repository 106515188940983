var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _excluded = ["start", "end", "colors", "locations", "useAngle", "angleCenter", "style"];

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var convertPoint = function convertPoint(name, point) {
  if (Array.isArray(point)) {
    console.warn("LinearGradient '".concat(name, "' property should be an object with fields 'x' and 'y', ") + "Array type is deprecated.");
    return {
      x: point[0],
      y: point[1]
    };
  }

  return point;
};

var LinearGradient = function (_Component) {
  (0, _inherits2.default)(LinearGradient, _Component);

  var _super = _createSuper(LinearGradient);

  function LinearGradient() {
    var _this;

    (0, _classCallCheck2.default)(this, LinearGradient);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.props = void 0;
    _this.gradientRef = void 0;
    return _this;
  }

  (0, _createClass2.default)(LinearGradient, [{
    key: "setNativeProps",
    value: function setNativeProps(props) {
      this.gradientRef.setNativeProps(props);
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;

      var _this$props = this.props,
          start = _this$props.start,
          end = _this$props.end,
          colors = _this$props.colors,
          locations = _this$props.locations,
          useAngle = _this$props.useAngle,
          angleCenter = _this$props.angleCenter,
          style = _this$props.style,
          otherProps = (0, _objectWithoutProperties2.default)(_this$props, _excluded);

      if (colors && locations && colors.length !== locations.length) {
        console.warn("LinearGradient colors and locations props should be arrays of the same length");
      }

      return _react.default.createElement(_reactNative.View, (0, _extends2.default)({
        ref: function ref(component) {
          _this2.gradientRef = component;
        }
      }, otherProps, {
        style: [style, {
          backgroundImage: "linear-gradient(to bottom, ".concat(colors[0], " 0%, ").concat(colors[1], " 100%)")
        }]
      }));
    }
  }]);
  return LinearGradient;
}(_react.Component);

exports.default = LinearGradient;
LinearGradient.defaultProps = {
  start: {
    x: 0.5,
    y: 0.0
  },
  end: {
    x: 0.5,
    y: 1.0
  }
};