var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _page$login$register$;

var _default = (_page$login$register$ = {
  page: {
    lobby: 'Lobby',
    home: 'Start'
  },
  login: {
    header: 'Anmelden',
    emailRequired: 'E-Mailadresse erfoderlich',
    emailValid: 'Fügen Sie eine gültige E-Mailadresse hinzu',
    passwordRequired: 'Passwort erforderlich',
    email: 'E-mail*',
    password: 'Passwort*',
    register: 'Registrieren',
    loginButton: 'Anmelden',
    sendLoginEmailButton: 'Mit E-Mail Adresse anmelden',
    backToLoginButton: 'Anmeldung erneut versuchen',
    createNewAccount: 'Für dieses Event registrieren',
    forgotPassword: 'Passwort vergessen?',
    login: 'Anmelden',
    forgotPasswordRetry: 'Ich benötige einen neuen Link zur Passwort zurücksetzung',
    emailOnlyText: 'Wir schicken Ihnen dann einen Link für eine passwortlose Anmeldung per E-Mail. Oder Sie können sich anmelden',
    emailOnlyTextLink: 'stattdessen ein Passwort zu verwenden.',
    emailPassText: 'Melden Sie sich mit dem Passwort an, das Sie bei der Registrierung festgelegt haben. Oder Sie können sich anmelden',
    emailPassTextLink: 'über einen Link, der per E-Mail verschickt wird.',
    emailWithLoginUrlSent: 'Es wurde eine E-Mail an Ihre E-Mail Adresse gesendet. Bitte klicken Sie auf den Link in der E-Mail, um sich bei der Veranstaltung einzuloggen.',
    confirmYourEmail: 'Bitte bestätigen Sie Ihre E-Mail Adresse. Sie haben den Login-Link nicht auf demselben Gerät/Browser geöffnet.'
  },
  register: {
    passwordMinCharacters: 'Das Passwort sollte mindestens 6 Zeichen enthalten',
    passwordMatch: 'Die Passwörter stimmen nicht überein',
    termsAndConditions: 'Mit der Registrierung bestätigen Sie, dass Sie mit den',
    confirmPassword: 'Passwort bestätigen*',
    terms: 'Nutzungsbedingungen',
    and: 'und',
    policy: 'Datenschutzrichtlinien',
    continue: 'Weiter',
    registration: 'Registrierung',
    fieldRequired: 'Dieses Feld ist erforderlich',
    alreadyHaveAccount: 'Haben Sie bereits ein Konto?',
    displayNameUpdate: 'Benutzernamen hinzufügen',
    displayNameUpdateInfo: 'Bitte geben Sie Ihren Benutzernamen an - denn bei der Registrierung wurde er nicht erfolgreich eingetragen.',
    saveDisplayName: 'Speichern',
    displayNameRequired: 'Ein Benutzername ist erforderlich. Bitte geben Sie einen an. Es sollte nicht Ihre E-Mail Adresse sein.',
    ticket: 'Ticket',
    username: 'Name*',
    userName2: 'Name*',
    userSurname: 'Nachname*',
    company: 'Unternehmen*',
    position: 'Position*',
    companyRequired: 'Unternehmen ist erforderlich',
    positionRequired: 'Position ist erforderlich',
    userName2Required: 'Name ist erforderlich',
    usernameRequired: 'Name ist erforderlich',
    surnameRequired: 'Nachname ist erforderlich',
    email: 'E-mail*',
    emailRequired: 'E-Mail Adresse ist erfoderlich',
    emailInvalid: 'Fügen Sie eine gültige E-Mail Adresse hinzu',
    password: 'Passwort*',
    passwordRequired: 'Passwort erforderlich',
    passwordConfirm: 'Passwort bestätigen*',
    passwordConfirmRequired: 'Passwortbestätigung ist erforderlich',
    doNotClose: 'Nur eine Sekunde, wir bereiten alles für Sie vor!',
    inProgress: 'Registrieung in Bearbeitung...',
    inProgressStepTwo: 'Registrieung in Bearbeitung... 2/3 des Benutzerprofils aktualisiert',
    inProgressStepThree: 'Registrieung in Bearbeitung... 3/3 Registrierung ferstigstellen',
    inProgressCompleted: 'Registrierung fertiggestellt.',
    error: {
      fileSize: 'Die Datei ist zu groß. Erlaubte maximale Größe ist 50MiB.',
      upload: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!'
    }
  },
  addOns: {
    title: 'Add-Ons'
  },
  resetPassword: {
    enterEmail: 'Geben Sie die bei der Registrierung verwendete E-Mail-Adresse ein',
    resetPassword: 'Passwort zurücksetzen',
    resetActionText: 'Geben Sie unten Ihr neues Passwort ein und drücken Sie die Bestätigungsschaltfläche, um Ihr Passwort zurückzusetzen.',
    invalidCode: 'Abgelaufener Code zum Zurücksetzen des Passworts',
    invalidCodeText: 'Ihr Passwort-zurücksetzungs-Code ist abgelaufen - er ist nur etwa eine Stunde aktiv. Bitte versuchen Sie den Reset-Vorgang erneut.',
    success: 'Passwort erfolgreich zurückgesetzt',
    successText: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können nun versuchen, sich mit Ihrem neuen Passwort anzumelden.',
    mailSent: 'Eine E-Mail zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.'
  },
  header: {
    about: 'Über',
    speakers: 'Referenten',
    schedule: 'Programm',
    benefits: 'Vorteile',
    testimonials: 'Testimonials',
    partners: 'Partner',
    booths: 'Messestände',
    sponsors: 'Sponsoren',
    checklist: 'Checklist',
    myTickets: 'Meine Tickets',
    myProfile: 'Mein Profil',
    logout: 'Abmelden',
    myFiles: 'Meine Dateien',
    lobbyPage: 'Live Veranstaltung'
  },
  leftMenu: {
    home: 'Start',
    liveNow: 'Jetzt Live',
    schedule: 'Programm',
    speakers: 'Redner',
    videos: 'Videos',
    sponsors: 'Sponsoren',
    networking: 'Networking',
    expo: 'Ausstellung'
  },
  rightSidebarMenu: {
    chat: 'Chat',
    booth: 'Messestände',
    people: 'Personen',
    files: 'Dateien',
    uploadedFiles: 'Dateien hochladen',
    speak: 'Sprechen',
    backstageChat: 'Hinter den Kulissen Chat'
  },
  rightSidebar: {
    noImage: 'Kein Bild'
  },
  footer: {
    termsAndConditions: 'Geschäftsbedingungen',
    privacyPolicy: 'Datenschutzrichtlinien'
  },
  global: {
    back: 'Zurück',
    backToPage: 'Zurück zur Seite',
    hours: 'Stunde(n)',
    days: 'Tag(e)',
    minutes: 'Minuten',
    today: '[Heute]',
    tomorrow: '[Morgen]',
    invalidSpeakerProfile: 'Dies ist kein gültiges Rednerprofil.',
    download: 'Herunterladen',
    yes: 'Ja',
    no: 'Nein'
  },
  networking: {
    noImage: 'Kein Bild',
    joinTable: 'Tisch beitreten',
    joinTheNetworkingArea: 'Zum Networking Bereich',
    viewTable: 'Ansehen',
    leaveTable: 'Tisch verlassen',
    noDevices: 'Keine Geräte',
    you: 'Du',
    apply: 'Schließen',
    internalServerError: 'Interner Serverfehler',
    ok: 'Ok',
    error: {
      tableIsFull: 'Entschuldigung, dieser Tisch ist voll. Bitte versuchen Sie es erneut, sobald jemand den Tisch verlässt.',
      internalServerError: 'Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal.'
    },
    checkAudioAndVideoSettings: 'Audio- und Videoeinstellungen prüfen',
    takeASeat: 'Nehmen Sie Platz',
    tableIsFull: 'Der Tisch ist voll',
    off: 'Aus',
    browserNotSupported: 'Ihr Browser unterstützt unsere Technologie nicht',
    openSupportedBrowser: 'Bitte besuchen Sie diese Seite mit einem der unterstützten Browser.',
    supportDetails: 'Weitere Details finden Sie auf der',
    supportedBrowsers: 'unterstützte Browser',
    unknownOS: 'Leider konnte das Betriebssystem Ihres Geräts nicht identifiziert werden. Bitte versuchen Sie einen anderen Browser.',
    connectionError: 'Verbindungsfehler',
    connectionErrorText: 'Die Verbindung ist fehlgeschlagen und der folgende Fehler wurde empfangen:',
    inactivityWarning: 'Das Systen wird Ihren Platz in {{seconds}} Sekunden an jemand anderen vergeben',
    youreInactive: 'Sie sind inaktiv',
    done: 'Fertig',
    somethingBadHappened: 'Ooops! Wir haben ein Problem!'
  },
  backstage: {
    startThisSession: 'Starten Sie diese Session?',
    startSessionInfo: 'Nachdem eine Session gestartet wurde, können die Teilnehmer die Sprecher der Session sehen und hören.',
    someone: 'Jemand',
    no: 'Abbrechen',
    join: 'Ja, beitreten',
    start: 'Session starten',
    sessionStarts: '{{name}} begann die Session.',
    sessionStarting: 'Session wird gestartet...',
    startsIn: 'Beginnt in:',
    noRequests: 'Keine Anfragen',
    iAmReady: 'Ich bin bereit',
    iAmReadyTooltip: 'Wenn Sie bereit sind, an der Session teilzunehmen, klicken Sie auf die Schaltfläche „Ich bin bereit„. Der Sessionshost wird benachrichtigt, dass Sie angekommen sind.',
    iAmReadyTooltipOk: 'OK, ich verstehe!',
    waitingForApproval: 'Warten auf die Bestätigung',
    stop: 'Stoppen',
    ok: 'OK',
    leave: 'Verlassen',
    leaveLive: 'Verlassen',
    cancel: 'Abbrechen',
    goToBackstage: 'Weiter',
    tableIsFull: 'Call room is full',
    internalServerError: 'Internal server error',
    internal: 'Internal server error',
    speakerJoinRequestFailed: 'Go live request failed',
    speakerLeaveRequestFailed: 'Leave request failed',
    adminSpeakerRequestFailed: 'Admit speaker request failed',
    welcomeToTheBackstage: 'Willkommen im Backstage!',
    welcomeToTheBackstageText: 'Gespräche hier sind privat. Die Veranstaltungsteilnehmer können Sie erst sehen, wenn der Gastgeber die Session beginnt.',
    welcomeToTheGreenRoom: 'Sie befinden sich jetzt im Green Room',
    welcomeToTheGreenRoomText: 'Dies ist der private Willkommensbereich für diese Veranstaltung.',
    welcomeToTheGreenRoomText2: 'Sie können die Videofunktionen ausprobieren, bevor Sie live gehen. Event-Teilnehmer können Sie noch nicht sehen.',
    continueToGreenRoom: 'Ok, weiter',
    checkSettings: 'Überprüfen Sie Ihre Video-/Audioeinstellungen',
    checkSettingsText: 'Stellen Sie sicher, dass Ihre Kamera und Ihr Mikrofon ordnungsgemäß funktionieren.',
    kickOutSpeaker: 'Möchtest du {{name}} wirklich aus diesem Livestream entfernen?',
    startBackstageSession: 'Live-Session starten',
    stopBackstageSession: 'Live-Session beenden',
    userDenied: 'Zugriff abgelehnt',
    userDeniedText: 'Ihr Zugang zum Backstage wurde vom Gastgeber (Host) verweigert. Fragen Sie den Gastgeber gerne im Backstage-Chat.',
    participantsCanSeeYou: 'Die Veranstaltungsteilnehmer können Sie sehen und hören.',
    participantsCannotSeeYou: 'Die Veranstaltungsteilnehmer können Sie noch nicht sehen und hören.',
    readyConfirmationTitle: 'Betreten Sie die Live-Session',
    readyConfirmation: 'Sind Sie bereit, an der Live-Session teilzunehmen?',
    bestOnDesktop: 'Backstage funktioniert am besten auf größeren Bildschirmen',
    goBackToLive: 'Zurück zum Live-Event',
    error: {
      tableIsFull: 'Sorry, the video call room is full. Please try again later.',
      internalServerError: 'Something went wrong, please try again later.',
      internal: 'Something went wrong, please try again later.',
      speakerJoinRequestFailed: 'Go live request failed. Please try again later.',
      adminSpeakerRequestFailed: 'Something went wrong, speaker request could not be admitted',
      kickOutSpeakerFailed: 'Speaker konnte nicht rausgeschmissen werden',
      backstageSessionStartFailed: 'Backstage session starting failed. Please try again later.',
      backstageSessionStopFailed: 'Backstage session stopping failed. Please try again later.',
      requestedResourceNotFound: 'The requested resource not found. The URL of this backstage seems to be corrupted. Please check again.',
      maximumNumberOfParticipantsIsReached: 'The backstage room is full. At this moment no more speakers can join this backstage.'
    },
    header: {
      backstage: 'Backstage',
      greenRoom: 'Green Room',
      session: 'Live-Session'
    }
  },
  checkout: {
    conferenceTickets: 'Konferenz Tickets',
    promoCode: 'Aktionscode',
    freeRegiser: 'Kostenfrei registrieren',
    buyTicket: 'Ticket kaufen',
    myTickets: 'Meine Tickets',
    otherTickets: 'Weitere Tickets',
    validity: 'Gültigkeit',
    buyedOn: 'Gekauft am',
    payment: 'Bezahlung abschließen',
    ticket: 'Ticket',
    free: 'Kostenfrei',
    allDays: 'Komplette Veranstaltung',
    summary: 'Zusammenfassung',
    generalAccess: 'Allgmeiner Zugang',
    addOns: 'Add-ons',
    total: 'Insgesamt',
    next: 'Weiter',
    optionalAddons: 'Optionale Add-ons',
    addonsInstruction: 'Bitte wählen Sie weitere Add-ons zu Ihrer Bestellung',
    approveTicket: 'Ihr Konto muss noch vom Organisator der Veranstaltung genehmigt werden. Sie erhalten eine Aktivierungs-E-Mail, sobald Ihr Konto genehmigt wurde',
    addToCalendar: 'Zum Kalender hinzufügen'
  }
}, (0, _defineProperty2.default)(_page$login$register$, "addOns", {
  title: 'Add-ons',
  multipleAddonsWarning: 'Ausgewählte Add-Ons werden auf alle Tickets angewendet.'
}), (0, _defineProperty2.default)(_page$login$register$, "configureTicket", {
  title: 'Ereigniszugriff',
  ticket: 'Ticket',
  quantity: 'Menge',
  owned: 'besessen'
}), (0, _defineProperty2.default)(_page$login$register$, "assignTickets", {
  pageTitle: 'Tickets zuweisen',
  thankYou: 'Vielen Dank für Ihren Einkauf!',
  thankYouDescription: 'Bitte geben Sie für jedes Ticket an, wer Zugang zur Veranstaltung haben wird.',
  email: 'Email',
  fullName: 'Vollständiger Name',
  thisIsMyTicket: 'Das ist mein Ticket',
  ticketAssigned: 'Ticket zugewiesen',
  emailShouldBeUnique: 'E-Mail-Adressen sollten einzigartig sein',
  youAlreadyHaveThisTicket: 'Sie haben bereits dieses Ticket',
  assignedTickets: 'Zugewiesene Tickets',
  unassignedTickets: 'Nicht zugewiesene Tickets',
  ticket: 'Ticket',
  addons: 'Addons',
  assignedTo: 'Zugewiesen an',
  assignedDate: 'Zugewiesen am'
}), (0, _defineProperty2.default)(_page$login$register$, "lobby", {
  eventStartIn: 'Das Event startet in:',
  eventStarted: 'Die Veranstaltung läuft.',
  eventEnded: 'Die Veranstaltung ist beendet.',
  joinLive: 'Live beitreten',
  with: 'mit',
  writeHere: 'Eine Nachricht schreiben',
  remained: 'Verbleibend',
  addToCalendar: 'Zum Kalender hinzufügen'
}), (0, _defineProperty2.default)(_page$login$register$, "billing", {
  promoCode: 'Aktionscode',
  cardInformation: 'Karteninformation',
  cardHolderName: 'Name des Karteninhabers ',
  country: 'Land',
  billingAddress: 'Rechnungsadresse',
  pay: 'Bezahlen',
  payWithCard: 'Mit Karte bezahlen',
  email: 'Email',
  currency: 'US',
  termsAndConditions: 'Sie kaufen für das Konto',
  termsAndConditions2: 'Mit der Bezahlung eines Tickets erklären Sie sich mit unserem',
  stripe: 'Sichere Zahlungen durch',
  create: {
    email: 'E-mail Adresse ist erforderlich',
    cardholderName: 'Name des Karteninhabers ist erforderlich',
    billingAddress: 'Rechnungsadresse ist erforderlich',
    addressLine1: 'Adresse Zeile ist erforderlich',
    postalCode: 'Postleitzahl ist erforderlich',
    city: 'Stadt ist erforderlich',
    allFields: 'ERFORDERLICH',
    invalidFormat: 'Dies ist kein gültiges Format',
    cardNumberPlaceholder: 'Kartennummer',
    addressLine1Placeholder: 'Adresse Zeile',
    postalCodePlaceholder: 'Postleitzahl',
    cityPlaceholder: 'Stadt'
  },
  error: {
    country: 'Das Rechnungsland scheint für Stripe ungültig / nicht verarbeitbar zu sein.',
    incomplete_number: 'Die Kartennummer scheint unvollständig zu sein.',
    invalid_number: 'Dies ist eine ungültige Kartennummer.',
    incomplete_expiry: 'Dies ist ein unvollständiges Verfallsdatum.',
    invalid_expiry_year: 'Das Verfallsdatum ist ungültig.',
    incomplete_cvc: 'Das CVC-Feld ist unvollständig.'
  }
}), (0, _defineProperty2.default)(_page$login$register$, "liveNow", {
  start: 'Beginnt',
  fewSeconds: 'wenige Sekunden',
  sessionsEnded: 'Diese Session ist beendet',
  noLiveVideo: 'Es sind keine Live-Videos vorhanden.',
  noPreview: 'HIer klicken',
  sessionEnded: 'Diese Session ist beendet',
  sessionDidNotStart: 'Diese Session ist noch nicht gestartet',
  sessionStarts: 'Diese Session startet in:',
  sessionWillStart: 'Diese Session startet in kürze',
  upgradeTicket: 'Ticket aktualisieren',
  noAccess: 'Tut mir leid, Ihr aktuelles Ticket beinhaltet keinen Zugang zu diesem Inhalt. Bitte versuchen Sie, Ihr Ticket zu aktualisieren.',
  tryAgain: 'Bitte Seite aktualisieren',
  upcoming: 'Bevorstehnde Sessionen',
  past: 'Vergangene Sessionen',
  refresh: 'Aktualisieren',
  backToNetworking: 'Zurück zur Vernetzung',
  eventFinished: 'The event has finished. Thank you for participating.',
  sessionFinished: 'Die Sessions sind auf dieser Stage beendet. Danke fürs Mitmachen!',
  prerecordedVideoFinished: 'This session has finished. Stay tuned for our next session.',
  prerecordedVideoUnavailable: 'Waiting for the Host (Organizer) to start the (RTMPS) live stream or to associate a pre-recorded video for this session.',
  invalidSession: 'This is not a valid live URL. Sorry. Please navigate back to the "Live Now" page.',
  break: 'Pause',
  goToNetworkingArea: 'Zum Networking Bereich',
  waitingForStreamStart: 'Warten Sie mal. Der Host ist dabei, den Stream zu starten ...',
  waitingForParticipants: 'Es befinden sich keine Teilnehmer im Networking raum. Warten auf den Eintritt der Teilnehmer...'
}), (0, _defineProperty2.default)(_page$login$register$, "videos", {
  otherVideos: 'Videos',
  notFoundVideo: 'Das Video existiert nicht (oder Sie haben keine Zugriffsrechte).',
  noVideos: 'Die Aufzeichnungen stehen 24 Stunden nach Veranstaltungsende zur Verfügung.',
  tryAgain: 'Bitte versuchen Sie es erneut, nachdem die Veranstaltung beendet ist.',
  noAccessToVideos: 'Ihr aktuelles Ticket beinhaltet leider keinen Zugang zu Aufzeichnungen. Bitte versuchen Sie, Ihr Ticket zu aktualisieren.'
}), (0, _defineProperty2.default)(_page$login$register$, "payment", {
  success: 'Die Bezahlung war erfolgreich!',
  failed: 'Leider ist de Bezahlung schiefgelaufen. Bitte versuchen Sie es erneut!',
  redirect: 'Sie werden weitergeleitet zu',
  in: 'in',
  seconds: 'Sekunden',
  complete: 'Bezahlung abschließen'
}), (0, _defineProperty2.default)(_page$login$register$, "domain", {
  noEventForDomain: 'Domain ohne zugehörige Veranstaltung.',
  noAssociatedEvent: 'Zur Zeit ist keine Veranstaltung geplant.',
  severalEvents: 'Es gibt mehrere Ereignisse, die mit dieser Domäne verbunden sind:'
}), (0, _defineProperty2.default)(_page$login$register$, "audiovideoSettings", {
  title: 'Audio & Video Einstellungen',
  description: 'Sie sind im Begriff, Ihre Webcam zu verwenden. Damit andere Sie sehen und hören können, fordert Ihr Browser den Zugriff auf Ihre Cam und Ihr Mikrofon an. Sie können sie dennoch jederzeit wieder ausschalten.',
  audioInput: 'Audio Eingabe',
  audioOutput: 'Audio Ausgabe',
  videoInput: 'Video Eingabe',
  apply: 'Anwenden',
  noLocalAudio: 'Keine lokale Audio',
  noLocalVideo: 'Kein lokales Video',
  audioVideoSettingsFirefoxNotSupported: 'Audio & Video Einstellungen funktioniert in Mozilla Firefox nicht richtig, bitte verwenden Sie Chrome oder Microsoft Edge.'
}), (0, _defineProperty2.default)(_page$login$register$, "toaster", {
  error: 'Etwas is schlief gelaufen',
  success: 'Erfolg'
}), (0, _defineProperty2.default)(_page$login$register$, "errors", {
  error: 'Fehler',
  firewall: 'Firewall Problem',
  firewall1: 'Möglicherweise befinden Sie sich hinter einer Firewall, die die Verbindungen zu ',
  firewall2: 'Bitte wenden Sie sich an Ihren Netzwerk- oder IT-Administrator und bitten Sie um',
  firewall3: 'Erlauben Sie den Zugriff auf*.googleapis.com domain.',
  error404: '404',
  somethingWentWrong: 'Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut!',
  tryAgain: 'Erneut versuchen',
  eventNotExists: 'Dieses Event existiert nicht.',
  'auth/admin-restricted-operation': 'Dieser Vorgang ist nur für Administratoren zugänglich.',
  'auth/argument-error': '',
  'auth/app-not-authorized': 'Diese App, identifiziert durch die Domain, in der sie gehostet wird, ist nicht berechtigt, die Firebase-Authentifizierung mit dem angegebenen API-Schlüssel zu verwenden. Überprüfen Sie Ihre Schlüsselkonfiguration in der Google API-Konsole',
  'auth/app-not-installed': 'Die angeforderte mobile Anwendung, die der angegebenen Kennung (Android-Paketname oder iOS-Bundle-ID) entspricht, ist nicht auf diesem Gerät installiert.',
  'auth/captcha-check-failed': 'Das bereitgestellte reCAPTCHA-Antwort-Token ist entweder ungültig, abgelaufen, bereits verwendet oder die damit verbundene Domain stimmt nicht mit der Liste der Whitelist-Domains überein.',
  'auth/code-expired': 'Der SMS-Code ist abgelaufen. Bitte senden Sie den Verifizierungscode erneut, um es erneut zu versuchen.',
  'auth/cordova-not-ready': 'Cordova framework ist nicht bereit.',
  'auth/cors-unsupported': 'Dieser Browser wird nicht unterstützt.',
  'auth/credential-already-in-use': 'Diese Zugangsdaten sind bereits mit einem anderen Benutzerkonto verknüpft.',
  'auth/custom-token-mismatch': 'Das benutzerdefinierte Token entspricht einer anderen Zielgruppe.',
  'auth/requires-recent-login': 'Dieser Vorgang ist vertraulich und erfordert eine aktuelle Authentifizierung. Melden Sie sich erneut an, bevor Sie diese Anfrage erneut versuchen.',
  'auth/dynamic-link-not-activated': 'Bitte aktivieren Sie Dynamische Links in der Firebase-Konsole und stimmen Sie den Bedingungen und Konditionen zu.',
  'auth/email-change-needs-verification': 'Multi-Faktor-Benutzer müssen immer eine verifizierte E-Mail haben.',
  'already-exists': 'Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet.',
  'auth/expired-action-code': 'Der Aktionscode ist abgelaufen. ',
  'auth/cancelled-popup-request': 'Dieser Vorgang wurde abgebrochen, da ein anderes konfliktbehaftetes Popup geöffnet wurde.',
  'auth/internal-error': 'Ein interner Fehler ist aufgetreten.',
  'auth/invalid-app-credential': 'Die Telefonverifizierungsanfrage enthält einen ungültigen Application Verifier. Die reCAPTCHA-Token-Antwort ist entweder ungültig oder abgelaufen.',
  'auth/invalid-app-id': 'Die Kennung der mobilen App ist nicht für das aktuelle Projekt registriert.',
  'auth/invalid-user-token': 'Der Berechtigungsnachweis dieses Benutzers ist für dieses Projekt nicht gültig. Dies kann passieren, wenn das Token des Benutzers manipuliert wurde oder wenn der Benutzer nicht für das mit diesem API-Schlüssel verknüpfte Projekt ist.',
  'auth/invalid-auth-event': 'Ein interner Fehler ist aufgetreten.',
  'auth/invalid-verification-code': 'Der SMS-Verifizierungscode, der zum Erstellen der Telefon-Authentifizierungsdaten verwendet wurde, ist ungültig. Bitte senden Sie die Verifizierungscode-SMS erneut und verwenden Sie unbedingt den vom Benutzer angegebenen Verifizierungscode.',
  'auth/invalid-continue-uri': 'Die in der Anfrage angegebene Fortsetzungs-URL ist ungültig.',
  'auth/invalid-cordova-configuration': 'Die folgenden Cordova-Plugins müssen installiert sein, um die OAuth-Anmeldung zu ermöglichen: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser und cordova-plugin-customurlscheme.',
  'auth/invalid-custom-token': 'Das benutzerdefinierte Token-Format ist falsch. Bitte prüfen Sie die Dokumentation.',
  'auth/invalid-dynamic-link-domain': 'Die angegebene Dynamic Link Domain ist nicht für das aktuelle Projekt konfiguriert oder autorisiert.',
  'auth/invalid-email': 'Die E-Mail Adresse ist schlecht formatiert.',
  'auth/invalid-api-key': 'Ihr API-Schlüssel ist ungültig, bitte überprüfen Sie, ob Sie ihn richtig kopiert haben.',
  'auth/invalid-cert-hash': 'Der angegebene SHA-1-Zertifikatshash ist ungültig.',
  'auth/invalid-credential': 'Das übergebene Authentifizierungskriterium ist fehlerhaft oder abgelaufen.',
  'auth/invalid-message-payload': 'Die E-Mail-Vorlage, die dieser Aktion entspricht, enthält ungültige Zeichen in ihrer Nachricht. Bitte beheben Sie dies, indem Sie in der Firebase-Konsole den Abschnitt Auth-E-Mail-Vorlagen aufrufen.',
  'auth/invalid-multi-factor-session': 'Die Anfrage enthält keinen gültigen Nachweis für die erfolgreiche Anmeldung mit dem ersten Faktor.',
  'auth/invalid-oauth-provider': 'EmailAuthProvider wird für diesen Vorgang nicht unterstützt. Dieser Vorgang unterstützt nur OAuth-Anbieter.',
  'auth/invalid-oauth-client-id': 'Die angegebene OAuth-Client-ID ist entweder ungültig oder stimmt nicht mit dem angegebenen API-Schlüssel überein.',
  'auth/unauthorized-domain': 'Diese Domäne ist nicht für OAuth-Vorgänge für Ihr Firebase-Projekt autorisiert. Bearbeiten Sie die Liste der autorisierten Domänen in der Firebase-Konsole.',
  'auth/invalid-action-code': 'Der Aktionscode ist ungültig. Dies kann passieren, wenn der Code fehlerhaft ist, abläuft oder bereits verwendet wurde.',
  'auth/wrong-password': 'Die E-Mail oder das Passwort ist ungültig oder der Benutzer hat kein Passwort.',
  'auth/invalid-persistence-type': 'Der angegebene Persistenztyp ist ungültig. Es kann nur lokal, Session oder keine sein.',
  'auth/invalid-phone-number': 'Das Format der angegebenen Rufnummer ist falsch. Bitte geben Sie die Rufnummer in einem Format an, das in das E.164-Format geparst werden kann. E.164-Rufnummern werden im Format [+][Landesvorwahl][Teilnehmernummer inkl. Vorwahl] geschrieben.',
  'auth/invalid-provider-id': 'Die angegebene Provider-ID ist ungültig.',
  'auth/invalid-recipient-email': 'Die zu dieser Aktion gehörende E-Mail konnte nicht gesendet werden, da die angegebene Empfänger-E-Mail-Adresse ungültig ist.',
  'auth/invalid-sender': 'The email template corresponding to this action contains an invalid sender email or name. Please fix this by going to the Auth Email Templates section in the Firebase console.',
  'auth/invalid-verification-id': 'Die Verifizierungs-ID, die zum Erstellen der Authentifizierungsdaten des Telefons verwendet wurde, ist ungültig.',
  'auth/invalid-tenant-id': 'Die Tenant-ID der Auth-Instanz ist ungültig.',
  'auth/multi-factor-info-not-found': 'Der Benutzer verfügt nicht über einen zweiten Faktor, der mit der angegebenen Kennung übereinstimmt.',
  'auth/multi-factor-auth-required': 'Der Nachweis des Besitzes eines zweiten Faktors ist erforderlich, um die Anmeldung abzuschließen.',
  'auth/missing-android-pkg-name': 'Es muss ein Android-Paketname angegeben werden, wenn die Android-App installiert werden soll.',
  'auth/auth-domain-config-required': 'Stellen Sie sicher, dass Sie authDomain beim Aufruf von firebase.initializeApp() einschließen, indem Sie den Anweisungen in der Firebase-Konsole folgen.',
  'auth/missing-app-credential': 'Der Telefonverifizierungsanforderung fehlt eine Anwendungsüberprüfungs-Assertion. Es muss ein reCAPTCHA-Antwort-Token bereitgestellt werden.',
  'auth/missing-verification-code': 'Die Telefonauthentifizierungsdaten wurden mit einem leeren SMS-Verifizierungscode erstellt.',
  'auth/missing-continue-uri': 'In der Anfrage muss eine Fortsezungs-URL angegeben werden.',
  'auth/missing-iframe-start': 'Es ist ein interner Fehler aufgetreten.',
  'auth/missing-ios-bundle-id': 'Eine iOS Bundle ID muss angegeben werden, wenn eine App Store ID angegeben wird.',
  'auth/missing-multi-factor-info': 'Es ist keine zweite Faktor-Kennung vorgesehen.',
  'auth/missing-multi-factor-session': 'Der Anfrage fehlt der Nachweis der erfolgreichen Anmeldung mit dem ersten Faktor.',
  'auth/missing-or-invalid-nonce': 'Die Anforderung enthält keine gültige Nonce. Dies kann auftreten, wenn der SHA-256-Hash der bereitgestellten rohen Nonce nicht mit der gehashten Nonce in der ID-Token-Nutzlast übereinstimmt.',
  'auth/missing-phone-number': 'Um Verifizierungscodes zu senden, geben Sie eine Telefonnummer für den Empfänger an.',
  'auth/missing-verification-id': 'Die Authentifizierungsdaten für das Telefon wurden mit einer leeren Verifizierungs-ID erstellt.',
  'auth/app-deleted': 'Diese Instanz von FirebaseApp wurde gelöscht.',
  'auth/account-exists-with-different-credential': 'Es existiert bereits ein Konto mit der gleichen E-Mail-Adresse, aber anderen Anmeldedaten. Melden Sie sich mit einem Anbieter an, der mit dieser E-Mail-Adresse verknüpft ist.',
  'auth/network-request-failed': 'Ein Netzwerkfehler (z. B. Timeout, unterbrochene Verbindung oder nicht erreichbarer Host) ist aufgetreten.',
  'auth/no-auth-event': 'Ein interner Fehler ist aufgetreten',
  'auth/no-such-provider': 'Der Benutzer war nicht mit einem Konto bei dem angegebenen Anbieter verknüpft.',
  'auth/null-user': 'Ein Null-Benutzerobjekt wurde als Argument für einen Vorgang angegeben, der ein Nicht-Null-Benutzerobjekt erfordert.',
  'auth/operation-not-allowed': 'Der angegebene Sign-in-Provider ist für dieses Firebase-Projekt deaktiviert. Aktivieren Sie ihn in der Firebase-Konsole auf der Registerkarte "sign-in method" im Abschnitt "Auth".',
  'auth/operation-not-supported-in-this-environment': 'Dieser Vorgang wird in der Umgebung, in der diese Anwendung ausgeführt wird, nicht unterstützt. "location.protocol" muss http, https oder chrome-extension sein und der Webspeicher muss aktiviert sein.',
  'auth/popup-blocked': 'Es konnte keine Verbindung mit dem Popup hergestellt werden. Möglicherweise wurde es vom Browser blockiert.',
  'auth/popup-closed-by-user': 'Das Popup wurde vom Benutzer geschlossen, bevor er den Vorgang abgeschlossen hat.',
  'auth/provider-already-linked': 'Ein Benutzer kann nur mit einer Identität für den jeweiligen Provider verknüpft werden.',
  'auth/quota-exceeded': 'Die Quote des Projekts für diesen Vorgang wurde überschritten.',
  'auth/redirect-cancelled-by-user': 'Der Umleitungsvorgang wurde vom Benutzer vor dem Abschluss abgebrochen.',
  'auth/redirect-operation-pending': 'Ein Redirect-Anmeldevorgang steht bereits an.',
  'auth/rejected-credential': 'Die Anfrage enthält fehlerhafte oder nicht übereinstimmende Anmeldedaten.',
  'auth/second-factor-already-in-use': 'Der zweite Faktor ist bereits auf diesem Konto angemeldet.',
  'auth/maximum-second-factor-count-exceeded': 'Die maximal zulässige Anzahl von Sekundenfaktoren für einen Benutzer wurde überschritten.',
  'auth/tenant-id-mismatch': 'Die angegebene Mandantenkennung stimmt nicht mit der Mandantenkennung der Auth-Instanz überein',
  'auth/timeout': 'Der Vorgang hat eine Zeitüberschreitung',
  'auth/user-token-expired': 'Die Anmeldeinformationen des Benutzers sind nicht mehr gültig. Der Benutzer muss sich erneut anmelden.',
  'auth/too-many-requests': 'Der Zugriff auf dieses Konto wurde aufgrund vieler fehlgeschlagener Anmeldeversuche vorübergehend deaktiviert. Sie können ihn sofort wiederherstellen, indem Sie Ihr Passwort zurücksetzen, oder Sie können es später erneut versuchen.',
  'auth/unauthorized-continue-uri': 'Die Domain der Fortsetzungs-URL ist nicht auf der Whitelist.  Bitte whitelisten Sie die Domain in der Firebase-Konsole.',
  'auth/unsupported-first-factor': 'Das Aktivieren eines zweiten Faktors oder die Anmeldung mit einem Multi-Faktor-Konto erfordert die Anmeldung mit einem unterstützten ersten Faktor.',
  'auth/unsupported-persistence-type': 'Dieser Vorgang wird in einem Multi-Tenant-Kontext nicht unterstützt.',
  'auth/unsupported-tenant-operation': 'Dieser Vorgang wird in einem Multi-Tenant-Kontext nicht unterstützt.',
  'auth/unverified-email': 'Der Vorgang erfordert eine verifizierte E-Mail.',
  'auth/user-cancelled': 'Der Benutzer hat Ihrer Anwendung nicht die angeforderten Berechtigungen erteilt.',
  'auth/user-not-found': 'Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer kann gelöscht worden sein.',
  'auth/user-disabled': 'Die angegebenen Anmeldeinformationen entsprechen nicht dem zuvor angemeldeten Benutzer.',
  'auth/user-mismatch': 'Die angegebenen Anmeldeinformationen entsprechen nicht dem zuvor angemeldeten Benutzer.',
  'auth/user-signed-out': '',
  'auth/weak-password': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
  'auth/web-storage-unsupported': 'Dieser Browser wird nicht unterstützt oder Cookies und Daten von Drittanbietern können deaktiviert werden.'
}), (0, _defineProperty2.default)(_page$login$register$, "expo", {
  visitBooth: 'Messestand besuchen',
  booth: 'Messestand',
  files: 'Dateien',
  noFiles: 'Keine Dateien...',
  registerMessage: 'Mit der Registrierung Ihres Interesses nehmen Sie deren Angebot in Anspruch und Ihre Kontaktdaten werden an den Partner zur Vertragserfüllung gesendet'
}), (0, _defineProperty2.default)(_page$login$register$, "agora", {
  DEVICE_NOT_FOUND: 'Keine Geräte oder das zuvor ausgewählte Gerät nicht gefunden. Bitte wählen Sie ein anderes Gerät oder stecken Sie ein Gerät ein!',
  PERMISSION_DENIED: 'Zugriff auf das Mediengerät fehlgeschlagen. Klicken Sie im Popup-Fenster zum Erlangen von Geräteberechtigungen auf "Zulassen"!',
  DEFAULT_DEVICE_IN_USE: 'Ein anderes Programm verwendet Ihre Kamera oder Ihr Mikrofon. Bitte schließen Sie dieses Programm und aktualisieren Sie diese Seite.',
  'AgoraRTCError UNEXPECTED_ERROR: NotReadableError: Could not start video source': 'Ein anderes Programm verwendet Ihre Kamera oder Ihr Mikrofon. Bitte schließen Sie dieses Programm und aktualisieren Sie diese Seite.'
}), (0, _defineProperty2.default)(_page$login$register$, "chat", {
  chat: 'Chat',
  deleteComment: 'Kommentar blockieren',
  blockUser: 'User blockieren',
  unblockUser: 'Unser blockierung aufheben',
  people: 'Personen',
  abuse: 'Missbrauch',
  youBlockedFromChat: 'Ihr Zugang zum Chat wurde vom Veranstalter gesperrt.',
  youDisconnectedFromChat: 'Die Verbindung zum Chat wurde unterbrochen. Bitte versuchen Sie es erneut!',
  youBlockedFromQA: 'Ihr Zugriff auf den Q&A-Bereich wurde vom Veranstalter gesperrt.',
  youDisconnectedFromQA: 'Die Verbindung zum Q&A Bereich ist abgebrochen. Bitte versuche es erneut!',
  retry: 'Erneut versucen',
  youNotInChatRoom: 'Sie sind nicht verbunden oder Ihr Firewall blockiert die Verbindung!',
  firewallClickHere: 'Klicken Sie hier',
  firewallInstructions: ' fuer die Firewall Einstellungsanweisungen.',
  join: 'Erneut versuchen',
  writeAComment: 'Noch keine Kommentare. Hinterlasse jetzt einen Kommentar!',
  writeAQuestion: 'Noch keine Fragen. Stellen Sie eine Frage!',
  yourQuestion: 'Deine Frage...',
  saySomethingNice: 'Sagen Sie etwas nettes',
  noPermission: 'Sie haben keine Berechtigung!',
  noUsers: 'Keine User online',
  noBlockedUsers: 'Keine blockierten User',
  sending: 'Wird gesendet...',
  deleting: 'Wird gelöscht...',
  reconnecting: 'Verbindung wird hergestellt...',
  unreadMessage: 'neue Nachricht',
  unreadMessages: 'neue Nachricht',
  position: {
    organizer: 'ORGANISATOR'
  },
  disabledMessage: 'Der Chat wurde vom Veranstalter deaktiviert.'
}), (0, _defineProperty2.default)(_page$login$register$, "menuItems", {
  chat: 'Chat',
  qa: 'Q & A',
  people: 'Personen',
  files: 'Dateien',
  abuse: 'Missbrauch',
  booth: 'Messestände',
  polls: 'Umfrage',
  backstageChat: 'Backstage Chat',
  requests: 'Anfragen'
}), (0, _defineProperty2.default)(_page$login$register$, "polls", {
  yourPolls: 'Deine Umfragen',
  addNewPoll: 'Neue Umfrage erstellen',
  backToPollsList: 'Zurück zu meinen Umfragen',
  respondToPolls: 'Wir laden Sie ein, auf unsere Umfrage(n) unten zu antworten:',
  alreadyVoted: 'Sie haben bereits für diese Umfrage gestimmt. Vielen Dank!',
  noVotesYet: 'Es sind noch keine Stimmen zu dieser Umfrage vorhanden. Seien Sie der Erste, der abstimmt.',
  deleteConfirmText: 'Sind Sie sicher, dass Sie diese Umfrage löschen möchten?',
  deleteConfirmTextOptions: 'Sind Sie sicher, dass Sie diese Option aus Ihrer Umfrage löschen möchten?',
  confirmDelete: 'Ja, löschen',
  cancelDelete: 'Abbrechen',
  noPollsYet: 'Es sind noch keine Umfragen vom Organisator dieser Veranstaltung erstellt worden.',
  new: {
    title: 'Der Titel meiner Umfrage',
    titlePlaceholder: 'Meine Umfrage',
    option: 'Hinzufügen einer Option zu Ihrer Umfrage',
    titleMin2: 'Der Titel muss mindestens 2 Zeichen enthalten.',
    titleMax150: 'Der Titel darf maximal 150 Zeichen enthalten.',
    titleRequired: 'Sie müssen einen Titel für Ihre Umfrage hinzufügen.',
    minOptionLength2: 'Die Option muss mindestens 2 Zeichen enthalten.',
    maxOptionLength100: 'Die Option darf maximal 100 Zeichen enthalten.',
    minOptions2: 'Die Umfrage muss mindestens 2 Optionen enthalten.',
    maxOptions10: 'Die Umfrage darf maximal 10 Optionen enthalten.',
    save: 'Diese Umfrage speichern',
    numberOfOptions: 'Die Anzahl der Umfrageoptionen',
    op: 'Option',
    invalidFormatTitle: 'Ungültiges Format. Es sind nur Buchstaben, Zahlen und einige Zeichen erlaubt.',
    invalidFormatOption: 'Ungültiges Format. Es sind nur Buchstaben, Zahlen und einige Zeichen erlaubt.',
    containsDuplicates: 'Doppelte Optionen sind in den Umfragen nicht erlaubt.'
  },
  update: {
    update: 'Diese Umfrage aktualisieren',
    optionAlreadyExists: 'Diese Option existiert bereits bei dieser Umfrage.'
  }
}), (0, _defineProperty2.default)(_page$login$register$, "calendar", {
  liveNow: 'Live Now',
  upcoming: 'Bevorstehende',
  yourSession: 'Your Session',
  startsAt: 'Starts in',
  endsIn: 'Ends In',
  networkingTextActive: 'Der Veranstalter veranstaltet eine Networking Session.',
  networkingTextupcoming: 'Der Veranstalter veranstaltet eine Networking Session.',
  errors: {
    eventNotStartedYet: 'The event is not started yet.',
    eventFinished: 'The event has finished. Thank you.',
    noSessionsForToday: 'There are no available sessions for this day on this stage.'
  }
}), (0, _defineProperty2.default)(_page$login$register$, "requests", {
  admit: 'Zulassen',
  isTheStageManager: 'ist der Bühnenmanager'
}), (0, _defineProperty2.default)(_page$login$register$, "january", 'Januar'), (0, _defineProperty2.default)(_page$login$register$, "february", 'Februar'), (0, _defineProperty2.default)(_page$login$register$, "march", 'März'), (0, _defineProperty2.default)(_page$login$register$, "april", 'April'), (0, _defineProperty2.default)(_page$login$register$, "may", 'Mai'), (0, _defineProperty2.default)(_page$login$register$, "june", 'Juni'), (0, _defineProperty2.default)(_page$login$register$, "july", 'Juli'), (0, _defineProperty2.default)(_page$login$register$, "august", 'August'), (0, _defineProperty2.default)(_page$login$register$, "september", 'September'), (0, _defineProperty2.default)(_page$login$register$, "october", 'Oktober'), (0, _defineProperty2.default)(_page$login$register$, "november", 'November'), (0, _defineProperty2.default)(_page$login$register$, "december", 'Dezember'), (0, _defineProperty2.default)(_page$login$register$, "monday", 'Montag'), (0, _defineProperty2.default)(_page$login$register$, "tuesday", 'Dienstag'), (0, _defineProperty2.default)(_page$login$register$, "wednesday", 'Mittwoch'), (0, _defineProperty2.default)(_page$login$register$, "thursday", 'Donnerstag'), (0, _defineProperty2.default)(_page$login$register$, "friday", 'Freitag'), (0, _defineProperty2.default)(_page$login$register$, "saturday", 'Samstag'), (0, _defineProperty2.default)(_page$login$register$, "sunday", 'Sonntag'), (0, _defineProperty2.default)(_page$login$register$, "profile", {
  displayName: 'Ganzer Name',
  headline: 'Headline',
  bio: 'Bio',
  email: 'E-Mail',
  linkedin: 'LinkedIn',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  changeAvatar: 'Profilbild ändern',
  phone: 'Telefonnummer',
  firstNameRequired: 'Das Feld "Vorname" darf nicht leer stehen.',
  lastNameRequired: 'Das Feld "Nachname" darf nicht leer stehen.',
  headlineRequired: 'Das Feld "Überschrift" darf nicht leer stehen.',
  bioRequired: 'Das Feld "Bio" darf nicht leer stehen.',
  success: 'Profildetails erfolgreich gespeichert.',
  phoneInvalid: 'Die eingegebene Telefonnummer ist ungültig.',
  max800: 'Darf nicht länger als 800 Zeichen sein.',
  max100: 'Darf nicht länger als 100 Zeichen sein.',
  max50: 'Darf nicht länger als 50 Zeichen sein.',
  instagramPlaceholder: 'Ex: https://www.instagram.com/your-instagram-page',
  facebookPlaceholder: 'Ex: https://www.facebook.com/your-facebook-page',
  linkedinPlaceholder: 'Ex: https://www.linkedin.com/your-linkedin-page',
  twitterPlaceholder: 'Ex: https://twitter.com/your-twitter-page'
}), _page$login$register$);

exports.default = _default;