var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AnimatedLayer;

var _react = _interopRequireWildcard(require("react"));

var _konva = _interopRequireDefault(require("konva"));

var _reactKonva = require("react-konva");

var _logger = _interopRequireDefault(require("../../../../core/services/logger"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function AnimatedLayer(_ref) {
  var children = _ref.children,
      stream = _ref.stream,
      fps = _ref.fps;
  var layerRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    if (!layerRef.current) return;
    if (!stream) return;
    var anim = new _konva.default.Animation(function () {}, layerRef.current);
    anim.start();

    _logger.default.debug('Canvas rendering started!');

    layerRef.current.getCanvas()._canvas.captureStream(fps).getTracks().forEach(function (track) {
      stream.addTrack(track);

      _logger.default.debug('Canvas track added!');
    });

    _logger.default.debug('Canvas stream capturing!');

    return function () {
      anim.stop();
    };
  }, [stream, fps]);
  return _react.default.createElement(_reactKonva.Layer, {
    ref: layerRef
  }, children);
}