var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LinkButton;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _lodash = _interopRequireDefault(require("lodash"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Typography = _interopRequireDefault(require("../typography/Typography"));

function LinkButton(_ref) {
  var children = _ref.children,
      onPress = _ref.onPress,
      disabled = _ref.disabled,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'link' : _ref$color,
      _ref$hoverColor = _ref.hoverColor,
      hoverColor = _ref$hoverColor === void 0 ? 'linkHover' : _ref$hoverColor,
      bold = _ref.bold,
      style = _ref.style,
      innerKey = _ref.innerKey;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      links = _useTheme.links;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      hover = _React$useState2[0],
      setHover = _React$useState2[1];

  var activeColor = hover ? hoverColor : color;
  var buttonStyle = {
    textDecorationLine: hover ? links.hoverDecoration : links.decoration,
    textDecorationColor: colors[activeColor] || activeColor,
    fontSize: 'inherit',
    fontWeight: bold ? 'bold' : 'normal'
  };
  return _react.default.createElement(_reactNative.TouchableHighlight, (0, _extends2.default)({}, _reactNative.Platform.select({
    web: {
      onMouseOver: function onMouseOver() {
        return setHover(true);
      },
      onMouseOut: function onMouseOut() {
        return setHover(false);
      }
    }
  }), {
    underlayColor: "#FFFFFF22",
    disabled: disabled,
    onPress: onPress,
    style: style
  }), _react.default.createElement(_reactNative.View, {
    key: innerKey
  }, _lodash.default.isString(children) ? _react.default.createElement(_Typography.default, {
    style: buttonStyle,
    color: colors[activeColor] || activeColor
  }, children) : _lodash.default.isFunction(children) ? children(buttonStyle, colors[activeColor] || activeColor) : children));
}