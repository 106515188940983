var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _leaderboardScreenContainer = _interopRequireDefault(require("./leaderboard-screen-container"));

function mapStateToProps(state) {
  return {
    leaderboard: _gameSparks.default.selectors.getLeaderboard(state),
    totalCount: _gameSparks.default.selectors.getLeaderboardTotal(state),
    isLastMonthLeaderboard: _gameSparks.default.selectors.isLastMonthLeaderboard(state),
    isLeaderboardLoading: _gameSparks.default.selectors.isLeaderboardLoading(state),
    totalPrize: _gameSparks.default.selectors.getTotalPrize(state),
    leaderboardUnit: _gameSparks.default.selectors.getLeaderboardUnit(state),
    learnMoreScreen: _gameSparks.default.selectors.getLeaderboarLearnMore(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadLeaderboard: _gameSparks.default.actions.loadLeaderboardData,
  loadAroundMeList: _gameSparks.default.actions.loadAroundMeList,
  getGsPropertySet: _gameSparks.default.actions.getPropertySet
})(_leaderboardScreenContainer.default);

exports.default = _default;