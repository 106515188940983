var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CheckoutContainer;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _ContainedButton = _interopRequireDefault(require("../../theme/components/buttons/ContainedButton"));

var _reactNative = require("react-native");

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _reactRedux = require("react-redux");

var _actions = require("../../core/checkout/actions");

var _SelectTicket = _interopRequireDefault(require("./ticket/SelectTicket"));

var _Ticket = _interopRequireDefault(require("./ticket/Ticket"));

var _Input = _interopRequireDefault(require("../../theme/components/inputs/Input"));

var _selectors = require("../../core/checkout/selectors");

var _selectors2 = require("../../core/event-info/selectors");

var _DesignSystem = require("../../theme/DesignSystem");

var _reactFeather = require("react-feather");

var _baseSelect = _interopRequireDefault(require("../../theme/components/inputs/baseSelect.web"));

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

var _actions2 = require("../../core/global/actions");

var _eventRegistration = _interopRequireDefault(require("../event-registration"));

var _useNavigationExtended = _interopRequireDefault(require("../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../navigation/client/routes-consts");

var _actions3 = require("../../core/tickets/actions");

var _actions4 = require("../../core/session/actions");

function CheckoutContainer(_ref) {
  var poster = _ref.poster,
      _ref$landingPage = _ref.landingPage,
      landingPage = _ref$landingPage === void 0 ? false : _ref$landingPage,
      callbackUrl = _ref.callbackUrl,
      _ref$useModalCheckout = _ref.useModalCheckout,
      useModalCheckout = _ref$useModalCheckout === void 0 ? true : _ref$useModalCheckout;
  var classes = (0, _styles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var navigation = (0, _useNavigationExtended.default)();
  var tickets = (0, _reactRedux.useSelector)(_selectors.validTicketsSelector);
  var checkoutDetails = (0, _reactRedux.useSelector)(_selectors2.getCheckoutDetails);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.checkoutLoadingSelector);
  var title = (checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.title) || null;
  var description = (checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.description) || null;
  var freeButtonLabel = checkoutDetails !== null && checkoutDetails !== void 0 && checkoutDetails.registerFreeButtonLabel && (checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.registerFreeButtonLabel) !== '' ? checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.registerFreeButtonLabel : _i18n.default.t('checkout.freeRegiser');
  var paidButtonLabel = checkoutDetails !== null && checkoutDetails !== void 0 && checkoutDetails.buyTicketButtonLabel && (checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.buyTicketButtonLabel) !== '' ? checkoutDetails === null || checkoutDetails === void 0 ? void 0 : checkoutDetails.buyTicketButtonLabel : _i18n.default.t('checkout.buyTicket');

  var _React$useState = _react.default.useState({
    code: '',
    ticketId: null
  }),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      promoCode = _React$useState2[0],
      setPromoCode = _React$useState2[1];

  var matchPromoCode = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.matchPromoCodeSelector)(state, promoCode);
  });
  var hasPromoCodes = (0, _reactRedux.useSelector)(_selectors.hasPromoCodesSelector);
  var error = (0, _reactRedux.useSelector)(_selectors.selectCheckoutError);
  var onMobileLayout = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.smd;
  }) && landingPage;
  var onDesktopLayout = !onMobileLayout;
  var smallHeight = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.HEIGHT, _DesignSystem.MediaConstants.operator.DOWN, function (_ref3) {
    var breakpoints = _ref3.breakpoints;
    return breakpoints.mmd;
  });
  var useDropdownTickets = ((0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref4) {
    var breakpoints = _ref4.breakpoints;
    return breakpoints.lg;
  }) || smallHeight) && landingPage && tickets.length > 1;

  _react.default.useEffect(function () {
    dispatch((0, _actions.resetSelectedAddonVariations)());
  }, [dispatch]);

  return _react.default.createElement(_core.Box, {
    component: _core.Card,
    className: (0, _clsx.default)(classes.container, landingPage ? classes.landingPage : null),
    elevation: 0,
    display: "flex",
    flexDirection: "column"
  }, _react.default.createElement(_core.Box, {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    className: classes.minHeightContainer
  }, onDesktopLayout && poster, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    className: classes.background,
    m: onMobileLayout ? 1 : 3,
    alignItems: "stretch"
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "1",
    className: classes.minHeightContainer
  }, _react.default.createElement(_SelectTicket.default, {
    tickets: tickets
  }, function (_ref5) {
    var _tickets = _ref5.tickets,
        selectedTicket = _ref5.selectedTicket,
        onSelect = _ref5.onSelect;
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      overflow: "auto",
      className: classes.minHeightContainer
    }, onDesktopLayout && _react.default.createElement(_core.Box, {
      display: "flex",
      alignItems: "flex-start"
    }, _react.default.createElement(_core.Box, {
      className: classes.ticketIcon
    }, _react.default.createElement(_reactNative.Image, {
      source: {
        uri: 'https://firebasestorage.googleapis.com/v0/b/dev-eventmix.appspot.com/o/assets%2Ftickets.svg?alt=media&token=9ba7f33e-1372-480b-977b-7ddcb092eaae'
      },
      resizeMode: "contain"
    })), _react.default.createElement(_core.Box, {
      flex: "1",
      mb: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }, _react.default.createElement(_core.Typography, {
      variant: "h3"
    }, title || _i18n.default.t('checkout.eventRegistration')), description && _react.default.createElement(_core.Box, {
      display: "flex",
      flexWrap: "wrap",
      mt: 2
    }, _react.default.createElement(_core.Typography, {
      variant: "caption"
    }, description)) || null)), useDropdownTickets ? _react.default.createElement(_core.Box, {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      flexDirection: "column",
      mb: 1
    }, _react.default.createElement(_baseSelect.default, {
      rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null),
      options: _tickets.map(function (ticket) {
        var ticketLabel = ticket.parsedPriceLabel ? "(".concat(ticket.parsedPriceLabel, ")") : '';
        var ticketText = "".concat(ticket.name, " ").concat(ticketLabel);
        return {
          id: ticket.id,
          disabled: ticket.disabled,
          value: ticketText
        };
      }),
      value: selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.id,
      onChange: function onChange(id) {
        var selected = _tickets.filter(function (ticket) {
          return ticket.id === id;
        });

        if (selected && selected[0]) {
          onSelect(selected[0]);
        }
      },
      name: "ticketOptions",
      hasEmptyOption: false,
      color: "alternativeText"
    })) : _react.default.createElement(_core.Box, {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "space-between"
    }, _tickets.map(function (ticket) {
      return _react.default.createElement(_Ticket.default, (0, _extends2.default)({
        onPress: function onPress() {
          if (ticket !== selectedTicket) {
            setPromoCode({
              ticketId: ticket.id,
              code: ''
            });
          }

          onSelect(ticket);
        }
      }, ticket, {
        disabled: ticket.disabled,
        selected: ticket.id === (selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.id),
        key: ticket.id
      }));
    })), useModalCheckout && selectedTicket && selectedTicket.parsedPriceLabel && hasPromoCodes && _react.default.createElement(_core.Box, {
      m: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      error: !matchPromoCode && !!promoCode.code.length,
      value: promoCode.code,
      style: matchPromoCode && promoCode.code.length ? {
        outlineColor: 'green',
        borderColor: 'green'
      } : {},
      onChange: function onChange(e) {
        setPromoCode({
          ticketId: (selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.id) || null,
          code: e.target.value
        });
      },
      placeholder: _i18n.default.t('checkout.promoCode'),
      type: "text"
    })) || null), _react.default.createElement(_core.Box, {
      my: useDropdownTickets ? 0 : 0.5,
      mx: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_ContainedButton.default, {
      disabled: !selectedTicket || !matchPromoCode && !!promoCode.code.length || isLoading,
      size: "large",
      onPress: function onPress() {
        dispatch((0, _actions2.openDialog)({
          id: 'register',
          content: _react.default.createElement(_eventRegistration.default, {
            initialTicketId: selectedTicket === null || selectedTicket === void 0 ? void 0 : selectedTicket.id,
            closeDialog: function closeDialog() {
              dispatch((0, _actions2.closeDialog)('register'));
            },
            callBacks: {
              goToLogin: function goToLogin() {
                navigation.navigate(_routesConsts.Routes.LOGIN);
              },
              onPaymentSuccess: function () {
                var _onPaymentSuccess = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
                  return _regenerator.default.wrap(function _callee$(_context) {
                    while (1) {
                      switch (_context.prev = _context.next) {
                        case 0:
                          _context.next = 2;
                          return dispatch((0, _actions3.getTickets)());

                        case 2:
                          _context.next = 4;
                          return dispatch((0, _actions4.getSessions)());

                        case 4:
                        case "end":
                          return _context.stop();
                      }
                    }
                  }, _callee);
                }));

                function onPaymentSuccess() {
                  return _onPaymentSuccess.apply(this, arguments);
                }

                return onPaymentSuccess;
              }()
            }
          })
        }));
      }
    }, isLoading ? _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: "#fff"
    }) : selectedTicket && selectedTicket.type === 'Free' ? freeButtonLabel : paidButtonLabel)));
  }))), error && _react.default.createElement(_core.Typography, {
    variant: "body1",
    color: "error",
    align: "center"
  }, error.message ? error.message : _i18n.default.t('errors.somethingWentWrong'))));
}