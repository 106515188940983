Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconSize = exports.iconColor = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  artworkContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 24
  }
});

exports.default = _default;
var iconColor = 'rgba(200,255,255,0.2)';
exports.iconColor = iconColor;
var iconSize = 200;
exports.iconSize = iconSize;