var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WaitForAllowButton;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _DesignSystem = require("../../../theme/DesignSystem");

function WaitForAllowButton() {
  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.md;
  });
  if (match) return _react.default.createElement(_ColoredIconButton.default, {
    customColor: colors.successColor,
    onClick: function onClick() {},
    disabled: true
  }, _react.default.createElement(_core.CircularProgress, {
    size: 20,
    color: "secondary",
    style: {
      color: '#fff'
    }
  }));
  return _react.default.createElement(_ContainedButton.default, {
    iconLeft: _react.default.createElement(_core.CircularProgress, {
      size: 20,
      color: "secondary",
      style: {
        color: '#fff'
      }
    }),
    onPress: function onPress() {},
    disabled: true,
    style: {
      backgroundColor: colors.successColor
    }
  }, _i18n.default.translate('backstage.waitingForApproval'));
}