var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MyBeatsScreenView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

var _mybeatsCardView = _interopRequireDefault(require("./content/mybeats-card-view"));

var _inviteFansCardView = _interopRequireDefault(require("./content/invite-fans-card-view"));

var _inviteDjCardView = _interopRequireDefault(require("./content/invite-dj-card-view"));

var _goLiveCardView = _interopRequireDefault(require("./content/go-live-card-view"));

var _Stack = _interopRequireDefault(require("../../../design-system/structure/Stack"));

var _headingView = _interopRequireDefault(require("../../../design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("../../../design-system/typography/subheading-view"));

var _section = _interopRequireDefault(require("../../../design-system/structure/section"));

var _mybeatsScreenStyles = _interopRequireDefault(require("./mybeats-screen-styles"));

function MyBeatsScreenView(props) {
  var userId = props.userId,
      userName = props.userName,
      beatsThisMonth = props.beatsThisMonth,
      beatsBalance = props.beatsBalance,
      totalBalance = props.totalBalance,
      referralsBeats = props.referralsBeats,
      referralsBeatsDj = props.referralsBeatsDj,
      referralsMessage = props.referralsMessage,
      referralsDjMessage = props.referralsDjMessage,
      liveReferralsBeats = props.liveReferralsBeats,
      liveReferralsMessage = props.liveReferralsMessage;
  return _react.default.createElement(_reactNativeSafeAreaView.default, {
    style: [_mybeatsScreenStyles.default.container],
    forceInset: {
      top: 'always',
      bottom: 'always',
      horizontal: 'always'
    },
    testID: "MyBeatsScreenContainer"
  }, _react.default.createElement(_reactNative.ScrollView, {
    showsVerticalScrollIndicator: false
  }, _react.default.createElement(_section.default, {
    spacing: "base",
    spacingBottom: "none"
  }, _react.default.createElement(_mybeatsCardView.default, {
    userId: userId,
    userName: userName,
    beatsThisMonth: beatsThisMonth,
    beatsBalance: beatsBalance,
    totalBalance: totalBalance
  })), _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_headingView.default, null, "Get more Beats"))), _react.default.createElement(_section.default, {
    spacingTop: "loose"
  }, _react.default.createElement(_Stack.default, {
    vertical: true
  }, _react.default.createElement(_section.default, {
    spacingHorizontal: "base"
  }, _react.default.createElement(_subheadingView.default, null, "Bring your friends on Soundmix")), _react.default.createElement(_reactNative.ScrollView, {
    horizontal: true,
    directionalLockEnabled: true,
    showsHorizontalScrollIndicator: false,
    snapToAlignment: "start",
    snapToInterval: 296,
    contentContainerStyle: [_mybeatsScreenStyles.default.spacingHorizontal]
  }, _react.default.createElement(_Stack.default, null, referralsBeats !== (undefined || 0) ? _react.default.createElement(_inviteFansCardView.default, {
    userId: props.userId,
    userName: props.userName,
    avatar: props.avatar,
    bio: props.bio,
    referralsBeats: referralsBeats,
    referralsMessage: referralsMessage
  }) : _react.default.createElement(_react.default.Fragment, null), referralsBeatsDj !== (undefined || 0) ? _react.default.createElement(_inviteDjCardView.default, {
    userId: props.userId,
    userName: props.userName,
    avatar: props.avatar,
    bio: props.bio,
    referralsBeats: referralsBeats,
    referralsBeatsDj: referralsBeatsDj,
    referralsDjMessage: referralsDjMessage
  }) : _react.default.createElement(_react.default.Fragment, null))))), liveReferralsBeats !== (undefined || 0) ? _react.default.createElement(_section.default, {
    spacing: "base"
  }, _react.default.createElement(_Stack.default, {
    vertical: true
  }, _react.default.createElement(_subheadingView.default, null, "Create engaging content"), _react.default.createElement(_goLiveCardView.default, {
    userId: props.userId,
    liveReferralsBeats: liveReferralsBeats,
    liveReferralsMessage: liveReferralsMessage
  }))) : null));
}