var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SplashScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _lottieReactNative = _interopRequireDefault(require("lottie-react-native"));

var _reactNativeSafeAreaView = _interopRequireDefault(require("react-native-safe-area-view"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var SplashScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(SplashScreenInternal, _React$PureComponent);

  var _super = _createSuper(SplashScreenInternal);

  function SplashScreenInternal() {
    (0, _classCallCheck2.default)(this, SplashScreenInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(SplashScreenInternal, [{
    key: "render",
    value: function render() {
      var colors = this.props.theme.colors;
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.StatusBar, {
        barStyle: "light-content",
        hidden: false,
        backgroundColor: "transparent",
        translucent: true
      }), _react.default.createElement(_reactNativeSafeAreaView.default, {
        style: [styles.container, {
          backgroundColor: colors.background
        }],
        forceInset: {
          top: 'always',
          bottom: 'always'
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.logo
      }, _react.default.createElement(_reactNative.View, {
        style: {
          position: 'absolute',
          bottom: 80,
          right: 36
        }
      }), _react.default.createElement(_lottieReactNative.default, {
        source: require('./common/logo/LogoAnimation_02.json'),
        style: {},
        autoPlay: true,
        loop: true
      }))));
    }
  }]);
  return SplashScreenInternal;
}(_react.default.PureComponent);

exports.SplashScreenInternal = SplashScreenInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  logo: {
    height: 280,
    width: 239
  }
});

var _default = (0, _reactNativePaper.withTheme)(SplashScreenInternal);

exports.default = _default;