var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ThankyouPopupInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _reactNativeRenderHtml = _interopRequireDefault(require("react-native-render-html"));

var _Button = _interopRequireDefault(require("./Button"));

var _CustomCachedImage = _interopRequireDefault(require("./CustomCachedImage"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ThankyouPopupInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ThankyouPopupInternal, _React$PureComponent);

  var _super = _createSuper(ThankyouPopupInternal);

  function ThankyouPopupInternal() {
    (0, _classCallCheck2.default)(this, ThankyouPopupInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(ThankyouPopupInternal, [{
    key: "render",
    value: function render() {
      var _this = this;

      var ticket = this.props.ticket;
      var image = ticket.get('image').get('contentUrl');
      var description = ticket.get('description');
      var thankYouTitle = ticket.get('thankYouTitle');
      var thankYouMessage = ticket.get('thankYouMessage');
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.card
      }, _react.default.createElement(_reactNative.View, {
        style: styles.thankYouTitleParent
      }, _react.default.createElement(_reactNative.Text, {
        style: styles.thankYouTitleText
      }, thankYouTitle)), image && _react.default.createElement(_CustomCachedImage.default, {
        style: styles.image,
        source: {
          uri: image
        },
        resizeMode: "cover"
      }), _react.default.createElement(_reactNative.View, {
        style: styles.infoDetails
      }, thankYouMessage && _react.default.createElement(_reactNative.View, {
        style: {
          paddingVertical: 8,
          paddingHorizontal: 32
        }
      }, _react.default.createElement(_reactNative.Text, {
        style: styles.thankYouMessage
      }, thankYouMessage)), description && _react.default.createElement(_reactNative.View, {
        style: styles.descriptionText
      }, _react.default.createElement(_reactNativeRenderHtml.default, {
        html: description,
        tagsStyles: {
          p: {
            color: 'black',
            fontSize: 15,
            lineHeight: 23,
            textAlign: 'center'
          }
        }
      }))), _react.default.createElement(_reactNative.View, {
        style: styles.bottomParent
      })), _react.default.createElement(_Button.default, {
        mode: "outlined",
        testID: "goBackButton",
        onPress: function onPress() {
          return _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());
        },
        style: {
          marginTop: 24
        },
        buttonSize: "small",
        color: "#ffffff",
        dark: false
      }, 'Back')));
    }
  }]);
  return ThankyouPopupInternal;
}(_react.default.PureComponent);

exports.ThankyouPopupInternal = ThankyouPopupInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  card: {
    flexDirection: 'column',
    width: '80%',
    borderRadius: 8
  },
  thankYouTitleParent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFE10D',
    paddingVertical: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  thankYouTitleText: {
    color: 'black',
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: -0.3
  },
  image: {
    height: 150,
    width: '100%'
  },
  infoDetails: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12
  },
  thankYouMessage: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
    textAlign: 'center'
  },
  descriptionText: {
    paddingVertical: 8,
    opacity: 0.5,
    paddingBottom: 20,
    letterSpacing: -0.3
  },
  bottomParent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFE10D',
    paddingVertical: 12,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
});

var _default = (0, _reactRedux.connect)(null, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(ThankyouPopupInternal)));

exports.default = _default;