Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textTruncate: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    badgeText: {
      color: theme.palette.buttonText.main,
      fontSize: '0.55rem'
    },
    userNameText: {
      fontWeight: 'bold',
      opacity: 0.7
    },
    elapsedTimeText: {
      opacity: 0.7,
      fontWeight: 'bold',
      marginLeft: 5,
      whiteSpace: 'nowrap'
    },
    roleSplitText: {
      fontSize: '0.6rem'
    }
  };
});
var _default = styles;
exports.default = _default;