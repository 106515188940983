var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackstageTooltip;

var _react = _interopRequireDefault(require("react"));

var _styles = require("@material-ui/core/styles");

var _Tooltip = _interopRequireDefault(require("@material-ui/core/Tooltip"));

var LightTooltip = (0, _styles.withStyles)(function (theme) {
  return {
    popper: {
      margin: function margin(_ref) {
        var distance = _ref.distance;
        return distance ? distance : 0;
      },
      filter: 'drop-shadow(#444 0px 0px 2px)'
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      maxWidth: 500
    },
    arrow: {
      color: theme.palette.common.white
    }
  };
})(_Tooltip.default);

function BackstageTooltip(_ref2) {
  var children = _ref2.children,
      tooltipContent = _ref2.tooltipContent,
      open = _ref2.open,
      distance = _ref2.distance,
      placement = _ref2.placement,
      arrow = _ref2.arrow;
  return _react.default.createElement(LightTooltip, {
    title: tooltipContent,
    open: open,
    placement: placement,
    arrow: arrow,
    distance: distance,
    interactive: true
  }, children);
}