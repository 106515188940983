Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countdownSize = exports.countdownColor = exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  announcingWinnersTitle: {
    fontWeight: 'bold'
  },
  leaderboardCountdown: {
    fontWeight: 'bold'
  }
});

exports.default = _default;
var countdownColor = '#F8E71B';
exports.countdownColor = countdownColor;
var countdownSize = 17;
exports.countdownSize = countdownSize;