var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/networking/selectors");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../theme/DesignSystem");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _actions = require("../../../core/networking/actions");

var _networkingTableLivePageStyles = _interopRequireDefault(require("./networking-table-live-page-styles"));

var _partners = _interopRequireDefault(require("../../partners"));

var _networkingTableLiveView = _interopRequireDefault(require("./networking-table-live-view"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _selectors2 = require("../../../core/event-info/selectors");

var _sessions = _interopRequireDefault(require("../../sessions"));

var _userActivityConstants = require("../../../constants/user-activity-constants");

var _actions2 = require("../../../core/user/actions");

var _networkingTableMenu = _interopRequireDefault(require("../networking-table-page/networking-table-menu"));

var NetworkingTableLivePageContainer = function NetworkingTableLivePageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var tableId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TABLE_ID);
  var event = (0, _reactRedux.useSelector)(_selectors2.selectEventInfo);
  var dispatch = (0, _reactRedux.useDispatch)();
  var table = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.tableByIdSelector)(state, tableId);
  });
  var isLoading = (0, _reactRedux.useSelector)(_selectors.networkingLoadingSelector);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.networkingLoadedSelector);

  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      navigate = _useNavigationExtende.navigate;

  var Styles = (0, _networkingTableLivePageStyles.default)();

  var join = function join() {
    return navigate(_routesConsts.Routes.NETWORKING_TABLE_PAGE, {
      tableId: tableId
    });
  };

  var leave = function leave() {
    navigate(_routesConsts.Routes.NETWORKING_PAGE);
  };

  _react.default.useEffect(function () {
    var unsubscribe = function unsubscribe() {};

    (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var _yield$dispatch, payload;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions.subscribeTableChangeById)(tableId));

            case 2:
              _yield$dispatch = _context.sent;
              payload = _yield$dispatch.payload;
              unsubscribe = payload;

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))();
    return function () {
      unsubscribe();
    };
  }, []);

  var mobileResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });

  _react.default.useEffect(function () {
    (0, _actions2.trackUserActivity)({
      event: event,
      eventType: _userActivityConstants.TRACKABLE_EVENTS.NETWORKING_ROOM_VIEWED,
      networking: {
        name: table === null || table === void 0 ? void 0 : table.name,
        id: tableId
      }
    });
  }, []);

  if (!isLoading && !table && isLoaded) {
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, null, "This is not a valid URL. Sorry."));
  }

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.container,
    direction: "row",
    alignItems: "stretch",
    wrap: "wrap",
    flex: 1
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1
  }, isLoading && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'auto']
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    size: "large"
  })), isLoaded && table && _react.default.createElement(_networkingTableLiveView.default, (0, _extends2.default)({}, table, {
    onJoin: join,
    onLeave: leave
  })), mobileResolution && _react.default.createElement(_networkingTableMenu.default, null), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.secondaryContainer
  }, _react.default.createElement(_sessions.default, {
    improvedWithPreview: false,
    showFullSchedule: false
  })), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    style: Styles.secondaryContainer
  }, _react.default.createElement(_partners.default, null))), !mobileResolution && _react.default.createElement(_networkingTableMenu.default, null)), _react.default.createElement(_footer.default, null)));
};

var _default = NetworkingTableLivePageContainer;
exports.default = _default;