var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleRoomChange;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function HandleRoomChange(_ref) {
  var roomId = _ref.roomId;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      leave = _useAudioVideoContext.leave;

  var leaveRef = _react.default.useRef(leave);

  _react.default.useEffect(function () {
    leaveRef.current = leave;
  }, [leave]);

  _react.default.useEffect(function () {
    return function () {
      leaveRef.current();
    };
  }, [roomId]);

  return null;
}