Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MUTE_UNMUTE_SPEAKER = exports.UNSUBSCRIBE_MUTE_SPEAKER = exports.SUBSCRIBE_MUTE_SPEAKER = exports.STOP_SCREEN_SHARE = exports.START_SCREEN_SHARE = exports.KICK_SPEAKER_FROM_LIVE = exports.DECLINE_LIVE_REQUEST = exports.ALLOW_LIVE_REQUEST = exports.STOP_LIVE = exports.START_LIVE = exports.LEAVE_LIVE = exports.CANCEL_LIVE_REQUEST = exports.JOIN_LIVE_REQUEST = exports.ROOM_CHANGED = exports.PARTICIPANTS_CHANGED = exports.UNSUBSCRIBE_ROOM_CHANGES = exports.UNSUBSCRIBE_PARTICIPANTS_CHANGES = exports.SUBSCRIBE_ROOM_CHANGES = exports.SUBSCRIBE_PARTICIPANTS_CHANGES = exports.UNWATCH = exports.WATCH = exports.LEAVE = exports.JOIN = exports.DISCONNECTED = exports.DISCONNECT = exports.CONNECT = exports.NAME = void 0;
var NAME = 'user-rooms';
exports.NAME = NAME;
var CONNECT = "".concat(NAME, "/CONNECT");
exports.CONNECT = CONNECT;
var DISCONNECT = "".concat(NAME, "/DISCONNECT");
exports.DISCONNECT = DISCONNECT;
var DISCONNECTED = "".concat(NAME, "/DISCONNECTED");
exports.DISCONNECTED = DISCONNECTED;
var JOIN = "".concat(NAME, "/JOIN");
exports.JOIN = JOIN;
var LEAVE = "".concat(NAME, "/LEAVE");
exports.LEAVE = LEAVE;
var WATCH = "".concat(NAME, "/WATCH");
exports.WATCH = WATCH;
var UNWATCH = "".concat(NAME, "/UNWATCH");
exports.UNWATCH = UNWATCH;
var SUBSCRIBE_PARTICIPANTS_CHANGES = "".concat(NAME, "/SUBSCRIBE_PARTICIPANTS_CHANGES");
exports.SUBSCRIBE_PARTICIPANTS_CHANGES = SUBSCRIBE_PARTICIPANTS_CHANGES;
var SUBSCRIBE_ROOM_CHANGES = "".concat(NAME, "/SUBSCRIBE_ROOM_CHANGES");
exports.SUBSCRIBE_ROOM_CHANGES = SUBSCRIBE_ROOM_CHANGES;
var UNSUBSCRIBE_PARTICIPANTS_CHANGES = "".concat(NAME, "/UNSUBSCRIBE_PARTICIPANTS_CHANGES");
exports.UNSUBSCRIBE_PARTICIPANTS_CHANGES = UNSUBSCRIBE_PARTICIPANTS_CHANGES;
var UNSUBSCRIBE_ROOM_CHANGES = "".concat(NAME, "/UNSUBSCRIBE_ROOM_CHANGES");
exports.UNSUBSCRIBE_ROOM_CHANGES = UNSUBSCRIBE_ROOM_CHANGES;
var PARTICIPANTS_CHANGED = "".concat(NAME, "/PARTICIPANTS_CHANGED");
exports.PARTICIPANTS_CHANGED = PARTICIPANTS_CHANGED;
var ROOM_CHANGED = "".concat(NAME, "/ROOM_CHANGED");
exports.ROOM_CHANGED = ROOM_CHANGED;
var JOIN_LIVE_REQUEST = "".concat(NAME, "/JOIN_LIVE_REQUEST");
exports.JOIN_LIVE_REQUEST = JOIN_LIVE_REQUEST;
var CANCEL_LIVE_REQUEST = "".concat(NAME, "/CANCEL_LIVE_REQUEST");
exports.CANCEL_LIVE_REQUEST = CANCEL_LIVE_REQUEST;
var LEAVE_LIVE = "".concat(NAME, "/LEAVE_LIVE");
exports.LEAVE_LIVE = LEAVE_LIVE;
var START_LIVE = "".concat(NAME, "/START_LIVE");
exports.START_LIVE = START_LIVE;
var STOP_LIVE = "".concat(NAME, "/STOP_LIVE");
exports.STOP_LIVE = STOP_LIVE;
var ALLOW_LIVE_REQUEST = "".concat(NAME, "/ALLOW_LIVE_REQUEST");
exports.ALLOW_LIVE_REQUEST = ALLOW_LIVE_REQUEST;
var DECLINE_LIVE_REQUEST = "".concat(NAME, "/DECLINE_LIVE_REQUEST");
exports.DECLINE_LIVE_REQUEST = DECLINE_LIVE_REQUEST;
var KICK_SPEAKER_FROM_LIVE = "".concat(NAME, "/KICK_SPEAKER_FROM_LIVE");
exports.KICK_SPEAKER_FROM_LIVE = KICK_SPEAKER_FROM_LIVE;
var START_SCREEN_SHARE = "".concat(NAME, "/START_SCREEN_SHARE");
exports.START_SCREEN_SHARE = START_SCREEN_SHARE;
var STOP_SCREEN_SHARE = "".concat(NAME, "/STOP_SCREEN_SHARE");
exports.STOP_SCREEN_SHARE = STOP_SCREEN_SHARE;
var SUBSCRIBE_MUTE_SPEAKER = "".concat(NAME, "/SUBSCRIBE_MUTE_SPEAKER");
exports.SUBSCRIBE_MUTE_SPEAKER = SUBSCRIBE_MUTE_SPEAKER;
var UNSUBSCRIBE_MUTE_SPEAKER = "".concat(NAME, "/UNSUBSCRIBE_MUTE_SPEAKER");
exports.UNSUBSCRIBE_MUTE_SPEAKER = UNSUBSCRIBE_MUTE_SPEAKER;
var MUTE_UNMUTE_SPEAKER = "".concat(NAME, "/MUTE_UNMUTE_SPEAKER");
exports.MUTE_UNMUTE_SPEAKER = MUTE_UNMUTE_SPEAKER;