var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaperV = require("react-native-paper-v3");

var _CustomIcon = _interopRequireDefault(require("../../../assets/icons/CustomIcon"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _core = require("@material-ui/core");

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var useStyles = (0, _core.makeStyles)({
  button: {
    color: function color(_ref) {
      var _color = _ref.color;
      return _color;
    },
    fontFamily: function fontFamily(_ref2) {
      var _fontFamily = _ref2.fontFamily;
      return _fontFamily;
    }
  }
});

var LoginButton = function LoginButton(_ref3) {
  var onPress = _ref3.onPress;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      topMenuLinks = _useTheme.colors.topMenuLinks,
      fontFamily = _useTheme.typography.fontFamily,
      base = _useTheme.spacing.base;

  var styles = useStyles({
    color: topMenuLinks,
    fontFamily: fontFamily
  });
  return _react.default.createElement(_Button.default, {
    onClick: onPress,
    className: styles.button,
    startIcon: _react.default.createElement(_CustomIcon.default, {
      size: 20,
      name: "users",
      style: {
        color: topMenuLinks,
        borderWidth: 1,
        borderColor: topMenuLinks,
        padding: base,
        borderRadius: '50%'
      }
    })
  }, _i18n.default.t('login.header'));
};

var _default = LoginButton;
exports.default = _default;