var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textWhite: {
      color: theme.palette.headerText.main
    },
    counter: (0, _defineProperty2.default)({
      fontSize: 64
    }, theme.breakpoints.up('sm'), {
      fontSize: 96
    }),
    description: (0, _defineProperty2.default)({}, theme.breakpoints.up('sm'), {
      fontSize: 24
    }),
    liveStatus: {
      color: theme.palette.headerText.main
    },
    liveStatusDescription: {
      display: 'inline-block',
      color: theme.palette.headerText.main,
      opacity: 0.6
    }
  };
});
var _default = styles;
exports.default = _default;