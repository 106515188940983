var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _formik = require("formik");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var Yup = _interopRequireWildcard(require("yup"));

var _reactFeather = require("react-feather");

var _actions = require("../../../../core/polls/actions");

var _reactRedux = require("react-redux");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _OutlinedButton = _interopRequireDefault(require("../../../../theme/components/buttons/OutlinedButton"));

var _RegExp = require("../../../../utils/RegExp");

var _pollsStyles = _interopRequireDefault(require("../polls-styles"));

var _selectors = require("../../../../core/polls/selectors");

var _Dialog = _interopRequireDefault(require("../../../../theme/components/dialog/Dialog"));

var _actions2 = require("../../../../core/global/actions");

var _reactNative = require("react-native");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _ = require('lodash');

var sortedOptionKeys = function sortedOptionKeys(theOptionsObject) {
  var map = new Map(Object.entries(theOptionsObject));
  var orderedMap = new Map((0, _toConsumableArray2.default)(map).sort(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        k = _ref3[0],
        v = _ref3[1];

    var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
        k2 = _ref4[0],
        v2 = _ref4[1];

    return v.createdAt > v2.createdAt ? 1 : -1;
  }));
  return Array.from(orderedMap.keys());
};

var EditPollContainer = function EditPollContainer(_ref5) {
  var backToPollsList = _ref5.backToPollsList,
      selectedPollId = _ref5.selectedPollId;
  var Styles = (0, _pollsStyles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectPollsIsLoading);
  var selectedPoll = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectPollById)(state, selectedPollId);
  });
  var optionKeys = selectedPoll.options ? sortedOptionKeys(selectedPoll.options) : [];
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 'l', 'l', 'l'],
    justifyContent: "center"
  }, _react.default.createElement(_OutlinedButton.default, {
    style: Styles.borderlessOutlineButton,
    color: "alternativeText",
    onPress: backToPollsList,
    spacing: 2,
    iconLeft: _react.default.createElement(_reactFeather.ArrowLeftCircle, null)
  }, _i18n.default.translate('polls.backToPollsList'))), _react.default.createElement(_formik.Formik, {
    initialValues: {
      title: selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.title,
      options: optionKeys,
      additionalOptions: []
    },
    onSubmit: function onSubmit(values) {
      dispatch((0, _actions.updatePoll)({
        pollData: {
          id: selectedPoll.id,
          title: values.title,
          options: selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.options,
          additionalOptions: values.additionalOptions
        }
      })).then(function () {
        backToPollsList();
      }).catch(function (error) {
        console.log(error);
      });
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().min(2, _i18n.default.t('polls.new.titleMin2')).max(150, _i18n.default.t('polls.new.titleMax150')).matches(_RegExp.pollTitleRegExp, _i18n.default.t('polls.new.invalidFormatTitle')).required(_i18n.default.t('polls.new.titleRequired')),
      numberOfOptions: Yup.number(),
      additionalOptions: Yup.array().of(Yup.string().trim().min(2, _i18n.default.t('polls.new.minOptionLength2')).max(100, _i18n.default.t('polls.new.maxOptionLength100')).matches(_RegExp.pollOptionRegExp, _i18n.default.t('polls.new.invalidFormatOption')).notOneOf(optionKeys, _i18n.default.t('polls.update.optionAlreadyExists')).required(_i18n.default.t('polls.new.minOptionLength2'))).test('additionalOptions', _i18n.default.t('polls.new.containsDuplicates'), function (value) {
        return _.uniq(value).length === value.length;
      })
    })
  }, function (_ref6) {
    var values = _ref6.values,
        setValues = _ref6.setValues,
        handleChange = _ref6.handleChange,
        errors = _ref6.errors,
        setFieldTouched = _ref6.setFieldTouched,
        touched = _ref6.touched,
        isValid = _ref6.isValid,
        submitForm = _ref6.submitForm,
        isSubmitting = _ref6.isSubmitting;
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'm', 0],
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 'base', 0]
    }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.new.title'))), _react.default.createElement(_Input.default, {
      shaped: true,
      color: "alternativeText",
      type: "text",
      value: values.title,
      onChange: handleChange,
      name: "title",
      error: !!errors.title && touched.title,
      onBlur: function onBlur() {
        return setFieldTouched('title');
      },
      placeholder: _i18n.default.translate('polls.new.titlePlaceholder')
    }), !!errors.title && _react.default.createElement(_Spacing.default, {
      mSpacing: ['base', 0, 0, 0]
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.title))), optionKeys.map(function (option, i) {
      return _react.default.createElement(_Spacing.default, {
        key: "option-".concat(i),
        mSpacing: [0, 0, 'm', 0],
        pSpacing: [0, 0, 0, 's'],
        shaping: "cards",
        direction: "row",
        alignItems: "stretch",
        style: Styles.optionContainer
      }, _react.default.createElement(_Spacing.default, {
        flex: 5
      }, _react.default.createElement(_core.Typography, null, i + 1, ". ", option)), _react.default.createElement(_Spacing.default, {
        flex: 1
      }, _react.default.createElement(_OutlinedButton.default, {
        flex: 1,
        style: Styles.borderlessOutlineButton,
        color: "alternativeText",
        iconLeft: _react.default.createElement(_reactFeather.Trash2, null),
        onPress: function onPress() {
          return dispatch((0, _actions2.openDialog)({
            id: "delete-".concat(option),
            content: _react.default.createElement(_Dialog.default, {
              onBackdropClick: function onBackdropClick() {
                return dispatch((0, _actions2.closeDialog)("delete-".concat(option)));
              }
            }, _react.default.createElement(_Spacing.default, {
              shaping: "cards",
              color: "pageBackground",
              direction: "column",
              alignItems: "stretch",
              pSpacing: "xxl"
            }, _react.default.createElement(_Spacing.default, {
              direction: "row",
              mSpacing: ['xl', 0]
            }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.deleteConfirmTextOptions'))), _react.default.createElement(_Spacing.default, {
              direction: "row",
              alignItems: "flex-end",
              justifyContent: "space-evenly"
            }, _react.default.createElement(_ContainedButton.default, {
              onPress: function onPress() {
                return (0, _reactRedux.batch)(function () {
                  dispatch((0, _actions.deleteOption)({
                    pollId: selectedPoll.id,
                    option: option
                  }));
                  dispatch((0, _actions2.closeDialog)("delete-".concat(option)));
                });
              }
            }, _i18n.default.translate('polls.confirmDelete')), _react.default.createElement(_OutlinedButton.default, {
              onPress: function onPress() {
                return dispatch((0, _actions2.closeDialog)("delete-".concat(option)));
              }
            }, _i18n.default.translate('polls.cancelDelete'))))),
            index: 0
          }));
        }
      })));
    }), values.additionalOptions.map(function (op, i) {
      return _react.default.createElement(_Spacing.default, {
        key: "option-".concat(i),
        mSpacing: [0, 0, 'm', 0],
        pSpacing: "s",
        shaping: "cards",
        direction: "column",
        alignItems: "stretch",
        style: Styles.optionContainer
      }, _react.default.createElement(_Spacing.default, {
        mSpacing: [0, 0, 'base', 0]
      }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.new.op'), " ", i + 1)), _react.default.createElement(_Input.default, {
        shaped: true,
        color: "alternativeText",
        type: "text",
        value: op,
        onChange: handleChange,
        name: "additionalOptions.".concat(i),
        error: !!errors.additionalOptions && !!errors.additionalOptions[i],
        onBlur: function onBlur() {
          return setFieldTouched("options.".concat(i));
        },
        placeholder: "".concat(_i18n.default.translate('polls.new.op'), " ").concat(i + 1)
      }), !!errors.additionalOptions && !!errors.additionalOptions[i] && _react.default.createElement(_Spacing.default, {
        mSpacing: "base"
      }, _react.default.createElement(_core.Typography, {
        variant: "caption",
        color: "error"
      }, errors.additionalOptions[i])));
    }), !!errors.additionalOptions && typeof errors.additionalOptions === 'string' && _react.default.createElement(_Spacing.default, {
      mSpacing: "base"
    }, _react.default.createElement(_core.Typography, {
      variant: "caption",
      color: "error"
    }, errors.additionalOptions)), _react.default.createElement(_OutlinedButton.default, {
      style: [Styles.addNewOptionIcon, Styles.borderlessOutlineButton],
      color: "button",
      onPress: function onPress() {
        var additionalOptions = (0, _toConsumableArray2.default)(values.additionalOptions);
        additionalOptions.push('');
        setValues(_objectSpread(_objectSpread({}, values), {}, {
          additionalOptions: additionalOptions
        }));
      },
      iconLeft: _react.default.createElement(_reactFeather.PlusCircle, null)
    }), _react.default.createElement(_ContainedButton.default, {
      disabled: isLoading || isSubmitting,
      onPress: function onPress() {
        console.log(errors);

        if (isValid) {
          submitForm();
        }
      }
    }, isLoading || isSubmitting ? _react.default.createElement(_reactNative.ActivityIndicator, {
      size: "small",
      color: "#fff"
    }) : _i18n.default.t('polls.update.update')));
  }));
};

var _default = EditPollContainer;
exports.default = _default;