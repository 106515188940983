Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectUserProfileLoaded = exports.selectUserProfileLoading = exports.selectUserDisplayName = exports.selectUserProfile = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var selectUserProfileState = function selectUserProfileState(state) {
  return state[_constants.NAME];
};

var selectUserProfile = (0, _toolkit.createSelector)(selectUserProfileState, function (userProfileState) {
  return userProfileState.profile;
});
exports.selectUserProfile = selectUserProfile;
var selectUserDisplayName = (0, _toolkit.createSelector)(selectUserProfile, function (userProfileState) {
  return (userProfileState === null || userProfileState === void 0 ? void 0 : userProfileState.displayName) || (userProfileState === null || userProfileState === void 0 ? void 0 : userProfileState.fullName);
});
exports.selectUserDisplayName = selectUserDisplayName;
var selectUserProfileLoading = (0, _toolkit.createSelector)(selectUserProfileState, function (state) {
  return state.loading;
});
exports.selectUserProfileLoading = selectUserProfileLoading;
var selectUserProfileLoaded = (0, _toolkit.createSelector)(selectUserProfileState, function (state) {
  return state.loaded;
});
exports.selectUserProfileLoaded = selectUserProfileLoaded;