var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DjProfileScreenInternal = exports.numberOfHashtags = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _reactNativePaper = require("react-native-paper");

var _reactNavigation = require("react-navigation");

var _profile = _interopRequireDefault(require("../../core/profile"));

var _user = _interopRequireDefault(require("../../core/user"));

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _events = _interopRequireDefault(require("../../core/events"));

var _NavigationButtons = require("../../navigation/NavigationButtons");

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _FollowUserButton = _interopRequireDefault(require("../common/follow/FollowUserButton"));

var _ShareButton = _interopRequireDefault(require("../common/ShareButton"));

var _AvatarImage = _interopRequireDefault(require("../common/avatar/AvatarImage"));

var _Headline = _interopRequireDefault(require("../common/typography_v1/Headline"));

var _Title = _interopRequireDefault(require("../common/typography_v1/Title"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _reactNativeReadMoreText = _interopRequireDefault(require("react-native-read-more-text"));

var _SimilarDjSection = _interopRequireDefault(require("../common/event/similar/SimilarDjSection"));

var _DjProfileSection = _interopRequireDefault(require("../common/dj/DjProfileSection"));

var _SocialMedia = _interopRequireDefault(require("../common/SocialMedia"));

var _CustomCachedImage = _interopRequireDefault(require("../common/CustomCachedImage"));

var _PastEventsFeed = _interopRequireDefault(require("../homepage/PastEventsFeed"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var numberOfHashtags = 6;
exports.numberOfHashtags = numberOfHashtags;
var djBadgeSize = 28;
var djBadgeMainColor = '#ffffff';
var djBadgeSecondaryColor = '#000000';

var DjProfileScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(DjProfileScreenInternal, _React$PureComponent);

  var _super = _createSuper(DjProfileScreenInternal);

  function DjProfileScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, DjProfileScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));
    _this.state = {
      hideHashtags: true,
      upcomingHasLoaded: false,
      leaderboardRank: _this.props.savedLbRank,
      rankWillUpdate: false
    };
    _this.scrollY = new _reactNative.Animated.Value(0);
    _this.changingBgColor = 0;

    _this._renderTruncatedFooter = function (handlePress) {
      return _react.default.createElement(_Paragraph.default, {
        style: {
          color: 'white',
          marginTop: 5
        },
        onPress: handlePress
      }, "Read more");
    };

    _this._renderRevealedFooter = function (handlePress) {
      return _react.default.createElement(_Paragraph.default, {
        style: {
          color: 'white',
          marginTop: 5
        },
        onPress: handlePress
      }, "Show less");
    };

    _this._renderHashtag = function (hashtag, index) {
      return _react.default.createElement(_reactNative.TouchableHighlight, {
        onPress: function onPress() {
          _this.props.navigation.navigate('GenreDetail', {
            hashtag: hashtag,
            djId: _this.props.djId
          });
        },
        key: index,
        testID: hashtag,
        style: styles.hashtagButton
      }, _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Paragraph.default, {
        style: styles.hashtagSign
      }, '# '), _react.default.createElement(_Paragraph.default, {
        style: styles.hashtagText
      }, hashtag.toLowerCase())));
    };

    return _this;
  }

  (0, _createClass2.default)(DjProfileScreenInternal, [{
    key: "componentDidMount",
    value: function () {
      var _componentDidMount = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
        var _this2 = this;

        var _this$props, loadProfile, djId, loadDjScheduleEvents, eventsDjUpcoming, loadScheduleEventsMusicTypes, hashtags, loadFollowersNumber, loadFollowingsNumber, loadLeaderboardEntry;

        return _regenerator.default.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this$props = this.props, loadProfile = _this$props.loadProfile, djId = _this$props.djId, loadDjScheduleEvents = _this$props.loadDjScheduleEvents, eventsDjUpcoming = _this$props.eventsDjUpcoming, loadScheduleEventsMusicTypes = _this$props.loadScheduleEventsMusicTypes, hashtags = _this$props.hashtags, loadFollowersNumber = _this$props.loadFollowersNumber, loadFollowingsNumber = _this$props.loadFollowingsNumber, loadLeaderboardEntry = _this$props.loadLeaderboardEntry;
                loadProfile(djId).then(function (djProfile) {
                  if (djProfile && djProfile.playerId) {
                    loadLeaderboardEntry(djProfile.playerId);
                  }
                });

                if (djId) {
                  loadDjScheduleEvents(djId).then(function () {
                    _this2.setState({
                      upcomingHasLoaded: true
                    });

                    if (eventsDjUpcoming.length === 0 && hashtags) {
                      loadScheduleEventsMusicTypes(hashtags);
                    }
                  });
                  loadFollowersNumber(djId);
                  loadFollowingsNumber(djId);
                }

                this.scrollY = new _reactNative.Animated.Value(0);
                this.changingBgColor = this.scrollY.interpolate({
                  inputRange: [0, 90, 120],
                  outputRange: ['transparent', 'transparent', 'black'],
                  extrapolate: 'clamp'
                });
                this.props.navigation.setParams({
                  bgColor: this.changingBgColor
                });

              case 6:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function componentDidMount() {
        return _componentDidMount.apply(this, arguments);
      }

      return componentDidMount;
    }()
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;

      var leaderboardRank = this.state.leaderboardRank;
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.Animated.ScrollView, {
        onScroll: _reactNative.Animated.event([{
          nativeEvent: {
            contentOffset: {
              y: this.scrollY
            }
          }
        }]),
        scrollEventThrottle: 1,
        showsVerticalScrollIndicator: false,
        style: {
          backgroundColor: 'black'
        },
        contentContainerStyle: {
          paddingBottom: 40
        }
      }, _react.default.createElement(_reactNative.View, {
        style: {
          flex: 1
        }
      }, _react.default.createElement(_reactNative.View, {
        style: {
          aspectRatio: 4 / 3,
          width: '100%'
        }
      }, _react.default.createElement(_CustomCachedImage.default, {
        style: {
          aspectRatio: 4 / 3,
          width: '100%',
          minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.5 : 0
        },
        source: {
          uri: 'https://www.istitutiprofessionali.com/wp-content/uploads/2017/10/i-5-dj-italiani-pi-famosi-al-mondo-istituti-professionali.jpg'
        },
        resizeMode: "cover"
      })), _react.default.createElement(_reactNativeLinearGradient.default, {
        locations: [0.6, 0.75, 0.85, 0.9, 1],
        colors: ['rgba(0, 0, 0, 0.0001)', 'rgba(0, 0, 0, 0.3)', 'rgba(0, 0, 0, 0.5)', 'rgba(0, 0, 0, 0.8)', 'rgba(0, 0, 0, 1)'],
        style: styles.linearGradient
      }, _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingHorizontal: 16
        }
      }, _react.default.createElement(_reactNative.View, null, _react.default.createElement(_AvatarImage.default, {
        size: 78,
        source: {
          uri: this.props.avatar
        },
        defaultLabel: this.props.djName,
        style: {
          borderColor: 'white',
          overflow: 'hidden',
          borderWidth: 1
        }
      }), _react.default.createElement(_reactNative.View, {
        style: styles.djBadgeContainer
      }, _react.default.createElement(_reactNative.View, {
        style: styles.djBadgeBackground
      }), _react.default.createElement(_CustomIcon.default, {
        style: {
          position: 'absolute'
        },
        name: "dj-badge",
        size: djBadgeSize,
        color: djBadgeSecondaryColor
      }), _react.default.createElement(_CustomIcon.default, {
        style: {
          position: 'absolute'
        },
        name: "dj-badge",
        size: djBadgeSize * 0.85,
        color: djBadgeMainColor
      }))), _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }
      }, leaderboardRank ? _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "leaderboardRankTouch",
        borderless: true,
        onPress: function onPress() {
          return _this3.props.navigation.navigate('LeaderboardList');
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.featuredStatsContainer
      }, _react.default.createElement(_Headline.default, {
        testId: 'leaderboardRank',
        style: styles.featuredStatsHeadline
      }, "#", leaderboardRank), _react.default.createElement(_Paragraph.default, {
        style: styles.featuredStatsSubtitle
      }, "TOP DJs"))) : null, _react.default.createElement(_reactNative.View, {
        style: {
          flexDirection: 'row',
          alignItems: 'flex-end'
        }
      }, _react.default.createElement(_FollowUserButton.default, {
        id: this.props.djId,
        buttonSize: 'small',
        isFixedButton: true,
        style: styles.followUserButton
      }), _react.default.createElement(_ShareButton.default, {
        style: styles.shareBtnStyle,
        deepLinkPath: "profile/".concat(this.props.djId),
        ogTitle: this.props.djName,
        ogImageUrl: this.props.avatar,
        ogDescription: this.props.bio,
        message: this.props.djId === this.props.loggedInUserId ? 'Checkout my profile on Soundmix [APP_INVITE_URL]' : "Checkout ".concat(this.props.djName, " profile on Soundmix [APP_INVITE_URL]"),
        windowTitle: "Share with Friends!"
      }))))), _react.default.createElement(_Headline.default, {
        style: styles.djName
      }, this.props.djName), _react.default.createElement(_Stack.default, {
        alignment: "center"
      }, _react.default.createElement(_Stack.default.Item, {
        fill: true
      }, _react.default.createElement(_reactNative.View, {
        style: styles.statsContainer
      }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "followersTouch",
        borderless: true,
        onPress: function onPress() {
          return _this3.props.navigation.navigate('Followers', {
            userId: _this3.props.djId,
            title: 'Followers'
          });
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.statsItem
      }, _react.default.createElement(_Paragraph.default, {
        style: {
          fontWeight: 'bold',
          lineHeight: 18
        }
      }, this.props.numFollowers, ' '), _react.default.createElement(_Paragraph.default, {
        style: {
          lineHeight: 18
        }
      }, "followers"))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        name: "followingTouch",
        borderless: true,
        onPress: function onPress() {
          return _this3.props.navigation.navigate('Following', {
            userId: _this3.props.djId,
            title: 'Following'
          });
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.statsItem
      }, _react.default.createElement(_Paragraph.default, {
        style: {
          fontWeight: 'bold',
          lineHeight: 18
        }
      }, this.props.numFollowing, ' '), _react.default.createElement(_Paragraph.default, {
        style: {
          lineHeight: 18
        }
      }, "following")))))), _react.default.createElement(_SocialMedia.default, {
        userId: this.props.djId
      }), this.props.hashtags && this.props.hashtags.size > 0 && _react.default.createElement(_reactNative.View, {
        testID: "hashtagsSection",
        style: styles.genresSectionContainer
      }, _react.default.createElement(_Title.default, {
        style: styles.sectionTitle
      }, 'Genres'), _react.default.createElement(_reactNative.View, {
        style: styles.hashtagsContainer
      }, this.props.hashtags.size >= numberOfHashtags ? _react.default.createElement(_react.default.Fragment, null, this.state.hideHashtags && _react.default.createElement(_react.default.Fragment, null, this.props.hashtags.slice(0, numberOfHashtags).map(function (el, i) {
        return _this3._renderHashtag(el, i);
      }), _react.default.createElement(_reactNative.TouchableHighlight, {
        testID: "moreButton",
        onPress: function onPress() {
          return _this3.setState({
            hideHashtags: false
          });
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.buttonMoreLess
      }, _react.default.createElement(_CustomIcon.default, {
        name: "more",
        size: 20,
        color: "#ffffff"
      })))), !this.state.hideHashtags && _react.default.createElement(_react.default.Fragment, null, this.props.hashtags.map(function (el, i) {
        return _this3._renderHashtag(el, i);
      }), _react.default.createElement(_reactNative.TouchableHighlight, {
        testID: "lessButton",
        onPress: function onPress() {
          return _this3.setState({
            hideHashtags: true
          });
        }
      }, _react.default.createElement(_reactNative.View, {
        style: styles.buttonMoreLess
      }, _react.default.createElement(_CustomIcon.default, {
        name: "chevron-up",
        size: 20,
        color: "#ffffff"
      }))))) : _react.default.createElement(_react.default.Fragment, null, this.props.hashtags.map(function (el, i) {
        return _this3._renderHashtag(el, i);
      })))), this.props.bio != null && this.props.bio !== '' && _react.default.createElement(_reactNative.View, {
        style: {
          paddingHorizontal: 16,
          marginBottom: 20
        }
      }, _react.default.createElement(_reactNativeReadMoreText.default, {
        numberOfLines: 3,
        renderTruncatedFooter: this._renderTruncatedFooter,
        renderRevealedFooter: this._renderRevealedFooter
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.bioText
      }, this.props.bio))), this.state.upcomingHasLoaded ? _react.default.createElement(_SimilarDjSection.default, {
        djId: this.props.djId
      }) : null, _react.default.createElement(_PastEventsFeed.default, {
        djId: this.props.djId.toString()
      }), _react.default.createElement(_DjProfileSection.default, {
        titleSection: "You Might Like",
        djId: this.props.djId
      }))));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(props, state) {
      var leaderboardEntryRank = props.leaderboardEntryRank,
          myLeaderboardRank = props.myLeaderboardRank,
          isMyProfile = props.isMyProfile,
          isEntryLoading = props.isEntryLoading;
      var leaderboardRank = state.leaderboardRank,
          rankWillUpdate = state.rankWillUpdate;
      var newRank = isMyProfile ? myLeaderboardRank : leaderboardEntryRank;

      if (isMyProfile && leaderboardRank !== newRank) {
        return {
          leaderboardRank: newRank
        };
      }

      if (isEntryLoading) {
        return {
          rankWillUpdate: true
        };
      }

      if (rankWillUpdate && newRank !== leaderboardRank) {
        return {
          leaderboardRank: newRank,
          rankWillUpdate: false
        };
      }

      return null;
    }
  }]);
  return DjProfileScreenInternal;
}(_react.default.PureComponent);

exports.DjProfileScreenInternal = DjProfileScreenInternal;

DjProfileScreenInternal.navigationOptions = function (_ref) {
  var navigation = _ref.navigation;
  var state = navigation.state;
  return {
    headerLeft: (0, _NavigationButtons.backButton)(navigation),
    headerRight: _react.default.createElement(_reactNative.View, null),
    headerTransparent: true,
    headerStyle: {
      backgroundColor: navigation.getParam('BackgroundColor', state.params.bgColor)
    }
  };
};

var styles = _reactNative.StyleSheet.create({
  linearGradient: {
    flex: 1,
    top: 0,
    position: 'absolute',
    width: '100%',
    aspectRatio: 4 / 3,
    justifyContent: 'flex-end',
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.5 : 0
  },
  djBadgeContainer: {
    position: 'absolute',
    width: djBadgeSize,
    height: djBadgeSize,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  djBadgeBackground: {
    width: djBadgeSize * 0.6,
    height: djBadgeSize * 0.6,
    borderRadius: djBadgeSize * 0.6 / 2,
    backgroundColor: djBadgeSecondaryColor,
    position: 'absolute'
  },
  followUserButton: {
    width: 74,
    paddingHorizontal: 0,
    fontSize: 13,
    lineHeight: 13,
    marginRight: 8
  },
  shareBtnStyle: {
    borderRadius: 4,
    backgroundColor: 'transparent',
    borderColor: 'rgba(255,255,255,1)',
    borderWidth: 1,
    width: 35,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center'
  },
  djName: {
    paddingHorizontal: 16,
    marginVertical: 16,
    lineHeight: 20
  },
  statsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },
  statsItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: 24
  },
  socialMediaContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 0,
    paddingHorizontal: 16
  },
  bioText: {
    color: 'rgba(255,255,255,0.7)'
  },
  reportButton: {
    borderColor: 'white'
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  },
  genresSectionContainer: {
    marginTop: 24,
    marginBottom: 16
  },
  hashtagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16
  },
  hashtagButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.14)',
    borderRadius: 4,
    paddingHorizontal: 12,
    height: 36,
    marginRight: 8,
    marginBottom: 8
  },
  hashtagSign: {
    marginVertical: 0,
    marginRight: 6,
    lineHeight: 15,
    height: 15,
    color: '#fff'
  },
  hashtagText: {
    marginVertical: 0,
    lineHeight: 15,
    height: 16,
    color: 'rgba(255,255,255,0.6)'
  },
  buttonMoreLess: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.14)',
    borderRadius: 4,
    height: 36,
    width: 36,
    marginBottom: 8
  },
  userName: {
    lineHeight: 20,
    color: 'white',
    textAlign: 'center',
    marginTop: 8
  },
  featuredStatsContainer: {
    width: 100,
    height: 100,
    backgroundColor: '#F8E71B',
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    marginBottom: 16
  },
  featuredStatsHeadline: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 32
  },
  featuredStatsSubtitle: {
    color: '#000',
    fontWeight: 'bold'
  }
});

function mapStateToProps(state, ownProps) {
  var djId = ownProps.djId;

  var hashtags = _profile.default.selectors.getHashtags(state, djId);

  return {
    djName: _profile.default.selectors.getUserName(state, djId),
    bio: _profile.default.selectors.getBio(state, djId),
    avatar: _profile.default.selectors.getUserPicture(state, djId),
    twitterUrl: _profile.default.selectors.getTwitterUrl(state, djId),
    facebookUrl: _profile.default.selectors.getFacebookUrl(state, djId),
    soundcloudUrl: _profile.default.selectors.getSoundcloudUrl(state, djId),
    instagramUrl: _profile.default.selectors.getInstagramUrl(state, djId),
    beatPortUrl: _profile.default.selectors.getBeatPortUrl(state, djId),
    residentAdvisorUrl: _profile.default.selectors.getResidentAdvisorUrl(state, djId),
    websiteUrl: _profile.default.selectors.getWebsiteUrl(state, djId),
    loggedInUserId: _user.default.selectors.getUserId(state),
    numFollowers: _profile.default.selectors.getNumFollowers(state, djId),
    numFollowing: _profile.default.selectors.getNumFollowing(state, djId),
    myLeaderboardRank: _gameSparks.default.selectors.getLeaderboardRank(state, djId),
    leaderboardEntryRank: _gameSparks.default.selectors.getLeaderboardEntryRank(state),
    isEntryLoading: _gameSparks.default.selectors.isLeaderboardEntryLoading(state),
    savedLbRank: _gameSparks.default.selectors.getSavedDjLeaderboardRank(state, djId),
    hashtags: hashtags,
    eventsDjUpcoming: _events.default.selectors.getDjUpcomingShows(state, djId)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadProfile: _profile.default.actions.loadProfile,
  loadDjScheduleEvents: _events.default.actions.loadDjScheduleEvents,
  loadScheduleEventsMusicTypes: _events.default.actions.loadScheduleEventsMusicTypes,
  loadFollowingsNumber: _profile.default.actions.loadFollowingsNumber,
  loadFollowersNumber: _profile.default.actions.loadFollowersNumber,
  loadLeaderboardEntry: _gameSparks.default.actions.loadLeaderboardEntry
})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(DjProfileScreenInternal)));

exports.default = _default;