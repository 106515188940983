Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useEventListStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginTop: -theme.spacing.l,
      marginHorizontal: -theme.spacing.l,
      marginBottom: theme.spacing.xl,
      alignItems: 'stretch',
      paddingLeft: theme.spacing.xl,
      paddingRight: theme.spacing.xl,
      justifyContent: 'center'
    },
    header: {
      paddingBottom: theme.spacing.m,
      margin: theme.spacing.xl
    },
    eventsContainer: {
      width: '100%'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: theme.spacing.l,
      paddingRight: theme.spacing.l
    },
    header: {
      margin: theme.spacing.l
    }
  };
}).buildHook();
var _default = useEventListStyles;
exports.default = _default;