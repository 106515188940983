var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _i18nJs = _interopRequireDefault(require("i18n-js"));

var _de = _interopRequireDefault(require("./locales/de"));

var _ee = _interopRequireDefault(require("./locales/ee"));

var _en = _interopRequireDefault(require("./locales/en"));

var _fr = _interopRequireDefault(require("./locales/fr"));

var _gk = _interopRequireDefault(require("./locales/gk"));

var _pl = _interopRequireDefault(require("./locales/pl"));

var _ro = _interopRequireDefault(require("./locales/ro"));

_i18nJs.default.fallbacks = true;
_i18nJs.default.translations = {
  de: _de.default,
  ee: _ee.default,
  en: _en.default,
  fr: _fr.default,
  gk: _gk.default,
  pl: _pl.default,
  ro: _ro.default
};
var _default = _i18nJs.default;
exports.default = _default;