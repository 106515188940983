var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Input;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _Spacing = _interopRequireDefault(require("../spacing/Spacing"));

var _core = require("@material-ui/core");

var _excluded = ["shaped", "disabled", "error", "style", "containerStyle", "leftIcon", "rightIcon", "lines", "onFocus", "dense", "onBlur", "color"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Input(_ref) {
  var shaped = _ref.shaped,
      disabled = _ref.disabled,
      error = _ref.error,
      style = _ref.style,
      containerStyle = _ref.containerStyle,
      leftIcon = _ref.leftIcon,
      rightIcon = _ref.rightIcon,
      lines = _ref.lines,
      _onFocus = _ref.onFocus,
      _ref$dense = _ref.dense,
      dense = _ref$dense === void 0 ? false : _ref$dense,
      _onBlur = _ref.onBlur,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'pageText' : _ref$color,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      focused = _React$useState2[0],
      setFocused = _React$useState2[1];

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape,
      colors = _useTheme.colors,
      typography = _useTheme.typography,
      spacing = _useTheme.spacing;

  var activeColor = error ? 'errorColor' : focused ? 'button' : color;
  var padding = dense ? spacing.s : spacing.m;
  return _react.default.createElement(_Spacing.default, {
    style: containerStyle,
    flex: 1,
    direction: "row",
    alignItems: "flex-start",
    justifyContent: "center"
  }, leftIcon ? _react.default.createElement(_reactNative.Text, {
    style: {
      position: 'absolute',
      left: padding,
      top: padding
    },
    color: activeColor
  }, _react.default.createElement(_core.Typography, {
    color: "inherit"
  }, leftIcon)) : null, _react.default.createElement(_reactNative.TextInput, (0, _extends2.default)({
    placeholderTextColor: "".concat(colors[color] || color, "88"),
    onFocus: function onFocus(e) {
      setFocused(true);
      _onFocus && _onFocus(e);
    },
    onBlur: function onBlur(e) {
      setFocused(false);
      _onBlur && _onBlur(e);
    },
    disabled: disabled,
    multiline: lines && lines > 1 ? true : false,
    numberOfLines: lines && lines > 1 ? lines : 1,
    style: [_objectSpread(_objectSpread({}, typography.paragraph), {}, {
      color: colors[color] || color,
      paddingVertical: padding,
      paddingLeft: padding + (leftIcon ? padding + spacing.m : 0),
      paddingRight: padding + (rightIcon ? padding + spacing.m : 0),
      borderStyle: 'solid',
      borderWidth: 2,
      width: '100%',
      borderColor: focused ? colors.button : colors[color] || color,
      outlineColor: 'transparent',
      outlineWidth: 0,
      borderRadius: 4,
      fontSize: dense ? '0.875rem' : 'inherit',
      backgroundColor: disabled ? 'grey' : 'transparent'
    }), shaped ? _objectSpread({}, shape.buttons) : {}, disabled ? {
      opacity: 0.4
    } : {}, error ? {
      borderColor: 'red'
    } : {}, style]
  }, rest)), rightIcon ? _react.default.createElement(_reactNative.Text, {
    style: {
      position: 'absolute',
      right: padding
    },
    color: activeColor
  }, _react.default.createElement(_core.Typography, {
    color: "inherit"
  }, rightIcon)) : null);
}