var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useClient;

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _react = _interopRequireDefault(require("react"));

function useClient() {
  return _react.default.useMemo(function () {
    return _agoraRtcSdkNg.default.createClient({
      mode: 'live',
      codec: 'vp8'
    });
  }, []);
}