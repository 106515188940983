var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _profileModalContainer.default;
  }
});

var _profileModalContainer = _interopRequireDefault(require("./profile-modal-container"));