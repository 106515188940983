var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _constants = require("./constants");

var _createReducer;

var eventSettingsReducer = (0, _toolkit.createReducer)(_constants.settingsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
}), (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getEventSettings.pending, function (state) {
  state.loading = true;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.getEventSettings.fulfilled, function (state, _ref) {
  var payload = _ref.payload;
  state.loading = false;
  state.loaded = true;

  _constants.settingsEntityAdapter.upsertMany(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.getEventSettings.rejected, function (state, _ref2) {
  var error = _ref2.error;
  state.loading = false;
  state.error = error;
}), _createReducer));
var _default = eventSettingsReducer;
exports.default = _default;