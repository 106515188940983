var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NetworkingTableView;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _networkingTableViewStyle = _interopRequireDefault(require("./networking-table-view-style"));

var _DynamicSection = _interopRequireDefault(require("../../../theme/components/section/DynamicSection"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _AudioVideoProvider = _interopRequireDefault(require("../../common/audio-video/AudioVideoProvider"));

var _ClientState = _interopRequireDefault(require("../../common/audio-video/ClientState"));

var _reactRedux = require("react-redux");

var _agoraRtcSdkNg = _interopRequireDefault(require("agora-rtc-sdk-ng"));

var _ToggleAudio = _interopRequireDefault(require("../../common/audio-video/ToggleAudio"));

var _ToggleVideo = _interopRequireDefault(require("../../common/audio-video/ToggleVideo"));

var _SettingsButton = _interopRequireDefault(require("../../common/audio-video/SettingsButton"));

var _HandleJoin = _interopRequireDefault(require("../../common/audio-video/HandleJoin"));

var _ColoredCircularProgress = _interopRequireDefault(require("../../../theme/components/loaders/ColoredCircularProgress"));

var _HandleError = _interopRequireDefault(require("../../common/audio-video/HandleError"));

var _LeaveButton = _interopRequireDefault(require("../../common/audio-video/LeaveButton"));

var _ToggleScreenShare = _interopRequireDefault(require("../../common/audio-video/ToggleScreenShare"));

var _OptimizeRemoteVideo = _interopRequireDefault(require("../../common/audio-video/OptimizeRemoteVideo"));

var _OptimizeLocalVideo = _interopRequireDefault(require("../../common/audio-video/OptimizeLocalVideo"));

var _HandleLeave = _interopRequireDefault(require("../../common/audio-video/HandleLeave"));

var _HandleInactivity = _interopRequireDefault(require("../../common/audio-video/HandleInactivity"));

var _Participants = _interopRequireDefault(require("../../common/audio-video/Participants"));

var _SubscriptionsByRoom = _interopRequireDefault(require("../../common/audio-video/SubscriptionsByRoom"));

var _AudioVideoDeviceSelectorStep = _interopRequireDefault(require("../../common/audio-video/AudioVideoDeviceSelectorStep"));

var _selectors = require("../../../core/user-rooms/selectors");

var _HandleNoInfo = _interopRequireDefault(require("../../common/audio-video/HandleNoInfo"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _sessions = _interopRequireDefault(require("../../sessions"));

var _selectors2 = require("../../../core/user-profile/selectors");

var _selectors3 = require("../../../core/user-roles/selectors");

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _networkingTableMenu = _interopRequireDefault(require("./networking-table-menu"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _selectors4 = require("../../../core/session/selectors");

_agoraRtcSdkNg.default.setLogLevel(3);

function NetworkingTableView(_ref) {
  var roomId = _ref.roomId,
      tableName = _ref.tableName;
  var Styles = (0, _networkingTableViewStyle.default)();

  var _useSelector = (0, _reactRedux.useSelector)((0, _selectors.accessTokensSelector)(roomId)),
      token = _useSelector.token,
      uid = _useSelector.id;

  var participantsInfo = (0, _reactRedux.useSelector)((0, _selectors.roomAllParticipantsSelector)(roomId));
  var navigation = (0, _useNavigationExtended.default)();
  var isUserJoined = Boolean(participantsInfo[uid]);
  var screenSharer = (0, _reactRedux.useSelector)((0, _selectors.roomScreenSharerSelector)(roomId));
  var userProfile = (0, _reactRedux.useSelector)(_selectors2.selectUserProfile);
  var networkingTableSession = (0, _reactRedux.useSelector)((0, _selectors4.sessionByNetworkingTableId)(roomId));
  var canUserMuteSpeaker = (0, _reactRedux.useSelector)((0, _selectors3.hasUserFeatureAccessSelector)(_featureNamesConstants.MUTE_UNMUTE_SPEAKER, networkingTableSession === null || networkingTableSession === void 0 ? void 0 : networkingTableSession.resourceId));

  var roomParticipantIds = _react.default.useMemo(function () {
    return Object.keys(participantsInfo).map(Number).filter(function (id) {
      return id !== Number(uid);
    });
  }, [participantsInfo, uid]);

  var mobileResolution = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.bg;
  });

  function loadingPlaceholder(key) {
    return _react.default.createElement(_DynamicSection.default, {
      key: key,
      style: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_ColoredCircularProgress.default, {
        customColor: color,
        size: 120
      });
    });
  }

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    style: Styles.networkingContainer,
    flex: 1,
    direction: "column",
    pSpacing: "s",
    justifyContent: "flex-start",
    alignItems: "stretch"
  }, _react.default.createElement(_AudioVideoProvider.default, null, _react.default.createElement(_HandleError.default, null), _react.default.createElement(_HandleLeave.default, null), _react.default.createElement(_OptimizeLocalVideo.default, null), _react.default.createElement(_ClientState.default, {
    disconnectedState: _react.default.createElement(_react.default.Fragment, {
      key: "disconnected"
    }, _react.default.createElement(_HandleInactivity.default, null), _react.default.createElement(_DynamicSection.default, {
      style: {
        flex: 1,
        padding: 16,
        justifyContent: 'center'
      },
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, _react.default.createElement(_AudioVideoDeviceSelectorStep.default, null, _react.default.createElement(_HandleJoin.default, {
      room: roomId,
      uid: uid,
      token: token
    }, function (_ref3) {
      var isQuerying = _ref3.isQuerying,
          onJoin = _ref3.onJoin;
      return _react.default.createElement(_ContainedButton.default, {
        onPress: onJoin,
        disabled: isQuerying || !token || !isUserJoined
      }, _i18n.default.translate('networking.takeASeat'));
    })))),
    connectedState: _react.default.createElement(_DynamicSection.default, {
      key: "connected",
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, _react.default.createElement(_HandleNoInfo.default, {
      hasInfo: participantsInfo[uid],
      onNoInfo: function onNoInfo() {
        navigation.navigate(_routesConsts.Routes.NETWORKING_PAGE);
      }
    }), _react.default.createElement(_OptimizeRemoteVideo.default, {
      participants: roomParticipantIds
    }), _react.default.createElement(_SubscriptionsByRoom.default, {
      roomId: roomId,
      screenSharer: screenSharer,
      roomParticipantIds: roomParticipantIds
    }), _react.default.createElement(_core.Box, null, _react.default.createElement(_DynamicSection.default, {
      shaping: "cards",
      darkColor: "#313131",
      lightColor: "#F2F2F2"
    }, function (color) {
      return _react.default.createElement(_core.Box, {
        display: "flex",
        flexDirection: "row",
        p: 1,
        pl: 2,
        pr: 2
      }, _react.default.createElement(_ToggleAudio.default, {
        roomId: roomId,
        speakerId: uid,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_ToggleVideo.default, {
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_SettingsButton.default, {
        iconButton: true,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1
      }), _react.default.createElement(_ToggleScreenShare.default, {
        room: roomId,
        color: color
      }), _react.default.createElement(_core.Box, {
        m: 1,
        display: "flex",
        flex: 1
      }), _react.default.createElement(_LeaveButton.default, {
        color: color
      }));
    })), _react.default.createElement(_Participants.default, {
      roomId: roomId,
      participantsInfo: participantsInfo,
      uid: uid,
      canUserMuteSpeaker: canUserMuteSpeaker
    }), roomId === 'voPxgI3T6cB6t6RsC4IE' && userProfile && _react.default.createElement("iframe", {
      src: "https://test-realtime-drawing.azurewebsites.net/?userName=".concat(userProfile.fullName),
      width: "1200",
      height: "900"
    })),
    connectingState: loadingPlaceholder('disconnected'),
    disconnectingState: loadingPlaceholder('disconnected'),
    reconnectingOverlay: loadingPlaceholder('reconnecting')
  })), _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0],
    alignItems: "center",
    justifyContent: "space-between"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, tableName)), mobileResolution && _react.default.createElement(_Spacing.default, {
    style: {
      margin: '-10px'
    },
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_networkingTableMenu.default, null)), _react.default.createElement(_Spacing.default, {
    pSpacing: ['l', 0],
    alignItems: "center"
  }, _react.default.createElement(_sessions.default, {
    improvedWithPreview: false,
    showFullSchedule: false
  }))), !mobileResolution && _react.default.createElement(_networkingTableMenu.default, null));
}