var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PaymentPopupInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon.js"));

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _Paragraph = _interopRequireDefault(require("../common/typography/Paragraph"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var currencyMap = {
  USD: '$',
  EUR: '€'
};

var PaymentPopupInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(PaymentPopupInternal, _React$PureComponent);

  var _super = _createSuper(PaymentPopupInternal);

  function PaymentPopupInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, PaymentPopupInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePress = function () {
      _this.props.navigation.goBack();
    };

    return _this;
  }

  (0, _createClass2.default)(PaymentPopupInternal, [{
    key: "render",
    value: function render() {
      var _this2 = this;

      var currency = this.props.ticket.get('currency');
      var price = this.props.ticket.get('price');
      return _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.surface
      }, _react.default.createElement(_reactNativePaper.Subheading, {
        style: styles.title
      }, "Full Experience"), _react.default.createElement(_reactNative.View, {
        style: styles.comment
      }, _react.default.createElement(_reactNative.View, {
        style: styles.commentText
      }, _react.default.createElement(_Paragraph.default, {
        style: styles.commentMessage
      }, 'Enjoy the full live experience'))), _react.default.createElement(_reactNativePaper.TouchableRipple, {
        testID: 'goBackTouch',
        borderless: false,
        onPress: function onPress() {
          return _this2.props.navigation.goBack();
        },
        style: styles.closeButton
      }, _react.default.createElement(_CustomIcon.default, {
        name: "close",
        size: 16,
        color: "rgba(0,0,0,1)"
      })), _react.default.createElement(_reactNativePaper.Button, {
        mode: "outlined",
        testID: "payButton",
        onPress: this.handlePress,
        style: styles.actionButton,
        color: "black"
      }, "PAY ", currencyMap[currency], price)));
    }
  }]);
  return PaymentPopupInternal;
}(_react.default.PureComponent);

exports.PaymentPopupInternal = PaymentPopupInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  surface: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    paddingTop: 8,
    width: '100%',
    maxWidth: 560,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    elevation: 2
  },
  title: {
    textAlign: 'center',
    color: 'black',
    marginBottom: 24,
    fontWeight: 'bold'
  },
  text: {
    color: 'black',
    marginBottom: 20,
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    top: 0,
    width: 48,
    height: 48
  },
  actionButton: {
    width: '100%',
    maxWidth: 450,
    paddingVertical: 3,
    borderColor: 'black'
  },
  comment: {
    flexDirection: 'row',
    marginBottom: 24,
    paddingHorizontal: 16
  },
  commentAvatar: {
    marginRight: 8
  },
  commentText: {
    flexDirection: 'column'
  },
  commentUserName: {
    color: '#000',
    fontWeight: 'bold',
    marginVertical: 0
  },
  commentMessage: {
    color: '#000',
    marginVertical: 0
  }
});

var _default = (0, _reactRedux.connect)(null, {})((0, _reactNavigation.withNavigation)((0, _reactNativePaper.withTheme)(PaymentPopupInternal)));

exports.default = _default;