var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LeaderboardPrizes;

var _react = _interopRequireDefault(require("react"));

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _readMore = _interopRequireDefault(require("../../utils/read-more"));

var _Headline = _interopRequireDefault(require("../../../common/typography_v1/Headline"));

var _leaderboardPrizesStyles = _interopRequireDefault(require("./leaderboard-prizes-styles"));

function LeaderboardPrizes(props) {
  var totalPrize = props.totalPrize;
  var headLine = 'Top DJs earn prizes every month';

  if (totalPrize) {
    headLine = "Top DJs win more than \n".concat(totalPrize, " every month");
  }

  return _react.default.createElement(_reactNativeLinearGradient.default, {
    useAngle: true,
    angle: 165,
    angleCenter: {
      x: 0.6,
      y: 0
    },
    locations: [0, 1],
    colors: ['rgba(143, 0, 255, 0.8)', 'rgba(255, 255, 255, 0)'],
    style: _leaderboardPrizesStyles.default.leaderboardHeader
  }, _react.default.createElement(_Headline.default, {
    style: _leaderboardPrizesStyles.default.headerHeadline
  }, headLine), _react.default.createElement(_readMore.default, null));
}