var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShareButtonView = ShareButtonView;
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _shareButtonStyles = _interopRequireDefault(require("./share-button-styles"));

var _Title = _interopRequireDefault(require("../../common/typography_v1/Title"));

var _ParagraphSmall = _interopRequireDefault(require("../../common/typography_v1/ParagraphSmall"));

var _CustomIcon = _interopRequireDefault(require("../../../assets/icons/CustomIcon.js"));

function ShareButtonView(props) {
  var colors = props.theme.colors;
  return _react.default.createElement(_reactNative.View, {
    style: _shareButtonStyles.default.sectionShareMore
  }, _react.default.createElement(_reactNative.View, {
    style: _shareButtonStyles.default.iconContainer
  }, _react.default.createElement(_CustomIcon.default, {
    name: "share",
    size: 24,
    color: colors.text
  })), _react.default.createElement(_reactNative.View, {
    style: _shareButtonStyles.default.sectionShareMoreText
  }, _react.default.createElement(_Title.default, {
    style: _shareButtonStyles.default.sectionTitle
  }, "Share your profile"), _react.default.createElement(_ParagraphSmall.default, {
    style: _shareButtonStyles.default.textButton
  }, "to earn more coconuts for each user that signs up")));
}

var _default = (0, _reactNativePaper.withTheme)(ShareButtonView);

exports.default = _default;