var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BlockView;

var _button = _interopRequireDefault(require("design-system/actions/button"));

var _iconButton = _interopRequireDefault(require("design-system/actions/icon-button"));

var _section = _interopRequireDefault(require("design-system/structure/section"));

var _Stack = _interopRequireDefault(require("design-system/structure/Stack"));

var _headingView = _interopRequireDefault(require("design-system/typography/heading-view"));

var _subheadingView = _interopRequireDefault(require("design-system/typography/subheading-view"));

var _textContainerView = _interopRequireDefault(require("design-system/typography/text-container-view"));

var _textView = _interopRequireDefault(require("design-system/typography/text-view"));

var _react = _interopRequireDefault(require("react"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _AvatarImage = _interopRequireDefault(require("../../common/avatar/AvatarImage"));

var _reportOrBlockStyles = _interopRequireDefault(require("./report-or-block-styles"));

function BlockView(props) {
  var onPressBlockConfirm = props.onPressBlockConfirm,
      fullName = props.fullName,
      avatar = props.avatar;
  var navigation = (0, _useNavigationExtended.default)();
  var nameInitials = fullName.split(' ', 2).map(function (i) {
    return i.charAt(0);
  });
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_section.default, {
    spacingHorizontal: "loose",
    spacingVertical: "tight"
  }, _react.default.createElement(_Stack.default, {
    distribution: "center"
  }, _react.default.createElement(_headingView.default, {
    inverted: true,
    textStyle: "center"
  }, "Are you sure you wanna ", '\n', " block this person?"))), _react.default.createElement(_section.default, {
    spacingVertical: "loose",
    spacingHorizontal: "base"
  }, _react.default.createElement(_Stack.default, {
    vertical: true,
    alignment: "center",
    spacing: "tight"
  }, _react.default.createElement(_AvatarImage.default, {
    size: 48,
    source: {
      uri: avatar
    },
    defaultLabel: nameInitials
  }), _react.default.createElement(_subheadingView.default, {
    inverted: true,
    textStyle: "strong"
  }, fullName.toString()))), _react.default.createElement(_section.default, {
    spacing: "base",
    spacingTop: "none"
  }, _react.default.createElement(_textContainerView.default, null, _react.default.createElement(_textView.default, {
    inverted: true
  }, "Blocking will hide all streams and clips of this person, filter them out of all chats, and prevent them from contacting you."), _react.default.createElement(_textView.default, {
    inverted: true
  }, "You can manage your blocked list at any time from settings."))), _react.default.createElement(_iconButton.default, {
    inverted: true,
    icon: "close",
    name: 'goBackTouch',
    onPress: function onPress() {
      return navigation.goBack();
    },
    style: _reportOrBlockStyles.default.closeButton
  }), _react.default.createElement(_section.default, {
    spacing: "base",
    spacingBottom: "loose"
  }, _react.default.createElement(_button.default, {
    inverted: true,
    fullWidth: true,
    size: "large",
    name: "reportButton",
    onPress: onPressBlockConfirm
  }, "Block")));
}