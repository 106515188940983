var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../../core/user"));

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var LoggedInGuard = function LoggedInGuard(_ref) {
  var children = _ref.children;
  var isLoggedIn = (0, _reactRedux.useSelector)(_user.default.selectors.isFirebaseUserLoggedIn);

  if (!isLoggedIn) {
    return children;
  }

  return _react.default.createElement(_Redirect.default, {
    to: _routesConsts.Routes.HOME
  });
};

var _default = LoggedInGuard;
exports.default = _default;