var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ErrorPopupInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigation = require("react-navigation");

var _Button = _interopRequireDefault(require("./Button"));

var _CustomIcon = _interopRequireDefault(require("../../assets/icons/CustomIcon"));

var _Paragraph = _interopRequireDefault(require("../common/typography_v1/Paragraph"));

var _reactRedux = require("react-redux");

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ERROR_PAYMENT_PROCESSING = 'There was an error processing your payment. Please try again.';
var ERROR_VIDEO = 'It seems like this show is no longer avaialable ot has technical issues.';

var ErrorPopupInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ErrorPopupInternal, _React$PureComponent);

  var _super = _createSuper(ErrorPopupInternal);

  function ErrorPopupInternal() {
    (0, _classCallCheck2.default)(this, ErrorPopupInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(ErrorPopupInternal, [{
    key: "render",
    value: function render() {
      var _this = this;

      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactNative.View, {
        style: styles.container
      }, _react.default.createElement(_reactNative.View, {
        style: styles.card
      }, _react.default.createElement(_reactNative.View, {
        style: styles.iconParent
      }, _react.default.createElement(_CustomIcon.default, {
        name: "alert-triangle",
        size: 48,
        color: 'black'
      })), _react.default.createElement(_reactNative.View, {
        style: styles.infoParent
      }, this.props.isPaymentError ? _react.default.createElement(_Paragraph.default, {
        style: styles.infoText
      }, ERROR_PAYMENT_PROCESSING) : null, this.props.isVideoError ? _react.default.createElement(_Paragraph.default, {
        style: styles.infoText
      }, ERROR_VIDEO) : null), _react.default.createElement(_reactNative.View, {
        style: {
          width: 80
        }
      }, _react.default.createElement(_Button.default, {
        mode: "outlined",
        testID: "okButton",
        onPress: function onPress() {
          return _this.props.navigation.dispatch(_reactNavigation.NavigationActions.back());
        },
        style: {
          marginVertical: 36
        },
        buttonSize: "small",
        color: "black",
        dark: false
      }, 'Ok')))));
    }
  }]);
  return ErrorPopupInternal;
}(_react.default.PureComponent);

exports.ErrorPopupInternal = ErrorPopupInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  card: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    borderRadius: 8,
    backgroundColor: 'white'
  },
  iconParent: {
    marginVertical: 32,
    marginHorizontal: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoParent: {
    marginHorizontal: 32,
    paddingHorizontal: 8
  },
  infoText: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
    textAlign: 'center',
    opacity: 0.5
  }
});

var _default = (0, _reactRedux.connect)(null, {})(ErrorPopupInternal);

exports.default = _default;