var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RegisterParticipantAudioInStream;

var _react = _interopRequireDefault(require("react"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

function RegisterParticipantAudioInStream(_ref) {
  var audioTrack = _ref.audioTrack,
      destinationStream = _ref.destinationStream,
      context = _ref.context;

  _react.default.useEffect(function () {
    if (!context) return;
    if (!destinationStream) return;
    if (!audioTrack) return;
    var media = new MediaStream();
    media.addTrack(audioTrack.getMediaStreamTrack());
    var audioIn = context.createMediaStreamSource(media);
    audioIn.connect(destinationStream);

    _logger.default.debug('An audio track registered!');

    return function () {
      _logger.default.debug('An audio track unregistered!');

      audioIn.disconnect();
    };
  }, [destinationStream, audioTrack, context]);

  return null;
}