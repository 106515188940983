var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _errors$global$backst;

var _default = (_errors$global$backst = {
  errors: {
    error404: '',
    auth: {
      'missing-continue-uri': 'Taotluses tuleb esitada jätkamise URL.',
      'network-request-failed': 'On ilmnenud võrguviga (näiteks aja ületamine, katkenud ühendus või kättesaamatu host).',
      'null-user': 'Operatsioonile, mis nõuab mittenull kasutajaobjekti, esitati argumendina null kasutajaobjekt.',
      'redirect-operation-pending': 'Ümbersuunatud sisselogimise operatsioon on juba pooleli.',
      'too-many-requests': 'Juurdepääs sellele kontole on ajutiselt blokeeritud paljude ebaõnnestunud sisselogimiskatsete tõttu. Saate selle kohe taastada, lähtestades oma salasõna või võite proovida hiljem uuesti.',
      'account-exists-with-different-credential': 'Konto on juba olemas sama e-posti aadressiga, kuid erinevate sisselogimisandmetega. Logige sisse, kasutades selle e-posti aadressiga seotud teenusepakkujat.',
      'missing-android-pkg-name': 'Androidi paketi nimi tuleb esitada, kui Androidi rakendus tuleb paigaldada.',
      'internal-error': 'Midagi läks valesti, viga.',
      'invalid-auth-event': 'Midagi läks valesti, viga.',
      'missing-iframe-start': 'Midagi läks valesti, viga.',
      'no-auth-event': 'Midagi läks valesti, viga.',
      'missing-ios-bundle-id': "App Store'i ID esitamisel tuleb esitada iOSi paketi ID.",
      'auth-domain-config-required': "Kindlasti lisage authDomain, kui kutsute firebase.initializeApp(), järgides Firebase'i konsooli juhiseid.",
      'cordova-not-ready': 'Cordova raamistik ei ole valmis.',
      'invalid-oauth-provider': 'EmailAuthProvider ei ole selle toimingu puhul toetatud. See toiming toetab ainult OAuth teenusepakkujaid.',
      'unsupported-first-factor': 'Teise teguri sisselogimine või mitmefaktorilise kontoga sisselogimine nõuab sisselogimist toetatud esimese teguriga.',
      'email-change-needs-verification': 'Mitmefaktorilistel kasutajatel peab alati olema kontrollitud e-posti aadress.',
      'missing-multi-factor-info': 'Teise teguri identifikaatorit ei ole esitatud.',
      'dynamic-link-not-activated': "Palun aktiveerige dünaamilised lingid Firebase'i konsoolis ja nõustuge tingimustega.",
      'multi-factor-auth-required': 'Sisselogimise lõpuleviimiseks on vaja tõendada teise teguri omandiõigust.',
      'expired-action-code': 'Tegevuskood on aegunud.',
      'invalid-action-code': 'Tegevuskood on kehtetu. See võib juhtuda, kui kood on valesti vormistatud, aegunud või juba kasutatud.',
      'invalid-tenant-id': 'Auth-instantsi üürniku ID on kehtetu.',
      'invalid-continue-uri': 'Taotluses esitatud jätkamise URL on kehtetu.',
      'unsupported-persistence-type': 'Praegune keskkond ei toeta määratud püsivusetüüpi.',
      'custom-token-mismatch': 'Kohandatud märgis vastab erinevale sihtrühmale.',
      'invalid-custom-token': 'Kohandatud sümboli vorming on vale. Palun vaadake dokumentatsiooni.',
      'unauthorized-continue-uri': "Jätkamise URL-i domeen ei ole valges nimekirjas. Palun lisage domeen Firebase'i konsoolis valimisse.",
      'invalid-email': 'E-posti aadress on halvasti vormistatud.',
      'invalid-recipient-email': 'Sellele tegevusele vastavat e-kirja ei õnnestunud saata, kuna esitatud vastuvõtja e-posti aadress on kehtetu.',
      'wrong-password': 'E-post või parool on kehtetu või kasutajal puudub parool.',
      'invalid-sender': "Sellele tegevusele vastav e-posti mall sisaldab vale saatja e-posti aadressi või nime. Palun parandage see, minnes Firebase Console'i jaotises Auth email templates.",
      'invalid-message-payload': "Sellele toimingule vastav e-posti mall sisaldab sõnumis kehtetuid sümboleid. Palun parandage see, minnes Firebase Console'i jaotises Auth emaili mallid.",
      'invalid-cordova-configuration': 'OAuth sisselogimise võimaldamiseks peavad olema paigaldatud järgmised Cordova pluginad: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser ja cordova-plugin-customurlscheme.',
      'invalid-phone-number': 'Esitatud telefoninumbri vorming on vale. Palun sisestage telefoninumber E.164 formaadis. E.164 telefoninumbrid kirjutatakse formaadis [+][riigi kood][abonendi number koos suunanumbriga].',
      'operation-not-allowed': "Antud sisselogimise teenusepakkuja on selle Firebase'i projekti jaoks välja lülitatud. Lubage see Firebase'i konsoolis, jaotise Auth vahekaardil sisselogimismeetod.",
      'maximum-second-factor-count-exceeded': 'Kasutajale lubatud maksimaalne teiseseid faktoreid on ületatud.',
      'invalid-app-id': 'Mobiilirakenduse identifikaator ei ole praeguse projekti jaoks registreeritud.',
      'invalid-oauth-client-id': 'Esitatud OAuth-kliendi ID on kas kehtetu või ei vasta määratud API võtmele.',
      timeout: 'Operatsioon on aegunud.',
      'unverified-email': 'Operatsioon nõuab kontrollitud e-posti aadressi.',
      'weak-password': 'Parool peab olema vähemalt 6 tähemärki pikk.',
      'missing-verification-code': 'Telefoni autentimisvolitus loodi tühja SMS-koodiga.',
      'missing-verification-id': 'Telefoni autoriseerimisvolitus loodi tühja kontrolltunnusega.',
      'invalid-app-credential': 'Telefoni kontrollitaotlus sisaldab kehtetut rakenduse verifitseerijat. ReCAPTCHA-tokendi vastus on kas kehtetu või aegunud.',
      'missing-app-credential': 'Telefoni kontrollitaotluses puudub rakenduse kontrollija kinnitus. Tuleb esitada reCAPTCHA vastusmärk.',
      'popup-closed-by-user': 'Kasutaja sulges hüpikakna enne operatsiooni lõpetamist.',
      'quota-exceeded': 'Projekti kvoot selle toimingu jaoks on ületatud.',
      'invalid-dynamic-link-domain': 'Antud dünaamilise lingi domeen ei ole praeguse projekti jaoks konfigureeritud või lubatud.',
      'tenant-id-mismatch': 'Esitatud üürniku ID ei vasta Auth-instantsi üürniku ID-le.',
      'captcha-check-failed': 'Esitatud reCAPTCHA vastusmärk on kas kehtetu, aegunud, juba kasutatud või sellega seotud domeen ei vasta valges nimekirjas olevate domeenide loetelule.',
      'redirect-cancelled-by-user': 'Kasutaja tühistas ümbersuunamisoperatsiooni enne lõpetamist.',
      'rejected-credential': 'Taotlus sisaldab valesti vormistatud või mittevastavaid volitusi.',
      'missing-or-invalid-nonce': "Taotlus ei sisalda kehtivat nonce'i. See võib juhtuda, kui esitatud töötlemata nonce'i SHA-256-hash ei ühti ID-tokendi kasuliku koormuse hashitud nonce'iga.",
      'invalid-multi-factor-session': 'Taotlus ei sisalda kehtivat tõendit esimese teguri eduka sisselogimise kohta.',
      'missing-multi-factor-session': 'Taotluses puudub tõend esimese teguri eduka sisselogimise kohta.',
      'app-not-installed': 'Esitatud identifikaatorile (Android-paketi nimi või iOS-paketi ID) vastav taotletud mobiilirakendus ei ole sellesse seadmesse paigaldatud.',
      'second-factor-already-in-use': 'Teine tegur on juba sellel kontol registreeritud.',
      'invalid-cert-hash': 'Esitatud SHA-1 sertifikaadi hash on kehtetu.',
      'code-expired': 'SMS-kood on aegunud. Palun saatke kinnituskood uuesti, et proovida uuesti.',
      'invalid-verification-code': 'Telefoni autoriseerimisvolituse loomiseks kasutatud SMS-kood on kehtetu. Palun saatke kinnituskoodi SMS uuesti ja kasutage kindlasti kasutaja antud kinnituskoodi.',
      'invalid-persistence-type': 'Määratud püsivusetüüp on kehtetu. See võib olla ainult kohalik, seanss või mitte mingi.',
      'invalid-provider-id': 'Määratud teenusepakkuja ID on kehtetu.',
      'invalid-credential': 'Esitatud auth-volitus on valesti vormistatud või aegunud.',
      'user-mismatch': 'Esitatud volitused ei vasta eelnevalt sisselogitud kasutajale.',
      'user-disabled': 'Administraator on kasutajakonto keelanud.',
      'user-cancelled': 'Kasutaja ei andnud teie rakendusele taotletud õigusi.',
      'multi-factor-info-not-found': 'Kasutajal ei ole teist tegurit, mis vastaks esitatud identifikaatorile.',
      'user-token-expired': 'Kasutaja volitused ei ole enam kehtivad. Kasutaja peab uuesti sisse logima.',
      'invalid-verification-id': 'Telefoni autoriseerimistunnistuse loomiseks kasutatud kontrolltunnus on kehtetu.',
      'user-not-found': 'Sellele identifikaatorile vastavat kasutajarekordit ei ole. Kasutaja võib olla kustutatud.',
      'app-not-authorized': "Rakenduses, mis on tuvastatud domeeni järgi, kus see asub, ei ole lubatud kasutada Firebase'i autentimist antud API võtmega. Vaadake oma võtme konfiguratsioon Google API konsoolis üle.",
      'web-storage-unsupported': 'Brauser ei toeta või kolmanda osapoole küpsised ja andmed võivad olla keelatud.',
      'cors-unsupported': 'Brauser ei ole toetatud.',
      'credential-already-in-use': 'Volitus on juba seotud teise kasutajakontoga.',
      'unauthorized-domain': "See domeen ei ole teie Firebase'i projekti jaoks OAuth-operatsioonide jaoks lubatud. Redigeerige volitatud domeenide nimekirja Firebase'i konsoolist.",
      'app-deleted': 'FirebaseAppi instants on kustutatud.',
      'cancelled-popup-request': 'See toiming on tühistatud, kuna on avatud teine vastuoluline hüpikaken.',
      'unsupported-tenant-operation': 'Seda toimingut ei toetata mitme rentniku kontekstis.',
      'operation-not-supported-in-this-environment': 'Seda toimingut ei toetata keskkonnas, kus see rakendus töötab. "location.protocol" peab olema http, https või chrome-extension ja veebimälu peab olema lubatud.',
      'admin-restricted-operation': 'Toiming on piiratud ainult administraatoritele.',
      'requires-recent-login': 'See toiming on tundlik ja nõuab hiljutist autentimist. Logige enne selle päringu uuesti proovimist uuesti sisse.',
      'invalid-user-token': 'Selle kasutaja volitused ei kehti selle projekti jaoks. See võib juhtuda, kui kasutaja sümbolit on võltsitud või kui kasutaja ei ole selle API-võtmega seotud projekti jaoks.',
      'missing-phone-number': 'Kinnituskoodide saatmiseks esitage vastuvõtja telefoninumber.',
      'popup-blocked': 'Ei õnnestu luua ühendust hüpikaknaga. Brauser võib olla selle blokeerinud.',
      'provider-already-linked': 'Kasutajat saab siduda ainult ühe identiteediga antud teenusepakkuja puhul.',
      'no-such-provider': 'Kasutaja ei olnud seotud antud teenusepakkuja kontoga.',
      'invalid-api-key': 'Teie API võti on kehtetu, palun kontrollige, et olete selle õigesti kopeerinud.',
      'argument-error': '',
      'user-signed-out': ''
    },
    firewall3: 'lubada juurdepääsu domeenile *.googleapis.com.',
    error: 'Viga',
    firewall: 'Tulemüüri probleem',
    firewall2: 'Palun võtke ühendust oma võrgu- või IT-administraatoriga ja paluge, et',
    somethingWentWrong: 'Midagi läks valesti. Palun proovige uuesti!',
    'already-exists': 'E-posti aadress on juba kasutusel teise konto poolt.',
    eventNotExists: 'Seda sündmust ei ole olemas.',
    tryAgain: 'Proovi uuesti',
    firewall1: 'Te võite olla tulemüüri taga, mis blokeerib ühendused'
  },
  global: {
    today: '[Täna]',
    tomorrow: '[Homme]',
    back: 'Tagasi',
    backToPage: 'Tagasi eelmisele lehele',
    cancel: 'Tühista',
    days: 'päev(ad)',
    download: 'Allalaadimine',
    hours: 'tund(i)',
    minutes: 'minutit',
    no: 'Ei',
    invalidSpeakerProfile: 'See ei ole kehtiv kõneleja profiil.',
    yes: 'Jah'
  },
  backstage: {
    sessionStarts: '{{name}} alustas seanssi.',
    userDenied: 'Juurdepääs keelatud',
    adminSpeakerRequestFailed: 'Esitleja lubamise taotlus ebaõnnestus',
    startSessionInfo: 'Pärast sessiooni algust saavad osalejad näha ja kuulata sessiooni esinejaid.',
    kickOutSpeaker: 'Kas olete kindel, et soovite {{name}} sellest otseülekandest eemaldada?',
    kick: 'Eemalda',
    kickUserFromLive: 'Eemalda {{name}} eetrist',
    error: {
      unauthenticated: 'Autentimine on vajalik.',
      backstageSessionStartFailed: 'Backstage seansi alustamine ebaõnnestus. Palun proovige hiljem uuesti.',
      backstageSessionStopFailed: 'Backstage seansi peatamine ebaõnnestus. Palun proovige hiljem uuesti.',
      speakerJoinRequestFailed: 'Otseülekande loomine ebaõnnestus. Proovi hiljem uuesti',
      noFeaturesError: 'Lubatud funktsioone pole ette antud.',
      somethingWentWrong: 'Midagi läks valesti',
      internalServerError: 'Midagi läks valesti, palun proovige hiljem uuesti.',
      internal: 'Midagi läks valesti, palun proovige hiljem uuesti.',
      adminSpeakerRequestFailed: 'Midagi läks valesti, kõneleja taotlust ei saanud vastu võtta',
      tableIsFull: 'Vabandust, videokõne ruum on täis. Palun proovige hiljem uuesti.',
      kickOutSpeakerFailed: 'Kõnelejat ei saanud välja visata',
      maximumNumberOfParticipantsIsReached: "Backstage'id on täis. Praegu ei saa rohkem esinejaid backstage'i liituda.",
      requestedResourceNotFound: "Taotletud ressurssi ei leitud. Selle backstage'i URL näib olevat vigane. Palun kontrollige uuesti.",
      noFeaturePermissionError: 'Teil ei ole selle tegevuse kasutamiseks luba.',
      backstageIdValidationError: 'Teil ei ole selle tegevuse kasutamiseks luba.',
      speakerIdValidationError: 'Teil ei ole selle tegevuse kasutamiseks luba.',
      eventIdValidationError: 'Teil ei ole selle tegevuse kasutamiseks luba.'
    },
    goBackToLive: 'Tagasi otseülekande juurde',
    header: {
      backstage: 'Backstage',
      greenRoom: 'Green room',
      session: 'Sessioon'
    },
    bestOnDesktop: 'Backstage töötab kõige paremini suurtel ekraanidel',
    tableIsFull: 'Helistamisruum on täis',
    no: 'Tühista',
    cancel: 'Tühista',
    checkSettings: 'Kontrolli oma heli ja video seadeid',
    goToBackstage: 'Jätka',
    welcomeToTheBackstageText: 'Vestlused siin on privaatsed. Sündmuse osalejad ei näe teid enne, kui saatejuht alustab sessiooni.',
    speakerJoinRequestFailed: 'Otseülekande loomine ebaõnnestus',
    iAmReady: 'Olen valmis',
    internalServerError: 'Serveri sisemine viga',
    internal: 'Serveri sisemine viga',
    join: 'Liitu',
    readyConfirmationTitle: 'Liitu sessiooniga?',
    leave: 'Lahku',
    leaveLive: 'Lahku otseülekandest',
    speakerLeaveRequestFailed: 'Otseülekandest lahkumine ebaõnnestus',
    checkSettingsText: 'Veenduge, et kaamera ja mikrofon töötavad.',
    noRequests: 'Ei ole taotlusi',
    ok: 'OK',
    continueToGreenRoom: 'Ok, jätkake',
    iAmReadyTooltipOk: 'Ok, sain aru!',
    sessionStarting: 'Sessioon algab...',
    someone: 'Keegi',
    start: 'Start',
    startBackstageSession: 'Seansi alustamine',
    startThisSession: 'Alustame sessiooni?',
    startsIn: 'Algab:',
    stop: 'Stop',
    stopBackstageSession: 'Seansi peatamine',
    participantsCanSeeYou: 'Sündmusel osalejad näevad ja kuulevad teid.',
    participantsCannotSeeYou: 'Sündmusel osalejad ei näe ega kuule teid veel.',
    welcomeToTheGreenRoomText: 'See on selle sündmuse privaatne tervitusala.',
    waitingForApproval: 'Ootab heakskiitu',
    welcomeToTheBackstage: "Tere tulemast Backstage'i!",
    iAmReadyTooltip: 'Kui olete valmis seansiga liituma, klõpsake nuppu "Olen valmis". Istungi korraldajale teatatakse teie saabumisest.',
    welcomeToTheGreenRoom: 'Olete nüüd rohelises ruumis',
    readyConfirmation: 'Saate liituda praeguse seansiga. Publik näeb ja kuuleb teid.',
    welcomeToTheGreenRoomText2: 'Saate proovida videofunktsioone enne otseülekande alustamist. Sündmusel osalejad ei näe teid.',
    userDeniedText: "Saatejuht on keelanud teile juurdepääsu backstage'ile. Küsige seda vabalt saatejailt Backstage Chatis."
  },
  resetPassword: {
    mailSent: 'Teie e-posti aadressile saadeti parooli taastamise e-kiri.',
    invalidCode: 'Aegunud salasõna lähtestamise kood',
    enterEmail: 'Sisestage registreerimisel kasutatud e-posti aadress',
    resetActionText: 'Sisestage allpool oma uus parool ja vajutage parooli lähtestamiseks nuppu "Kinnita".',
    resetPassword: 'Parooli lähtestamine',
    success: 'Salasõna lähtestamine oli edukas',
    successText: 'Teie salasõna on edukalt lähtestatud. Nüüd võite proovida uue parooliga sisse logida.',
    invalidCodeText: 'Teie parooli lähtestamise kood on aegunud - see on aktiivne ainult umbes tund aega. Palun proovige lähtestamist uuesti.'
  },
  register: {
    displayNameRequired: 'Vajalik on kasutajanimi. Palun sisestage üks. See ei tohiks olla teie e-posti aadress',
    emailInvalid: 'Lisa toimiv e-posti aadress',
    displayNameUpdate: 'Lisa oma kasutajanimi',
    alreadyHaveAccount: 'Kas teil on juba konto?',
    and: 'ja',
    termsAndConditions: 'Registreerudes kinnitate, et nõustute järgmiste tingimustega',
    passwordConfirmRequired: 'Nõutav on parooli kinnitamine',
    confirmPassword: 'Parooli kinnitamine*',
    passwordConfirm: 'Parooli kinnitamine*',
    continue: 'Jätka',
    emailRequired: 'E-posti aadress on nõutav',
    email: 'E-post*',
    username: 'Täisnimi',
    doNotClose: 'Hetk, me valmistame teile kõike ette!',
    usernameRequired: 'Nimi on nõutav',
    passwordRequired: 'Salasõna on nõutud',
    password: 'Salasõna*',
    displayNameUpdateInfo: 'Palun andke oma kasutajanimi - sest registreerimisprotsessis ei olnud see edukalt registreeritud.',
    policy: 'Privaatsuspoliitika',
    registration: 'Registreerimine',
    inProgressCompleted: 'Registreerimine lõpetatud.',
    inProgress: 'Registreerimine pooleli...',
    inProgressStepTwo: 'Registreerimine pooleli... 2/3 kasutaja profiili uuendamine',
    inProgressStepThree: 'Registreerimine pooleli... 3/3 registreerimise lõpetamine',
    saveDisplayName: 'Salvesta',
    error: {
      upload: 'Midagi läks valesti, palun proovige uuesti!',
      fileSize: 'Fail on liiga suur. Lubatud maksimaalne suurus on 50MB'
    },
    terms: 'Kasutustingimused',
    passwordMinCharacters: 'Parool peaks sisaldama vähemalt 6 tähemärki',
    passwordMatch: 'Paroolid ei sobi kokku',
    fieldRequired: 'Väli on nõutud',
    ticket: 'Pilet'
  },
  header: {
    about: 'Kirjeldus',
    benefits: 'Eelised',
    booths: 'Esitlusalad',
    checklist: 'Loend',
    lobbyPage: 'Otseülekanne',
    myFiles: 'Minu failid',
    myProfile: 'Minu profiil',
    myTickets: 'Minu piletid',
    partners: 'Partnerid',
    schedule: 'Ajakava',
    logout: 'Kirjutage välja',
    speakers: 'Kõnelejad',
    sponsors: 'Sponsorid',
    testimonials: 'Soovitused'
  },
  chat: {
    abuse: 'Kuritarvitus',
    blockUser: 'Blokeeri kasutaja',
    chat: 'Vestlus',
    firewallClickHere: 'Vajuta siia',
    deleteComment: 'Kustuta kommentaar',
    deleting: 'Kustutan...',
    firewallInstructions: 'tulemüüri seadistamise juhiseid.',
    unreadMessage: 'uus sõnum',
    unreadMessages: 'uued sõnumid',
    noBlockedUsers: 'Blokeeritud kasutajaid ei ole',
    writeAComment: 'Kommentaarid puuduvad. Võite minna esimesena!',
    writeAQuestion: 'Küsimusi veel ei ole. Võite küsida esimesena!',
    noUsers: 'Kasutajaid hetkel pole',
    position: {
      organizer: 'KORRALDAJA'
    },
    people: 'Inimesed',
    reconnecting: 'Ühendame uuesti...',
    retry: 'Proovi uuesti',
    join: 'Proovi uuesti',
    saySomethingNice: 'Ütle midagi toredat',
    sending: 'Saatmine...',
    disabledMessage: 'Sündmuse korraldaja lülitas vestluse välja.',
    unblockUser: 'Kasutajate blokeeringu tühistamine',
    youNotInChatRoom: 'Te ei ole ühendatud või võite olla tulemüüri taga, mis blokeerib ühenduse!',
    noPermission: 'Sul ei ole luba!',
    youDisconnectedFromChat: 'Teid katkestati vestlusest. Palun proovige uuesti!',
    youDisconnectedFromQA: 'Teid lahutati küsimuste ja vastuste sektsioonist. Palun proovige uuesti!',
    youBlockedFromChat: 'Sündmuse korraldaja blokeeris teie juurdepääsu vestlusele.',
    youBlockedFromQA: 'Sündmuse korraldaja blokeeris teie juurdepääsu küsimuste ja vastuste sektsioonile.',
    yourQuestion: 'Teie küsimus...'
  },
  menuItems: {
    abuse: 'Kuritarvitus',
    backstageChat: 'Backstage vestlus',
    booth: 'Esitlusala',
    chat: 'Vestlus',
    files: 'Failid',
    people: 'Inimesed',
    polls: 'Küsitlused',
    qa: 'Q & A',
    requests: 'Taotlused'
  },
  login: {
    emailValid: 'Lisa toimiv e-posti aadress',
    emailWithLoginUrlSent: 'Sinu e-posti aadressile saadeti kiri. Kliki kirjas olevale lingile, et sündmusele sisse logida.',
    emailRequired: 'E-posti aadress on nõutud',
    email: 'E-post*',
    forgotPassword: 'Unustasid salaõna?',
    forgotPasswordRetry: 'Vajan uut lähtestamise linki',
    passwordRequired: 'Salasõna on nõutud',
    password: 'Salasõna*',
    confirmYourEmail: 'Palun kinnitage oma e-posti aadress. Olete avanud sisselogimislingi mitte samal seadmel/selveris.',
    createNewAccount: 'Registreeri sündmusele',
    backToLoginButton: 'Proovi uuesti sisse logida',
    header: 'Logi sisse',
    loginButton: 'Logi sisse',
    login: 'Logi sisse',
    register: 'Registreeri',
    emailPassText: 'Logi sisse kasutades salaõna, mille lõite registreerudes. Või saate sisse logida',
    sendLoginEmailButton: 'Logi sisse e-postiga',
    emailPassTextLink: 'kasutade salasõnatut linki, mille saatsime teile e-postiga.',
    emailOnlyTextLink: 'kasutades salasõna.',
    emailOnlyText: 'Saadame sulle lingi salasõnatuks sisselogimiseks. Või saate sisse logida '
  },
  polls: {
    new: {
      option: 'Lisage küsitlusele valikuvõimalus',
      containsDuplicates: 'Korduvvalikud ei ole küsitlustes lubatud.',
      invalidFormatTitle: 'Vale vorming. Lubatud on ainult tähed, numbrid ja mõned märgid.',
      invalidFormatOption: 'Vale vorming. Lubatud on ainult tähed, numbrid ja mõned märgid.',
      titlePlaceholder: 'Minu küsitlus',
      op: 'Valik',
      save: 'Salvesta see küsitlus',
      numberOfOptions: 'Küsitlusvalikute arv',
      minOptionLength2: 'Valik peab sisaldama vähemalt 2 märki.',
      maxOptionLength100: 'Valik peab sisaldama maksimaalselt 100 tähemärki.',
      minOptions2: 'Küsitlus peab sisaldama vähemalt 2 valikut.',
      maxOptions10: 'Küsitlus võib sisaldada maksimaalselt 10 valikut.',
      titleMin2: 'Pealkiri peab sisaldama vähemalt 2 tähemärki.',
      titleMax150: 'Pealkiri peab sisaldama maksimaalselt 150 tähemärki.',
      title: 'Teie küsitluse pealkiri',
      titleRequired: 'Sa pead lisama oma küsitlusele pealkirja.'
    },
    deleteConfirmTextOptions: 'Kas olete kindel, et soovite selle valiku oma küsitlusest kustutada?',
    deleteConfirmText: 'Kas olete kindel, et soovite selle küsitluse kustutada?',
    backToPollsList: 'Tagasi minu küsitluste juurde',
    cancelDelete: 'Tühista',
    addNewPoll: 'Uue küsitluse loomine',
    noPollsYet: 'Selle sündmuse korraldaja ei ole veel ühtegi küsitlust loonud.',
    noVotesYet: 'Sellel küsitlusel ei ole veel ühtegi häält. Ole esimene, kes hääletab.',
    update: {
      optionAlreadyExists: 'Võimalus on juba olemas selles küsitluses.',
      update: 'Uuenda seda küsitlust'
    },
    respondToPolls: 'Kutsume teid üles vastama meie küsitlus(t)ele allpool:',
    confirmDelete: 'Jah, kustutage see',
    alreadyVoted: 'Te olete juba hääletanud selle küsitluse üle. Tänan teid.',
    yourPolls: 'Teie küsitlused'
  },
  checkout: {
    addToCalendar: 'Lisa kalendrise',
    addOns: 'Lisad',
    buyedOn: 'Ostetud',
    buyTicket: 'Osta pilet',
    payment: 'Lõpeta maksmine',
    conferenceTickets: 'Konverentsi piletid',
    free: 'Tasuta',
    allDays: 'Täielik sündmus',
    generalAccess: 'Üldine juurdepääs',
    myTickets: 'Minu piletid',
    next: 'Järgmine',
    optionalAddons: 'Valikulised lisad',
    otherAddons: 'Muud lisad',
    otherTickets: 'Muud piletid',
    addonsInstruction: 'Palun valige lisad, mida soovite oma tellimusele lisada.',
    promoCode: 'Promo kood',
    prmoCodeOutOfStock: 'Promokoodid on otsas',
    freeRegiser: 'Registreeru tasuta',
    summary: 'Kokkuvõte',
    ticket: 'Pilet',
    total: 'Kokku',
    validity: 'Kehtivus',
    approveTicket: 'Teie konto ootab sündmuse korraldaja heakskiitu. Kui teie konto on heaks kiidetud, saadetakse teile aktiveerimise e-kiri.'
  },
  configureTicket: {
    title: 'Juurdepääs sündmustele',
    ticket: 'Pilet',
    quantity: 'kogus',
    owned: 'omanduses'
  },
  addOns: {
    title: 'lisandmoodulid',
    multipleAddonsWarning: 'Valitud lisandmoodulid rakendatakse kõikidele piletitele.'
  },
  assignTickets: {
    pageTitle: 'Tickets zuweisen',
    thankYou: 'Vielen Dank für Ihren Einkauf!',
    thankYouDescription: 'Bitte geben Sie für jedes Ticket an, wer Zugang zur Veranstaltung haben wird.',
    email: 'e-post',
    fullName: 'Täisnimi',
    thisIsMyTicket: 'See on minu pilet',
    ticketAssigned: 'Määratud pilet',
    emailShouldBeUnique: 'E-posti aadressid peaksid olema unikaalsed',
    youAlreadyHaveThisTicket: 'Sul on juba see pilet olemas',
    assignedTickets: 'Määratud piletid',
    unassignedTickets: 'Määramata piletid',
    ticket: 'Pilet',
    addons: 'Lisandid',
    assignedTo: 'Määratud kasutajale',
    assignedDate: 'Määratud kuupäev'
  },
  lobby: {
    addToCalendar: 'Lisa kalendrise',
    joinLive: 'Liitu otseülekandega',
    remained: 'Ülejäänud',
    eventEnded: 'Sündmus on lõppenud.',
    eventStarted: 'Sündmus on käimas.',
    eventStartIn: 'Sündmus algab:',
    with: 'koos',
    writeHere: 'Kirjutage sõnum'
  }
}, (0, _defineProperty2.default)(_errors$global$backst, "configureTicket", {
  title: 'Juurdepääs sündmusele',
  ticket: 'Pilet',
  quantity: 'Kogus'
}), (0, _defineProperty2.default)(_errors$global$backst, "addOns", {
  title: 'Lisad'
}), (0, _defineProperty2.default)(_errors$global$backst, "billing", {
  create: {
    addressLine1Placeholder: 'Aadress',
    addressLine1: 'Aadress on nõutav',
    billingAddress: 'Arveldusaadress on nõutud',
    cardNumberPlaceholder: 'Kaardi number',
    cardholderName: 'Kaardiomaniku nimi on nõutud',
    cityPlaceholder: 'Linn',
    city: 'Linn on nõutav',
    email: 'E-posti aadress on nõutav',
    postalCodePlaceholder: 'Postiindeks',
    postalCode: 'Postiindeks on nõutav',
    allFields: 'NÕUTUD',
    invalidFormat: 'See ei ole kehtiv vorming'
  },
  billingAddress: 'Arveldusaadress',
  termsAndConditions2: 'Pileti eest tasudes nõustute meie',
  cardInformation: 'Kaardi andmed',
  cardHolderName: 'Kaardiomaniku nimi',
  country: 'Riik',
  email: 'E-post',
  pay: 'Maksa',
  payWithCard: 'Makske kaardiga',
  promoCode: 'Promo kood',
  stripe: 'Turvalised maksed, mida toetab',
  error: {
    country: "Arveldusriik näib olevat kehtetu / Stripe'i jaoks töötlemata.",
    incomplete_number: 'Kaardinumber näib olevat puudulik.',
    incomplete_cvc: 'CVC-väli on mittetäielik.',
    invalid_expiry_year: 'Aegumiskuupäev on vale.',
    incomplete_expiry: 'See on mittetäielik aegumiskuupäev.',
    invalid_number: 'See on kehtetu kaardinumber.'
  },
  currency: 'US',
  termsAndConditions: 'Te ostate konto jaoks'
}), (0, _defineProperty2.default)(_errors$global$backst, "requests", {
  admit: 'Luba',
  deny: 'Keela',
  isTheStageManager: 'lava manageris'
}), (0, _defineProperty2.default)(_errors$global$backst, "agora", {
  DEFAULT_DEVICE_IN_USE: 'Mingi teine programm kasutab teie kaamerat või mikrofoni. Palun sulgege see programm ja värskendage seda lehekülge.',
  'AgoraRTCError UNEXPECTED_ERROR: NotReadableError: Could not start video source': 'Mingi teine programm kasutab teie kaamerat või mikrofoni. Palun sulgege see programm ja värskendage seda lehekülge.',
  PERMISSION_DENIED: 'Ei õnnestu saada juurdepääsu meediaseadmele. Klõpsake seadme õiguste saamiseks hüpikaknas "Allow"!',
  DEVICE_NOT_FOUND: 'Seadmeid ei ole või varem valitud seadet ei leitud. Palun valige teine seade või lisage seade!'
}), (0, _defineProperty2.default)(_errors$global$backst, "audiovideoSettings", {
  apply: 'Rakenda',
  title: 'Audio ja video seaded',
  audioVideoSettingsFirefoxNotSupported: "Audio- ja videosätted ei tööta korralikult Mozilla Firefoxis, palun kasutage hoopis Chrome'i või Microsoft Edge'i.",
  audioInput: 'Heli sisend',
  audioOutput: 'Heli väljund',
  noLocalAudio: 'Kohalik heli puudub',
  noLocalVideo: 'Ei kohalik video',
  videoInput: 'Video sisend',
  description: 'Te kavatsete kasutada oma veebikaamerat. Selleks, et teised saaksid teid näha ja kuulda, taotleb teie brauser juurdepääsu teie kaamerale ja mikrofonile. Saate need siiski igal ajal tagasi välja lülitada.'
}), (0, _defineProperty2.default)(_errors$global$backst, "apr", 'apr'), (0, _defineProperty2.default)(_errors$global$backst, "april", 'aprill'), (0, _defineProperty2.default)(_errors$global$backst, "domain", {
  noAssociatedEvent: 'Hetkel ei ole kavandatud ühtegi sündmust.',
  noEventForDomain: 'Domeen, millega ei ole seotud sündmust.',
  severalEvents: 'Selle valdkonnaga on seotud mitu sündmust:'
}), (0, _defineProperty2.default)(_errors$global$backst, "aug", 'aug'), (0, _defineProperty2.default)(_errors$global$backst, "august", 'august'), (0, _defineProperty2.default)(_errors$global$backst, "liveNow", {
  backToNetworking: 'Tagasi võrgustiku juurde',
  start: 'Algab',
  break: 'Break',
  noPreview: 'Vajuta siia',
  fewSeconds: 'paar sekundit',
  goToNetworkingArea: '',
  past: 'Varasemad sessioonid',
  tryAgain: 'Palun värskendage lehte',
  waitingForStreamStart: 'Palun oodake. Vastuvõtja on alustamas voogesitust ...',
  refresh: 'Värskenda',
  noAccessNext: 'Vabandame, teie praegune pilet (või piletilisa valik) ei sisalda juurdepääsu eelseisvale seanssile. Palun proovige oma piletit või lisaseadmeid uuendada.',
  noAccess: 'Vabandame, teie praegune pilet (või lisaseadme valik) ei sisalda juurdepääsu sellele sisule. Palun proovige oma piletit uuendada.',
  eventFinished: 'Sündmus on lõppenud. Täname teid osalemise eest.',
  sessionWillStart: 'Sessioon algab peagi',
  sessionFinished: 'Seansid on sellel etapil lõppenud. Aitäh osalemise eest!',
  noLiveVideo: 'Otseülekandeid ei ole.',
  waitingForParticipants: 'Võrgustikuruumis osalejaid ei ole. Oodates osalejate sisenemist ...',
  invalidSession: 'See ei ole kehtiv eetri URL. Vabandust. Palun navigeerige tagasi "Eetris" lehele.',
  sessionDidNotStart: 'Sessioon ei alanud',
  sessionsEnded: 'Sessioon on lõppenud',
  sessionEnded: 'Sessioon on lõppenud',
  prerecordedVideoFinished: 'Sessioon on lõppenud. Jääge lainele meie järgmise sessiooni jaoks.',
  sessionStarts: 'Seanss algab:',
  upcoming: 'Eelseisvad sessioonid',
  upgradeTicket: 'Uuenda pilet',
  prerecordedVideoUnavailable: 'Ootame, et saatejuht (korraldaja) alustaks (RTMPS) otseülekannet või seostaks selle sessiooni jaoks eelnevalt salvestatud video.'
}), (0, _defineProperty2.default)(_errors$global$backst, "rightSidebarMenu", {
  backstageChat: 'Backstage vestlus',
  booth: 'Esitlusala',
  chat: 'Vestlus',
  files: 'Failid',
  people: 'Inimesed',
  speak: 'Räägi',
  uploadedFiles: 'Üleslaaditud failid'
}), (0, _defineProperty2.default)(_errors$global$backst, "profile", {
  bio: 'Bio',
  changeAvatar: 'Muuda avatari',
  email: 'E-post',
  twitterPlaceholder: 'Näiteks: https://twitter.com/your-twitter-page',
  facebookPlaceholder: 'Näiteks: https://www.facebook.com/your-facebook-page',
  instagramPlaceholder: 'Näiteks: https://www.instagram.com/your-instagram-page',
  linkedinPlaceholder: 'Näiteks: https://www.linkedin.com/your-linkedin-page',
  facebook: 'Facebook',
  displayName: 'Täisnimi',
  headline: 'Pealkiri',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  max100: 'Maksimaalne pikkus peab olema 100 tähemärki.',
  max50: 'Maksimaalne pikkus peab olema 50 tähemärki.',
  max800: 'Maksimaalne pikkus peab olema 800 tähemärki.',
  phone: 'Telefoninumber',
  phoneInvalid: 'Telefoninumber ei ole kehtiv.',
  success: 'Profiili andmed edukalt salvestatud.',
  bioRequired: 'Väli "Bio" on nõutav.',
  firstNameRequired: 'Väli "Eesnimi" on nõutav.',
  headlineRequired: 'Väli "Pealkiri" on nõutav.',
  lastNameRequired: 'Välja "Perekonnanimi" täitmine on nõutav.',
  twitter: 'Twitter'
}), (0, _defineProperty2.default)(_errors$global$backst, "expo", {
  booth: 'Esitlusala',
  registerMessage: 'Registreerides oma huvi, võtate vastu nende pakkumise ja teie kontaktandmed saadetakse partneri poolt lepingu sõlmimiseks.',
  files: 'Failid',
  noFiles: 'Ei mingeid faile...',
  visitBooth: 'Külasta boksi'
}), (0, _defineProperty2.default)(_errors$global$backst, "networking", {
  checkAudioAndVideoSettings: 'Kontrolli oma heli ja video seadeid',
  apply: 'Sulge',
  connectionError: 'Ühenduse viga',
  done: 'Tehtud',
  joinTheNetworkingArea: 'Mine võrgustiku lehele',
  internalServerError: 'Serveri sisemine viga',
  joinTable: 'Liitu lauaga',
  leaveTable: 'Lahku lauast',
  noDevices: 'Seadmeid ei ole',
  noImage: 'Pilt puudub',
  off: 'Väljaspool',
  ok: 'Ok',
  somethingBadHappened: 'Ooops! Midagi juhtus!',
  openSupportedBrowser: 'Palun külastage seda lehekülge ühe toetatud brauseriga.',
  error: {
    internalServerError: 'Midagi läks valesti, palun proovige hiljem uuesti.',
    tableIsFull: 'Vabandust, see laud on täis. Palun proovige uuesti, kui keegi lahkub lauast.'
  },
  supportedBrowsers: 'toetatud brauserid',
  tableIsFull: 'Laud on täis',
  takeASeat: 'Võtke istet',
  connectionErrorText: 'Ühendus ebaõnnestus ja saadi järgmine viga:',
  inactivityWarning: 'Süsteem annab teie koha kellelegi teisele {{seconds}} pärast {{seconds}} sekundit',
  unknownOS: 'Kahjuks ei õnnestunud teie seadme operatsioonisüsteemi tuvastada. Palun proovige teist brauserit.',
  viewTable: 'Vaata',
  you: 'Sa',
  supportDetails: 'Lisateavet leiate veebilehelt',
  youreInactive: 'Sa oled mitteaktiivne',
  browserNotSupported: 'Teie brauser ei toeta meie tehnoloogiat'
}), (0, _defineProperty2.default)(_errors$global$backst, "payment", {
  complete: 'Lõpeta maksmine',
  in: 'in',
  success: 'Maksmine õnnestus!',
  seconds: 'sekundit',
  failed: 'Midagi läks maksmisega valesti. Palun proovige uuesti!',
  redirect: 'Teid suunatakse edasi'
}), (0, _defineProperty2.default)(_errors$global$backst, "dec", 'dets'), (0, _defineProperty2.default)(_errors$global$backst, "december", 'detsember'), (0, _defineProperty2.default)(_errors$global$backst, "calendar", {
  endsIn: 'Lõppeb',
  liveNow: 'Eetris',
  startsAt: 'Algab',
  errors: {
    eventFinished: 'Sündmus on lõppenud. Täname teid.',
    eventNotStartedYet: 'Sündmus pole veel alanud.',
    noSessionsForToday: 'Selleks päevaks ei ole sellel etapil vabu seansse.'
  },
  networkingTextActive: 'Sündmuse korraldajad korraldavad võrgustike loomise sessiooni.',
  upcoming: 'Tulemas',
  networkingTextUpcoming: 'Sündmuse korraldajate korraldatud eelseisev võrgustike loomise sessioon.',
  yourSession: 'Sinu sessioon'
}), (0, _defineProperty2.default)(_errors$global$backst, "leftMenu", {
  expo: 'Expo',
  home: 'Avaleht',
  liveNow: 'Eetris',
  networking: 'Võrgustik',
  schedule: 'Ajakava',
  speakers: 'Kõnelejad',
  sponsors: 'Sponsorid',
  videos: 'Videod'
}), (0, _defineProperty2.default)(_errors$global$backst, "feb", 'veebr'), (0, _defineProperty2.default)(_errors$global$backst, "february", 'veebruar'), (0, _defineProperty2.default)(_errors$global$backst, "friday", 'reede'), (0, _defineProperty2.default)(_errors$global$backst, "page", {
  home: 'Avaleht',
  lobby: 'Lobby'
}), (0, _defineProperty2.default)(_errors$global$backst, "jan", 'jaan'), (0, _defineProperty2.default)(_errors$global$backst, "january", 'jaanuar'), (0, _defineProperty2.default)(_errors$global$backst, "jul", 'juuli'), (0, _defineProperty2.default)(_errors$global$backst, "july", 'juuli'), (0, _defineProperty2.default)(_errors$global$backst, "jun", 'juuni'), (0, _defineProperty2.default)(_errors$global$backst, "june", 'juuni'), (0, _defineProperty2.default)(_errors$global$backst, "event", {
  live: 'Otseülekanded',
  past: 'Vanad sündmused',
  upcoming: 'Tulevased sündmused'
}), (0, _defineProperty2.default)(_errors$global$backst, "mar", 'märts'), (0, _defineProperty2.default)(_errors$global$backst, "march", 'märts'), (0, _defineProperty2.default)(_errors$global$backst, "may", 'mai'), (0, _defineProperty2.default)(_errors$global$backst, "monday", 'esmaspäev'), (0, _defineProperty2.default)(_errors$global$backst, "rightSidebar", {
  noImage: 'Pilt puudub'
}), (0, _defineProperty2.default)(_errors$global$backst, "nov", 'nov'), (0, _defineProperty2.default)(_errors$global$backst, "november", 'november'), (0, _defineProperty2.default)(_errors$global$backst, "oct", 'okt'), (0, _defineProperty2.default)(_errors$global$backst, "october", 'oktoober'), (0, _defineProperty2.default)(_errors$global$backst, "videos", {
  tryAgain: 'Palun proovige uuesti pärast sündmuse lõppu.',
  noVideos: 'Salvestused on kättesaadavad 24 tundi pärast sündmuse lõppu.',
  noAccessToVideos: 'Vabandust, teie praegune pilet ei sisalda juurdepääsu salvestustele. Palun proovige oma piletit uuendada.',
  notFoundVideo: 'Seda videot ei ole olemas (või teil ei ole juurdepääsuõigusi).',
  otherVideos: 'Videod'
}), (0, _defineProperty2.default)(_errors$global$backst, "footer", {
  privacyPolicy: 'Privaatsuspoliitika',
  termsAndConditions: 'Tingimused'
}), (0, _defineProperty2.default)(_errors$global$backst, "saturday", 'laupäev'), (0, _defineProperty2.default)(_errors$global$backst, "sep", 'sept'), (0, _defineProperty2.default)(_errors$global$backst, "september", 'september'), (0, _defineProperty2.default)(_errors$global$backst, "toaster", {
  error: 'Midagi läks valesti',
  success: 'Edukas'
}), (0, _defineProperty2.default)(_errors$global$backst, "sunday", 'pühapäev'), (0, _defineProperty2.default)(_errors$global$backst, "thursday", 'neljapäev'), (0, _defineProperty2.default)(_errors$global$backst, "tuesday", 'teisipäev'), (0, _defineProperty2.default)(_errors$global$backst, "wednesday", 'kolmapäev'), _errors$global$backst);

exports.default = _default;