var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Poster;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function Poster(_ref) {
  var posterUrl = _ref.posterUrl,
      onPress = _ref.onPress,
      withVideo = _ref.withVideo;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      shape = _useTheme.shape;

  if (withVideo) {
    return _react.default.createElement(_reactNative.TouchableHighlight, {
      style: {
        height: 0,
        paddingBottom: '50%',
        position: 'relative',
        width: '100%'
      },
      underlayColor: '#FFFFFF55',
      onPress: onPress
    }, _react.default.createElement(_reactNative.View, {
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    }, _react.default.createElement(_reactNative.Image, {
      style: _objectSpread(_objectSpread({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }, shape.forms), {}, {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }),
      source: {
        uri: posterUrl
      },
      resizeMode: "cover"
    }), _react.default.createElement(_reactNative.Image, {
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        width: 64,
        height: 64
      },
      source: {
        uri: 'https://firebasestorage.googleapis.com/v0/b/dev-eventmix.appspot.com/o/assets%2Fplay-icon.png?alt=media&token=334651cd-82cd-4b85-944c-d92770069d19'
      },
      resizeMode: "cover"
    })));
  } else {
    return _react.default.createElement(_reactNative.View, {
      style: {
        height: 0,
        paddingBottom: '50%',
        position: 'relative',
        width: '100%'
      },
      underlayColor: '#FFFFFF55',
      onPress: onPress
    }, _react.default.createElement(_reactNative.Image, {
      style: _objectSpread(_objectSpread({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }, shape.forms), {}, {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }),
      source: {
        uri: posterUrl
      },
      resizeMode: "cover"
    }));
  }
}