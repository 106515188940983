var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _title = _interopRequireDefault(require("./title"));

var _actions = _interopRequireDefault(require("./actions"));

function CardView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      roundness = _useTheme.roundness,
      colors = _useTheme.colors;

  var cardProp = {
    elevation: 1,
    theme: {
      roundness: roundness
    }
  };
  var cardStyle = [{
    backgroundColor: colors.neutralLightest
  }, props.style];

  if (props.primary) {
    cardStyle.push({
      backgroundColor: colors.primary
    });
  }

  if (props.width !== undefined) {
    cardStyle.push({
      width: props.width
    });
  }

  return _react.default.createElement(_reactNativePaperV.Card, (0, _extends2.default)({}, cardProp, {
    style: cardStyle
  }), props.children);
}

CardView.Title = _title.default;
CardView.Content = _reactNativePaperV.Card.Content;
CardView.Cover = _reactNativePaperV.Card.Cover;
CardView.Actions = _actions.default;
var _default = CardView;
exports.default = _default;