var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ParticipantView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactNativePaper = require("react-native-paper");

var _AvatarImage = _interopRequireDefault(require("../../../common/avatar/AvatarImage"));

var _getInitials = _interopRequireDefault(require("../../../common/avatar/getInitials"));

var _Paragraph = _interopRequireDefault(require("../../../common/typography_v1/Paragraph"));

var _ParagraphSmall = _interopRequireDefault(require("../../../common/typography_v1/ParagraphSmall"));

var _Title = _interopRequireDefault(require("../../../common/typography_v1/Title"));

var _CustomIcon = _interopRequireDefault(require("../../../../assets/icons/CustomIcon.js"));

var _FollowUserButton = _interopRequireDefault(require("../../../common/follow/FollowUserButton"));

var _participantStyles = _interopRequireDefault(require("./participant-styles"));

function ParticipantView(props) {
  var navigation = (0, _reactNavigationHooks.useNavigation)();
  var isWinner = props.prize !== undefined;

  var handlePress = function handlePress() {
    navigation.navigate('Profile', {
      userId: props.userId,
      djName: props.fullName,
      avatar: props.avatarUrl
    });
  };

  return _react.default.createElement(_reactNative.View, {
    style: _participantStyles.default.djContainer
  }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
    testID: 'TapDj' + props.userId,
    name: 'navigateToProfileTouch',
    onPress: handlePress,
    style: [_participantStyles.default.djTouchable, isWinner && _participantStyles.default.winnerTouchable]
  }, _react.default.createElement(_reactNative.View, {
    style: [_participantStyles.default.djTouchable, isWinner && _participantStyles.default.winnerTouchable]
  }, _react.default.createElement(_reactNative.View, {
    style: _participantStyles.default.rank
  }, _react.default.createElement(_Paragraph.default, null, props.rank)), _react.default.createElement(_reactNative.View, {
    style: _participantStyles.default.avatarContainer
  }, _react.default.createElement(_AvatarImage.default, {
    size: isWinner ? 72 : 48,
    source: {
      uri: props.avatarUrl
    },
    defaultLabel: (0, _getInitials.default)(props.fullName),
    shape: isWinner ? 'round' : 'square',
    style: [_participantStyles.default.avatar, isWinner && _participantStyles.default.winnerAvatar]
  }), isWinner && _react.default.createElement(_reactNative.View, {
    style: _participantStyles.default.prize
  }, _react.default.createElement(_CustomIcon.default, {
    name: "trophy",
    size: 20,
    color: "#000"
  }), _react.default.createElement(_ParagraphSmall.default, {
    style: _participantStyles.default.prizeText
  }, props.prize))), _react.default.createElement(_reactNative.View, {
    style: _participantStyles.default.djDetails
  }, _react.default.createElement(_Title.default, {
    numberOfLines: isWinner ? 2 : 1,
    styles: [isWinner && _participantStyles.default.djNameBold]
  }, props.fullName), _react.default.createElement(_Paragraph.default, {
    style: _participantStyles.default.djBeats
  }, "".concat(props.beats, " ").concat(props.leaderboardUnit))))), _react.default.createElement(_reactNative.View, null, _react.default.createElement(_FollowUserButton.default, {
    navigation: navigation,
    id: props.userId,
    buttonSize: 'small',
    style: _participantStyles.default.follow
  })));
}