var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AssignTicketsStep;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _eventRegisterProvider = require("../../event-register-provider");

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _checkbox = _interopRequireDefault(require("../../../../theme/components/inputs/checkbox"));

var _formik = require("formik");

var _reactRedux = require("react-redux");

var _actions = require("../../../../core/tickets/actions");

var _constants = require("../../../../core/tickets/constants");

var _successfulAssignment = _interopRequireDefault(require("../common/successfulAssignment"));

var _RenderUnassignedAddons = require("../../../common/RenderUnassignedAddons");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    container: (0, _defineProperty2.default)({
      height: 600
    }, theme.breakpoints.down('sm'), {
      height: '100%'
    }),
    pageTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    ticketAssignmentContainer: (0, _defineProperty2.default)({
      backgroundColor: theme.palette.pageBackground.main,
      borderRadius: 8
    }, theme.breakpoints.up('sm'), {
      marginRight: 150,
      marginLeft: 150
    }),
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 8
    }
  };
});

function AssignTicketsStep() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      currentTransactionUnassignedTickets = _useEventRegisterCont.currentTransactionUnassignedTickets,
      selectedTicket = _useEventRegisterCont.selectedTicket,
      successfulAssigments = _useEventRegisterCont.successfulAssigments,
      userProfile = _useEventRegisterCont.userProfile,
      userHasSelectedTicket = _useEventRegisterCont.userHasSelectedTicket,
      mappedTicketsAddons = _useEventRegisterCont.mappedTicketsAddons;

  var classes = useStyles();
  var dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(function () {
    dispatch((0, _actions.getBoughtTickets)());
  }, []);

  var _useFormikContext = (0, _formik.useFormikContext)(),
      values = _useFormikContext.values,
      handleChange = _useFormikContext.handleChange,
      handleBlur = _useFormikContext.handleBlur,
      setFieldValue = _useFormikContext.setFieldValue,
      setValues = _useFormikContext.setValues,
      errors = _useFormikContext.errors,
      touched = _useFormikContext.touched;

  var isMyTicket = values.isMyTicket;
  (0, _react.useEffect)(function () {
    var assignValues = {};
    currentTransactionUnassignedTickets.forEach(function (ticket) {
      var _objectSpread2;

      assignValues = _objectSpread(_objectSpread({}, assignValues), {}, (_objectSpread2 = {}, (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.FULLNAME_FIELD, "-").concat(ticket.id), ''), (0, _defineProperty2.default)(_objectSpread2, "".concat(_constants.EMAIL_FIELD, "-").concat(ticket.id), ''), _objectSpread2));
    });
    setValues(_objectSpread(_objectSpread({}, values), assignValues));
  }, [currentTransactionUnassignedTickets]);
  (0, _react.useEffect)(function () {
    setFieldValue('isMyTicket', true);
    var firstTicket = currentTransactionUnassignedTickets[0];

    if (!userHasSelectedTicket && userProfile && firstTicket) {
      setFieldValue("".concat(_constants.FULLNAME_FIELD, "-").concat(firstTicket.id), userProfile.displayName || userProfile.fullName);
      setFieldValue("".concat(_constants.EMAIL_FIELD, "-").concat(firstTicket.id), userProfile.email);
    }
  }, [currentTransactionUnassignedTickets, userHasSelectedTicket]);
  (0, _react.useEffect)(function () {
    var firstTicket = currentTransactionUnassignedTickets[0];

    if (userProfile && !userHasSelectedTicket && firstTicket) {
      setFieldValue("".concat(_constants.FULLNAME_FIELD, "-").concat(firstTicket.id), isMyTicket ? userProfile.displayName || userProfile.fullName : '');
      setFieldValue("".concat(_constants.EMAIL_FIELD, "-").concat(firstTicket.id), isMyTicket ? userProfile.email : '');
    }
  }, [currentTransactionUnassignedTickets, isMyTicket, userHasSelectedTicket]);
  return _react.default.createElement(_core.Box, {
    className: classes.container
  }, _react.default.createElement(_core.Box, {
    className: classes.pageTitle,
    mt: 2,
    mb: 4
  }, _react.default.createElement(_Typography.default, {
    variant: "h3"
  }, _i18n.default.translate('assignTickets.thankYou')), _react.default.createElement(_Typography.default, null, _i18n.default.translate('assignTickets.thankYouDescription'))), currentTransactionUnassignedTickets.map(function (unassignedTicket, index) {
    var _unassignedTicket$sel;

    return _react.default.createElement(_core.Box, {
      mt: 1,
      mb: 5,
      className: classes.ticketAssignmentContainer
    }, _react.default.createElement(_core.Box, {
      px: 5,
      py: 3
    }, _react.default.createElement(_core.Box, {
      mb: 2
    }, _react.default.createElement(_Typography.default, {
      variant: "h3"
    }, "".concat(selectedTicket.name, " - Ticket #").concat(index + 1))), successfulAssigments["".concat(_constants.ASSIGNED_FIELD, "-").concat(unassignedTicket.id)] ? _react.default.createElement(_successfulAssignment.default, null) : _react.default.createElement(_react.default.Fragment, null, !userHasSelectedTicket && index === 0 && _react.default.createElement(_core.Box, {
      my: 2
    }, _react.default.createElement(_checkbox.default, {
      value: isMyTicket,
      name: "isMyTicket",
      activeColor: "button",
      onChange: handleChange,
      label: _i18n.default.translate('assignTickets.thisIsMyTicket')
    })), (unassignedTicket === null || unassignedTicket === void 0 ? void 0 : (_unassignedTicket$sel = unassignedTicket.selectedAddons) === null || _unassignedTicket$sel === void 0 ? void 0 : _unassignedTicket$sel.length) >= 1 && _react.default.createElement(_core.Box, {
      alignItems: "stretch",
      direction: "column",
      pSpacing: "l",
      key: "addons",
      my: 2
    }, _react.default.createElement(_RenderUnassignedAddons.RenderUnassignedAddons, {
      ticketAddons: mappedTicketsAddons
    })), _react.default.createElement(_core.Box, {
      mt: 1
    }, _react.default.createElement(_Input.default, {
      type: "text",
      placeholder: "".concat(_i18n.default.translate('assignTickets.fullName'), " *"),
      color: "alternativeText",
      disabled: !userHasSelectedTicket && !!isMyTicket && index === 0,
      value: values["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)],
      name: "".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id),
      onChange: handleChange,
      onBlur: handleBlur
    }), !!errors["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)] && touched["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)] && _react.default.createElement(_core.Box, {
      className: classes.errorContainer
    }, _react.default.createElement(_Typography.default, {
      variant: "caption",
      style: {
        color: 'red'
      }
    }, errors["".concat(_constants.FULLNAME_FIELD, "-").concat(unassignedTicket.id)]))), _react.default.createElement(_core.Box, {
      my: 2
    }, _react.default.createElement(_Input.default, {
      type: "email",
      color: "alternativeText",
      placeholder: "".concat(_i18n.default.translate('assignTickets.email'), " *"),
      disabled: !userHasSelectedTicket && !!isMyTicket && index === 0,
      value: values["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)],
      name: "".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id),
      onChange: handleChange,
      onBlur: handleBlur
    }), !!errors["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)] && touched["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)] && _react.default.createElement(_core.Box, {
      className: classes.errorContainer
    }, _react.default.createElement(_Typography.default, {
      variant: "caption",
      style: {
        color: 'red'
      }
    }, errors["".concat(_constants.EMAIL_FIELD, "-").concat(unassignedTicket.id)]))))));
  }), errors.duplicatedEmails && _react.default.createElement(_core.Box, {
    className: classes.errorContainer
  }, _react.default.createElement(_Typography.default, {
    variant: "caption",
    style: {
      color: 'red'
    }
  }, _i18n.default.translate('assignTickets.emailShouldBeUnique'))));
}