var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _DesignSystem = require("../../../../theme/DesignSystem");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var useTableStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    outerContainer: {
      overflow: 'hidden',
      borderRadius: theme.shape.cards.borderRadius,
      backgroundColor: theme.colors.button + '44'
    },
    outerContainerFull: {
      overflow: 'hidden',
      borderRadius: theme.shape.cards.borderRadius,
      backgroundColor: theme.colors.borderColor
    },
    innerContainer: {
      borderRadius: theme.shape.cards.borderRadius,
      backgroundColor: theme.colors.alternativeBackground,
      minHeight: 220
    },
    leftColumn: {
      marginLeft: -theme.spacing.xxl,
      flex: 2
    },
    centerColumn: {
      marginTop: -theme.spacing.xxxl,
      marginBottom: -theme.spacing.xxxl,
      flex: 4
    },
    rightColumn: {
      marginRight: -theme.spacing.xxl,
      flex: 2
    },
    fullWidth: {
      minWidth: '100%'
    },
    placeholder: {
      backgroundColor: theme.colors.containerBackground,
      borderRadius: theme.spacing.l,
      borderWidth: 2,
      borderColor: theme.colors.borderColor,
      padding: theme.spacing.l
    },
    image: _objectSpread(_objectSpread({}, theme.shape.cards), {}, {
      minHeight: 210,
      minWidth: 310,
      backgroundColor: theme.colors.alternativeBackground,
      borderWidth: 5,
      borderColor: theme.colors.alternativeBackground,
      borderStyle: 'solid',
      flexDirection: 'row',
      alignItems: 'stretch',
      justifyContent: 'center'
    }),
    dialog: {
      maxWidth: '40vh'
    },
    indicator: {
      marginRight: 15,
      opacity: 0.6
    },
    container: {
      width: '100%',
      minWidth: 0,
      maxWidth: 471
    },
    description: {
      opacity: 0.85
    },
    actionButtons: {
      margin: theme.spacing.xs
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    outerContainer: {
      padding: theme.spacing.m
    },
    innerContainer: {
      padding: theme.spacing.m
    },
    placeholder: {
      borderRadius: theme.spacing.l,
      padding: theme.spacing.l
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.sm;
}, function (theme) {
  return {
    titleSection: {
      alignItems: 'center'
    },
    image: {
      minWidth: 70
    }
  };
}).buildHook();
var _default = useTableStyles;
exports.default = _default;