var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _core = require("@material-ui/core");

var _selectors = require("../../../core/tickets/selectors");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var TicketApproveMessageContainer = function TicketApproveMessageContainer() {
  var classes = (0, _styles.default)();
  var pendingTicket = (0, _reactRedux.useSelector)(_selectors.selectPendingTicketForEvent);
  if (!pendingTicket) return null;
  return _react.default.createElement(_core.Typography, {
    variant: "body1",
    align: "center",
    className: classes.messageBar
  }, _i18n.default.t('checkout.approveTicket'));
};

var _default = TicketApproveMessageContainer;
exports.default = _default;