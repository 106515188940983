Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spacing = void 0;
var Spacing = {
  none: 0,
  extraTight: 4,
  tight: 8,
  mediumTight: 10,
  baseTight: 12,
  base: 16,
  loose: 24,
  mediumLoose: 30,
  extraLoose: 32,
  webTight: 48,
  webLoose: 64,
  webExtraLoose: 72,
  webHugeLoose: 128
};
exports.Spacing = Spacing;