var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ToggleVideo;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

var _Spacing = _interopRequireDefault(require("./../../../theme/components/spacing/Spacing"));

function ToggleVideo(_ref) {
  var color = _ref.color;
  var Styles = (0, _audioVideoStyles.default)();

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      videoEnabled = _useAudioVideoContext.videoEnabled,
      toggleVideo = _useAudioVideoContext.toggleVideo;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      reserved = _React$useState2[0],
      setReserved = _React$useState2[1];

  var onToggleVideo = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setReserved(true);
              _context.next = 3;
              return toggleVideo();

            case 3:
              setReserved(false);

            case 4:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onToggleVideo() {
      return _ref2.apply(this, arguments);
    };
  }();

  return _react.default.createElement(_ColoredIconButton.default, {
    customColor: color,
    disabled: reserved,
    onClick: onToggleVideo
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, videoEnabled ? _react.default.createElement(_reactFeather.Video, null) : _react.default.createElement(_reactFeather.VideoOff, null)));
}