Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var isSmallDevice = _reactNative.Dimensions.get('screen').width < 375 ? true : false;
var defaultWidth = isSmallDevice ? 207 : '100%';
var defaultAspectRatio = 3 / 2;

var _default = _reactNative.StyleSheet.create({
  sectionContainer: {
    marginVertical: 20
  },
  container: {
    width: defaultWidth,
    aspectRatio: defaultAspectRatio,
    borderRadius: 16
  },
  small: {
    width: defaultWidth * 0.8
  },
  large: {
    width: defaultWidth * 1.2
  },
  imageContainer: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  itemImage: {
    flex: 1,
    borderRadius: 16,
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.2 : 0
  },
  sectionTitle: {
    lineHeight: 17,
    color: 'white',
    paddingHorizontal: 16,
    marginBottom: 16
  },
  linearGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    flex: 1,
    borderRadius: 16
  },
  itemTitle: {
    lineHeight: 19,
    paddingRight: 8,
    flexWrap: 'wrap',
    marginBottom: 4
  },
  itemPeriod: {
    color: 'rgba(255,255,255,0.6)',
    lineHeight: 17
  },
  followButtonContainer: {
    width: 80,
    marginTop: 8
  },
  vipBadge: {
    right: 0,
    top: 0
  },
  tall: {
    aspectRatio: 0.6
  }
});

exports.default = _default;