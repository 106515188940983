var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ContentContainer;

var _react = _interopRequireDefault(require("react"));

var _collapsed = _interopRequireDefault(require("./collapsed"));

var _participant = _interopRequireDefault(require("./participant"));

function ContentContainer(props) {
  var item = props.item,
      leaderboardUnit = props.leaderboardUnit,
      index = props.index,
      wasLoadMorePressed = props.wasLoadMorePressed,
      onPress = props.onPress;
  var isFirstAroundMe = item.isFirstAroundMe,
      userName = item.userName,
      rank = item.rank,
      _item$externalIds = item.externalIds,
      externalIds = _item$externalIds === void 0 ? {} : _item$externalIds;
  var beatsNr = item['SUM-value'];
  var avatarUrl = externalIds.imageUrl || undefined;
  var apiDjId = externalIds.userId || undefined;
  var prize = item.prize || undefined;
  return _react.default.createElement(_react.default.Fragment, null, isFirstAroundMe && rank !== index + 1 ? _react.default.createElement(_collapsed.default, {
    wasLoadMorePressed: wasLoadMorePressed,
    onPress: onPress
  }) : null, _react.default.createElement(_participant.default, {
    userId: apiDjId,
    leaderboardUnit: leaderboardUnit,
    fullName: userName,
    avatarUrl: avatarUrl,
    beats: beatsNr,
    rank: rank,
    prize: prize
  }));
}