var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var AsyncActionHandler = function () {
  function AsyncActionHandler(callback) {
    (0, _classCallCheck2.default)(this, AsyncActionHandler);
    this.callback = void 0;
    this.waitOnceActions = {};
    this.waitEveryActions = {};
    this.waitOnceDone = false;
    this.callback = callback;
  }

  (0, _createClass2.default)(AsyncActionHandler, [{
    key: "waitOnce",
    value: function waitOnce(action) {
      this.waitOnceActions = _objectSpread(_objectSpread({}, this.waitOnceActions), {}, (0, _defineProperty2.default)({}, action.type, false));
      return this;
    }
  }, {
    key: "waitEvery",
    value: function waitEvery(action) {
      this.waitEveryActions = _objectSpread(_objectSpread({}, this.waitEveryActions), {}, (0, _defineProperty2.default)({}, action.type, true));
      this.waitOnceActions = _objectSpread(_objectSpread({}, this.waitOnceActions), {}, (0, _defineProperty2.default)({}, action.type, false));
      return this;
    }
  }, {
    key: "next",
    value: function next(action) {
      if (!this.waitOnceDone) {
        if (this.waitOnceActions[action === null || action === void 0 ? void 0 : action.type] === false) {
          this.waitOnceActions = _objectSpread(_objectSpread({}, this.waitOnceActions), {}, (0, _defineProperty2.default)({}, action.type, true));
          var actions = Object.values(this.waitOnceActions).filter(function (value) {
            return !value;
          });

          if (!actions.length) {
            this.waitOnceDone = true;
            this.callback();
          }
        }
      }

      if (this.waitEveryActions[action === null || action === void 0 ? void 0 : action.type]) {
        this.waitOnceActions = _objectSpread(_objectSpread({}, this.waitOnceActions), {}, (0, _defineProperty2.default)({}, action.type, true));

        var _actions = Object.values(this.waitOnceActions).filter(function (value) {
          return !value;
        });

        if (!_actions.length) {
          this.callback();
        }
      }
    }
  }]);
  return AsyncActionHandler;
}();

var _default = AsyncActionHandler;
exports.default = _default;