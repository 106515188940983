Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _BaseStyles = require("../../theme/BaseStyles");

var updateStyles = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    width: _BaseStyles.dimensions.fullWidth,
    height: _BaseStyles.dimensions.fullHeight,
    paddingBottom: 16
  },
  logo: {
    height: 150,
    width: 109,
    marginTop: 100
  },
  textStyle: {
    textAlign: 'center',
    paddingHorizontal: 16,
    marginBottom: 12
  }
});

var _default = updateStyles;
exports.default = _default;