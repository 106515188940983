var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _agoraConstants = require("../../../constants/agora-constants");

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _useEmitEvent = _interopRequireDefault(require("../../../utils/hooks/useEmitEvent"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function Subscription(_ref) {
  var participant = _ref.participant,
      mediaType = _ref.mediaType;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      client = _useAudioVideoContext.client;

  var emit = (0, _useEmitEvent.default)(_agoraConstants.subscriptionObserver);
  (0, _react.useEffect)(function () {
    if (!client) return;
    if (!participant) return;
    client.subscribe(participant, mediaType).then(function () {
      return emit({
        type: _agoraConstants.SUBSCRIBE_EVENT,
        origin: '[Subscription]'
      });
    });

    _logger.default.debug("Subscribe to ".concat(participant.uid, " - ").concat(mediaType));

    return function () {
      if (!client) return;
      if (!participant) return;
      client.unsubscribe(participant, mediaType).then(function () {
        return emit({
          type: _agoraConstants.UNSUBSCRIBE_EVENT,
          origin: '[Subscription]'
        });
      });

      _logger.default.debug("Unsubscribe to ".concat(participant.uid, " - ").concat(mediaType));
    };
  }, [client, participant, mediaType, emit]);
  return null;
}

var _default = (0, _react.memo)(Subscription);

exports.default = _default;