var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LiveShareButtonInternal = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _ShareButton = _interopRequireDefault(require("../common/ShareButton"));

var _user = _interopRequireDefault(require("../../core/user"));

var _excluded = ["referrerId", "liveId", "djId", "djPicture", "djName", "hashTags"];

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var LiveShareButtonInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(LiveShareButtonInternal, _React$PureComponent);

  var _super = _createSuper(LiveShareButtonInternal);

  function LiveShareButtonInternal() {
    (0, _classCallCheck2.default)(this, LiveShareButtonInternal);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(LiveShareButtonInternal, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          referrerId = _this$props.referrerId,
          liveId = _this$props.liveId,
          djId = _this$props.djId,
          djPicture = _this$props.djPicture,
          djName = _this$props.djName,
          hashTags = _this$props.hashTags,
          restProps = (0, _objectWithoutProperties2.default)(_this$props, _excluded);
      var hashTagsAsString = hashTags ? hashTags.join(' ') : '';
      var deepLinkPath = ["event/".concat(liveId), "?last_attribution=".concat(referrerId === djId ? 'share_dj' : 'share_community')].join('');
      return _react.default.createElement(_ShareButton.default, (0, _extends2.default)({
        deepLinkPath: deepLinkPath,
        ogTitle: hashTagsAsString,
        ogImageUrl: djPicture,
        ogDescription: "".concat(djName, " is playing live on Soundmix"),
        message: "".concat(djName, " is playing ").concat(hashTagsAsString, " live on Soundmix [APP_INVITE_URL]"),
        customData: {
          liveId: "".concat(liveId)
        }
      }, restProps));
    }
  }]);
  return LiveShareButtonInternal;
}(_react.default.PureComponent);

exports.LiveShareButtonInternal = LiveShareButtonInternal;

function mapStateToProps(state) {
  return {
    referrerId: _user.default.selectors.getUserId(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})(LiveShareButtonInternal);

exports.default = _default;