Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pollsEntityAdapter = exports.POLL_TYPES = exports.RESET_POLLS = exports.UPDATE_POLL = exports.ON_POLLS_CHANGED = exports.SUBSCRIBE_TO_POLL_CHANGES = exports.VOTE_ON_EVENT_POLL = exports.DELETE_EVENT_POLL_OPTION = exports.DELETE_EVENT_POLL = exports.SAVE_EVENT_POLL_OPTION = exports.SAVE_EVENT_POLL = exports.GET_EVENT_POLLS = exports.NAME = void 0;

var _toolkit = require("@reduxjs/toolkit");

var NAME = 'polls';
exports.NAME = NAME;
var GET_EVENT_POLLS = 'polls/GET_EVENT_POLLS';
exports.GET_EVENT_POLLS = GET_EVENT_POLLS;
var SAVE_EVENT_POLL = 'polls/SAVE_EVENT_POLL';
exports.SAVE_EVENT_POLL = SAVE_EVENT_POLL;
var SAVE_EVENT_POLL_OPTION = 'polls/SAVE_EVENT_POLL_OPTION';
exports.SAVE_EVENT_POLL_OPTION = SAVE_EVENT_POLL_OPTION;
var DELETE_EVENT_POLL = 'polls/DELETE_EVENT_POLL';
exports.DELETE_EVENT_POLL = DELETE_EVENT_POLL;
var DELETE_EVENT_POLL_OPTION = 'polls/DELETE_EVENT_POLL_OPTION';
exports.DELETE_EVENT_POLL_OPTION = DELETE_EVENT_POLL_OPTION;
var VOTE_ON_EVENT_POLL = 'polls/VOTE_ON_EVENT_POLL';
exports.VOTE_ON_EVENT_POLL = VOTE_ON_EVENT_POLL;
var SUBSCRIBE_TO_POLL_CHANGES = 'polls/SUBSCRIBE_TO_POLL_CHANGES';
exports.SUBSCRIBE_TO_POLL_CHANGES = SUBSCRIBE_TO_POLL_CHANGES;
var ON_POLLS_CHANGED = 'polls/ON_POLLS_CHANGED';
exports.ON_POLLS_CHANGED = ON_POLLS_CHANGED;
var UPDATE_POLL = 'polls/UPDATE_POLL';
exports.UPDATE_POLL = UPDATE_POLL;
var RESET_POLLS = 'polls/RESET_POLLS';
exports.RESET_POLLS = RESET_POLLS;
var POLL_TYPES = {
  NETWORKING: 'networking',
  LOBBY: 'lobby',
  LIVE: 'live',
  BOOTH: 'booth'
};
exports.POLL_TYPES = POLL_TYPES;
var pollsEntityAdapter = (0, _toolkit.createEntityAdapter)({
  sortComparer: function sortComparer(a, b) {
    return new Date(a.createdDate) - new Date(b.createdDate);
  }
});
exports.pollsEntityAdapter = pollsEntityAdapter;