Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../../design-system/spacing/index");

var _reactNativeMediaQueries = require("react-native-media-queries");

var base = {
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  buttonIconTouchable: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonIconTouchableSpacing: {
    marginHorizontal: 4
  },
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  overlayBg: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  videoOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%'
  },
  overlayTop: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 10,
    paddingRight: 4,
    backgroundColor: 'red'
  },
  overlayTopRight: {
    position: 'absolute',
    top: 10,
    right: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  videoOverlayBottom: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16
  },
  djNameAndAvatar: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: 16,
    overflow: 'hidden'
  },
  videoTopBar: {
    position: 'absolute',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  videoTopBarLeft: {
    marginLeft: 10,
    justifyContent: 'flex-start',
    flexDirection: 'row'
  },
  videoTopBarRight: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 10
  },
  video: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  backgroundVideo: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'white'
  },
  videoLoadingPosition: {
    position: 'absolute',
    justifyContent: 'flex-start',
    width: '100%',
    left: 0,
    top: 0
  },
  buttonIcon: {
    width: 34,
    height: 34,
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  castButton: {
    width: 34,
    height: 34
  },
  webContainer: {},
  webVideoContainer: {
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden'
  },
  webVideo: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  webChatContainer: {
    flex: 1,
    position: 'relative',
    maxWidth: 380
  },
  webChatInnerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  countDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 0,
    paddingBottom: '56.25%'
  },
  countDownInnerContainer: {
    paddingVertical: _index.Spacing.webExtraLoose,
    paddingHorizontal: _index.Spacing.extraLoose,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  countdownText: {
    color: '#FFFFFF',
    fontSize: 15,
    lineHeight: 24,
    letterSpacing: -1,
    marginTop: _index.Spacing.loose,
    marginBottom: _index.Spacing.base
  },
  countdownSpeaker: {
    width: 64,
    marginRight: '0px !important'
  },
  liveVideoContainer: {
    paddingTop: 1,
    borderRadius: 10,
    overflow: 'hidden',
    marginVertical: _index.Spacing.mediumTight,
    marginHorizontal: _index.Spacing.mediumLoose
  }
};
var Styles = (0, _reactNativeMediaQueries.createStyles)(base, (0, _reactNativeMediaQueries.maxWidth)(768, {
  webContainer: {},
  webVideoContainer: {
    flex: 0,
    marginHorizontal: 10,
    marginVertical: 10
  },
  webChatContainer: {
    minHeight: 200,
    maxWidth: '100%'
  },
  countDownInnerContainer: {
    paddingVertical: _index.Spacing.base
  },
  countdownText: {
    marginTop: _index.Spacing.baseTight,
    marginBottom: _index.Spacing.baseTight
  },
  liveVideoContainer: {
    marginHorizontal: _index.Spacing.mediumTight
  }
}), (0, _reactNativeMediaQueries.maxWidth)(1024, {
  liveVideoContainer: {
    marginLeft: _index.Spacing.mediumTight,
    marginRight: _index.Spacing.mediumTight
  }
}));
var _default = Styles;
exports.default = _default;