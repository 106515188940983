var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _selectors = require("../../../core/user-rooms/selectors");

var _useUserBackstageFeatures = _interopRequireDefault(require("../../../utils/hooks/useUserBackstageFeatures"));

var _uniq = _interopRequireDefault(require("lodash/uniq"));

var _Subscription = _interopRequireDefault(require("./Subscription"));

var _useParticipants = require("../../../utils/hooks/useParticipants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function Subscriptions(_ref) {
  var roomId = _ref.roomId;
  var userRoomParticipants = Object.keys((0, _reactRedux.useSelector)((0, _selectors.roomAllParticipantsSelector)(roomId))).map(function (id) {
    return Number(id);
  });
  var requestParticipants = Object.keys((0, _reactRedux.useSelector)((0, _selectors.participantsWithRequestSelector)(roomId))).map(function (id) {
    return Number(id);
  });
  var uid = (0, _reactRedux.useSelector)((0, _selectors.userIdSelector)(roomId));
  var screenSharer = (0, _reactRedux.useSelector)((0, _selectors.roomScreenSharerSelector)(roomId));
  var shouldSubscribeOnRequestParticipants = (0, _useUserBackstageFeatures.default)(roomId, [_featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS, _featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_SESSION, _featureNamesConstants.SHOW_JOIN_LIVE_REQUEST_UI_COMPONENTS_BY_STAGE]);
  var webRtcParticipants = (0, _useParticipants.useContextParticipants)();
  var subscribeAbleParticipantIds = (0, _react.useMemo)(function () {
    if (!shouldSubscribeOnRequestParticipants) return userRoomParticipants;
    return [].concat((0, _toConsumableArray2.default)(requestParticipants), (0, _toConsumableArray2.default)(userRoomParticipants));
  }, [shouldSubscribeOnRequestParticipants, userRoomParticipants, requestParticipants]);
  return _react.default.createElement(_react.default.Fragment, null, (0, _uniq.default)(subscribeAbleParticipantIds).filter(function (id) {
    return id !== Number(uid);
  }).map(function (id) {
    var webRtcParticipant = webRtcParticipants.get(id);
    var webRtcParticipantScreenShare = webRtcParticipants.get(Number(screenSharer.screenShareId));
    var subscriptions = [];

    if (webRtcParticipantScreenShare && Number(id) === Number(screenSharer.id)) {
      if (webRtcParticipantScreenShare.hasVideo) {
        subscriptions.push(_react.default.createElement(_Subscription.default, {
          key: "screen-video-".concat(id),
          participant: webRtcParticipantScreenShare,
          mediaType: "video"
        }));
      }
    }

    if (webRtcParticipant) {
      if (webRtcParticipant.hasVideo) {
        subscriptions.push(_react.default.createElement(_Subscription.default, {
          key: "video-".concat(id),
          participant: webRtcParticipant,
          mediaType: "video"
        }));
      }

      if (webRtcParticipant.hasAudio) {
        if (userRoomParticipants.includes(id)) {
          subscriptions.push(_react.default.createElement(_Subscription.default, {
            key: "audio-".concat(id),
            participant: webRtcParticipant,
            mediaType: "audio"
          }));
        }
      }
    }

    return _react.default.createElement(_react.default.Fragment, {
      key: id
    }, subscriptions);
  }));
}

var _default = (0, _react.memo)(Subscriptions);

exports.default = _default;