var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _reactNativePaperV = require("react-native-paper-v3");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _reactFeather = require("react-feather");

var _NavigationService = _interopRequireDefault(require("../../../navigation/NavigationService"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var MobilePreventionView = function MobilePreventionView() {
  var logoUrl = (0, _reactRedux.useSelector)(_selectors.selectEventLogoUrl);
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: false
  });
  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }, _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    onPress: function onPress() {}
  }, logoUrl && typeof logoUrl === 'string' ? _react.default.createElement(_reactNative.Image, {
    nativeID: "imageLogo",
    source: {
      uri: logoUrl
    },
    resizeMode: "contain",
    style: {
      height: 160,
      width: 160
    }
  }) : _react.default.createElement(_reactNative.View, null)), _react.default.createElement(_core.Typography, {
    variant: "h4",
    style: {
      margin: '10px 20px 30px'
    }
  }, _i18n.default.t('backstage.bestOnDesktop')), _react.default.createElement(_ContainedButton.default, {
    onPress: function onPress() {
      return _NavigationService.default.navigateExtended(_routesConsts.Routes.LOBBY);
    },
    iconRight: _react.default.createElement(_reactFeather.ArrowRight, {
      style: {
        paddingLeft: 10
      }
    }),
    size: "large",
    style: {
      minWidth: 200
    }
  }, _i18n.default.translate('backstage.goBackToLive')));
};

var _default = MobilePreventionView;
exports.default = _default;