var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _reactNative = require("react-native");

var _reactNativeIntercom = _interopRequireDefault(require("react-native-intercom"));

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var useIntercom = function useIntercom(props) {
  var userId = props.userId,
      userName = props.userName;
  (0, _react.useEffect)(function () {
    if (_reactNative.Platform.OS === 'ios') {
      _reactNativeIntercom.default.registerForPush();
    } else if (_reactNative.Platform.OS === 'android') {
      _reactNativeFirebase.default.messaging().getToken().then(function (token) {
        if (token) {
          _reactNativeIntercom.default.sendTokenToIntercom(token);

          _reactNativeIntercom.default.handlePushMessage();
        }
      });
    }

    if (userId) {
      var userIdAsString = userId.toString();

      _reactNativeIntercom.default.registerIdentifiedUser({
        userId: userIdAsString
      });

      _reactNativeIntercom.default.updateUser({
        user_id: userIdAsString,
        name: userName
      });
    }
  }, [userId, userName]);
  return _reactNativeIntercom.default;
};

var _default = useIntercom;
exports.default = _default;