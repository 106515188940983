Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAnEmailAddress = void 0;

var isAnEmailAddress = function isAnEmailAddress(text) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(text).toLowerCase());
};

exports.isAnEmailAddress = isAnEmailAddress;