var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _actionTypes = require("./actionTypes");

var _actions = require("./actions");

var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));

var constants = _interopRequireWildcard(require("./constants"));

var _user = _interopRequireDefault(require("../user"));

var _events = _interopRequireDefault(require("../events"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function createMiddleware(socket) {
  return function (store) {
    socket.on(constants.WEBSOCKET_WELCOME_EVENT, onWelcome(socket, store));
    socket.on(constants.WEBSOCKET_NUMBER_OF_USERS_CHANGED_EVENT, onNumUsersChanged(socket, store));
    var pendingJoin;
    var isVip = false;
    var eventTicketId;
    return function (next) {
      return function (action) {
        var result = next(action);

        switch (action.type) {
          case _actionTypes.JOIN:
            if (socket.connected || socket.io.readyState === constants.CONNECTION_STATUS.CONNECTING) {
              socket.disconnect();
            }

            if (pendingJoin) {
              clearTimeout(pendingJoin);
            }

            eventTicketId = _events.default.selectors.getAccessTicketID(store.getState(), action.liveId);

            if (eventTicketId !== undefined) {
              isVip = _user.default.selectors.hasTicketAccess(store.getState(), eventTicketId);
            }

            pendingJoin = setTimeout(function () {
              socket.setupConfig({
                query: {
                  liveId: action.liveId,
                  deviceId: _reactNativeDeviceInfo.default.getUniqueId(),
                  isDj: _user.default.selectors.isLoggedUserDj(store.getState()),
                  isVip: isVip
                }
              });
              socket.connect();
            }, 0);
            break;

          case _actionTypes.LOAD_USERS:
            socket.emit('all:users', true, function (users) {
              store.dispatch((0, _actions.loadUsersCompleted)(users));
            });
            break;

          case _actionTypes.LEAVE:
            if (socket.connected) {
              socket.disconnect();
            }

            break;

          default:
            break;
        }

        return result;
      };
    };
  };
}

var onWelcome = function onWelcome(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.joinCompleted)(response.liveId));
    store.dispatch((0, _actions.refreshListeners)(response.numUsers, response.numTotalUsers));
  };
};

var onNumUsersChanged = function onNumUsersChanged(socket, store) {
  return function (response) {
    store.dispatch((0, _actions.refreshListeners)(response.numUsers, response.numTotalUsers));
  };
};