var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeviceSelector;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _baseSelect = _interopRequireDefault(require("../../../theme/components/inputs/baseSelect.web"));

var _reactFeather = require("react-feather");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

function DeviceSelector(_ref) {
  var children = _ref.children,
      _ref$devices = _ref.devices,
      devices = _ref$devices === void 0 ? [] : _ref$devices,
      _ref$value = _ref.value,
      value = _ref$value === void 0 ? '' : _ref$value,
      _onChange = _ref.onChange,
      hasOff = _ref.hasOff;
  var selectableDevices = devices.length ? hasOff ? [{
    id: '',
    value: _i18n.default.translate('networking.off')
  }].concat((0, _toConsumableArray2.default)(devices)) : devices : [{
    id: '',
    value: _i18n.default.translate('networking.noDevices'),
    disabled: true
  }];
  return _react.default.createElement(_baseSelect.default, {
    flex: 1,
    hasEmptyOption: false,
    onChange: function onChange(id) {
      return _onChange(id);
    },
    options: selectableDevices,
    leftIcon: children,
    value: value,
    rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null)
  });
}