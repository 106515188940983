var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _blockedUsersContainer = _interopRequireDefault(require("./blocked-users-container"));

var _user = _interopRequireDefault(require("../../../core/user"));

function mapStateToProps(state, ownProps) {
  return {
    blockedUsersList: _user.default.selectors.getBlockedUsersList(state),
    unblockInProgress: _user.default.selectors.isUnblockUserInProgress(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  getBlockedUsers: _user.default.actions.loadBlockedUsers,
  unblockUser: _user.default.actions.unblockUser
})(_blockedUsersContainer.default);

exports.default = _default;