Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blockedUserEntityAdapter = exports.userEntityAdapter = exports.messageEntityAdapter = exports.WEBSOCKET_ERRORS = exports.WEBSOCKET_STATUS = exports.WEBSOCKET_EVENTS = exports.SEND_DELETE_MESSAGE_FAILED = exports.SEND_MESSAGE_FAILED = exports.SEND_USER_UNBLOCK_FAILED = exports.SEND_USER_BLOCK_FAILED = exports.ROOM_LEAVE_FAILED = exports.ROOM_CONNECT_FAILED = exports.RECEIVE_CONNECTION_FAILED_ERROR = exports.CONNECT_FAILED = exports.SET_AUTO_READ_MODE = exports.RESET_UNREAD_COUNTER = exports.RECEIVE_DISCONNECT = exports.RECEIVE_USER_UNBLOCK = exports.RECEIVE_USER_BLOCK = exports.RECEIVE_USER_LEAVE = exports.RECEIVE_USER_JOIN = exports.RECEIVE_DELETE_MESSAGE = exports.RECEIVE_MESSAGE = exports.RECEIVE_CONNECTION = exports.RECEIVE_ROOM_LEAVE = exports.RECEIVE_ROOM_CHANGE = exports.RECEIVE_ROOM_JOIN = exports.SEND_DISCONNECT = exports.SEND_ROOM_LEAVE = exports.SEND_ROOM_CHANGE = exports.SEND_ROOM_REJOIN = exports.SEND_ROOM_JOIN = exports.SEND_USER_UNBLOCK = exports.SEND_USER_BLOCK = exports.SEND_DELETE_MESSAGE = exports.SEND_MESSAGE = exports.SEND_CONNECT = exports.CHAT_LATEST_MESSAGES = exports.NAME = void 0;

var _toolkit = require("@reduxjs/toolkit");

var NAME = 'chat';
exports.NAME = NAME;
var CHAT_LATEST_MESSAGES = 50;
exports.CHAT_LATEST_MESSAGES = CHAT_LATEST_MESSAGES;
var SEND_CONNECT = "".concat(NAME, "/SEND_CONNECT");
exports.SEND_CONNECT = SEND_CONNECT;
var SEND_MESSAGE = "".concat(NAME, "/SEND_MESSAGE");
exports.SEND_MESSAGE = SEND_MESSAGE;
var SEND_DELETE_MESSAGE = "".concat(NAME, "/SEND_DELETE_MESSAGE");
exports.SEND_DELETE_MESSAGE = SEND_DELETE_MESSAGE;
var SEND_USER_BLOCK = "".concat(NAME, "/SEND_USER_BLOCK");
exports.SEND_USER_BLOCK = SEND_USER_BLOCK;
var SEND_USER_UNBLOCK = "".concat(NAME, "/SEND_USER_UNBLOCK");
exports.SEND_USER_UNBLOCK = SEND_USER_UNBLOCK;
var SEND_ROOM_JOIN = "".concat(NAME, "/SEND_ROOM_JOIN");
exports.SEND_ROOM_JOIN = SEND_ROOM_JOIN;
var SEND_ROOM_REJOIN = "".concat(NAME, "/SEND_ROOM_REJOIN");
exports.SEND_ROOM_REJOIN = SEND_ROOM_REJOIN;
var SEND_ROOM_CHANGE = "".concat(NAME, "/SEND_ROOM_CHANGE");
exports.SEND_ROOM_CHANGE = SEND_ROOM_CHANGE;
var SEND_ROOM_LEAVE = "".concat(NAME, "/SEND_ROOM_LEAVE");
exports.SEND_ROOM_LEAVE = SEND_ROOM_LEAVE;
var SEND_DISCONNECT = "".concat(NAME, "/SEND_DISCONNECT");
exports.SEND_DISCONNECT = SEND_DISCONNECT;
var RECEIVE_ROOM_JOIN = "".concat(NAME, "/RECEIVE_ROOM_JOIN");
exports.RECEIVE_ROOM_JOIN = RECEIVE_ROOM_JOIN;
var RECEIVE_ROOM_CHANGE = "".concat(NAME, "/RECEIVE_ROOM_CHANGE");
exports.RECEIVE_ROOM_CHANGE = RECEIVE_ROOM_CHANGE;
var RECEIVE_ROOM_LEAVE = "".concat(NAME, "/RECEIVE_ROOM_LEAVE");
exports.RECEIVE_ROOM_LEAVE = RECEIVE_ROOM_LEAVE;
var RECEIVE_CONNECTION = "".concat(NAME, "/RECEIVE_CONNECTION");
exports.RECEIVE_CONNECTION = RECEIVE_CONNECTION;
var RECEIVE_MESSAGE = "".concat(NAME, "/RECEIVE_MESSAGE");
exports.RECEIVE_MESSAGE = RECEIVE_MESSAGE;
var RECEIVE_DELETE_MESSAGE = "".concat(NAME, "/RECEIVE_DELETE_MESSAGE");
exports.RECEIVE_DELETE_MESSAGE = RECEIVE_DELETE_MESSAGE;
var RECEIVE_USER_JOIN = "".concat(NAME, "/RECEIVE_USER_JOIN");
exports.RECEIVE_USER_JOIN = RECEIVE_USER_JOIN;
var RECEIVE_USER_LEAVE = "".concat(NAME, "/RECEIVE_USER_LEAVE");
exports.RECEIVE_USER_LEAVE = RECEIVE_USER_LEAVE;
var RECEIVE_USER_BLOCK = "".concat(NAME, "/RECEIVE_USER_BLOCK");
exports.RECEIVE_USER_BLOCK = RECEIVE_USER_BLOCK;
var RECEIVE_USER_UNBLOCK = "".concat(NAME, "/RECEIVE_USER_UNBLOCK");
exports.RECEIVE_USER_UNBLOCK = RECEIVE_USER_UNBLOCK;
var RECEIVE_DISCONNECT = "".concat(NAME, "/RECEIVE_DISCONNECT");
exports.RECEIVE_DISCONNECT = RECEIVE_DISCONNECT;
var RESET_UNREAD_COUNTER = "".concat(NAME, "/RESET_UNREAD_COUNTER");
exports.RESET_UNREAD_COUNTER = RESET_UNREAD_COUNTER;
var SET_AUTO_READ_MODE = "".concat(NAME, "/SET_AUTO_READ_MODE");
exports.SET_AUTO_READ_MODE = SET_AUTO_READ_MODE;
var CONNECT_FAILED = "".concat(NAME, "/CONNECT_FAILED");
exports.CONNECT_FAILED = CONNECT_FAILED;
var RECEIVE_CONNECTION_FAILED_ERROR = "".concat(NAME, "/RECEIVE_CONNECTION_FAILED_ERROR");
exports.RECEIVE_CONNECTION_FAILED_ERROR = RECEIVE_CONNECTION_FAILED_ERROR;
var ROOM_CONNECT_FAILED = "".concat(NAME, "/ROOM_CONNECT_FAILED");
exports.ROOM_CONNECT_FAILED = ROOM_CONNECT_FAILED;
var ROOM_LEAVE_FAILED = "".concat(NAME, "/ROOM_LEAVE_FAILED");
exports.ROOM_LEAVE_FAILED = ROOM_LEAVE_FAILED;
var SEND_USER_BLOCK_FAILED = "".concat(NAME, "/SEND_USER_BLOCK_FAILED");
exports.SEND_USER_BLOCK_FAILED = SEND_USER_BLOCK_FAILED;
var SEND_USER_UNBLOCK_FAILED = "".concat(NAME, "/SEND_USER_UNBLOCK_FAILED");
exports.SEND_USER_UNBLOCK_FAILED = SEND_USER_UNBLOCK_FAILED;
var SEND_MESSAGE_FAILED = "".concat(NAME, "/SEND_MESSAGE_FAILED");
exports.SEND_MESSAGE_FAILED = SEND_MESSAGE_FAILED;
var SEND_DELETE_MESSAGE_FAILED = "".concat(NAME, "/SEND_DELETE_MESSAGE_FAILED");
exports.SEND_DELETE_MESSAGE_FAILED = SEND_DELETE_MESSAGE_FAILED;
var WEBSOCKET_EVENTS = {
  RECEIVE_CONNECTION: 'receive:connection',
  RECEIVE_MESSAGE: 'receive:message',
  RECEIVE_DELETE_MESSAGE: 'receive:deleteMessage',
  RECEIVE_USER_JOIN: 'receive:userJoin',
  RECEIVE_USER_LEAVE: 'receive:userLeave',
  RECEIVE_USER_BLOCK: 'receive:userBlock',
  RECEIVE_USER_UNBLOCK: 'receive:userUnblock',
  RECEIVE_ROOM_JOIN: 'receive:roomJoin',
  RECEIVE_ROOM_LEAVE: 'receive:roomLeave',
  RECEIVE_ROOM_CHANGE: 'receive:roomChange',
  RECEIVE_ERROR: 'send:error',
  SEND_CONNECTION: 'send:connection',
  SEND_MESSAGE: 'send:message',
  SEND_DELETE_MESSAGE: 'send:deleteMessage',
  SEND_USER_BLOCK: 'send:userBlock',
  SEND_USER_UNBLOCK: 'send:userUnblock',
  SEND_ROOM_JOIN: 'send:roomJoin',
  SEND_ROOM_LEAVE: 'send:roomLeave',
  SEND_ROOM_CHANGE: 'send:roomChange',
  CONNECT: 'connect',
  CONNECTING: 'connecting',
  DISCONNECT: 'disconnect',
  CONNECT_FAILED: 'connect_failed',
  ERROR: 'error',
  RECONNECT: 'reconnect',
  RECONNECTING: 'reconnecting',
  RECONNECT_FAILED: 'reconnect_failed'
};
exports.WEBSOCKET_EVENTS = WEBSOCKET_EVENTS;
var WEBSOCKET_STATUS = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected'
};
exports.WEBSOCKET_STATUS = WEBSOCKET_STATUS;
var WEBSOCKET_ERRORS = {
  GENERAL: 'general',
  AUTH: 'auth',
  SEND_MESSAGE: 'sendMessage',
  DELETE_MESSAGE: 'deleteMessage',
  BLOCK_USER: 'blockUser',
  UNBLOCK_USER: 'unblockUser'
};
exports.WEBSOCKET_ERRORS = WEBSOCKET_ERRORS;
var messageEntityAdapter = (0, _toolkit.createEntityAdapter)({
  selectId: function selectId(entity) {
    return entity.sig;
  },
  sortComparer: function sortComparer(a, b) {
    return new Date(a.iat) - new Date(b.iat);
  }
});
exports.messageEntityAdapter = messageEntityAdapter;
var userEntityAdapter = (0, _toolkit.createEntityAdapter)({
  selectId: function selectId(entity) {
    return entity.id;
  },
  sortComparer: function sortComparer(a, b) {
    return a.fnm.toLowerCase().localeCompare(b.fnm.toLowerCase());
  }
});
exports.userEntityAdapter = userEntityAdapter;
var blockedUserEntityAdapter = (0, _toolkit.createEntityAdapter)({
  selectId: function selectId(entity) {
    return entity.id;
  },
  sortComparer: function sortComparer(a, b) {
    return a.fnm.toLowerCase().localeCompare(b.fnm.toLowerCase());
  }
});
exports.blockedUserEntityAdapter = blockedUserEntityAdapter;