var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Expo;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _expoStyles = _interopRequireDefault(require("./expo-styles"));

function Expo(_ref) {
  var expo = _ref.expo,
      category = _ref.category,
      sponsor = _ref.sponsor;
  var navigation = (0, _useNavigationExtended.default)();
  var Styles = (0, _expoStyles.default)();
  var selectedStyle = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '0' ? (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '1' ? Styles.large : Styles.medium : Styles.small;
  var selectedStyleBtn = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '0' ? (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) !== '1' ? Styles.largeBtn : Styles.mediumBtn : Styles.smallBtn;
  var selectedStyleinfoContainer = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) === '0' ? Styles.smallinfoContainer : '';
  var titleVariant = (category === null || category === void 0 ? void 0 : category.selectedLogoSize.id) === '2' ? 'h2' : 'h3';
  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    justifyContent: "center",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    alignItems: "stretch",
    style: Styles.innerContainer
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    color: "containerBackground",
    style: (Styles.content, selectedStyle)
  }, _react.default.createElement(_reactNative.TouchableHighlight, {
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.EXPO_BOOTH_PAGE, {
        boothId: expo.id
      });
    }
  }, _react.default.createElement(_reactNative.Image, {
    resizeMode: 'cover',
    style: Styles.image,
    source: {
      uri: expo.headerUrl
    }
  })), _react.default.createElement(_Spacing.default, {
    mSpacing: ['l'],
    alignItems: "stretch",
    style: [Styles.infoContainer, selectedStyleinfoContainer]
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 's', 0],
    direction: "column",
    alignItems: "stretch",
    style: Styles.infoDescription
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 's', 0]
  }, _react.default.createElement(_Typography.default, {
    variant: titleVariant,
    style: Styles.cardTitle
  }, expo.headline)), _react.default.createElement(_Spacing.default, null, _react.default.createElement(_Typography.default, {
    variant: "h4"
  }, sponsor ? sponsor.name : ''))), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 0, 's'],
    justifyContent: "flex-end"
  }, _react.default.createElement(_ContainedButton.default, {
    style: (Styles.cardBtn, selectedStyleBtn),
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.EXPO_BOOTH_PAGE, {
        boothId: expo.id
      });
    }
  }, _react.default.createElement(_Typography.default, {
    style: [Styles.cardBtn, selectedStyleBtn]
  }, _i18n.default.t('expo.visitBooth'))))))));
}