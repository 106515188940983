var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ShareButtonContainer;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _shareButtonView = _interopRequireDefault(require("./share-button-view"));

var _shareModal = _interopRequireDefault(require("./share-modal"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ShareButtonContainer(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      showModal = _useState2[0],
      setShowModal = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      inviteUrl = _useState4[0],
      setInviteUrl = _useState4[1];

  var handlePress = function handlePress() {
    var linkParams = {};
    var config = {};

    if (props.deepLinkPath) {
      linkParams.deepLink = [process.env.DEEPLINK_SCHEME, '://', props.deepLinkPath].join('');
      linkParams.$desktop_redirect_url = [process.env.SOUNDMIX_WEB_APP_HOST, '/', props.deepLinkPath].join('');
    }

    if (props.referrerId) {
      var referrerId = props.referrerId,
          referrerName = props.referrerName,
          referrerPicture = props.referrerPicture;
      linkParams.userId = referrerId.toString();
      linkParams.displayName = referrerName;
      linkParams.imageUrl = referrerPicture;
    }

    if (props.ogTitle) {
      config.subject = props.ogTitle;
    }

    if (props.ogImageUrl) {
      config.imageUrl = props.ogImageUrl;
    }

    if (props.ogDescription) {}

    if (props.customData) {
      (0, _extends2.default)(linkParams, props.customData);
    }

    if (props.message) {
      config.text = props.message;
    }

    if (props.windowTitle) {
      config.windowTitle = props.windowTitle;
    }
  };

  var handleClose = function handleClose() {
    setShowModal(false);
  };

  return _react.default.createElement(_react.default.Fragment, null, showModal && _react.default.createElement(_shareModal.default, {
    testID: "shareModal",
    title: props.windowTitle,
    message: props.message,
    url: inviteUrl,
    onClose: handleClose
  }), _react.default.createElement(_reactNativePaper.TouchableRipple, {
    testID: "shareButton",
    borderless: props.borderless,
    onPress: handlePress,
    style: props.style
  }, _react.default.createElement(_reactNative.View, null, props.children ? props.children : _react.default.createElement(_shareButtonView.default, {
    style: props.contentStyle
  }))));
}