var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactNativePaperV = require("react-native-paper-v3");

var _textWithIconView = _interopRequireDefault(require("./text-with-icon-view"));

var _typographyStyles = _interopRequireDefault(require("./typography-styles"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function HeadingView(props) {
  var _useTheme = (0, _reactNativePaperV.useTheme)(),
      colors = _useTheme.colors;

  var textStyles = [_typographyStyles.default.heading, props.style];
  var iconProps = {
    icon: props.icon,
    iconSize: 21
  };

  if (props.iconSize != null) {
    iconProps = _objectSpread(_objectSpread({}, iconProps), {}, {
      iconSize: props.iconSize
    });
  }

  if (props.iconColor != null) {
    iconProps = _objectSpread(_objectSpread({}, iconProps), {}, {
      iconColor: props.iconColor
    });
  }

  if (props.inverted) {
    textStyles.push({
      color: colors.neutralDarkest
    });
    iconProps = _objectSpread(_objectSpread({}, iconProps), {}, {
      iconColor: colors.neutralDarkest
    });
  }

  if (props.textStyle === 'center') {
    textStyles.push({
      textAlign: 'center'
    });
  }

  return _react.default.createElement(_textWithIconView.default, (0, _extends2.default)({}, iconProps, {
    innerTextStyle: textStyles
  }), props.children);
}

var _default = HeadingView;
exports.default = _default;