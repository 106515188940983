var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ObsoleteScreenInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _reactNative = require("react-native");

var _reactNativePaper = require("react-native-paper");

var _moment = _interopRequireDefault(require("moment"));

var _notifications = _interopRequireDefault(require("../../core/notifications"));

var _Logo = _interopRequireDefault(require("../common/logo/Logo"));

var _style = _interopRequireDefault(require("./style"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var ObsoleteScreenInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(ObsoleteScreenInternal, _React$PureComponent);

  var _super = _createSuper(ObsoleteScreenInternal);

  function ObsoleteScreenInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, ObsoleteScreenInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handleUpgrade = function () {
      _reactNative.Linking.openURL(_this.props.upgradeUrl).catch(function () {});
    };

    _this.handleReadMore = function () {
      _reactNative.Linking.openURL(_this.props.readMoreUrl).catch(function () {});
    };

    return _this;
  }

  (0, _createClass2.default)(ObsoleteScreenInternal, [{
    key: "render",
    value: function render() {
      var action = null;
      var description = null;
      var colors = this.props.theme.colors;

      if (this.props.hasNewerVersion) {
        description = _react.default.createElement(_reactNativePaper.Subheading, {
          style: [_style.default.textStyle, {
            color: colors.text
          }]
        }, "This app version became obsolete", this.props.cutOffDate ? ' on ' + (0, _moment.default)(this.props.cutOffDate).format('MMMM Do YYYY') : '', ". Update it to keep on listening to your favorite music.");
        action = _react.default.createElement(_reactNativePaper.Button, {
          color: colors.white,
          mode: "contained",
          buttonSize: "large",
          onPress: this.handleUpgrade
        }, "Upgrade");
      } else if (this.props.readMoreUrl) {
        description = _react.default.createElement(_reactNativePaper.Subheading, {
          style: [_style.default.textStyle, {
            color: colors.text
          }]
        }, "This app version became obsolete", ' ', this.props.cutOffDate ? 'on ' + (0, _moment.default)(this.props.cutOffDate).format('MMMM Do YYYY') : '', ' ', "and the current version of your operating system does not allow app upgrades. Read about the options you have.");
        action = _react.default.createElement(_reactNativePaper.Button, {
          mode: "contained",
          color: colors.white,
          buttonSize: "large",
          onPress: this.handleReadMore
        }, "Learn more");
      } else {
        description = _react.default.createElement(_reactNativePaper.Subheading, {
          style: [_style.default.textStyle, {
            color: colors.text
          }]
        }, "This app version became obsolete", ' ', this.props.cutOffDate ? 'on ' + (0, _moment.default)(this.props.cutOffDate).format('MMMM Do YYYY') : '', ' ', "and the current version of your operating system does not allow app upgrades.");
      }

      return _react.default.createElement(_reactNative.View, {
        style: [_style.default.container, {
          backgroundColor: colors.background
        }]
      }, _react.default.createElement(_reactNative.View, {
        style: _style.default.logo
      }, _react.default.createElement(_Logo.default, null)), _react.default.createElement(_reactNative.View, null, description), _react.default.createElement(_reactNative.View, null, action));
    }
  }]);
  return ObsoleteScreenInternal;
}(_react.default.PureComponent);

exports.ObsoleteScreenInternal = ObsoleteScreenInternal;

function mapStateToProps(state) {
  return {
    hasNewerVersion: _notifications.default.selectors.hasNewerVersion(state),
    latestAvailableVersion: _notifications.default.selectors.getLatestAvailableVersion(state),
    upgradeUrl: _notifications.default.selectors.getLatestAvailableVersionUrl(state),
    readMoreUrl: _notifications.default.selectors.getLatestAvailableVersionReadMoreUrl(state),
    cutOffDate: _notifications.default.selectors.getAppCutOffDate(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps)((0, _reactNativePaper.withTheme)(ObsoleteScreenInternal));

exports.default = _default;