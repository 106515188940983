var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Participant;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _AbsoluteBox = _interopRequireDefault(require("../../../theme/components/box/AbsoluteBox"));

var _stringFormatter = require("../../../utils/string-formatter");

var _Avatar = _interopRequireDefault(require("./Avatar"));

var _GridItem = _interopRequireDefault(require("./GridItem"));

var _NameStrip = _interopRequireDefault(require("./NameStrip"));

var _RoleStrip = _interopRequireDefault(require("./RoleStrip"));

var _RemoteOrLocalAudio = _interopRequireDefault(require("./RemoteOrLocalAudio"));

var _RemoteOrLocalVideo = _interopRequireDefault(require("./RemoteOrLocalVideo"));

var _KickOutLiveParticipant = _interopRequireDefault(require("./KickOutLiveParticipant"));

var _Reconnecting = _interopRequireDefault(require("./Reconnecting"));

var _roleUtils = require("../../../utils/role-utils");

var _NetworkIndicator = _interopRequireDefault(require("./NetworkIndicator"));

var _MuteUnmuteSpeaker = _interopRequireDefault(require("./MuteUnmuteSpeaker"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function Participant(_ref) {
  var info = _ref.info,
      grid = _ref.grid,
      participant = _ref.participant,
      isLocal = _ref.isLocal,
      id = _ref.id,
      _ref$hasScreenShare = _ref.hasScreenShare,
      hasScreenShare = _ref$hasScreenShare === void 0 ? false : _ref$hasScreenShare,
      roomId = _ref.roomId,
      canUserMuteSpeaker = _ref.canUserMuteSpeaker;
  var participantHasAudioTrack = !!(participant !== null && participant !== void 0 && participant.audioTrack);

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      audioEnabled = _useAudioVideoContext.audioEnabled;

  return _react.default.createElement(_GridItem.default, (0, _extends2.default)({
    key: grid.id
  }, grid), _react.default.createElement(_RemoteOrLocalVideo.default, (0, _extends2.default)({}, grid, {
    videoTrack: participant === null || participant === void 0 ? void 0 : participant.videoTrack,
    isLocal: isLocal,
    key: "video"
  }), _react.default.createElement(_Avatar.default, {
    sizes: "".concat(grid.width / 3, "px"),
    src: info === null || info === void 0 ? void 0 : info.picture,
    alt: info === null || info === void 0 ? void 0 : info.name
  }, (0, _stringFormatter.getInitials)(info === null || info === void 0 ? void 0 : info.name))), _react.default.createElement(_RemoteOrLocalAudio.default, {
    isLocal: isLocal,
    audioTrack: participant === null || participant === void 0 ? void 0 : participant.audioTrack,
    canUserMuteSpeaker: canUserMuteSpeaker
  }, isLocal ? !audioEnabled ? _react.default.createElement(_AbsoluteBox.default, {
    size: grid.width,
    style: {
      margin: 4,
      padding: 10,
      borderRadius: '50%'
    }
  }, _react.default.createElement(_reactFeather.MicOff, {
    width: "1em",
    height: "1em",
    color: "#FFF"
  })) : null : canUserMuteSpeaker ? !!participant && _react.default.createElement(_AbsoluteBox.default, {
    size: grid.width,
    style: {
      margin: 4,
      padding: 5,
      borderRadius: '50%'
    }
  }, _react.default.createElement(_MuteUnmuteSpeaker.default, {
    participantHasAudioTrack: participantHasAudioTrack,
    roomId: roomId,
    speakerId: id,
    color: "#00000080"
  })) : !(participant !== null && participant !== void 0 && participant.audioTrack) ? _react.default.createElement(_AbsoluteBox.default, {
    size: grid.width,
    style: {
      margin: 4,
      padding: 10,
      borderRadius: '50%'
    }
  }, _react.default.createElement(_reactFeather.MicOff, {
    width: "1em",
    height: "1em",
    color: "#FFF"
  })) : null), _react.default.createElement(_NameStrip.default, {
    key: "name",
    name: info === null || info === void 0 ? void 0 : info.name,
    width: grid.width
  }), _react.default.createElement(_RoleStrip.default, {
    key: "role",
    role: (0, _roleUtils.getHighestRole)(info === null || info === void 0 ? void 0 : info.roles),
    width: grid.width
  }), _react.default.createElement(_KickOutLiveParticipant.default, {
    key: "kickOut",
    id: id,
    role: (0, _roleUtils.getHighestRole)(info === null || info === void 0 ? void 0 : info.roles),
    name: (info === null || info === void 0 ? void 0 : info.name) || ''
  }), isLocal && !hasScreenShare && _react.default.createElement(_AbsoluteBox.default, {
    align: {
      vertical: 'top',
      horizontal: 'left'
    },
    size: grid.width,
    style: {
      margin: 4,
      padding: 10,
      borderRadius: '50%',
      color: 'white'
    }
  }, _react.default.createElement(_NetworkIndicator.default, {
    color: "inherit",
    size: "inherit"
  })) || null, (info === null || info === void 0 ? void 0 : info.isReconnecting) && _react.default.createElement(_Reconnecting.default, {
    grid: grid
  }) || null);
}