var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FestivalHeaderInternal = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var React = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _reactNavigation = require("react-navigation");

var _festival = _interopRequireDefault(require("../../../core/festival"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _flags = _interopRequireDefault(require("../../../core/flags"));

var _reactNative = require("react-native");

var _Paragraph = _interopRequireDefault(require("../../common/typography_v1/Paragraph"));

var _Button = _interopRequireDefault(require("../../common/Button"));

var _FestivalDateFormat = _interopRequireDefault(require("../helpers/FestivalDateFormat"));

var _reactNativeLinearGradient = _interopRequireDefault(require("react-native-linear-gradient"));

var _ShareButton = _interopRequireDefault(require("../../common/ShareButton"));

var _CustomCachedImage = _interopRequireDefault(require("../../common/CustomCachedImage"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var FestivalHeaderInternal = function (_React$PureComponent) {
  (0, _inherits2.default)(FestivalHeaderInternal, _React$PureComponent);

  var _super = _createSuper(FestivalHeaderInternal);

  function FestivalHeaderInternal() {
    var _this;

    (0, _classCallCheck2.default)(this, FestivalHeaderInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _this.handlePress = function () {
      _this.props.navigation.navigate('VipPopup', {
        ticket: _this.props.ticket,
        isFestivalPopup: true
      });
    };

    return _this;
  }

  (0, _createClass2.default)(FestivalHeaderInternal, [{
    key: "render",
    value: function render() {
      var shareBtnPosition = this.props.isVipFestival === true && this.props.hasAccess === false ? 94 : 24;
      return React.createElement(_reactNative.View, {
        style: styles.container
      }, React.createElement(_CustomCachedImage.default, {
        style: styles.itemImage,
        source: {
          uri: this.props.backgroundImage
        },
        resizeMode: "cover"
      }), React.createElement(_reactNativeLinearGradient.default, {
        locations: [0, 0.5, 0.6, 0.7, 1],
        colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.0)', 'rgba(0, 0, 0, 0.05)', 'rgba(0, 0, 0, 0.2)', 'rgba(0, 0, 0, 1)'],
        style: styles.linearGradient
      }), React.createElement(_reactNative.View, {
        style: styles.infoContainer
      }, React.createElement(_reactNative.View, {
        style: styles.logoContainer
      }, React.createElement(_CustomCachedImage.default, {
        style: styles.logo,
        source: {
          uri: this.props.logo
        },
        resizeMode: "contain"
      })), React.createElement(_Paragraph.default, {
        style: styles.festivalDate
      }, (0, _FestivalDateFormat.default)(this.props.startDate, this.props.endDate)), this.props.isVipFestival && !this.props.hasAccess && !this.props.disablePaymentRegionFestivalFlag && React.createElement(_Button.default, {
        testID: "GetVipButton",
        icon: "vip-badge",
        mode: "contained",
        color: this.props.festivalThemeColor,
        buttonSize: "large",
        onPress: this.handlePress,
        style: styles.actionButton
      }, 'GET VIP ACCESS'), React.createElement(_ShareButton.default, {
        style: [styles.shareBtnStyle, {
          bottom: shareBtnPosition
        }],
        contentStyle: styles.shareBtnContentStyle,
        borderless: true,
        deepLinkPath: "festival/".concat(this.props.festivalId),
        ogTitle: this.props.festivalName,
        ogImageUrl: this.props.festivalImage,
        ogDescription: this.props.festivalDescription,
        message: "Check out the best performances from ".concat(this.props.festivalName, ", LIVE on Soundmix! [APP_INVITE_URL]"),
        windowTitle: "Share with Friends!"
      })));
    }
  }]);
  return FestivalHeaderInternal;
}(React.PureComponent);

exports.FestivalHeaderInternal = FestivalHeaderInternal;

var styles = _reactNative.StyleSheet.create({
  container: {
    aspectRatio: 4 / 3,
    width: '100%',
    minHeight: _reactNative.Platform.OS === 'web' ? _reactNative.Dimensions.get('screen').height / 3.5 : 0
  },
  itemImage: {
    flex: 1
  },
  linearGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    flex: 1
  },
  infoContainer: {
    position: 'absolute',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    bottom: 0,
    width: '100%',
    height: '100%',
    paddingHorizontal: 16
  },
  logoContainer: {
    width: '100%',
    height: '35%',
    maxWidth: 343,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 16
  },
  logo: {
    borderRadius: 8,
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  festivalDate: {
    textAlign: 'center',
    lineHeight: 15,
    marginBottom: 24
  },
  actionButton: {
    width: '100%',
    maxWidth: 400,
    marginBottom: 24
  },
  shareBtnStyle: {
    position: 'absolute',
    right: 16,
    bottom: 94
  },
  shareBtnContentStyle: {
    borderRadius: 22,
    width: 44,
    height: 44,
    backgroundColor: 'transparent',
    borderColor: 'rgba(255,255,255,1)',
    borderWidth: 1
  }
});

function mapStateToProps(state, ownProps) {
  var id = ownProps.festivalId;

  var festivalTicketID = _festival.default.selectors.getAccessTicketID(state, id);

  return {
    backgroundImage: _festival.default.selectors.getBackgroundImage(state, id),
    startDate: _festival.default.selectors.getStartDate(state, id),
    endDate: _festival.default.selectors.getEndDate(state, id),
    logo: _festival.default.selectors.getLogo(state, id),
    hasAccess: _user.default.selectors.hasTicketAccess(state, festivalTicketID),
    festivalThemeColor: _festival.default.selectors.getThemeColor(state, id),
    isVipFestival: _festival.default.selectors.isVipFestival(state, id),
    festivalName: _festival.default.selectors.getName(state, id),
    festivalDescription: _festival.default.selectors.getDescription(state, id),
    festivalImage: _festival.default.selectors.getImage(state, id),
    disablePaymentRegionFestivalFlag: _flags.default.selectors.disablePaymentRegionFestivalFlag(state),
    ticket: _festival.default.selectors.getAccessTicket(state, id)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {})((0, _reactNavigation.withNavigation)(FestivalHeaderInternal));

exports.default = _default;