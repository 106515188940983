var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ticketsEntityAdapter = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _createReducer;

var ticketsEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.ticketsEntityAdapter = ticketsEntityAdapter;
var initialState = ticketsEntityAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  rightsLoading: false,
  rightsLoaded: false,
  boughtTicketsLoading: false,
  boughtTickets: []
});
var ticketsReducer = (0, _toolkit.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getTickets.pending, function (state, _) {
  state.loading = true;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTickets.rejected, function (state, _ref) {
  var error = _ref.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTickets.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loading = false;
  state.loaded = true;
  ticketsEntityAdapter.addMany(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return initialState;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTicketRights.pending, function (state, _) {
  state.rightsLoading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTicketRights.rejected, function (state, _) {
  state.rightsLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.getTicketRights.fulfilled, function (state, _ref3) {
  var payload = _ref3.payload;
  state.rightsLoading = false;
  state.rightsLoaded = true;
  ticketsEntityAdapter.updateMany(state, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.getBoughtTickets.pending, function (state, _) {
  state.rightsLoading = true;
  state.boughtTicketsLoading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getBoughtTickets.rejected, function (state, _) {
  state.boughtTicketsLoading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getBoughtTickets.fulfilled, function (state, _ref4) {
  var payload = _ref4.payload;
  state.boughtTicketsLoading = false;
  state.boughtTickets = payload;
}), _createReducer));
var _default = ticketsReducer;
exports.default = _default;