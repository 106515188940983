var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderContainer;

var _react = _interopRequireDefault(require("react"));

var _leaderboardCountdown = _interopRequireDefault(require("./leaderboard-countdown"));

var _leaderboardPrizes = _interopRequireDefault(require("./leaderboard-prizes"));

var _winnersAnnouncement = _interopRequireDefault(require("./winners-announcement"));

function HeaderContainer(props) {
  var isFinished = props.isFinished,
      isEmpty = props.isEmpty,
      untilDate = props.untilDate,
      onFinish = props.onFinish,
      totalPrize = props.totalPrize;
  return _react.default.createElement(_react.default.Fragment, null, !isFinished ? _react.default.createElement(_leaderboardPrizes.default, {
    totalPrize: totalPrize
  }) : _react.default.createElement(_winnersAnnouncement.default, null), !isEmpty && !isFinished && _react.default.createElement(_leaderboardCountdown.default, {
    untilDate: untilDate,
    onFinish: onFinish
  }));
}