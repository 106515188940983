var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DynamicSection;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DesignSystem = require("../../DesignSystem");

var _lodash = _interopRequireDefault(require("lodash"));

var _colorUtils = _interopRequireDefault(require("../../../utils/color-utils"));

var _excluded = ["children", "style", "lightColor", "darkColor", "elevation", "shaping", "parentBackground"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function DynamicSection(_ref) {
  var children = _ref.children,
      style = _ref.style,
      _ref$lightColor = _ref.lightColor,
      lightColor = _ref$lightColor === void 0 ? '#FFFFFF' : _ref$lightColor,
      _ref$darkColor = _ref.darkColor,
      darkColor = _ref$darkColor === void 0 ? '#000000' : _ref$darkColor,
      elevation = _ref.elevation,
      _ref$shaping = _ref.shaping,
      shaping = _ref$shaping === void 0 ? 'none' : _ref$shaping,
      parentBackground = _ref.parentBackground,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      shadows = _useTheme.shadows,
      shape = _useTheme.shape;

  var color = (0, _colorUtils.default)(colors[parentBackground] || parentBackground || colors.pageBackground, lightColor, darkColor);
  var textColor = (0, _colorUtils.default)(color, lightColor, darkColor);
  return _react.default.createElement(_reactNative.View, (0, _extends2.default)({}, rest, {
    style: [{
      backgroundColor: colors[color] || color
    }, _objectSpread({}, shape[shaping] || {}), elevation ? {
      boxShadow: shadows[elevation]
    } : {}, style]
  }), _lodash.default.isFunction(children) ? children(textColor) : children);
}