var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textUppercase: {
      textTransform: 'uppercase'
    },
    textNormal: {
      fontWeight: '400'
    },
    title: (0, _defineProperty2.default)({
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10
    }, theme.breakpoints.up('sm'), {
      textAlign: 'left'
    }),
    textContainer: (0, _defineProperty2.default)({
      margin: 10,
      alignItems: 'center',
      justifyContent: 'center'
    }, theme.breakpoints.up('sm'), {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '0 0 0 10px'
    }),
    description: {
      marginTop: 10
    },
    eventTime: {
      fontWeight: '400'
    }
  };
});
var _default = styles;
exports.default = _default;