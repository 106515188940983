var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _noVideosStyles = _interopRequireDefault(require("./no-videos-styles"));

var NoVideosAvailable = function NoVideosAvailable() {
  var Styles = (0, _noVideosStyles.default)();
  return _react.default.createElement(_Spacing.default, {
    direction: "row",
    flex: 1,
    alignItems: "stretch",
    style: Styles.container
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    flex: 1,
    alignItems: "center",
    style: Styles.lobbyCard,
    justifyContent: "center"
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    color: "containerBackground",
    style: Styles.noVideContainer
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'xxl'],
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    align: "center",
    variant: "body1"
  }, _i18n.default.t('videos.noVideos'))))));
};

var _default = NoVideosAvailable;
exports.default = _default;