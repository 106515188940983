var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../core/user"));

var _profile = _interopRequireDefault(require("../../core/profile"));

var _gameSparks = _interopRequireDefault(require("../../core/gameSparks"));

var _mybeatsScreenContainer = _interopRequireDefault(require("./mybeats-screen-container"));

function mapStateToProps(state) {
  var id = _user.default.selectors.getUserId(state);

  return {
    userId: id,
    userName: _user.default.selectors.getUserName(state),
    userEmail: _user.default.selectors.getUserEmail(state),
    bio: _profile.default.selectors.getBio(state, id),
    beatsThisMonth: _gameSparks.default.selectors.getCurrentLeaderboardBeats(state),
    beatsBalance: _gameSparks.default.selectors.getBeatsBalance(state),
    totalBalance: _gameSparks.default.selectors.geTotaltBeatsBalance(state),
    referralsBeats: _gameSparks.default.selectors.getNewReferralBeatsValue(state),
    referralsMessage: _gameSparks.default.selectors.getNewReferralBeatsMessage(state),
    referralsBeatsDj: _gameSparks.default.selectors.getNewDjReferralBeatsValue(state),
    referralsDjMessage: _gameSparks.default.selectors.getNewDjReferralBeatsMessage(state),
    liveReferralsBeats: _gameSparks.default.selectors.getNewLiveBeatsValue(state),
    liveReferralsMessage: _gameSparks.default.selectors.getNewLiveBeatsMessage(state),
    isDj: _user.default.selectors.isLoggedUserDj(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadGsPlayer: _gameSparks.default.actions.loadPlayerData,
  getGsPropertySet: _gameSparks.default.actions.getPropertySet
})(_mybeatsScreenContainer.default);

exports.default = _default;