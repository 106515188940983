var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _ticketCardStyles = _interopRequireDefault(require("./ticket-card-styles"));

var _currencyUtils = require("../../../../utils/currency-utils");

var _timezoneUtils = require("../../../../utils/timezone-utils");

var TicketsCardView = function TicketsCardView(_ref) {
  var paymentId = _ref.paymentId,
      name = _ref.name,
      description = _ref.description,
      price = _ref.price,
      currency = _ref.currency,
      createdDate = _ref.createdDate,
      rights = _ref.rights,
      timezone = _ref.timezone;
  var Styles = (0, _ticketCardStyles.default)();

  var returnDaysInSameTimezone = function returnDaysInSameTimezone() {
    var validity = [];

    if (rights !== null && rights !== void 0 && rights.selectedDays) {
      rights.selectedDays.map(function (date) {
        var newDay = (0, _momentTimezone.default)(date.name);
        validity.push(newDay.format('DD MMM'));
      });
    }

    return validity.sort().join(', ');
  };

  var returnDaysInOtherTimezone = function returnDaysInOtherTimezone() {
    var validity = [];

    if (rights !== null && rights !== void 0 && rights.selectedDays) {
      rights.selectedDays.map(function (date) {
        var start = _momentTimezone.default.tz(date.name, timezone).startOf('day');

        var end = _momentTimezone.default.tz(date.name, timezone).endOf('day');

        validity.push("".concat(_momentTimezone.default.tz(start, _momentTimezone.default.tz.guess()).format('DD MMM, hh:mm:ss A'), " - ").concat(_momentTimezone.default.tz(end, _momentTimezone.default.tz.guess()).format('DD MMM, hh:mm:ss A')));
      });
    }

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
      flex: 1,
      direction: "column",
      alignItems: "flex-start"
    }, _react.default.createElement(_Typography.default, null, _momentTimezone.default.tz.guess(), " (", (0, _timezoneUtils.getZoneAbbreviation)(), ")"), validity.sort().map(function (date) {
      return _react.default.createElement(_Typography.default, {
        key: date
      }, date);
    })));
  };

  var getTicketValidity = function getTicketValidity() {
    if (!!rights && rights.selectedAllDays) {
      return _i18n.default.t('checkout.allDays');
    } else {
      return _momentTimezone.default.tz.zone(timezone).abbrs.includes((0, _timezoneUtils.getZoneAbbreviation)()) ? returnDaysInSameTimezone() : returnDaysInOtherTimezone();
    }
  };

  return _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    style: Styles.container,
    pSpacing: ['l']
  }, _react.default.createElement(_Spacing.default, {
    flex: 1,
    direction: "column",
    alignItems: "flex-start"
  }, _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    variant: "h2"
  }, name), _react.default.createElement(_Spacing.default, {
    mSpacing: ['xs', 0, 'm', 0]
  }, _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    variant: "description"
  }, description)), _react.default.createElement(_Typography.default, {
    color: "alternativeText"
  }, _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    style: {
      fontWeight: 'bold'
    }
  }, _i18n.default.t('checkout.validity'), ":"), ' ', getTicketValidity()), paymentId !== 'FREE' && _react.default.createElement(_Typography.default, {
    color: "alternativeText"
  }, _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    style: {
      fontWeight: 'bold'
    }
  }, _i18n.default.t('checkout.buyedOn'), ":"), ' ', createdDate ? (0, _momentTimezone.default)(createdDate.toDate()).format('DD MMMM YYYY, hh:mm:ss') : '-')), _react.default.createElement(_Spacing.default, {
    style: Styles.price
  }, paymentId !== 'FREE' && _react.default.createElement(_Typography.default, {
    color: "alternativeText",
    variant: "h2"
  }, (0, _currencyUtils.concatPriceWithCurrency)(price, currency))));
};

var _default = TicketsCardView;
exports.default = _default;