var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/expo/selectors");

var _selectors2 = require("../../../core/partners/selectors");

var _selectors3 = require("../../../core/speakers/selectors");

var _selectors4 = require("../../../core/recordedVideos/selectors");

var _LinkButton = _interopRequireDefault(require("../../../theme/components/buttons/LinkButton"));

var _Booth = _interopRequireDefault(require("../../../theme/components/icons/Booth"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _sideMenuStyles = _interopRequireDefault(require("./side-menu-styles"));

var _heightListener = _interopRequireDefault(require("./height-listener/height-listener"));

var _selectors5 = require("../../../core/landing-page-sections/selectors");

var _consts = require("../../../utils/consts");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _selectors6 = require("../../../core/networking/selectors");

var _hamburgerMenuFooter = _interopRequireDefault(require("./landing-page/menu-footer/hamburger-menu-footer"));

var _reactNativePaperV = require("react-native-paper-v3");

var _useOpenState3 = _interopRequireDefault(require("./useOpenState"));

var _eventSettings = _interopRequireDefault(require("../../../core/event-settings"));

var CustomSideMenu = function CustomSideMenu() {
  var partnersLength = (0, _reactRedux.useSelector)(_selectors2.selectPartnersLength);
  var speakersLength = (0, _reactRedux.useSelector)(_selectors3.selectSpeakersLength);
  var boothsLength = (0, _reactRedux.useSelector)(_selectors.selectExposLength);
  var videoLength = (0, _reactRedux.useSelector)(_selectors4.selectRecordedVideosLength);
  var tablesLength = (0, _reactRedux.useSelector)(_selectors6.tablesListLengthSelector);

  var _useSelector = (0, _reactRedux.useSelector)(_eventSettings.default.selectors.labelsSettingsSelector),
      sponsorsTitle = _useSelector.Sponsors,
      scheduleTitle = _useSelector.Schedule,
      speakersTitle = _useSelector.Speakers;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      breakpoints = _useTheme.breakpoints;

  var sections = (0, _reactRedux.useSelector)(_selectors5.selectSections);
  var sectionTypes = sections.filter(function (section) {
    return section.isActive;
  }).map(function (section) {
    return section.type;
  });
  var menuItems = [{
    name: _i18n.default.t('leftMenu.home'),
    icon: _react.default.createElement(_reactFeather.Home, null),
    path: 'Lobby'
  }, {
    name: _i18n.default.t('leftMenu.liveNow'),
    icon: _react.default.createElement(_reactFeather.Video, null),
    path: 'LiveNowPage',
    subPaths: ['EventLive']
  }];

  if (boothsLength) {
    menuItems.push({
      name: _i18n.default.t('leftMenu.expo'),
      icon: _react.default.createElement(_Booth.default, null),
      path: 'ExpoPage',
      subPaths: ['ExpoBoothPage']
    });
  }

  if (tablesLength) {
    menuItems.push({
      name: _i18n.default.t('leftMenu.networking'),
      icon: _react.default.createElement(_reactFeather.Users, null),
      path: _routesConsts.Routes.NETWORKING_PAGE
    });
  }

  if (sectionTypes.includes(_consts.SECTION_TYPES.SCHEDULE)) {
    menuItems.push({
      name: scheduleTitle || _i18n.default.t('leftMenu.schedule'),
      icon: _react.default.createElement(_reactFeather.Calendar, null),
      path: 'AgendaPage'
    });
  }

  if (videoLength) {
    menuItems.push({
      name: _i18n.default.t('leftMenu.videos'),
      icon: _react.default.createElement(_reactFeather.Youtube, null),
      path: 'RecordedVideosPage'
    });
  }

  if (speakersLength && sectionTypes.includes(_consts.SECTION_TYPES.SPEAKERS)) {
    menuItems.push({
      name: speakersTitle || _i18n.default.t('leftMenu.speakers'),
      icon: _react.default.createElement(_reactFeather.Mic, null),
      path: 'SpeakersPage'
    });
  }

  if (partnersLength && sectionTypes.includes(_consts.SECTION_TYPES.SPONSORS)) {
    menuItems.push({
      name: sponsorsTitle || _i18n.default.t('leftMenu.sponsors'),
      icon: _react.default.createElement(_reactFeather.Flag, null),
      path: 'PartnersPage'
    });
  }

  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      currentRoute = _useNavigationExtende.state.routeName,
      navigate = _useNavigationExtende.navigate;

  var Styles = (0, _sideMenuStyles.default)();
  var match = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
    var breakpoints = _ref.breakpoints;
    return breakpoints.bg;
  });

  var _useOpenState = (0, _useOpenState3.default)('from side menu'),
      _useOpenState2 = (0, _slicedToArray2.default)(_useOpenState, 2),
      open = _useOpenState2[0],
      setOpen = _useOpenState2[1];

  var renderMenuItem = function renderMenuItem(item) {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: [match ? 'm' : 'l', 's'],
      alignItems: "center",
      justifyContent: "center",
      key: item.name
    }, _react.default.createElement(_LinkButton.default, {
      style: Styles.buttonStyle,
      onPress: function onPress() {
        navigate(item.path);

        if (_reactNative.Dimensions.get('window').width <= breakpoints.bg) {
          setOpen(false);
        }
      }
    }, _react.default.createElement(_Spacing.default, {
      direction: match ? 'row' : 'column',
      alignItems: "center",
      justifyContent: "center"
    }, _react.default.createElement(_Typography.default, {
      color: item.path === currentRoute || item.subPaths && item.subPaths.includes(currentRoute) ? 'button' : 'alternativeText',
      style: Styles.icon
    }, item.icon), _react.default.createElement(_Typography.default, {
      style: Styles.buttonTextStyle,
      variant: match ? 'h4' : 'smallText',
      color: item.path === currentRoute || item.subPaths && item.subPaths.includes(currentRoute) ? 'button' : 'alternativeText'
    }, item.name))));
  };

  return (open || !match) && _react.default.createElement(_Section.default, {
    onLayout: function onLayout(_ref2) {
      var height = _ref2.nativeEvent.layout.height;

      if (height) {
        _heightListener.default.nextHeight(height);
      }
    },
    color: "containerBackground",
    style: Styles.container
  }, _react.default.createElement(_reactNative.ScrollView, null, _react.default.createElement(_reactNative.View, {
    style: Styles.innerContainer
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: match ? 'flex-start' : 'center'
  }, menuItems.map(function (item) {
    return renderMenuItem(item);
  })), _react.default.createElement(_reactNativePaperV.TouchableRipple, {
    style: Styles.closeIcon,
    onPress: function onPress() {
      return setOpen(false);
    }
  }, _react.default.createElement(_reactFeather.X, {
    size: "24"
  })), _react.default.createElement(_Spacing.default, {
    style: Styles.footer
  }, _react.default.createElement(_hamburgerMenuFooter.default, null)))));
};

var _default = CustomSideMenu;
exports.default = _default;