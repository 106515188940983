var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleGamesparksConnection = handleGamesparksConnection;
exports.gameSparksInitCompleted = gameSparksInitCompleted;
exports.setGsError = setGsError;
exports.getProperty = getProperty;
exports.isGsPropertyLoading = isGsPropertyLoading;
exports.gsPropertyCompleted = gsPropertyCompleted;
exports.getPropertySet = getPropertySet;
exports.isGsPropertySetLoading = isGsPropertySetLoading;
exports.gsPropertySetCompleted = gsPropertySetCompleted;
exports.loadLeaderboardEntry = loadLeaderboardEntry;
exports.isLeaderboardEntryLoading = isLeaderboardEntryLoading;
exports.loadLeaderboardEntryCompleted = loadLeaderboardEntryCompleted;
exports.loadPlayerData = loadPlayerData;
exports.isPlayerDataLoading = isPlayerDataLoading;
exports.loadPlayerDataCompleted = loadPlayerDataCompleted;
exports.loadSpecificPlayerData = loadSpecificPlayerData;
exports.isSpecificPlayerDataLoading = isSpecificPlayerDataLoading;
exports.loadSpecificPlayerDataCompleted = loadSpecificPlayerDataCompleted;
exports.loadLastMonthLeaderboard = loadLastMonthLeaderboard;
exports.loadLeaderboardData = loadLeaderboardData;
exports.isLeaderboardLoading = isLeaderboardLoading;
exports.loadLeaderboardDataCompleted = loadLeaderboardDataCompleted;
exports.loadAroundMeList = loadAroundMeList;
exports.loadAroundMeCompleted = loadAroundMeCompleted;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actionTypes = require("./actionTypes");

var _constants = require("./constants");

var _helpers = require("./helpers");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getSetupConfig = function getSetupConfig(dispatch, options) {
  var opts = _objectSpread({
    onError: function onError(err) {
      return dispatch(setGsError(err));
    }
  }, options);

  return opts;
};

function handleGamesparksConnection(authToken) {
  return function (dispatch, getState, _ref) {
    var gsSocket = _ref.gsSocket;

    if (gsSocket.isConnected()) {
      gsSocket.connectWithAuthToken(authToken, _objectSpread({
        onInit: function onInit() {
          dispatch(gameSparksInitCompleted(gsSocket.getLoggedUser(), authToken));
        }
      }, getSetupConfig(dispatch)));
    } else {
      gsSocket.initByEnv(_objectSpread({
        key: process.env.GAMESPARKS_KEY,
        secret: process.env.GAMESPARKS_SECRET,
        onInit: function onInit() {
          gsSocket.connectWithAuthToken(authToken, _objectSpread({
            onInit: function onInit() {
              dispatch(gameSparksInitCompleted(gsSocket.getLoggedUser(), authToken));
            }
          }, getSetupConfig(dispatch)));
        }
      }, getSetupConfig(dispatch)));
    }
  };
}

function gameSparksInitCompleted(playerId, authToken, gsDeviceId) {
  return {
    type: _actionTypes.GS_INIT_COMPLETED,
    playerId: playerId,
    authToken: authToken,
    gsDeviceId: gsDeviceId
  };
}

function setGsError(error) {
  return {
    type: _actionTypes.GS_ERROR,
    error: error
  };
}

function getProperty(propertyShortCode) {
  return function (dispatch, getState, _ref2) {
    var gsSocket = _ref2.gsSocket;
    dispatch(isGsPropertyLoading(true));
    return gsSocket.sendWithData('.GetPropertyRequest', {
      propertyShortCode: propertyShortCode
    }, function (res) {
      var property = res.property,
          error = res.error;

      if (error) {
        dispatch(isGsPropertyLoading(false));
        return dispatch(setGsError(res.error));
      }

      if (property && property.value) {
        dispatch(gsPropertyCompleted(propertyShortCode, property.value));
      }
    });
  };
}

function isGsPropertyLoading(loading) {
  return {
    type: _actionTypes.IS_GS_PROPERTY_LOADING,
    loading: loading
  };
}

function gsPropertyCompleted(propName, value) {
  return {
    type: _actionTypes.GS_PROPERTY_COMPLETED,
    propName: propName,
    value: value
  };
}

function getPropertySet(propertySetShortCode) {
  return function (dispatch, getState, _ref3) {
    var gsSocket = _ref3.gsSocket;
    dispatch(isGsPropertySetLoading(true));
    return gsSocket.sendWithData('.GetPropertySetRequest', {
      propertySetShortCode: propertySetShortCode
    }, function (res) {
      var propertySet = res.propertySet,
          error = res.error;

      if (error) {
        dispatch(isGsPropertySetLoading(false));
        return dispatch(setGsError(res.error));
      }

      if (propertySet) {
        dispatch(gsPropertySetCompleted(propertySetShortCode, propertySet));
      }
    });
  };
}

function isGsPropertySetLoading(loading) {
  return {
    type: _actionTypes.IS_GS_PROPERTY_SET_LOADING,
    loading: loading
  };
}

function gsPropertySetCompleted(propName, propertySet) {
  return {
    type: _actionTypes.GS_PROPERTY_SET_COMPLETED,
    propName: propName,
    propertySet: propertySet
  };
}

function loadLeaderboardEntry(playerId) {
  return function (dispatch, getState, _ref4) {
    var gsSocket = _ref4.gsSocket;
    var shortCode = (0, _helpers.getShortCode)();
    dispatch(isLeaderboardEntryLoading(true));
    return gsSocket.sendWithData('.GetLeaderboardEntriesRequest', {
      leaderboards: [shortCode],
      player: playerId
    }, function (res) {
      if (res.error) {
        dispatch(isLeaderboardEntryLoading(false));
        return dispatch(setGsError(res.error));
      }

      if (res[shortCode]) {
        dispatch(loadLeaderboardEntryCompleted(res[shortCode]));
      }
    });
  };
}

function isLeaderboardEntryLoading(loading) {
  return {
    type: _actionTypes.IS_LEADERBOARD_ENTRY_LOADING,
    loading: loading
  };
}

function loadLeaderboardEntryCompleted(data) {
  return {
    type: _actionTypes.LOAD_LEADERBOARD_ENTRY_COMPLETED,
    data: data
  };
}

function loadPlayerData(simulateError) {
  var shortCode = (0, _helpers.getShortCode)();
  var paramsObj = {
    scriptData: {
      shortCode: shortCode
    }
  };
  return function (dispatch, getState, _ref5) {
    var gsSocket = _ref5.gsSocket;
    dispatch(isPlayerDataLoading(true));

    if (simulateError) {
      paramsObj = {
        simulateError: simulateError
      };
    }

    return gsSocket.sendWithData('.AccountDetailsRequest', paramsObj, function (res) {
      var error = res.error,
          currencies = res.currencies;

      if (error) {
        dispatch(isPlayerDataLoading(false));
        return dispatch(setGsError(error));
      }

      if (currencies) {
        dispatch(loadPlayerDataCompleted(res));
      }
    });
  };
}

function isPlayerDataLoading(loading) {
  return {
    type: _actionTypes.IS_PLAYER_LOADING,
    loading: loading
  };
}

function loadPlayerDataCompleted(data) {
  return {
    type: _actionTypes.LOAD_PLAYER_COMPLETED,
    data: data
  };
}

function loadSpecificPlayerData(playerId, simulateError) {
  var paramsObj = {
    playerId: playerId,
    eventKey: 'GET_PLAYER_DATA'
  };
  return function (dispatch, getState, _ref6) {
    var gsSocket = _ref6.gsSocket;
    dispatch(isSpecificPlayerDataLoading(true));

    if (simulateError) {
      paramsObj = {
        simulateError: simulateError
      };
    }

    return gsSocket.sendWithData('.LogEventRequest', paramsObj, function (res) {
      var error = res.error;

      if (error) {
        dispatch(isSpecificPlayerDataLoading(false));
        return dispatch(setGsError(error));
      }

      dispatch(loadSpecificPlayerDataCompleted(res));
    });
  };
}

function isSpecificPlayerDataLoading(loading) {
  return {
    type: _actionTypes.IS_SPECIFIC_PLAYER_LOADING,
    loading: loading
  };
}

function loadSpecificPlayerDataCompleted(data) {
  return {
    type: _actionTypes.LOAD_SPECIFIC_PLAYER_COMPLETED,
    data: data
  };
}

function loadLastMonthLeaderboard(params) {
  var entryCount = params.entryCount,
      offset = params.offset,
      reset = params.reset;
  var lastMonthShortCode = (0, _helpers.getLastMonthShortCode)();
  return function (dispatch, getState, _ref7) {
    var gsSocket = _ref7.gsSocket;
    return gsSocket.sendWithData('.LeaderboardDataRequest', {
      leaderboardShortCode: lastMonthShortCode,
      entryCount: entryCount || _constants.ITEMS_ON_PAGE,
      offset: offset || 0
    }, function (res) {
      var error = res.error,
          data = res.data,
          _res$scriptData = res.scriptData,
          scriptData = _res$scriptData === void 0 ? {} : _res$scriptData;

      if (error && error.leaderboardShortCode === 'INVALID') {
        return dispatch(loadLeaderboardDataCompleted([], 0, true));
      }

      if (error) {
        dispatch(isLeaderboardLoading(false));
        return dispatch(setGsError(error));
      }

      var leaderboardList = data && scriptData.data || [];

      if (leaderboardList.length > 0) {
        dispatch(loadLeaderboardDataCompleted(leaderboardList, scriptData.total, reset || false, true));
      } else {
        dispatch(loadLeaderboardDataCompleted([], 0, true));
      }
    });
  };
}

function loadLeaderboardData(params) {
  var entryCount = params.entryCount,
      offset = params.offset,
      reset = params.reset,
      testParams = params.testParams;
  var shortCode = (0, _helpers.getShortCode)();
  var paramsObj = {
    leaderboardShortCode: shortCode,
    entryCount: entryCount || _constants.ITEMS_ON_PAGE,
    offset: offset || 0
  };

  if (testParams) {
    paramsObj = testParams;
  }

  return function (dispatch, getState, _ref8) {
    var gsSocket = _ref8.gsSocket;
    dispatch(isLeaderboardLoading(true));
    return gsSocket.sendWithData('.LeaderboardDataRequest', paramsObj, function (res) {
      var error = res.error,
          data = res.data,
          _res$scriptData2 = res.scriptData,
          scriptData = _res$scriptData2 === void 0 ? {} : _res$scriptData2;

      if (error && error.leaderboardShortCode === 'INVALID') {
        return dispatch(loadLastMonthLeaderboard(params));
      }

      if (error) {
        dispatch(isLeaderboardLoading(false));
        return dispatch(setGsError(error));
      }

      var leaderboardList = data && scriptData.data || [];

      if (leaderboardList.length > 0) {
        reset ? dispatch(loadAroundMeList({
          leaderboard: leaderboardList,
          leaderboardShortCode: shortCode,
          total: scriptData.total
        })) : dispatch(loadLeaderboardDataCompleted(leaderboardList, scriptData.total, false));
      } else {
        dispatch(loadLastMonthLeaderboard(params));
      }
    });
  };
}

function isLeaderboardLoading(loading) {
  return {
    type: _actionTypes.IS_LEADERBOARD_LOADING,
    loading: loading
  };
}

function loadLeaderboardDataCompleted(leaderboard, total, reset, isFromLastMonth) {
  return {
    type: _actionTypes.LOAD_LEADERBOARD_COMPLETED,
    leaderboard: leaderboard,
    total: total,
    reset: reset,
    isFromLastMonth: isFromLastMonth
  };
}

function loadAroundMeList(params) {
  var leaderboard = params.leaderboard,
      leaderboardShortCode = params.leaderboardShortCode,
      total = params.total,
      testParams = params.testParams;
  var paramsObj = {
    leaderboardShortCode: leaderboardShortCode,
    entryCount: _constants.AROUND_ME_ENTRY_COUNT
  };

  if (testParams) {
    paramsObj = testParams;
  }

  return function (dispatch, getState, _ref9) {
    var gsSocket = _ref9.gsSocket;

    if (total === leaderboard.length) {
      return dispatch(loadLeaderboardDataCompleted(leaderboard, total, true));
    }

    return gsSocket.sendWithData('.AroundMeLeaderboardRequest', paramsObj, function (res) {
      var error = res.error,
          data = res.data,
          _res$scriptData3 = res.scriptData,
          scriptData = _res$scriptData3 === void 0 ? {} : _res$scriptData3;

      if (error) {
        if (error.leaderboardShortCode && error.leaderboardShortCode === 'NO_ENTRY') {
          dispatch(loadLeaderboardDataCompleted(leaderboard, total, true));
          return dispatch(loadLeaderboardData({
            offset: _constants.TOP_DJ_COUNT
          }));
        }

        dispatch(isLeaderboardLoading(false));
        return dispatch(setGsError(error));
      }

      if (data) {
        var list = scriptData.data || data;
        dispatch(loadAroundMeCompleted({
          leaderboard: leaderboard,
          aroundMeList: list,
          total: total
        }));
      }
    });
  };
}

function loadAroundMeCompleted(params) {
  var leaderboard = params.leaderboard,
      aroundMeList = params.aroundMeList,
      total = params.total;
  return {
    type: _actionTypes.LOAD_AROUND_ME_COMPLETED,
    leaderboard: leaderboard,
    aroundMeList: aroundMeList,
    total: total
  };
}