var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MuiLinkButton;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = require("@material-ui/core/styles");

var useStyles = (0, _styles.makeStyles)({
  link: {
    cursor: 'pointer'
  }
});

function MuiLinkButton(_ref) {
  var translationKey = _ref.translationKey,
      onClick = _ref.onClick,
      color = _ref.color,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? 'body1' : _ref$variant;
  var classes = useStyles();
  return _react.default.createElement(_core.Link, {
    className: classes.link,
    onClick: onClick,
    color: color,
    variant: variant
  }, _i18n.default.translate(translationKey));
}