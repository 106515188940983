var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _profile = _interopRequireDefault(require("../../../core/profile"));

var _DjBase = _interopRequireDefault(require("./DjBase"));

function mapStateToProps(state, ownProps) {
  var djId = ownProps.djId;
  var musicType = ownProps.musicType;

  if (musicType === undefined) {
    var hashtags = _profile.default.selectors.getHashtags(state, djId);

    return {
      djs: _profile.default.selectors.getDjsMusicGenre(state, djId, hashtags),
      hashtags: hashtags
    };
  } else {
    return {
      djs: _profile.default.selectors.getDjsMusicGenre(state, djId, [musicType]),
      hashtags: [musicType]
    };
  }
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  loadDjsMusicGenre: _profile.default.actions.loadDjsMusicGenre
})(_DjBase.default);

exports.default = _default;