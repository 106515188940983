var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSpecialStages = exports.getOrderedStages = void 0;

var _constants = require("./constants");

var _events = _interopRequireDefault(require("../events/"));

var _lodash = _interopRequireDefault(require("lodash"));

var getOrderedStages = function getOrderedStages(state, festivalId) {
  var stages = state[_constants.NAME].get(festivalId);

  var newList = stages.map(function (el) {
    var val = _events.default.selectors.hasLives(state, el.get('id'));

    return el.set('hasLives', val);
  });
  newList = newList.sort(function (a, b) {
    return a.get('priority') < b.get('priority') ? 1 : -1;
  });
  return _lodash.default.sortBy(newList.toArray(), function (stage) {
    return stage.get('hasLives');
  });
};

exports.getOrderedStages = getOrderedStages;

var getSpecialStages = function getSpecialStages(state, festivalId) {
  var stages = state[_constants.NAME].get(festivalId);

  var list = stages.filter(function (el) {
    return el.get('type') === 'continuous_streaming';
  });
  return list;
};

exports.getSpecialStages = getSpecialStages;