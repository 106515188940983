var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectCheckoutError = exports.selectTicketsIsLoaded = exports.selectedTicketByIdSelector = exports.freeTicketIdSelector = exports.checkoutIntentSelector = exports.hasPromoCodesSelector = exports.outOfStockPromoCodeSelector = exports.matchPromoCodeSelector = exports.validTicketsSelector = exports.checkoutLoadingSelector = exports.callbackUrlSelector = exports.selectedPromoCodeSelector = exports.selectedPromoCodeIdSelector = exports.selectedTicketAddonsSortedSelector = exports.selectedTicketSelector = exports.ticketTotalPriceSelector = exports.selectedVariationsPriceSelector = exports.selectedAddonVariationsGroupedByAddonIdSelector = exports.selectedAddonVariationsSelector = exports.selectedTicketIdSelector = exports.promoCodeByIdSelector = exports.ticketByIdSelector = exports.promoCodesSelector = exports.ticketsSelector = exports.promoCodeStateSelector = exports.ticketStateSelector = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _moment = _interopRequireDefault(require("moment"));

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../tickets/selectors");

var _currencyUtils = require("../../utils/currency-utils");

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _paymentUtils = require("../../utils/payment-utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var checkoutStateSelector = function checkoutStateSelector(state) {
  return state[_constants.NAME] || state;
};

var checkoutIntentStateSelector = function checkoutIntentStateSelector(state) {
  return state[_constants.CHECKOUT_INTENT_NAME];
};

var ticketStateSelector = (0, _toolkit.createSelector)(checkoutStateSelector, function (state) {
  return (state === null || state === void 0 ? void 0 : state.ticketState) || state;
});
exports.ticketStateSelector = ticketStateSelector;
var promoCodeStateSelector = (0, _toolkit.createSelector)(checkoutStateSelector, function (state) {
  return (state === null || state === void 0 ? void 0 : state.promoCodeState) || state;
});
exports.promoCodeStateSelector = promoCodeStateSelector;

var ticketsSelector = _constants.eventTicketEntityAdapter.getSelectors(ticketStateSelector).selectAll;

exports.ticketsSelector = ticketsSelector;

var promoCodesSelector = _constants.promoCodeEntityAdapter.getSelectors(promoCodeStateSelector).selectAll;

exports.promoCodesSelector = promoCodesSelector;

var ticketByIdSelector = _constants.eventTicketEntityAdapter.getSelectors(ticketStateSelector).selectById;

exports.ticketByIdSelector = ticketByIdSelector;

var promoCodeByIdSelector = _constants.promoCodeEntityAdapter.getSelectors(promoCodeStateSelector).selectById;

exports.promoCodeByIdSelector = promoCodeByIdSelector;
var selectedTicketIdSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state.ticketId;
});
exports.selectedTicketIdSelector = selectedTicketIdSelector;
var selectedAddonVariationsSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state.selectedAddonVariations;
});
exports.selectedAddonVariationsSelector = selectedAddonVariationsSelector;
var selectedAddonVariationsGroupedByAddonIdSelector = (0, _toolkit.createSelector)(selectedAddonVariationsSelector, function (addons) {
  if (!addons) {
    return [];
  }

  return addons.reduce(function (accum, variation) {
    var addonId = variation.addonId;

    if (!accum[addonId]) {
      accum[addonId] = [];
    }

    accum[addonId].push(variation.id);
    return accum;
  }, {});
});
exports.selectedAddonVariationsGroupedByAddonIdSelector = selectedAddonVariationsGroupedByAddonIdSelector;
var selectedVariationsPriceSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state.variationsPrice;
});
exports.selectedVariationsPriceSelector = selectedVariationsPriceSelector;
var ticketTotalPriceSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state.totalPrice;
});
exports.ticketTotalPriceSelector = ticketTotalPriceSelector;
var selectedTicketSelector = (0, _toolkit.createSelector)(ticketStateSelector, selectedTicketIdSelector, ticketByIdSelector);
exports.selectedTicketSelector = selectedTicketSelector;
var selectedTicketAddonsSortedSelector = (0, _toolkit.createSelector)(selectedTicketSelector, function (ticket) {
  var _ticket$selectedAddon;

  if (!(ticket !== null && ticket !== void 0 && (_ticket$selectedAddon = ticket.selectedAddons) !== null && _ticket$selectedAddon !== void 0 && _ticket$selectedAddon.length) || ticket.externalPaymentLink) {
    return [];
  } else {
    var addons = (0, _toConsumableArray2.default)(ticket === null || ticket === void 0 ? void 0 : ticket.selectedAddons);
    return addons.sort(function (a, b) {
      return a.hasOwnProperty('sort') && b.hasOwnProperty('sort') ? a.sort - b.sort : a.createdDate - b.createdDate;
    });
  }
});
exports.selectedTicketAddonsSortedSelector = selectedTicketAddonsSortedSelector;
var selectedPromoCodeIdSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state.promoCodeId;
});
exports.selectedPromoCodeIdSelector = selectedPromoCodeIdSelector;
var selectedPromoCodeSelector = (0, _toolkit.createSelector)(promoCodeStateSelector, selectedPromoCodeIdSelector, promoCodeByIdSelector);
exports.selectedPromoCodeSelector = selectedPromoCodeSelector;
var callbackUrlSelector = (0, _toolkit.createSelector)(checkoutIntentStateSelector, function (state) {
  return state === null || state === void 0 ? void 0 : state.callbackUrl;
});
exports.callbackUrlSelector = callbackUrlSelector;
var checkoutLoadingSelector = (0, _toolkit.createSelector)(checkoutStateSelector, function (state) {
  return state === null || state === void 0 ? void 0 : state.loading;
});
exports.checkoutLoadingSelector = checkoutLoadingSelector;

var generatePrice = function generatePrice(price, currency) {
  return (0, _currencyUtils.concatPriceWithCurrency)(price, currency);
};

var validTicketsSelector = (0, _toolkit.createSelector)(_selectors.getCurrency, ticketsSelector, _selectors2.selectTicketsForEvent, function (currency, tickets, userTickets) {
  var userTicketIds = userTickets.map(function (ticket) {
    return ticket.ticketId;
  });

  var isTicketDisabled = function isTicketDisabled(ticket) {
    if (!(0, _moment.default)().isBetween((0, _moment.default)(ticket.startDate), (0, _moment.default)(ticket.endDate)) && !ticket.unlimitedSellingTime) return true;
    return false;
  };

  return tickets.map(function (ticket, index) {
    var getParsedPriceLabel = function getParsedPriceLabel() {
      if (ticket.displaySoldOutLabel) {
        return _i18n.default.t('checkout.soldOut');
      }

      if (ticket.price) {
        return generatePrice(ticket.price, currency);
      }

      if (ticket.showFreeTicketLabel) {
        return _i18n.default.t('checkout.free');
      }

      return '';
    };

    return _objectSpread(_objectSpread({}, ticket), {}, {
      disabled: isTicketDisabled(ticket),
      alreadyOwned: userTicketIds.includes(ticket.id),
      parsedPriceLabel: getParsedPriceLabel(),
      description: ticket.description
    });
  });
});
exports.validTicketsSelector = validTicketsSelector;

var promoCodeSelector = function promoCodeSelector(state, query) {
  return query;
};

var matchPromoCodeSelector = (0, _toolkit.createSelector)(promoCodesSelector, promoCodeSelector, function (promoCodes, _ref) {
  var _code = _ref.code,
      ticketId = _ref.ticketId;
  return promoCodes.find(function (_ref2) {
    var code = _ref2.code,
        allTickets = _ref2.allTickets,
        endDate = _ref2.endDate,
        startDate = _ref2.startDate,
        selectedTickets = _ref2.selectedTickets,
        quantity = _ref2.quantity,
        used = _ref2.used;
    var codeAccess = String(code).toLowerCase() === String(_code).toLowerCase();
    var ticketAccess = allTickets || selectedTickets.find(function (_ref3) {
      var id = _ref3.id;
      return id === ticketId;
    });
    var dateAccess = endDate && startDate ? (0, _moment.default)().isBetween((0, _moment.default)(startDate), (0, _moment.default)(endDate)) : true;
    var hasQuantity = quantity ? quantity - (used || 0) > 0 : true;
    return codeAccess && ticketAccess && dateAccess && hasQuantity;
  });
});
exports.matchPromoCodeSelector = matchPromoCodeSelector;
var outOfStockPromoCodeSelector = (0, _toolkit.createSelector)(promoCodesSelector, promoCodeSelector, function (promoCodes, _ref4) {
  var _code = _ref4.code,
      ticketId = _ref4.ticketId,
      endDate = _ref4.endDate,
      startDate = _ref4.startDate;
  var promoCode = promoCodes.find(function (_ref5) {
    var code = _ref5.code,
        allTickets = _ref5.allTickets,
        selectedTickets = _ref5.selectedTickets;
    var codeAccess = String(code).toLowerCase() === String(_code).toLowerCase();
    var ticketAccess = allTickets || selectedTickets.find(function (_ref6) {
      var id = _ref6.id;
      return id === ticketId;
    });
    var dateAccess = endDate && startDate ? (0, _moment.default)().isBetween((0, _moment.default)(startDate), (0, _moment.default)(endDate)) : true;
    return codeAccess && ticketAccess && dateAccess;
  });
  if (!promoCode) return null;
  return promoCode.quantity ? promoCode.quantity - (promoCode.used || 0) <= 0 : false;
});
exports.outOfStockPromoCodeSelector = outOfStockPromoCodeSelector;
var hasPromoCodesSelector = (0, _toolkit.createSelector)(promoCodesSelector, function (promoCodes) {
  return !!promoCodes.length;
});
exports.hasPromoCodesSelector = hasPromoCodesSelector;
var checkoutIntentSelector = (0, _toolkit.createSelector)(selectedPromoCodeSelector, selectedTicketSelector, _selectors.getCurrency, function (promoCode, ticket, currency) {
  if (!ticket) return null;

  if (!promoCode) {
    return {
      id: ticket.id,
      name: ticket.name,
      quantity: 1,
      price: generatePrice(ticket.price, currency),
      type: ticket.type,
      description: ticket.description
    };
  } else {
    return {
      id: ticket.id,
      name: ticket.name,
      quantity: 1,
      price: ticket.price,
      type: ticket.type,
      promoCode: promoCode,
      description: ticket.description,
      oldPrice: generatePrice(ticket.price, currency),
      newPrice: generatePrice((ticket.price - ticket.price * (promoCode.percent / 100)).toFixed(2), currency)
    };
  }
});
exports.checkoutIntentSelector = checkoutIntentSelector;
var freeTicketIdSelector = (0, _toolkit.createSelector)(validTicketsSelector, function (tickets) {
  var _tickets$find;

  return ((_tickets$find = tickets.find(function (s) {
    return s.type === 'Free';
  })) === null || _tickets$find === void 0 ? void 0 : _tickets$find.id) || null;
});
exports.freeTicketIdSelector = freeTicketIdSelector;
var selectedTicketByIdSelector = (0, _toolkit.createSelector)(ticketByIdSelector, _selectors.getCurrency, function (ticket, currency) {
  if (!ticket) return null;
  return {
    name: ticket.name,
    quantity: 1,
    price: generatePrice(ticket.price, currency),
    type: ticket.type,
    description: ticket.description,
    selectedAddons: ticket.selectedAddons,
    externalPaymentLink: ticket.externalPaymentLink
  };
});
exports.selectedTicketByIdSelector = selectedTicketByIdSelector;
var selectTicketsIsLoaded = (0, _toolkit.createSelector)(checkoutStateSelector, function (state) {
  return state.ticketState.loaded;
});
exports.selectTicketsIsLoaded = selectTicketsIsLoaded;
var selectCheckoutError = (0, _toolkit.createSelector)(checkoutStateSelector, function (state) {
  return state === null || state === void 0 ? void 0 : state.error;
});
exports.selectCheckoutError = selectCheckoutError;