Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.windowOpen = exports.getUrlRoute = exports.getBackstageIdParam = exports.getEventIdParam = exports.getQueryParameter = exports.getUrlParam = void 0;

var getUrlParam = function getUrlParam(elem) {
  elem = elem.replace('/', '');
  var urlParts = window.location.pathname.split('/');
  var index = urlParts.indexOf(elem);
  return index > 0 ? urlParts[index + 1] : '';
};

exports.getUrlParam = getUrlParam;

var getQueryParameter = function getQueryParameter(name) {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

exports.getQueryParameter = getQueryParameter;

var getEventIdParam = function getEventIdParam(elem) {
  try {
    var url = new URL(elem);
    return url.pathname.split('/')[1];
  } catch (_unused) {
    return '';
  }
};

exports.getEventIdParam = getEventIdParam;

var getBackstageIdParam = function getBackstageIdParam(elem) {
  try {
    var url = new URL(elem);
    var paths = url.pathname.split('/');

    if (paths[2] && paths[2] === 'backstage' && paths[3]) {
      return paths[3];
    }

    return '';
  } catch (_unused2) {
    return '';
  }
};

exports.getBackstageIdParam = getBackstageIdParam;

var getUrlRoute = function getUrlRoute(elem) {
  try {
    var url = new URL(elem);
    return url.pathname.split('/')[2];
  } catch (_unused3) {
    return '';
  }
};

exports.getUrlRoute = getUrlRoute;

var windowOpen = function windowOpen(url, name, specs) {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }

  return window.open(url, name, specs);
};

exports.windowOpen = windowOpen;