var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _eventCardView = _interopRequireDefault(require("./event-card-view"));

var _default = (0, _reactRedux.connect)()(_eventCardView.default);

exports.default = _default;