var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PulseDot = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));

var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));

var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _lottieReactNative = _interopRequireDefault(require("lottie-react-native"));

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = (0, _getPrototypeOf2.default)(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = (0, _getPrototypeOf2.default)(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return (0, _possibleConstructorReturn2.default)(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

var PulseDot = function (_React$PureComponent) {
  (0, _inherits2.default)(PulseDot, _React$PureComponent);

  var _super = _createSuper(PulseDot);

  function PulseDot() {
    (0, _classCallCheck2.default)(this, PulseDot);
    return _super.apply(this, arguments);
  }

  (0, _createClass2.default)(PulseDot, [{
    key: "render",
    value: function render() {
      var color = this.props.color;
      return _react.default.createElement(_reactNative.View, null, _react.default.createElement(_lottieReactNative.default, {
        source: color === 'red' ? require('./Pulse-red.json') : require('./Pulse.json'),
        style: color === 'red' ? {
          height: 24,
          width: 24
        } : {
          height: 16,
          width: 16
        },
        autoPlay: true,
        loop: true
      }));
    }
  }]);
  return PulseDot;
}(_react.default.PureComponent);

exports.PulseDot = PulseDot;
var _default = PulseDot;
exports.default = _default;