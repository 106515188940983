var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CalendarSessionListView;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _AddToCalendarButton = _interopRequireDefault(require("../../../theme/components/buttons/AddToCalendarButton"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _avatars = _interopRequireDefault(require("../../common/avatars"));

var _calendarSessionListStyles = _interopRequireDefault(require("./calendar-session-list-styles"));

var _dateFormatter = require("../../../utils/date-formatter");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user/selectors");

var _quickAccessButton = _interopRequireDefault(require("../button/quick-access-button"));

var _sessionUtils = require("../../../utils/session-utils");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _reactNavigationHooks = require("react-navigation-hooks");

var _calendarNotice = _interopRequireDefault(require("../../backstage/schedule/calendar-notice"));

var _styles = _interopRequireDefault(require("./styles"));

var DATE_ONLY = 'YYYY/MM/DD';

function CalendarSessionListView(_ref) {
  var selectedDay = _ref.selectedDay,
      onAvatarNavigation = _ref.onAvatarNavigation,
      onLiveSessionNavigation = _ref.onLiveSessionNavigation,
      onNetworkingSessionNavigation = _ref.onNetworkingSessionNavigation,
      selectedStage = _ref.selectedStage,
      sessions = _ref.sessions,
      eventName = _ref.eventName,
      eventTimezone = _ref.eventTimezone,
      eventDateFormat = _ref.eventDateFormat,
      improved = _ref.improved,
      showJoinLiveButton = _ref.showJoinLiveButton;
  var Styles = (0, _calendarSessionListStyles.default)();
  var classes = (0, _styles.default)();
  var isLoggedIn = (0, _reactRedux.useSelector)(_selectors.isFirebaseUserLoggedIn);
  var livePageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.LIVE_ID);

  var groupBy = function groupBy(list) {
    var result = list.reduce(function (prev, current) {
      var categ = current.category || '';
      var prevCategory = prev[prev.length - 1] && prev[prev.length - 1][0].category || '';

      if (prev.length && categ === prevCategory) {
        prev[prev.length - 1].push(current);
      } else {
        prev.push([current]);
      }

      return prev;
    }, []);
    return result;
  };

  var isPresentable = function isPresentable(session) {
    var shouldShowUp = false;

    if (selectedStage && selectedDay && session.resourceId === selectedStage.id && (0, _momentTimezone.default)(session.start).format(DATE_ONLY) === (0, _momentTimezone.default)(selectedDay).format(DATE_ONLY)) {
      shouldShowUp = true;
    }

    return shouldShowUp;
  };

  var renderListItem = function renderListItem(session) {
    var _getSessionDetailsFor = (0, _sessionUtils.getSessionDetailsForCalendar)(session, eventDateFormat),
        isLive = _getSessionDetailsFor.isLive,
        startDateTime = _getSessionDetailsFor.startDateTime,
        endDateTime = _getSessionDetailsFor.endDateTime,
        startHour = _getSessionDetailsFor.startHour,
        endHour = _getSessionDetailsFor.endHour,
        zoneAbbreviation = _getSessionDetailsFor.zoneAbbreviation,
        speakerUrlsAndIds = _getSessionDetailsFor.speakerUrlsAndIds,
        speakerNames = _getSessionDetailsFor.speakerNames;

    var start = (0, _momentTimezone.default)(startDateTime).format(_dateFormatter.longDateTimeFormat);
    var end = (0, _momentTimezone.default)(endDateTime).format(_dateFormatter.longDateTimeFormat);
    var sessionTitle = "".concat(session.title, " | ").concat(eventName);
    var description = "".concat(_i18n.default.t('lobby.with'), " ").concat(speakerNames);
    var location = window.location.href.slice(0, window.location.href.lastIndexOf('/'));
    var sessionDescription = session.description;

    var renderTitle = function renderTitle(title, speakerNames) {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
        className: classes.title
      }, _react.default.createElement(_core.Typography, {
        variant: "h4"
      }, title), speakerNames && _react.default.createElement(_core.Typography, {
        variant: "h4",
        className: classes.textNormal
      }, ", ", _i18n.default.t('lobby.with'), " ", speakerNames) || null));
    };

    return _react.default.createElement(_Spacing.default, {
      style: Styles.row,
      direction: "row",
      wrap: "nowrap",
      flex: 1,
      alignItems: "center",
      mSpacing: ['m', 'l'],
      justifyContent: "flex-start"
    }, improved ? _react.default.createElement(_core.Box, {
      className: classes.textContainer
    }, _react.default.createElement(_core.Typography, {
      variant: "overline"
    }, "".concat(startHour, " - ").concat(endHour, " (").concat(zoneAbbreviation, ")")), renderTitle(session.title, speakerNames), !!speakerUrlsAndIds.length && _react.default.createElement(_avatars.default, {
      style: Styles.avatars,
      onPress: onAvatarNavigation,
      urls: speakerUrlsAndIds,
      nonTouching: true
    }), sessionDescription && _react.default.createElement(_core.Typography, {
      className: classes.description,
      variant: "overline"
    }, sessionDescription)) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
      style: Styles.textContainer,
      flex: 1,
      mSpacing: [0, 0, 0, 'm'],
      direction: "column",
      alignItems: "flex-start"
    }, _react.default.createElement(_core.Typography, {
      variant: "h4",
      className: classes.eventTime
    }, "".concat(startHour, " - ").concat(endHour, " (").concat(zoneAbbreviation, ")")), renderTitle(session.title, speakerNames), _react.default.createElement(_avatars.default, {
      style: Styles.avatars,
      onPress: onAvatarNavigation,
      urls: speakerUrlsAndIds
    }), sessionDescription ? _react.default.createElement(_core.Typography, {
      className: classes.description,
      variant: "overline"
    }, sessionDescription) : null)), showJoinLiveButton && isLoggedIn && isLive && !improved && _react.default.createElement(_quickAccessButton.default, {
      alignment: "right",
      session: session,
      liveNavigation: function liveNavigation() {
        return onLiveSessionNavigation(selectedStage.id);
      },
      networkingNavigation: function networkingNavigation() {
        return onNetworkingSessionNavigation(session.networkTable);
      }
    }), _react.default.createElement(_Spacing.default, {
      style: Styles.addToCalendarButton,
      self: "right"
    }, _react.default.createElement(_AddToCalendarButton.default, {
      start: start,
      end: end,
      timezone: eventTimezone,
      title: sessionTitle,
      description: description,
      location: location,
      iconButton: true
    })));
  };

  var presentableSessions = sessions.filter(function (session) {
    return isPresentable(session);
  });

  if (!presentableSessions.length) {
    return _react.default.createElement(_Spacing.default, (0, _defineProperty2.default)({
      style: Styles.row,
      justifyContent: "flex-start",
      mSpacing: "m"
    }, "style", {
      marginLeft: -4
    }), _react.default.createElement(_calendarNotice.default, {
      text: _i18n.default.t('calendar.errors.noSessionsForToday')
    }));
  }

  var groupedSessions = groupBy(presentableSessions);
  return _react.default.createElement(_react.default.Fragment, null, Object.entries(groupedSessions).map(function (_ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
        key = _ref3[0],
        value = _ref3[1];

    var sessionCategory = value[0].category;
    return _react.default.createElement(_react.default.Fragment, {
      key: key
    }, _react.default.createElement(_Spacing.default, {
      style: Styles.row,
      mSpacing: sessionCategory ? ['l', 'm', 'm', 'm'] : 'm',
      justifyContent: "flex-start"
    }, _react.default.createElement(_core.Typography, {
      className: classes.textUppercase,
      variant: "h3"
    }, sessionCategory)), value && value.map(function (session, index) {
      var _getSessionDetailsFor2 = (0, _sessionUtils.getSessionDetailsForCalendar)(session),
          isLive = _getSessionDetailsFor2.isLive;

      if (isLive && improved) return null;
      return _react.default.createElement(_Section.default, {
        shaped: true,
        style: Styles.descriptionText,
        color: index % 2 === 0 ? 'alternativeBackground' : 'pageBackground',
        key: index
      }, renderListItem(session));
    }));
  }));
}